var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.card.title && _vm.card.title === 'Add new payment method')?_c('div',{staticClass:"addCard",on:{"click":function($event){return _vm.handleActionClick(_vm.card.title)}}},[_c('v-icon',{attrs:{"size":44,"color":"primary"}},[_vm._v(_vm._s('mdi-plus'))]),_c('div',{staticClass:"cardTitle",style:({
      'font-size': ("" + (_vm.currentScreenWidth > 1900 ? '18px' : '20px')),
      padding: ("" + (_vm.getAddNewPaymentTextPadding())),
    })},[_vm._v(" "+_vm._s(_vm.translatedTitle)+" ")])],1):_c('div',{staticClass:"savedCards"},[_c('div',{staticClass:"iconContainer"},[(
        !_vm.card.paymentMethodBrand ||
        (_vm.card.paymentMethodBrand && !_vm.logos[_vm.card.paymentMethodBrand])
      )?_c('div',{staticClass:"cardIconAbsent"},[_c('v-img',{attrs:{"src":'/assets/img/Generic-card.svg',"alt":"image","aspect-ratio":16 / 9,"lazy-src":"/assets/img/slides/placeholder-slide.svg"}})],1):_c('div',{staticClass:"cardIconPresent"},[_c('v-img',{attrs:{"src":("" + (_vm.logos[_vm.card.paymentMethodBrand])),"alt":"image","aspect-ratio":16 / 9,"lazy-src":"/assets/img/slides/placeholder-slide.svg"}})],1)]),_c('div',{staticClass:"otherCardInfo"},[_c('div',{staticClass:"maskedCardNumber"},[_c('div',{staticClass:"mask"},[_vm._v(_vm._s('**** **** ****'))]),_c('div',{staticClass:"last4Numbers"},[_vm._v(" "+_vm._s(("" + (_vm.card.paymentMethodLast4)))+" ")])]),_c('div',{staticClass:"cardHolderName"},[_vm._v(" "+_vm._s(_vm.card.name)+" ")]),(!_vm.isCardExpired)?_c('div',{staticClass:"cardExpiry"},[_vm._v(" "+_vm._s(((_vm.$t('profile.editPaymentDetail.expiresOn')) + " " + (!(_vm.card.exp_month.toString().length > 1) ? ("0" + (_vm.card.exp_month.toString())) : ("" + (_vm.card.exp_month.toString()))) + "/" + (_vm.card.exp_year.toString().slice(-2))))+" ")]):_c('div',{staticClass:"cardExpired"},[_vm._v(" "+_vm._s(((_vm.$t('profile.editPaymentDetail.expiredOn')) + " " + (!(_vm.card.exp_month.toString().length > 1) ? ("0" + (_vm.card.exp_month.toString())) : ("" + (_vm.card.exp_month.toString()))) + "/" + (_vm.card.exp_year.toString().slice(-2))))+" ")])]),_c('div',{staticClass:"cardActionsAndStatus"},[_c('div',{staticClass:"status"},[(_vm.card.is_default && _vm.card.is_default === true)?_c('v-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.$t('profile.failedPaymentDetail.defaultMethod'),
          placement: 'top',
        }),expression:"{\n          content: $t('profile.failedPaymentDetail.defaultMethod'),\n          placement: 'top',\n        }"}],staticClass:"current-subscription-check"},[_vm._v(" "+_vm._s('mdi-check-circle')+" ")]):_vm._e()],1),(_vm.actionsNeeded === true)?_c('div',{staticClass:"actions"},[_c('v-menu',{attrs:{"bottom":"","absolute":"","offset-y":"","close-on-click":true,"close-on-content-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"dotsIcon",attrs:{"size":30}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s('mdi-dots-vertical')+" ")])]}}],null,false,2860914891)},[_c('v-list',_vm._l(([].concat( _vm.getOptionsWithCardStatus() )),function(option,ind){return _c('v-list-item',{key:ind,on:{"click":function($event){return _vm.handleActionClick(option.title)}}},[_c('v-list-item-title',[_c('v-icon',[_vm._v(_vm._s(option.icon))]),_c('div',{staticClass:"optionTitle"},[_vm._v(_vm._s(_vm.$t(option.display_title)))])],1)],1)}),1)],1)],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }