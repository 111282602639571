var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"detail-banner"},[_c('div',{staticClass:"context-wrapper"},[(!_vm.isPrezentSourceBestPractice)?[_c('span',[_vm._v(" "+_vm._s(_vm.prezentationData.prezentationSource === 'IOCUpload' || _vm.prezentationData.prezentationSource === 'User Uploaded' ? _vm.$t('build.step3.updatedOn') : _vm.prezentationData.prezentationSource === 'Generated' ? _vm.$t('build.step3.generatedOn') : _vm.prezentationData.prezentationSource === 'Redesign' ? _vm.$t('build.step3.redesignedOn') : _vm.prezentationData.prezentationSource === 'Comply' ? _vm.$t('build.step3.addedOn') : _vm.$t('build.step3.createdOn'))+" "+_vm._s(_vm._f("formatDate")(_vm.prezentationData.createdAt,'MM/DD/YYYY'))+" ")]),_c('span',{staticClass:"define-context-sub-header__divider"},[_vm._v(" | ")])]:_vm._e(),_c('v-tooltip',{attrs:{"bottom":"","content-class":"tooltip-content"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"storyline"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('prezentationsList.storylineText'))+": "+_vm._s(_vm.getStoryline)+" ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.getStoryline)+" ")])]),_c('span',{staticClass:"define-context-sub-header__divider"},[_vm._v(" | ")]),_c('span',[_vm._v(_vm._s(_vm.$t('prezentationsList.source'))+": "+_vm._s(_vm.sourceName)+" ")]),_c('span',{staticClass:"define-context-sub-header__divider"},[_vm._v(" | ")]),_c('PrezentationShareStatus',{attrs:{"currentUser":_vm.currentUser,"prezentation":_vm.prezentationData,"isEditable":true,"origin":((_vm.origin || _vm.matomo_my_prezentation) + "-" + _vm.matomo_expanded_view + "-icon")}}),(
        (_vm.prezentationData.prezentationSource === 'Product' ||
          _vm.prezentationData.prezentationSource === 'User Uploaded') &&
        !_vm.isPrezentSourceBestPractice
      )?_c('span',{staticClass:"define-context-sub-header"},[_c('span',{staticClass:"define-context-sub-header__divider",staticStyle:{"padding-right":"10px"}},[_vm._v(" | ")]),_c('span',[_vm._v(" "+_vm._s(_vm.$t('build.step3.author'))+": "+_vm._s(_vm.currentUser.user.id === _vm.prezentationData.ownerID ? _vm.$t('build.step1.me') : ("" + (_vm.prezentationData.author)))+" ")])]):_vm._e(),(
        [
          'Product',
          'User Uploaded',
          'IOCUpload',
          'Generated',
          'Comply' ].includes(_vm.prezentationData.prezentationSource)
      )?[_c('span',{staticClass:"define-context-sub-header__divider"},[_vm._v(" | ")]),_c('v-tooltip',{attrs:{"bottom":"","content-class":"tooltip-content"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"description prez-description"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('build.step3.description'))+": "+_vm._s(_vm.getPrezDescription)+" ")])]}}],null,false,3048506356)},[_c('span',[_vm._v(_vm._s(_vm.getPrezDescription))])])]:_vm._e(),(_vm.getPrezAlias.length > 0)?[_c('span',{staticClass:"define-context-sub-header__divider"},[_vm._v(" | ")]),_c('v-tooltip',{attrs:{"bottom":"","content-class":"tooltip-content"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"description prez-description also-known-as"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('bestPracticesGuide.knowContainer.alsoKnownAs'))+" "+_vm._s(_vm.getPrezAlias)+" ")])]}}],null,false,3578884103)},[_c('span',[_vm._v(_vm._s(_vm.getPrezAlias))])])]:_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }