<template>
  <div class="module-wrapper">
    <v-expansion-panels multiple :value="activeModule" flat>
      <v-expansion-panel
        v-for="(courseModule, index) in modules"
        :key="index"
        class="module-section"
      >
        <v-expansion-panel-header>
          <div class="module-section__header">
            <v-progress-circular
              :size="45"
              :value="courseModule.status"
              color="#21a7e0"
            >
              <span class="module-section__header--status">
                {{ courseModule.status }}%
              </span>
            </v-progress-circular>
            <p class="module-section__header--title">
              {{ courseModule.display_name }}
            </p>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content
          v-for="(assetContent, assetIndex) in courseModule.assets"
          :key="assetIndex"
          :class="[
            { first: assetIndex === 0 },
            { last: assetIndex === courseModule.assets.length - 1 },
          ]"
          class="module-content-section"
        >
          <v-timeline dense>
            <v-timeline-item
              :class="assetContent.status < 100 ? 'v-timeline-item-custom' : ''"
              :icon="getAssetIcon(assetContent)"
              icon-color="#fff"
            >
              <div class="assests-section">
                <div class="assests-title-section">
                  <NewFeatureBadge
                    module-name="learncoursedetail"
                    :feature-id="assetContent.id"
                    :offsetX="'-5'"
                    :offsetY="'20'"
                    :inline="false"
                  >
                    <div class="assests-section__title">
                      {{ assetContent.display_name }}
                    </div>
                  </NewFeatureBadge>
                  <div class="assests-section__description">
                    {{ assetContent.description }}
                  </div>
                </div>

                <div class="asset-video-duration-section">
                  <a
                    @click="
                      getAssetInfo({
                        ...assetContent,
                        learnModuleName: courseModule.display_name,
                        courseCompletionPercentage: courseModule.status,
                      })
                    "
                    class="asset-video-duration-section__video"
                    v-if="assetContent.asset_type.toLowerCase() === 'video'"
                  >
                    <v-icon color="#21a7e0">mdi-television-play</v-icon>
                    {{ $t('learn.course.video') }}
                  </a>
                  <a
                    class="asset-video-duration-section__video"
                    @click="
                      goToPractice(
                        assetContent.id,
                        courseModule.id,
                        assetContent.display_name,
                      )
                    "
                    v-if="assetContent.asset_type.toLowerCase() === 'exercise'"
                  >
                    <v-icon color="#21a7e0">mdi-book-open-variant</v-icon>
                    {{ $t('learn.course.practice') }}
                  </a>
                  <a
                    :class="`asset-video-duration-section__video ${
                      quizEnabled(courseModule.assets) ? '' : 'disabled'
                    }`"
                    :disabled="quizEnabled(courseModule.assets)"
                    @click="
                      goToQuiz(
                        assetContent.id,
                        courseModule.assets,
                        courseModule.display_name,
                      )
                    "
                    v-if="assetContent.asset_type.toLowerCase() === 'quiz'"
                  >
                    <v-icon
                      :color="
                        quizEnabled(courseModule.assets, courseModule.assets)
                          ? '#21a7e0'
                          : '#b6b6b6'
                      "
                      :disabled="!quizEnabled(courseModule.assets)"
                      >mdi-playlist-check</v-icon
                    >
                    {{ $t('learn.course.quiz') }}
                  </a>
                  <span class="asset-video-duration-section__duration">
                    <v-icon> mdi-clock-time-eight-outline </v-icon>
                    {{ assetContent.duration_seconds | secondsToMinutes }}m
                  </span>
                </div>
              </div>
            </v-timeline-item>
          </v-timeline>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <VideoPlayer
      :is-open="openDialogue"
      :video-detail="videoInfo"
      @likeupdated="handleLikeFn"
      @dislikeupdated="handleDislikeFn"
      @viewupdated="handleViewUpdateFn"
      @modalclosed="handleVideoClosedFn"
      v-if="openDialogue"
    />
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import learnApi from '../../../API/learn-api';
import VideoPlayer from '../../common/VideoPlayer.vue';
import { getFileURL } from '@/utils/calyrex';
import { MatomoAnalyticsHandler } from '../../common/Analytics/MatomoAnalyticsHandler';
import NewFeatureBadge from '../../common/NewFeatureBadge.vue';
import { trackLearnEvents } from '../../common/Analytics/LearnEvents';

export default {
  name: 'CourseModules',
  components: {
    VideoPlayer,
    NewFeatureBadge,
  },
  props: {
    courseSlug: {
      type: String,
      required: true,
    },
    modules: {
      type: Array,
      required: true,
    },
    activeModule: {
      type: Array,
      default() {
        return [0];
      },
    },
    activeAsset: {
      type: Object,
    },
    courseDetails: {
      type: Object,
    },
  },
  data() {
    return {
      timeLineIcon: 'mdi-television-play',
      timeLineIconColor: '#21a7e0',
      openDialogue: false,
      videoInfo: {},
      writingQuestionsExcerciseCourses: [
        {
          moduleId: 'ddc924d8-1fa7-4542-9244-a4458ce340eb',
          name: 'foundations-of-defining-problems',
        },
      ],
      issueTreesExcerciseCourses: [
        {
          moduleId: 'caefdb3e-2f60-414b-84ad-42107edd9aee',
          name: 'structure-problems-like-a-rocket-scientist',
        },
      ],
      artOfSlidesExcerciseCourses: [
        {
          moduleId: 'f60eec53-a03d-4665-a654-3d59f6921aad',
          name: 'tell-stories-that-mesmerize',
        },
      ],
    };
  },
  computed: {
    // a computed getter
    ...mapState('users', ['currentUser']),
    ...mapState('learndata', ['courseInfoForMatotmo']),
  },
  watch: {
    async activeAsset(val) {
      if (val) {
        if (val.asset_type.toLowerCase() === 'video') {
          await this.getAssetInfo(val);
        } else if (val.asset_type.toLowerCase() === 'quiz') {
          this.goToQuiz(val.id);
        } else if (val.asset_type.toLowerCase() === 'exercise') {
          this.goToPractice(val.id, val.moduleId);
        }
      }
    },
  },
  methods: {
    ...mapActions('learndata', ['setCourseInfoForMatomo']),
    quizEnabled(assets) {
      for (let i = 0; i < assets.length; i++) {
        if (assets[i].status !== 100 && assets[i].asset_type !== 'quiz')
          return false;
      }
      return true;
    },
    calculateDuration(duration) {
      return Math.floor(duration / 60);
    },
    handleVideoClosedFn(assetCompletionInfo) {
      const query = { ...this.$route.query };
      // removing log_search and display_name from query
      if (query.log_search) {
        delete query.log_search;
      }
      if (query.display_name) {
        delete query.display_name;
      }
      // removing assetid from query
      if (query.assetid) {
        delete query.assetid;
        this.$router.replace({ query }).catch(() => {});
        this.$emit('removeActiveAsset');
      }
      // need to change the logic based on the completion status.
      if (Object.keys(assetCompletionInfo.uStatus).length > 0) {
        learnApi.methods
          .updateAssetUserStatus(assetCompletionInfo.uStatus)
          .then(
            (assetRep) => {
              if (assetRep) {
                this.$emit('assetStatusUpdated', {
                  action: assetCompletionInfo.videoClosedOrCompleted,
                });
                this.openDialogue = false;
              }
            },
            (error) => {
              console.log('error while updating the asset status --', error);
              this.openDialogue = false;
            },
          );
      } else {
        this.$emit('assetStatusUpdated', {
          action: assetCompletionInfo.videoClosedOrCompleted,
        });
        this.openDialogue = false;
      }
    },

    async handleLikeFn(assetId) {
      await learnApi.methods.getAssetInfo(assetId).then((resp) => {
        this.videoInfo.asset.likes = resp.asset.likes;
      });
    },

    async handleDislikeFn(assetId) {
      await learnApi.methods.getAssetInfo(assetId).then((resp) => {
        this.videoInfo.asset.dislikes = resp.asset.dislikes;
      });
    },

    async handleViewUpdateFn(assetId) {
      await learnApi.methods
        .getAssetInfo(assetId, this.currentUser?.user?.language)
        .then((resp) => {
          this.videoInfo.asset.views = resp.asset.views;
        });
    },

    async getAssetInfo(assetInfo) {
      localStorage.setItem('lastSeenModule', JSON.stringify(assetInfo));
      await learnApi.methods
        .getAssetInfo(assetInfo.id, this.currentUser?.user?.language)
        .then(async (assetResp) => {
          this.videoInfo = assetResp;
          const videoUrl = await getFileURL(
            this.currentUser.user.cognitoID,
            this.videoInfo.asset.video,
            this.currentUser.userIp,
          );
          this.videoInfo.asset.video = videoUrl;
          this.videoInfo = {
            ...this.videoInfo,
            ...{ status: assetInfo.status },
          };
          this.setCourseInfoForMatomo({
            learnContentName: assetInfo.display_name,
            learnModuleName: assetInfo.learnModuleName,
            modules: this.modules,
          });
          try {
            const matomoData = {
              contentName: assetInfo.display_name,
              moduleName: assetInfo.learnModuleName,
              display_name: '',
              domain: '',
            };
            MatomoAnalyticsHandler.learnVideoClick(
              matomoData,
              this.currentUser,
            );
            MatomoAnalyticsHandler.learnCourseModuleProgress(
              this.courseInfoForMatotmo,
              this.currentUser,
              `${assetInfo.courseCompletionPercentage}%`,
            );
            trackLearnEvents.learnVideoClick(this.currentUser, {
              learnCategoryName: this.courseInfoForMatotmo?.learnCategoryName,
              learnCourseName: this.courseInfoForMatotmo?.learnCourseName,
              learnContentName: assetInfo.display_name,
              learnModuleName: assetInfo.learnModuleName,
              learnVideoLength: assetInfo.duration_seconds,
              modules: this.modules,
            });
          } catch (e) {
            console.log(e);
          }

          console.log(this.videoInfo);
          this.openDialogue = true;
        });
    },

    getAssetIcon(assetInfo) {
      return assetInfo.status < 100 ? '' : 'mdi-check';
    },

    goToPractice(
      id,
      moduleId,
      assetDisplayName = 'Practice writing questions',
    ) {
      const moduleName = this.modules.find(
        (m) => m.id === moduleId,
      ).display_name;
      this.setCourseInfoForMatomo({
        learnModuleName: moduleName,
        learnContentName: assetDisplayName,
        modules: this.modules,
      });
      trackLearnEvents.learnPracticeClick(this.currentUser, {
        ...this.courseInfoForMatotmo,
        learnModuleName: moduleName,
        learnContentName: assetDisplayName,
      });
      const practiceQuestionModuleFound =
        this.writingQuestionsExcerciseCourses.find(
          (excersise) =>
            excersise.moduleId === moduleId &&
            excersise.name === this.courseSlug,
        );
      const issueTreesModuleFound = this.issueTreesExcerciseCourses.find(
        (excersise) =>
          excersise.moduleId === moduleId && excersise.name === this.courseSlug,
      );
      const artOfSlidesModuleFound = this.artOfSlidesExcerciseCourses.find(
        (excersise) =>
          excersise.moduleId === moduleId && excersise.name === this.courseSlug,
      );
      if (practiceQuestionModuleFound) {
        this.$router.push({
          name: 'learn.practice.questions',
          params: { assetId: id, courseId: this.$route.params.id },
        });
      }
      if (issueTreesModuleFound) {
        this.$router.push({
          name: 'learn.practice.issueTrees',
          params: { assetId: id, courseId: this.$route.params.id },
        });
      }
      if (artOfSlidesModuleFound) {
        this.$router.push({
          name: 'learn.practice.artOfSlides',
          params: { assetId: id, courseId: this.$route.params.id },
        });
      }
    },

    goToQuiz(id, assetsList) {
      const moduleName =
        this.modules[
          this.activeModule.length === 1
            ? this.activeModule[0]
            : this.activeModule[this.activeModule.length - 1]
        ].display_name;
      const contentName = assetsList.find(
        (asset) => asset.id === id,
      ).display_name;
      this.setCourseInfoForMatomo({
        learnModuleName: moduleName,
        learnContentName: contentName,
        moduleStatus: `${
          this.courseDetails.modules.find(
            (mod) => mod.display_name === moduleName,
          ).status
        }%`,
        modules: this.modules,
      });
      MatomoAnalyticsHandler.trackQuizClick(
        this.courseInfoForMatotmo,
        this.currentUser.user,
      );
      trackLearnEvents.learnQuizClick(this.currentUser, {
        ...this.courseInfoForMatotmo,
        learnModuleName: moduleName,
        learnContentName: contentName,
      });
      if (assetsList !== undefined) {
        if (this.quizEnabled(assetsList)) {
          this.$router.push({
            name: 'learn.quiz',
            params: { assetId: id, courseId: this.$route.params.id },
          });
        }
      } else {
        this.$router.push({
          name: 'learn.quiz',
          params: { assetId: id, courseId: this.$route.params.id },
        });
      }
    },
  },
  async mounted() {
    if (this.activeAsset) {
      if (this.activeAsset.asset_type.toLowerCase() === 'video') {
        await this.getAssetInfo(this.activeAsset);
      } else if (this.activeAsset.asset_type.toLowerCase() === 'quiz') {
        this.goToQuiz(this.activeAsset.id);
      } else if (this.activeAsset.asset_type.toLowerCase() === 'exercise') {
        this.goToPractice(this.activeAsset.id, this.activeAsset.moduleId);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.module-section {
  margin-bottom: 20px;
  border-radius: 10px !important;
  box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%) !important;
  &__header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    &--title {
      font-family: 'Roboto', 'sans-serif';
      font-size: 23px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000;
      margin-bottom: 0;
      margin-left: 20px;
    }
    &--status {
      color: #000;
      font-size: 13px;
      padding: 14px 1px 12px;
    }
  }
  .module-content-section {
    padding-left: 20px;
  }
  ::v-deep.v-timeline-item__dot .v-timeline-item__inner-dot {
    height: 38px;
    margin: 0px;
    width: 38px;
  }

  .assests-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .assests-title-section {
      width: 80%;
      margin-right: 10px;
    }

    &__title {
      font-family: 'Roboto', 'sans-serif';
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000;
    }

    &__description {
      font-family: 'Roboto', 'sans-serif';
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000;
    }
  }

  .asset-video-duration-section {
    display: flex;
    flex-direction: row;
    margin-right: 10px;
    width: 20%;
    &__video {
      cursor: pointer;
      font-family: 'Roboto', 'sans-serif';
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      width: 60%;
      line-height: normal;
      letter-spacing: normal;
      color: #21a7e0;
      &:hover {
        text-decoration: none;
      }
    }
    &__duration {
      font-family: 'Roboto', 'sans-serif';
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      width: 40%;
      line-height: normal;
      letter-spacing: normal;
      color: #7b7b7b;
    }
  }
  .disabled {
    color: #b6b6b6;
    pointer-events: none;
  }

  .module-wrapper * {
    font-family: 'Roboto', 'sans-serif' !important;
  }

  ::v-deep .v-timeline-item-custom {
    .v-timeline-item__dot {
      box-shadow: none !important;
    }
    .v-timeline-item__inner-dot {
      background-color: unset !important;
      border: solid 2px #21a7e0;
      box-shadow: unset !important;
    }
  }
  ::v-deep .v-expansion-panel-content > .v-expansion-panel-content__wrap {
    padding-bottom: 0;
  }
}
::v-deep.v-expansion-panel:not(:first-child)::after {
  border-top: none !important;
}
::v-deep.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)
  > .v-expansion-panel--active {
  border-radius: 10px !important;
}
.first {
  .v-timeline:before {
    height: 50%;
    top: 50%;
  }
}
.last {
  .v-timeline:before {
    height: 50%;
    bottom: 50%;
  }
}
</style>
