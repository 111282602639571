<template>
  <div class="paymentMethodsContainer">
    <v-expansion-panels class="paymentExpansionPanels">
      <v-expansion-panel @click="trackUserClick()">
        <v-expansion-panel-header>
          <div>
            <NewFeatureBadge
              module-name="payments"
              :feature-id="'payments_settings_id'"
              :offsetX="'-4'"
              :offsetY="'8'"
              :size="'large'"
            >
              {{ $t('profile.paymentMethods') }}
            </NewFeatureBadge>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div
            class="paymentCardsContainer"
            :style="{ 'grid-template-columns': frames }"
          >
            <div
              v-for="(card, i) in getPaymentMethods"
              :key="i"
              class="cardLayout"
            >
              <PaymentMethodCard
                :card="card"
                :key="i"
                :noCardsPresent="noCardsPresent"
                :actionsNeeded="true"
                :currentScreenWidth="screenWidth"
              />
            </div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-snackbar
      v-model="snackBarStatus"
      :timeout="3000"
      :max-width="600"
      :min-height="50"
      multi-line
      @input="(e) => handleSnackbarInput(e)"
    >
      <template #default>
        <div class="snackTextAndTryAgain">
          <div class="snackBarText">{{ getSnackbarText }}</div>
          <span
            v-if="
              getSnackbarAction &&
              getSnackbarAction.type &&
              getSnackbarAction.type === 'Try again'
            "
            class="tryAgain"
            @click="handleTryAgain(getSnackbarAction)"
          >
            {{ $t('common.tryAgain') }}
          </span>
        </div>
        <v-icon small @click="handleSnackbarClose">{{ 'mdi-close' }}</v-icon>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { getSavedPaymentMethods } from '../../../../API/payment-api';
import PaymentMethodCard from './PaymentMethodCard.vue';
import AddPaymentMethodModal from './AddPaymentMethodModal.vue';
import NewFeatureBadge from '../../../common/NewFeatureBadge.vue';
import { trackProfileEvents } from '../../../common/Analytics/ProfileEvents';

export default {
  name: 'PaymentMethods',
  components: {
    PaymentMethodCard,
    NewFeatureBadge,
  },
  data() {
    return {
      frames: '1fr 1fr 1fr',
      snackBarStatus: false,
      screenWidth: 0,
    };
  },
  props: {},
  watch: {
    getSnackbar(val) {
      this.snackBarStatus = val;
    },
  },
  computed: {
    ...mapState('users', ['currentUser']),
    ...mapGetters('paymentMethods', [
      'getPaymentMethods',
      'getSnackbar',
      'getSnackbarText',
      'getSnackbarAction',
    ]),

    noCardsPresent() {
      return this.getPaymentMethods.length === 1;
    },
  },
  methods: {
    ...mapActions('paymentMethods', ['setPaymentMethods', 'setSnackbar']),
    trackUserClick() {
      trackProfileEvents.profileAdminSettingsPaymentMethodClick(
        this.currentUser,
        {},
      );
    },
    getItemsInScreen() {
      if (window.innerWidth <= 1020) {
        this.frames = '1fr';
      } else if (window.innerWidth > 1020 && window.innerWidth <= 1400) {
        this.frames = '1fr 1fr';
      } else if (window.innerWidth > 1400 && window.innerWidth <= 1900) {
        this.frames = '1fr 1fr 1fr';
      } else {
        this.frames = '1fr 1fr 1fr 1fr';
      }
      this.screenWidth = window.innerWidth;
    },

    handleSnackbarClose() {
      this.setSnackbar({
        status: false,
        text: '',
        action: null,
      });
    },

    handleTryAgain(action) {
      this.setSnackbar({
        status: false,
        text: '',
        action: null,
      });
      this.$modal.show(
        AddPaymentMethodModal,
        {
          noCardsPresent: action.data.noCardsPresent,
          methodMode: action.data.methodMode,
          card: action.data.card,
        },
        {
          name: 'AddPaymentMethodModal',
          width: '600px',
          height: 'auto',
          // top: '300px !important',
          styles: {
            // top: '300px !important',
            'max-height': '80%',
            'max-width': '800px',
            overflow: 'auto',
            'border-radius': '12px',
          },
          clickToClose: true,
        },
      );
      trackProfileEvents.profileAdminSettingsPaymentsErrorPopupTryAgain(
        this.currentUser,
        {},
      );
    },

    handleSnackbarInput(e) {
      if (!e) {
        this.setSnackbar({
          status: false,
          text: '',
          action: null,
        });
      }
    },
  },
  mounted() {
    getSavedPaymentMethods()
      .then((resp) => {
        if (resp?.data && resp?.data.length) {
          const cardsInfo = resp.data.map((card) => ({
            ...card,
          }));
          this.setPaymentMethods(cardsInfo);
        } else {
          this.setPaymentMethods([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    this.getItemsInScreen();
    window.addEventListener('resize', this.getItemsInScreen);
  },
  destroyed() {
    this.setPaymentMethods([]);
    window.removeEventListener('resize', this.getItemsInScreen);
  },
};
</script>

<style lang="scss" scoped>
.paymentMethodsContainer {
  box-shadow: 0px 1px 0px rgb(0 0 0 / 20%);
  margin-bottom: 36px;
  padding-bottom: 21px;
  .paymentExpansionPanels {
    ::v-deep .v-expansion-panel {
      &:before {
        box-shadow: none !important;
      }
      .v-expansion-panel-header {
        padding: 0;
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
      }
      .v-expansion-panel-content {
        width: 100%;
        .v-expansion-panel-content__wrap {
          width: 100%;
          flex: none !important;
          padding: 6px 2px 16px 2px;
          .paymentCardsContainer {
            width: 100%;
            display: grid;
            grid-column-gap: 64px;
            grid-row-gap: 32px;
            .cardLayout {
              // width: 400px;
            }
          }
        }
      }
    }
  }
  ::v-deep .v-snack {
    .v-snack__wrapper {
      .v-snack__content {
        width: 100% !important;
        display: flex !important;
        justify-content: space-between !important;
        padding: 14px 2px 14px 16px !important;
        .snackTextAndTryAgain {
          display: flex;
          margin-right: 16px;
          .snackBarText {
            color: white;
          }
          .tryAgain {
            cursor: pointer;
            padding: 0px 2px;
            color: #56c1e7;
          }
        }
        .v-icon {
          cursor: pointer;
        }
      }
    }
  }
}
</style>
