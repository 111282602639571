<template>
  <div class="us-table">
    <div class="us-table__header">
      <h3 class="us-table__heading">
        {{ $t('profile.metricDetail.usageStatistics') }}
      </h3>
      <v-tooltip right max-width="250" content-class="tooltip-content" fixed>
        <template v-slot:activator="{ on, attrs }">
          <v-icon class="icon-title" v-bind="attrs" v-on="on">
            mdi-information-outline
          </v-icon>
        </template>
        <span>
          {{ $t('profile.metricDetail.detailedViewOfUsageStatistics') }}
        </span>
      </v-tooltip>
      <v-tooltip right max-width="200" content-class="tooltip-content" fixed>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            color="primary"
            icon
            @click="downloadCSV()"
          >
            <v-icon>mdi-download-outline</v-icon>
          </v-btn>
        </template>
        <span>
          {{ $t('profile.metricDetail.usageStatisticsCsv') }}
        </span>
      </v-tooltip>
    </div>
    <v-card :class="getHeaders.length > 8 ? 'fullTable' : 'smallTable'" rounded>
      <v-data-table
        :headers="getHeaders"
        :items="teamstats"
        :search="teamStatsSearch"
        :no-results-text="$t('build.step1.storylineFieldNoResult')"
        :items-per-page="10"
        class="us-table__tbl elevation-1"
        :item-class="team_stats_row_classes"
        :loading="loading"
        :loading-text="'Loading users...'"
        :footer-props="{ 'items-per-page-options': [10, 25, 50, 100] }"
        :custom-filter="customSearch"
      >
        <template v-slot:[`header`]="{}">
          <thead>
            <tr>
              <th
                v-for="(group, groupIndex) in allHeaders"
                :key="`header_${groupIndex}`"
                :colspan="getColspanLength(group)"
                :class="groupIndex === 0 ? 'fixed-column' : ''"
              >
                {{ group.text }}
              </th>
            </tr>
          </thead>
        </template>
        <template v-slot:top>
          <v-toolbar flat>
            <div class="us-table__toolbar">
              <v-text-field
                v-model="teamStatsSearch"
                append-icon="mdi-magnify"
                single-line
                hide-details
                :placeholder="$t('profile.searchByEmailOrFirstname')"
                class="admin-table-search-input"
                clearable
                :clear-icon="'mdi-close'"
                @keyup.stop.native="handleTeamStatsSearch($event)"
              />
              <TimelineFilter
                v-model="range"
                @change="fetchUsageStatistics()"
                origin="UsageStatistics"
              />
            </div>
          </v-toolbar>
        </template>
        <template v-slot:[`header.slidesGenerated`]="{ header }">
          <NewFeatureBadge
            :moduleName="'generate'"
            :featureId="'generate_id'"
            size="medium"
            :offsetX="'0'"
            :offsetY="'0'"
          >
            {{ header.text }}
          </NewFeatureBadge>
        </template>
        <template v-slot:[`header.userUploadedSlides`]="{ header }">
          <!-- {{ `header.complySlides` }} -->
          <NewFeatureBadge
            module-name="upload"
            :feature-id="'upload_landing_id'"
            size="medium"
            :offsetX="'10'"
            :offsetY="'0'"
          >
            {{ header.text }}
          </NewFeatureBadge>
        </template>
        <template v-slot:[`header.userUploadedPrezentation`]="{ header }">
          <NewFeatureBadge
            :moduleName="'generate'"
            :featureId="'generate_id'"
            size="medium"
            :offsetX="'0'"
            :offsetY="'0'"
          >
            {{ header.text }}
          </NewFeatureBadge>
        </template>
        <template v-slot:[`header.complySlides`]="{ header }">
          <NewFeatureBadge
            :moduleName="'comply'"
            :featureId="'comply_id'"
            size="medium"
            :offsetX="'13'"
            :offsetY="'0'"
          >
            {{ header.text }}
          </NewFeatureBadge>
        </template>
        <template v-slot:[`header.prezentationsGenerated`]="{ header }">
          <NewFeatureBadge
            :moduleName="'generate'"
            :featureId="'generate_id'"
            size="medium"
            :offsetX="'0'"
            :offsetY="'0'"
          >
            {{ header.text }}
          </NewFeatureBadge>
        </template>
        <template v-slot:[`header.prezsSynthesized`]="{ header }">
          <NewFeatureBadge
            :moduleName="'synthesis'"
            :featureId="'synthesis_id'"
            size="medium"
            :offsetX="'10'"
            :offsetY="'0'"
          >
            {{ header.text }}
          </NewFeatureBadge>
        </template>
        <template v-slot:[`header.slidesRedesigned`]="{ header }">
          <NewFeatureBadge
            :moduleName="'redesign'"
            :featureId="'redesign_id'"
            size="medium"
            :offsetX="'10'"
            :offsetY="'0'"
          >
            {{ header.text }}
          </NewFeatureBadge>
        </template>

        <template v-slot:[`item.name`]="{ item }">
          <AudienceItem
            :firstName="item.firstName"
            :lastName="item.lastName"
            :profileImgS3Path="item.profileImageUrl || ''"
            :email="item.id"
            :status="item.active ? '' : 'Disabled'"
          />
        </template>
        <template v-slot:[`item.slide`]="{ item }">
          {{ item.slide | formatNumber }}
        </template>
        <template v-slot:[`item.slidesGenerated`]="{ item }">
          {{ item.slidesGenerated | formatNumber }}
        </template>
        <template v-slot:[`item.complySlides`]="{ item }">
          {{ item.complySlides | formatNumber }}
        </template>
        <template v-slot:[`item.prezentationsGenerated`]="{ item }">
          {{ item.prezentationsGenerated | formatNumber }}
        </template>
        <template v-slot:[`item.userUploadedSlides`]="{ item }">
          {{ item.userUploadedSlides | formatNumber }}
        </template>
        <template v-slot:[`item.searches`]="{ item }">
          {{ item.searches | formatNumber }}
        </template>
        <template v-slot:[`item.learnModulesCompleted`]="{ item }">
          {{ item.learnModulesCompleted | formatNumber }}
        </template>
        <template v-slot:[`item.prezsSynthesized`]="{ item }">
          {{ item.prezsSynthesized | formatNumber }}
        </template>
        <template v-slot:[`item.slidesRedesigned`]="{ item }">
          {{ item.slidesRedesigned | formatNumber }}
        </template>
        <template v-slot:[`item.knowDownloads`]="{ item }">
          {{ item.knowDownloads | formatNumber }}
        </template>
        <template v-slot:[`item.bpeDownloadedCount`]="{ item }">
          {{ item.bpeDownloadedCount | formatNumber }}
        </template>
        <template v-slot:[`item.prezBuilt`]="{ item }">
          {{ item.prezBuilt | formatNumber }}
        </template>
        <template v-slot:[`item.userUploadedPrezentation`]="{ item }">
          {{ item.userUploadedPrezentation | formatNumber }}
        </template>
        <template v-slot:[`item.fingerprintStats`]="{ item }">
          {{ item.fingerprintStats | formatNumber }}
        </template>
        <template v-slot:[`item.themeRequests`]="{ item }">
          {{ item.themeRequests | formatNumber }}
        </template>
        <template v-slot:[`body.append`]>
          <tr class="footer-total">
            <td class="us-table__cell-sticky">Total</td>
            <td>{{ sumField['searches'].toLocaleString() }}</td>
            <td>{{ sumField['slide'].toLocaleString() }}</td>
            <td v-if="isGenerateEnabled">
              {{ sumField['slidesGenerated'].toLocaleString() }}
            </td>
            <td>{{ sumField['prezBuilt'].toLocaleString() }}</td>
            <td v-if="isPrezentationUploadEnabled">
              {{ sumField['userUploadedSlides'].toLocaleString() }}
            </td>
            <td v-if="isRedesignEnabled">
              {{ sumField['slidesRedesigned'].toLocaleString() }}
            </td>
            <td v-if="isSynthesisEnabled">
              {{ sumField['prezsSynthesized'].toLocaleString() }}
            </td>
            <td v-if="isComplyEnabledWithAccess">
              {{ sumField['complySlides'].toLocaleString() }}
            </td>
            <td>{{ sumField['themeRequests'].toLocaleString() }}</td>
            <td v-if="isKnowEnabled">
              {{ sumField['knowDownloads'].toLocaleString() }}
            </td>
            <td v-if="isKnowEnabled">
              {{ sumField['bpeDownloadedCount'].toLocaleString() }}
            </td>
            <td>{{ sumField['fingerprintStats'].toLocaleString() }}</td>
            <td>
              {{ sumField['learnModulesCompleted'].toLocaleString() }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import { mapGetters, mapState } from 'vuex';
import csvDownload from 'json-to-csv-export';
import AudienceItem from '../../../common/AudienceItem.vue';
import { trackProfileEvents } from '../../../common/Analytics/ProfileEvents';
import {
  TD_USAGE_STATISTICS,
  TD_PLACEMENT,
  TD_QUERY,
} from '../../../common/Analytics/MatomoTrackingDataHelper';
import { getLocalisedNumberInString } from '../../../utils';
import { getTeamUsageStatistics } from '../../../../utils/api-helper';
import TimelineFilter from './TimelineFilter.vue';
import NewFeatureBadge from '../../../common/NewFeatureBadge.vue';

export default {
  name: 'UsageStatistics',
  components: {
    AudienceItem,
    TimelineFilter,
    NewFeatureBadge,
  },
  data() {
    return {
      headers: [],
      teamstats: [],
      teamstatsFiltered: [],
      teamStatsSearch: '',
      loading: false,
      data: [],
      range: { from: null, to: null },
      allHeaders: [],
    };
  },
  filters: {
    // eslint-disable-next-line arrow-body-style
    formatNumber: getLocalisedNumberInString,
  },
  computed: {
    ...mapState('users', ['currentUser']),
    ...mapGetters('users', ['isKnowEnabled', 'isPrezentationUploadEnabled']),
    isAdmin() {
      return this.currentUser.user.role.toLowerCase() === 'admin';
    },
    getHeaders() {
      const headersForAdminOrUser = this.isAdmin
        ? this.headers.filter((item) => item.admin)
        : this.headers.filter((item) => item.user);
      return headersForAdminOrUser;
    },
    sumField() {
      // sum data in give key (property)
      const keys = [
        'searches',
        'viewed',
        'prezBuilt',
        'userUploadedPrezentation',
        'slide',
        'slidesGenerated',
        'complySlides',
        'prezsSynthesized',
        'slidesRedesigned',
        'prezentationsGenerated',
        'userUploadedSlides',
        'learnModulesCompleted',
        'knowDownloads',
        'bpeDownloadedCount',
        'fingerprintStats',
        'themeRequests',
      ];
      return keys.reduce(
        (prev, current) => ({
          ...prev,
          [current]: (this.teamStatsSearch
            ? this.teamstatsFiltered
            : this.teamstats
          ).reduce((a, b) => {
            const num = b[current];
            return parseInt(a, 10) + (parseInt(num, 10) || 0);
          }, 0),
        }),
        {},
      );
    },
    isGenerateEnabled() {
      const genFeature = this.currentUser?.features?.find(
        (itm) => itm.feature_name === 'slide-generate',
      );
      return (
        (genFeature &&
          genFeature.enabled &&
          genFeature.access_level &&
          genFeature.access_level === 'full_access') ||
        (genFeature &&
          genFeature.access_level &&
          (genFeature.access_level === 'limited_access' ||
            genFeature.access_level === 'restricted_access'))
      );
    },
    isComplyEnabledWithAccess() {
      const complyFeature = this.currentUser?.features?.find(
        (itm) => itm.feature_name === 'comply',
      );
      return (
        (complyFeature &&
          complyFeature.enabled &&
          complyFeature.access_level &&
          complyFeature.access_level === 'full_access') ||
        (complyFeature &&
          complyFeature.access_level &&
          (complyFeature.access_level === 'limited_access' ||
            complyFeature.access_level === 'restricted_access'))
      );
    },
    isRedesignEnabled() {
      const redesignFeature = this.currentUser?.features?.find(
        (itm) => itm.feature_name === 'redesign',
      );
      return (
        (redesignFeature &&
          redesignFeature.enabled &&
          redesignFeature.access_level &&
          redesignFeature.access_level === 'full_access') ||
        (redesignFeature &&
          redesignFeature.access_level &&
          (redesignFeature.access_level === 'limited_access' ||
            redesignFeature.access_level === 'restricted_access'))
      );
    },
    isSynthesisEnabled() {
      const synFeature = this.currentUser?.features?.find(
        (itm) => itm.feature_name === 'synthesis',
      );
      return (
        (synFeature &&
          synFeature.enabled &&
          synFeature.access_level &&
          synFeature.access_level === 'full_access') ||
        (synFeature &&
          synFeature.access_level &&
          (synFeature.access_level === 'limited_access' ||
            synFeature.access_level === 'restricted_access'))
      );
    },
  },
  watch: {
    teamStatsSearch(val) {
      if (val) {
        this.teamstatsFiltered = this.teamstats.filter((teamstat) => {
          if (
            typeof teamstat.id === 'string' &&
            teamstat.id.toString().toLowerCase().indexOf(val.toLowerCase()) > -1
          ) {
            return true;
          }
          for (const header of this.headers) {
            if (
              (typeof teamstat[header.value] === 'string' ||
                typeof teamstat[header.value] === 'number') &&
              teamstat[header.value]
                .toString()
                .toLowerCase()
                .indexOf(val.toLowerCase()) > -1
            ) {
              return true;
            }
          }
          return false;
        });
      } else {
        this.teamstatsFiltered = this.teamstats;
      }
    },
    // 'currentUser.features': function () {
    //   this.getAllHeaders();
    // },
  },
  methods: {
    customSearch(value, search, item) {
      if (value)
        return Object.values(item).some(
          (v) => v && v.toString().toLowerCase().includes(search.toLowerCase()),
        );
      return false;
    },
    getColspanLength(val) {
      return val.columns.filter((item) => Object.keys(item).length > 0).length;
    },
    getAllAvailableHeaders() {
      const allAvailableHeaders = [
        {
          text: '',
          columns: [
            {
              text: 'Name',
              sortable: true,
              value: 'name',
              class: '',
              admin: true,
              user: true,
            },
          ],
          class: '',
          admin: true,
          user: true,
        },
        {
          text: 'Create',
          columns: [
            {
              text: 'Slides Searched',
              align: 'center',
              sortable: true,
              value: 'searches',
              class: '',
              admin: true,
              user: true,
            },
            {
              text: 'Slides Downloaded',
              align: 'center',
              value: 'slide',
              sortable: true,
              class: '',
              admin: true,
              user: true,
            },
            this.isGenerateEnabled
              ? {
                  text: 'Slides Generated',
                  align: 'center',
                  value: 'slidesGenerated',
                  sortable: true,
                  class: '',
                  admin: true,
                  user: true,
                }
              : {},
            {
              text: 'Prezentations Built',
              align: 'center',
              value: 'prezBuilt',
              sortable: true,
              class: '',
              admin: true,
              user: true,
            },
            this.isPrezentationUploadEnabled
              ? {
                  text: 'Slides Uploaded',
                  align: 'center',
                  value: 'userUploadedSlides',
                  sortable: true,
                  class: '',
                  admin: true,
                  user: true,
                }
              : {},
          ],
          class: '',
          admin: true,
          user: true,
        },
        this.isSynthesisEnabled ||
        this.isComplyEnabledWithAccess ||
        this.isRedesignEnabled
          ? {
              text: 'Transform',
              columns: [
                this.isRedesignEnabled
                  ? {
                      text: 'Slides Redesigned',
                      align: 'center',
                      value: 'slidesRedesigned',
                      sortable: true,
                      class: '',
                      admin: true,
                      user: true,
                    }
                  : {},
                this.isSynthesisEnabled
                  ? {
                      text: 'Prezentations Synthesized',
                      align: 'center',
                      value: 'prezsSynthesized',
                      sortable: true,
                      class: '',
                      admin: true,
                      user: true,
                    }
                  : {},
                this.isComplyEnabledWithAccess
                  ? {
                      text: 'Slides Templates Converted',
                      align: 'center',
                      value: 'complySlides',
                      sortable: true,
                      class: '',
                      admin: true,
                      user: true,
                    }
                  : {},
              ],
              class: '',
              admin: true,
              user: true,
            }
          : {},
        {
          text: 'Enable',
          columns: [
            {
              text: 'Template Requests Sent',
              align: 'center',
              value: 'themeRequests',
              sortable: true,
              class: '',
              admin: true,
              user: true,
            },
            this.isKnowEnabled
              ? {
                  text: 'Best Practice Slides Downloaded',
                  align: 'center',
                  value: 'knowDownloads',
                  sortable: true,
                  class: '',
                  admin: true,
                  user: true,
                }
              : {},
            this.isKnowEnabled
              ? {
                  text: 'Best Practice Decks Downloaded',
                  align: 'center',
                  value: 'bpeDownloadedCount',
                  sortable: true,
                  class: '',
                  admin: true,
                  user: true,
                }
              : {},
          ],
          class: '',
          admin: true,
          user: true,
        },
        {
          text: 'Learn',
          columns: [
            {
              text: 'Fingerprints Created or Viewed',
              align: 'center',
              value: 'fingerprintStats',
              sortable: true,
              class: '',
              admin: true,
              user: true,
            },

            {
              text: 'Learn Steps Completed',
              align: 'center',
              value: 'learnModulesCompleted',
              sortable: true,
              class: '',
              admin: true,
              user: true,
            },
          ],
          class: '',
          admin: true,
          user: true,
        },
      ];
      return allAvailableHeaders.filter((item) => Object.keys(item).length > 0);
    },
    async fetchUsageStatistics() {
      this.loading = true;
      const response = await getTeamUsageStatistics(
        this.range.from,
        this.range.to,
      );
      this.data = response.data;
      this.setUsageStatistics();
      this.loading = false;
    },
    // eslint-disable-next-line func-names
    handleTeamStatsSearch: debounce(function () {
      trackProfileEvents.profileMetricsSearch(this.currentUser, {
        [TD_QUERY]: this.teamStatsSearch,
      });
    }, 500),
    team_stats_row_classes(item) {
      if (item.active === false) {
        return 'disabled-row';
      }
      return '';
    },
    async setUsageStatistics() {
      this.teamstats = [];
      this.data.forEach(async (user) => {
        const userstat = {
          name: user.name,
          firstName: user.firstName,
          lastName: user.lastName,
          id: user.id,
          slide: user.downloads || 0,
          slidesGenerated: user.slidesGenerated || 0,
          complySlides: user.complySlides || 0,
          prezsSynthesized: user.prezsSynthesized || 0,
          slidesRedesigned: user.slidesRedesigned || 0,
          prezentationsGenerated: user.prezentationsGenerated || 0,
          userUploadedSlides: user.userUploadedSlides || 0,
          searches: Number(user.searches) + Number(user.knowSearches) || 0,
          profileImageUrl: user.profileImage || '',
          learnModulesCompleted: Number(user.learningSteps) || 0,
          knowDownloads: Number(user.knowDownloads) || 0,
          bpeDownloadedCount: Number(user.bpeDownloadedCount) || 0,
          prezBuilt: Number(user.prezentationCreated) || 0,
          userUploadedPrezentation: Number(user.userUploadedPrezentation) || 0,
          fingerprintStats:
            Number(user.fingerprintCreated) + Number(user.fingerprintViewed) ||
            0,
          themeRequests: Number(user.themeRequests) || 0,
          active: user.active,
        };
        if (user.role !== 'TrialUser') {
          this.teamstats.push(userstat);
        }
      });
    },
    downloadCSV() {
      // eslint-disable-next-line camelcase, object-curly-newline
      const teamstats = this.teamStatsSearch
        ? this.teamstatsFiltered
        : this.teamstats;
      const data = teamstats.map(
        ({
          id,
          name,
          searches,
          slide,
          slidesGenerated,
          prezBuilt,
          userUploadedSlides,
          slidesRedesigned,
          prezsSynthesized,
          complySlides,
          themeRequests,
          knowDownloads,
          bpeDownloadedCount,
          fingerprintStats,
          learnModulesCompleted,
        }) => [
          id,
          name,
          searches,
          slide,
          ...(this.isGenerateEnabled ? [slidesGenerated] : []),
          prezBuilt,
          ...(this.isPrezentationUploadEnabled ? [userUploadedSlides] : []),
          ...(this.isRedesignEnabled ? [slidesRedesigned] : []),
          ...(this.isSynthesisEnabled ? [prezsSynthesized] : []),
          ...(this.isComplyEnabledWithAccess ? [complySlides] : []),
          themeRequests,
          ...(this.isKnowEnabled ? [knowDownloads] : []),
          ...(this.isKnowEnabled ? [bpeDownloadedCount] : []),
          fingerprintStats,
          learnModulesCompleted,
        ],
      );
      data.push([
        'Total',
        '',
        this.sumField.searches.toLocaleString(),
        this.sumField.slide.toLocaleString(),
        ...(this.isGenerateEnabled
          ? [this.sumField.slidesGenerated.toLocaleString()]
          : []),
        this.sumField.prezBuilt.toLocaleString(),
        ...(this.isPrezentationUploadEnabled
          ? [this.sumField.userUploadedSlides.toLocaleString()]
          : []),
        ...(this.isRedesignEnabled
          ? [this.sumField.slidesRedesigned.toLocaleString()]
          : []),
        ...(this.isSynthesisEnabled
          ? [this.sumField.prezsSynthesized.toLocaleString()]
          : []),
        ...(this.isComplyEnabledWithAccess
          ? [this.sumField.complySlides.toLocaleString()]
          : []),
        this.sumField.themeRequests.toLocaleString(),
        ...(this.isKnowEnabled
          ? [this.sumField.knowDownloads.toLocaleString()]
          : []),
        ...(this.isKnowEnabled
          ? [this.sumField.bpeDownloadedCount.toLocaleString()]
          : []),
        this.sumField.fingerprintStats.toLocaleString(),
        this.sumField.learnModulesCompleted.toLocaleString(),
      ]);
      const headers = this.headers
        .filter((header) => {
          if (header.value === 'knowDownloads' && !this.isKnowEnabled) {
            return false;
          }
          if (header.value === 'bpeDownloadedCount' && !this.isKnowEnabled) {
            return false;
          }
          if (
            header.value === 'userUploadedPrezentation' &&
            !this.isPrezentationUploadEnabled
          ) {
            return false;
          }
          if (
            header.value === 'userUploadedSlides' &&
            !this.isPrezentationUploadEnabled
          ) {
            return false;
          }
          if (header.value === 'slidesGenerated' && !this.isGenerateEnabled) {
            return false;
          }
          if (
            header.value === 'complySlides' &&
            !this.isComplyEnabledWithAccess
          ) {
            return false;
          }
          if (header.value === 'prezsSynthesized' && !this.isSynthesisEnabled) {
            return false;
          }
          if (header.value === 'slidesRedesigned' && !this.isRedesignEnabled) {
            return false;
          }
          return true;
        })
        .map((header) => header.text);
      csvDownload({
        headers: ['id', ...headers],
        data,
        filename: 'Usage Statistics',
        delimiter: ',',
      });
      trackProfileEvents.profileMetricsDownload(this.currentUser, {
        [TD_PLACEMENT]: TD_USAGE_STATISTICS,
      });
    },
    getAvailableHeaders() {
      const allHeaders = [
        {
          text: 'Name',
          sortable: true,
          value: 'name',
          class: '',
          admin: true,
          user: true,
        },
        {
          text: 'Slides Searched',
          align: 'center',
          sortable: true,
          value: 'searches',
          class: '',
          admin: true,
          user: true,
        },
        {
          text: 'Slides Downloaded',
          align: 'center',
          value: 'slide',
          sortable: true,
          class: '',
          admin: true,
          user: true,
        },
        this.isGenerateEnabled
          ? {
              text: 'Slides Generated',
              align: 'center',
              value: 'slidesGenerated',
              sortable: true,
              class: '',
              admin: true,
              user: true,
            }
          : {},
        {
          text: 'Prezentations Built',
          align: 'center',
          value: 'prezBuilt',
          sortable: true,
          class: '',
          admin: true,
          user: true,
        },
        this.isPrezentationUploadEnabled
          ? {
              text: 'Slides Uploaded',
              align: 'center',
              value: 'userUploadedSlides',
              sortable: true,
              class: '',
              admin: true,
              user: true,
            }
          : {},
        this.isRedesignEnabled
          ? {
              text: 'Slides Redesigned',
              align: 'center',
              value: 'slidesRedesigned',
              sortable: true,
              class: '',
              admin: true,
              user: true,
            }
          : {},
        this.isSynthesisEnabled
          ? {
              text: 'Prezentations Synthesized',
              align: 'center',
              value: 'prezsSynthesized',
              sortable: true,
              class: '',
              admin: true,
              user: true,
            }
          : {},
        this.isComplyEnabledWithAccess
          ? {
              text: 'Slides Templates Converted',
              align: 'center',
              value: 'complySlides',
              sortable: true,
              class: '',
              admin: true,
              user: true,
            }
          : {},
        {
          text: 'Template Requests Sent',
          align: 'center',
          value: 'themeRequests',
          sortable: true,
          class: '',
          admin: true,
          user: true,
        },
        this.isKnowEnabled
          ? {
              text: 'Best Practice Slides Downloaded',
              align: 'center',
              value: 'knowDownloads',
              sortable: true,
              class: '',
              admin: true,
              user: true,
            }
          : {},
        this.isKnowEnabled
          ? {
              text: 'Best Practice Decks Downloaded',
              align: 'center',
              value: 'bpeDownloadedCount',
              sortable: true,
              class: '',
              admin: true,
              user: true,
            }
          : {},
        {
          text: 'Fingerprints Created or Viewed',
          align: 'center',
          value: 'fingerprintStats',
          sortable: true,
          class: '',
          admin: true,
          user: true,
        },

        {
          text: 'Learn Steps Completed',
          align: 'center',
          value: 'learnModulesCompleted',
          sortable: true,
          class: '',
          admin: true,
          user: true,
        },
      ];
      return allHeaders.filter((item) => Object.keys(item).length > 0);
    },
  },
  mounted() {
    this.headers = this.getAvailableHeaders();
    this.allHeaders = this.getAllAvailableHeaders();
    this.monthlyDownloads = 0;
    this.fetchUsageStatistics();
  },
};
</script>

<style lang="scss" scoped>
.us-table {
  &__header {
    display: flex;
    gap: 12px;
    text-align: left;
    margin: 0px 0px 16px 0px;
  }
  &__heading {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
  &__toolbar {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  &__cell-sticky {
    position: sticky;
    background-color: white;
    left: 0;
    z-index: 1;
  }
  &__tbl {
    ::v-deep .v-data-table__wrapper::-webkit-scrollbar {
      height: 7px;
    }
    ::v-deep .v-data-table__wrapper::-webkit-scrollbar-thumb {
      background-color: #b6b6b6;
    }
  }
}
.fixed-column {
  position: sticky;
  left: 0;
  // overflow-y: hidden;
  overflow: hidden;
  flex: 0 0 auto;
  background: white;
}
</style>
