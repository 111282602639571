<template>
  <div class="new-presentation-section">
    <div
      v-if="!loading"
      class="new-presentation-wrapper"
      :class="{ 'left-shift': templateSelector }"
    >
      <div
        :class="
          stepperValue === 1 ? 'stepper-wrapper-one' : 'stepper-wrapper-two'
        "
        id="stepper-header"
        :style="{
          'padding-top': `${isRightPanelOpen ? '21px' : '40px'}`,
          width: `${
            isRightPanelOpen || (stepperValue === 2 && isStoryLineGalleryOpen)
              ? '73%'
              : '100%'
          }`,
        }"
        v-if="
          !status.isProcessing &&
          !fullScreenMode &&
          !isFullScreenMode &&
          !isFromDetailView
        "
      >
        <div class="back-btn-container">
          <v-btn
            class="btn-back"
            v-if="stepperValue > 1 && stepperValue <= 2 && showStepper"
            @click="() => handleBack()"
          >
            <v-icon class="icon" color="primary">mdi-chevron-left</v-icon>
            <span>{{ $t('common.back') }}</span>
          </v-btn>
          <v-btn
            class="btn-back"
            v-if="previousRoute === 'search' && stepperValue === 1"
            @click="goToSearchLandingPage"
          >
            <v-icon class="icon" color="primary">mdi-chevron-left</v-icon>
            <span>{{ $t('slides.backToSearch') }}</span>
          </v-btn>
        </div>
        <div class="itemcenter">
          <v-stepper
            class="v-stepper__wrapper"
            v-if="showStepper"
            alt-labels
            v-model="stepperValue"
          >
            <v-stepper-header>
              <NewFeatureBadge
                module-name="release_2100"
                :feature-id="'release_2100'"
                :offsetX="'-235'"
                :offsetY="'-7'"
              >
              </NewFeatureBadge>
              <v-stepper-step
                step=""
                id="context-step"
                :complete="stepperValue > 1"
                :class="{
                  'active-step': stepperValue === 1,
                }"
              >
                {{ $t('build.steps[0]') }}
              </v-stepper-step>
              <v-divider />
              <v-stepper-step
                id="outline-step"
                step=""
                :complete="stepperValue > 2"
                :class="{
                  'active-step': stepperValue === 2,
                }"
                style="margin-bottom: 5px"
              >
                {{ $t('build.steps[1]') }}
              </v-stepper-step>
              <v-divider />
              <v-stepper-step
                step=""
                id="slides-step"
                :complete="stepperValue > 3"
                :class="{
                  'active-step': stepperValue === 3,
                }"
              >
                {{ $t('build.steps[2]') }}
              </v-stepper-step>
            </v-stepper-header>
          </v-stepper>
        </div>
        <div
          :class="`template-selector-button ${
            templateSelector ? 'template-selection-open' : ''
          }`"
        >
          <NewFeatureBadge
            v-if="!loading && stepperValue === 1 && !status.isProcessing"
            module-name="template_build"
            :feature-id="'template_build_id'"
            :offsetX="'10'"
            :offsetY="'0'"
            :size="'small'"
          >
            <div
              @click="toggleTemplatesMenu"
              class="expand-button"
              data-pendo-id="build-advance-icon"
            >
              <v-icon color="#21a7e0" class="expand-button-icon" alt>
                mdi-compare
              </v-icon>
              <v-tooltip
                bottom
                content-class="tooltip-content"
                fixed
                v-if="!templateSelector"
              >
                <template v-slot:activator="{ on, attrs }">
                  <span
                    class="expand-button-text primary-color"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ $t('build.step1.templateLabel') }}:
                    {{ getTemplateName }}
                  </span>
                </template>
                <span>{{ getTemplateName }}</span>
              </v-tooltip>
              <span
                class="expand-button-text primary-color"
                v-if="templateSelector"
              >
                {{ $t('build.step1.templateLabel') }}
              </span>
            </div>
          </NewFeatureBadge>
        </div>
      </div>
      <div
        :class="`define-context-wrapper ${
          status.isProcessing ? 'full-width' : ''
        } ${stepperValue === 1 ? 'first-stepper' : ''}`"
        v-if="stepperValue === 1 && showStepper"
      >
        <div v-if="!status.isProcessing" class="stepper-1">
          <div class="define-context-header">
            {{ $t('build.step1.title') }}
          </div>
          <div class="define-context-subheader">
            {{ $t('build.step1.virbage') }}
          </div>
          <div class="presentation-name-wrapper">
            <div class="presentation-name-label">
              {{ $t('build.step1.prezentationNameFieldLabel') }}
            </div>
            <v-text-field
              :placeholder="$t('build.step1.prezentationNameFieldPlaceholder')"
              v-model="result.presentationName"
              id="prezentation-name"
              :rules="maxLimit"
              @focus="handleStartEventTrigger"
              data-pendo-id="prezentation-name"
            />
          </div>
          <div class="primary-audience-wrapper">
            <div class="primary-audience-label">
              {{ $t('build.step1.audianceFieldLabel') }}
              <div>
                <v-tooltip
                  top
                  max-width="250"
                  content-class="tooltip-content"
                  fixed
                  open-delay="500"
                  close-delay="300"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="icon"
                      v-text="`mdi-information-outline`"
                    />
                  </template>
                  <span>
                    {{ $t('build.step1.audianceLabelTooltip') }}
                  </span>
                </v-tooltip>
              </div>
            </div>
            <AudienceSelectorTypesense
              ref="audienceSelector"
              v-model="result.primaryAudience"
              :isTextFieldStyle="true"
              class="no-padding"
              origin="Build"
              @change="handleAudienceSelect($event)"
              @focus="handleStartEventTrigger($event)"
              @add="handleAddAudience($event)"
              :placeholder="$t('build.step1.audianceFieldPlaceholder')"
            />
          </div>
          <div class="presentation-type-wrapper">
            <StorylineDropdown
              ref="storylineSelector"
              v-model="result.presentationType"
              :isTextFieldStyle="true"
              class="no-padding"
              origin="Build"
              :audienceStoryPref="audienceStoryPref"
              @change="handleStorylineSelect($event)"
              :label="$t('build.step1.storylineFieldLabel')"
              :isCustom="true"
              :placeholder="$t('build.step1.storylineFieldPlaceholder')"
              @focus="handleStartEventTrigger($event)"
              :handleCustomSelectClick="handleCustomSelectClick"
              :handleManageStorylines="handleManageStorylines"
              :disabled="!result.primaryAudience"
            />
          </div>
          <div class="action-wrapper" style="text-align: center">
            <v-btn
              v-if="stepperValue === 1"
              class="primary-button"
              color="#21a7e0"
              rounded
              width="135"
              height="48"
              @click="handleStepper(false, null, null, null, false, null)"
              :disabled="disableNextButton"
              id="next-btn"
              data-pendo-id="next-btn"
              style="text-transform: none !important"
            >
              {{ $t('common.next') }}
            </v-btn>
          </div>
        </div>
        <div v-else class="analyzing-area">
          <div class="analyzing-wrapper" v-if="status.waitingIndex === 1">
            <clip-loader
              :loading="status.waitingIndex === 1"
              :color="`#21a7e0`"
              :width="20"
              :height="20"
            />
            <div class="analyzing-wrapper-header">
              {{ $t('build.step1.waitingText1') }}
            </div>
            <div class="image-wrapper">
              <img src="/assets/img/new-presentation-analyze.svg" alt />
            </div>
          </div>
          <div class="analyzing-wrapper" v-if="status.waitingIndex === 2">
            <clip-loader
              :loading="status.waitingIndex === 2"
              :color="`#21a7e0`"
              :width="20"
              :height="20"
            />
            <div class="analyzing-wrapper-header">
              {{ $t('build.step1.waitingText2') }}
            </div>
            <div class="image-wrapper">
              <img src="/assets/img/apply-audience-preference.svg" alt />
            </div>
          </div>
          <div class="analyzing-wrapper" v-if="status.waitingIndex === 3">
            <clip-loader
              :loading="status.waitingIndex === 3"
              :color="`#21a7e0`"
              :width="20"
              :height="20"
            />
            <div class="analyzing-wrapper-header">
              {{ $t('build.step1.waitingText3') }}
            </div>
            <div class="image-wrapper">
              <img src="/assets/img/fine-tuning.svg" alt />
            </div>
          </div>
        </div>
      </div>
      <div
        :class="`define-context-wrapper ${
          status.isProcessing ? 'full-width' : ''
        }`"
        v-if="stepperValue === 2"
      >
        <div v-if="!status.isProcessing">
          <StorylineContainer
            :storylineData="result.presentationType"
            :presentationName="result.presentationName"
            :get-prez-details="getPrezDetails"
            :hiddenIdeas="hiddenIdeas"
            ref="storylineContainer"
            :totalSlides="totalSlides"
            :fingerprint="getFingerprint"
            @ideaSelected="itemSelected"
          />

          <div
            class="footer"
            :class="{
              galleryOpen:
                stepperValue === 2 &&
                !isStoryLineGalleryOpen &&
                !leftNavExpanded,
              expanded: leftNavExpanded,
            }"
          >
            <div class="footer-container">
              <div class="total-slides-text">
                {{ $t('build.step2.slidesCountText') }} {{ totalSlidesCount }}
                {{
                  totalSlidesCount > 1
                    ? $t('build.step2.slides')
                    : $t('build.step2.slide')
                }}
              </div>
              <div class="accept-outline">
                <v-btn
                  rounded
                  class="primary-button mb-3"
                  color="#21a7e0"
                  height="48"
                  @click="handleStepper(false, null, null, null, false, null)"
                  id="accept-outline-button"
                  data-pendo-id="accept-outline-button"
                  :disabled="disableAcceptStoryLine"
                >
                  {{ $t('build.step2.acceptStorylineBtn') }}
                </v-btn>
              </div>

              <div class="accept-outline">
                <v-btn
                  text
                  color="primary"
                  @click="handleAcceptAndSave"
                  class="accept-btn"
                  id="accept-and-save-outline-button"
                  :disabled="disableAcceptStoryLine"
                >
                  {{ $t('build.step2.acceptSaveStorylineBtn') }}
                </v-btn>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="analyzing-area">
          <div class="analyzing-wrapper" v-if="status.waitingIndex === 4">
            <clip-loader
              :loading="status.waitingIndex === 4"
              :color="`#21a7e0`"
              :width="20"
              :height="20"
            />
            <div class="analyzing-wrapper-header">
              {{ $t('build.step2.waitingText1') }}
            </div>
            <div class="image-wrapper">
              <img src="/assets/img/searching slide library.svg" alt />
            </div>
          </div>
          <div class="analyzing-wrapper" v-if="status.waitingIndex === 5">
            <clip-loader
              :loading="status.waitingIndex === 5"
              :color="`#21a7e0`"
              :width="20"
              :height="20"
            />
            <div class="analyzing-wrapper-header">
              {{ $t('build.step2.waitingText2') }}
            </div>
            <div class="image-wrapper">
              <img src="/assets/img/apply-audience-preference-2.svg" alt />
            </div>
          </div>
          <div class="analyzing-wrapper" v-if="status.waitingIndex === 6">
            <clip-loader
              :loading="status.waitingIndex === 6"
              :color="`#21a7e0`"
              :width="20"
              :height="20"
            />
            <div class="analyzing-wrapper-header">
              {{ $t('build.step2.waitingText3') }}
            </div>
            <div class="image-wrapper">
              <img src="/assets/img/fine-tuning-favorites-2.svg" alt />
            </div>
          </div>
        </div>
      </div>
      <div class="define-context-wrapper stepper-3" v-if="stepperValue >= 3">
        <!-- <PrezentationDetailComponent
          :style="{ 'margin-top': showUpgradeBanner ? '0px' : '-20px' }"
          :title="getDetailsTitle"
          :prezentation="finalPrezentation"
          :disableEditOutline="false"
          :isNew="true"
          :eventStart="eventStart"
          :eventStartAcceptAndSave="eventStartAcceptAndSave"
          :onEditStoryLine="onEditStoryLine"
          :isSaveStoryLine="isSaveStoryLine"
          :metaDataForMatomo="result"
          origin="build"
          v-if="isSlidesPresent"
          :setFullScreen="setFullScreen"
          ref="prezentationDetailNew"
        /> -->
        <PrezentationDetails
          :style="{ 'margin-top': showUpgradeBanner ? '0px' : '-20px' }"
          :prezentation="finalPrezentation"
          :disableEditOutline="false"
          :isNew="true"
          :eventStart="eventStart"
          :eventStartAcceptAndSave="eventStartAcceptAndSave"
          :onEditStoryLine="onEditStoryLine"
          :isSaveStoryLine="isSaveStoryLine"
          :metaDataForMatomo="result"
          origin="build"
          v-if="isSlidesPresent"
          :setFullScreen="setFullScreen"
          ref="prezentationDetailNew"
        />
        <div v-else class="no-slides-container">
          <img src="/assets/img/no-slides-found-for-template.svg" alt />
          <div class="font-weight-black mt-6">
            {{ $t('build.step3.noSlidesFound') }}
          </div>
          <div>{{ $t('build.step3.selectDifferentTemplateText') }}</div>
          <v-btn
            class="primary-button mt-2"
            rounded
            color="#21a7e0"
            height="48"
            min-width="134"
            @click="$router.push(`/profile?tab=templates`)"
          >
            {{ $t('build.step3.changeTemplateText') }}
          </v-btn>
        </div>
      </div>
    </div>
    <Loading v-else />
    <TemplateMenu
      v-if="!loading && stepperValue === 1 && templateSelector"
      class="template-selector-active"
      @handleClose="closeTemplateSelector"
    />
    <NoAccessModal
      v-if="showNoAccess"
      :noAccess="noAccess"
      :show="showNoAccess"
      :uuid="result.presentationType.uuid"
      :onClose="
        () => {
          showNoAccess = false;
          noAccess = false;
        }
      "
    />
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { mapActions, mapGetters, mapState } from 'vuex';
import { graphqlOperation } from 'aws-amplify';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import _ from 'lodash';
import Loading from '../../common/Loading.vue';
import TemplateMenu from './TemplateMenu.vue';
import API2 from '../../../utils/api';
import {
  getPrezentationByID,
  addSearchTerms,
  updateSearchTerms,
  createStorylines,
  updateStorylines,
  getUserSearchTerms,
  logSearchQuery,
} from '@/utils/api-helper';
import {
  // performAutoComplete,
  // performHybridSearch,
  performSearch,
} from '@/graphql/queries';

import DialogService from '../../../services/DialogService';
import ReminderDialog from '../MyPrezentation/ReminderDialog.vue';
import AddCollegueDetail from './AddCollegueDetail.vue';
import AcceptAndSaveDialog from './AcceptAndSaveDialog.vue';
// import PrezentationDetailComponent from '../../common/PrezentationDetailNew.vue';
import PrezentationDetails from '../PrezentationDetails/components/PrezentationDetails.vue';
import AudienceSelectorTypesense from '../../common/AudienceSelectorTypesense.vue';
import { AnalyticsHandler } from '../../common/Analytics/AnalyticsHandler';
import ideaGallerySearchItemTemplate from '../../common/ideaGallerySearchItemTemplate';
// import { slideDownloads } from '../../common/downloadStats.js';
import {
  DEDUCTIVE_PRINTS,
  INDUCTIVE_PRINTS,
  getAllPrezentationOutline,
} from './getPrezentationOutline.js';
import { getFileURL } from '@/utils/calyrex';
import { decoratePrezentation } from '@/store/modules/prezentations';
import { MatomoAnalyticsHandler } from '../../common/Analytics/MatomoAnalyticsHandler';
import usersApi from '../../../API/users-api';
import EventBus from '@/components/common/event-bus';
// import { trackSearchEvents } from '../../common/Analytics/SearchEvents';
import {
  // getOutlinesMerged,
  TD_AUD,
  TD_AUDIENCE_ORIGIN_NP,
  TD_IDEABEGIN,
  TD_IDEAEND,
  TD_IDEAMIDDLE,
  TD_ISDOWNLOAD,
  TD_LIST,
  // TD_NORESULTSFOUND,
  TD_NUMBEROFSLIDES,
  TD_PREZNAME,
  TD_PREZTYPE,
  // TD_QUERY,
  TD_VIEWTYPE,
  BUILD,
  getPrezData,
  TD_PREZENTATIONAUTHOR,
  TD_COMPANY_OF_AUD_TAILORED_FOR,
  NA,
  TD_SECTION_4,
  TD_SECTION_5,
  TD_QUERY,
  TD_PREZENTATION_COMPANY_SOURCE,
  TD_PREZENTATION_ORIGIN,
  // TD_COMPANY_OF_AUD_TAILORED_FOR,
} from '../../common/Analytics/MatomoTrackingDataHelper';

import { PrezentationViewTypes } from '../../../utils/constants';
import { trackBuildEvents } from '../../common/Analytics/BuildEvents';
import { trackPrezentationEvents } from '../../common/Analytics/PrezentationEvents';
import {
  getHiddenIdeas,
  // getFeatureUsage,
  getStorylineByID,
  // postFeatureUsage,
} from '../../../utils/api-helper';
import { AddNewAudienceModalProps } from '../AddNewAudience/AddNewAudienceModalProps';
import NewFeatureBadge from '../../common/NewFeatureBadge.vue';
import StorylineDropdown from '../../common/StorylineDropdown.vue';
import NoAccessModal from '../Storyline/NoAccessModal.vue';
import StorylineContainer from './BuildPrezentation/Storyline/StorylineContainer.vue';
import audienceApi from '../../../API/audience-api';
import { TypeSenseServer } from '../../../pages/components/TypeSenseInit';
import { GraphQLService } from '../../../services/GraphQLService';

// const handleDebounce = debounce((callback) => {
//   callback();
// }, 1000);

export default {
  name: 'NewPrezentation',
  mixins: [DialogService],
  components: {
    // draggable,
    Loading,
    AudienceSelectorTypesense,
    // PrezentationDetailComponent,
    PrezentationDetails,
    // VueSuggestions,
    TemplateMenu,
    NewFeatureBadge,
    NoAccessModal,
    ClipLoader,
    StorylineDropdown,
    StorylineContainer,
  },
  watch: {
    async stepperValue(val) {
      if (val === 1) {
        await this.getPrezentCustomStoryline();
      }
    },
    async startLoadingSlidesFlag(val) {
      if (this.$route.query?.outline_id && val) {
        this.loading = true;
        await this.buildForStorylines();
        this.loading = false;
      } else if (val && this.$route.query?.audience) {
        this.result.primaryAudience = {
          defaultAudience: this.$route.query.audience,
        };
      }
    },
    '$route.query': {
      async handler(newVal, oldVal) {
        if (newVal !== oldVal && newVal?.outline_id !== oldVal?.outline_id) {
          if (this.startLoadingSlidesFlag === true) {
            this.loading = true;
            await this.buildForStorylines();
            this.result.presentationName = null;
            this.stepperValue = 1;
            this.loading = false;
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      previousRoute: '',
      eventStart: null,
      eventStartAcceptAndSave: null,
      search: '',
      searchClicked: false,
      crossButton: false,
      templateSelector: false,
      isSearchColor: '',
      alreadySearchedIdeas: [],
      hiddenIdeas: [],
      fullName: '',
      finalPrezentation: {},
      isLoadingOutlineSuggestions: false,
      // listDownloads: [],
      countFirstTime: 0,
      fab: false,
      audienceStoryPref: 'all',
      slidesParam: '',
      ideaKey: 0,
      prezentation: null,
      ideaGallerySearchItemTemplate,
      newComments: 0,
      items: [],
      downloading: false,
      bkey: 0,
      componentKey: 0,
      members: ['Taylor', 'Charles Xavier'],
      back: 'common.backButton',
      viewMode: 'list',
      isCommentMode: false,
      commentContent: '',
      isCommentEdit: false,
      newCommentValue: '',
      currentItem: null,
      beginningItems: [],
      middleItems: [],
      endItems: [],
      allItems: [],
      idea: [],
      searchTerm: '',
      additionalInfoTitle: 'More Information',
      ideaSearch: [
        {
          name: 'Dependencies',
        },
        {
          name: 'People',
        },
        {
          name: 'Approach',
        },
        {
          name: 'Risk Assessment & Mitigation',
        },
        {
          name: 'What’s next',
        },
        {
          name: 'Assumptions',
        },
        {
          name: 'Challenges',
        },
        {
          name: 'Checklist',
        },
        {
          name: 'Issues',
        },
        {
          name: 'Data Sources',
        },
        {
          name: 'Strategy',
        },
      ],
      avatars: {
        director: 'lion.png',
        navigator: 'Wolf.png',
        scholar: 'Owl.png',
        performer: 'Peacock.png',
        architect: 'Horse.svg',
        surgeon: 'Eagle.png',
        scientist: 'Dolphin.png',
        producer: 'Bear.png',
      },
      ideaFiltered: [],
      addingItem: {},
      slides: [],
      controlOnStart: true,
      currentIndex: 0,
      stepperValue: 1,
      result: {
        id: '',
        name: '',
        presentationName: '',
        presentationType: {
          outline: null,
        },
        primaryAudience: null,
      },
      status: {
        isProcessing: false,
        waitingIndex: 0,
      },
      comments: [],
      loading: true,
      associatedUsers: [],
      presentTypeList: [],
      privatePrezentTypeList: [],
      userAssociatedCompanies: [],
      isSlidesPresent: false,
      viewTypes: PrezentationViewTypes,
      isSaveStoryLine: false,
      showNoAccess: false,
      noAccess: false,
      totalSlidesCount: 0,
      leftNavExpanded: false,
      fullScreenMode: false,
      inductivePrezentCustomStoryline: {},
      deductivePrezentCustomStoryline: {},
    };
  },
  computed: {
    ...mapState('users', [
      'currentTheme',
      'showUpgradeBanner',
      'isBannerActive',
    ]),
    ...mapState('users', [
      'currentUser',
      'currentTheme',
      'startLoadingSlidesFlag',
      'limitsInfoObj',
    ]),
    ...mapState('users', ['currentUserFavorites']),
    ...mapState('prezentations', ['currNewPrezentation']),
    ...mapState('prezentationDetails', [
      'isFullScreenMode',
      'isRightPanelOpen',
      'prezentationData',
    ]),
    ...mapGetters('users', ['getAssetFolder', 'getCompanyAssets']),
    ...mapGetters('synonyms', ['getSynonymsList']),
    ...mapState('users', ['prezentationInProcess']),
    maxLimit() {
      return [
        (v) => (v || '').length <= 100 || this.$t('generate.cannotExceed100'),
      ];
    },
    isFromDetailView() {
      return this.$route.query?.origin === 'prezentation';
    },
    disableNextButton() {
      return (
        !this.result.presentationName ||
        !this.result.primaryAudience ||
        !this.result.presentationType?.sections ||
        this.result.presentationName.length > 100
      );
    },
    getTemplateName() {
      return this.currentTheme?.name;
    },
    disableAcceptStoryLine() {
      let length = 0;
      this.result?.presentationType?.sections?.list.forEach((section) => {
        length += section.slides.length;
      });

      return length <= 0;
    },
    getFingerprint() {
      return this.result.primaryAudience?.fingerPrint;
    },
    getPrezentationName() {
      return `${this.prezentation.name}.pptx`;
    },
    // getDetailsTitle() {
    //   const presentationType = this.result.presentationType.type;
    //   return this.$t('prezentation.recommendationLabel', { presentationType });
    // },
    ...mapState('prezentations', [
      'allPrezentations',
      'isStoryLineGalleryOpen',
    ]),
    dragOptions() {
      return {
        animation: 900,
        ghostClass: 'ghost-list',
      };
    },
    ideaTitle() {
      const presentationType = this.result.presentationType.type;
      return this.$t('prezentation.popularStorylineIdeas', {
        presentationType,
      });
    },
    getTitles() {
      const { text, title, subTitle, subText1, subText2 } =
        this.result.presentationType;
      return {
        title:
          title ||
          this.$t('prezentation.titleRecommendation', {
            presentationType: text,
          }),
        subTitle: subTitle || this.$t('prezentation.subTitleRecommendation'),
        subText1: subText1 || this.$t('prezentation.subText1Recommendation'),
        subText2: subText2 || this.$t('prezentation.subText2Recommendation'),
      };
    },
    showStepper() {
      return !this.$route.query.id;
    },
  },
  methods: {
    ...mapActions('users', [
      'addSearchTerms',
      'setCurrentUser',
      'setPrezentationInProcess',
      'setLimitData',
    ]),
    ...mapActions('prezentations', [
      'fetchAllPrezentations',
      'fetchPrezentationComments',
      'setUserSelectedPrezentationView',
      'setSaveStorylineStartTime',
    ]),
    ...mapActions('prezentationDetails', ['setFullScreenMode']),

    setFullScreen(fullScreenMode) {
      this.fullScreenMode = fullScreenMode;
    },
    goToSearchLandingPage() {
      this.$router.back();
    },
    async getHiddenIdeasSearch() {
      try {
        const hiddenIdea = await getHiddenIdeas({
          companyID: this.currentUser?.company?.id,
          themeID: this.currentUser?.theme?.id,
          isBPLSlides: false,
        });
        if (hiddenIdea && hiddenIdea.data.hiddenIdeas.length) {
          this.hiddenIdeas = [...hiddenIdea.data.hiddenIdeas];
        }
      } catch (err) {
        console.log(err);
      }
    },
    handleManageStorylines() {
      this.$router.push(`/profile?tab=storylines`);
      trackBuildEvents.buildManagestorylines(
        this.currentUser,
        this.getCommonMatomoOtherData(),
      );
    },
    handleStartEventTrigger() {
      if (this.eventStart === null) {
        this.eventStart = new Date();
      }
    },
    getPrezDetails() {
      return {
        [TD_AUD]: this.result?.primaryAudience?.id,
        [TD_PREZNAME]: this.result?.presentationName,
        [TD_PREZTYPE]: this.result?.presentationType?.type,
        [TD_PREZENTATIONAUTHOR]: this.currentUser?.user?.fullName,
        [TD_NUMBEROFSLIDES]: this.totalSlidesCount,
        [TD_COMPANY_OF_AUD_TAILORED_FOR]:
          this.result?.primaryAudience?.companyName,
        [TD_PREZENTATION_ORIGIN]: 'Build',
        [TD_PREZENTATION_COMPANY_SOURCE]:
          this.result?.primaryAudience?.companyName,
      };
    },
    totalSlides(count) {
      this.totalSlidesCount = count;
    },
    // eslint-disable-next-line func-names
    debounceInputChange: _.debounce(function (e) {
      const searchText = e?.target?.value;
      if (searchText.length) this.inputChange(searchText);
    }, 300),
    inputChange(text) {
      trackBuildEvents.buildStorylineSearch(this.currentUser, {
        [TD_AUD]: this.result?.primaryAudience?.id,
        [TD_QUERY]: text,
      });
    },
    clearSearch() {
      this.crossButton = false;
      this.addingItem = {};
    },
    toggleTemplatesMenu() {
      this.templateSelector = !this.templateSelector;
      if (this.templateSelector) {
        trackBuildEvents.buildTemplateInfoClick(this.currentUser, {});
      }
    },
    closeTemplateSelector() {
      this.templateSelector = false;
    },
    onClickHandle(check) {
      if (check) {
        this.isSearchColor = '#21a7e0';
      } else {
        this.isSearchColor = 'black';
      }
    },
    async handleCustomSelectClick() {
      this.search = 'Custom';
      let tempCustomStoryline = {};
      if (
        this.result.primaryAudience &&
        this.result.primaryAudience?.storyPreference[0] === 'Deductive'
      ) {
        tempCustomStoryline = {
          ...this.deductivePrezentCustomStoryline,
        };
      } else if (
        this.result.primaryAudience &&
        this.result.primaryAudience?.storyPreference[0] === 'Inductive'
      ) {
        tempCustomStoryline = {
          ...this.inductivePrezentCustomStoryline,
        };
      }
      if (
        tempCustomStoryline.ideaGallery &&
        tempCustomStoryline.ideaGallery.list &&
        tempCustomStoryline.ideaGallery.list.length > 0
      ) {
        tempCustomStoryline.ideaGallery.list = [
          ...tempCustomStoryline.ideaGallery.list.filter(
            (payload) =>
              !this.hiddenIdeas.includes(payload.category.toLowerCase()),
          ),
        ];
      }
      if (
        tempCustomStoryline.sectionGallery &&
        tempCustomStoryline.sectionGallery.list &&
        tempCustomStoryline.sectionGallery.list.length > 0
      ) {
        const sectionGallery = [];

        for (const section of tempCustomStoryline.sectionGallery.list) {
          if (section.slides && section.slides.length) {
            sectionGallery.push({
              ...section,
              slides: section.slides.filter(
                (payload) =>
                  !this.hiddenIdeas.includes(payload.category.toLowerCase()),
              ),
            });
          } else {
            sectionGallery.push({
              ...section,
            });
          }
        }

        tempCustomStoryline.sectionGallery.list = sectionGallery;
      }
      if (
        tempCustomStoryline.sections &&
        tempCustomStoryline.sections.list &&
        tempCustomStoryline.sections.list.length > 0
      ) {
        const sections = [];
        for (const section of tempCustomStoryline.sections.list) {
          if (section.slides && section.slides.length) {
            sections.push({
              ...section,
              slides: section.slides.filter(
                (payload) =>
                  !this.hiddenIdeas.includes(payload.category.toLowerCase()),
              ),
            });
          } else {
            sections.push({
              ...section,
            });
          }
        }
        tempCustomStoryline.sections.list = sections;
      }
      this.result.presentationType = {
        defaultStoryline: 'Custom',
        ...tempCustomStoryline,
      };
      trackBuildEvents.buildCustomstoryline(
        this.currentUser,
        this.getCommonMatomoOtherData(),
      );
    },
    handleStorylineChangeInStep2() {
      console.log('step 2 storyline change');
      this.stepperValue = 1;
      this.handleStepper(false, null, null, null, false, null);
      // this.stepperValue = 2;
      console.log('stepperValue -', this.stepperValue);
    },
    async getSearchTerms() {
      getUserSearchTerms()
        .then((searchData) => {
          const terms = searchData.data.searchByUser.items;
          if (terms.length) {
            this.searchTerms = terms;
          }
        })
        .catch((err) => console.log(err));
    },
    openRemindModal(currentAudience, trackingInfo) {
      this.$modal.show(
        ReminderDialog,
        {
          currentAudience,
          isUser: currentAudience.type === 'user',
          origin: BUILD,
          metaDataForTracking: trackingInfo,
        },
        {
          name: 'ReminderDialog',
          width: '649px',
          height: '80%',
        },
      );
    },
    deletingItemFilteredList(data) {
      const { beginning, middle, end } = this.result.presentationType.outline;
      return [...beginning, ...middle, ...end].filter(
        (item) => item.name === data.name,
      );
    },
    handleBack(val) {
      if (this.stepperValue > 1) {
        if (val) {
          this.stepperValue = val;
        } else {
          this.stepperValue -= 1;
        }

        if (this.stepperValue >= 3) {
          this.$router.push(
            `/home/build?id=${this.prezentationData.id}&origin=prezentation`,
          );
          this.stepperValue = 1;
          this.getPrezentationDataInEditMode(this.prezentationData.id);
        } else {
          const { primaryAudience, presentationType, presentationName } =
            this.result;

          this.result.primaryAudience = null;
          this.result.presentationType = null;
          this.result.presentationName = null;

          const otherData = {
            [TD_AUD]: primaryAudience?.id,
            [TD_PREZNAME]: presentationName,
            [TD_PREZTYPE]: presentationType?.type,
            [TD_IDEABEGIN]: this.getSectionDetails(0),
            [TD_IDEAMIDDLE]: this.getSectionDetails(1),
            [TD_IDEAEND]: this.getSectionDetails(2),
            [TD_SECTION_4]: this.getSectionDetails(3),
            [TD_SECTION_5]: this.getSectionDetails(4),
            [TD_PREZENTATIONAUTHOR]: this.currentUser?.user?.fullName,
          };
          trackBuildEvents.buildNextBackToStepOne(this.currentUser, otherData);
          // trackBuildEvents.buildBackfromstep2(this.currentUser, otherData);
          // this.stepperValue -= 1;
        }
      }
      return false;
    },
    onEditStoryLine(val) {
      this.handleBack(val);
    },
    getRecommendedSlides(payload) {
      const getSlides = (resolve, reject) => {
        const notprefs = {};
        const prefs = {};
        notprefs.construct = [];
        prefs.construct = [];
        if (this.skipEmphasis(payload.category)) {
          notprefs.construct.push('emphasis');
        } else {
          prefs.construct.push('emphasis');
          notprefs.construct.push('category');
        }

        const andprefs = {};
        andprefs.theme = this.currentTheme.code
          ? this.currentTheme.code
          : 'default';
        const params = {
          username: payload.username,
          query: payload.query ? payload.query : '',
          category: payload.category,
          orderby: 'CHOICE_DESC',
          choice: payload.choice,
          morprefs: payload.morprefs ? payload.morprefs : [],
          andprefs,
          prefs,
          notprefs,
          // company: this.currentUser.companyName.toLowerCase(),
          limit: payload.limit,
          skip: payload.skip >= 0 ? payload.skip : 0,
          audience_id: payload.audience_id,
          audience_type: payload.audience_type,
        };
        const serialized = JSON.stringify(params);
        AnalyticsHandler.performOutlineSlideSearch(
          this.currentUser,
          payload.query ? payload.query : payload.category,
        );
        AnalyticsHandler.performSlideSearch(
          this.currentUser,
          payload.query ? payload.query : payload.category,
        );
        GraphQLService.requestWithToken(
          graphqlOperation(performSearch, {
            parameters: serialized,
          }),
        )
          .then(async (res) => {
            const result = JSON.parse(res.data.performSearch);
            if (result.statusCode === 200) {
              const assets = [];
              const slides = result.body.payloads;
              if (!slides || slides.length < 1) {
                resolve(assets);
              } else {
                // eslint-disable-next-line no-restricted-syntax
                for (const slide of slides) {
                  // eslint-disable-next-line no-await-in-loop
                  await (async () => {
                    const obj = {};
                    obj.asset = slide;
                    obj.asset.name = obj.asset.title;
                    obj.asset.file = obj.asset.filename;
                    obj.asset.pptFile = obj.asset.file;
                    obj.isTrending = false;
                    obj.isFavorite = !!this.currentUserFavorites.find(
                      (element) => element.assetID === slide.unique_id,
                    );
                    const filePath = obj.asset.landscape;
                    obj.thumbnail = await getFileURL(
                      this.currentUser.user.id,
                      filePath,
                      this.currentUser.userIp,
                    );
                    assets.push(obj);
                  })();
                }
                resolve(assets);
              }
            } else {
              resolve(result);
            }
          })
          .catch((err) => reject(err));
      };
      return new Promise(getSlides);
    },
    getChoice(fingerprint) {
      let choice = { visual: 6.0, data: 5.0 };
      if (!fingerprint) return choice;
      switch (fingerprint.toLowerCase()) {
        case 'director':
          choice = { visual: 6.0, data: 5.0 };
          return choice;

        case 'performer':
          choice = { visual: 6.0, data: 0.0 };
          return choice;

        case 'navigator':
          choice = { visual: 6.0, data: 0.0 };
          return choice;

        case 'surgeon':
          choice = { visual: 0.0, data: 5.0 };
          return choice;

        case 'architect':
          choice = { visual: 0.0, data: 5.0 };
          return choice;

        case 'scholar':
          choice = { visual: 6.0, data: 5.0 };
          return choice;

        case 'scientist':
          choice = { visual: 0.0, data: 5.0 };
          return choice;

        default:
          return choice;
      }
    },
    skipEmphasis(category) {
      const emphasisAllowed = [
        'divider',
        'title',
        'congratulations',
        'thank_you',
        'call_to_action',
      ];
      if (emphasisAllowed.indexOf(category.toLowerCase()) > -1) return false;
      return true;
    },
    getRandom(min, max) {
      // min and max included
      return Math.floor(Math.random() * (max - min + 1) + min);
    },

    handleAcceptAndSave() {
      if (this.$route.query.id) {
        this.setSaveStorylineStartTime(new Date());
      } else {
        this.eventStartAcceptAndSave = new Date();
      }
      this.$modal.show(
        AcceptAndSaveDialog,
        {
          onSave: this.handleSave,
          type: this.result.presentationType.type,
          description:
            this.result.presentationType?.description ===
            'Build your own storyline based on specific needs'
              ? 'Custom outline tailored to the audience'
              : this.result.presentationType?.description,
          isOwner: this.result.presentationType.isOwner,
          permissionLevel: this.result.presentationType.permissionLevel,
        },
        {
          name: 'AcceptAndSaveDialog',
          width: '650px',
          height: 'auto',
          styles: {
            'max-height': '95%',
            overflow: 'auto',
            borderRadius: '20px',
          },
        },
      );
    },
    handleSave(type, description, teamAllowed, updateStoryline) {
      if (
        !this.result.presentationType.isPrezentGenerated &&
        this.result.presentationType?.uuid
      ) {
        getStorylineByID(this.result.presentationType.uuid)
          .then((response) => {
            console.log(response);

            if (!response.data || !Object.hasOwn(response.data, 'hasAccess')) {
              // storyline deleted
              this.$modal.hide('AcceptAndSaveDialog');
              this.showNoAccess = true;
              this.noAccess = false;
            } else if (!response?.data?.hasAccess) {
              // request access
              this.$modal.hide('AcceptAndSaveDialog');
              this.showNoAccess = true;
              this.noAccess = true;
            } else if (response?.data?.hasAccess) {
              this.handleStepper(
                true,
                type,
                description,
                teamAllowed,
                false,
                null,
                updateStoryline,
              );
              this.isSaveStoryLine = true;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.handleStepper(
          true,
          type,
          description,
          teamAllowed,
          false,
          null,
          updateStoryline,
        );
        this.isSaveStoryLine = true;
      }
    },
    getSectionDetails(index) {
      let details = '';
      if (this.result?.presentationType?.sections?.list[index]?.sectionName) {
        details = `${this.result.presentationType?.sections?.list[index]?.sectionName}_`;
        this.result.presentationType?.sections?.list[index]?.slides.forEach(
          (slide) => {
            details += slide.outline;
            details += '_';
          },
        );
      }
      return details;
    },
    getSlidesForEditOrCreate(isPrezInEditMode, editSlideObj, slides) {
      if (isPrezInEditMode === true) {
        return {
          asset: {
            unique_id: editSlideObj.uniqueID,
            filename: editSlideObj.fileName,
            landscape: editSlideObj.landScape,
          },
        };
      }
      if (slides) {
        return slides[this.getRandom(0, Math.min(slides.length, 5))];
      }
      return null;
    },
    async handleStepper(
      isSaveOutline,
      customType,
      customDescription,
      teamAllowed,
      isPrezInEditMode,
      selectedPrezentation,
      updateStoryline,
    ) {
      this.templateSelector = false;
      console.log('inside stepper -', this.stepperValue);
      if (this.stepperValue === 1) {
        this.$refs.audienceSelector?.addToRealtimeAudience();
        // pass matomo tracking event only on new build flow. should restrict on editStoryLine flow from step-3
        if (!this.$route.query.id) {
          const { primaryAudience, presentationType, presentationName } =
            this.result;
          trackBuildEvents.buildNext(this.currentUser, {
            [TD_AUD]: primaryAudience?.id,
            [TD_PREZNAME]: presentationName,
            [TD_PREZTYPE]: presentationType?.type,
          });
        }
        this.$set(this.status, 'isProcessing', true);
        this.$set(this.status, 'waitingIndex', 1);

        const { sections } = getAllPrezentationOutline(
          this.result.presentationType,
          this.result.primaryAudience.personalPreference,
        );

        for (let i = 0; i < sections.list.length; i++) {
          const section = sections.list[i];
          for (let j = 0; j < section.slides.length; j++) {
            const item = section.slides[j];
            if (!item.assetId) {
              const payload = {};
              payload.category = item.category;
              payload.username = this.currentUser.user.cognitoID; // "pz_rath_admin"),
              payload.limit = 6;
              payload.skip = 0;
              payload.choice = this.getChoice(
                this.result.primaryAudience.fingerPrint,
              );
              payload.audience_id = this.result.primaryAudience.email;
              payload.audience_type = this.result.primaryAudience.type;
              this.getRecommendedSlides(payload)
                .then((slides) => {
                  let obj = {};
                  if (slides && slides.length > 0) {
                    obj = slides[this.getRandom(0, Math.min(slides.length, 5))];
                  }

                  this.result.presentationType.sections.list[i].slides[j] = {
                    ...item,
                    uniqueID: obj.asset?.unique_id,
                    assetId: obj.asset?.landscape,
                    filename: obj.asset?.filename,
                  };
                })
                .catch((err) => console.log(err));
            }
          }
        }

        setTimeout(() => {
          this.$set(this.status, 'waitingIndex', 2);
        }, 1000);
        setTimeout(() => {
          this.$set(this.status, 'waitingIndex', 3);
        }, 2000);
        setTimeout(() => {
          this.$set(this.status, 'isProcessing', false);
          this.loading = false;
          this.stepperValue += 1;
          console.log(this.result.presentationType.sections);
        }, 3000);
      }
      if (this.stepperValue === 2) {
        if (this.$route.query.id) {
          this.setSaveStorylineStartTime(new Date());
        } else {
          this.eventStartAcceptAndSave = new Date();
        }
        this.$set(this.status, 'isProcessing', true);
        this.$set(this.status, 'waitingIndex', 4);
        setTimeout(() => {
          this.$set(this.status, 'waitingIndex', 5);
        }, 1000);
        setTimeout(() => {
          this.$set(this.status, 'waitingIndex', 6);
        }, 2000);

        this.prezentation = { ...this.prezentationInProcess };
        const dataToTrack = {
          name:
            this.result.name ||
            this.result.presentationName ||
            this.prezentation?.name,
          type: isSaveOutline ? customType : this.result.presentationType.type,
          audienceID: this.prezentationData?.audienceID,
        };

        const otherData = {
          [TD_PREZNAME]: dataToTrack.name,
          [TD_PREZTYPE]: dataToTrack.type,
          [TD_AUD]: dataToTrack.audienceID,
          [TD_VIEWTYPE]: TD_LIST,
          [TD_IDEABEGIN]: this.getSectionDetails(0),
          [TD_IDEAMIDDLE]: this.getSectionDetails(1),
          [TD_IDEAEND]: this.getSectionDetails(2),
          [TD_SECTION_4]: this.getSectionDetails(3),
          [TD_SECTION_5]: this.getSectionDetails(4),
          [TD_NUMBEROFSLIDES]: this.totalSlidesCount,
          [TD_ISDOWNLOAD]: 0,
        };
        try {
          if (this.result.id && !isSaveOutline) {
            otherData[TD_IDEABEGIN] = this.getSectionDetails(0);
            otherData[TD_IDEAMIDDLE] = this.getSectionDetails(1);
            otherData[TD_IDEAEND] = this.getSectionDetails(2);
            otherData[TD_SECTION_4] = this.getSectionDetails(3);
            otherData[TD_SECTION_5] = this.getSectionDetails(4);
            MatomoAnalyticsHandler.myPrezentationOutlineIdeas(
              dataToTrack,
              this.currentUser,
            );
            trackPrezentationEvents.prezentationsAcceptStoryline(
              this.currentUser,
              otherData,
            );
          }
          if (!this.result.id && !isSaveOutline) {
            otherData[TD_IDEABEGIN] = this.getSectionDetails(0);
            otherData[TD_IDEAMIDDLE] = this.getSectionDetails(1);
            otherData[TD_IDEAEND] = this.getSectionDetails(2);
            otherData[TD_SECTION_4] = this.getSectionDetails(3);
            otherData[TD_SECTION_5] = this.getSectionDetails(4);
            otherData[TD_NUMBEROFSLIDES] = this.totalSlidesCount;
            MatomoAnalyticsHandler.newPrezentationOutlineIdeas(
              dataToTrack,
              this.currentUser,
            );
            trackBuildEvents.buildAcceptStoryline(this.currentUser, otherData);
          }
        } catch (e) {
          console.log(e);
        }

        const sections = {
          list: this.$refs.storylineContainer.returnFinalData(),
        };
        // const refGaleryIdea = {};
        const apis = [];
        const slidesInfo = [];

        for (let i = 0; i < sections.list.length; i++) {
          const section = sections.list[i];

          for (let j = 0; j < section.slides.length; j++) {
            const item = section.slides[j];
            if (!item.assetId) {
              const payload = {};
              payload.category = item.category;
              payload.username = this.currentUser.user.cognitoID; // "pz_rath_admin"),
              payload.limit = 6;
              payload.skip = 0;
              payload.choice = this.getChoice(
                this.result.primaryAudience.fingerPrint,
              );
              payload.audience_id = this.result.primaryAudience.email;
              payload.audience_type = this.result.primaryAudience.type;
              apis.push(this.getRecommendedSlides(payload));
              slidesInfo.push({ slide: item, slideIndex: j, sectionIndex: i });
            }
          }
        }
        const res = await Promise.all(apis);
        for (let index = 0; index < res.length; index++) {
          const info = slidesInfo[index];
          const slides = res[index];
          let obj = {};
          if (slides && slides.length > 0) {
            obj = slides[this.getRandom(0, Math.min(slides.length, 5))];
          }

          sections.list[info.sectionIndex].slides[info.slideIndex] = {
            ...info.slide,
            thumbnail: undefined,
            uniqueID: obj.asset?.unique_id,
            assetId: obj.asset?.landscape,
            filename: obj.asset?.filename,
          };
        }
        const payloadSectionList = JSON.parse(JSON.stringify(sections.list));
        sections.list.forEach((section, secIndex) => {
          section.slides.forEach((slide, slideIndex) => {
            payloadSectionList[secIndex].slides[slideIndex] = {
              category: slide.category,
              outline: slide.outline,
            };

            // removing slides which dosn't have slide assetId(slide image)
            if (slide.assetId) {
              sections.list[secIndex].slides = sections.list[
                secIndex
              ].slides.filter((s) => s.assetId);
            }
          });
        });

        if (isSaveOutline) {
          const outlineData = {
            type: customType,
            description: customDescription,
            sections: { list: payloadSectionList },
          };

          if (updateStoryline) {
            outlineData.uuid = this.result.presentationType.uuid
              ? this.result.presentationType.uuid
              : null;
            await updateStorylines(outlineData)
              .then((value) => {
                console.log(`updated outline`, value);
                trackBuildEvents.buildUpdateExistingStorylineAcceptAndSave(
                  this.currentUser,
                  this.getCommonMatomoOtherData(),
                );
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            await createStorylines(outlineData)
              .then((value) => {
                console.log(`created outline`, value);
              })
              .catch((err) => {
                console.log(err);
              });
          }
          trackBuildEvents.buildAcceptSaveStoryline(this.currentUser, {
            [TD_PREZNAME]: dataToTrack.name,
            [TD_PREZTYPE]: customType,
            [TD_AUD]: dataToTrack.audienceID,
            [TD_VIEWTYPE]: TD_LIST,
            [TD_IDEABEGIN]: this.getSectionDetails(0),
            [TD_IDEAMIDDLE]: this.getSectionDetails(1),
            [TD_IDEAEND]: this.getSectionDetails(2),
            [TD_NUMBEROFSLIDES]: this.totalSlidesCount,
            [TD_ISDOWNLOAD]: 0,
            [TD_SECTION_4]: this.getSectionDetails(3),
            [TD_SECTION_5]: this.getSectionDetails(4),
          });
        }
        const prezentationDataForCreate = {
          id: uuidv4(),
          name: this.result.name || this.result.presentationName,
          type: isSaveOutline ? customType : this.result.presentationType.type,
          description: isSaveOutline
            ? customDescription
            : this.result.presentationType.description,
          ownerID: this.currentUser.user.id,
          visibleTeam: teamAllowed,
          companyID: this.currentUser.user.companyID,
          teamID: this.currentUser.user.teamID,
          reviewersIDs: [],
          audienceID: this.result.primaryAudience.id,
          audienceOwnerID: this.result.primaryAudience.ownerID
            ? this.result.primaryAudience.ownerID
            : null,
          sections,
          source: this.currentUser?.company?.name,
          theme: this.currentTheme.code ? this.currentTheme.code : 'default',
          outlineID: this.result.presentationType.uuid
            ? this.result.presentationType.uuid
            : null,
          fingerprintUsed: this.result.primaryAudience.fingerPrint,
        };

        const prezentationDataForUpdate = {
          id: this.result.id || this.prezentation?.id,
          name:
            this.result.name ||
            this.result.presentationName ||
            this.prezentation?.name,
          type: isSaveOutline ? customType : this.result.presentationType.type,
          description: isSaveOutline
            ? customDescription
            : this.result.presentationType.description,
          ownerID: this.currentUser.user.id,
          visibleTeam: teamAllowed,
          companyID: this.currentUser.user.companyID,
          teamID: this.currentUser.user.teamID,
          reviewersIDs: [],
          audienceID: this.result.primaryAudience.id,
          audienceOwnerID: this.result.primaryAudience.ownerID
            ? this.result.primaryAudience.ownerID
            : null,
          sections,
          source: this.currentUser?.company?.name,
          theme: this.currentTheme.code ? this.currentTheme.code : 'default',
        };

        if (!this.$route.query.id) {
          // await getFeatureUsage()
          //   .then((response) => {
          //     if (response?.data) {
          //       this.setLimitData(response.data);
          //     }
          //   })
          //   .catch((error) => console.log(error));
          // if (!this.limitsInfoObj?.prezentation?.isLimitCrossed) {
          //   postFeatureUsage({ feature: 'prezentation' })
          //     .then((response) => console.log(response))
          //     .catch((error) => console.log(error));
          await this.createPrezentation(prezentationDataForCreate)
            .then((value) => {
              this.prezentation = value.prezentationData;

              this.finalPrezentation = decoratePrezentation(
                value.prezentationData,
                this.currentUser,
              );

              // TODO: need to check  this.finalPrezentation.slides.some((item) => item.id !== null)
              this.isSlidesPresent = true;
              this.setPrezentationInProcess({ ...this.finalPrezentation });
              MatomoAnalyticsHandler.newPrezentationCreated(
                this.prezentation,
                this.currentUser,
              );
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          console.log(prezentationDataForUpdate);
          await this.updatePrezentation(
            this.processPayload(prezentationDataForUpdate),
          )
            .then((value) => {
              this.prezentation = value.prezentationData;
              const index = this.allPrezentations.findIndex(
                (item) => item.id === this.prezentation.id,
              );
              this.allPrezentations[index] = this.mapPrezentation(
                this.prezentation,
              );
              this.finalPrezentation = decoratePrezentation(
                value.prezentationData,
                this.currentUser,
              );
              this.setPrezentationInProcess({ ...this.finalPrezentation });

              setTimeout(() => {
                this.$set(this.status, 'isProcessing', false);

                if (this.$route.query.id) {
                  this.$router.push(
                    `/home/myPrezentation?id=${this.prezentation.id}&ideaGallery=true`,
                    () => {},
                  );
                }
              }, 500);
            })
            .catch((err) => {
              console.log(err);
            });
        }
        setTimeout(() => {
          if (!this.$route.query.id) {
            this.$set(this.status, 'isProcessing', false);

            this.stepperValue += 1;
          }
        }, 500);

        EventBus.$emit('REFRESH_PREZENTATION_LIST');
      }
    },

    processPayload(prezentationData) {
      const payload = JSON.parse(JSON.stringify({ ...prezentationData }));

      if (prezentationData?.sections?.list) {
        payload.sections.list = prezentationData?.sections?.list.map(
          (section) => ({
            ...section,
            slides: section.slides.map(
              ({ thumbnail, originalThumbnail, ...others }) => others,
            ),
          }),
        );
      }

      return payload;
    },
    async createPrezentation(prezentationData) {
      const path = '/prezentation';
      return API2.post(path, prezentationData);
    },
    async updatePrezentation(prezentationData) {
      const path = '/prezentation';
      return API2.put(path, prezentationData);
    },
    mapPrezentation(item) {
      const presentation = item;
      presentation.sections?.list.forEach((section, sectionIndex) => {
        section.slides.forEach(async (slide, slideIndex) => {
          if (sectionIndex === 0 && slideIndex === 0) {
            this.selectedSlide = slide;
          }
          const filePath = slide.assetId || '';
          let thumbnail = '';
          if (filePath) {
            thumbnail = await getFileURL(
              this.currentUser.user.id,
              filePath,
              this.currentUser.userIp,
            );
          }

          presentation.sections.list[sectionIndex].slides[
            slideIndex
          ].thumbnail = thumbnail;
        });
      });

      return presentation;
    },

    omit(obj, props) {
      // eslint-disable-next-line no-param-reassign
      props = props instanceof Array ? props : [props];
      return eval(`(({${props.join(',')}, ...o}) => o)(obj)`);
    },

    openAddCollegue() {
      this.$modal.show(
        AddCollegueDetail,
        {
          origin: TD_AUDIENCE_ORIGIN_NP,
        },
        AddNewAudienceModalProps,
      );
    },
    handleStorylineSelect(storyline) {
      if (
        storyline &&
        storyline.type !== 'Custom' &&
        storyline.type !== 'Other'
      ) {
        // if storyline is not PrezentZgenerated copy ideaGallery and sectionGallery from prezentCustomStorylines
        if (storyline.isPrezentGenerated) {
          if (
            storyline.ideaGallery &&
            storyline.ideaGallery.list &&
            storyline.ideaGallery.list.length > 0
          ) {
            storyline.ideaGallery.list = [
              ...storyline.ideaGallery.list.filter(
                (payload) =>
                  !this.hiddenIdeas.includes(payload.category.toLowerCase()),
              ),
            ];
          }
          if (
            storyline.sectionGallery &&
            storyline.sectionGallery.list &&
            storyline.sectionGallery.list.length > 0
          ) {
            const sectionGallery = [];

            for (const section of storyline.sectionGallery.list) {
              if (section.slides && section.slides.length) {
                sectionGallery.push({
                  ...section,
                  slides: section.slides.filter(
                    (payload) =>
                      !this.hiddenIdeas.includes(
                        payload.category.toLowerCase(),
                      ),
                  ),
                });
              } else {
                sectionGallery.push({ ...section });
              }
            }

            storyline.sectionGallery.list = sectionGallery;
          }
          if (
            storyline.sections &&
            storyline.sections.list &&
            storyline.sections.list.length > 0
          ) {
            const sections = [];
            for (const section of storyline.sections.list) {
              if (section.slides && section.slides.length) {
                sections.push({
                  ...section,
                  slides: section.slides.filter(
                    (payload) =>
                      !this.hiddenIdeas.includes(
                        payload.category.toLowerCase(),
                      ),
                  ),
                });
              } else {
                sections.push({ ...section });
              }
            }
            storyline.sections.list = sections;
          }
          this.result.presentationType = storyline;
        } else {
          let customIdeaGallery = {};
          let customSectionGallery = {};
          if (
            this.result.primaryAudience &&
            this.result.primaryAudience?.storyPreference[0] === 'Deductive'
          ) {
            customSectionGallery = JSON.parse(
              this.deductivePrezentCustomStoryline.sectionGallery
                ? JSON.stringify(
                    this.deductivePrezentCustomStoryline.sectionGallery,
                  )
                : '{}',
            );
            customIdeaGallery = JSON.parse(
              this.deductivePrezentCustomStoryline.ideaGallery
                ? JSON.stringify(
                    this.deductivePrezentCustomStoryline.ideaGallery,
                  )
                : '{}',
            );
          } else if (
            this.result.primaryAudience &&
            this.result.primaryAudience?.storyPreference[0] === 'Inductive'
          ) {
            customSectionGallery = JSON.parse(
              this.inductivePrezentCustomStoryline.sectionGallery
                ? JSON.stringify(
                    this.inductivePrezentCustomStoryline.sectionGallery,
                  )
                : '{}',
            );
            customIdeaGallery = JSON.parse(
              this.inductivePrezentCustomStoryline.ideaGallery
                ? JSON.stringify(
                    this.inductivePrezentCustomStoryline.ideaGallery,
                  )
                : '{}',
            );
          }

          this.result.presentationType = {};
          if (
            customIdeaGallery &&
            customIdeaGallery.list &&
            customIdeaGallery.list.length
          ) {
            customIdeaGallery.list = [
              ...customIdeaGallery.list.filter(
                (payload) =>
                  !this.hiddenIdeas.includes(payload.category.toLowerCase()),
              ),
            ];
          }
          if (
            customSectionGallery &&
            customSectionGallery.list &&
            customSectionGallery.list.length
          ) {
            const sectionGallery = [];
            for (const section of customSectionGallery.list) {
              if (section.slides && section.slides.length) {
                sectionGallery.push({
                  ...section,
                  slides: section.slides.filter(
                    (payload) =>
                      !this.hiddenIdeas.includes(
                        payload.category.toLowerCase(),
                      ),
                  ),
                });
              } else {
                sectionGallery.push({
                  ...section,
                });
              }
            }
            customSectionGallery.list = sectionGallery;
          }
          if (
            storyline.sections &&
            storyline.sections.list &&
            storyline.sections.list.length
          ) {
            const sections = [];
            for (const section of storyline.sections.list) {
              if (section.slides && section.slides.length) {
                sections.push({
                  ...section,
                  slides: section.slides.filter(
                    (payload) =>
                      !this.hiddenIdeas.includes(
                        payload.category.toLowerCase(),
                      ),
                  ),
                });
              } else {
                sections.push({
                  ...section,
                });
              }
            }
            storyline.sections.list = sections;
          }
          this.result.presentationType = {
            ...storyline,
            ideaGallery: customIdeaGallery,
            sectionGallery: customSectionGallery,
          };
        }
      }
    },
    handleAddAudience(value) {
      if (value && !value.fingerPrint) {
        let additionalTrackingInfo = {};
        if (this.prezentationData) {
          additionalTrackingInfo = getPrezData(this.prezentationData);
          trackBuildEvents.buildTailorSlidesGreyAudienceClickOnStepOne(
            this.currentUser,
            additionalTrackingInfo,
          );
          additionalTrackingInfo = {
            ...{ stepCount: 'STEP_1' },
            ...{ prezentationTrackingInfo: additionalTrackingInfo },
          };
        }
        this.openRemindModal(value, additionalTrackingInfo);
      } else {
        this.openAddCollegue();
      }
    },
    handleAudienceSelect() {
      if (this.result.primaryAudience)
        AnalyticsHandler.newPrezentationSelectedAudience(
          this.currentUser,
          this.result.primaryAudience.username
            ? this.result.primaryAudience.username
            : this.result.primaryAudience.firstName,
          this.result.primaryAudience.fingerPrint,
        );
      if (Number(this.result.primaryAudience.id)) {
        this.result.primaryAudience.id = this.result.primaryAudience.email;
      }
      if (
        DEDUCTIVE_PRINTS.includes(
          this.result.primaryAudience.fingerPrint.toLowerCase(),
        )
      ) {
        this.audienceStoryPref = 'deductive';
      } else if (
        INDUCTIVE_PRINTS.includes(
          this.result.primaryAudience.fingerPrint.toLowerCase(),
        )
      ) {
        this.audienceStoryPref = 'inductive';
      }
      if (this.result.presentationType) {
        if (this.result.presentationType.isPrezentGenerated) {
          // need to include logic to auto select the
          // similar storyline for different storypreference of the selcted user
          // this.result.presentationType = this.presentTypeList.find(
          // (i) => this.result.presentationType.type === i.type,
          // );
        }
      }
      return true;
    },
    async itemSelected(item) {
      const payload = {};
      payload.category = item.category;
      payload.username = this.currentUser.user.cognitoID; // "pz_rath_admin"),
      payload.limit = 6;
      payload.skip = 0;
      payload.choice = this.getChoice(this.result.primaryAudience.fingerPrint);
      payload.audience_id = this.result.primaryAudience.email;
      payload.audience_type = this.result.primaryAudience.type;
      const slides = await this.getRecommendedSlides(payload);
      if (!this.alreadySearchedIdeas.includes(item.outline)) {
        this.alreadySearchedIdeas.push(item.outline);
        // we don't need to wait this
        const userData = {
          performIncrements: {
            countSearches: 1,
          },
        };
        usersApi.methods
          .updateUserProfile(userData)
          .then((updatedUserDetails) => {
            const userInfo = this.currentUser;
            userInfo.user = updatedUserDetails;
            this.setCurrentUser(userInfo);
            console.log('slide incrementSearchCount');
          })
          .catch((err) =>
            console.log('error while updating the search count', err),
          );
      }
      // Adding to search terms
      this.logSearch({ item, results: slides });
    },
    logSearch(term) {
      const logPayload = {
        searchTerm: term?.item?.outline?.toLowerCase() || '',
        searchTermResult: false,
        type: 'Idea Gallery',
      };
      console.log('on log search', term);
      try {
        const index =
          this.searchTerms &&
          this.searchTerms.findIndex((item) => {
            const category = item.category.toLowerCase().replace('_', ' ');
            const termName = term.item.outline.toLowerCase().replace('_', ' ');
            return category === termName;
          });
        if (index >= 0) {
          console.log('on log search-1');
          this.searchTerms[index].count += 1;
          const searchData = {
            id: this.searchTerms[index].id,
            count: this.searchTerms[index].count,
            searchTermResult: !(
              term.results === undefined ||
              term.results === null ||
              term.results.length <= 0
            ),
          };
          logPayload.searchTermResult = searchData.searchTermResult;
          updateSearchTerms(searchData)
            .then((value) => {
              console.log(value);
              this.getSearchTerms();
            })
            .catch((err) => console.log(err));
        } else {
          console.log('on log search-2');
          const searchData = {
            id: uuidv4(),
            userID: this.currentUser.user.id,
            searchTerm: term.item.outline,
            category: term.item.category,
            count: 1,
            createdAt: new Date().toISOString(),
            searchTermResult: true,
          };
          logPayload.searchTermResult = searchData.searchTermResult;
          addSearchTerms(searchData)
            .then((value) => {
              this.addSearchTerms(value.data.createSearchTerm);
              this.getSearchTerms();
            })
            .catch((err) => console.log(err));
        }
      } catch (err) {
        console.log(err);
      }
      logSearchQuery(logPayload)
        .then()
        .catch((err) => console.log(err));
    },
    setLabel(item) {
      return item.name;
    },
    serializeParam(params) {
      return Object.entries({ ...params })
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
    },

    async getPrezentationDataInEditMode(prezId) {
      this.loading = true;
      const pid = prezId;
      const selectedPrezentation = await getPrezentationByID(pid);

      // TODO: Replace this function code to mount function too.
      await this.loadEditModePrezentations(selectedPrezentation);
      this.handleStepper(false, null, null, null, true, selectedPrezentation);
    },
    async loadEditModePrezentations(selectedPrezentation) {
      let id = 1000;

      const audiences = await audienceApi.methods.audienceSearch({
        query: selectedPrezentation.audienceID,
        userId: this.currentUser.user.id,
        companyId: this.currentUser.user.companyID,
        searchAudienceTypes: selectedPrezentation.audienceOwnerID
          ? ['private_audience']
          : ['user', 'audience', 'standard'],
      });

      this.result = {
        presentationName: selectedPrezentation.name,
        presentationType: {
          ...selectedPrezentation,
          value: 100 + id++,
        },
        primaryAudience: audiences[0] || { fingerPrint: 'director' },
        id: selectedPrezentation.id,
        name: selectedPrezentation.name,
      };
    },
    async buildForStorylines() {
      await getStorylineByID(this.$route.query.outline_id)
        .then(async (res) => {
          this.result.presentationType = null;
          if (res.data) {
            this.storylineData = res.data;
            this.result.primaryAudience = {
              defaultAudience: 'SELF',
            };
            console.log(res.data.type);
            this.result.presentationType = {
              defaultStoryline: res.data.type,
            };
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCommonMatomoOtherData() {
      return {
        [TD_AUD]: this.result?.primaryAudience?.id,
        [TD_PREZNAME]: this.result?.presentationName || NA,
        [TD_PREZTYPE]: this.result?.presentationType?.text || NA,
      };
    },
    async getPrezentCustomStoryline() {
      const tsClient = await TypeSenseServer.getClient();
      const searchParameters = {
        q: 'Custom',
        query_by: 'type',
        filter_by: `outlineType:=[Custom]`,
      };
      await tsClient
        .collections('storylines')
        .documents()
        .search(searchParameters)
        .then((response) => {
          const { hits } = response;
          hits.forEach((ele) => {
            if (ele.document.storyType === 'deductive') {
              this.deductivePrezentCustomStoryline = ele.document;
              this.deductivePrezentCustomStoryline.sectionGallery = this
                .deductivePrezentCustomStoryline.sectionGallery
                ? JSON.parse(
                    this.deductivePrezentCustomStoryline.sectionGallery,
                  )
                : {};
              this.deductivePrezentCustomStoryline.ideaGallery = this
                .deductivePrezentCustomStoryline.ideaGallery
                ? JSON.parse(this.deductivePrezentCustomStoryline.ideaGallery)
                : {};
              this.deductivePrezentCustomStoryline.sections = this
                .deductivePrezentCustomStoryline.sections
                ? JSON.parse(this.deductivePrezentCustomStoryline.sections)
                : {};
            } else if (ele.document.storyType === 'inductive') {
              this.inductivePrezentCustomStoryline = ele.document;
              this.inductivePrezentCustomStoryline.sectionGallery = this
                .inductivePrezentCustomStoryline.sectionGallery
                ? JSON.parse(
                    this.inductivePrezentCustomStoryline.sectionGallery,
                  )
                : {};
              this.inductivePrezentCustomStoryline.ideaGallery = this
                .inductivePrezentCustomStoryline.ideaGallery
                ? JSON.parse(this.inductivePrezentCustomStoryline.ideaGallery)
                : {};
              this.inductivePrezentCustomStoryline.sections = this
                .inductivePrezentCustomStoryline.sections
                ? JSON.parse(this.inductivePrezentCustomStoryline.sections)
                : {};
            }
          });
        });
    },
  },

  async mounted() {
    await this.getPrezentCustomStoryline();
    this.previousRoute = this.$router.app.previousRoute.name;
    this.setFullScreenMode(false);
    this.getSearchTerms();
    // await this.getStoryLinesForBuild();
    this.setUserSelectedPrezentationView(this.viewTypes.MY_PREZENTATIONS);
    EventBus.$on('DOWNLOAD_BUTTON_CLICKED', () => {
      this.stepperValue += 1;
    });
    setTimeout(() => {
      EventBus.$emit('MINIMIZE_LEFTNAV');
    }, 50);
    EventBus.$on('CLEAR_NEW_PREZENTATION', () => {
      if (this.stepperValue >= 2) {
        this.result = {
          id: '',
          name: '',
          presentationName: '',
          presentationType: {
            outline: null,
          },
          primaryAudience: null,
        };
        this.stepperValue = 1;
        if (this.$route.query.id) {
          this.$router.push('/home/build');
        }
      }
    });
    EventBus.$on('LEFTNAV_EXPANDED', () => {
      this.leftNavExpanded = true;
    });
    EventBus.$on('LEFTNAV_MINIMIZED', () => {
      this.leftNavExpanded = false;
    });
    AnalyticsHandler.loadedNewPrezentations(this.currentUser);
    let selectedPrezentation = null;
    // when coming from my prezentation for editing
    if (this.$route.query.id) {
      this.loading = true;
      const pid = this.$route.query.id;
      this.stepperValue = 1;
      selectedPrezentation = await getPrezentationByID(pid);
      await this.loadEditModePrezentations(selectedPrezentation);
      this.handleStepper(false, null, null, null, true, selectedPrezentation);
    } else if (this.$route.query?.outline_id) {
      if (this.startLoadingSlidesFlag === true) {
        this.loading = true;
        await this.buildForStorylines();
        this.loading = false;
      }
    } else if (this.$route.query?.audience) {
      if (this.startLoadingSlidesFlag) {
        this.result.primaryAudience = {
          defaultAudience: this.$route.query.audience,
        };
      }
      this.loading = false;
    } else {
      this.loading = false;
    }
    await this.getHiddenIdeasSearch();
  },

  beforeRouteLeave(to, from, next) {
    if (this.$refs.prezentationDetailNew) {
      this.$refs.prezentationDetailNew?.backButton('other-page', next);
    } else {
      next();
    }
  },
  beforeDestroy() {
    EventBus.$off('DOWNLOAD_BUTTON_CLICKED');
    EventBus.$off('LEFTNAV_EXPANDED');
    EventBus.$off('LEFTNAV_MINIMIZED');
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/scss/variables.scss', '@/scss/app.scss';
.v-stepper__wrapper {
  ::v-deep .active-step .v-stepper__label {
    color: rgba(0, 0, 0, 0.87) !important;
    font-weight: bold;
  }
}
.top-cntr {
  margin: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btn-back {
  border: none;
  text-transform: none;
  background-color: #ffffff !important;
  box-shadow: none;
  letter-spacing: normal;
  color: #21a7e0;
  padding: 0;
  margin: 0px 0px 0px -8px;
}
.presentation-type-combobox-wrapper {
  .presentation-type-step2 {
    display: flex;
    ::v-deep .v-input {
      margin: 0 !important;
      padding: 0 !important;
      max-width: 85%;
      font-size: 14x;
    }
  }
  .storyline-type {
    padding-top: 5px;
    font-size: 14px;
    padding-right: 7px;
  }
}
.new-presentation-section {
  display: flex;
  position: relative;
  height: 100%;
}
.template-selector-active {
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  width: 32%;
  max-width: 500px;
}
.step-two-accept__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.cliploader {
  margin: calc(40vh + 25px) auto 0px auto;
}

.container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.ghost-list {
  opacity: 0.9;
  background: #c8ebfb;
}

.no-slides-container {
  margin-top: 75px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
}

.new-presentation-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  // overflow-x: auto;
  // padding: 20px;
  width: 100%;
  &.left-shift {
    width: 67%;
  }

  #context-step,
  #outline-step,
  #slides-step {
    ::v-deep .v-stepper__step__step {
      background-color: $white-default;
      border: 1.5px solid $zen-blue-default;
      &.primary {
        background-color: $white-default !important;
        .v-icon {
          color: $zen-blue-default;
          padding-right: 1.5px;
          svg {
            height: 23px;
            width: 23px;
          }
        }
      }
    }
  }

  .stepper-wrapper-two {
    align-items: flex-start;
    display: flex;
    justify-content: flex-end;
    padding: 40px 10px 20px 16px;
    .itemcenter {
      margin: 0 auto;
      display: flex;
    }
  }

  .stepper-wrapper-one {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    padding: 40px 10px 20px 16px;
    margin: 0 auto;

    .itemcenter {
      margin: 0 auto;
      display: flex;
      justify-content: center;
    }

    .itemright {
      flex: 0 1 auto;
      width: 100px;
      height: 100px;
      margin-left: auto;
      margin-right: 170px;
    }
  }

  .first-stepper {
    margin: 0 auto;
  }

  .back-button {
    color: $zen-blue-default;
    cursor: pointer;
    margin: auto 0;
  }

  .v-stepper {
    box-shadow: none !important;
    width: 350px;
  }

  .v-stepper__step {
    padding: 0 24px;
  }
  .back-btn-container {
    flex: 1;
    ::v-deep .v-btn {
      height: auto;
    }
  }

  .template-selector-button {
    cursor: pointer;
    flex: 1;
    display: flex;
    justify-content: flex-end;

    .expand-button {
      display: flex;
      align-items: center;
      // margin: 0 0 0 400px;
      border-radius: 25px;
      background: rgba(65, 72, 77, 0.08);
      height: 32px;
      padding: 10px;
    }

    // .expand-button > * {
    //   align-items: center;
    //   border-radius: 25px;
    //   color: white;
    //   display: inline-flex;
    //   justify-content: space-between;
    //   height: 32px;
    //   overflow: hidden;
    //   position: absolute;
    //   right: 0;
    //   width: 180px;
    //   text-decoration: none;
    //   max-width: 32px;
    //   bottom: -16px;
    //   -webkit-transition: max-width 0.5s;
    //   transition: max-width 0.5s;
    // }

    // .expand-button > *:hover {
    //   background: rgba(65, 72, 77, 0.08);
    //   max-width: 200px;
    // }
    .expand-button-text {
      color: black;
      font-size: 14px;
      margin-left: 5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 200px;

      &.primary-color {
        color: #21a7e0;
      }
    }
    .expand-button-icon {
      margin-right: 0;
      // padding: 0px 6px;
      display: flex;
      align-items: center;
    }

    &.template-selection-open {
      right: 33%;
    }
  }

  .define-context-wrapper {
    height: 100%;
    // margin: auto;
    // width: fit-content;

    .stepper-1 {
      ::v-deep .v-input {
        font-size: 14px;
      }
    }

    &.stepper-3 {
      // height: calc(100vh - 100px);
      // overflow-y: auto;
      width: 100%;
    }

    &.full-width {
      height: 100%;
      width: 100%;
    }

    .define-context-header {
      font-size: 28px;
      font-stretch: normal;
      font-style: normal;
      font-weight: bold;
      letter-spacing: normal;
      line-height: normal;
      margin-bottom: 5px;
      text-align: left;
    }

    .define-context-subheader {
      font-family: 'Lato' !important;
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      margin: 20px 0;
      text-align: left;
    }
    .define-context-sub-header {
      font-size: 1.2vw;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      margin-bottom: 10px;
    }
    .define-context-description {
      font-size: 1vw;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      margin-bottom: 25px;
    }

    .dditional-info-title {
      margin-top: 20px;
    }
    .toolbar-panel {
      align-items: center;
      border-bottom: solid 0.3px $light-gray;
      display: flex;
      justify-content: space-between;
      padding: 0 0 15px;

      .view-modes,
      .view-mode {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .comment-panel {
        cursor: pointer;
      }
      .view-mode {
        cursor: pointer;
        .active {
          display: none;
        }
        .normal {
          display: block;
        }
        img {
          margin-right: 5px;
        }
        &.active {
          .active {
            display: block;
          }
          .normal {
            display: none;
          }
        }
      }
      .list {
        margin-right: 20px;
      }
    }
    .slides-content-wrapper {
      height: calc(100vh - 250px);
      overflow: auto;
      padding-top: 10px;
      width: 100%;

      .slides-list-view,
      .slides-mosaic-view {
        height: 100%;
        transition: width 0.3s, visibility 0.3s, opacity 0.3s ease-out;
        width: 100%;
      }

      ::v-deep .fab-btn {
        box-shadow: none;
        background: white;
        padding: 0;
        &::before {
          display: none;
        }
      }
      ::v-deep .my-slide-speed .v-speed-dial__list {
        left: 0px;
      }
      ::v-deep .download-list {
        width: 208px;
        border-radius: 2px;
        box-shadow: 0 2px 4px 0 #68788c;
        position: absolute;
        top: -172px;
        right: -10px;

        .download-list-item {
          font-size: 20px;
          padding-bottom: 5px;
          background: white;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: black;
          text-align: left;
          padding-left: 5px;
          cursor: pointer;
          &:hover {
            background: #f2f2f2;
          }
        }
      }

      .slides-comment {
        align-items: center;
        border-bottom: 0.3px solid #d8d8d8;
        border-left: 0.3px solid #d8d8d8;
        display: flex;
        flex-direction: column;
        height: calc(100% - 104px);
        justify-content: space-between;
        min-width: 100px;
        transition: all 0.3s ease-out;

        &.normal {
          display: none;
          width: 0px;
        }

        &.active {
          width: 340px;
        }
        .comment-send-wrapper {
          width: 100%;

          textarea {
            border: solid 0.2px #d8d8d8;
            height: 75px;
            padding-left: -12px !important;
            padding-right: -12px !important;
            width: 100%;
          }
          .comment-send-control {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 5px 0 5px;
            .icons {
              display: flex;
              align-items: center;
              justify-content: space-between;
              .comment-who {
                margin-right: 5px;
              }
              .comment-who,
              .comment-smile {
                cursor: pointer;
              }
            }
            .comment-send-button {
              cursor: pointer;
            }
            .comment-send-button > img {
              filter: invert(1);
            }
          }
        }
        .comments {
          height: calc(100% - 100px);
          .comment {
            margin-bottom: 30px;
            position: relative;
            .header {
              display: flex;
              align-items: flex-start;
              justify-content: flex-start;
              position: relative;
              width: 260px;
              .header-tool {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                //position: absolute;
                right: 10px;
                top: 0px;
                .edit {
                  margin-right: 10px;
                }
                .edit,
                .delete {
                  cursor: pointer;
                }
              }

              .online-status {
                width: 8px;
                height: 8px;
                border-radius: 50%;
                margin-top: 7px;
                margin-right: 5px;
                &.green {
                  background: #61bc6f;
                }
                &.red {
                  background: #bc6161;
                }
              }
              .header-info {
                text-align: left;
                .uesr-name {
                  font-size: 16px;
                  font-weight: bold;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: normal;
                  letter-spacing: normal;
                }
                .date-info {
                  font-size: 12px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: normal;
                  letter-spacing: normal;
                  color: #68788c;
                }
              }
            }
            .comment-content {
              text-align: left;
              position: relative;
              width: 100%;
              input {
                width: 100%;
                border: 1px solid gray;
                padding: 3px;
                border-radius: 5px;
              }
            }
            .comment-control {
              position: absolute;
              bottom: -30px;
              right: 0px;
              display: none;
              align-items: center;
              justify-content: flex-start;
              &.show {
                display: flex;
              }
              .comment-cancel {
                margin-right: 10px;
              }
              .comment-cancel,
              .comment-save {
                cursor: pointer;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
              }
            }
          }
        }
      }

      .slides-list-view {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        &.less-width {
          width: calc(100% - 340px);
        }
        .left-side-items {
          height: 100%;
          min-width: 280px;
          overflow-y: auto;
          padding: 5px;
          padding-right: 15px;
          flex-grow: 0.5;

          .left-side-item {
            border-radius: 8px;
            box-shadow: -2px 2px 25px 1px rgba(0, 0, 0, 0.1);
            cursor: pointer;
            margin-bottom: 15px;
            overflow: hidden;
            width: 100%;
          }

          .left-side-item:hover {
            box-shadow: -4px 4px 25px 1px rgba(0, 0, 0, 0.3);
          }
        }
        .current-item-panel {
          flex-grow: 3.5;
          padding-left: 30px;

          .image {
            border-radius: 16px;
            box-shadow: 0 2px 4px 0 #68788c;
            cursor: pointer;
            margin-bottom: 30px;
            overflow: hidden;
          }

          .description-wrapper {
            align-items: center;
            display: grid;
            grid-template-columns: 1fr 170px;
            margin-top: -20px;
          }

          .description {
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
          }

          button.download-btn {
            font-size: 24px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: white;
            text-transform: none;
          }
        }
      }
      .slides-mosaic-view {
        .slide-mosaic--container {
          display: flex;
          flex-direction: column;
          .slider-content-wrapper {
            box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
            border-radius: 8px;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            max-width: 337px;
            min-width: 200px;
          }
          .slider-content-wrapper:hover {
            box-shadow: -4px 4px 25px 1px rgba(0, 0, 0, 0.3);
          }
          .image-wrapper img {
            width: 100%;
          }
          .image-wrapper-mosaic img {
            width: 80%;
            height: 80%;
          }
          .image-wrapper {
            border-bottom: 1px solid rgba(211, 211, 211, 0.2);
          }
          .bottom-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 15px;
            .bottom-wrapper-title {
              font-size: 12px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              width: 80%;
            }
            .action-wrapper {
              display: flex;
              align-items: center;
              justify-content: center;
              .show-detail {
                cursor: pointer;
                margin-right: 15px;
                margin-left: 15px;
              }
            }
          }
        }
        button.download-btn {
          font-size: 24px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: white;
          text-transform: none;
          margin-bottom: 30px;
        }
      }
    }
    .footer {
      pointer-events: none;
      position: fixed;
      left: 55px;
      bottom: 15px;
      width: 73%;
      display: flex;
      justify-content: center;

      &.galleryOpen {
        // left: 305px;
        // width: calc(100% - 280px);
        width: 100%;
      }
      &.expanded {
        width: 100%;
        left: 164px;
      }
      .total-slides-text {
        margin-bottom: 15px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.01em;
        background-color: white;
        padding: 10px;
      }

      .footer-container {
        pointer-events: all;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }

    .outline-wrapper {
      flex-wrap: nowrap;
      //position: relative;
      // min-height: 300px;
      max-width: calc(100vw - 500px);
      .gallery-wrapper {
        position: relative;
      }
      .outline-header-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        &.idea {
          padding-left: 0px;
        }
      }
      .outline-header {
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #21a7e0;
        text-align: left;
        margin-right: 10px;
        &.idea {
          color: black;
        }
      }
      .list-group {
        margin-top: 14px;
        overflow-x: hidden;
        overflow-y: auto;
        max-height: 500px;
        border-radius: 5px;
        max-width: 337px;
        min-width: 200px;
        height: 100%; //min-height: 100%;
      }
      .list-group-item {
        height: 40px;
        width: 190px;
        cursor: pointer;
        margin: 1px 1px 14px 3px;
        padding: 11px 8px 10px 12px;
        border-width: 2px;
        border-radius: 20px;
        box-shadow: 1px 3px 6px 1px rgba(0, 0, 0, 0.2);
        background-color: trasparent;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: justify;
        text-justify: inter-word;
        .idea-text {
          min-width: 80%;
        }
        .idea-galary {
          padding-top: 15px;
          text-align: left;
          max-width: 150px;
          word-wrap: break-word;
          text-align-last: left;
        }
        &.idea {
          margin-left: 40px;
        }
        .element-info-wrapper {
          display: none;
          float: right;
        }
        &:hover {
          box-shadow: 0 1px 8px -1px rgba(0, 0, 0, 0.5),
            0 2px 4px 1px rgba(0, 0, 0, 0.15), 0 0 3px 0 rgba(0, 0, 0, 0.5);
          border: solid 3px #21a7e0;
          .element-info-wrapper {
            display: flex;
            margin: 0px;
            padding: 0px;
          }
        }
      }
    }
    .accept-btn {
      background-color: #ffffff;
      color: $zen-blue-default;
      font-size: 18px;
      text-transform: none;
      letter-spacing: normal;
    }
    .presentation-name-wrapper {
      margin-bottom: 30px;
      .presentation-name-label {
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
      }
    }
    .primary-audience-wrapper {
      margin-bottom: 48px;
      .primary-audience-label {
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
    .presentation-type-wrapper {
      .presentation-type-label {
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
      }
    }
  }

  .analyzing-area {
    height: 100%;
    padding: 20px;
    width: 100%;
  }

  .analyzing-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    width: 100%;

    .analyzing-wrapper-header {
      font-size: 24px;
      font-stretch: normal;
      font-style: normal;
      font-weight: normal;
      line-height: normal;
      letter-spacing: normal;
      margin-bottom: 30px;
      text-align: center;
    }
  }

  .action-wrapper {
    padding-top: 50px;
    text-align: center;

    // button {
    //   color: white;
    //   font-size: 24px;
    //   font-stretch: normal;
    //   font-style: normal;
    //   font-weight: bold;
    //   line-height: normal;
    //   letter-spacing: normal;
    //   text-align: center;
    // }
  }

  // .search-bar {
  //   margin-top: 14px;
  //   height: 40px;
  //   width: 190px;
  //   align-items: left;
  //   justify-content: flex-start;
  //   position: relative;
  // }
  .search-bar {
    margin-top: 14px;
    width: 190px;
    z-index: 3;
  }
}

.mention-item {
  padding: 4px 10px;
  border-radius: 4px;
}

.mention-selected {
  background: rgb(192, 250, 153);
}

.add-icon {
  color: #21a7e0;
  position: relative;
  transform: translateY(-2px);
}

.theme--light.v-stepper
  .v-stepper__step:not(.v-stepper__step--active):not(.v-stepper__step--complete):not(.v-stepper__step--error)
  .v-stepper__step__step {
  background: white;
  display: inline-block;
  border: 3px solid #00000061;
}

.theme--light.v-stepper .v-stepper__step__step {
  color: transparent !important;
}

.theme--light.v-stepper .v-stepper__step__step.primary {
  background-color: transparent !important;
  border: 3px solid #6faec3;
}

.theme--light.v-stepper .v-stepper__step__step .v-icon {
  color: #6faec3;
}

.v-stepper__step--complete + .v-divider {
  border-color: $zen-blue-default !important;
}

.theme--light.v-stepper .v-stepper__header .v-divider {
  margin-top: 12px !important;
}

.v-stepper__header {
  box-shadow: none !important;
}

.v-divider {
  margin: 35px -76px 0 !important;
  border-width: 1.5px 0 0 0 !important;
}

.v-stepper__step--complete > .v-stepper__label {
  color: #6faec3 !important;
}

.theme--light.v-stepper .v-stepper__step__step .v-icon {
  color: #6faec3;
  font-size: 35px;
  position: relative;
  left: 3px;
  top: -4px;
}

.dialog-actions-fab {
  align-items: flex-start;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  margin-right: 200px;
  margin-top: 20px;
  width: 100%;

  ::v-deep .new-slide-detail-speed .v-speed-dial__list {
    left: 0;
  }

  ::v-deep .download-list {
    width: 208px;
    border-radius: 2px;
    box-shadow: 0 2px 4px 0 #68788c;
    position: absolute;
    top: -166px;
    right: -10px;
    .download-list-item {
      font-size: 20px;
      padding-bottom: 5px;
      background: white;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: black;
      text-align: left;
      padding-left: 5px;
      cursor: pointer;

      &:hover {
        background: #f2f2f2;
      }
    }
  }

  button.cancel {
    border-radius: 25px;
    border: solid 2px #21a7e0;
    text-transform: none;
    color: #21a7e0 !important;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-right: 40px;
  }
  button.download {
    border-radius: 25px;
    text-transform: none;
    color: white !important;
    background: #21a7e0;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
}
.download-btn-fab {
  margin-left: -15px;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: black;
  text-transform: none;

  .v-btn::before {
    background-color: transparent !important;
    text-align: left;
  }
  .v-btn__content {
    justify-content: flex-start !important;
  }
  .add-icon {
    color: #21a7e0;
    position: relative;
    transform: translateY(-2px);
  }

  .v-list .v-list-item--active .v-icon {
    color: black;
  }
  &:hover::before {
    opacity: 0 !important;
  }
  .v-list-item .v-list-item__title {
    font-size: 0.9vw !important;
  }
  .v-select__slot input {
    font-size: 0.9vw !important;
  }
  .v-label {
    font-size: 0.9vw;
  }
  .audience-list-content--auto {
    font-size: 0.9vw;
  }
}

.vue-suggestion.inactive {
  opacity: 0.5;
}

#gallery-search-icon {
  cursor: pointer;
}
.option-wrapper {
  display: flex;
  align-items: flex-end;
  .plus-icon {
    font-size: 30px;
    margin-right: 10px;
  }
  .prez-heading__wrapper {
    width: 100% !important;
  }
  .prez-heading,
  .prez-description {
    max-width: 43vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .prez-heading {
    font-size: 14px;
    color: black;
  }
  .prez-description {
    font-size: 12px;
  }
}
.v-list .v-list-item--active {
  color: #21a7e0 !important;
}
.v-list .v-list-item--active .v-icon {
  color: rgba(0, 0, 0, 0.87);
}
.v-list-item--two-line {
  min-height: unset !important;
}
.prezentaion-list-item--add {
  position: sticky;
  bottom: 0;
  background: white;
  border-top: 1px solid #ccc;

  &.add-list-item {
    bottom: 49px;
  }

  .v-list-item__title {
    align-items: center;
    display: flex;
    font-size: 14px;

    .add-icon {
      margin-right: 10px;
    }
  }

  .prez-heading-primary {
    justify-content: center;
    font-size: 14px;
    color: #21a7e0;
  }
}
.v-tooltip__content {
  .option-tooltip-wrapper {
    max-width: 43vw;
    .prez-heading {
      font-size: 14px;
      color: black;
    }
    .prez-description {
      font-size: 12px;
      color: #666666;
    }
  }
  .option-tooltip-wrapper__small {
    max-width: 25vw;
    .prez-heading {
      font-size: 14px;
      color: black;
    }
    .prez-description {
      font-size: 12px;
      color: #666666;
    }
    ::v-deep .v-list-item__subtitle,
    .prez-description {
      white-space: normal !important;
    }
  }
}
</style>
