<template>
  <div v-if="isLoading" class="text-center spinner-container">
    <Loading />
  </div>
  <div class="storyline" v-else-if="items && items.length && items.length > 0">
    <div class="storyline-title">
      {{ $t('profile.storylinesTabContent.myCustomStorylines') }}
    </div>
    <div>
      <v-data-table
        v-if="!isLoading"
        :headers="tableHeaders"
        :items="filteredStorylines"
        :footer-props="{
          itemsPerPageOptions: [10, 25, 50, 100],
        }"
        class="storyline-table"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <div class="storyline-searchbar">
              <Search
                :searchKey="search"
                :placeholder="$t('profile.storylinesTabContent.searchByAuthor')"
                :onSearch="onSearch"
              />
              <v-btn
                rounded
                class="primary-button mb-3"
                color="#21a7e0"
                height="48"
                @click="handleNewBuild"
                data-pendo-id="build-new-prezentation"
              >
                {{ $t('profile.storylinesTabContent.buildNewPrezentation') }}
              </v-btn>
            </div>
          </v-toolbar>
        </template>
        <template v-slot:[`item.searchTypeDescription`]="{ item }">
          <div>
            <v-tooltip
              top
              max-width="300"
              content-class="tooltip-content"
              fixed
              open-delay="500"
              close-delay="300"
            >
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                  class="outline-title"
                  @click="() => handleViewStoryline(item)"
                >
                  {{ item.type }}
                </div>
              </template>
              <span>{{ item.type }}</span>
            </v-tooltip>
            <v-tooltip
              top
              max-width="300"
              content-class="tooltip-content"
              fixed
              open-delay="500"
              close-delay="300"
            >
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on" class="outline-description">
                  {{ item.description }}
                </div>
              </template>
              <span>{{ item.description }}</span>
            </v-tooltip>
          </div>
        </template>
        <template v-slot:[`item.displayDate`]="{ item }">
          <div>
            {{ formatDate(item.displayDate) }}
          </div>
        </template>

        <template v-slot:[`item.displayAuthor`]="{ item }">
          <v-img
            v-if="item.isPrezentGenerated"
            src="/assets/img/prezent_avatar.svg"
            width="22"
          ></v-img>
          <div v-else>
            {{ item.displayAuthor }}
          </div>
        </template>

        <template v-slot:[`item.access`]="{ item }">
          <div class="access-col" v-if="item.isOwner">
            <span
              class="access-icon outline-title"
              v-if="!item.shared"
              @click="() => handleShare(item)"
            >
              <v-icon small color="primary"> mdi-account-check-outline </v-icon>
              <span class="access-text">{{ $t('build.step1.private') }}</span>
            </span>
            <span
              class="access-icon outline-title"
              v-if="item.shared"
              @click="() => handleShare(item)"
            >
              <v-icon small color="primary">
                mdi-account-multiple-check-outline
              </v-icon>
              <span class="access-text">{{ $t('build.step1.shared') }}</span>
            </span>
          </div>
          <div class="access-col" v-else>
            <span class="access-icon" v-if="item.permissionLevel === 'Editor'">
              <v-icon small> mdi-lock-open-variant-outline </v-icon>
              <span class="access-text">{{ item.permissionLevel }}</span>
            </span>
            <span class="access-icon" v-if="item.permissionLevel === 'Viewer'">
              <v-icon small> mdi-lock-outline </v-icon>
              <span class="access-text">{{ item.permissionLevel }}</span>
            </span>
          </div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip
            top
            max-width="200"
            content-class="tooltip-content"
            fixed
            open-delay="500"
            close-delay="300"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                v-bind="attrs"
                v-on="on"
                class="action-button build-storyline--auto"
                color="primary"
                @click="handleBuildStoryline(item)"
              >
                mdi-plus-circle-outline
              </v-icon>
            </template>
            <span>{{ $t('profileStorylinesList.story_builder_tooltip') }}</span>
          </v-tooltip>
          <v-tooltip
            top
            max-width="200"
            content-class="tooltip-content"
            fixed
            open-delay="500"
            close-delay="300"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                v-bind="attrs"
                v-on="on"
                class="action-button copy-storyline--auto"
                color="primary"
                @click="handleCopyStoryline(item)"
              >
                mdi-content-copy
              </v-icon>
            </template>
            <span>{{
              copiedFPId === item.id
                ? $t('common.copied')
                : $t('userUploadPrezentation.copyLink')
            }}</span>
          </v-tooltip>
          <v-tooltip
            top
            max-width="200"
            content-class="tooltip-content"
            fixed
            open-delay="500"
            close-delay="300"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                v-bind="attrs"
                v-on="on"
                class="action-button duplicate-storyline--auto"
                color="primary"
                @click="handleDuplicateStoryline(item)"
              >
                mdi-plus-box-multiple-outline
              </v-icon>
            </template>
            <span>{{ $t('common.duplicate') }}</span>
          </v-tooltip>
          <v-tooltip
            top
            max-width="200"
            content-class="tooltip-content"
            fixed
            open-delay="500"
            close-delay="300"
            v-if="item.permissionLevel !== 'Viewer'"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                v-bind="attrs"
                v-on="on"
                class="action-button edit-storyline--auto"
                color="primary"
                @click="handleEditStoryline(item)"
              >
                mdi-pencil-outline
              </v-icon>
            </template>
            <span>{{ $t('profile.storylinesTabContent.edit') }}</span>
          </v-tooltip>
          <v-tooltip
            top
            max-width="200"
            content-class="tooltip-content"
            fixed
            open-delay="500"
            close-delay="300"
            v-if="item.isOwner && item.permissionLevel !== 'Viewer'"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                v-bind="attrs"
                v-on="on"
                class="action-button delete-storyline--auto"
                color="primary"
                @click="handleDeleteStoryline(item)"
              >
                mdi-delete-outline
              </v-icon>
            </template>
            <span>{{ $t('common.delete') }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <Loading v-else />
    </div>

    <StorylineModal
      v-if="showEdit"
      :show="showEdit"
      :title="$t('profile.storylinesTabContent.editStorylineDetails')"
      :text="$t('profile.storylinesTabContent.editStorylineDetailsText')"
      :primaryBtnText="$t('profile.storylinesTabContent.saveStorylineBtn')"
      :secondaryBtnText="editButtonText"
      :type="selectedItem.type"
      :description="selectedItem.description"
      :onPrimaryClick="() => handleEditOutline(true)"
      :onSecondaryClick="() => handleEditOutline()"
      :isSecondaryloading="isUpdatingSecondary"
      :isPrimaryloading="isUpdatingPrimary"
      :onChange="handleInputChange"
      :onClose="
        () => {
          editError = false;
          showEdit = false;
        }
      "
    />

    <StorylineModal
      v-if="showDuplicate"
      :show="showDuplicate"
      :title="$t('profile.storylinesTabContent.duplicateStoryline')"
      :text="$t('profile.storylinesTabContent.duplicateStorylineText')"
      :secondaryBtnText="$t('common.duplicate')"
      :primaryBtnText="$t('common.cancel')"
      :type="selectedItem.type"
      :description="selectedItem.description"
      :onPrimaryClick="() => (showDuplicate = false)"
      :onSecondaryClick="handleDuplicateOutline"
      :onChange="handleInputChange"
      :isSecondaryloading="isCreating"
      :onClose="() => (showDuplicate = false)"
    />
    <ConfirmationModal
      v-if="showDelete"
      :show="showDelete"
      :title="$t('profile.storylinesTabContent.deleteStoryline')"
      :text="$t('profile.storylinesTabContent.deleteStorylineText')"
      :onPrimaryClick="() => (showDelete = false)"
      :onSecondaryClick="handleDeleteOutline"
      :isConfirming="isDeleting"
      :onClose="() => (showDelete = false)"
    />
    <ConfirmationModal
      v-if="editError"
      :show="editError"
      :title="$t('profile.storylinesTabContent.editStorylineDetails')"
      :text="$t('profile.storylinesTabContent.unableToUpdateStoryline')"
      :primaryBtnText="''"
      :secondaryBtnText="''"
      :onClose="() => (editError = false)"
    />
    <NoAccessModal
      v-if="showNoAccess"
      :noAccess="noAccess"
      :show="showNoAccess"
      :uuid="uuid"
      :isDuplicate="isDuplicate"
      :onClose="
        () => {
          showNoAccess = false;
          noAccess = false;
          isDuplicate = false;
          uuid = null;
          getOutlines();
        }
      "
    />
  </div>
  <div v-else-if="!isLoading">
    <div class="no-outline-access">
      <div class="align-center img">
        <img src="/assets/img/no-results-found-generic.svg" alt />
      </div>
      <p class="no-access-text">
        {{ $t('profile.storylinesTabContent.noStorylineCreated') }}
      </p>
      <div class="actions align-end">
        <v-btn
          rounded
          class="primary-button mb-3"
          color="#21a7e0"
          height="48"
          @click="handleNewBuild"
          data-pendo-id="build-new-prezentation"
        >
          {{ $t('profile.storylinesTabContent.buildNewPrezentation') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { mapState } from 'vuex';
import EventBus from '../../common/event-bus';
import Search from '../../common/Search.vue';
import Loading from '../../common/Loading.vue';
import {
  deleteStorylines,
  getStorylines,
  createStorylines,
  updateStorylines,
  getStorylineByID,
} from '../../../utils/api-helper';
// import AudienceItem from '../../common/AudienceItem.vue';
// import NewFeatureBadge from '../../common/NewFeatureBadge.vue';
import ConfirmationModal from '../../common/ConfirmationDialog.vue';
import StorylineModal from './StorylineModal.vue';
import ShareStoryline from '../Storyline/ShareStoryline.vue';
import NoAccessModal from '../Storyline/NoAccessModal.vue';
import { trackProfileEvents } from '../../common/Analytics/ProfileEvents';
import {
  TD_PLACEMENT,
  TD_PREZTYPE,
  TD_PROFILE_STORYLINES_BUILDNEWPREZ_PLACEMENT,
  TD_PROFILE_STORYLINES_BUILDNEWPREZ_PLACEMENT_NO_RECORDS,
  TD_STORYLINE_ORIGIN_TABLE,
} from '../../common/Analytics/MatomoTrackingDataHelper';

export default {
  name: 'Storylines',
  data() {
    return {
      tableHeaders: [
        {
          text: 'Name',
          value: 'searchTypeDescription',
          width: '35%',
        },
        {
          text: 'Updated on',
          value: 'displayDate',
        },
        { text: 'Author', value: 'displayAuthor' },
        { text: 'Access', value: 'access' },
        { text: 'Actions', value: 'actions', sortable: false, width: '200px' },
      ],
      isLoading: true,
      items: [],
      search: '',
      selectedItem: {},
      showDelete: false,
      isDeleting: false,
      showDuplicate: false,
      isCreating: false,
      showEdit: false,
      isUpdatingPrimary: false,
      isUpdatingSecondary: false,
      copiedFPId: '',
      showShare: false,
      editButtonText: 'storyline.saveEdits',
      editError: false,
      showNoAccess: false,
      noAccess: false,
      isDuplicate: false,
      uuid: null,
    };
  },
  components: {
    Loading,
    Search,
    ConfirmationModal,
    StorylineModal,
    NoAccessModal,
  },
  computed: {
    ...mapState('users', ['currentUser']),
    filteredStorylines() {
      if (!this.search) {
        return this.storylines;
      }
      const searchTerms = this.search.toLowerCase().split(' ');
      return this.storylines.filter((storyline) => {
        const filterByFields = [
          storyline?.type,
          storyline?.description,
          storyline?.extension,
          storyline?.fullName,
          storyline?.userID,
        ];
        // Check if every search term is found in at least one of the storyline properties
        return (
          filterByFields.some(
            (field) =>
              field &&
              searchTerms.every((term) => field.toLowerCase().includes(term)),
          ) ||
          searchTerms.every((term) =>
            storyline?.sections?.list.some((section) =>
              section?.sectionName.toLowerCase().includes(term),
            ),
          )
        );
      });
    },
    storylines() {
      return this.items.filter(
        (ele) => !(ele.type === 'Custom' && ele.isPrezentGenerated),
      );
    },
  },
  async mounted() {
    this.getOutlines();
    EventBus.$on('GOTO_STORYLINES', (query) => {
      this.search = query;
    });
    console.log(this.currentUser.user);
  },
  beforeDestroy() {
    EventBus.$off('GOTO_STORYLINES');
  },
  methods: {
    getOutlines() {
      this.isLoading = true;
      getStorylines({ addInternal: true })
        .then((res) => {
          if (res.data) {
            let storyLinesList = res.data?.items?.map((item) => ({
              ...item,
              displayDate: item.isPrezentGenerated ? '-' : item.updatedAt,
              displayAuthor: item.isOwner ? 'Me' : item.fullName,
              searchTypeDescription: `${item.type} ${item.description}`,
              // eslint-disable-next-line no-nested-ternary
              access: item.isOwner
                ? item.shared
                  ? 'Shared'
                  : 'Private'
                : item.permissionLevel,
            }));
            const prezGeneratedstoryLinesList = storyLinesList.filter(
              (storyLine) => storyLine.displayDate === '-',
            );
            storyLinesList = storyLinesList.filter(
              (storyLineItem) => storyLineItem.displayDate !== '-',
            );
            this.items = [...storyLinesList, ...prezGeneratedstoryLinesList];
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onSearch(val) {
      this.search = val;
    },
    handleShare(data) {
      this.showShare = true;
      this.selectedItem = { ...data };
      trackProfileEvents.profileStorylinesShare(
        this.currentUser,
        this.getOtherDataForMatomo(data),
      );
      this.$modal.show(
        ShareStoryline,
        {
          data,
          onUpdate: () => {
            this.getOutlines();
          },
          origin: TD_STORYLINE_ORIGIN_TABLE,
        },
        {
          name: 'ShareStoryline',
          width: 700,
          height: 'auto',
          minHeight: 300,
          styles: { borderRadius: '20px' },
        },
      );
    },
    handleNewBuild() {
      this.$router.push(`/home/build`);
      if (this.items?.length) {
        trackProfileEvents.profileStorylinesBuildnewPrez(this.currentUser, {
          [TD_PLACEMENT]: TD_PROFILE_STORYLINES_BUILDNEWPREZ_PLACEMENT,
        });
      } else {
        trackProfileEvents.profileStorylinesBuildnewPrez(this.currentUser, {
          [TD_PLACEMENT]:
            TD_PROFILE_STORYLINES_BUILDNEWPREZ_PLACEMENT_NO_RECORDS,
        });
      }
    },
    handleBuildStoryline(data) {
      console.log(data);
      trackProfileEvents.profileStorylinesBuild(this.currentUser);
      this.$router.push(`/home/build?outline_id=${data.uuid}`);
    },
    handleCopyStoryline(data) {
      console.log(data);
      trackProfileEvents.profileStorylinesCopylink(
        this.currentUser,
        this.getOtherDataForMatomo(data),
      );
      const copyLink = `${window.location.origin}/home/storyline/${data.uuid}`;
      navigator.clipboard.writeText(copyLink);
      this.copiedFPId = data.id;
    },
    handleEditStoryline(data) {
      console.log(data);
      trackProfileEvents.profileStorylinesEdit(
        this.currentUser,
        this.getOtherDataForMatomo(data),
      );
      this.editButtonText = 'storyline.saveEdits';
      this.showEdit = true;
      this.selectedItem = { ...data };
    },
    handleEditOutline(goToStoryline) {
      const { uuid, type, description } = this.selectedItem;
      const otherData = this.getOtherDataForMatomo(this.selectedItem);
      if (goToStoryline) {
        this.isUpdatingPrimary = true;
        trackProfileEvents.profileStorylinesEditSaveeditsandgotostoryline(
          this.currentUser,
          otherData,
        );
      } else {
        this.isUpdatingSecondary = true;
        trackProfileEvents.profileStorylinesEditSaveedits(
          this.currentUser,
          otherData,
        );
      }

      this.uuid = uuid;
      getStorylineByID(uuid)
        .then((response) => {
          console.log(response);
          if (!response.data || !Object.hasOwn(response.data, 'hasAccess')) {
            // storyline deleted
            this.showEdit = false;
            this.showNoAccess = true;
            this.noAccess = false;
          } else if (!response?.data?.hasAccess) {
            // request access
            this.showEdit = false;
            this.showNoAccess = true;
            this.noAccess = true;
          } else if (
            response?.data?.hasAccess &&
            response?.data?.permissionLevel &&
            response.data.permissionLevel === 'Viewer'
          ) {
            // request access
            this.showEdit = false;
            this.showNoAccess = true;
            this.noAccess = true;
          } else if (response?.data?.hasAccess) {
            updateStorylines({ uuid, type, description })
              .then((res) => {
                console.log(res);
                if (res?.error) {
                  this.showEdit = false;
                  this.editError = true;
                } else {
                  this.editButtonText = 'storyline.editsSaved';
                  setTimeout(this.callSetTimeout, 1300);
                }
              })
              .catch((error) => {
                this.showEdit = false;
                this.editError = true;
                console.log(error);
              })
              .finally(() => {
                if (goToStoryline) {
                  this.isUpdatingPrimary = false;
                  this.$router.push(`/home/storyline/${uuid}`);
                } else {
                  this.isUpdatingSecondary = false;
                }
              });
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isUpdatingPrimary = false;
          this.isUpdatingSecondary = false;
        });
    },
    callSetTimeout() {
      this.editButtonText = 'storyline.saveEdits';
      this.showEdit = false;
      this.getOutlines();
    },
    handleViewStoryline(data) {
      trackProfileEvents.profileStorylinesNameofstoryline(
        this.currentUser,
        this.getOtherDataForMatomo(data),
      );
      this.$router.push(`/home/storyline/${data.uuid}`);
    },
    handleDuplicateStoryline(data) {
      console.log(data);
      this.showDuplicate = true;
      this.selectedItem = { ...data };
    },
    handleInputChange(key, value) {
      console.log(key, value);
      if (value) {
        this.selectedItem[key] = value;
      }
    },
    handleDuplicateOutline() {
      this.isCreating = true;
      this.uuid = this.selectedItem.uuid;
      trackProfileEvents.profileStorylinesDuplicate(
        this.currentUser,
        this.getOtherDataForMatomo(this.selectedItem),
      );
      getStorylineByID(this.selectedItem.uuid)
        .then((response) => {
          console.log(response);
          console.log('handleDuplicateStoryline');
          if (!response.data || !Object.hasOwn(response.data, 'hasAccess')) {
            // storyline deleted
            this.showDuplicate = false;
            this.showNoAccess = true;
            this.noAccess = false;
            this.isDuplicate = true;
          } else if (!response?.data?.hasAccess) {
            // request access
            this.showDuplicate = false;
            this.showNoAccess = true;
            this.noAccess = true;
            this.isDuplicate = true;
          } else if (response?.data?.hasAccess) {
            createStorylines({ ...this.selectedItem, uuid: uuidv4() })
              .then((res) => {
                console.log(res);
                this.showDuplicate = false;
                this.getOutlines();
              })
              .catch((error) => {
                console.log(error);
              })
              .finally(() => {
                this.isCreating = false;
              });
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isCreating = false;
        });
    },
    handleDeleteStoryline(data) {
      console.log(data);
      this.showDelete = true;
      this.selectedItem = { ...data };
    },
    handleDeleteOutline() {
      this.isDeleting = true;
      if (this.selectedItem.uuid) {
        deleteStorylines(this.selectedItem.uuid)
          .then((res) => {
            console.log(res);
            this.showDelete = false;
            this.getOutlines();
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.isDeleting = false;
          });
        trackProfileEvents.profileStorylinesDelete(
          this.currentUser,
          this.getOtherDataForMatomo(this.selectedItem),
        );
      }
    },
    getOtherDataForMatomo(data) {
      return {
        [TD_PREZTYPE]: data?.type,
      };
    },

    formatDate(value) {
      if (value === '-') return '-';
      return value ? moment(value).format('MM/DD/YYYY') : 'NA';
    },
  },
};
</script>

<style lang="scss" scoped>
.spinner-container {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.storyline {
  padding: 32px;
  padding-top: 10px;
  margin: 2px;

  ::v-deep .audience-item {
    cursor: pointer;
    padding-left: 0px;

    .v-list-item__content {
      .v-list-item__title.text-name {
        color: $zen-blue-default;
      }
    }
  }

  ::v-deep table {
    thead {
      tr {
        th {
          &:first-child {
            padding-left: 0px;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          &:first-child {
            padding-left: 0px;
          }
        }
      }
    }
  }

  ::v-deep header {
    .v-toolbar__content {
      padding-left: 0px;
    }
  }

  .add-audience {
    color: white;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: normal;
  }

  .storyline-searchbar {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .search-wrapper {
      width: 420px;
    }

    ::v-deep .item-search-input {
      margin-left: 0px;
    }
  }

  .outline-description,
  .outline-title {
    max-width: 350px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .cliploader {
    display: inline-block;
    margin: 30vh auto 0px auto;
  }

  .outline-title {
    color: #21a7e0 !important;
    cursor: pointer;
  }

  .storyline-title {
    font-size: 20px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 700;
    letter-spacing: normal;
    line-height: 24px;
    margin-bottom: 14px;
    text-align: start;
  }

  .action-button {
    margin-right: 10px;
  }

  .access-col {
    display: flex;
    // align-items: center;

    .access-icon {
      display: flex;
    }

    .access-text {
      padding: 2px 5px;
    }
  }

  .v-data-table::v-deep th {
    color: $zen-blue-default !important;
    font-size: 16px !important;
    font-weight: normal;
    min-width: 150px;

    .v-data-table-header__icon {
      margin-top: -2px;
    }
  }
  .primary-button {
    color: white;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    height: 40px !important;
    line-height: normal;
    letter-spacing: normal;
    min-width: 64px;
    text-transform: none !important;
  }
}

.no-outline-access {
  margin: 100px auto;
  .img {
    padding-bottom: 5px;
  }
  .no-access-text {
    font-size: 24px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    width: fit-content;
    margin: 24px auto 30px auto;
  }
  .actions {
    padding: 5px;
  }

  .align-center {
    text-align: center;
    display: flex;
    justify-content: center;
  }

  .primary-button {
    color: white;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    height: 40px !important;
    line-height: normal;
    letter-spacing: normal;
    min-width: 64px;
    text-transform: none !important;
  }
}
</style>
