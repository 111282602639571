<template>
  <div class="upload-success-modal">
    <v-btn class="close-btn" icon @click="handleClose">
      <v-icon dark v-text="`mdi-close`" />
    </v-btn>
    <!-- <br /> -->
    <div class="image">
      <img
        src="/assets/img/uploader/prezentation-uploaded-successfully.svg"
        alt
        width="350px"
      />
    </div>
    <!-- <br /> -->
    <div class="success-text">{{ $t('upload.uploadedSuccessfully') }}</div>
    <div class="sub-text">
      {{ $t('upload.accessPrezentations') }}
    </div>
    <div class="go-to-actions">
      <v-btn
        class="primary-button"
        rounded
        color="primary"
        @click="handleClose()"
      >
        {{ $t('upload.goToPrezentations') }}
      </v-btn>
      <v-btn
        class="primary-button1"
        rounded
        outlined
        width="180"
        color="#21a7e0"
        @click="handleGoToSlides()"
      >
        {{ $t('upload.goToSlides') }}
      </v-btn>
    </div>
    <!-- <br /> -->
    <div class="sub-text-second">
      {{ $t('upload.uploadAnotherPrezentation') }}
    </div>

    <div class="actions">
      <v-btn
        class="primary-button1"
        rounded
        outlined
        width="90"
        color="#21a7e0"
        @click="handleGoToUpload"
      >
        {{ $t('common.upload') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UploadPrezentationConfirmationModal',
  components: {},
  props: {},
  methods: {
    handleClose() {
      this.$modal.hide('UploadPrezentationConfirmationModal');
    },
    handleGoToUpload() {
      this.$router.push(`/home/upload`, () => {});
      this.handleClose();
    },
    handleGoToSlides() {
      this.$router.push(`/home/slides?type=uploaded`, () => {});
      this.handleClose();
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-success-modal {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 16px;

  .go-to-actions {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: 10px 0px 20px 0px;
  }

  .success-text {
    font-weight: 600;
    margin: 20px 0px 10px 0px;
  }

  .sub-text-second {
    margin-bottom: 10px;
  }

  .primary-button {
    &.v-btn--has-bg {
      background-color: #21a7e0 !important;
      color: #fff;
    }
    color: #21a7e0;
    border-color: #21a7e0 !important;
    font-family: Lato;
    font-size: 16px;
    font-weight: bold;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    text-transform: none !important;
  }
  .primary-button1 {
    color: #21a7e0;
    border-color: #21a7e0 !important;
    font-family: Lato;
    text-transform: none !important;
    font-size: 16px;
    font-weight: bold;
    line-height: 19px;
    height: 40px !important;
    letter-spacing: 0em;
  }

  .close-btn {
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 1;
  }
}
</style>
