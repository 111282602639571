<template>
  <div class="drag-drop-wrapper" id="drag-drop-wrapper">
    <div
      id="myPaletteDiv"
      style="height: 80px"
      :class="`${currentIndex === 1 ? 'second-questionare' : ''}`"
    />
    <div id="myDiagramDiv" style="height: 80px" />
    <div
      :class="`${
        result.first || result.second || result.third
          ? 'message-blocks result-page'
          : 'message-blocks'
      }`"
      v-if="currentIndex === 0"
    >
      <p>{{ $t('learn.novice.earlyStage') }}</p>
      <p>{{ $t('learn.novice.middleStage') }}</p>
      <p>{{ $t('learn.novice.endStage') }}</p>
    </div>
    <div
      :class="`${
        result.first || result.second
          ? 'message-blocks-second result-page-second'
          : 'message-blocks-second'
      }`"
      v-if="currentIndex === 1"
    >
      <p>
        <span style="float: left"> {{ $t('learn.novice.text3') }}<br /> </span
        >{{ $t('learn.novice.text4') }}
      </p>
      <p>
        <span style="float: left"
          >{{ $t('learn.novice.text1') }} <br />
          {{ $t('learn.novice.text2') }}</span
        >
      </p>
    </div>
    <div class="drag-result-wrapper">
      <div class="result-wrapper first-result-wrapper">
        <div class="result-final-wrapper" v-if="result.first === true">
          <div class="result-title">
            <img
              src="/assets/img/learn/practice-well-done.png"
              width="36px"
              height="36px"
              alt=""
            />
            <div class="result-title-content">
              {{ $t('learn.novice.niceChoice') }}
            </div>
          </div>
          <div class="result-description">
            {{ result.firstMessage1 }}<br />{{ result.firstMessage2 }}
          </div>
        </div>
        <div class="result-final-wrapper" v-if="result.first === false">
          <div class="result-title">
            <img src="/assets/img/learn/practice-highlight.svg" alt="" />
            <div class="result-title-content">{{ $t('common.tryAgain') }}</div>
          </div>
          <div class="result-description">
            {{ result.firstMessage1 }}<br />{{ result.firstMessage2 }}
          </div>
        </div>
      </div>
      <div class="result-wrapper second-result-wrapper">
        <div class="result-final-wrapper" v-if="result.second === true">
          <div class="result-title">
            <img
              src="/assets/img/learn/practice-well-done.png"
              width="36px"
              height="36px"
              alt=""
            />
            <div class="result-title-content">
              {{ $t('learn.novice.niceChoice') }}
            </div>
          </div>
          <div class="result-description">
            {{ result.secondMessage1 }}<br />{{ result.secondMessage2 }}
          </div>
        </div>
        <div class="result-final-wrapper" v-if="result.second === false">
          <div class="result-title">
            <img src="/assets/img/learn/practice-highlight.svg" alt="" />
            <div class="result-title-content">{{ $t('common.tryAgain') }}</div>
          </div>
          <div class="result-description">
            {{ result.secondMessage1 }}<br />{{ result.secondMessage2 }}
          </div>
        </div>
      </div>
      <div
        class="result-wrapper third-result-wrapper"
        v-if="result.third !== null"
      >
        <div class="result-final-wrapper" v-if="result.third === true">
          <div class="result-title">
            <img
              src="/assets/img/learn/practice-well-done.png"
              width="36px"
              height="36px"
              alt=""
            />
            <div class="result-title-content">
              {{ $t('learn.novice.niceChoice') }}
            </div>
          </div>
          <div class="result-description">
            {{ result.thirdMessage1 }}<br />{{ result.thirdMessage2 }}
          </div>
        </div>
        <div class="result-final-wrapper" v-if="result.third === false">
          <div class="result-title">
            <img src="/assets/img/learn/practice-highlight.svg" alt="" />
            <div class="result-title-content">{{ $t('common.tryAgain') }}</div>
          </div>
          <div class="result-description">
            {{ result.thirdMessage1 }}<br />{{ result.thirdMessage2 }}
          </div>
        </div>
      </div>
    </div>
    <div class="action-wrapper">
      <v-btn
        class="action-button submit"
        v-if="!redo && !done"
        id="SaveButton"
        width="157"
        height="50"
        rounded
        color="#20a7e0"
        :disabled="disableSubmit"
        @click="save()"
      >
        {{ $t('common.submit') }}
      </v-btn>
      <v-btn
        class="action-button try-again"
        v-else-if="!done"
        id="TryAgainButton"
        width="157"
        height="50"
        rounded
        color="#20a7e0"
        @click="tryAgain()"
      >
        {{ $t('common.tryAgain') }}
      </v-btn>
      <v-btn
        class="action-button try-again"
        v-else
        id="NextButton"
        width="232"
        height="50"
        rounded
        color="#20a7e0"
        @click="updateExerciseIndex()"
      >
        {{
          currentIndex === 1
            ? $t('learn.novice.keepLearning')
            : $t('learn.novice.nextQuestion')
        }}
      </v-btn>
    </div>
  </div>
</template>
<script>
import go from 'gojs';

export default {
  name: 'DragAndDrop',
  data() {
    return {
      redo: false,
      done: false,
      CellSize: new go.Size(230, 50),
      diagCellSize: new go.Size(220, 50),
      myDiagram: null,
      myPalette: null,
      paletteData: [],
      loadValue: {
        class: 'go.TreeModel',
      },
      result: {
        first: null,
        second: null,
        third: null,
        firstMessage1: '',
        firstMessage2: '',
        secondMessage1: '',
        secondMessage2: '',
        thirdMessage1: '',
        thirdMessage2: '',
      },
    };
  },
  props: {
    exerciseData: {
      type: Object,
      required: true,
    },
    currentIndex: {
      type: Number,
      required: true,
    },
    updateExerciseIndex: {
      type: Function,
      required: true,
    },
  },
  watch: {
    currentIndex() {
      this.redo = false;
      this.done = false;
      this.init();
    },
  },
  computed: {
    disableSubmit() {
      let isDisableSubmit = true;
      if (this.myDiagram) {
        const { model } = this.myDiagram;
        for (let k = 1; k <= 10; k++) {
          const data = model.findNodeDataForKey(k);
          if (data) {
            if (data.name !== '') {
              isDisableSubmit = false;
            }
          }
        }
      }
      return isDisableSubmit;
    },
  },
  methods: {
    // The user cannot delete selected nodes in the Palette with the Delete key or Control-X,
    // but they can if they do so programmatically.
    removeFromPalette() {
      this.myPalette.commandHandler.deleteSelection();
    },
    init() {
      this.result.first = null;
      this.result.second = null;
      this.result.third = null;
      document.getElementById('myDiagramDiv').innerHTML = '';
      document.getElementById('myPaletteDiv').innerHTML = '';
      this.loadValue.nodeDataArray = this.exerciseData.nodeDataArray;
      go.Diagram.licenseKey =
        '73f947eab26331b700ca0d2b113f69ed1bb37a639dd41ef25d5841f3ef01694370c9ed7b58d099d0d5f11ef41b7e938a8fc76a2e96440c3be339da8847e281ffb26275e01609108ba05071969afa38b1fa2d21f4c2e727a3c8688ff3e8a8dc9e43eb';

      const $ = go.GraphObject.make; // for conciseness in defining templates
      if (this.myDiagram && this.myDiagram.div) {
        this.myDiagram.div = null;
      }
      if (this.myPalette && this.myPalette.div) {
        this.myPalette.div = null;
      }
      this.myDiagram = $(
        go.Diagram,
        'myDiagramDiv', // must be the ID or reference to div
        {
          maxSelectionCount: 1, // users can select only one part at a time
          allowDrop: true,
          initialContentAlignment: go.Spot.Left,
          ExternalObjectsDropped(e) {
            e.diagram.commandHandler.deleteSelection();
          },
          allowHorizontalScroll: false,
          allowVerticalScroll: false,
          initialDocumentSpot: go.Spot.Top,
          initialViewportSpot: go.Spot.Top,

          'undoManager.isEnabled': true, // enable undo & redo
        },
      );

      // define the Node template
      this.myDiagram.nodeTemplate = $(
        go.Node,
        'Auto',
        new go.Binding('location', 'loc').makeTwoWay(),
        {
          mouseDragLeave(e, node) {
            node.findObject('SHAPE').stroke = 'black';
            node.findObject('SHAPE').fill = 'white';
          },
          mouseDrop: (e, node) => {
            // var diagram = node.diagram;
            // var selnode = diagram.selection.first();  // assume just one Node in selection
            const droppedPart = node.diagram.selection.first().part;
            const val = droppedPart.data.name;
            const msg1 = droppedPart.data.message1;
            const msg2 = droppedPart.data.message2;
            e.diagram.model.setDataProperty(node.data, 'name', val);
            e.diagram.model.setDataProperty(node.data, 'message1', msg1);
            e.diagram.model.setDataProperty(node.data, 'message2', msg2);
            this.removeFromPalette();
          },
        },
        // for sorting, have the Node.text be the data.name
        new go.Binding('text', 'name'),
        // bind the Part.layerName to control the Node's layer depending on whether it isSelected
        // new go.Binding("layerName", "isSelected", function(sel) { return sel ? "Foreground" : ""; }).ofObject(),
        // define the node's outer shape
        $(
          go.Shape,
          'RoundedRectangle',
          {
            name: 'SHAPE',
            minSize:
              this.currentIndex === 0
                ? this.diagCellSize
                : new go.Size(330, 50),
            desiredSize:
              this.currentIndex === 0
                ? this.diagCellSize
                : new go.Size(330, 50), // initially 1x1 cell
          },
          new go.Binding('fill', 'color'),
          new go.Binding('stroke', 'strokecolor'),
          new go.Binding('desiredSize', 'size', go.Size.parse).makeTwoWay(
            go.Size.stringify,
          ),
        ),
        // with the textual key in the middle
        $(
          go.TextBlock,
          {
            // alignment: go.Spot.Center,
            font: '12px sans-serif',
            wrap: go.TextBlock.WrapFit,
            width: this.currentIndex === 0 ? 180 : 300,
            margin: 10,
          },
          new go.Binding('text', 'name'),
        ),
      ); // end Node

      // create the Palette
      this.myPalette = $(go.Palette, 'myPaletteDiv');
      this.myPalette.findLayer('Tool').opacity = 0.5;

      // the Palette's node template is different from the main Diagram's
      this.myPalette.nodeTemplate = $(
        go.Node,
        'Auto',
        {
          mouseEnter(e, node) {
            // eslint-disable-next-line no-param-reassign
            node.findObject('SHAPE').fill = '#E1F5FE';
          },
          mouseLeave(e, node) {
            // eslint-disable-next-line no-param-reassign
            node.findObject('SHAPE').fill = 'white';
          },
        },
        $(
          go.Shape,
          'RoundedRectangle',
          {
            name: 'SHAPE',
            fill: 'white',
            minSize:
              this.currentIndex === 0 ? this.CellSize : new go.Size(330, 50),
            desiredSize:
              this.currentIndex === 0 ? this.CellSize : new go.Size(330, 50), // initially 1x1 cell
            strokeWidth: 1.5,
          },
          new go.AnimationTrigger('stroke'),
          new go.Binding('fill', 'color'),
          new go.Binding('desiredSize', 'size', go.Size.parse).makeTwoWay(
            go.Size.stringify,
          ),
        ),
        // with the textual key in the middle
        $(
          go.TextBlock,
          { alignment: go.Spot.Center, font: '12px sans-serif' },
          new go.Binding('text', 'name'),
        ),
      );
      // the list of data to show in the Palette
      this.myPalette.model.nodeDataArray = this.exerciseData.myPaletteArray;

      // read in the JSON-format data from the "mySavedModel" element
      this.loadGo();
    }, // end init// end init

    // Show the diagram's model in JSON format
    save() {
      const modelJson = this.loadValue;
      const { model } = this.myDiagram;
      this.paletteData = [];

      const firstData = model.findNodeDataForKey(1);
      this.myDiagram.nodes.each((n) => {
        // eslint-disable-next-line no-param-reassign
        n.findObject('SHAPE').minSize = new go.Size(300, 50);
      });
      this.myDiagram.nodes.each((n) => {
        // eslint-disable-next-line no-param-reassign
        n.findObject('SHAPE').desiredSize = new go.Size(300, 50);
      });
      if (firstData) {
        this.result.firstMessage1 = firstData.message1;
        this.result.firstMessage2 = firstData.message2;
        if (firstData.name !== firstData.target) {
          this.result.first = false;
        } else {
          this.result.first = true;
        }
      }
      const secondData = model.findNodeDataForKey(2);
      if (secondData) {
        this.result.secondMessage1 = secondData.message1;
        this.result.secondMessage2 = secondData.message2;
        if (secondData.name !== secondData.target) {
          this.result.second = false;
        } else {
          this.result.second = true;
        }
      }
      const thirdData = model.findNodeDataForKey(3);
      if (thirdData) {
        this.result.thirdMessage1 = thirdData.message1;
        this.result.thirdMessage2 = thirdData.message2;
        if (thirdData.name !== thirdData.target) {
          this.result.third = false;
        } else {
          this.result.third = true;
        }
      }
      const palletPoints = [
        new go.Point(30, 30),
        new go.Point(320, 30),
        new go.Point(600, 30),
      ];
      let k;
      let data;
      for (k = 1; k <= 3; k++) {
        data = model.findNodeDataForKey(k);
        if (data) {
          if (data.name !== data.target) {
            // var pdata = {
            //   key: k,
            //   name: data.name,
            // };
            const tempData = {
              key: k,
              name: data.name,
              message1: data.message1,
              message2: data.message2,
              color: 'white',
              loc: palletPoints[k - 1],
            };
            this.redo = true;
            modelJson.nodeDataArray[k - 1].name = '';
            this.paletteData.push(tempData);
            model.startTransaction('modified property');
            model.set(data, 'color', 'red');
            // ... maybe modify other properties and/or other data objects
            model.commitTransaction('modified property');
          } else {
            model.startTransaction('modified property');
            model.set(data, 'color', 'white');
            model.commitTransaction('modified property');
          }
        }
      }

      for (k = 1; k <= 3; k++) {
        const points = [
          new go.Point(30, 50),
          new go.Point(400, 50),
          new go.Point(780, 50),
        ];
        data = model.findNodeDataForKey(k);
        if (data && this.currentIndex === 0) {
          model.startTransaction('modified property');
          model.set(data, 'loc', points[k - 1]);
          model.commitTransaction('modified property');
        }
      }
      if (!this.redo) {
        this.done = true;
        this.myDiagram.isModified = false;
      }
    },

    tryAgain() {
      this.redo = false;
      this.myPalette.model.nodeDataArray = this.paletteData;
      this.loadGo();
    },

    loadGo() {
      this.myDiagram.model = go.Model.fromJson(this.loadValue);
      // make sure new data keys are unique positive integers
      let lastkey = 1;
      this.myDiagram.model.makeUniqueKeyFunction = (model, data) => {
        let k = data.key || lastkey;
        while (model.findNodeDataForKey(k)) k++;
        // eslint-disable-next-line no-param-reassign
        data.key = k;
        lastkey = k;
        return k;
      };
    },
  },
  mounted() {
    this.init();
    this.redo = false;
    this.done = false;
  },
};
</script>

<style lang="scss" scoped>
.drag-drop-wrapper {
  width: 100%;
  position: relative;
  .action-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 20px;

    .action-button {
      font-size: 24px;
      font-weight: bold;
      color: white;
      text-transform: none;
    }
  }
  .drag-result-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    .result-wrapper {
      flex: 1;
      padding-right: 15px;
      flex-basis: 350px;
    }

    .result-final-wrapper {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: flex-start;

      .result-title {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 10px;

        img {
          margin-right: 15px;
        }
      }

      .result-description {
        text-align: left;
        font-size: 16px;
      }
    }
  }
}
.message-blocks {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  font-weight: bold;
  margin: 0 10% 0 10%;
  p {
    text-align: center;
  }
}
.message-blocks-second {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  font-weight: bold;
  margin: 0 20% 0 0%;

  p {
    text-align: center;
  }
}
.result-page {
  margin: 0 25% 0 10%;
}
.result-page-second {
  margin: 0 24% 0 0;
}
</style>
