<template>
  <div
    v-if="card.title && card.title === 'Add new payment method'"
    class="addCard"
    @click="handleActionClick(card.title)"
  >
    <v-icon :size="44" color="primary">{{ 'mdi-plus' }}</v-icon>
    <div
      class="cardTitle"
      :style="{
        'font-size': `${currentScreenWidth > 1900 ? '18px' : '20px'}`,
        padding: `${getAddNewPaymentTextPadding()}`,
      }"
    >
      {{ translatedTitle }}
    </div>
  </div>
  <div class="savedCards" v-else>
    <div class="iconContainer">
      <div
        v-if="
          !card.paymentMethodBrand ||
          (card.paymentMethodBrand && !logos[card.paymentMethodBrand])
        "
        class="cardIconAbsent"
      >
        <v-img
          :src="'/assets/img/Generic-card.svg'"
          alt="image"
          :aspect-ratio="16 / 9"
          lazy-src="/assets/img/slides/placeholder-slide.svg"
        />
      </div>
      <div v-else class="cardIconPresent">
        <v-img
          :src="`${logos[card.paymentMethodBrand]}`"
          alt="image"
          :aspect-ratio="16 / 9"
          lazy-src="/assets/img/slides/placeholder-slide.svg"
        />
      </div>
    </div>
    <div class="otherCardInfo">
      <div class="maskedCardNumber">
        <div class="mask">{{ '**** **** ****' }}</div>
        <div class="last4Numbers">
          {{ `${card.paymentMethodLast4}` }}
        </div>
      </div>
      <div class="cardHolderName">
        {{ card.name }}
      </div>
      <div class="cardExpiry" v-if="!isCardExpired">
        {{
          `${$t('profile.editPaymentDetail.expiresOn')} ${
            !(card.exp_month.toString().length > 1)
              ? `0${card.exp_month.toString()}`
              : `${card.exp_month.toString()}`
          }/${card.exp_year.toString().slice(-2)}`
        }}
      </div>
      <div class="cardExpired" v-else>
        {{
          `${$t('profile.editPaymentDetail.expiredOn')} ${
            !(card.exp_month.toString().length > 1)
              ? `0${card.exp_month.toString()}`
              : `${card.exp_month.toString()}`
          }/${card.exp_year.toString().slice(-2)}`
        }}
      </div>
    </div>
    <div class="cardActionsAndStatus">
      <div class="status">
        <v-icon
          class="current-subscription-check"
          v-if="card.is_default && card.is_default === true"
          v-tooltip="{
            content: $t('profile.failedPaymentDetail.defaultMethod'),
            placement: 'top',
          }"
        >
          {{ 'mdi-check-circle' }}
        </v-icon>
      </div>
      <div class="actions" v-if="actionsNeeded === true">
        <v-menu
          bottom
          absolute
          offset-y
          :close-on-click="true"
          :close-on-content-click="true"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon :size="30" v-bind="attrs" v-on="on" class="dotsIcon">
              {{ 'mdi-dots-vertical' }}
            </v-icon>
          </template>
          <v-list>
            <v-list-item
              v-for="(option, ind) in [...getOptionsWithCardStatus()]"
              :key="ind"
              @click="handleActionClick(option.title)"
            >
              <v-list-item-title>
                <v-icon>{{ option.icon }}</v-icon>
                <div class="optionTitle">{{ $t(option.display_title) }}</div>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import AddPaymentMethodModal from './AddPaymentMethodModal.vue';
import SwitchPaymentDefaultModal from './SwitchPaymentDefaultModal.vue';
import DeletePaymentMethodModal from './DeletePaymentMethodModal.vue';
import { trackProfileEvents } from '../../../common/Analytics/ProfileEvents';

export default {
  name: 'PaymentMethodCard',
  props: {
    card: {
      type: Object,
      default: () => {},
    },
    noCardsPresent: {
      type: Boolean,
      default: true,
    },
    actionsNeeded: {
      type: Boolean,
      default: true,
    },
    currentScreenWidth: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      cardOptions: [
        {
          title: 'Set as default',
          display_title: 'profile.failedPaymentDetail.setAsDefault',
          icon: 'mdi-check-circle-outline',
        },
        {
          title: 'Edit',
          display_title: 'profile.storylinesTabContent.edit',
          icon: 'mdi-pencil-outline',
        },
        {
          title: 'Delete',
          display_title: 'common.delete',
          icon: 'mdi-delete-outline',
        },
      ],
      logos: {
        visa: '/assets/img/Visa.svg',
        mastercard: '/assets/img/Mastercard.svg',
        amex: '/assets/img/American-Express.svg',
        jcb: '/assets/img/JCB.svg',
        unionpay: '/assets/img/Union-Pay.svg',
        discover: '/assets/img/Discover.svg',
        diners: '/assets/img/Diners-Club.svg',
      },
    };
  },
  computed: {
    ...mapState('users', ['currentUser']),
    isCardExpired() {
      const currDate = new Date();
      const currMonth = currDate.getUTCMonth() + 1;
      const currYear = currDate.getUTCFullYear();
      if (this.card.exp_month && this.card.exp_year) {
        if (currYear > this.card.exp_year) {
          return true;
        }
        if (
          currYear === this.card.exp_year &&
          currMonth > this.card.exp_month
        ) {
          return true;
        }
        return false;
      }
      return false;
    },
    translatedTitle() {
      if (this.card.title === 'Add new payment method') {
        return this.$t('profile.failedPaymentDetail.addNewPaymentMethod');
      }
      return this.card.title;
    },
  },
  methods: {
    ...mapActions('paymentMethods', ['setSnackbar']),
    getOptionsWithCardStatus() {
      const options = [...this.cardOptions];
      if (this.card.is_default === true || this.isCardExpired === true) {
        return options.filter((opt) => opt.title !== 'Set as default');
      }
      return options;
    },

    getAddNewPaymentTextPadding() {
      if (this.currentScreenWidth >= 2592 && this.currentScreenWidth < 3456) {
        return '14px 5px';
      }
      if (this.currentScreenWidth >= 3456) {
        return '28px 5px';
      }
      return '10px 5px';
    },

    openAddNewPaymentMethodModal(mode) {
      this.setSnackbar({
        status: false,
        text: '',
        action: null,
      });
      this.$modal.show(
        AddPaymentMethodModal,
        {
          noCardsPresent: this.noCardsPresent,
          methodMode: mode,
          card: this.card,
        },
        {
          name: 'AddPaymentMethodModal',
          width: '600px',
          height: 'auto',
          // top: '300px !important',
          styles: {
            // top: '300px !important',
            'max-height': '80%',
            'max-width': '800px',
            overflow: 'auto',
            'border-radius': '12px',
          },
          clickToClose: true,
        },
      );
    },

    openDefaultPaymentSwitchModal() {
      this.setSnackbar({
        status: false,
        text: '',
        action: null,
      });
      this.$modal.show(
        SwitchPaymentDefaultModal,
        {
          card: this.card,
        },
        {
          name: 'SwitchPaymentDefaultModal',
          width: '600px',
          height: 'auto',
          // top: '300px !important',
          styles: {
            // top: '300px !important',
            'max-height': '80%',
            'max-width': '800px',
            overflow: 'auto',
            'border-radius': '12px',
          },
          clickToClose: true,
        },
      );
    },

    openDeletePaymentMethodModal() {
      this.setSnackbar({
        status: false,
        text: '',
        action: null,
      });
      this.$modal.show(
        DeletePaymentMethodModal,
        {
          card: this.card,
        },
        {
          name: 'DeletePaymentMethodModal',
          width: '600px',
          height: 'auto',
          // top: '300px !important',
          styles: {
            // top: '300px !important',
            'max-height': '80%',
            'max-width': '800px',
            overflow: 'auto',
            'border-radius': '12px',
          },
          clickToClose: true,
        },
      );
    },

    handleActionClick(type) {
      switch (type) {
        case 'Add new payment method':
          this.openAddNewPaymentMethodModal('createMethod');
          trackProfileEvents.profileAdminSettingsPaymentsAddNewPaymentMethod(
            this.currentUser,
            {},
          );
          break;

        case 'Set as default':
          this.openDefaultPaymentSwitchModal();
          trackProfileEvents.profileAdminSettingsPaymentsElipsisSetAsDefault(
            this.currentUser,
            {},
          );
          break;

        case 'Delete':
          this.openDeletePaymentMethodModal();
          trackProfileEvents.profileAdminSettingsPaymentsElipsisDelete(
            this.currentUser,
            {},
          );
          break;

        case 'Edit':
          this.openAddNewPaymentMethodModal('updateMethod');
          trackProfileEvents.profileAdminSettingsPaymentsElipsisEdit(
            this.currentUser,
            {},
          );
          break;

        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.addCard {
  display: flex;
  height: 100%;
  padding: 32px;
  border-radius: 16px;
  box-shadow: 0 0 4px 0 rgb(0 0 0 / 15%);
  cursor: pointer;
  .cardTitle {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
  &:hover {
    box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.5);
  }
}
.savedCards {
  padding: 8px;
  display: flex;
  height: 100%;
  border-radius: 16px;
  box-shadow: 0 0 4px 0 rgb(0 0 0 / 15%);
  .iconContainer {
    width: 25%;
    padding: 24px 12px;
    .cardIconAbsent,
    .cardIconPresent {
      width: 100%;
      height: 100%;
      ::v-deep .v-image {
        width: 100%;
        height: 100%;
        .v-responsive__content {
          width: 100% !important;
          height: 100% !important;
        }
      }
    }
  }
  .otherCardInfo {
    width: 65%;
    text-align: left;
    padding: 8px 0px;
    .maskedCardNumber {
      display: flex;
      .mask {
        font-size: 26px;
        height: 24px;
        font-weight: 600;
      }
      .last4Numbers {
        padding: 2px 0px 0px 4px;
        font-size: 16px;
        font-weight: 600;
      }
    }
    .cardHolderName,
    .cardExpiry {
      color: #4d5359;
    }
    .cardExpired {
      color: #fa755a;
    }
    .cardHolderName {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .cardActionsAndStatus {
    width: 10%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    .status {
      .current-subscription-check {
        color: $zen-blue-default;
        font-size: 20px;
      }
    }
    .actions {
      .dotsIcon {
        &:hover {
          background-color: #21a7e01c !important;
          color: #21a7e0 !important;
          border-radius: 20px !important;
        }
        &:after {
          background-color: transparent !important;
        }
      }
    }
  }
}
.v-menu__content {
  .v-list {
    .v-list-item {
      cursor: pointer;
      .v-list-item__title {
        display: flex;
        .optionTitle {
          padding: 2px 0px 0px 4px;
        }
      }
      &:hover {
        background-color: #d8d8d8;
      }
    }
  }
}
</style>
