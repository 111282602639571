import { render, staticRenderFns } from "./ComplyScore.vue?vue&type=template&id=5d4b3a70&scoped=true&"
import script from "./ComplyScore.vue?vue&type=script&lang=js&"
export * from "./ComplyScore.vue?vue&type=script&lang=js&"
import style0 from "./ComplyScore.vue?vue&type=style&index=0&id=5d4b3a70&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d4b3a70",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCheckbox,VCol,VImg,VProgressCircular,VRow})
