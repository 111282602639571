<template>
  <div class="manage_container">
    <div class="closeIconContainer">
      <v-icon @click="close">{{ 'mdi-close' }}</v-icon>
    </div>
    <h1 class="manage-heading">
      {{ $t('overnightPresentations.newMessage') }}
    </h1>
    <div class="spinnerContainer" v-if="loading">
      <v-progress-circular indeterminate :size="50" color="#21a7e0" />
    </div>
    <div class="content" v-else>
      <div class="instructions">
        <div class="instructionsHeader">
          {{ $t('overnightPresentations.enterMessage') }}
        </div>
        <v-textarea
          v-model="instructions"
          class="instructionsArea"
          solo
          :label="$t('overnightPresentations.example')"
          :no-resize="true"
        />
      </div>
      <div class="files-desc">
        <div class="files-subheader">
          {{ $t('overnightPresentations.uploadFiles') }}
        </div>
        <div>
          {{ $t('overnightPresentations.provideDocumets') }}
          {{ $t('overnightPresentations.confidentiality') }}
        </div>
      </div>
      <div class="upload-container">
        <div
          class="local-files-wrapper"
          :class="{
            highlight: localFiles.length,
          }"
        >
          <div class="header">
            {{ $t('overnightPresentations.localFiles') }}
          </div>
          <div class="desc">
            <div>{{ $t('overnightPresentations.filesUpload') }}</div>
            <div>
              <span>
                {{ $t('overnightPresentations.maximumFileSize') }}
                <v-tooltip
                  right
                  max-width="300"
                  content-class="tooltip-content"
                  fixed
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="icon-title"
                      v-text="`mdi-information-outline`"
                      :style="{ 'font-size': '16px' }"
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <span>
                    {{ $t('overnightPresentations.fileType') }}
                  </span>
                </v-tooltip>
              </span>
            </div>
          </div>
          <div class="user-input">
            <div class="label">
              {{ $t('overnightPresentations.addAsManyAsYouNeed') }}
            </div>
            <div class="file-chips-wrapper">
              <template v-if="localFiles.length">
                <div
                  class="file-chip"
                  :class="{ 'size-error': lFile.size / 1024 / 1024 > 200 }"
                  v-for="(lFile, i) in localFiles"
                  :key="i"
                  :style="{
                    height: `${getFileItemsHeight(lFile)}`,
                  }"
                >
                  <div class="fileNameWrapper">
                    {{ lFile.fileName ? lFile.fileName : lFile.name }}
                  </div>
                  <v-icon :color="'#7B7B7B'" @click="removeFile(i)">
                    mdi-close-circle-outline
                  </v-icon>
                </div>
              </template>
              <div class="file-chip-placeholder" v-else>
                {{ $t('overnightPresentations.filesWillAppear') }}
              </div>
            </div>
          </div>
          <div class="file-upload-action">
            <label for="file-upload" class="custom-file-upload">
              {{ $t('overnightPresentations.selectFiles') }}
            </label>
            <input
              id="file-upload"
              type="file"
              :accept="`${allowedUploadTypes.join(', ')}`"
              @change="previewFiles"
              multiple
            />
          </div>
        </div>
        <div
          class="cloud-files-wrapper"
          :class="{ highlight: sharedLinks.trim().length }"
        >
          <div class="header">
            {{ $t('overnightPresentations.cloudlinks') }}
          </div>
          <div class="desc">
            <div>{{ $t('overnightPresentations.pasteLinks') }}</div>
            <div>{{ $t('overnightPresentations.filesLargerThan200MB') }}</div>
          </div>
          <div class="user-input">
            <div class="label">
              {{ $t('overnightPresentations.shareableLinkInNewLink') }}
            </div>
            <v-textarea
              v-model="sharedLinks"
              class="share-links"
              solo
              :rules="linkRules"
              :label="$t('overnightPresentations.pasteLinksHere')"
              :no-resize="true"
            ></v-textarea>
          </div>
          <div
            :class="`${
              sharedLinks.length > 0 && !sharedAccess
                ? 'check-access-error'
                : 'check-access'
            }`"
          >
            <v-checkbox
              v-model="sharedAccess"
              :hide-details="true"
              :label="$t('overnightPresentations.sharedAccessWithPrezentium')"
            ></v-checkbox>
            <span class="guide-link" @click="handleHowtoShareAccess">{{
              $t('overnightPresentations.howToShareAccess')
            }}</span>
          </div>
        </div>
      </div>
      <!-- <div class="files-container">
        <table class="files-table" v-if="files && files.length > 0">
          <thead>
            <tr>
              <th class="text-left-heading">File name</th>
              <th class="text-left">Uploaded date</th>
              <th class="text-left">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in files" :key="item.attachmentId">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <td class="text-left-heading" v-bind="attrs" v-on="on">
                    {{ item.fileName }}
                  </td>
                </template>
                <span>{{ item.fileName }}</span>
              </v-tooltip>
              <td class="text-left">{{ getDateFunction(item.createdAt) }}</td>
              <td class="text-left">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="#20a7e0"
                      class="add-icon"
                      v-text="`mdi-tray-arrow-down`"
                      size="16"
                      @click="downloadHandle(item)"
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <span>{{ 'Download' }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="#20a7e0"
                      class="delete-icon"
                      v-text="`mdi-delete-outline`"
                      size="16"
                      @click="handleDelete('file', item)"
                      :disabled="request.status === 'Completed'"
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <span>{{ 'Delete' }}</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-else>{{ 'No files attached to the request' }}</div>
        <v-btn
          @click="handleFileImport"
          class="uploadBtn"
          :disabled="request.status === 'Completed'"
        >
          {{ '+ Upload more files' }}
        </v-btn>
        <input
          ref="uploader"
          class="d-none"
          type="file"
          :accept="`${allowedUploadTypes.join(', ')}`"
          @change="onFileChanged"
          multiple
        />
      </div>
      <div class="uploads">
        <div class="uploadFilesAndLinksContainer">
          <div class="user-input">
            <div class="label">
              <span>
                {{
                  'Add as many files as you need. The maximum file upload size is 200 MB'
                }}
                <v-tooltip
                  right
                  max-width="300"
                  content-class="tooltip-content"
                  fixed
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="icon-title"
                      v-text="`mdi-information-outline`"
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <span>
                    {{
                      'Select a ppt, .pptx, .xls, .xlsx, .doc, .docx, .txt, .pdf, .jpg or .png file.'
                    }}
                  </span>
                </v-tooltip>
              </span>
            </div>
            <div class="file-chips-wrapper">
              <template v-if="localFiles.length">
                <div
                  class="file-chip"
                  :class="{ 'size-error': lFile.size / 1024 / 1024 > 200 }"
                  v-for="(lFile, i) in localFiles"
                  :key="i"
                  :style="{
                    height: `${getFileItemsHeight(lFile)}`,
                  }"
                >
                  <div class="fileNameWrapper">
                    {{ lFile.fileName ? lFile.fileName : lFile.name }}
                  </div>
                  <v-icon :color="'#7B7B7B'" @click="removeFile(i)">
                    {{ 'mdi-close-circle-outline' }}
                  </v-icon>
                </div>
              </template>
              <div class="file-chip-placeholder" v-else>
                {{ 'Your files will appear here' }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <h2 class="manage-sub-heading">Links</h2>
      <div class="links-container">
        <table class="files-table" v-if="links && links.length > 0">
          <thead>
            <tr>
              <th class="text-left-heading">URL</th>
              <th class="text-left">Uploaded date</th>
              <th class="text-left">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in links" :key="item.attachmentId">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <td class="text-left-heading" v-bind="attrs" v-on="on">
                    <a :href="item.linkUrl" target="_blank">
                      {{ item.linkUrl }}
                    </a>
                  </td>
                </template>
                <span>{{ item.linkUrl }}</span>
              </v-tooltip>
              <td class="text-left">{{ getDateFunction(item.createdAt) }}</td>
              <td class="text-left">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="#20a7e0"
                      class="delete-icon"
                      v-text="`mdi-delete-outline`"
                      size="16"
                      @click="handleDelete('link', item)"
                      :disabled="request.status === 'Completed'"
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <span>{{ 'Delete' }}</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-else>{{ 'No links attached to the request' }}</div>
        <v-btn
          class="uploadBtn"
          :disabled="request.status === 'Completed'"
          @click="openTextAreaForLinks"
        >
          {{ '+ Add more links' }}
        </v-btn>
      </div>

      <div class="uploads" v-if="openLinksTextBox">
        <div class="uploadFilesAndLinksContainer">
          <div class="user-input">
            <div class="label">
              {{ 'Paste each shareable link in a new line' }}
            </div>
            <v-textarea
              v-model="sharedLinks"
              class="share-links"
              solo
              :rules="linkRules"
              label="Paste links here"
              :no-resize="true"
            />
          </div>
          <div
            :class="`${
              sharedLinks.length > 0 && !sharedAccess
                ? 'check-access-error'
                : 'check-access'
            }`"
          >
            <v-checkbox
              v-model="sharedAccess"
              :hide-details="true"
              color="#21a7e0"
              label="I have shared access with slides@prezentium.com"
            />
            <span class="guide-link" @click="handleHowtoShareAccess">
              {{ 'How to share access?' }}
            </span>
          </div>
        </div>
      </div> -->

      <div class="actionButtons">
        <div class="buttonsContainer">
          <v-btn
            class="saveButton"
            :disabled="
              (localFiles.length <= 0 &&
                sharedLinks.length <= 0 &&
                filesToBeDeleted.length <= 0 &&
                linksToBeDeleted.length <= 0 &&
                instructions.length <= 0) ||
              (sharedLinks.length > 0 &&
                (!isValidHttpUrl(sharedLinks) || sharedAccess === false))
            "
            @click="handleSaveAttachmentsAndLinks"
            :loading="updating"
          >
            {{ $t('common.submit') }}
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import {
  // getOPManageFiles,
  updateOpRequestWithAttachmentAndLinks,
  deleteOpRequestAttachmentsAndLinks,
} from '../../../utils/api-helper';
import ConfirmationDialoge from './ConfirmationDialoge.vue';
import { downloadFileWithCustomName } from '../../../utils/common';
import HowToShareAccess from './HowToShareAccess.vue';
import Upload from './Upload.vue';
import { trackOvernightPrezentationsEvents } from '../../common/Analytics/OvernightPrezentationsEvents';
import {
  TD_COMMON_COLUMN_NAME,
  TD_COMMON_COLUMN_VALUE,
  TD_INSTRUCTIONS,
  TD_ISDOWNLOAD,
  TD_PREZENTATION_CATEGORY,
  NA,
  TD_PREZNAME,
  TD_PREZTYPE,
  TD_VIEWTYPE,
  TD_EXPANDED_STATUS_VIEW,
  TD_PLACEMENT,
  TD_AUD,
  TD_CHECKED,
  TD_UNCHECKED,
} from '../../common/Analytics/MatomoTrackingDataHelper';

export default {
  name: 'ManageFiles',
  data() {
    return {
      files: [],
      links: [],
      loading: false,
      instructions: [],
      sharedLinks: '',
      linkRules: [
        (v) =>
          this.isValidHttpUrl(v) ||
          v.length <= 0 ||
          'Oops! One or more of the entered links are invalid',
      ],
      sharedAccess: false,
      openLinksTextBox: false,
      updating: false,
      localFiles: [],
      linksToBeDeleted: [],
      filesToBeDeleted: [],
      allowedUploadTypes: [
        '.ppt',
        '.pptx',
        '.xls',
        '.xlsx',
        '.doc',
        '.docx',
        '.txt',
        '.pdf',
        '.jpg',
        '.png',
      ],
    };
  },
  props: {
    id: {
      type: String,
    },
    request: {
      type: Object,
      default: () => ({}),
    },
    isManagedClosed: {
      type: Function,
      default: () => {},
    },
    viewType: {
      type: String,
      default: '',
    },
  },
  async mounted() {
    this.loading = true;
    // await getOPManageFiles(this.id)
    //   .then((res) => {
    //     if (res?.data?.s3) {
    //       this.files = [...res.data.s3];
    //     }
    //     if (res?.data?.link) {
    //       this.links = [...res.data.link];
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    this.loading = false;
  },
  methods: {
    getFileItemsHeight(file) {
      if (file.fileName) {
        if (file.fileName.length <= 40) {
          return '32px';
        }
        return 'auto';
      }
      return file.name.length <= 40 ? '32px' : 'auto';
    },
    async previewFiles(event) {
      // this.localFiles = [...event.target.files];
      const newFilesToUpload = [...event.target.files].filter(
        (fle) => !this.fileAlreadyUploaded(this.localFiles, fle),
      );
      if (newFilesToUpload.length > 0) {
        this.$modal.show(
          Upload,
          {
            filess: [...newFilesToUpload],
            updateLocalFiles: this.updateLocalFiles,
          },
          {
            name: 'upload',
            width: '600px',
            height: 'auto',
            styles: {
              'max-height': '45%',
              'max-width': '600px',
              'border-radius': '12px',
              overflow: 'auto',
            },
            clickToClose: false,
          },
        );
        // event.target.value = '';
      }
      event.target.value = '';
    },
    async handleSaveAttachmentsAndLinks() {
      if (
        this.request.status !== 'Completed' ||
        !this.completedMoreThanSevenDays
      ) {
        this.updating = true;
        // deleting necessary attachments and links before saving new changes
        const attachmentsToBeDeleted = [
          ...this.filesToBeDeleted,
          ...this.linksToBeDeleted,
        ];
        if (attachmentsToBeDeleted.length > 0) {
          const deleteReqs = [...attachmentsToBeDeleted].map(
            async (item) =>
              await deleteOpRequestAttachmentsAndLinks(
                this.request.ticketId,
                item,
              )
                .then((resp) => {
                  console.log(resp);
                })
                .catch((err) => {
                  console.log(err);
                }),
          );
          await Promise.all(deleteReqs);
        }

        // updating new attachments, links and instructions
        let attachmentLinks = [];
        let attachmentFiles = [];

        if (this.sharedLinks) {
          attachmentLinks = this.sharedLinks
            .split('\n')
            .filter((ele) => ele !== '')
            .map((ele) => ({
              linkUrl: ele,
              isShared: true,
            }));
        }

        if (this.localFiles.length) {
          attachmentFiles = [...this.localFiles];
        }

        if (
          attachmentFiles.length > 0 ||
          attachmentLinks.length > 0 ||
          this.instructions.length > 0
        ) {
          const payload = {
            ticketId: this.request.ticketId,
            attachment: {
              files: attachmentFiles,
              links: attachmentLinks,
            },
            instructions:
              this.instructions && this.instructions.length > 0
                ? [this.instructions.replace(/\n/g, '<br />')]
                : [],
          };
          await updateOpRequestWithAttachmentAndLinks(payload)
            .then((resp) => {
              console.log(resp);
            })
            .catch((err) => {
              console.log(err);
            });
        }
        this.updating = false;
      }
      console.log('sharedAccesssharedAccess-->', this.sharedAccess);
      trackOvernightPrezentationsEvents.OPMyRequestsManageFilesAndLinkSave(
        this.currentUser,
        {
          [TD_COMMON_COLUMN_NAME]:
            'I have shared access with slides@prezentium.com',
          [TD_COMMON_COLUMN_VALUE]: this.sharedAccess
            ? TD_CHECKED
            : TD_UNCHECKED,
          [TD_INSTRUCTIONS]:
            this.instructions.length > 0 ? this.instructions : NA,
          ...this.getMatomoDetails(),
        },
      );
      this.close();
    },
    openTextAreaForLinks() {
      this.openLinksTextBox = !this.openLinksTextBox;
    },
    handleHowtoShareAccess() {
      trackOvernightPrezentationsEvents.OPHowToShareAccessClick(
        this.currentUser,
      );
      this.$modal.show(
        HowToShareAccess,
        {},
        {
          name: 'HowToShareAccess',
          classes: 'v-application',
          // width: '1330px',
          height: 'auto',
          styles: {
            borderRadius: '20px',
            padding: '20px',
            'max-height': '800px',
            'min-height': '300px',
            'overflow-y': 'auto',
          },
        },
      );
    },
    isValidHttpUrl(string) {
      const linksArr = string.split('\n').filter((ln) => ln !== '');
      for (let i = 0; i < linksArr.length; i++) {
        let url = linksArr[i];
        try {
          url = new URL(url);
          if (url.protocol === 'http:' || url.protocol === 'https:') {
            console.log(url.protocol);
          } else {
            return false;
          }
        } catch (_) {
          return false;
        }
      }
      return true;
    },
    close() {
      this.isManagedClosed();
      this.$modal.hide('ManageFiles');
    },
    downloadHandle(item) {
      downloadFileWithCustomName(
        item.attachmentUrl,
        item.fileName,
        this.downloadInProgress,
        false,
      );
      trackOvernightPrezentationsEvents.OPMyRequestsManageFilesAndLinkDownload(
        this.currentUser,
        {
          [TD_ISDOWNLOAD]: 1,
          ...this.getMatomoDetails(),
        },
      );
    },
    downloadInProgress(val) {
      console.log(val);
    },
    getDateFunction(date) {
      const tempDate = date ? new Date(date) : new Date();
      return `${tempDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })}`;
    },
    handleDelete(type, item) {
      this.$modal.show(
        ConfirmationDialoge,
        {
          onSecondaryClick:
            type === 'file'
              ? this.handleDeleteConfirmation
              : this.handleLinkDelete,
          item,
          deleteType: type,
        },
        {
          name: 'ConfirmationDialoge',
          classes: 'v-application',
          width: '610px',
          height: 'auto',
          styles: {
            borderRadius: '20px',
            padding: '16px',
            'max-height': '800px',
            // 'min-height': '300px',
            'overflow-y': 'auto',
          },
        },
      );
    },
    getMatomoDetails() {
      const placement = TD_EXPANDED_STATUS_VIEW;
      return {
        [TD_AUD]: this.request?.audienceEmail,
        [TD_PREZNAME]: this.request?.name,
        [TD_PREZTYPE]: this.request?.storyline,
        [TD_VIEWTYPE]: this.$route.query.viewType
          ? this.$route.query.viewType
          : this.viewType,
        [TD_PLACEMENT]: placement,
        [TD_PREZENTATION_CATEGORY]: this.request?.status
          ? this.request?.status
          : NA,
      };
    },
    handleDeleteConfirmation(id) {
      this.$modal.hide('ConfirmationDialoge');
      this.files = this.files.filter((ele) => ele.attachmentId !== id);
      this.filesToBeDeleted.push(id);
      trackOvernightPrezentationsEvents.OPMyRequestsManageFilesAndLinkDelete(
        this.currentUser,
        { ...this.getMatomoDetails() },
      );
    },
    handleLinkDelete(id) {
      this.$modal.hide('ConfirmationDialoge');
      this.links = this.links.filter((ele) => ele.attachmentId !== id);
      this.linksToBeDeleted.push(id);
      trackOvernightPrezentationsEvents.OPMyRequestsManageFilesAndLinkDelete(
        this.currentUser,
        { ...this.getMatomoDetails() },
      );
    },
    handleFileImport() {
      this.isSelecting = true;
      window.addEventListener(
        'focus',
        () => {
          this.isSelecting = false;
        },
        { once: true },
      );
      this.$refs.uploader.click();
    },
    fileAlreadyUploaded(uploadedFiles, file) {
      return uploadedFiles.find((fl) => fl.fileName === file.name);
    },
    async onFileChanged(event) {
      // this.localFiles = [...event.target.files];
      const newFilesToUpload = [...event.target.files].filter(
        (fle) => !this.fileAlreadyUploaded(this.localFiles, fle),
      );
      if (newFilesToUpload.length > 0) {
        this.$modal.show(
          Upload,
          {
            filess: [...newFilesToUpload],
            updateLocalFiles: this.updateLocalFiles,
          },
          {
            name: 'upload',
            width: '600px',
            height: 'auto',
            styles: {
              'max-height': '45%',
              'max-width': '600px',
              'border-radius': '12px',
              overflow: 'auto',
            },
            clickToClose: false,
          },
        );
        // event.target.value = '';
      }
      event.target.value = '';
    },
    updateLocalFiles(val) {
      this.localFiles = [...this.localFiles, ...val];
    },
    removeFile(i) {
      this.localFiles.splice(i, 1);
    },
  },
  computed: {
    ...mapState('users', ['currentUser']),
    completedMoreThanSevenDays() {
      if (!this.request?.completionDate) return false;
      const completionDateString = this.prezentation?.completionDate;
      const completionDate = new Date(completionDateString);
      const currentDate = new Date();
      const timeDifference = currentDate - completionDate;
      const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
      return daysDifference > 7;
    },
  },
};
</script>

<style lang="scss" scoped>
// @import '@/scss/variables.scss', '@/scss/app.scss';

.manage_container {
  padding: 10px;
  background-color: white;
  width: 100%;
  padding: 20px;

  .closeIconContainer {
    position: absolute;
    top: 5px;
    right: 10px;
  }
  .manage-heading {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin: 0px 0px 10px 0px;
  }
  .instructions {
    padding: 24px 0px 0px 0px;
    width: 100%;
    .instructionsHeader {
      font-family: 'Lato';
      font-style: normal;
      font-size: 15px;
      color: #000000;
      width: 100%;
    }
    .instructionsArea {
      margin-top: 12px;
      width: 100%;
      border-radius: 16px;

      ::v-deep .v-input__slot {
        filter: drop-shadow(2px 2px 8px rgba(0, 0, 0, 0.05))
          drop-shadow(-2px -2px 4px rgba(0, 0, 0, 0.05));
        box-shadow: none;

        textarea {
          height: 100px;
        }
      }
    }
  }
  .files-desc {
    margin-bottom: 20px;
    .files-subheader {
      font-weight: 600;
    }
  }
  .disclaimer {
    font-size: 14px;
    line-height: 18px;
  }
  .spinnerContainer {
    text-align: center;
    margin: 50px 0px 0px 0px;
  }
  .content {
    .manage-sub-heading {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 130%;
      color: #7b7b7b;
      margin: 0px 0px 10px 0px;
    }
    .upload-container {
      display: flex;
      justify-content: space-between;
      gap: 38px;
      overflow: auto;
      padding: 2px;

      .highlight {
        border: 2px solid #21a7e0;
      }

      > div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 30px;
        gap: 12px;
        width: 448px;
        height: 340px;
        background: #ffffff;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1),
          -1px -1px 4px rgba(0, 0, 0, 0.1);
        border-radius: 12px;

        .header {
          font-family: 'Lato';
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          display: flex;
          align-items: center;
          color: #212121;
        }

        img {
          height: 120px;
        }

        .desc {
          font-family: 'Lato';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: 0.01em;
          color: #212121;
        }

        .user-input {
          display: flex;
          flex-direction: column;
          gap: 8px;
          width: 372px;
          // height: 149px;

          .label {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #000000;
          }

          .share-links {
            width: 100%;
            border-radius: 16px;

            &:focus-within {
              border: 1px solid #21a7e0;
            }

            ::v-deep .v-input__slot {
              filter: drop-shadow(2px 2px 8px rgba(0, 0, 0, 0.05))
                drop-shadow(-2px -2px 4px rgba(0, 0, 0, 0.05));
              box-shadow: none;

              // .v-text-field__slot {
              //   height: 101px;
              // }

              textarea {
                height: 101px;
              }
            }
          }
          .file-chips-wrapper {
            padding: 12px;
            height: 113px;
            border-radius: 16px;
            background: white;
            filter: drop-shadow(2px 2px 8px rgba(0, 0, 0, 0.05))
              drop-shadow(-2px -2px 4px rgba(0, 0, 0, 0.05));
            display: flex;
            flex-wrap: wrap;
            gap: 5px;
            overflow: auto;
            margin: 0px 0px 24px 0px;

            .file-chip {
              display: flex;
              flex-direction: row;
              align-items: center;
              padding: 10px 12px;
              // height: 32px;
              gap: 8px;
              position: relative;
              background: #ffffff;
              box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1),
                -1px -1px 4px rgba(0, 0, 0, 0.1);
              border-radius: 20px;
              max-width: 96%;
              margin: 4px;
              cursor: default;

              &:hover {
                box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.1),
                  -1px -1px 8px rgba(0, 0, 0, 0.1);
              }
              .fileNameWrapper {
                max-width: 90%;
                word-wrap: break-word;
              }
              .loading-file {
                width: 90%;
                position: absolute;
                bottom: 0;
              }

              &.size-error {
                border: 1px solid #b00020;
              }

              i {
                cursor: pointer;
              }
            }

            .file-chip-placeholder {
              font-family: 'Lato';
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 130%;
              color: #7b7b7b;
            }
          }
          .v-textarea {
            height: 116px;
          }
        }

        .check-access {
          width: 378px;
          margin-top: 10px;

          .guide-link {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: #21a7e0;
            margin-left: 30px;
            margin-top: 4px;
            display: inline-block;
            cursor: pointer;
          }

          ::v-deep .v-input--selection-controls {
            margin-top: 0;
            padding-top: 0;

            .v-input--selection-controls__input {
              margin-right: 6px;
            }
            svg {
              width: 20px;
              height: 20px;
            }

            label {
              font-family: 'Lato';
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 130%;
              color: #000000;
            }
          }
        }
        .check-access-error {
          width: 378px;
          margin-top: 10px;

          .guide-link {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: #21a7e0;
            margin-left: 30px;
            margin-top: 4px;
            display: inline-block;
            cursor: pointer;
          }

          ::v-deep .v-input--selection-controls {
            margin-top: 0;
            padding-top: 0;

            .v-input--selection-controls__input {
              margin-right: 6px;
            }
            svg {
              width: 20px;
              height: 20px;
              color: red;
            }

            label {
              font-family: 'Lato';
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 130%;
              color: #000000;
            }
          }
        }

        .file-upload-action {
          input[type='file'] {
            display: none;
          }

          .custom-file-upload {
            padding: 6px 16px;
            height: 31px;
            border: 1.5px solid #21a7e0;
            border-radius: 25px;
            color: #21a7e0;
            cursor: pointer;
          }
        }
      }
    }
    .links-container {
      .files-table {
        width: 100%;
        thead {
          width: 100%;
          display: block;
          tr {
            display: table;
            width: 100%;
            .text-left-heading {
              width: 300px;
              display: block;
              text-align: left;
              padding-right: 50px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .text-left {
              padding-right: 5px;
              text-align: start;
              width: 21%;
              .delete-icon {
                padding-left: 0px;
                cursor: pointer;
              }
              .add-icon {
                cursor: pointer;
              }
            }
          }
        }
        tbody {
          width: 100%;
          display: block;
          max-height: 126px;
          overflow: auto;
          tr {
            width: 100%;
            display: table;
            .text-left-heading {
              width: 300px;
              display: block;
              text-align: left;
              padding-right: 50px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .text-left {
              padding-right: 5px;
              width: 21%;
              text-align: start;
              .delete-icon {
                padding-left: 0px;
                cursor: pointer;
              }
              .add-icon {
                cursor: pointer;
              }
            }
          }
        }
      }
      .uploadBtn {
        background-color: #fff !important;
        box-shadow: none;
        letter-spacing: normal;
        text-transform: none;
        padding: 0;
        cursor: pointer;
        font-family: 'Lato';
        font-style: normal;
        font-size: 16px;
        color: #21a7e0;
        &:before {
          opacity: 0;
        }
      }
    }
    .uploads {
      padding: 0px 0px 8px 0px;
      width: 100%;
      .uploadFilesAndLinksContainer {
        display: flex;
        flex-direction: column;
        background: #ffffff;
        border-radius: 12px;
        .user-input {
          display: flex;
          flex-direction: column;
          gap: 12px;
          // height: 149px;

          .label {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            color: #000000;
          }

          ::v-deep .share-links {
            width: 100%;
            border-radius: 16px;
            .v-input__control {
              .v-input__slot {
                filter: drop-shadow(2px 2px 8px rgba(0, 0, 0, 0.05))
                  drop-shadow(-2px -2px 4px rgba(0, 0, 0, 0.05));
                box-shadow: none;
                textarea {
                  height: 101px;
                }
              }
              .v-text-field__details {
                .v-messages {
                  color: #f70000 !important;
                  .v-messages_wrapper {
                    .v-messages_message {
                      color: #f70000 !important;
                    }
                  }
                }
              }
            }
          }
          .file-chips-wrapper {
            padding: 12px;
            height: 101px;
            border-radius: 16px;
            background: white;
            filter: drop-shadow(2px 2px 8px rgba(0, 0, 0, 0.05))
              drop-shadow(-2px -2px 4px rgba(0, 0, 0, 0.05));
            display: flex;
            flex-wrap: wrap;
            gap: 5px;
            overflow: auto;

            .file-chip {
              display: flex;
              flex-direction: row;
              align-items: center;
              padding: 10px 12px;
              // height: 32px;
              gap: 8px;
              position: relative;
              background: #ffffff;
              box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1),
                -1px -1px 4px rgba(0, 0, 0, 0.1);
              border-radius: 20px;
              max-width: 100%;
              margin: 4px;
              .fileNameWrapper {
                max-width: 90%;
                word-wrap: break-word;
              }
              .loading-file {
                width: 90%;
                position: absolute;
                bottom: 0;
              }

              &.size-error {
                border: 1px solid #b00020;
              }

              i {
                cursor: pointer;
              }
            }

            .file-chip-placeholder {
              font-family: 'Lato';
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 130%;
              color: #7b7b7b;
            }
          }
        }
        .check-access {
          width: 378px;

          .guide-link {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: #21a7e0;
            margin-left: 30px;
            margin-top: 4px;
            display: inline-block;
            cursor: pointer;
          }

          ::v-deep .v-input--selection-controls {
            margin-top: 0;
            padding-top: 0;

            .v-input--selection-controls__input {
              margin-right: 6px;
            }
            svg {
              width: 20px;
              height: 20px;
            }

            label {
              font-family: 'Lato';
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 130%;
              color: #000000;
            }
          }
        }
        .check-access-error {
          width: 378px;

          .guide-link {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: #21a7e0;
            margin-left: 30px;
            margin-top: 4px;
            display: inline-block;
            cursor: pointer;
          }

          ::v-deep .v-input--selection-controls {
            margin-top: 0;
            padding-top: 0;

            .v-input--selection-controls__input {
              margin-right: 6px;
            }
            svg {
              width: 20px;
              height: 20px;
              color: red;
            }

            label {
              font-family: 'Lato';
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 130%;
              color: #000000;
            }
          }
        }
      }
    }
    .actionButtons {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
      .buttonsContainer {
        display: flex;
        justify-content: space-between;
        .cancelButton,
        .saveButton {
          box-shadow: none;
          text-transform: none;
          letter-spacing: normal;
          border-radius: 20px;
          min-width: 75px;
        }
        .cancelButton {
          border: 1px solid #21a7e0;
          background-color: #fff !important;
          color: #21a7e0;
        }
        .saveButton {
          background-color: #21a7e0;
          color: #fff;
        }
      }
    }
  }
}
</style>
