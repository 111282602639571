<template>
  <div class="manage-request-container">
    <div class="stepper-container">
      <v-stepper vertical>
        <template v-for="(status, i) in statuses">
          <template>
            <v-stepper-step
              :key="`${i}-step`"
              step=""
              :color="'#21a7e0'"
              :complete="currentLevel.level >= status.level"
            >
              <div class="status-title">
                <img :src="status.icon" class="status-icon" />
                <span>
                  {{ status.display }}{{ statusText(prezentation, status) }}
                </span>
              </div>
            </v-stepper-step>
            <v-stepper-content step="" :key="i"></v-stepper-content>
          </template>
        </template>
      </v-stepper>
    </div>
    <div v-if="!getMessageCount" class="info-container">
      <div class="img-container">
        <img :src="currentLevel.image" />
      </div>
      <div class="info-message">
        {{ currentLevel.text }}
      </div>
    </div>
    <div v-if="getMessageCount" class="info-container">
      <div class="img-container">
        <img
          src="/assets/img/overnight-prezentations/op-detail/unread-msg-op2.svg"
        />
      </div>
      <div class="info-message">You have a new unread message</div>
      <v-btn
        v-if="!hideViewMsgBtn"
        class="view-msg-btn"
        outlined
        rounded
        color="#20a7e0"
        @click="handleViewClick"
      >
        View message
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OnGoingRequests',
  props: {
    prezData: {
      type: Object,
      required: true,
    },
    getMessageCount: {
      type: Number,
      default: 0,
    },
    hideViewMsgBtn: {
      type: Boolean,
    },
  },
  data() {
    return {
      prezentation: this.prezData,
      statuses: [
        {
          value: 'submitted',
          display: 'Submitted',
          image:
            '/assets/img/overnight-prezentations/op-detail/op-submitted.svg',
          icon: '/assets/img/overnight-prezentations/op-submitted-icon.svg',
          level: 0,
          text: 'Our team of designers is reviewing your request. We will notify you over email with an ETA and let you know if we have any questions.',
          subtext:
            'We will notify you over email with an ETA and let you know if we have any questions.',
        },
        {
          value: 'accepted',
          display: 'Accepted',
          image:
            '/assets/img/overnight-prezentations/op-detail/op-accepted.svg',
          icon: '/assets/img/overnight-prezentations/op-accepted-icon.svg',
          level: 1,
          text: 'Great news! Our team is getting ready to start work on your request.',
        },
        {
          value: 'in progress',
          display: 'In Progress',
          image:
            '/assets/img/overnight-prezentations/op-detail/op-in-progress.svg',
          icon: '/assets/img/overnight-prezentations/op-in-progress-icon.svg',
          level: 2,
          text: 'Our team is working hard to meet your expectations. Hope you are getting some well-deserved rest! You will receive your final document in the timeframe communicated to you over email.',
        },
        {
          value: 'completed',
          display: 'Completed',
          image:
            '/assets/img/overnight-prezentations/op-detail/op-completed.svg',
          icon: '/assets/img/overnight-prezentations/op-completed-icon.svg',
          level: 3,
          // text: 'Yay! Your Overnight Prezentation is ready!',
          text: 'Processing your slides. You will be able to view them here in a few minutes.In the meantime, you can download your deck and view it offline.',
        },
      ],
    };
  },
  methods: {
    handleViewClick() {
      this.$emit('onMainActionClick', 'manageRequest');
    },
    dateFormatter(data) {
      const date = new Date(data);
      // return `${date.getMonth()}/${date.getDate()}/${date.getFullYear()}`;
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
    },
    statusText(prezentation, status) {
      let textData = '';
      if (this.currentLevel.level === status.level) {
        switch (status?.value) {
          case 'completed':
            textData = ` on ${this.dateFormatter(
              prezentation?.completionDate,
            )}`;
            break;
          case 'submitted':
            textData = ` on ${this.dateFormatter(prezentation?.createdAt)}`;
            break;
          default:
            break;
        }
      } else {
        switch (status?.value) {
          case 'submitted':
            textData = ` on ${this.dateFormatter(prezentation?.createdAt)}`;
            break;
          default:
            break;
        }
      }
      return textData;
    },
  },
  computed: {
    currentLevel() {
      return this.statuses.find(
        (i) => i.value === this.prezentation.status.toLowerCase(),
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.manage-request-container {
  display: flex;
  margin-top: 20px;
  .stepper-container {
    // width: 280px;
    flex-basis: 30%;

    .status-title {
      display: flex;
      gap: 4px;
      align-items: center;
      font-family: Lato;
      font-size: 14px;
      font-weight: 400;
      line-height: 16.8px;
      letter-spacing: 0.01em;
      text-align: left;

      .status-icon {
        height: 16px;
        width: 16px;
      }
    }

    ::v-deep .v-sheet.v-stepper {
      box-shadow: none !important;

      .v-stepper__step__step {
        background: white !important;
        border: 2.5px solid #21a7e0;

        .v-icon {
          color: #21a7e0;
          padding-right: 1.5px;
          svg {
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }

      .v-stepper__step--complete + .v-stepper__content:not(:last-child) {
        border-left: 2.5px solid #21a7e0 !important;
      }

      .v-stepper__step {
        padding: 17px 24px 7px;
      }

      .v-stepper__content {
        padding: 16px 62px 43px 23px;
        margin: -8px -36px -16px 35px;
        &:not(:last-child) {
          border-left: 2.5px solid rgba(0, 0, 0, 0.12);
        }
      }
    }
  }
  .info-container {
    flex-basis: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 17px;
    .img-container {
      img {
        height: 269px;
      }
    }
    .info-message {
      padding-top: 30px;
      font-family: Lato;
      font-size: 16px;
      font-weight: 400;
      line-height: 20.8px;
      text-align: center;
      width: 70%;
    }
    .view-msg-btn {
      border-radius: 25px;
      color: white !important;
      background-color: $zen-blue-default;
      font-size: 18px;
      font-style: normal;
      font-stretch: normal;
      font-weight: 600;
      height: 40px;
      line-height: normal;
      letter-spacing: normal;
      text-transform: none;
      min-width: 140px;
      padding: 0 !important;
      margin: 20px;
      cursor: pointer;
    }
  }
}
</style>
