<template>
  <div class="main-wrapper">
    <v-row v-if="!isUploading">
      <v-col cols="3">
        <div class="stepper-header-section back-section">
          <div
            class="back-btn"
            @click="previousStep"
            v-html="$t('common.backButton')"
            v-if="stepperValue > 1"
          ></div>
        </div>
      </v-col>
      <v-col cols="6">
        <div class="stepper-header-section">
          <div class="stepper-header-section__stepper">
            <v-stepper
              alt-labels
              v-model="stepperValue"
              style="box-shadow: none"
            >
              <v-stepper-header>
                <v-stepper-step
                  step=""
                  id="import-step"
                  :complete="stepperValue > 1"
                  :class="{
                    'active-step': stepperValue === 1,
                  }"
                >
                  {{ $t('upload.import') }}
                </v-stepper-step>
                <v-divider />
                <v-stepper-step
                  step=""
                  id="select-step"
                  :complete="stepperValue > 2"
                  :class="{
                    'active-step': stepperValue === 2,
                  }"
                >
                  {{ $t('common.select') }}
                </v-stepper-step>
                <v-divider />
                <v-stepper-step
                  step=""
                  id="sanitize-step"
                  :complete="stepperValue > 3"
                  :class="{
                    'active-step': stepperValue === 3,
                  }"
                >
                  {{ $t('upload.anonymize') }}
                </v-stepper-step>
                <v-divider />
                <v-stepper-step
                  id="tag-step"
                  step=""
                  :complete="stepperValue > 4"
                  style="margin-bottom: 5px"
                  :class="{
                    'active-step': stepperValue === 4,
                  }"
                >
                  {{ $t('common.tag') }}
                </v-stepper-step>
                <v-divider />
                <v-stepper-step
                  step=""
                  id="share-step"
                  :complete="stepperValue > 5"
                  :class="{
                    'active-step': stepperValue === 5,
                  }"
                >
                  {{ $t('common.share') }}
                </v-stepper-step>
              </v-stepper-header>
            </v-stepper>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="steps-content-section">
          <ImportPrezentation
            v-if="stepperValue === 1 && !isEditTags"
            ref="importPrezentations"
            :nextStep="nextStep"
            :onPrezentationUpload="handlePreUploadPrezentationData"
            :prezentationData="prezentationFinalData"
            :toggleIsFileUploaded="toggleIsFileUploaded"
          />
          <SlidesSelection
            v-if="stepperValue === 2"
            ref="slidesSelection"
            :nextStep="nextStep"
            :prezentationData="prezentationFinalData"
            :onPrezentationUpload="handleSlidesSelectionChange"
            :isTransferFlow="isTransferFlow"
            @setPrezentationFinalData="prezentationFinalData = $event"
            :setIsUploading="setIsUploading"
          />
          <SanitizeSlides
            v-if="stepperValue === 3"
            ref="sanitizeSlide"
            :nextStep="nextStep"
            :prezentationData="prezentationFinalData"
            :onPrezentationUpload="handleSanitizeSlidesChange"
            :isTransferFlow="isTransferFlow"
          />
          <TagSlides
            v-if="stepperValue === 4"
            ref="slideTags"
            :nextStep="nextStep"
            :onPrezentationUpload="handleFinalUploadPrezentationData"
            :onBackClick="handleBackClickOnEditTags"
            :prezentationDetails="prezentationFinalData"
            :editMode="isEditTags"
            :isTransferFlow="isTransferFlow"
            :origin="isTransferFlow ? 'transfer' : 'upload'"
          />
          <ShareUploadPrezentation
            v-if="stepperValue === 5"
            :nextStep="nextStep"
            :prezentationDetails="prezentationFinalData"
            :isTransferFlow="isTransferFlow"
            :setIsUploading="setIsUploading"
            :toggleIsFileUploaded="toggleIsFileUploaded"
          />
        </div>
      </v-col>
    </v-row>
    <ConfirmationDialog
      :show="showConfirmation"
      :on-primary-click="onDialogCancel"
      :on-secondary-click="onDialogConfirm"
      :on-close="onDialogConfirm"
      :primary-btn-text="$t('common.yes')"
      :secondary-btn-text="$t('common.no')"
      :title="$t('upload.exitUpload')"
      :text="$t('upload.exitUploadMessage')"
    />
  </div>
</template>
<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import ImportPrezentation from './ImportPrezentation.vue';
import TagSlides from './TagSlides.vue';
import ShareUploadPrezentation from './ShareUploadPrezentation.vue';
import SanitizeSlides from './SanitizeSlides.vue';
import { getLoggedInUserToken } from '../../../utils/api';
import SlidesSelection from './SlidesSelection.vue';
import { trackUploadEvents } from '../../common/Analytics/UploadEvents';
import {
  TD_AUD,
  TD_COMMON_COLUMN_NAME,
  TD_COMMON_COLUMN_VALUE,
  TD_NUMBEROFSLIDES,
  TD_PLACEMENT,
  TD_PREZNAME,
  TD_PREZTYPE,
  TD_TEMPLATE,
  TD_TIME_SPENT_IN_SECS,
} from '../../common/Analytics/MatomoTrackingDataHelper';
import EventBus from '../../common/event-bus';
import ConfirmationDialog from '../../common/ConfirmationDialog.vue';

export default {
  name: 'PrezentationUpload',
  components: {
    ImportPrezentation,
    ShareUploadPrezentation,
    TagSlides,
    SanitizeSlides,
    SlidesSelection,
    ConfirmationDialog,
  },
  data() {
    return {
      stepperValue: 1,
      prezentationData: null,
      prezentationFinalData: {},
      isEditTags: false,
      isTransferFlow: false,
      isUploading: false,
      showConfirmation: false,
      isFileUploaded: false,
    };
  },
  computed: {
    ...mapGetters('users', ['isUploadFullAccess']),
    ...mapState('users', ['currentUser']),
  },
  methods: {
    ...mapActions('users', ['setUploadFlowCTAOrigin']),
    setIsUploading(val) {
      this.isUploading = val;
    },
    goToMyPrezentations() {
      if (this.isEditTags) {
        this.$router.push(`/home/myPrezentation/${this.$route.params.id}`);
      } else {
        this.$router.push(`/home/prezentations`, () => {});
      }
    },
    toggleIsFileUploaded(val) {
      this.isFileUploaded = val;
    },
    handleBackClickOnEditTags() {
      this.$router.push(`/home/myPrezentation/${this.$route.params.id}`);
    },
    onDialogCancel() {
      this.showConfirmation = false;
      this.navigationNext();
    },
    onDialogConfirm() {
      this.showConfirmation = false;
    },
    pageChangeAction(navigationNext) {
      this.navigationNext = navigationNext;
      this.showConfirmation = true;
    },
    handlePreUploadPrezentationData({ data = [], ...otherData }) {
      const slidesList = [];
      data.forEach(async (slide) => {
        const awsAssetsDistribution =
          process.env.VUE_APP_AWS_ASSETS_DISTRIBUTION;
        const token = await getLoggedInUserToken();
        const thumbnail = `${awsAssetsDistribution}${
          slide.thumbnail_jpg.s3_path.startsWith('/') ? '' : '/'
        }${slide.thumbnail_jpg.s3_path}?accesskey=${token}`;
        const slideObj = {
          ...slide,
          s3_path: slide.thumbnail_jpg.s3_path,
          thumbnail,
          name: `${this.prezentationFinalData.prezentationName}_Slide_${slide.index}`,
        };
        slidesList.push(slideObj);
      });
      this.prezentationFinalData = { ...otherData, slidesList };
    },
    handleSlidesSelectionChange(data) {
      this.prezentationFinalData = {
        ...this.prezentationFinalData,
        ...data,
      };
    },
    handleSanitizeSlidesChange(data) {
      this.prezentationFinalData = data;
    },
    handleFinalUploadPrezentationData(data) {
      this.prezentationFinalData = data;
    },
    nextStep(value = 1) {
      this.stepperValue = value;
      if (this.stepperValue === 2) {
        trackUploadEvents.uploadImportNext(this.currentUser, {
          [TD_AUD]:
            this.$refs.importPrezentations?.selectedAudience?.email || '',
          [TD_PREZNAME]: this.$refs.importPrezentations?.prezentationName || '',
          [TD_PREZTYPE]:
            this.$refs.importPrezentations?.prezentationType?.type || '',
          [TD_TEMPLATE]: this.$refs.importPrezentations?.templateSearch || '',
          [TD_TIME_SPENT_IN_SECS]: this.$refs.importPrezentations?.timeSpent,
        });
      } else if (this.stepperValue === 3) {
        trackUploadEvents.uploadImportNextSelectNext(this.currentUser, {
          [TD_TIME_SPENT_IN_SECS]: this.$refs.slidesSelection?.timeSpent,
        });
      } else if (this.stepperValue === 4) {
        trackUploadEvents.uploadImportNextSelectNextSanitizeNext(
          this.currentUser,
          {
            [TD_NUMBEROFSLIDES]:
              this.$refs.sanitizeSlide?.noSanitizedData.length,
            [TD_COMMON_COLUMN_NAME]: 'Type of sanitization',
            [TD_COMMON_COLUMN_VALUE]:
              this.$refs.sanitizeSlide?.sanitizeType || '',
            [TD_TIME_SPENT_IN_SECS]: this.$refs.sanitizeSlide?.timeSpent,
          },
        );
      } else if (this.stepperValue === 5) {
        trackUploadEvents.uploadImportNextSelectNextSanitizeNextTagNext(
          this.currentUser,
          {
            [TD_NUMBEROFSLIDES]: this.$refs.slideTags?.slides.length,
            [TD_TIME_SPENT_IN_SECS]: this.$refs.slideTags?.timeSpent,
          },
        );
      }
    },
    previousStep() {
      if (this.isTransferFlow && this.stepperValue === 2) {
        this.$router.push(`/home/myPrezentation/${this.$route.params.id}`);
        return;
      }
      const placementObj = {
        1: 'Import',
        2: 'Select',
        3: 'Sanitize',
        4: 'Tags',
        5: 'Share',
      };
      trackUploadEvents.uploadBack(this.currentUser, {
        [TD_PLACEMENT]: placementObj[this.stepperValue],
      });
      this.stepperValue--;
    },
  },
  beforeMount() {
    if (!this.isUploadFullAccess) {
      this.$router.push('/home', () => {});
    }
    const { isTransferFlow } = this.$route.meta;
    const prezentationId = this.$route.params.id;

    if (isTransferFlow && prezentationId) {
      this.isTransferFlow = true;
      this.stepperValue = 2;
    }
  },
  mounted() {
    setTimeout(() => {
      EventBus.$emit('MINIMIZE_LEFTNAV');
    }, 50);
  },
  beforeDestroy() {
    this.setUploadFlowCTAOrigin('');
  },
  destroyed() {
    this.setUploadFlowCTAOrigin('');
  },
  beforeRouteLeave(to, from, next) {
    if (this.isFileUploaded) {
      this.pageChangeAction(next);
    } else {
      next();
    }
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/variables.scss', '@/scss/app.scss';
.main-wrapper {
  margin-top: 30px;
  margin-left: 50px;
  margin-right: 50px;
}

.stepper-header-section {
  display: flex;
  justify-content: center;
  align-items: center;
  &__stepper {
    width: 500px;
  }

  ::v-deep .v-stepper__step__step {
    background-color: $white-default !important;
    border: 1.5px solid $zen-blue-default;
    &.primary {
      background-color: $white-default !important;
      .v-icon {
        color: $zen-blue-default;
        padding-right: 1.5px;
        svg {
          height: 23px;
          width: 23px;
        }
      }
    }
  }
  ::v-deep .active-step .v-stepper__label {
    color: rgba(0, 0, 0, 0.87) !important;
  }
}

.back-section {
  justify-content: flex-start;
}
.steps-content-section {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.v-stepper__step {
  padding: 0 24px;
}
.theme--light.v-stepper
  .v-stepper__step:not(.v-stepper__step--active):not(.v-stepper__step--complete):not(.v-stepper__step--error)
  .v-stepper__step__step {
  background: white;
  display: inline-block;
  border: 3px solid #00000061;
}
.theme--light.v-stepper .v-stepper__step__step {
  color: transparent !important;
}
.theme--light.v-stepper .v-stepper__step__step.primary {
  background-color: transparent !important;
  border: 3px solid #6faec3;
}
.theme--light.v-stepper .v-stepper__step__step .v-icon {
  color: #6faec3;
}
.v-stepper__step--complete + .v-divider {
  border-color: #6faec3 !important;
}
.theme--light.v-stepper .v-stepper__header .v-divider {
  margin-top: 12px !important;
}
.v-divider {
  margin: 35px -76px 0 !important;
  border-width: 3px 0 0 0 !important;
}
.v-stepper__step--complete > .v-stepper__label {
  color: #6faec3 !important;
}
.theme--light.v-stepper .v-stepper__step__step .v-icon {
  color: #6faec3;
  font-size: 35px;
  position: relative;
  left: 3px;
  top: -4px;
}
.back-btn {
  color: $zen-blue-default;
  cursor: pointer;
  position: relative;
  top: 4px;
}
</style>
