<template>
  <div v-if="!loading" class="learn-landing-wrapper" :key="searchKey">
    <div class="learn-type-wrapper">
      <v-sheet max-width="100%">
        <v-chip-group show-arrows mandatory cols="1">
          <v-chip
            :class="
              learnCourseChipClicked.toLowerCase() ===
              keyword.display_name.toLowerCase()
                ? 'learnCourseChipActive'
                : 'learnCourseChipAvailable'
            "
            outlined
            :name="keyword.display_name"
            v-for="(keyword, index) in learnCourseChips"
            :key="index"
            exact-active-class=""
            @click="(e) => clickChipIdeas(e, keyword)"
          >
            {{ keyword.display_name }}
          </v-chip>
        </v-chip-group>
      </v-sheet>
    </div>
    <div class="learn-search-row">
      <learnSuggestions
        :items="itemFiltered"
        v-model="item"
        :placeholder="$t('learn.searchPlaceHolder')"
        :minLen="0"
        :item-template="headerItemTemplate"
        :set-label="setLabel"
        :values="searchModel"
        @changed="debounceInputChange"
        @click="onClickHandle"
        @selected="itemSelected"
        @enter="debounceOnEnterKeyPress"
      >
        <template #searchSlot>
          <v-icon
            class="icon"
            v-text="`mdi-close`"
            @click="clearSearch"
            name="learn-cross-icon"
            color="#21a7e0"
            v-show="crossButton"
          />
          <v-icon
            class="icon"
            v-text="`mdi-magnify`"
            @click="debounceOnEnterKeyPress"
            name="learn-magnify-glass"
            :color="isSearchColor"
          />
        </template>
      </learnSuggestions>
    </div>
    <div
      class="learn-card-wrapper"
      v-if="
        getTheCoursesList.problemSolving.courses.length > 0 &&
        (learnCourseChipClicked === 'All' ||
          learnCourseChipClicked === 'Problem-Solving')
      "
    >
      <div class="learn-landing-sub-header">
        {{ domains.problemSolving.display_name }}
      </div>
      <v-row class="learn-landing-items">
        <v-col
          v-for="(item, index) in getTheCoursesList.problemSolving.courses"
          :key="index"
          :lg="4"
          :md="6"
          :sm="12"
        >
          <CourseCard
            :course-info="item"
            :domain="getTheCoursesList.problemSolving.display_name"
            :isActiveCourse="isActive(item)"
            :openPanel="
              getTheCoursesList.problemSolving.expandPanel ? true : false
            "
            @removeActiveCourse="removeActiveCourse()"
            :data-pendo-id="index === 0 ? 'learn-card' : null"
          />
        </v-col>
      </v-row>
    </div>
    <div
      class="learn-card-wrapper"
      v-if="
        getTheCoursesList.storyLining.courses.length > 0 &&
        (learnCourseChipClicked === 'All' ||
          learnCourseChipClicked === 'Storylining')
      "
    >
      <div class="learn-landing-sub-header">
        {{ domains.storyLining.display_name }}
      </div>
      <v-row class="learn-landing-items">
        <v-col
          v-for="(item, index) in getTheCoursesList.storyLining.courses"
          :key="index"
          :lg="4"
          :md="6"
          :sm="12"
        >
          <CourseCard
            :course-info="item"
            :domain="getTheCoursesList.storyLining.display_name"
            :isActiveCourse="isActive(item)"
            :openPanel="
              getTheCoursesList.storyLining.expandPanel ? true : false
            "
            @removeActiveCourse="removeActiveCourse()"
          />
        </v-col>
      </v-row>
    </div>
    <div
      class="learn-card-wrapper"
      v-if="
        getTheCoursesList.creatingPresentations.courses.length > 0 &&
        (learnCourseChipClicked === 'All' ||
          learnCourseChipClicked === 'Building Presentations')
      "
    >
      <div class="learn-landing-sub-header">
        {{ domains.creatingPresentations.display_name }}
      </div>
      <v-row class="learn-landing-items">
        <v-col
          v-for="(item, index) in getTheCoursesList.creatingPresentations
            .courses"
          :key="index"
          :lg="4"
          :md="6"
          :sm="12"
        >
          <CourseCard
            :course-info="item"
            :domain="getTheCoursesList.creatingPresentations.display_name"
            :isActiveCourse="isActive(item)"
            :openPanel="
              getTheCoursesList.creatingPresentations.expandPanel ? true : false
            "
            @removeActiveCourse="removeActiveCourse()"
          />
        </v-col>
      </v-row>
    </div>
    <div
      class="learn-card-wrapper"
      v-if="
        getTheCoursesList.deliveringPresentations.courses.length > 0 &&
        (learnCourseChipClicked === 'All' ||
          learnCourseChipClicked === 'Delivering Presentations')
      "
    >
      <div class="learn-landing-sub-header">
        {{ domains.deliveringPresentations.display_name }}
      </div>
      <v-row class="learn-landing-items">
        <v-col
          v-for="(item, index) in getTheCoursesList.deliveringPresentations
            .courses"
          :key="index"
          :lg="4"
          :md="6"
          :sm="12"
        >
          <CourseCard
            :course-info="item"
            :domain="getTheCoursesList.deliveringPresentations.display_name"
            :isActiveCourse="isActive(item)"
            :openPanel="
              getTheCoursesList.deliveringPresentations.expandPanel
                ? true
                : false
            "
            @removeActiveCourse="removeActiveCourse()"
          />
        </v-col>
      </v-row>
    </div>
    <div class="no-outline-access" v-if="getNoResultsFound">
      <div class="align-center img">
        <img src="/assets/img/no-results-found-generic.svg" alt />
      </div>
      <p class="no-access-text">
        {{ $t('build.step1.storylineFieldNoResult') }}
      </p>
      <div class="go-to-course-btn">
        <v-btn
          class="go-to-btn"
          rounded
          height="36"
          min-width="64"
          color="#21a7e0"
          @click="clearSearch"
          primary
          >{{ $t('learn.goToCourses') }}
        </v-btn>
      </div>
    </div>
    <div v-if="isLoading">
      <Loading :classes="'loading-spinner'" />
    </div>
  </div>
  <div v-else>
    <Loading />
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState, mapActions } from 'vuex';
import LearnApi from '../../../API/learn-api.js';
import usersApi from '../../../API/users-api';
import EventBus from '../../common/event-bus';
import CourseCard from './CourseCard.vue';
import Loading from '../../common/Loading.vue';
import { getFileURL } from '@/utils/calyrex';
import SubscriptionExpired from '../../common/SubscriptionExpired.vue';
import headerItemTemplate from './headerItemTemplate.vue';
import { MatomoAnalyticsHandler } from '../../common/Analytics/MatomoAnalyticsHandler.js';
import { logSearchQuery } from '@/utils/api-helper';
import learnSuggestions from './learnSuggestions.vue';
import { TypeSenseServer } from '../../../pages/components/TypeSenseInit';
import { trackLearnEvents } from '../../common/Analytics/LearnEvents';
import {
  TD_COMMON_COLUMN_NAME,
  TD_COMMON_COLUMN_VALUE,
  TD_NORESULTSFOUND,
  TD_QUERY,
  TD_SUGGESTED_QUERY,
} from '../../common/Analytics/MatomoTrackingDataHelper';

export default {
  name: 'LearnLanding',
  components: {
    CourseCard,
    Loading,
    learnSuggestions,
  },
  mixins: [LearnApi],
  data() {
    return {
      loading: true,
      hybridSearchQuery: '',
      tsClient: '',
      domains: {
        problemSolving: {},
        storyLining: {},
        creatingPresentations: {},
        deliveringPresentations: {},
      },
      learnCourseChips: [{ name: 'all', display_name: 'All' }],
      learnCourseChipClicked: 'All',
      searchModel: '',
      activeCourse: null,
      itemFiltered: [],
      itemsOnEnterClick: [],
      item: {},
      headerItemTemplate,
      crossButton: false,
      searchKey: 0,
      isSearchColor: 'gray',
      onSearchEnterClick: false,
      isLoading: false,
    };
  },
  watch: {
    searchModel(val) {
      if (val.length === 0 && this.onSearchEnterClick) {
        this.onSearchEnterClick = false;
      }
    },
  },
  computed: {
    ...mapState('users', ['currentUser', 'isTrialExpired']),

    getTheCoursesList() {
      const tempItems = JSON.parse(JSON.stringify(this.domains));
      if (this.onSearchEnterClick) {
        const courseIdList = [];
        const categories = [
          'creatingPresentations',
          'deliveringPresentations',
          'problemSolving',
          'storyLining',
        ];
        this.itemsOnEnterClick.forEach((ele) => {
          courseIdList.push(ele.item.etags.lm_course_id);
        });
        categories.forEach((category) => {
          tempItems[category].courses = tempItems[category].courses.filter(
            (obj) => courseIdList.includes(obj.id),
          );
          tempItems[category].expandPanel = true;
        });
        return tempItems;
      }
      return this.domains;
    },

    getNoResultsFound() {
      return (
        !this.isLoading &&
        ((this.getTheCoursesList.problemSolving.courses.length === 0 &&
          this.learnCourseChipClicked === 'Problem-Solving') ||
          (this.getTheCoursesList.deliveringPresentations.courses.length ===
            0 &&
            this.learnCourseChipClicked === 'Delivering Presentations') ||
          (this.getTheCoursesList.creatingPresentations.courses.length === 0 &&
            this.learnCourseChipClicked === 'Building Presentations') ||
          (this.getTheCoursesList.storyLining.courses.length === 0 &&
            this.learnCourseChipClicked === 'Storylining') ||
          (this.getTheCoursesList.problemSolving.courses.length === 0 &&
            this.getTheCoursesList.deliveringPresentations.courses.length ===
              0 &&
            this.getTheCoursesList.creatingPresentations.courses.length === 0 &&
            this.getTheCoursesList.storyLining.courses.length === 0 &&
            this.learnCourseChipClicked === 'All'))
      );
    },
  },
  methods: {
    ...mapActions('users', ['updateRecentLearnQueries', 'setCurrentUser']),
    removeActiveCourse() {
      this.activeCourse = null;
    },

    clickChipIdeas(e, keyword) {
      const payload = {
        type: 'Learn Chip',
        searchTerm: keyword.display_name,
        searchTermResult: true,
      };
      logSearchQuery(payload);
      this.updateRecentLearnQueries(keyword.display_name);
      if (this.learnCourseChipClicked === keyword.display_name) {
        return;
      }
      this.learnCourseChipClicked = keyword.display_name;
      const otherData = {};
      trackLearnEvents.learnCourseChipClick(this.currentUser, otherData);
    },

    clearTextFieldSearch() {
      this.searchModel = '';
    },

    clearSearch() {
      this.crossButton = false;
      this.item = {};
      this.searchModel = '';
      this.searchKey++;
      this.itemFiltered = [];
      this.itemsOnEnterClick = [];
      this.onSearchEnterClick = false;
    },

    // eslint-disable-next-line func-names
    debounceInputChange: _.debounce(function (searchText) {
      this.inputChange(searchText);
      if (searchText.length) {
        this.crossButton = true;
      } else {
        this.crossButton = false;
      }
    }, 100),

    async inputChange(text) {
      this.searchModel = text;
      this.itemFiltered = [];
      if (text && text.length > 1) {
        const learnData = await this.searchLearnVideos(text, 3);
        this.itemFiltered = learnData;
      }
    },

    async searchLearnVideos(query, numberOfSuggestions = null) {
      const searchParameters = {
        q: query,
        query_by: 'title, industry',
        per_page: numberOfSuggestions,
      };
      return this.tsClient
        .collections('learn')
        .documents()
        .search(searchParameters)
        .then((results) => {
          const itemsFiltered = [];
          results.hits.forEach((ele) => {
            itemsFiltered.push({
              item: { ...ele.document },
              type: 'Learn',
              name: ele.document.etags.lm_title,
            });
          });
          return itemsFiltered;
        })
        .catch(() => []);
    },

    onClickHandle(check) {
      if (check) {
        this.isSearchColor = '#21a7e0';
      } else {
        this.isSearchColor = 'gray';
      }
    },

    async itemSelected(item) {
      this.item = item;
      this.searchModel = item.name;
      this.crossButton = true;
      this.updateRecentLearnQueries(item.name);
      let callSearchLog = true;
      const categories = [
        'All',
        'Problem-Solving',
        'Storylining',
        'Building Presentations',
        'Delivering Presentations',
      ];
      if (item.item && item.item.stype === 'video') {
        callSearchLog = false;
        this.makeNavigation(
          `/home/learn/course/${item.item.etags.lm_course_id}?assetid=${item.item.filename}&log_search=true&display_name=${item.name}`,
          'learn',
          'push',
        );
      } else if (item.item && item.item.stype === 'practice') {
        callSearchLog = false;
        this.makeNavigation(
          `/home/learn/novice/practiceQuestions/${item.item.filename}/${item.item.etags.lm_course_id}?log_search=true&display_name=${item.name}`,
          'learn',
          'push',
        );
      } else if (categories.includes(this.searchModel)) {
        this.learnCourseChipClicked = this.searchModel;
      } else {
        this.onEnterKeyPress();
        callSearchLog = false;
      }
      const otherData = {
        [TD_QUERY]: item.name,
        [TD_NORESULTSFOUND]: item ? 1 : 0,
        [TD_COMMON_COLUMN_NAME]: TD_SUGGESTED_QUERY,
        [TD_COMMON_COLUMN_VALUE]: item.name,
      };
      trackLearnEvents.learnLocalDropdownSearch(this.currentUser, otherData);
      if (callSearchLog) {
        const payload = {
          type: 'Learn Local Search',
          searchTerm: item.name,
          searchTermResult: true,
        };
        logSearchQuery(payload);
      }
    },

    makeNavigation(routePath, type, action, pushAction = false) {
      if (action === 'push') {
        if (pushAction === true) {
          this.$router
            .push(routePath, () => this.$router.go(0))
            .catch(() => {});
        } else {
          this.$router.push(routePath).catch(() => {});
        }
      }
    },

    // eslint-disable-next-line func-names
    debounceOnEnterKeyPress: _.debounce(function () {
      this.onEnterKeyPress();
    }, 300),

    async onEnterKeyPress() {
      if (!this.searchModel) return;
      this.isLoading = true;
      let learnData = [];
      this.onSearchEnterClick = true;
      this.item = {};
      this.item.name = this.searchModel;
      if (this.searchModel && this.searchModel.length > 1) {
        learnData = await this.searchLearnVideos(this.searchModel);
        if (
          learnData &&
          learnData.length === 1 &&
          learnData[0].item.etags.lm_title.toLowerCase() ===
            this.searchModel.toLowerCase() &&
          learnData[0].item.stype === 'video'
        ) {
          this.makeNavigation(
            `/home/learn/course/${learnData[0].item.etags.lm_course_id}?assetid=${learnData[0].item.filename}&log_search=true&display_name=${learnData[0].name}`,
            'learn',
            'push',
          );
        } else {
          this.itemsOnEnterClick = learnData;
        }
        this.isLoading = false;
      }
      this.updateRecentLearnQueries(this.searchModel);
      const payload = {
        type: 'Learn Local Search',
        searchTerm: this.searchModel,
        searchTermResult: true,
      };
      const otherData = {
        [TD_QUERY]: this.searchModel,
        [TD_NORESULTSFOUND]: learnData && learnData.length ? 1 : 0,
      };
      trackLearnEvents.learnLocalHybridSearch(this.currentUser, otherData);
      logSearchQuery(payload);
    },

    isActive(courseObj) {
      if (this.activeCourse && this.activeCourse.id === courseObj.id) {
        return true;
      }
      return false;
    },

    generateSignedImages(domainResponse) {
      domainResponse.forEach((domains) =>
        domains.courses.map(async (course) => {
          await getFileURL(
            this.currentUser.user.cognitoID,
            course.illustration_med,
            this.currentUser.userIp,
          ).then((signedImageUrl) => {
            course.illustration_med = signedImageUrl;
          });
          await getFileURL(
            this.currentUser.user.cognitoID,
            course.illustration_high,
            this.currentUser.userIp,
          ).then((signedImageUrl) => {
            course.illustration_high = signedImageUrl;
          });
        }),
      );
    },

    setLabel(item) {
      return item.name;
    },

    getCourse(courseID) {
      if (this.domains.problemSolving.courses) {
        for (
          let idx = 0;
          idx < this.domains.problemSolving.courses.length;
          idx++
        ) {
          if (courseID === this.domains.problemSolving.courses[idx].id) {
            return this.domains.problemSolving.courses[idx];
          }
        }
      }
      if (this.domains.storyLining.courses) {
        for (
          let idx = 0;
          idx < this.domains.storyLining.courses.length;
          idx++
        ) {
          if (courseID === this.domains.storyLining.courses[idx].id) {
            return this.domains.storyLining.courses[idx];
          }
        }
      }
      if (this.domains.creatingPresentations.courses) {
        for (
          let idx = 0;
          idx < this.domains.creatingPresentations.courses.length;
          idx++
        ) {
          if (courseID === this.domains.creatingPresentations.courses[idx].id) {
            return this.domains.creatingPresentations.courses[idx];
          }
        }
      }
      if (this.domains.deliveringPresentations.courses) {
        for (
          let idx = 0;
          idx < this.domains.deliveringPresentations.courses.length;
          idx++
        ) {
          if (
            courseID === this.domains.deliveringPresentations.courses[idx].id
          ) {
            return this.domains.deliveringPresentations.courses[idx];
          }
        }
      }
      return null;
    },

    displayTrialExpiredModal() {
      if (this.currentUser.user.role === 'TrialUser') {
        this.$router.push('/profile?tab=admin', () => {});
        this.$modal.show(
          SubscriptionExpired,
          { trialDaysLeft: 0 },
          {
            name: 'SubscriptionExpired',
            width: '849px',
            height: 'auto',
            styles: { borderRadius: '20px' },
          },
          {
            'before-close': () => {},
          },
        );
      }
    },
  },
  async beforeMount() {
    this.tsClient = await TypeSenseServer.getClient();
  },
  mounted() {
    MatomoAnalyticsHandler.learnHome(this.currentUser);
    this.loading = true;
    if (this.isTrialExpired) this.displayTrialExpiredModal();
    LearnApi.methods.getDomainsList().then(async (resp) => {
      console.log(resp);
      this.generateSignedImages(resp);
      this.loading = false;
      this.domains.problemSolving = resp.find(
        (domain) => domain.name === 'problem-solving',
      );
      this.domains.storyLining = resp.find(
        (domain) => domain.name === 'storylining',
      );
      this.domains.creatingPresentations = resp.find(
        (domain) => domain.name === 'building-presentations',
      );
      this.domains.deliveringPresentations = resp.find(
        (domain) => domain.name === 'delivering-presentations',
      );
      console.log(this.domains);
      this.learnCourseChips = [
        ...this.learnCourseChips,
        ...Object.values(this.domains),
      ];
      if (this.$route.query.courseid) {
        this.activeCourse = this.getCourse(this.$route.query.courseid);
      }
    });
    EventBus.$on('GOTO_LEARN_LANDING', (query) => {
      this.searchModel = query;
      this.debounceOnEnterKeyPress();
    });
  },
  beforeDestroy() {
    EventBus.$off('GOTO_LEARN_LANDING');
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      if (from?.name !== 'course.detail') {
        const userinfo = await usersApi.methods.getUserInfo();
        await vm.setCurrentUser({ ...userinfo });
      }
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/scss/variables.scss';

.course-title-expansion-panel::before {
  box-shadow: unset !important;
}
.learn-landing-wrapper {
  width: 100%;
  height: 100%;
  padding: 16px 35px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  overflow: hidden;

  .learn-landing-header {
    font-size: 36px;
    font-weight: bold;
    text-align: left;
  }

  .learn-landing-sub-header {
    font-size: 26px;
    font-weight: bold;
    text-align: left;
  }

  .learn-landing-title {
    font-size: 24px;
    font-weight: normal;
    margin-bottom: 30px;
  }

  .learn-landing-items {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 5px 0;

    .inactive {
      pointer-events: none;
    }
    .learn-landing-item:hover {
      box-shadow: -4px 4px 25px 1px rgba(0, 0, 0, 0.3);
    }
    .learn-landing-item {
      min-height: 350px;
      cursor: pointer;
      object-fit: contain;
      //box-shadow: 4px 5px 16px -3px rgba(0, 0, 0, 0.5);
      border-radius: 10px;
      box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1),
        -1px -1px 4px 0 rgba(0, 0, 0, 0.1);
      position: relative;

      .learn-landing-item-coming {
        position: absolute;
        top: 21px;
        right: 18px;
        padding: 2px 25.4px 6px 25.6px;
        border-radius: 16px;
        background: #21a7e0;
        font-size: 20px;
        font-weight: normal;
        color: white;
        text-align: center;
        z-index: 1;
      }
      .learn-landing-item-image-blured {
        width: 100%;
        height: 284px;
        position: relative;
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        /* background-image: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0.04),
          rgba(255, 255, 255, 0.99)
        );
       */
        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          /*   -webkit-filter: blur(5px); /* Safari 6.0 - 9.0 */
          /*filter: blur(5px); */
        }

        // .learn-landing-item-title {
        //   padding: 0 17px 0 14px;
        //   margin-bottom: 10px;
        //   font-size: 15px;
        //   font-weight: bold;
        //   text-align: left;
        //   line-height: normal;
        //   letter-spacing: normal;
        //   z-index: 1;
        //   height: 48px;
        //   display: flex;
        //   align-items: flex-start;
        //   justify-content: flex-start;
        //   color: $grey-blue;
        // }
      }
      .learn-landing-item-image {
        width: 100%;
        height: 266px;
        position: relative;
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        /* background-image: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0.04),
          rgba(255, 255, 255, 0.99)
        ); */
        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          /*  -webkit-filter: blur(3px); /* Safari 6.0 - 9.0 */
          /*filter: blur(3px); */
        }
      }

      .learn-landing-item-progress {
        margin-bottom: 17px;
      }

      .learn-landing-item-desc {
        font-size: 16px;
        font-weight: normal;
        text-align: left;
        margin-bottom: 3px;
        padding: 0 17px 0 14px;
      }

      .learn-landing-item-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 17px 0 14px;

        .learn-landing-item-info-modules,
        .learn-landing-item-info-hours {
          font-size: 14px;
          font-weight: normal;
          color: $gray-dark-login-text;
          text-align: left;

          &.coming {
            visibility: hidden;
          }
        }
      }
    }
  }

  .course-title-section {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    // width: 300px;

    .learn-landing-item-title {
      color: $grey-blue;
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      margin-bottom: 5px;
      padding: 0 0px 0 2px;
      text-align: left;
    }
  }

  .course-module-title {
    color: $zen-blue-default;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  .learn-type-wrapper {
    display: flex;
    width: 100%;

    .learnCourseChipActive {
      border-radius: 40px;
      font-size: 16px;
      font-weight: 500;
      height: 40px;
      margin-right: 18px !important;
      padding: 0 25px !important;
      background: #21a7e0 !important;
      color: white !important;
    }
    .learnCourseChipAvailable {
      border-radius: 40px;
      font-size: 16px;
      font-weight: 500;
      height: 40px;
      margin-right: 18px !important;
      padding: 0 25px !important;
      background: white !important;
      color: #21a7e0 !important;
      border-color: #21a7e0 !important;
    }
  }

  .learn-card-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    overflow: hidden;
  }

  .learn-search-bar {
    ::placeholder {
      color: #757575 !important;
    }
    .learn-search-bar__input {
      background-color: #fff;
      border: 1px solid transparent;
      border-radius: 24px;
      box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
      display: flex;
      font-size: 14px;
      height: 40px !important;
      padding: 2px 14px 8px 20px;
      width: 380px;
      margin: 0px 42px 0px 0px;
      z-index: 3;
      ::v-deep .v-input__control {
        .v-input__slot {
          .v-input__append-inner {
            margin: 0;
            padding: 4px 0px 0px 4px;
          }
          .v-text-field__slot {
            input {
              caret-color: black !important;
            }
          }
        }
      }

      ::v-deep .v-input__slot::before {
        border-style: none !important;
        display: none;
      }
      ::v-deep .v-input__icon {
        margin-right: -8px;
      }
    }
  }

  .learn-search-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: 24px 0px 24px 0px;
    width: 32%;
  }

  .no-outline-access {
    margin: 100px auto;
    .img {
      padding-bottom: 5px;
    }
    .no-access-text {
      font-size: 30px;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      width: fit-content;
      margin: 24px auto 24px auto;
    }

    .align-center {
      text-align: center;
      display: flex;
      justify-content: center;
    }
  }

  .loading-spinner {
    height: 0px !important;
  }

  .go-to-btn {
    color: white;
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    text-transform: none;
    display: flex;
  }

  .go-to-course-btn {
    display: flex;
    justify-content: center;
    margin-left: 10px;
  }
}
</style>
