var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"slides-mosaic-view col-12"},[(_vm.prezentationData.sections.list.length >= 5 && !_vm.isViewAccess)?_c('div',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm.$t('build.step3.sectionsLimitText'))+" ")]):_vm._e(),(
      !_vm.isViewAccess &&
      !_vm.isTemplateRemoved(_vm.themes) &&
      _vm.prezentationData.sections.list.length < 5
    )?_c('div',{staticClass:"col-2 pa-0"},[(
        !_vm.isViewAccess &&
        !_vm.isTemplateRemoved(_vm.themes) &&
        _vm.prezentationData.sections.list.length < 5 &&
        !_vm.isOpPrezentation &&
        !_vm.isPartialAccess(_vm.currentUser)
      )?_c('SectionHeader',{attrs:{"onNameChange":function (newValue) { return _vm.handleAddSection(newValue); },"origin":_vm.origin,"isAddNewSection":true}}):_vm._e()],1):_vm._e(),(_vm.prezentationData.sections.list.length)?_c('div',{staticClass:"expand-all-btn",on:{"click":_vm.handleExpandToggle}},[_c('v-icon',{attrs:{"id":"expand-collapse--auto"}},[_vm._v(" "+_vm._s(!_vm.isExpanded ? 'mdi-chevron-down' : 'mdi-chevron-up')+" ")]),_vm._v(" "+_vm._s(!_vm.isExpanded ? _vm.$t('build.step3.expandAllText') : _vm.$t('build.step3.collapseAllText'))+" ")],1):_vm._e(),(_vm.prezentationData.sections.list.length)?_c('v-expansion-panels',{staticClass:"expansion-panels",attrs:{"accordion":"","flat":"","multiple":""},model:{value:(_vm.expandedList),callback:function ($$v) {_vm.expandedList=$$v},expression:"expandedList"}},[_c('draggable',_vm._b({class:"dragArea sections-drag expansion-panels v-item-group theme--light v-expansion-panels v-expansion-panels--accordion v-expansion-panels--flat",attrs:{"list":_vm.prezentationData.sections.list,"group":"sections","draggable":".expansion-panel","handle":".section-drag-icon"},on:{"start":_vm.handleSectionDragStart,"end":_vm.hadleSectionDragEnd,"add":_vm.hadleSectionDragEnd}},'draggable',_vm.dragOptions,false),_vm._l((_vm.prezentationData.sections.list),function(section,sectionIndex){return _c('v-expansion-panel',{key:((section.sectionName) + "-" + sectionIndex),staticClass:"expansion-panel"},[_c('v-expansion-panel-header',[(
              !_vm.isViewAccess && !_vm.isTemplateRemoved(_vm.themes) && !_vm.isOpPrezentation
            )?_c('div',{staticClass:"header-drag-icon",class:("section-" + sectionIndex + "-dragarea--auto"),on:{"click":function($event){$event.stopPropagation();}}},[_c('SectionHeader',{attrs:{"data":section,"onNameChange":function (newValue) { return _vm.onSectionNameChange(sectionIndex, newValue); },"origin":_vm.origin,"count":section.slides.filter(function (i) { return i.assetId; }).length.toString(),"onDeleteClick":function () { return _vm.handleDeleteSection(sectionIndex, section); },"onEdit":function (val) { return (_vm.isInEditMode = val); }}})],1):_c('div',{staticClass:"section-header"},[_vm._v(" "+_vm._s(section.sectionName)+" "),_c('v-badge',{staticClass:"badge",attrs:{"color":"#FFE1B7","content":section.slides.filter(function (i) { return i.assetId; }).length.toString(),"inline":""}})],1)]),_c('v-expansion-panel-content',[(
              section.slides.filter(function (i) { return i.assetId; }).length === 0 &&
              !_vm.isPartialAccess(_vm.currentUser)
            )?_c('div',{key:("divider-" + sectionIndex + "-0-top"),staticClass:"slide-divider no-slides-divider"},[(
                !_vm.isViewAccess &&
                !_vm.isPartialAccess(_vm.currentUser) &&
                !_vm.isOpPrezentation
              )?_c('div',{staticClass:"inner-divider"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"add-slides",attrs:{"fab":"","dark":"","small":"","color":"primary"},on:{"click":function () { return _vm.addSlidesInBetween(sectionIndex, 0, 'Between slides'); }}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('build.step3.addSlideText')))])])],1):_vm._e()]):_vm._e(),_c('draggable',_vm._b({class:("dragArea list-group row sectionIndex-" + sectionIndex),attrs:{"list":section.slides,"group":"slides","draggable":".dragable-slide","handle":".drag-icon"},on:{"start":_vm.hadleSlideDragStart,"end":_vm.hadleSlideDragEnd,"add":_vm.hadleSlideDragEnd}},'draggable',_vm.dragOptions,false),_vm._l((section.slides.filter(
                function (i) { return i.assetId; }
              )),function(slide,slideIndex){return _c('div',{key:(sectionIndex + "-" + slideIndex),staticClass:"slides-container dragable-slide"},[_c('div',{key:("divider-" + sectionIndex + "-" + slideIndex + "-top"),staticClass:"slide-divider mosaic-view"},[(
                    !_vm.isViewAccess &&
                    !_vm.isTemplateRemoved(_vm.themes) &&
                    !_vm.isPartialAccess(_vm.currentUser)
                  )?_c('div',{staticClass:"inner-divider"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"add-slides",attrs:{"fab":"","dark":"","small":"","color":"primary"},on:{"click":function () { return _vm.addSlidesInBetween(
                              sectionIndex,
                              slideIndex,
                              'Between slides'
                            ); }}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('build.step3.addSlideText')))])])],1):_vm._e()]),_c('div',{staticClass:"mosaic-slide-container"},[_c('SlideMosaicViewImage',{key:(sectionIndex + "-" + slideIndex),attrs:{"isSelected":slideIndex === _vm.selectedSlideIndex &&
                    _vm.selectedSectionIndex === sectionIndex,"isMatchedSlide":_vm.getIsMatchedSlide(slideIndex),"serialNumber":_vm.getSerialNumber(sectionIndex, slideIndex),"slideIndex":slideIndex,"sectionIndex":sectionIndex,"onClick":function () { return _vm.handleRightSideImageClick(
                        slide,
                        slideIndex,
                        sectionIndex
                      ); },"slide":slide,"onDuplicate":function () { return _vm.handleLeftSideDuplicate(slide, slideIndex, sectionIndex); },"onDelete":function () { return _vm.handleLeftSideDelete(slideIndex, sectionIndex); },"isViewAccess":_vm.isViewAccess,"isTemplateRemoved":_vm.isTemplateRemoved(_vm.themes)}})],1),(
                  slideIndex ===
                  section.slides.filter(function (i) { return i.assetId; }).length - 1
                )?_c('div',{key:("divider-" + sectionIndex + "-" + slideIndex + "-bottom"),staticClass:"slide-divider mosaic-view"},[(
                    !_vm.isViewAccess &&
                    !_vm.isTemplateRemoved(_vm.themes) &&
                    !_vm.isPartialAccess(_vm.currentUser)
                  )?_c('div',{staticClass:"inner-divider"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"add-slides",attrs:{"fab":"","dark":"","small":"","color":"primary"},on:{"click":function () { return _vm.addSlidesInBetween(
                              sectionIndex,
                              slideIndex + 1,
                              'Between slides'
                            ); }}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('build.step3.addSlideText')))])])],1):_vm._e()]):_vm._e()])}),0)],1)],1)}),1)],1):_vm._e(),_c('ConfirmationDialog',{attrs:{"show":_vm.deleteSlideConfirmPopup,"on-primary-click":_vm.handleSideDeleteCancel,"on-secondary-click":_vm.handleSideDeleteConfirm,"on-close":_vm.handleSideDeleteCancel,"primary-btn-text":_vm.$t('common.cancel'),"secondary-btn-text":_vm.$t('common.delete'),"title":_vm.$t('build.step3.deleteSlide'),"text":_vm.$t('build.step3.deleteSlideText')}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }