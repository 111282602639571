<template>
  <div class="certificate-modal-container">
    <div v-if="!loading">
      <v-btn class="close-btn" icon @click="close">
        <v-icon dark v-text="`mdi-close`" />
      </v-btn>
      <div class="model-content">
        <h2 class="title">{{ $t('learn.course.congratulations') }}</h2>
        <div class="sub-title">
          {{ certificateInfo.completion_text }}
        </div>
        <div class="main-context">
          <div class="left-panel">
            <div class="certificate-image" v-if="certificateInfo.thumbnail">
              <img
                src="/assets/img/learn/certificate-bg.svg"
                alt
                width="480"
                height="312"
              />
              <img
                :src="certificateInfo.thumbnail"
                alt
                class="inner-image"
                width="300"
                height="300"
              />
            </div>
            <div class="certificate-image" v-else>
              <img
                src="/assets/img/learn/certificate-placeholder.svg"
                alt
                width="480"
                height="312"
              />
            </div>
            <div class="action-container">
              <div class="share-your-accomplishment">
                {{ $t('learn.course.shareYour') }}
              </div>
              <div>
                <v-speed-dial direction="bottom">
                  <template v-slot:activator>
                    <v-btn
                      style="
                        width: 314px;
                        padding: 11px 42.5px;
                        border-radius: 25px;
                        background-color: #21a7e0;
                        text-transform: none;
                        font-family: 'Roboto', 'sans-serif';
                        font-size: 21px;
                        font-weight: 500;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        text-align: center;
                        color: #fff;
                        height: 50px;
                      "
                      rounded
                      color="#21a7e0"
                      class="primary-bg-btn"
                    >
                      <v-icon size="30px" left> mdi-linkedin </v-icon>
                      {{ $t('learn.course.linkedin') }}
                    </v-btn>
                  </template>
                  <div class="share-list">
                    <div class="share-list-item">
                      <v-btn
                        text
                        depressed
                        elevation="0"
                        ref="saveBtn"
                        class="download-btn"
                        :ripple="false"
                        id="slide-detail-download-from-list--auto"
                        style="text-transform: none"
                        @click="handleLinkedinShare('share-as-post')"
                      >
                        {{ $t('learn.course.post') }}
                      </v-btn>
                    </div>
                    <div class="share-list-item">
                      <v-btn
                        text
                        depressed
                        elevation="0"
                        ref="saveBtn"
                        class="download-btn"
                        :ripple="false"
                        id="slide-detail-download-from-list--auto"
                        style="text-transform: none"
                        @click="handleLinkedinShare('share-as-certificate')"
                      >
                        {{ $t('learn.course.addCertification') }}
                      </v-btn>
                    </div>
                  </div>
                </v-speed-dial>
              </div>
              <div>
                <span>
                  <v-btn
                    class="download-link"
                    @click="downloadItem"
                    text
                    color="#21a7e0"
                    :loading="downloadLoader"
                  >
                    <v-icon left> mdi-download </v-icon>
                    {{ $t('common.download') }}
                  </v-btn>
                </span>
                <span>
                  <v-btn
                    class="email-link"
                    @click="emailItem"
                    text
                    color="#21a7e0"
                    :loading="emailLoader"
                  >
                    <v-icon left> mdi-email-plus-outline </v-icon
                    >{{ $t('navbarActions.addColleagueDetail.email') }}
                  </v-btn>
                </span>
              </div>
            </div>
          </div>
          <div class="right-panel">
            <div class="card" style="min-height: 233px">
              <h3 class="card-title">
                {{ $t('learn.course.recommendCourse') }}
              </h3>
              <div class="recommended">
                <div class="image">
                  <img :src="courseMetaInfo.illustration_high" alt="image" />
                </div>
                <div>
                  <div class="empathize">
                    {{ courseMetaInfo.recomendedCourse.display_name }}
                  </div>
                  <div class="discover">
                    {{ courseMetaInfo.detail }}
                  </div>
                  <div class="course-details">
                    <span>
                      {{ courseMetaInfo.recomendedCourse.module }}
                      {{ $t('learn.course.modules') }} </span
                    ><v-icon class="course-details__dot">
                      mdi-circle-small
                    </v-icon>
                    <span>
                      <v-icon class="clock-icon">
                        mdi-clock-time-eight-outline
                      </v-icon>
                      {{
                        courseMetaInfo.recomendedCourse.duration_seconds
                          | secondsToHoursAndMinutes
                      }}
                    </span>
                  </div>
                  <div>
                    <v-btn
                      class="primary-bg-btn ma-2 view-course-btn"
                      rounded
                      color="#21a7e0"
                      outlined
                      @click="goToCourse(courseMetaInfo.recomendedCourse.id)"
                      style="
                        text-transform: unset;
                        font-size: 18px;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        text-align: center;
                        color: #fff;
                      "
                    >
                      {{ $t('learn.course.viewCourse') }}
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div class="card course-retake" style="min-height: 233px">
              <h3 class="card-title">{{ $t('learn.course.retakeCourse') }}</h3>

              <div class="module-detail__list">
                <v-list-item
                  v-for="(item, index) in courseMetaInfo.currentCourseModules"
                  :key="index"
                  class="module-detail__list--item"
                >
                  <v-list-item-content class="module-detail__list__content">
                    <v-list-item-title
                      @click="
                        goToCourse(courseMetaInfo.courseDetails.id, item.id)
                      "
                      class="module-detail__list__content__title-section"
                    >
                      <v-icon
                        :color="item.status < 100 ? '#d8d8d8' : '#21a7e0'"
                      >
                        mdi-shield-star
                      </v-icon>
                      <a
                        class="module-detail__list__content__title-section--title"
                      >
                        {{ item.display_name }}
                      </a>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="spinner">
      <img
        src="/assets/img/learn/certificate-placeholder.svg"
        alt
        width="480"
        height="312"
      />
      <LoadSpinner :id="'spinner'" :size="'60'" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  sendEmail,
  updateShareCertificateCount,
} from '../../../utils/api-helper.js';
import { MatomoAnalyticsHandler } from '../../common/Analytics/MatomoAnalyticsHandler';
import { downloadFile } from '../../utils';
import { getFileURL } from '@/utils/calyrex';
import LearnApi from '../../../API/learn-api';
import LoadSpinner from '../../common/LoadSpinner.vue';
import { trackLearnEvents } from '../../common/Analytics/LearnEvents.js';
import {
  TD_COURSE_SHARE_LINKEDIN,
  TD_COURSE_SHARE_POST,
  TD_LINKEDINSHARETYPE,
} from '../../common/Analytics/MatomoTrackingDataHelper.js';

export default {
  components: {
    LoadSpinner,
  },
  name: 'CourseCertificateModal',
  props: {
    courseDetails: {
      type: Object,
      required: true,
    },
    emailSuccess: {
      type: Function,
      required: false,
    },
    functionalScreen: {
      type: String,
      required: true,
    },
    setModuleIdInCurrentCourse: {
      type: Function,
      required: false,
    },
  },
  data() {
    return {
      loading: false,
      certificate: null,
      certificateInfo: {},
      courseMetaInfo: {},
      emailLoader: false,
      downloadLoader: false,
    };
  },
  computed: {
    ...mapState('users', ['currentUser']),
    courseInfoForMatotmo() {
      const {
        domain_display_name: learnCategoryName,
        display_name: learnCourseName,
        modules,
        domain,
      } = this.courseDetails;
      return {
        learnCategoryName: learnCategoryName || domain,
        learnCourseName,
        modules,
      };
    },
  },
  async beforeMount() {
    await this.getCertificateDetails();
  },
  methods: {
    close() {
      this.$modal.hide('CourseCertificateModal');
    },
    async getCertificateDetails() {
      const { id } = this.courseDetails;
      this.loading = true;
      Promise.all([
        LearnApi.methods.getCourseCompletionCertificate(id),
        LearnApi.methods.getCourseRecomendations(id),
      ])
        .then((values) => {
          const [certificateInfo, courseRecommended] = values;
          this.certificateInfo = certificateInfo;

          const courseMetaInfo = courseRecommended[0];
          this.courseMetaInfo = {};
          this.courseMetaInfo = {
            ...courseMetaInfo,
            ...{ recomendedCourse: courseMetaInfo },
            ...{ currentCourseModules: this.courseDetails.modules },
            ...{ courseDetails: this.courseDetails },
          };
          this.generateSignedImageUrls(courseMetaInfo);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async generateSignedImageUrls(courseMetaInfo) {
      const allImageResponses = [
        'illustration_med',
        'illustration_high',
        'illustration_low',
      ].map(
        (illustration) =>
          new Promise((resolve, reject) => {
            try {
              getFileURL(
                this.currentUser.user.cognitoID,
                courseMetaInfo[illustration],
                this.currentUser.userIp,
              ).then((signedImage) => {
                const imageData = {
                  signedImageUrl: signedImage,
                  type: illustration,
                };
                resolve(imageData);
              });
            } catch (error) {
              reject(error);
            }
          }),
      );
      await Promise.all(allImageResponses)
        .then((imagesList) => {
          imagesList.forEach((imageInfo) => {
            switch (imageInfo.type) {
              case 'illustration_med':
                this.courseMetaInfo.illustration_med = imageInfo.signedImageUrl;
                break;
              case 'illustration_high':
                this.courseMetaInfo.illustration_high =
                  imageInfo.signedImageUrl;
                break;
              case 'illustration_low':
                this.courseMetaInfo.illustration_low = imageInfo.signedImageUrl;
                break;
              default:
                break;
            }
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async downloadItem() {
      this.downloadLoader = true;
      // get pdf url
      if (!this.certificate) {
        this.certificate = await LearnApi.methods.getCertificatePDF(
          this.certificateInfo.id,
        );
      }
      await LearnApi.methods
        .getCertificatePdfBlob(this.certificate.certificate_pdf)
        .then(
          (resp) => {
            if (resp) {
              MatomoAnalyticsHandler.trackDownloadCertClick(
                this.currentUser.user,
                this.courseMetaInfo.courseDetails.display_name,
              );
              trackLearnEvents.learnCourseDownloadCert(
                this.currentUser,
                this.courseInfoForMatotmo,
              );
              downloadFile(
                resp.data,
                `${this.courseMetaInfo.courseDetails.display_name}_${this.currentUser.user.firstName}_${this.currentUser.user.lastName}`,
                'pdf',
              );
            }
          },
          (error) => {
            console.log('error while fetching the certificate blob ', error);
          },
        );
      // window.location.href = this.certificate.certificate_pdf;
      this.downloadLoader = false;
    },
    async emailItem() {
      this.emailLoader = true;
      // get pdf url
      if (!this.certificate) {
        this.certificate = await LearnApi.methods.getCertificatePDF(
          this.certificateInfo.id,
        );
        if (this.certificate) {
          MatomoAnalyticsHandler.trackEmailCertClick(
            this.currentUser.user,
            this.courseMetaInfo.courseDetails.display_name,
          );
          trackLearnEvents.learnCourseEmailCert(
            this.currentUser,
            this.courseInfoForMatotmo,
          );
        }
      }
      const params = {
        from: [],
        to: [],
        type: 'learn-certificate',
        meta: {
          course_name: this.courseMetaInfo.courseDetails.display_name,
          certification_link: `${window.location.href}?certificateModal=true`,
          certification_pdf: this.certificate.certificate_pdf,
        },
      };
      await sendEmail(params);
      this.emailLoader = false;
      console.log(this.emailSuccess());
      this.close();
    },
    goToCourse(courseId, mId) {
      switch (this.functionalScreen) {
        case 'landingPage':
          if (!mId)
            this.$router.push({
              name: 'course.detail',
              params: { id: courseId },
            });
          else {
            this.$router.push({
              name: 'course.detail',
              params: { id: courseId, moduleId: mId },
            });
          }
          break;
        case 'courseDetailPage':
          if (!mId) {
            this.$router.push({
              name: 'course.detail',
              params: { id: courseId },
            });
          } else {
            this.setModuleIdInCurrentCourse(mId);
          }
          break;
        default:
          break;
      }
      this.close();
    },
    handleLinkedinShare(shareCase) {
      let link = '';
      // pvt to prezent linkedin
      const orgId = 74732254;
      const orgName = 'Prezent.ai';
      switch (shareCase) {
        case 'share-as-post':
          link = `https://www.linkedin.com/sharing/share-offsite/?url=${this.certificateInfo.certificate}`;
          MatomoAnalyticsHandler.trackLearnCourseSharePost(
            this.courseInfoForMatotmo,
            this.currentUser,
          );
          trackLearnEvents.learnCourseShare(
            this.currentUser,
            this.courseInfoForMatotmo,
            {
              [TD_LINKEDINSHARETYPE]: TD_COURSE_SHARE_POST,
            },
          );
          break;
        default:
          link = `https://www.linkedin.com/profile/add?name=${this.courseDetails.display_name}&organizationId=${orgId}&organizationName=${orgName}&issueYear=${this.certificateInfo.issued_year}&issueMonth=${this.certificateInfo.issued_month}&certUrl=${this.certificateInfo.certificate}&certId=${this.certificateInfo.id}`;
          MatomoAnalyticsHandler.trackLearnCourseAddPost(
            this.courseInfoForMatotmo,
            this.currentUser,
          );
          trackLearnEvents.learnCourseShare(
            this.currentUser,
            this.courseInfoForMatotmo,
            {
              [TD_LINKEDINSHARETYPE]: TD_COURSE_SHARE_LINKEDIN,
            },
          );
      }
      updateShareCertificateCount()
        .then()
        .catch((err) => console.log(err));
      window.open(link);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/scss/variables.scss';
.certificate-modal-container {
  height: 100%;
  overflow: auto;

  .spinner {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 30px;
  }

  .close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;
  }

  .primary-bg-btn {
    color: #fff;
    background-color: #21a7e0;
  }
  .model-content {
    font-family: 'Roboto' !important;
    padding: 20px 40px 0 40px;

    .title {
      font-family: 'Roboto', 'sans-serif';
      font-size: 30px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000;
    }

    .sub-title {
      color: #000;
      font-family: 'Roboto', 'sans-serif';
      font-stretch: normal;
      font-style: normal;
      font-weight: 500;
      letter-spacing: normal;
      line-height: normal;
    }

    .sub-title {
      margin-top: 10px;
      font-family: Roboto;
      font-size: 24px;
      font-weight: 500;
    }

    .main-context {
      display: flex;
      margin-top: 22px;

      .left-panel,
      .right-panel {
        width: 50%;
        padding: 0 10px;
      }
      .left-panel {
        border-right: 1px solid #fff;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .certificate-image {
          position: relative;
          .inner-image {
            position: absolute;
            top: 60px;
            right: 116px;
          }
        }
      }

      .action-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        > div {
          margin: 10px 0;
        }

        .share-list {
          border-radius: 2px;
          box-shadow: 0 2px 4px 0 #68788c;
          position: absolute;
          right: -183px;
          top: -90px;
          width: 180px;

          .share-list-item {
            background: white;
            color: black;
            cursor: pointer;
            font-size: 20px;
            font-stretch: normal;
            font-style: normal;
            font-weight: normal;
            letter-spacing: normal;
            line-height: normal;
            padding-bottom: 5px;
            text-align: left;
            &:hover {
              background: $gray-background-hover;
            }
            .v-btn.v-size--default {
              font-size: 17px !important;
            }
            button {
              padding: 0 10px !important;
            }
          }
          &:hover {
            background: #f2f2f2;
          }
        }
      }
    }

    .card-title {
      font-size: 22px;
      font-weight: 500;
      margin-bottom: 10px;
    }
    .recommended {
      display: flex;

      .image {
        // width: 47%;
        margin-right: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      .empathize {
        font-size: 18px;
        font-weight: 500;
        line-height: 1.2;
        margin-top: 5px;
      }

      .discover {
        font-size: 16px;
        margin: 10px 0;
      }
      .course-details {
        color: #7b7b7b;
        margin: 10px 0;

        &__dot {
          font-size: 26px;
          width: 13px;
        }
      }

      .clock-icon {
        font-size: 16px;
      }

      .view-course-btn {
        float: right;
      }
    }

    .course-retake {
      div {
        margin-left: 10px;
      }
      span {
        color: #21a7e0;
        font-size: 16px;
      }
    }

    .card {
      box-shadow: 0 0px 0 0 rgb(0 0 0 / 50%), 0 0px 0px 0 #68788c,
        0 1px 8px 0 rgb(0 0 0 / 10%);
      padding: 15px 20px;
      border-radius: 8px;
    }
  }
  .module-detail__list {
    .module-detail__list__content__title-section--title {
      color: #21a7e0;
      text-decoration: none;
    }
  }

  .module-detail__list,
  .learning-objectives__list {
    &__content {
      &__title-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        &--title {
          font-size: 18px;
          font-weight: 400;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          margin-left: 5px;
          cursor: pointer;
        }
      }
    }
  }

  .share-your-accomplishment {
    // width: 304px;
    height: 28px;
    margin: 0 38px 37px 36px;
    font-family: Roboto;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000;
  }

  .download-btn {
    color: black;
    letter-spacing: normal;
    line-height: normal;
    text-transform: none;
    .v-btn::before {
      background-color: transparent !important;
      text-align: left;
    }
    .v-btn__content {
      justify-content: flex-start !important;
    }
    .add-icon {
      color: #21a7e0;
      position: relative;
      transform: translateY(-2px);
    }
    .v-icon.v-icon {
      font-size: 2vw;
    }
    .v-list .v-list-item--active .v-icon {
      color: black;
    }
    &:hover::before {
      opacity: 0 !important;
    }
  }

  .download-link,
  .email-link {
    color: $zen-blue-default;
    font-size: 20px;
    text-transform: none;
    letter-spacing: normal;
    font-family: 'Roboto', 'sans-serif' !important;
    &:hover::before {
      background-color: rgba(0, 0, 0, 0.64) !important;
    }
  }
}
</style>
