<template>
  <div class="addPaymentMethodModalContainer">
    <div class="closeIconContainer">
      <v-icon @click="handleModalClose">{{ 'mdi-close' }}</v-icon>
    </div>
    <div class="headingContainer">
      {{
        $t('profile.editPaymentDetail.addEditPaymentMethod', {
          addOrEdit:
            methodMode === 'createMethod'
              ? $t('fingerprint.add')
              : $t('profile.storylinesTabContent.edit'),
        })
      }}
    </div>
    <div class="stripeFieldContainer">
      <div class="cardFieldLabel">{{ $t('toggleAutoRenew.cardNumber') }}</div>
      <stripe-element-card
        @element-change="stripeElementCardChanged($event)"
        ref="stripeElementCard"
        :pk="striprPublicKey"
        class="stripeElement"
        v-if="methodMode === 'createMethod'"
      />
      <v-text-field
        disabled
        v-if="methodMode === 'updateMethod'"
        class="disabledTextFieldOnUpdate"
      >
        <template #prepend-inner>
          <div class="prependIconContainer">
            <v-img
              :src="`${logos[card.paymentMethodBrand]}`"
              alt="image"
              :aspect-ratio="16 / 9"
              lazy-src="/assets/img/Generic-card.svg"
            />
          </div>
          <div class="cardMask">{{ '**** **** ****' }}</div>
          <div class="cardLast4">{{ card.paymentMethodLast4 }}</div>
        </template>
        <template #append>
          <div class="cvvText">{{ $t('profile.editPaymentDetail.cvv') }}</div>
          <div class="cardCVVMask">{{ '***' }}</div>
        </template>
      </v-text-field>
    </div>
    <div class="nameAndExpiryFields">
      <div
        class="cardHolderNameField"
        :style="{ width: `${methodMode === 'createMethod' ? '100%' : '48%'}` }"
      >
        <div class="cardHolderNameLabel">
          {{ $t('toggleAutoRenew.nameOnCard') }}
        </div>
        <div class="nameFieldContainer">
          <v-text-field
            v-model="cardHolderName"
            :placeholder="$t('toggleAutoRenew.cardHolderName')"
          />
          <div class="nameError" v-if="cardHolderName.length > 30">
            {{ $t('toggleAutoRenew.nameCannotExceedCharacters') }}
          </div>
        </div>
      </div>
      <div class="cardExpiryField" v-if="methodMode === 'updateMethod'">
        <div class="cardExpiryLabel">
          {{ $t('profile.editPaymentDetail.expiryDate') }}
        </div>
        <div class="yearAndMonthPicker">
          <v-text-field
            v-model="cardExpiry"
            :placeholder="$t('profile.editPaymentDetail.expiryDateFormat')"
            @keyup="(e) => formatExpiryDate(e)"
            :maxlength="5"
            :class="
              cardExpiry.length === 5 && !isCardExpiryValid
                ? 'invalidExpiry'
                : 'validExpiry'
            "
          />
        </div>
        <div class="expiryError" v-if="!isCardExpiryValid">
          {{ cardExpiryError }}
        </div>
      </div>
    </div>
    <div
      class="makeDefaultCheckboxContainer"
      v-if="methodMode === 'createMethod'"
    >
      <v-checkbox
        v-model="makeDefault"
        :label="$t('profile.editPaymentDetail.setAsDefaultMethod')"
        color="#20a7e0"
        hide-details
        @click="handleDefaultClick"
        v-if="!noCardsPresent"
      />
      <div class="makeDefaultNoCardsPresent" v-else>
        {{ $t('profile.editPaymentDetail.defaultCard') }}
      </div>
    </div>
    <div class="actionButtonContainer">
      <div
        class="buttonsContainer"
        :style="{ width: `${methodMode === 'createMethod' ? 11 : 35}%` }"
      >
        <v-btn
          class="cancel"
          @click="handleModalClose"
          v-if="methodMode === 'updateMethod'"
        >
          {{ $t('common.cancel') }}
        </v-btn>
        <v-btn
          class="accept"
          v-if="methodMode === 'updateMethod'"
          :disabled="
            cardHolderName === '' ||
            cardExpiry === '' ||
            cardExpiry.length < 5 ||
            !isCardExpiryValid ||
            cardHolderName.length > 30
          "
          @click="handlePaymentMethodUpdate"
          :loading="updating === true"
        >
          {{ $t('common.update') }}
        </v-btn>
        <v-btn
          class="accept"
          v-if="methodMode === 'createMethod'"
          :disabled="
            isCardElementError === true ||
            cardHolderName === '' ||
            cardHolderName.length > 30
          "
          @click="handleAddPaymentMethod"
          :loading="creating === true"
        >
          {{ $t('common.add') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { StripeElementCard } from '@vue-stripe/vue-stripe';
import {
  createNewPaymentMethod,
  updatePaymentMethodDetails,
  markPaymentMethodAsDefault,
} from '../../../../API/payment-api';
import { trackProfileEvents } from '../../../common/Analytics/ProfileEvents';
import {
  TD_PLACEMENT,
  TD_EDIT,
  TD_CARD_ADDITION,
  TD_COMMON_COLUMN_NAME,
  TD_COMMON_COLUMN_VALUE,
  TD_CLICK_STATUS,
  TD_CHECKED,
  TD_UNCHECKED,
} from '../../../common/Analytics/MatomoTrackingDataHelper';

export default {
  name: 'AddPaymentMethodModal',
  props: {
    noCardsPresent: {
      type: Boolean,
      default: true,
    },
    methodMode: {
      type: String,
      default: 'createMethod',
    },
    card: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    StripeElementCard,
  },
  data() {
    return {
      striprPublicKey: process.env.VUE_APP_STRIPE_PUBLIC_KEY,
      isCardElementError: true,
      cardHolderName: '',
      makeDefault: false,
      creating: false,
      logos: {
        visa: '/assets/img/Visa.svg',
        mastercard: '/assets/img/Mastercard.svg',
        amex: '/assets/img/American-Express.svg',
        jcb: '/assets/img/JCB.svg',
        unionpay: '/assets/img/Union-Pay.svg',
        discover: '/assets/img/Discover.svg',
        diners: '/assets/img/Diners-Club.svg',
      },
      updating: false,
      cardExpiry: '',
      isCardExpiryValid: false,
      cardExpiryError: '',
    };
  },
  computed: {
    ...mapState('users', ['currentUser']),
  },
  watch: {
    cardExpiry(val) {
      const currDate = new Date();
      const currYear = currDate.getUTCFullYear();
      const currYearOfCentury = Number(currYear.toString().slice(-2));
      if (val.length === 5 && this.methodMode === 'updateMethod') {
        const dateArr = val.split('/');
        if (
          dateArr.length === 2 &&
          dateArr[0].length === 2 &&
          dateArr[1].length === 2 &&
          Number(dateArr[1]) - currYearOfCentury <= 50
        ) {
          if (
            !this.isCardExpired(
              Number(dateArr[0]),
              Number(`${currYear.toString().slice(0, 2)}${dateArr[1]}`),
            )
          ) {
            this.isCardExpiryValid = true;
          } else {
            this.cardExpiryError = "Your card's expiry date is in the past.";
            this.isCardExpiryValid = false;
          }
        } else {
          this.cardExpiryError = 'Invalid card expiry date.';
          this.isCardExpiryValid = false;
        }
      } else {
        this.cardExpiryError = 'Invalid card expiry date.';
        this.isCardExpiryValid = false;
      }
    },
  },
  methods: {
    ...mapActions('paymentMethods', [
      'setSnackbar',
      'addNewPaymentMethod',
      'setPaymentMethodAsDefault',
      'updatePaymentMethodDetailsInStore',
    ]),

    isCardExpired(expMonth, expYear) {
      const currDate = new Date();
      const currMonth = currDate.getUTCMonth() + 1;
      const currYear = currDate.getUTCFullYear();
      if (expMonth && expYear) {
        if (currYear > expYear) {
          return true;
        }
        if (currYear === expYear && currMonth > expMonth) {
          return true;
        }
        return false;
      }
      return false;
    },

    handleModalClose(e) {
      this.$modal.hide('AddPaymentMethodModal');
      if (this.methodMode !== 'createMethod' && e) {
        trackProfileEvents.profileAdminSettingsPaymentsCancel(
          this.currentUser,
          {
            [TD_PLACEMENT]: TD_EDIT,
          },
        );
      }
    },

    handleDefaultClick() {
      trackProfileEvents.profileAdminSettingsPaymentsDisclaimerBoxClick(
        this.currentUser,
        {
          [TD_PLACEMENT]: TD_CARD_ADDITION,
          [TD_COMMON_COLUMN_NAME]: TD_CLICK_STATUS,
          [TD_COMMON_COLUMN_VALUE]: this.makeDefault
            ? TD_CHECKED
            : TD_UNCHECKED,
        },
      );
    },

    stripeElementCardChanged(event) {
      if (event && (event.error || event.empty || !event.complete)) {
        this.isCardElementError = true;
      } else {
        this.isCardElementError = false;
      }
    },

    getCorrectMonthAndYearForUpdate() {
      const currDate = new Date();
      const currYear = currDate.getUTCFullYear();
      const currCentury = currYear.toString().substring(0, 2);
      const dateArr = this.cardExpiry.split('/');
      const yearStr = `${currCentury}${dateArr[1]}`;
      /* eslint-disable-next-line */
      return {
        month: Number(dateArr[0]),
        year: Number(yearStr),
      };
    },

    async handlePaymentMethodUpdate() {
      trackProfileEvents.profileAdminSettingsPaymentsUpdate(this.currentUser, {
        [TD_PLACEMENT]: TD_EDIT,
      });
      this.updating = true;
      await updatePaymentMethodDetails({
        payment_method_id: this.card.id,
        name: this.cardHolderName,
        exp_month: this.getCorrectMonthAndYearForUpdate().month,
        exp_year: this.getCorrectMonthAndYearForUpdate().year,
      })
        .then((resp) => {
          this.updating = false;
          this.handleModalClose();
          this.setSnackbar({
            status: true,
            text: 'Payment method updated successfully!',
            action: null,
          });
          this.updatePaymentMethodDetailsInStore({
            ...this.card,
            name: this.cardHolderName,
            exp_month: this.getCorrectMonthAndYearForUpdate().month,
            exp_year: this.getCorrectMonthAndYearForUpdate().year,
          });
          console.log(resp);
        })
        .catch((err) => {
          this.updating = false;
          this.handleModalClose();
          this.setSnackbar({
            status: true,
            text: 'Could not update payment method. Something went wrong.',
            action: {
              type: 'Try again',
              data: {
                noCardsPresent: this.noCardsPresent,
                methodMode: this.methodMode,
                card: this.card,
              },
            },
          });
          console.log(err);
        });
    },

    async handleAddPaymentMethod() {
      trackProfileEvents.profileAdminSettingsPaymentsAddNewPaymentMethodAdd(
        this.currentUser,
        {},
      );
      const { stripe } = this.$refs.stripeElementCard;
      this.creating = true;
      await stripe
        .createPaymentMethod({
          type: 'card',
          card: this.$refs.stripeElementCard.element,
          billing_details: {
            name: this.cardHolderName,
            email: this.currentUser.user.id,
          },
        })
        .then(async (res) => {
          const { paymentMethod } = res;
          await createNewPaymentMethod({
            payment_method_id: paymentMethod?.id,
          })
            .then(async (resp) => {
              console.log(resp);
              this.addNewPaymentMethod(resp.data);
              this.setSnackbar({
                status: true,
                text: 'New payment method added successfully!',
                action: null,
              });
              if (this.noCardsPresent === true || this.makeDefault === true) {
                await markPaymentMethodAsDefault({
                  payment_method_id: paymentMethod?.id,
                })
                  .then((response) => {
                    this.setPaymentMethodAsDefault(
                      response?.data?.payment_method_id,
                    );
                    console.log(response);
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
            })
            .catch((er) => {
              console.log(er);
              this.setSnackbar({
                status: true,
                text: 'Could not add payment method. Something went wrong.',
                action: {
                  type: 'Try again',
                  data: {
                    noCardsPresent: this.noCardsPresent,
                    methodMode: this.methodMode,
                    card: this.card,
                  },
                },
              });
              trackProfileEvents.profileAdminSettingsPaymentsErrorPopup(
                this.currentUser,
                {},
              );
            });
        })
        .catch((err) => {
          console.log(err);
          this.setSnackbar({
            status: true,
            text: 'Could not add payment method. Something went wrong.',
            action: {
              type: 'Try again',
              data: {
                noCardsPresent: this.noCardsPresent,
                methodMode: this.methodMode,
                card: this.card,
              },
            },
          });
          trackProfileEvents.profileAdminSettingsPaymentsErrorPopup(
            this.currentUser,
            {},
          );
        });
      this.creating = false;
      this.handleModalClose();
    },

    formatExpiryDate(event) {
      if (event.keyCode === 8) {
        // when cleared using backspace
        if (event.target.value.includes('/')) {
          if (event.target.value.split('/')[0].length <= 1) {
            event.target.value = event.target.value.split('/')[0];
          } else if (!event.target.value.split('/')[1].length) {
            event.target.value = event.target.value.split('/')[0];
          }
        } else if (event.target.value.substring(0, 2).includes('0')) {
          event.target.value = event.target.value.substring(1, 2);
        } else {
          event.target.value = event.target.value.substring(0, 2);
        }
        this.cardExpiry = event.target.value;
      } else {
        event.target.value = event.target.value
          .replace(
            /^([1-9]\/|[2-9])$/g,
            '0$1/', // 3 > 03/
          )
          .replace(
            /^(0[1-9]|1[0-2])$/g,
            '$1/', // 11 > 11/
          )
          .replace(
            /^([0-1])([3-9])$/g,
            '0$1/$2', // 13 > 01/3
          )
          .replace(
            /^(0?[1-9]|1[0-2])([0-9]{2})$/g,
            '$1/$2', // 141 > 01/41
          )
          .replace(
            /^([0]+)\/|[0]+$/g,
            '0', // 0/ > 0 and 00 > 0
          )
          .replace(
            /* eslint-disable-next-line */
            /[^\d\/]|^[\/]*$/g,
            '', // To allow only digits and `/`
          )
          .replace(
            /\/\//g,
            '/', // Prevent entering more than 1 `/`
          );
        this.cardExpiry = event.target.value;
      }
    },
  },
  mounted() {
    if (this.methodMode === 'updateMethod') {
      this.cardHolderName = this.card.name;
      /* eslint prefer-destructuring: ["error", {VariableDeclarator: {object: true}}] */
      this.cardExpiry = `${
        this.card.exp_month.toString().length <= 1
          ? `0${this.card.exp_month}`
          : this.card.exp_month
      }/${this.card.exp_year.toString().slice(-2)}`;
    }
  },
};
</script>

<style lang="scss" scoped>
.addPaymentMethodModalContainer {
  width: 100%;
  padding: 24px 32px;
  .closeIconContainer {
    text-align: right;
    position: absolute;
    right: 10px;
    top: 8px;
  }
  .headingContainer {
    width: 100%;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
  .stripeFieldContainer {
    width: 100%;
    height: 130px;
    padding: 16px 0px 0px 0px;
    .cardFieldLabel {
      width: 100%;
      padding: 16px 0px 8px 0px;
      font-size: 16px;
      font-weight: 600;
    }
    ::v-deep .stripeElement {
      #stripe-element-form {
        .StripeElement {
          box-shadow: none !important;
          border-radius: 0px !important;
          padding: 14px 0px 0px 0px;
          border-bottom: thin solid rgba(0, 0, 0, 0.42);
        }
        .StripeElement--focus {
          border-bottom: thin solid #21a7e0 !important;
        }
        .StripeElement--invalid {
          box-shadow: none !important;
          border-radius: 0px !important;
          padding: 14px 0px 0px 0px;
          border-color: transparent transparent #fa755a transparent;
        }
        #stripe-element-errors {
          color: #fa755a;
        }
      }
    }
    .disabledTextFieldOnUpdate {
      padding: 10px 0px 0px 0px !important;
      margin: 0 !important;
      ::v-deep .v-input__control {
        .v-input__slot {
          margin: 0 !important;
          padding: 0px 20px 0px 0px !important;
          .v-input__prepend-inner {
            .prependIconContainer {
              width: 30px;
              height: 24px;
              .v-image {
                width: 100%;
                height: 100%;
              }
            }
            .cardMask {
              padding: 3px 7px 0px 10px;
              font-size: 29px;
              height: 14px;
            }
            .cardLast4 {
              font-size: 14px;
              font-weight: 600;
              padding-top: 4px;
              height: 16px;
            }
          }
          .v-input__append-inner {
            display: flex;
            width: 14%;
            justify-content: space-between;
            .cvvText {
              font-weight: 600;
              padding: 5px 0px 0px 0px;
            }
            .cardCVVMask {
              font-size: 24px;
              font-weight: 600;
              padding: 6px 0px 0px 0px;
            }
          }
          &:before {
            border-image: none !important;
          }
        }
        .v-text-field__details {
          display: none;
        }
      }
    }
  }
  .nameAndExpiryFields {
    display: flex;
    justify-content: space-between;
    .cardHolderNameField {
      height: 100px;
      .cardHolderNameLabel {
        width: 100%;
        padding: 16px 0px 14px 0px;
        font-size: 16px;
        font-weight: 600;
      }
      .nameFieldContainer {
        width: 100%;
        ::v-deep .v-input {
          padding: 0;
          margin: 0;
          .v-input__control {
            .v-input__slot {
              margin: 0 !important;
              &:before {
                border-style: none !important;
                border-width: 0px !important;
                border-bottom: thin solid rgba(0, 0, 0, 0.42) !important;
              }
              &:after {
                border: none !important;
              }
            }
            .v-text-field__details {
              display: none;
            }
          }
        }
        ::v-deep .v-input--is-focused {
          padding: 0;
          margin: 0;
          .v-input__control {
            .v-input__slot {
              margin: 0 !important;
              &:before {
                border-style: none !important;
                border-width: 0px !important;
                border-bottom: thin solid #21a7e0 !important;
              }
              &:after {
                border: none !important;
              }
            }
            .v-text-field__details {
              display: none;
            }
          }
        }
        .nameError {
          color: red;
        }
      }
    }
    .cardExpiryField {
      width: 48%;
      height: 100px;
      .cardExpiryLabel {
        width: 100%;
        padding: 16px 0px 14px 0px;
        font-size: 16px;
        font-weight: 600;
      }
      .yearAndMonthPicker {
        width: 100%;
        ::v-deep .v-input {
          padding: 0;
          margin: 0;
          .v-input__control {
            .v-input__slot {
              margin: 0 !important;
              &:before {
                border-style: none !important;
                border-width: 0px !important;
                border-bottom: thin solid rgba(0, 0, 0, 0.42) !important;
              }
              &:after {
                border: none !important;
              }
            }
            .v-text-field__details {
              display: none;
            }
          }
        }
        ::v-deep .v-input--is-focused {
          padding: 0;
          margin: 0;
          .v-input__control {
            .v-input__slot {
              margin: 0 !important;
              &:before {
                border-style: none !important;
                border-width: 0px !important;
                border-bottom: thin solid #21a7e0 !important;
              }
              &:after {
                border: none !important;
              }
            }
            .v-text-field__details {
              display: none;
            }
          }
        }
      }
      .expiryError {
        color: #fa755a;
      }
    }
  }
  .makeDefaultCheckboxContainer {
    width: 100%;
    padding: 16px 0px;
    .v-input {
      margin: 0 !important;
      padding: 0 !important;
      ::v-deep .v-input__control {
        .v-input__slot {
          .v-label {
            left: 6px !important;
            color: black !important;
          }
        }
      }
    }
    .makeDefaultNoCardsPresent {
      font-size: 16px;
    }
  }
  .actionButtonContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 16px 0px 0px 0px;
    .buttonsContainer {
      // width: 35%;
      display: flex;
      justify-content: space-between;
      .cancel,
      .accept {
        background: transparent;
        box-shadow: none;
        letter-spacing: normal;
        text-transform: none;
        font-size: 17px;
        color: #20a7e0;
        border-radius: 22px;
      }
      ::v-deep .cancel {
        color: #20a7e0;
        border: 1px solid #20a7e0;
        background-color: transparent;
        .v-btn__content {
          line-height: 25px !important;
        }
      }
      ::v-deep .accept {
        color: #fff;
        border: 0px;
        background-color: #20a7e0;
        .v-btn__content {
          line-height: 25px !important;
        }
      }
    }
  }
}
</style>
