<template>
  <div class="certificate-modal-container">
    <div v-if="!loading">
      <v-btn class="close-btn" icon @click="close">
        <v-icon dark v-text="`mdi-close`" />
      </v-btn>
      <div class="content">
        <img
          src="/assets/img/learn/email-success.svg"
          alt
          width="350"
          height="350"
          class="display-image"
        />
        <div class="display-text">
          {{ $t('learn.intialLoad.sentYourCertificate') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  name: 'EmailSentModal',
  props: {},
  data() {
    return {
      loading: false,
    };
  },
  computed: {},
  // async beforeMount() {
  //   await this.getCertificateDetails();
  // },
  methods: {
    close() {
      this.$modal.hide('EmailSentModal');
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/scss/variables.scss';
.certificate-modal-container {
  height: 100%;
  overflow: scroll;

  .spinner {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;
  }

  .content {
    display: flex;
    flex-direction: column;

    .display-image {
      margin: auto;
      padding: 80px 0px 30px 0px;
    }

    .display-text {
      margin: auto;
      margin: 0 38px 37px 36px;
      font-family: Roboto;
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #000;
    }
  }
}
</style>
