<template>
  <span>
    <div v-if="type === 'status'">
      <div v-if="!isEditable || !isPrezentationOwner">
        <div class="permission-status">
          <span v-if="prezentation.permission_level === 1">
            <v-icon class="permission-status-icon">mdi-lock-outline</v-icon>
            {{ $t('prezentationShareStatus.viewer') }}
          </span>
          <span v-if="prezentation.permission_level === 0">
            <v-icon class="permission-status-icon">mdi-lock-outline</v-icon>
            {{ $t('prezentationShareStatus.restricted') }}
          </span>
          <span v-if="prezentation.permission_level === 2">
            <v-icon class="permission-status-icon">mdi-comment-outline</v-icon>
            {{ $t('prezentationShareStatus.reviewer') }}
          </span>
          <span v-if="isPrezentationOwner">
            <v-icon class="permission-status-icon">
              mdi-account-check-outline
            </v-icon>
            {{ $t('build.step1.private') }}
          </span>
          <!-- <span class="status-label" v-if="isShared">
            <v-icon class="status-icon">
              mdi-account-multiple-check-outline
            </v-icon>
            Shared</span
          > -->
          <span v-else-if="prezentation.permission_level === 3">
            <v-icon class="permission-status-icon">
              mdi-lock-open-variant-outline
            </v-icon>
            {{ $t('prezentationShareStatus.editor') }}
          </span>
        </div>
      </div>
      <div v-if="isEditable && isPrezentationOwner">
        <v-tooltip
          top
          max-width="200"
          content-class="tooltip-content"
          fixed
          :disabled="origin.includes('expanded')"
          open-delay="500"
          close-delay="300"
        >
          <template v-slot:activator="{ on, attrs }">
            <div
              @click.prevent="
                origin.includes('expanded')
                  ? () => {}
                  : openSharingOptions(prezentation)
              "
              :class="!origin.includes('expanded') && 'prez-status clickable'"
              v-bind="attrs"
              v-on="on"
            >
              <span class="status-label" v-if="isShared">
                <v-icon
                  :class="
                    origin.includes('expanded')
                      ? 'status-icon-expand'
                      : 'status-icon'
                  "
                >
                  mdi-account-multiple-check-outline
                </v-icon>
                {{ $t('build.step1.shared') }}
              </span>
              <span class="status-label" v-else>
                <v-icon
                  :class="
                    origin.includes('expanded')
                      ? 'status-icon-expand'
                      : 'status-icon'
                  "
                >
                  mdi-account-check-outline
                </v-icon>
                {{ $t('build.step1.private') }}
              </span>
            </div>
          </template>
          <span>{{ $t('common.share') }}</span>
        </v-tooltip>
      </div>
    </div>
    <div
      class="iconOrigin"
      v-if="type === 'icon'"
      @click="
        origin.includes('expanded')
          ? () => {}
          : openSharingOptions(prezentation)
      "
    >
      <div class="icon">
        <v-icon>mdi-account-check-outline</v-icon>
      </div>
      <div class="actionName">{{ $t('common.share') }}</div>
    </div>
  </span>
</template>
<script>
import SharePrezentation from './SharePrezentation.vue';

export default {
  name: 'PrezentationShareStatus',
  props: {
    type: {
      type: String,
      default: 'status',
    },
    currentUser: {
      type: Object,
      required: false,
    },
    prezentation: {
      type: Object,
      required: true,
    },
    isEditable: {
      type: Boolean,
      required: false,
      default: false,
    },
    prezentationStatus: {
      type: Boolean,
      required: false,
      default: false,
    },
    iconSize: {
      type: String,
      required: false,
      default: '30px',
    },
    iconColor: {
      type: String,
      required: false,
      default: 'primary',
    },
    prezentationUpdatedFn: {
      type: Function,
      required: false,
    },
    origin: {
      type: String,
      required: false,
    },
    source: {
      type: String,
      required: false,
    },
  },
  components: {},
  mounted() {},
  computed: {
    isPrezentationOwner() {
      return (
        this.prezentation.ownerID &&
        this.currentUser &&
        this.prezentation.ownerID.toLowerCase() ===
          this.currentUser.user.id.toLowerCase()
      );
    },
    isShared() {
      if (typeof this.prezentation.shared === 'boolean') {
        return this.prezentation.shared;
      }
      let isSharedd = false;
      this.prezentation?.acl_list?.forEach((ele) => {
        if (
          ele.includes('shared') &&
          ele.includes(this.currentUser.user.num_id)
        ) {
          isSharedd = true;
        }
      });
      return isSharedd;
    },
  },
  methods: {
    openSharingOptions(prezentation) {
      console.log(this.origin);
      this.$modal.show(
        SharePrezentation,
        {
          prezentation,
          prezentationUpdatedFn: this.prezentationUpdatedFn,
          origin: this.origin,
          source: this.source,
        },
        {
          name: 'SharePrezentation',
          width: 700,
          height: 'auto',
          minHeight: 300,
          styles: { borderRadius: '20px' },
        },
      );
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/variables.scss';

.status-icon {
  color: $zen-blue-default;
  font-size: 14px;
  margin-bottom: 2px;
}
.status-icon-expand {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  margin-bottom: 2px;
}

.prez-status {
  color: $zen-blue-default;
}
.clickable {
  cursor: pointer;
}

.permission-status {
  .permission-status-icon {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    margin-bottom: 2px;
  }
}

.iconOrigin {
  display: flex;
  .actionName {
    padding: 2px 4px 0px 4px;
  }
}
</style>
