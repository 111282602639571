var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"us-table"},[_c('div',{staticClass:"us-table__header"},[_c('h3',{staticClass:"us-table__heading"},[_vm._v(" "+_vm._s(_vm.$t('profile.metricDetail.usageStatistics'))+" ")]),_c('v-tooltip',{attrs:{"right":"","max-width":"250","content-class":"tooltip-content","fixed":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"icon-title"},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('profile.metricDetail.detailedViewOfUsageStatistics'))+" ")])]),_c('v-tooltip',{attrs:{"right":"","max-width":"200","content-class":"tooltip-content","fixed":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.downloadCSV()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-download-outline")])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('profile.metricDetail.usageStatisticsCsv'))+" ")])])],1),_c('v-card',{class:_vm.getHeaders.length > 8 ? 'fullTable' : 'smallTable',attrs:{"rounded":""}},[_c('v-data-table',{staticClass:"us-table__tbl elevation-1",attrs:{"headers":_vm.getHeaders,"items":_vm.teamstats,"search":_vm.teamStatsSearch,"no-results-text":_vm.$t('build.step1.storylineFieldNoResult'),"items-per-page":10,"item-class":_vm.team_stats_row_classes,"loading":_vm.loading,"loading-text":'Loading users...',"footer-props":{ 'items-per-page-options': [10, 25, 50, 100] },"custom-filter":_vm.customSearch},scopedSlots:_vm._u([{key:"header",fn:function(ref){return [_c('thead',[_c('tr',_vm._l((_vm.allHeaders),function(group,groupIndex){return _c('th',{key:("header_" + groupIndex),class:groupIndex === 0 ? 'fixed-column' : '',attrs:{"colspan":_vm.getColspanLength(group)}},[_vm._v(" "+_vm._s(group.text)+" ")])}),0)])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('div',{staticClass:"us-table__toolbar"},[_c('v-text-field',{staticClass:"admin-table-search-input",attrs:{"append-icon":"mdi-magnify","single-line":"","hide-details":"","placeholder":_vm.$t('profile.searchByEmailOrFirstname'),"clearable":"","clear-icon":'mdi-close'},nativeOn:{"keyup":function($event){$event.stopPropagation();return _vm.handleTeamStatsSearch($event)}},model:{value:(_vm.teamStatsSearch),callback:function ($$v) {_vm.teamStatsSearch=$$v},expression:"teamStatsSearch"}}),_c('TimelineFilter',{attrs:{"origin":"UsageStatistics"},on:{"change":function($event){return _vm.fetchUsageStatistics()}},model:{value:(_vm.range),callback:function ($$v) {_vm.range=$$v},expression:"range"}})],1)])]},proxy:true},{key:"header.slidesGenerated",fn:function(ref){
var header = ref.header;
return [_c('NewFeatureBadge',{attrs:{"moduleName":'generate',"featureId":'generate_id',"size":"medium","offsetX":'0',"offsetY":'0'}},[_vm._v(" "+_vm._s(header.text)+" ")])]}},{key:"header.userUploadedSlides",fn:function(ref){
var header = ref.header;
return [_c('NewFeatureBadge',{attrs:{"module-name":"upload","feature-id":'upload_landing_id',"size":"medium","offsetX":'10',"offsetY":'0'}},[_vm._v(" "+_vm._s(header.text)+" ")])]}},{key:"header.userUploadedPrezentation",fn:function(ref){
var header = ref.header;
return [_c('NewFeatureBadge',{attrs:{"moduleName":'generate',"featureId":'generate_id',"size":"medium","offsetX":'0',"offsetY":'0'}},[_vm._v(" "+_vm._s(header.text)+" ")])]}},{key:"header.complySlides",fn:function(ref){
var header = ref.header;
return [_c('NewFeatureBadge',{attrs:{"moduleName":'comply',"featureId":'comply_id',"size":"medium","offsetX":'13',"offsetY":'0'}},[_vm._v(" "+_vm._s(header.text)+" ")])]}},{key:"header.prezentationsGenerated",fn:function(ref){
var header = ref.header;
return [_c('NewFeatureBadge',{attrs:{"moduleName":'generate',"featureId":'generate_id',"size":"medium","offsetX":'0',"offsetY":'0'}},[_vm._v(" "+_vm._s(header.text)+" ")])]}},{key:"header.prezsSynthesized",fn:function(ref){
var header = ref.header;
return [_c('NewFeatureBadge',{attrs:{"moduleName":'synthesis',"featureId":'synthesis_id',"size":"medium","offsetX":'10',"offsetY":'0'}},[_vm._v(" "+_vm._s(header.text)+" ")])]}},{key:"header.slidesRedesigned",fn:function(ref){
var header = ref.header;
return [_c('NewFeatureBadge',{attrs:{"moduleName":'redesign',"featureId":'redesign_id',"size":"medium","offsetX":'10',"offsetY":'0'}},[_vm._v(" "+_vm._s(header.text)+" ")])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('AudienceItem',{attrs:{"firstName":item.firstName,"lastName":item.lastName,"profileImgS3Path":item.profileImageUrl || '',"email":item.id,"status":item.active ? '' : 'Disabled'}})]}},{key:"item.slide",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.slide))+" ")]}},{key:"item.slidesGenerated",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.slidesGenerated))+" ")]}},{key:"item.complySlides",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.complySlides))+" ")]}},{key:"item.prezentationsGenerated",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.prezentationsGenerated))+" ")]}},{key:"item.userUploadedSlides",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.userUploadedSlides))+" ")]}},{key:"item.searches",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.searches))+" ")]}},{key:"item.learnModulesCompleted",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.learnModulesCompleted))+" ")]}},{key:"item.prezsSynthesized",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.prezsSynthesized))+" ")]}},{key:"item.slidesRedesigned",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.slidesRedesigned))+" ")]}},{key:"item.knowDownloads",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.knowDownloads))+" ")]}},{key:"item.bpeDownloadedCount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.bpeDownloadedCount))+" ")]}},{key:"item.prezBuilt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.prezBuilt))+" ")]}},{key:"item.userUploadedPrezentation",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.userUploadedPrezentation))+" ")]}},{key:"item.fingerprintStats",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.fingerprintStats))+" ")]}},{key:"item.themeRequests",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.themeRequests))+" ")]}},{key:"body.append",fn:function(){return [_c('tr',{staticClass:"footer-total"},[_c('td',{staticClass:"us-table__cell-sticky"},[_vm._v("Total")]),_c('td',[_vm._v(_vm._s(_vm.sumField['searches'].toLocaleString()))]),_c('td',[_vm._v(_vm._s(_vm.sumField['slide'].toLocaleString()))]),(_vm.isGenerateEnabled)?_c('td',[_vm._v(" "+_vm._s(_vm.sumField['slidesGenerated'].toLocaleString())+" ")]):_vm._e(),_c('td',[_vm._v(_vm._s(_vm.sumField['prezBuilt'].toLocaleString()))]),(_vm.isPrezentationUploadEnabled)?_c('td',[_vm._v(" "+_vm._s(_vm.sumField['userUploadedSlides'].toLocaleString())+" ")]):_vm._e(),(_vm.isRedesignEnabled)?_c('td',[_vm._v(" "+_vm._s(_vm.sumField['slidesRedesigned'].toLocaleString())+" ")]):_vm._e(),(_vm.isSynthesisEnabled)?_c('td',[_vm._v(" "+_vm._s(_vm.sumField['prezsSynthesized'].toLocaleString())+" ")]):_vm._e(),(_vm.isComplyEnabledWithAccess)?_c('td',[_vm._v(" "+_vm._s(_vm.sumField['complySlides'].toLocaleString())+" ")]):_vm._e(),_c('td',[_vm._v(_vm._s(_vm.sumField['themeRequests'].toLocaleString()))]),(_vm.isKnowEnabled)?_c('td',[_vm._v(" "+_vm._s(_vm.sumField['knowDownloads'].toLocaleString())+" ")]):_vm._e(),(_vm.isKnowEnabled)?_c('td',[_vm._v(" "+_vm._s(_vm.sumField['bpeDownloadedCount'].toLocaleString())+" ")]):_vm._e(),_c('td',[_vm._v(_vm._s(_vm.sumField['fingerprintStats'].toLocaleString()))]),_c('td',[_vm._v(" "+_vm._s(_vm.sumField['learnModulesCompleted'].toLocaleString())+" ")])])]},proxy:true}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }