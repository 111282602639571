<template>
  <div
    class="storyline-step"
    :style="{ 'margin-top': `${isFromDetailView ? '32px' : '0px'}` }"
  >
    <div
      class="storyline-container"
      :style="{
        width: `${leftNavExpanded || !isGalleryOpen ? '100%' : '70%'}`,
      }"
    >
      <div class="header-context">
        <div
          class="define-context-header"
          v-if="renderFor === 'build prezentation'"
        >
          {{ getTitles.title }}
        </div>
        <div class="define-context-header" v-if="renderFor === 'storyline'">
          {{ storylineData.type }} {{ $t('build.step2.storylineText') }}
        </div>
        <div
          class="define-context-description"
          v-if="renderFor === 'storyline'"
        >
          {{ storylineData.description }}
        </div>
        <div v-if="showMoreInfo && renderFor === 'build prezentation'">
          <div class="define-context-sub-header">
            {{ getTitles.subTitle }}
          </div>
          <div class="define-context-description">
            {{ getTitles.subText1 }}
            <br />
            {{ getTitles.subText2 }}
          </div>
        </div>
        <div
          @click="handleShowMoreInfo"
          class="more-info"
          v-if="renderFor === 'build prezentation'"
        >
          {{
            showMoreInfo
              ? $t('build.step2.lessInfo')
              : $t('build.step2.moreInfo')
          }}

          <v-icon id="more-information--auto">
            {{ showMoreInfo ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
          </v-icon>
        </div>
        <v-row no-gutters class="sections-row">
          <draggable
            class="row no-gutters sections"
            :list="sections"
            group="sections"
            v-bind="dragOptions"
            :options="{ disabled: isInEditMode }"
            draggable=".dragable"
            @add="addSection($event)"
            @end="handleSectionEnd"
          >
            <v-col
              v-for="(section, sectionIndex) in sections"
              :key="sectionIndex"
              :class="`section-col dragable section-${sectionIndex}-dragarea--auto ${
                hoverSectionIndex === sectionIndex ? 'hover' : ''
              }`"
              :data-pendo-id="
                sectionIndex === 0 ? 'build-middle-section' : undefined
              "
              :style="getWidth"
              @dragover="hoverSectionIndex = sectionIndex"
              @dragend="onDragEnd(section)"
            >
              <SectionHeader
                :data="section"
                :onNameChange="
                  (newValue) => onSectionNameChange(sectionIndex, newValue)
                "
                :count="section.slides.length.toString()"
                :onDeleteClick="
                  () => handleDeleteSection(section, sectionIndex)
                "
                :onEdit="(val) => (isInEditMode = val)"
              />
              <draggable
                class="dragArea list-group"
                :list="section.slides"
                group="slides"
                v-bind="dragOptions"
                draggable=".idea-chip"
                @add="addIdea($event, sectionIndex)"
              >
                <OutlineItem
                  v-for="(slide, slideIndex) in section.slides"
                  :index="slideIndex"
                  :key="slideIndex"
                  :data="slide"
                  :onDuplicate="
                    () => handleDuplicateSlide(slide, slideIndex, sectionIndex)
                  "
                  :onDelete="
                    () => handleDeleteSlide(slide, slideIndex, sectionIndex)
                  "
                />
                <!-- <div class="empty-idea">Drag & drop ideas here</div> -->
              </draggable>
            </v-col>

            <v-col
              class="section-col"
              :style="getWidth"
              v-if="sections.length < 5"
            >
              <SectionHeader
                :onNameChange="(newValue) => handleAddSection(newValue)"
                :isAddNewSection="true"
              />
            </v-col>
          </draggable>
        </v-row>
      </div>
    </div>
    <div>
      <v-btn
        class="detail-expand-btn"
        small
        @click="handleGalleryPanelToggle"
        v-if="leftNavExpanded || !isGalleryOpen"
      >
        <v-icon color="#21a7e0" class="gallery-icon"> mdi-chevron-left </v-icon>
        <span class="gallery-text">
          {{ $t('prezentationGallery.title') }}
        </span>
      </v-btn>
      <div
        :class="`panel ${isBannerActive ? 'banner-active' : ''}`"
        v-if="!leftNavExpanded && isGalleryOpen"
      >
        <div
          class="gallery-container"
          :style="{
            'margin-top': `${origin === 'viewStoryline' ? '26px' : '40px'}`,
          }"
        >
          <div class="define-context-header" data-pendo-id="build-gallery">
            {{ $t('build.step2.galleryText') }}
          </div>
          <div class="gallery-close-btn">
            <v-hover v-slot="{ hover }">
              <v-btn
                depressed
                small
                icon
                :ripple="false"
                @click="handleRightPanelClose"
                :color="hover ? '#21A7E0' : '#000'"
              >
                <v-icon name="close-icon"> mdi-close </v-icon>
              </v-btn>
            </v-hover>
          </div>
          <v-tabs v-model="tab">
            <v-tab style="font-size: 16px; padding: 0 71px 0 60px">{{
              $t('build.step2.ideaText')
            }}</v-tab>
            <v-tab style="font-size: 16px; padding: 0 60px 0 49px">{{
              $t('build.step2.sectionsText')
            }}</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item class="gallery-idea">
              <v-combobox
                v-model="searchIdea"
                :items="filteredIdeaGallery"
                :loading="isLoadingIdeaSuggestions"
                @keyup="searchIdeas"
                @change="handleIdeaSelect"
                :open-on-clear="true"
                @click:clear="
                  () => {
                    filteredIdeaGallery = [];
                  }
                "
                class="search-input"
                solo
                clearable
                :filter="
                  (item) => {
                    return item.outline;
                  }
                "
                hide-details
                item-text="outline"
                item-value="category"
                :label="$t('build.step2.searchIdeaLabel')"
                :placeholder="$t('build.step2.searchIdeaLabel')"
                append-icon="mdi-magnify"
                return-object
              >
                <template v-slot:no-data>
                  <v-list-item v-if="searchIdea && searchIdea.length">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ $t('build.step1.storylineFieldNoResult') }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-slot:item="{ item, attrs, on }">
                  <v-list-item
                    v-on="on"
                    v-bind="attrs"
                    :disabled="item.disabled"
                  >
                    <v-list-item-content>
                      <v-list-item-title style="font-size: 14px">
                        {{ item.outline }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-combobox>

              <draggable
                class="dragArea list-group"
                :list="ideaGallery"
                v-bind="dragOptions"
                :group="{ name: 'slides', put: false }"
                @end="handleIdeaGalleryDragEnd()"
              >
                <OutlineItem
                  v-for="(slide, slideIndex) in ideaGallery"
                  :index="slideIndex"
                  :key="slide.outline + slideIndex"
                  :click="handleCurrentIdeaGallery"
                  :data="slide"
                  :isGalleryIdea="true"
                />
              </draggable>
            </v-tab-item>
            <v-tab-item class="gallery-section">
              <div v-if="sections.length >= 5" class="section-limit">
                {{ $t('build.step2.sectionLimitText') }}
              </div>
              <v-combobox
                v-model="searchSection"
                :items="filteredSectionGallery"
                :loading="isLoadingSectionSuggestions"
                @keyup="searchSections"
                @change="handleSectionSelect"
                :open-on-clear="true"
                @click:clear="() => (filteredSectionGallery = [])"
                class="search-input"
                solo
                :filter="
                  (item) => {
                    return item.sectionName;
                  }
                "
                clearable
                hide-selected
                hide-details
                item-text="sectionName"
                item-value="slides"
                :label="$t('build.step2.searchSectionLabel')"
                :placeholder="$t('build.step2.searchSectionLabel')"
                append-icon="mdi-magnify"
                return-object
              >
                <template
                  v-slot:no-data
                  v-if="searchSection && searchSection.length"
                >
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ $t('build.step1.storylineFieldNoResult') }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-slot:item="{ item, attrs, on }">
                  <v-list-item
                    v-on="on"
                    v-bind="attrs"
                    :disabled="item.disabled"
                  >
                    <v-list-item-content>
                      <v-list-item-title style="font-size: 14px">
                        {{ item.sectionName }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-combobox>
              <div
                @click="
                  () => {
                    isExpanded = !isExpanded;
                    hoverExpandIndex = null;
                  }
                "
                class="expand-all-btn"
              >
                {{
                  !isExpanded
                    ? $t('build.step2.expandAllText')
                    : $t('build.step2.collapseAllText')
                }}
                <v-icon
                  id="expand-collapse--auto"
                  :style="{ 'margin-left': `${!isExpanded ? '70%' : '68%'}` }"
                >
                  {{ !isExpanded ? 'mdi-chevron-down' : 'mdi-chevron-up' }}
                </v-icon>
              </div>
              <v-expansion-panels
                v-model="getExpandedList"
                accordion
                flat
                tile
                multiple
              >
                <draggable
                  class="sections-drag"
                  :list="sectionGallery"
                  v-bind="dragOptions"
                  :group="{ name: 'sections', put: false }"
                  :options="{ disabled: sections.length >= 5 }"
                  @start="handleSectionDragStart"
                  @end="handleSectionDragEnd"
                >
                  <v-expansion-panel
                    v-for="(section, i) in sectionGallery"
                    :class="`section-${i}-dragarea--auto`"
                    :key="i"
                  >
                    <v-expansion-panel-header>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <div
                            class="chip-title section-header-text"
                            v-bind="attrs"
                            v-on="on"
                            v-on:mouseover="() => handleSectionMouseover(i)"
                            @mousedown="() => handleSectionMouseDown(i)"
                          >
                            <div class="drag-icon">
                              <v-icon dense> mdi-dots-vertical </v-icon>
                              <v-icon dense> mdi-dots-vertical </v-icon>
                            </div>
                            <div class="title-text">
                              {{ section.sectionName }}
                            </div>
                          </div>

                          <v-badge
                            color="#FFE1B7"
                            class="badge"
                            :content="section.slides.length.toString()"
                            inline
                          ></v-badge>
                        </template>
                        <span>
                          {{ section.sectionName }}
                        </span>
                      </v-tooltip>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <OutlineItem
                        v-for="(slide, slideIndex) in section.slides"
                        :key="slide.outline + slideIndex"
                        :data="slide"
                        :isGalleryIdea="true"
                        :isDragable="false"
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </draggable>
              </v-expansion-panels>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import debounce from 'lodash/debounce';
import { graphqlOperation } from 'aws-amplify';
import { mapActions, mapState } from 'vuex';
import OutlineItem from './OutlineItem.vue';
import SectionHeader from './SectionHeader.vue';
import {
  getSectionGalery,
  logSearchQuery,
} from '../../../../../utils/api-helper';
import {
  performAutoComplete,
  performSearch,
} from '../../../../../graphql/queries';
import { AnalyticsHandler } from '../../../../common/Analytics/AnalyticsHandler';
import EventBus from '../../../../common/event-bus';
import { getFileURL } from '@/utils/calyrex';
import { trackBuildEvents } from '../../../../common/Analytics/BuildEvents';
import {
  TD_PREZNAME,
  TD_QUERY,
  TD_SECTION_NAME,
  getPrezData,
  TD_SECTION_4,
  TD_SECTION_5,
  TD_NORESULTSFOUND,
  TD_IDEA,
  TD_PLACEMENT,
  TD_STEP2,
  TD_SLIDE_SOURCE,
} from '../../../../common/Analytics/MatomoTrackingDataHelper';
import { trackPrezentationEvents } from '../../../../common/Analytics/PrezentationEvents';
import { trackSearchEvents } from '../../../../common/Analytics/SearchEvents';
import { GraphQLService } from '../../../../../services/GraphQLService';

export default {
  components: {
    draggable,
    OutlineItem,
    SectionHeader,
  },
  data() {
    return {
      showMoreInfo: false,
      tab: 0,
      panel: [],
      searchIdea: '',
      searchSection: '',
      sections: [],
      ideaGallery: [],
      sectionGallery: [],
      filteredSectionGallery: [],
      isLoadingSectionSuggestions: false,
      filteredIdeaGallery: [],
      isLoadingIdeaSuggestions: false,
      expandedList: [],
      isExpanded: false,
      isInEditMode: false,
      hoverExpandIndex: null,
      hoverSectionIndex: null,
      currentSlideIndex: null,
      isSectionDraging: false,
      leftNavExpanded: false,
      isGalleryOpen: true,
    };
  },
  props: {
    storylineData: {
      type: Object,
      default: null,
    },
    origin: {
      type: String,
      default: null,
    },
    presentationName: {
      type: String,
      default: '',
    },
    renderFor: {
      type: String,
      default: 'build prezentation',
    },
    totalSlides: {
      type: Function,
      default: () => false,
    },
    fingerprint: {
      type: String,
      default: 'director',
    },
    getPrezDetails: {
      type: Function,
      default: () => {},
    },
    hiddenIdeas: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    sections: {
      handler() {
        let count = 0;
        this.sections.forEach((section) => {
          count += section.slides.length;
        });
        this.totalSlides(count);
      },
      deep: true,
    },
    isExpanded(val) {
      if (val) {
        this.expandedList = [];
        this.sectionGallery.forEach((section, sectionIndex) => {
          this.expandedList.push(sectionIndex);
        });
      } else {
        this.expandedList = [];
      }
    },
    tab() {
      this.searchIdea = '';
      this.searchSection = '';
    },
  },
  beforeMount() {
    this.sections = this.storylineData?.sections?.list;
    this.ideaGallery =
      this.storylineData?.ideaGallery?.list || this.ideaGallery || [];
    this.sectionGallery =
      this.storylineData?.sectionGallery?.list || this.sectionGallery || [];
  },
  mounted() {
    setTimeout(() => {
      EventBus.$emit('MINIMIZE_LEFTNAV');
    }, 50);
    EventBus.$on('LEFTNAV_EXPANDED', () => {
      this.leftNavExpanded = true;
      this.setIsStorylineGalleryOpen(false);
    });
    EventBus.$on('LEFTNAV_MINIMIZED', () => {
      this.leftNavExpanded = false;
      this.isGalleryOpen = true;
      this.setIsStorylineGalleryOpen(true);
    });
    console.log('minimize');
  },
  beforeDestroy() {
    EventBus.$off('LEFTNAV_EXPANDED');
    EventBus.$off('LEFTNAV_MINIMIZED');
    this.setIsStorylineGalleryOpen(true);
  },
  computed: {
    ...mapState('users', [
      'currentUser',
      'currentTheme',
      'currentUserFavorites',
      'isBannerActive',
    ]),
    isFromDetailView() {
      return this.$route.query?.origin === 'prezentation';
    },
    getWidth() {
      const { length } = this.sections;
      let i = length < 5 ? 1 : 0;
      if (this.isSectionDraging) {
        i += 1;
      }

      return {
        width: `${100 / (length + i)}%`,
      };
    },
    getExpandedList: {
      get() {
        return [...this.expandedList, this.hoverExpandIndex];
      },
      set() {},
    },
    ideaTitle() {
      const presentationType = this.storylineData.text;
      return this.$t('prezentation.recommendationLabel', { presentationType });
    },
    dragOptions() {
      return {
        animation: 900,
        ghostClass: 'ghost-list',
      };
    },
    getTitles() {
      const { type, title, subTitle, subText1, subText2 } = this.storylineData;
      return {
        title:
          title ||
          this.$t('prezentation.titleRecommendation', {
            presentationType: type,
          }),
        subTitle: subTitle || this.$t('prezentation.subTitleRecommendation'),
        subText1: subText1 || this.$t('prezentation.storylineSubText1'),
        subText2: subText2 || this.$t('prezentation.storylineSubText2'),
      };
    },
  },
  methods: {
    ...mapActions('users', ['setCurrentUser']),
    ...mapActions('prezentations', ['setIsStorylineGalleryOpen']),
    handleRightPanelClose() {
      this.isGalleryOpen = false;
      this.setIsStorylineGalleryOpen(false);
    },
    getRecommendedSlides(payload) {
      const getSlides = (resolve, reject) => {
        const notprefs = {};
        const prefs = {};
        notprefs.construct = [];
        prefs.construct = [];
        if (this.skipEmphasis(payload.category)) {
          notprefs.construct.push('emphasis');
        } else {
          prefs.construct.push('emphasis');
          notprefs.construct.push('category');
        }

        const andprefs = {};
        andprefs.theme = this.currentTheme.code
          ? this.currentTheme.code
          : 'default';
        const params = {
          username: payload.username,
          query: payload.query ? payload.query : '',
          category: payload.category,
          orderby: 'CHOICE_DESC',
          choice: payload.choice,
          morprefs: payload.morprefs ? payload.morprefs : [],
          andprefs,
          prefs,
          notprefs,
          // company: this.currentUser.companyName.toLowerCase(),
          limit: payload.limit,
          skip: payload.skip >= 0 ? payload.skip : 0,
        };
        const serialized = JSON.stringify(params);
        AnalyticsHandler.performOutlineSlideSearch(
          this.currentUser,
          payload.query ? payload.query : payload.category,
        );
        AnalyticsHandler.performSlideSearch(
          this.currentUser,
          payload.query ? payload.query : payload.category,
        );
        GraphQLService.requestWithToken(
          graphqlOperation(performSearch, {
            parameters: serialized,
          }),
        )
          .then(async (res) => {
            const result = JSON.parse(res.data.performSearch);
            if (result.statusCode === 200) {
              const assets = [];
              const slides = result.body.payloads;
              if (!slides || slides.length < 1) {
                resolve(assets);
              } else {
                // eslint-disable-next-line no-restricted-syntax
                for (const slide of slides) {
                  // eslint-disable-next-line no-await-in-loop
                  await (async () => {
                    const obj = {};
                    obj.asset = slide;
                    obj.asset.name = obj.asset.title;
                    obj.asset.file = obj.asset.filename;
                    obj.asset.pptFile = obj.asset.file;
                    obj.isTrending = false;
                    obj.isFavorite = !!this.currentUserFavorites.find(
                      (element) => element.assetID === slide.unique_id,
                    );
                    const filePath = obj.asset.landscape;
                    obj.thumbnail = await getFileURL(
                      this.currentUser.user.id,
                      filePath,
                      this.currentUser.userIp,
                    );
                    assets.push(obj);
                  })();
                }
                resolve(assets);
              }
            } else {
              resolve(result);
            }
          })
          .catch((err) => reject(err));
      };
      return new Promise(getSlides);
    },
    getSectionDeatils(value) {
      let details = '';
      if (this.sections[value]?.sectionName) {
        details = `${this.sections[value]?.sectionName}_`;
        this.sections[value].slides.forEach((slide) => {
          details += slide.outline;
          details += '_';
        });
      }
      return details;
    },
    handleSectionEnd(e) {
      if (this.$route.path === '/home/build' && this.$route.query?.id) {
        trackPrezentationEvents.prezentationsSectionPositionChanged(
          this.currentUser,
          this.storylineData,
          {
            [TD_SECTION_NAME]: this.sections[e.newIndex]?.sectionName,
            [TD_PLACEMENT]: TD_STEP2,
          },
        );
      } else {
        const section4 = this.getSectionDeatils(3);
        const section5 = this.getSectionDeatils(4);
        trackBuildEvents.buildSectionPositionChanged(this.currentUser, {
          ...this.getPrezDetails(),
          [TD_SECTION_NAME]: this.sections[e.newIndex]?.sectionName,
          [TD_SECTION_4]: section4,
          [TD_PLACEMENT]: 'Step 2',
          [TD_SECTION_5]: section5,
        });
      }
    },
    onDragEnd() {
      this.hoverSectionIndex = null;
    },
    handleCurrentIdeaGallery(slide) {
      this.currentSlideIndex = slide;
    },
    handleIdeaGalleryDragEnd() {
      const ideea = this.currentSlideIndex;
      const section4 = this.getSectionDeatils(3);
      const section5 = this.getSectionDeatils(4);
      this.currentSlideIndex = null;
      if (this.$route.query.origin) {
        trackPrezentationEvents.prezentationsIdeaDragged(
          this.currentUser,
          this.storylineData,
          {
            [TD_IDEA]: ideea.outline,
            [TD_PLACEMENT]: TD_STEP2,
            [TD_SLIDE_SOURCE]: 'prezent',
          },
        );
      } else {
        trackBuildEvents.buildIdeaDragged(this.currentUser, {
          [TD_IDEA]: ideea.outline,
          ...this.getPrezDetails(),
          [TD_SECTION_4]: section4,
          [TD_SECTION_5]: section5,
        });
      }
    },
    skipEmphasis(category) {
      const emphasisAllowed = [
        'divider',
        'title',
        'congratulations',
        'thank_you',
        'call_to_action',
      ];
      if (emphasisAllowed.indexOf(category.toLowerCase()) > -1) return false;
      return true;
    },
    getRandom(min, max) {
      // min and max included
      return Math.floor(Math.random() * (max - min + 1) + min);
    },
    getChoice(fingerprint) {
      let choice = { visual: 6.0, data: 5.0 };
      if (!fingerprint) return choice;
      switch (fingerprint.toLowerCase()) {
        case 'director':
          choice = { visual: 6.0, data: 5.0 };
          return choice;

        case 'performer':
          choice = { visual: 6.0, data: 0.0 };
          return choice;

        case 'navigator':
          choice = { visual: 6.0, data: 0.0 };
          return choice;

        case 'surgeon':
          choice = { visual: 0.0, data: 5.0 };
          return choice;

        case 'architect':
          choice = { visual: 0.0, data: 5.0 };
          return choice;

        case 'scholar':
          choice = { visual: 6.0, data: 5.0 };
          return choice;

        case 'scientist':
          choice = { visual: 0.0, data: 5.0 };
          return choice;

        default:
          return choice;
      }
    },
    getAssetIDForIdeas(i) {
      const section = this.sections[i];

      for (let j = 0; j < section.slides.length; j++) {
        const item = section.slides[j];
        if (!item.assetId) {
          const payload = {};
          payload.category = item.category;
          payload.username = this.currentUser.user.cognitoID; // "pz_rath_admin"),
          payload.limit = 6;
          payload.skip = 0;
          payload.choice = this.getChoice(this.fingerprint);
          this.getRecommendedSlides(payload)
            .then((slides) => {
              let obj = {};
              if (slides && slides.length > 0) {
                obj = slides[this.getRandom(0, Math.min(slides.length, 5))];
              }

              this.sections[i].slides[j] = {
                ...item,
                uniqueID: obj.asset?.unique_id,
                assetId: obj.asset?.landscape,
                filename: obj.asset?.filename,
              };
            })
            .catch((err) => console.log(err));
        }
      }
    },
    addIdea(e, index) {
      if (this.renderFor === 'build prezentation') {
        this.getAssetIDForIdeas(index);
      }
    },
    addSection(e) {
      if (this.renderFor === 'build prezentation') {
        this.getAssetIDForIdeas(
          e.newIndex === this.sections.length ? e.newIndex - 1 : e.newIndex,
        );
      }
    },
    handleSectionMouseover(i) {
      if (this.expandedList.indexOf(i) === -1) {
        this.hoverExpandIndex = i;
      }
    },
    handleGalleryPanelToggle() {
      if (!this.isGalleryOpen) {
        this.isGalleryOpen = true;
        if (this.leftNavExpanded) {
          EventBus.$emit('MINIMIZE_LEFTNAV');
          EventBus.$on('LEFTNAV_MINIMIZED', () => {
            this.leftNavExpanded = false;
          });
        }
        this.setIsStorylineGalleryOpen(true);
      } else if (this.leftNavExpanded) {
        EventBus.$emit('MINIMIZE_LEFTNAV');
        EventBus.$on('LEFTNAV_MINIMIZED', () => {
          this.leftNavExpanded = false;
          this.isGalleryOpen = true;
        });
      }
      this.setIsStorylineGalleryOpen(true);
    },
    handleSectionMouseDown(i) {
      if (this.expandedList.indexOf(i) === -1) {
        this.hoverExpandIndex = i;
      }
    },
    // handleSectionMouseleave(i) {
    //   if (this.expandedList.indexOf(i) === -1) {
    //     this.hoverExpandIndex = null;
    //   }
    // },
    handleSectionDragStart() {
      this.isSectionDraging = true;
    },
    handleSectionDragEnd() {
      this.isSectionDraging = false;
      if (this.$route.path === '/home/build' && this.$route.query?.id) {
        trackPrezentationEvents.prezentationsSectionDragged(
          this.currentUser,
          this.storylineData,
          {
            // [TD_SECTION_NAME]: this.selectedSlide?.sectionName,
            [TD_PLACEMENT]: TD_STEP2,
          },
        );
      } else {
        trackBuildEvents.buildSectionDragged(this.currentUser, {
          ...this.getPrezDetails(),
          [TD_PLACEMENT]: 'Step 2',
        });
      }
    },
    serializeParam(params) {
      return Object.entries({ ...params })
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
    },
    handleShowMoreInfo() {
      this.showMoreInfo = !this.showMoreInfo;
    },
    handleAddSection(newName) {
      if (newName) {
        this.sections.push({ sectionName: newName, slides: [] });
        const section4 = this.getSectionDeatils(3);
        const section5 = this.getSectionDeatils(4);
        if (this.$route.path === '/home/build' && this.$route.query?.id) {
          // trackPrezentationEvents.prezentationsCustomSectionAddition(
          //   this.currentUser,
          //   this.storylineData,
          //   {
          //     [TD_SECTION_NAME]: newName,
          //     [TD_PLACEMENT]: TD_STEP2,
          //   },
          // );
        } else {
          trackBuildEvents.buildCustomSectionAddition(this.currentUser, {
            ...this.getPrezDetails(),
            [TD_SECTION_NAME]: newName,
            [TD_SECTION_4]: section4,
            [TD_SECTION_5]: section5,
            [TD_PLACEMENT]: 'Step 2',
          });
        }
      }
    },
    handleDeleteSection(section, sectionIndex) {
      this.sections.splice(sectionIndex, 1);
      if (
        !this.sectionGallery.find(
          (item) => item.sectionName === section.sectionName,
        )
      ) {
        this.sectionGallery.push(section);
      }
      if (this.$route.path === '/home/build' && this.$route.query?.id) {
        trackPrezentationEvents.prezentationsSectionDeleted(
          this.currentUser,
          this.storylineData,
          {
            [TD_SECTION_NAME]: section.sectionName,
            [TD_PLACEMENT]: TD_STEP2,
          },
        );
      } else if (this.$route.path === '/home/build') {
        const section4 = this.getSectionDeatils(3);
        const section5 = this.getSectionDeatils(4);
        trackBuildEvents.buildSectionDeleted(this.currentUser, {
          ...this.getPrezDetails(),
          [TD_SECTION_NAME]: section.sectionName,
          [TD_SECTION_4]: section4,
          [TD_SECTION_5]: section5,
          [TD_PLACEMENT]: 'Step 2',
        });
      }
    },
    onSectionNameChange(sectionIndex, newValue) {
      this.sections[sectionIndex].sectionName = newValue;
      if (this.$route.path === '/home/build' && this.$route.query?.id) {
        const prezData = getPrezData(this.storylineData);
        const section4 = this.getSectionDeatils(3);
        const section5 = this.getSectionDeatils(4);
        trackPrezentationEvents.prezentationsSectionRenamed(this.currentUser, {
          ...prezData,
          [TD_SECTION_NAME]: newValue,
          [TD_SECTION_4]: section4,
          [TD_SECTION_5]: section5,
        });
      } else {
        const section4 = this.getSectionDeatils(3);
        const section5 = this.getSectionDeatils(4);
        trackBuildEvents.buildSectionRenamed(this.currentUser, {
          ...this.getPrezDetails(this.storylineData),
          [TD_SECTION_NAME]: newValue,
          [TD_SECTION_4]: section4,
          [TD_SECTION_5]: section5,
          [TD_PLACEMENT]: 'Step 2',
        });
      }
    },
    handleDuplicateSlide(slide, slideIndex, sectionIndex) {
      this.sections[sectionIndex]?.slides.splice(slideIndex, 0, slide);
      const section4 = this.getSectionDeatils(3);
      const section5 = this.getSectionDeatils(4);
      if (this.$route.path === '/home/build' && this.$route.query?.id) {
        trackPrezentationEvents.prezentationsIdeaDuplicate(
          this.currentUser,
          this.storylineData,
          {
            [TD_IDEA]: slide?.outline,
            [TD_PLACEMENT]: TD_STEP2,
          },
        );
      } else {
        trackBuildEvents.buildIdeaDuplicate(this.currentUser, {
          ...this.getPrezDetails(this.storylineData),
          [TD_IDEA]: slide?.outline,
          [TD_SECTION_4]: section4,
          [TD_PLACEMENT]: 'Step 2',
          [TD_SECTION_5]: section5,
        });
      }
    },
    handleDeleteSlide(slide, slideIndex, sectionIndex) {
      this.sections[sectionIndex]?.slides.splice(slideIndex, 1);
      if (!this.ideaGallery.find((item) => item.outline === slide.outline)) {
        this.ideaGallery.push(slide);
      }
      const section4 = this.getSectionDeatils(3);
      const section5 = this.getSectionDeatils(4);
      if (this.$route.path === '/home/build' && this.$route.query?.id) {
        trackPrezentationEvents.prezentationsIdeaDelete(
          this.currentUser,
          this.storylineData,
          {
            [TD_IDEA]: slide?.outline,
            [TD_PLACEMENT]: TD_STEP2,
          },
        );
      } else {
        trackBuildEvents.buildIdeaDelete(this.currentUser, {
          ...this.getPrezDetails(),
          [TD_IDEA]: slide?.outline,
          [TD_SECTION_4]: section4,
          [TD_SECTION_5]: section5,
          [TD_PLACEMENT]: 'Step 2',
        });
      }
    },
    handleSectionSelect(value) {
      if (value && typeof value !== 'string') {
        logSearchQuery({
          type: 'Section',
          searchTerm: value.sectionName,
          searchTermResult: true,
        })
          .then()
          .catch((err) => console.log(err));
        if (value && value.slides && value.slides.length)
          value.slides = value.slides.filter(
            (payload) =>
              !this.hiddenIdeas.includes(payload.category.toLowerCase()),
          );
        this.sectionGallery.unshift(value);
        if (this.$route.path === '/home/build' && this.$route.query?.id) {
          const section4 = this.getSectionDeatils(3);
          const section5 = this.getSectionDeatils(4);
          const prezData = getPrezData(this.storylineData);
          trackSearchEvents.searchDropdownPrezentationIdeaGallerySection(
            this.currentUser,
            {
              ...prezData,
              [TD_PREZNAME]: this.presentationName,
              [TD_QUERY]: value.sectionName,
              [TD_SECTION_NAME]: value.sectionName,
              [TD_SECTION_4]: section4,
              [TD_SECTION_5]: section5,
            },
          );
        } else {
          const section4 = this.getSectionDeatils(3);
          const section5 = this.getSectionDeatils(4);
          trackSearchEvents.searchDropdownBuildIdeaGallerySection(
            this.currentUser,
            {
              [TD_PREZNAME]: this.presentationName,
              [TD_QUERY]: value.sectionName,
              [TD_SECTION_NAME]: value.sectionName,
              [TD_SECTION_4]: section4,
              [TD_SECTION_5]: section5,
            },
          );
        }
      }
    },
    // eslint-disable-next-line func-names
    searchSections: debounce(function (e) {
      let text = null;
      if (typeof e === 'string') {
        text = e;
        this.searchSection = e;
      } else {
        text = e.target.value;
        this.searchSection = e.target.value;
      }

      if (text === undefined || text === null || text.length === 0) {
        this.filteredSectionGallery = [];
        this.isLoadingSectionSuggestions = false;
        this.searchSection = '';
        return;
      }

      if (this.isLoadingSectionSuggestions) return;

      if (text && text.length > 1) {
        this.isLoadingSectionSuggestions = true;

        getSectionGalery(text)
          .then((res) => {
            const list = [];
            if (res?.data?.items) {
              this.sectionGallery.forEach((item) => {
                list.push(item.sectionName.toLowerCase());
              });
              this.sections.forEach((section) => {
                list.push(section.sectionName.toLowerCase());
              });
            }
            this.filteredSectionGallery = res.data.items.filter((item) => {
              if (list.indexOf(item.sectionName.toLowerCase()) < 0) {
                list.push(item.sectionName.toLowerCase());
                return true;
              }
              return false;
            });
            this.filteredSectionGallery = this.filteredSectionGallery.filter(
              (ele) => {
                if (
                  ele &&
                  ele.slides.length === 1 &&
                  this.hiddenIdeas.includes(
                    ele.slides[0].category.toLowerCase(),
                  )
                ) {
                  return false;
                }
                return true;
              },
            );
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.isLoadingSectionSuggestions = false;
            if (this.$route.path === '/home/build' && this.$route.query?.id) {
              const section4 = this.getSectionDeatils(3);
              const section5 = this.getSectionDeatils(4);
              const prezData = getPrezData(this.storylineData);
              trackSearchEvents.searchPrezentationIdeaGallerySection(
                this.currentUser,
                {
                  ...prezData,
                  [TD_PREZNAME]: this.presentationName,
                  [TD_QUERY]: text,
                  [TD_SECTION_4]: section4,
                  [TD_SECTION_5]: section5,
                  [TD_NORESULTSFOUND]:
                    this.filteredSectionGallery.length > 0 ? '0' : '1',
                },
              );
            } else {
              const section4 = this.getSectionDeatils(3);
              const section5 = this.getSectionDeatils(4);
              trackSearchEvents.searchBuildIdeaGallerySection(
                this.currentUser,
                {
                  [TD_PREZNAME]: this.presentationName,
                  [TD_QUERY]: text,
                  [TD_SECTION_4]: section4,
                  [TD_SECTION_5]: section5,
                  [TD_NORESULTSFOUND]:
                    this.filteredSectionGallery.length > 0 ? '0' : '1',
                },
              );
            }
          });
      }
    }, 500),
    handleIdeaSelect(value) {
      if (value && typeof value !== 'string') {
        this.ideaGallery.unshift(value);
        this.$emit('ideaSelected', value);
      }
    },

    // eslint-disable-next-line func-names
    searchIdeas: debounce(function (e) {
      let text = null;
      if (typeof e === 'string') {
        text = e;
        this.searchIdea = e;
      } else {
        text = e.target.value;
        this.searchIdea = e.target.value;
      }

      if (text === undefined || text === null || text.length === 0) {
        this.filteredIdeaGallery = [];
        this.isLoadingIdeaSuggestions = false;
        this.searchIdea = '';
        return;
      }

      if (this.isLoadingIdeaSuggestions) return;

      if (text && text.length > 1) {
        this.isLoadingIdeaSuggestions = true;
        const params = {
          username: this.currentUser.user.cognitoID, // "pz_rath_admin",
          query: text,
          companies: `${this.currentUser.company.name.toLowerCase()},generic`,
          is_know: 'no',
          limit: 10,
          type: 'keywords',
          theme: this.currentUser?.theme?.code || 'default',
        };
        const serialized = this.serializeParam(params);

        GraphQLService.requestWithToken(
          graphqlOperation(performAutoComplete, {
            parameters: serialized,
          }),
        )
          .then((res) => {
            const result = JSON.parse(res.data.performAutoComplete);
            const matchedItems = [];

            if (result?.statusCode === 200 && result?.body?.payloads) {
              result.body.payloads.forEach((item) => {
                if (
                  item.keytype === 'K_CATEGORY' &&
                  item.slide_type !== 'uploaded'
                ) {
                  matchedItems.push({
                    outline: item.display_name,
                    category: item.name,
                  });
                }
              });
              this.filteredIdeaGallery = [...matchedItems];
            }
            if (this.$route.path === '/home/build' && this.$route.query?.id) {
              const prezData = getPrezData(this.storylineData);
              const section4 = this.getSectionDeatils(3);
              const section5 = this.getSectionDeatils(4);
              trackPrezentationEvents.prezentationIdeaSearchGallery(
                this.currentUser,
                {
                  ...prezData,
                  [TD_QUERY]: text,
                  [TD_SECTION_4]: section4,
                  [TD_SECTION_5]: section5,
                  [TD_NORESULTSFOUND]: matchedItems.length ? '0' : '1',
                },
              );
            } else {
              const section4 = this.getSectionDeatils(3);
              const section5 = this.getSectionDeatils(4);
              trackBuildEvents.buildSearchIdeaGallery(this.currentUser, {
                ...this.getPrezDetails(),
                [TD_QUERY]: text,
                [TD_SECTION_4]: section4,
                [TD_SECTION_5]: section5,
                [TD_NORESULTSFOUND]: matchedItems.length ? '0' : '1',
              });
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.isLoadingIdeaSuggestions = false;
          });
      }
    }, 500),

    returnFinalData() {
      return this.sections.filter((section) => section.slides.length > 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.storyline-step {
  display: flex;
  margin: 0px 2rem;
  margin-bottom: 140px;
  margin-right: 0;

  .drag-icon {
    display: flex !important;
    cursor: move;
  }

  .header-context {
    .define-context-description,
    .define-context-sub-header,
    .define-context-subheader,
    .define-context-header {
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
    }
    .define-context-header {
      font-size: 28px;
      font-weight: bold;
      margin-bottom: 5px;
    }

    .define-context-subheader {
      font-family: 'Lato' !important;
      font-size: 1.2vw;
      margin: 20px 0;
    }
    .define-context-sub-header {
      font-size: 24px;
      margin-bottom: 10px;
    }
    .define-context-description {
      font-size: 20px;
      margin-bottom: 25px;
    }
  }

  .more-info,
  .expand-all-btn {
    cursor: pointer;
    color: #21a7e0;
    .v-icon {
      color: #21a7e0;
    }
  }

  .gallery-header {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.01em;
    color: #000000;
  }
  .define-context-header {
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
  }
  .define-context-header {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .sections-row {
    min-height: 350px;

    // .sections {
    //   // justify-content: center;
    // }
  }

  .empty-idea {
    height: 43px;
    background-color: lightgray;
    display: none;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px 10px 10px;
    background-color: #ededed;
    border: 1px solid #ededed;
    border-radius: 8px;
    margin-bottom: 15px;
    width: 100% !important;
    color: #888;
    position: absolute;
    bottom: -75px;
  }
  .dragArea {
    position: relative;
    padding-bottom: 50px;
  }

  .dragable.hover {
    .empty-idea {
      display: flex;
    }
  }

  ::v-deep .v-slide-group__prev,
  ::v-deep .v-slide-group__next {
    display: none !important;
  }

  .v-tab {
    text-transform: capitalize;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
    text-align: center;
  }

  .v-tab--active {
    color: #212121;
  }

  .search-input {
    margin: 12px 0;
    border-radius: 43px;

    ::v-deep .v-label,
    ::v-deep input {
      font-size: 14px;
    }
  }

  .menu-item-text {
    background-color: #fff;
    cursor: pointer;
    font-size: 14px;
  }

  ::v-deep .v-input__slot {
    padding: 0 20px;
    overflow: hidden;
  }

  .section-col {
    padding: 0 1rem;
    // margin: 0 auto;
    // max-height: 500px;
    max-width: 430px;
  }

  .gallery-container {
    width: 100%;
    .section-col {
      margin: 0 auto;
    }

    .gallery-close-btn {
      display: flex;
      justify-content: flex-end;
      transform: translateY(-31px);
    }

    ::v-deep .v-input__control {
      min-height: 40px !important;
    }

    ::v-deep .v-input__slot {
      align-items: center;
      background-color: rgb(255, 255, 255);
      border: 1px solid transparent;
      border-radius: 24px;
      box-shadow: rgb(64 60 67 / 16%) 0px 2px 5px 1px !important;
    }

    ::v-deep .v-tab {
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
    }

    .row {
      width: 100%;
    }
  }

  .gallery-section {
    display: flex;
    flex-direction: column;

    .idea-chip {
      cursor: unset !important;
    }

    .badge {
      margin-top: -4px;
      justify-content: flex-end;
    }

    ::v-deep .badge .v-badge__badge {
      color: #4d5358 !important;
      margin-top: 5px;
    }

    // .v-expansion-panels {
    //   max-width: 225px;
    // }

    ::v-deep .v-expansion-panel:not(:first-child)::after {
      border-top: none;
    }
  }

  .gallery-idea {
    width: 95%;
    .v-expansion-panels,
    .idea-chip {
      width: 48% !important;
    }
  }

  .section-header-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: black;
    display: flex;
  }

  .storyline-container {
    width: 70%;
  }

  .sortable-chosen {
    cursor: move !important;
  }

  .section-limit {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.02em;
    padding-top: 20px;
    color: #b00020;
  }

  .sections {
    .section-col:first-of-type {
      padding-left: 0px !important;
    }
  }

  .ghost-list {
    opacity: 0.9;
    background: #c8ebfb;
  }

  // .v-expansion-panel--active:not(:first-child),
  // .v-expansion-panel--active + .v-expansion-panel {
  //   margin: 0;
  // }

  .v-expansion-panel {
    max-width: 225px;

    &::before {
      box-shadow: none;
    }
    .v-expansion-panel-header {
      padding: 0px;
    }

    ::v-deep .v-expansion-panel-content__wrap {
      padding: 0px;
    }

    .chip-title {
      display: flex;
      align-items: center;
      width: 100% !important;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 20px;
      .v-icon:nth-child(2) {
        margin-left: -15px;
      }

      .v-icon {
        color: #a2a9b0;
      }

      .title-text {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        color: black;
        padding: 0 5px;
        white-space: normal;
        font-size: 16px;
      }
      .title-text::after {
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 0 5px;
        background: white;
      }
    }
  }
  ::v-deep .v-select.v-select--is-menu-active .v-input__icon--append .v-icon {
    transform: none !important;
  }

  .v-window {
    overflow: initial;
  }

  .expand-all-btn {
    text-align: start;
    margin-left: 8px;
    font-size: 15px;
  }

  .sections-drag {
    width: 100%;
    .v-expansion-panel {
      width: 100%;
      max-width: 100%;
    }
  }

  .panel {
    position: absolute !important;
    right: 0;
    top: 0px;
    bottom: 0;
    width: 27%;
    border-left: 2px solid #e9e2e2;
    padding: 0 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow-y: scroll;
    overflow-x: hidden;

    &.banner-active {
      // top: 48px;
    }

    .list-group,
    ::v-deep .v-expansion-panel-content__wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .idea-chip {
        width: 48% !important;
      }
    }
  }

  .detail-expand-btn {
    position: absolute;
    min-width: 24px !important;
    height: 24px !important;
    background-color: #fff !important;
    border-radius: 0 !important;
    padding: 0 !important;
    z-index: 1;
    width: 104px;
    transform: translate(-105px, 4px);

    .gallery-icon {
      transform: translateX(5px);
      margin-top: 2px;
    }

    .gallery-text {
      padding-right: 10px;
      font-family: Lato;
      font-size: 18px;
      font-weight: 600;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      text-transform: none;
      color: #21a7e0;
    }

    &:hover {
      background-color: #fff;
    }
  }
}
</style>
