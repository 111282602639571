<template>
  <div class="processing-payment-wrapper">
    <v-btn class="close-btn" icon @click="handleClose">
      <v-icon dark v-text="`mdi-close`" />
    </v-btn>
    <div class="success-payment">
      <div class="success-payment-img">
        <img src="/assets/img/profile/payment-success.svg" alt="" />
      </div>
      <div class="success-payment-title">
        {{ $t('profile.editPaymentDetail.success') }}
      </div>
      <div class="success-payment-desc">
        {{ $t('profile.editPaymentDetail.paymentProcessed') }}<br />{{
          $t('profile.editPaymentDetail.subscriptionActive')
        }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SuccessPaymentDetail',
  data() {
    return {};
  },
  methods: {
    handleClose() {
      this.$modal.hide('SuccessPaymentDetail');
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.processing-payment-wrapper {
  width: 100%;
  height: 100%;

  .close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;
  }

  .success-payment {
    width: 100%;
    height: 100%;

    .success-payment-img {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        margin: auto;
        margin: 65px auto 45px auto;
      }
    }

    .success-payment-title {
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      margin-bottom: 20px;
    }

    .success-payment-desc {
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
    }
  }
}
</style>
