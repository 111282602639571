<template>
  <div class="course-details-wrapper" v-if="!loading">
    <v-btn class="back-btn" @click="goToSearchLandingPage()">
      <v-icon class="icon" color="primary">mdi-chevron-left</v-icon>
      {{
        $t(previousRoute === 'search' ? 'slides.backToSearch' : 'common.back')
      }}
    </v-btn>
    <div class="course-title">
      <span> {{ courseDetails.display_name }}</span>
      <span>
        <v-tooltip
          top
          max-width="200"
          content-class="tooltip-content"
          fixed
          open-delay="500"
          close-delay="300"
          :disabled="courseDetails.status == 100"
        >
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-btn
                class="certificate-btn"
                :disabled="courseDetails.status < 100"
                color="primary"
                @click="openCertificateModal"
                :loading="certificateLoading"
              >
                {{ $t('learn.course.viewCertificate') }}
              </v-btn>
            </span>
          </template>
          <span>{{ $t('learn.course.completeCourse') }}</span>
        </v-tooltip>
      </span>
    </div>
    <div class="course-duration-section">
      <div class="course-duration-section__duration">
        <v-icon> mdi-clock-time-eight-outline </v-icon>
        {{ courseDetails.duration_seconds | secondsToHoursAndMinutes }}
      </div>
    </div>
    <div class="course-modules-section" v-if="courseDetails.modules">
      <CourseModules
        :modules="courseDetails.modules"
        :activeModule="getActiveModule"
        :activeAsset="activeAsset"
        :courseSlug="courseDetails.name"
        @assetStatusUpdated="(e) => getCourseDetails(e)"
        :courseDetails="courseDetails"
        @removeActiveAsset="removeActiveAsset()"
      />
    </div>
  </div>
  <div v-else>
    <v-row
      align="center"
      justify="center"
      v-if="loading"
      class="mt-9"
      style="height: 800px"
    >
      <LoadSpinner
        :id="'spinner'"
        :text="$t('learn.movieDialog.loading')"
        :size="'40'"
        :bgcolor="'lightgray'"
        :speed="'0'"
        :color="'#21a7e0'"
        class="loadspinner"
      />
    </v-row>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import CourseModules from './CourseModules.vue';
import LearnApi from '../../../API/learn-api.js';
import CourseCertificateModal from './CourseCertificateModal.vue';
import EmailSentModal from './EmailSentModal.vue';
import LoadSpinner from '../../common/LoadSpinner.vue';
import NoviceBadgeDialog from './Novice/NoviceBadgeDialog.vue';
import { MatomoAnalyticsHandler } from '../../common/Analytics/MatomoAnalyticsHandler';
import { logSearchQuery } from '../../../utils/api-helper';
import { trackLearnEvents } from '../../common/Analytics/LearnEvents';

export default {
  name: 'CourseDetail',
  components: { CourseModules, LoadSpinner },
  data() {
    return {
      previousRoute: '',
      courseDetails: {},
      certificateDetails: {},
      courseMetaInfo: {},
      loading: false,
      certificateLoading: false,
      defaultModuleId: '',
      activeAsset: null,
    };
  },
  async mounted() {
    this.previousRoute = this.$router.app.previousRoute.name;
    const logPayload = {
      type: 'Learn',
      searchTerm: '',
      searchTermResult: false,
    };
    if (this.$route.query) {
      logPayload.searchTerm =
        this.$route.query.display_name?.toLowerCase() || '';
    }
    await this.getCourseDetails();
    if (!this.$route.query.assetid) {
      MatomoAnalyticsHandler.learnCourseViewedExpand(
        this.courseDetails,
        this.currentUser,
      );
    }
    if (this.$route.query.certificateModal) {
      this.openCertificateModal();
    }
    if (this.$route.query.showBadge) {
      this.$modal.show(
        NoviceBadgeDialog,
        {
          goToInitial: this.goToInitial,
          remainingBadges: this.getRemainingBadgesCount(),
        },
        {
          name: 'NoviceBadgeDialog',
          width: '353px',
          height: '394px',
        },
      );
    }
    if (this.$route.params.moduleId) {
      this.defaultModuleId = this.$route.params.moduleId;
    }
    if (this.$route.query.assetid) {
      this.activeAsset = this.getAsset(this.$route.query.assetid);
      logPayload.searchTermResult = !!this.activeAsset;
    }
    if (this.$route.query.log_search) {
      logSearchQuery(logPayload)
        .then()
        .catch((err) => console.log(err));
    }
  },
  watch: {
    async $route(to, from) {
      const logPayload = {
        type: 'Learn',
        searchTerm: '',
        searchTermResult: false,
      };
      if (this.$route.query) {
        logPayload.searchTerm =
          this.$route.query.display_name?.toLowerCase() || '';
      }
      if (this.isCourseChanged(to, from)) {
        await this.getCourseDetails();
      }
      if (this.$route.query.assetid) {
        this.activeAsset = this.getAsset(this.$route.query.assetid);
        logPayload.searchTermResult = !!this.activeAsset;
      } else {
        this.activeAsset = null;
      }
      if (this.$route.query.log_search) {
        logSearchQuery(logPayload)
          .then()
          .catch((err) => console.log(err));
      }
    },
  },
  beforeMount() {
    console.log(this.$route.params.id);
    console.log(this.$route.query.assetid);
  },
  computed: {
    ...mapState('users', ['currentUser']),
    ...mapState('learndata', ['courseInfoForMatotmo']),
    getActiveModule() {
      if (!this.courseDetails) {
        return [0];
      }
      const lastSeenVideo = JSON.parse(localStorage.getItem('lastSeenModule'));
      if (lastSeenVideo !== null) {
        const lastSeenModuleIndex = this.courseDetails.modules.findIndex(
          (module) =>
            module.assets.find((asset) => asset.id === lastSeenVideo.id),
        );
        if (lastSeenModuleIndex > 0) {
          return [lastSeenModuleIndex];
        }
      }
      if (this.defaultModuleId) {
        return [
          this.courseDetails.modules.findIndex(
            (module) => module.id === this.defaultModuleId,
          ),
        ];
      }
      const activeModuleIndex = this.courseDetails.modules.findIndex(
        (module) => module.status !== 100,
      );
      return [activeModuleIndex < 0 ? 0 : activeModuleIndex];
    },
  },
  methods: {
    ...mapActions('learndata', ['setCourseInfoForMatomo']),
    async removeActiveAsset() {
      this.activeAsset = null;
    },

    async getCourseDetails(completionAction) {
      this.loading = true;
      await LearnApi.methods.getCourseInfo(this.$route.params.id).then(
        (resp) => {
          console.log(resp);
          // eslint-disable-next-line prefer-destructuring
          this.courseDetails = resp[0];
          this.setCourseInfoForMatomo({
            learnCategoryName: this.courseDetails.domain_display_name,
            learnCourseName: this.courseDetails.display_name,
            modules: this.courseDetails?.modules,
          });
          this.loading = false;
          const moduleFound = this.courseDetails.modules.find(
            (mod) =>
              mod.display_name === this.courseInfoForMatotmo.learnModuleName,
          );
          if (
            completionAction &&
            completionAction.action &&
            completionAction.action === 'completed'
          ) {
            MatomoAnalyticsHandler.learnCourseModuleProgress(
              this.courseInfoForMatotmo,
              this.currentUser,
              `${moduleFound !== undefined ? moduleFound.status : 0}%`,
            );
          }
        },
        (error) => {
          this.loading = false;
          console.log(error.response);
          if (error.response && error.response.status === 403) {
            this.$router.push({
              name: 'learn.landing',
              query: { courseid: this.$route.params.id },
            });
          } else if (error.response && error.response.status === 400) {
            this.$router.push({
              name: 'learn.landing',
            });
          }
        },
      );
    },

    async openCertificateModal() {
      MatomoAnalyticsHandler.trackViewCertificate(
        this.currentUser.user,
        this.courseDetails.display_name,
      );
      trackLearnEvents.learnCourseViewCertButton(
        this.currentUser,
        this.courseInfoForMatotmo,
      );
      this.$modal.show(
        CourseCertificateModal,
        {
          courseDetails: this.courseDetails,
          emailSuccess: this.openEmailSentModal,
          functionalScreen: 'courseDetailPage',
          setModuleIdInCurrentCourse: this.setDefaultModuleId,
        },
        {
          name: 'CourseCertificateModal',
          width: '1172px',
          height: '75%',
          styles: { borderRadius: '20px' },
        },
      );
    },

    setDefaultModuleId(mId) {
      this.defaultModuleId = mId;
    },

    async openEmailSentModal() {
      this.$modal.show(
        EmailSentModal,
        {
          courseDetails: this.courseDetails,
        },
        {
          name: 'EmailSentModal',
          width: '500px',
          height: '500px',
          styles: { borderRadius: '20px' },
        },
      );
    },

    goToSearchLandingPage() {
      this.$router.back();
    },

    goToInitial() {
      this.$modal.hide('NoviceBadgeDialog');
      const remainingModules = this.courseDetails.modules.filter(
        (module) => module.status !== 100,
      ).length;

      if (remainingModules === 0) {
        this.openCertificateModal();
      }
    },

    getRemainingBadgesCount() {
      return this.courseDetails.modules.filter(
        (module) => module.status !== 100,
      ).length;
    },

    getAsset(assetID) {
      if (this.courseDetails && this.courseDetails.modules) {
        for (let midx = 0; midx < this.courseDetails.modules.length; midx++) {
          if (this.courseDetails.modules[midx].assets) {
            for (
              let aidx = 0;
              aidx < this.courseDetails.modules[midx].assets.length;
              aidx++
            ) {
              if (
                this.courseDetails.modules[midx].assets[aidx].id === assetID
              ) {
                this.defaultModuleId = this.courseDetails.modules[midx].id;
                const assetObj = this.courseDetails.modules[midx].assets[aidx];
                assetObj.moduleId = this.courseDetails.modules[midx].id;
                return {
                  ...assetObj,
                  learnModuleName:
                    this.courseDetails.modules[midx].display_name,
                };
              }
            }
          }
        }
      }
      return null;
    },

    isCourseChanged(toRoute, fromRoute) {
      if (
        toRoute.name === 'course.detail' &&
        fromRoute.name === 'course.detail' &&
        toRoute.params.id !== fromRoute.params.id
      ) {
        return true;
      }
      return false;
    },
  },
  beforeDestroy() {
    localStorage.removeItem('lastSeenModule');
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/variables.scss';

.course-details-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow: hidden;
  padding: 16px 35px;
  position: relative;
  width: 100%;

  .back-btn {
    border: none;
    text-transform: none;
    background-color: #ffffff;
    box-shadow: none;
    letter-spacing: normal;
    color: #21a7e0;
    padding: 0;
    margin: 0px 0px 0px -8px;
  }

  &__back-btn {
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    text-transform: unset;
  }
}
.course-title {
  align-content: center;
  color: #000;
  display: flex;
  font-family: Roboto;
  font-size: 30px;
  font-stretch: normal;
  font-style: norma1;
  font-weight: 500 !important;
  height: 35px;
  justify-content: space-between;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
  width: 100%;

  .certificate-btn {
    width: 220px;
    height: 40px;
    // margin: 18px 73.5px 8.4px 79.5px;
    padding: 11px 42.2px 11px 43px;
    border-radius: 25px;
    background-color: #21a7e0;
    text-transform: unset;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }
}
.course-duration-section {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 30px;

  &__duration {
    color: $gray-dark-login-text;
    font-size: 15px;
    font-weight: bold;
    text-align: left;
  }
}
.course-modules-section {
  width: 100%;
}
</style>
