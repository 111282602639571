<template>
  <div class="replace-img-container" v-if="!isLoading">
    <div v-if="imagesList.length">
      <div class="replace-visual-verbose">
        {{ $t('prezentation.selectImageText') }}
      </div>
      <div
        :class="`select-image my-1 ${
          isConvertingInProgress ? 'item-disabled' : ''
        } `"
      >
        <v-slide-group show-arrows="always">
          <v-slide-item
            v-for="item in imagesList"
            :key="item.replace_ref"
            v-slot="{ toggle }"
            :class="`${
              item.imageIndex === selectedImage.imageIndex ? 'selected' : ''
            }`"
          >
            <div>
              <v-img
                v-if="!item.invalid"
                class="select-image__item ma-2"
                max-height="80"
                max-width="100"
                height="80"
                contain
                :src="item.imgUrl"
                @click="onImageSelected(item, toggle)"
                lazy-src="/assets/img/slides/placeholder-slide.svg"
                :disabled="isConvertingInProgress"
              ></v-img>
            </div>
          </v-slide-item>
        </v-slide-group>
      </div>
      <ImageLibrary
        @imageSelected="onReplaceImage($event)"
        :currentSelectedSlideImageData="selectedImage"
        :isConvertingInProgress="isConvertingInProgress"
        :key="imageLibraryComponentKey"
        @onImageSearch="onImageSearch"
      />
    </div>
    <div v-else class="no-image">
      <img
        :src="'/assets/img/generate/slide-with-no-visuals.svg'"
        alt="image"
        contain
      />
      <div class="desc">
        <div>{{ $t('generate.currentSlideHaveNoVisuals') }}</div>
        <div v-if="isShellTypeSlide">
          {{ $t('generate.toAddVisualUseDifferentLayout') }}
        </div>
      </div>
      <v-btn
        elevation="2"
        rounded
        color="primary"
        class="primary-btn"
        @click="changeLayout"
        v-if="isShellTypeSlide"
      >
        {{ $t('generate.changeLayout') }}
      </v-btn>
    </div>
  </div>
  <div v-else class="spinner text-center loading-spinner_library">
    <v-progress-circular
      :size="50"
      :width="2"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { mapState, mapActions, mapGetters } from 'vuex';
import { getFileURLDownload } from '@/utils/calyrex-test';
// import { getFileURL } from '@/utils/calyrex';
import {
  // convertToPDF,
  extractImage,
  replaceImage,
} from '../../../../../utils/replace-image-api-helper';
import { calyrexConfig } from '../../../../../runtimeConfig';
// import { convertPdfToImage } from '../../../../../utils/pdf-to-png';
import { convertPPTToPng } from '../../../../../utils/merge-slide-helper';
import ImageLibrary from '../../../SlideImageReplacement/ImageLibrary.vue';

export default {
  name: 'PrezentationReplaceImage',
  components: {
    ImageLibrary,
  },
  props: {
    origin: {
      type: String,
      default: '',
    },
    selectedSlide: {
      type: Object,
      default: () => {},
    },
    updateReplaceImage: {
      type: Function,
      default: () => {},
    },
  },
  async beforeMount() {
    this.fetchReplaceImages();
  },
  data() {
    return {
      imageLibraryComponentKey: 1,
      selectedImage: null,
      imageToReplace: null,
      convertedImage: null,
      isLoading: true,
      iconLibraryComponentKey: 1,
      imagesList: [],
      newInput: {},
      currentAnimationImages: null,
      isConvertingInProgress: false,
      illustrationList: [
        {
          image: '/assets/img/slideImageReplacement/analyzing-content.svg',
          text: 'Analyzing image content...',
          timeout: 0,
        },
        {
          image:
            '/assets/img/slideImageReplacement/resizing-cleaning-image.svg',
          text: 'Resizing and cleaning the image...',
          timeout: 1500,
        },
        {
          image: '/assets/img/slideImageReplacement/finalizing-slide.svg',
          text: 'Finalizing slide design...',
          timeout: 1000,
        },
      ],
    };
  },
  watch: {
    selectedSlide(val) {
      if (val) {
        this.fetchReplaceImages();
      }
    },
  },
  computed: {
    ...mapState('users', ['currentUser']),
    ...mapState('prezentationDetails', [
      'prezentationData',
      'selectedSectionIndex',
      'selectedSlideIndex',
      'actionCompletedInfo',
      'slideActions',
    ]),
    ...mapGetters('prezentationDetails', ['isBestPractices']),
    showImages() {
      return '';
    },
    // TODO: Update flag when new premium features are added to prez
    isPremiumFeature() {
      return (
        this.selectedSlide?.isUserUploaded ||
        this.selectedSlide?.isUploaded ||
        this.selectedSlide?.isGenerated ||
        this.selectedSlide?.isRedesign ||
        this.selectedSlide?.isAnonymized ||
        this.selectedSlide?.isSynthesis ||
        this.selectedSlide?.source === 'generated' ||
        this.selectedSlide?.source === 'uploaded' ||
        this.selectedSlide?.source === 'comply'
      );
    },
    isShellTypeSlide() {
      let changeLayoutAction = false;
      if (this.slideActions.length) {
        changeLayoutAction = !!this.slideActions.find(
          (action) => action.id === 'changeLayout',
        );
      }
      if (
        this.selectedSlide.isSynthesis ||
        this.selectedSlide.source === 'synthesis'
      ) {
        return (
          this.actionCompletedInfo?.synthesisCompleted &&
          this.selectedSlideIndex === 0 &&
          changeLayoutAction
        );
      }
      return changeLayoutAction;
    },
    getS3Path() {
      const { replacedSlideData, filename, file } = this.selectedSlide;
      // use path
      // if (this.selectedSlide.isImageReplaced) {
      //   return this.selectedSlide.file || this.selectedSlide.filename;
      // }
      if (
        this.selectedSlide?.prefs?.source === 'uploaded' ||
        this.isPremiumFeature
      ) {
        return this.selectedSlide.filename;
      }
      return replacedSlideData?.s3_path || filename || file;
    },
  },

  methods: {
    ...mapActions('prezentationDetails', [
      'setSelectedSlideAction',
      'setLoadingIllustrations',
      'updateActionCompletedInfo',
    ]),
    changeLayout() {
      this.setSelectedSlideAction({
        id: 'changeLayout',
        labelText: 'Change layout',
        tooltipText: 'Change layout',
        icon: 'mdi-view-split-vertical',
        isCustomIcon: false,
      });
    },
    onImageSelected(slideImage, toggle) {
      toggle();
      this.selectedImage = { ...slideImage };
      this.imageLibraryComponentKey++;
    },
    getSlideCategoryS3Bucket() {
      if (
        this.selectedSlide?.prefs?.source === 'uploaded' ||
        this.selectedSlide?.filename?.indexOf('public/') !== -1 ||
        this.selectedSlide?.filename?.indexOf('private/') !== -1 ||
        this.selectedSlide?.filename?.indexOf('protected/') !== -1
      ) {
        return process.env.VUE_APP_MVP_ASSETS_BUCKET;
      }
      if (this.isPremiumFeature) {
        let premiumFeatures3Bucket = process.env.VUE_APP_MVP_ASSETS_BUCKET;
        if (
          this.selectedSlide?.filename?.startsWith('newImageReplaceOutput') ||
          this.selectedSlide?.filename?.startsWith('/newImageReplaceOutput')
        ) {
          premiumFeatures3Bucket = calyrexConfig.calyrex_bucket_name;
        }
        return premiumFeatures3Bucket;
      }

      if (
        this.selectedSlide?.prefs?.source === 'generated' ||
        this.selectedSlide?.prefs?.source === 'synthesis' ||
        this.selectedSlide?.prefs?.source === 'redesign' ||
        this.selectedSlide?.prefs?.source === 'comply'
      ) {
        if (
          this.selectedSlide?.landscape?.startsWith(
            '/newImageReplaceOutput/output/',
          ) ||
          this.selectedSlide?.landscape?.startsWith('/output/pptx/')
        ) {
          return calyrexConfig.calyrex_bucket_name;
        }
        return process.env.VUE_APP_MVP_ASSETS_BUCKET;
      }

      const { replacedSlideData, filename } = this.selectedSlide;
      if (
        !replacedSlideData &&
        filename.startsWith('/newImageReplaceOutput/output/')
      ) {
        return calyrexConfig.calyrex_bucket_name;
      }
      return replacedSlideData?.s3_bucket || calyrexConfig.slides_bucket_name;
    },

    fetchReplaceImages() {
      this.isLoading = true;

      const body = {
        s3_bucket: this.getSlideCategoryS3Bucket(),
        s3_path: this.getS3Path,
        force_update: false,
      };
      extractImage(body)
        .then(async (res) => {
          if (res?.images) {
            const list = [];

            for (const key in res.images) {
              if (Object.hasOwnProperty.call(res.images, key)) {
                const image = res.images[key];
                const path = `${image.s3_path}`;

                const imgUrl = await getFileURLDownload(
                  this.currentUser.user.cognitoID,
                  path,
                  this.currentUser.userIp,
                );
                list.push({
                  ...image,
                  imgUrl,
                  imageName:
                    this.selectedSlide.outline ||
                    this.selectedSlide.category?.replaceAll('_', ' ') ||
                    '',
                });
              }
            }

            this.imagesList = list;
            // eslint-disable-next-line prefer-destructuring
            this.selectedImage = list[0];
            this.isLoading = false;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getThirdSlashIndex(inputString) {
      const parts = inputString.split('/');
      if (parts.length >= 4) {
        const modifiedParts = parts.slice(0, 3);
        const modifiedString = modifiedParts.join('/');
        return modifiedString.length;
      }
      return -1;
    },
    getTargetS3Location(inputS3Path, outputFormat) {
      let target = {};
      if (inputS3Path?.indexOf('private/sanitize/') !== -1) {
        inputS3Path = inputS3Path.replace(/^\//, '');
        const thirdSlashIndex = this.getThirdSlashIndex(inputS3Path);
        if (thirdSlashIndex !== -1) {
          const outputS3Path = `/${inputS3Path.slice(
            0,
            thirdSlashIndex + 1,
          )}${uuidv4()}.${outputFormat}`;
          target = {
            s3_bucket: process.env.VUE_APP_MVP_ASSETS_BUCKET,
            s3_path: outputS3Path,
          };
        }
      }
      return target;
    },
    async onReplaceImage(imageToReplace) {
      this.setLoadingIllustrations({
        isLoading: true,
        illustrationList: this.illustrationList,
      });

      this.isConvertingInProgress = true;
      this.imageToReplace = imageToReplace;
      this.savedImagePath = null;
      this.convertedImage = null;
      this.outputFileName = `${uuidv4()}.pptx`;

      const { meta, shapeType, imageIndex } = this.selectedImage;

      let newImage = {
        imageIndex,
        s3_path: this.imageToReplace.s3_path,
        source: this.imageToReplace.source,
      };

      if (
        this.imageToReplace.source === 'adobe' ||
        this.imageToReplace.source === 'freepik'
      ) {
        newImage = {
          imageIndex,
          source: this.imageToReplace.source,
          id: this.imageToReplace.id,
        };
      } else if (this.imageToReplace.source === 'upload') {
        newImage = {
          imageIndex,
          source: this.imageToReplace.source,
          image: this.imageToReplace.image,
          extension: this.imageToReplace.extension,
        };
      }
      let s3Bucket = calyrexConfig.slides_bucket_name;
      const s3Path = this.newInput.s3_path
        ? this.newInput.s3_path
        : this.getS3Path;
      if (s3Path.indexOf('newImageReplaceOutput/') !== -1) {
        s3Bucket = calyrexConfig.calyrex_bucket_name;
      }

      if (
        this.selectedSlide?.prefs?.source === 'uploaded' ||
        this.selectedSlide?.filename?.indexOf('public/') !== -1 ||
        this.selectedSlide?.filename?.indexOf('private/') !== -1 ||
        this.selectedSlide?.filename?.indexOf('protected/') !== -1
      ) {
        if (s3Path.indexOf('newImageReplaceOutput/') !== -1) {
          s3Bucket = calyrexConfig.calyrex_bucket_name;
        }
        s3Bucket = process.env.VUE_APP_MVP_ASSETS_BUCKET;
      } else if (this.isPremiumFeature) {
        s3Bucket = process.env.VUE_APP_MVP_ASSETS_BUCKET;
        if (
          this.selectedSlide?.filename?.startsWith('newImageReplaceOutput') ||
          this.selectedSlide?.filename?.startsWith('/newImageReplaceOutput')
        ) {
          s3Bucket = calyrexConfig.calyrex_bucket_name;
        }
      } else if (
        this.selectedSlide?.prefs?.source === 'generated' ||
        this.selectedSlide?.prefs?.source === 'synthesis' ||
        this.selectedSlide?.prefs?.source === 'redesign' ||
        this.selectedSlide?.prefs?.source === 'comply'
      ) {
        if (
          this.selectedSlide?.landscape?.startsWith(
            '/newImageReplaceOutput/output/',
          ) ||
          this.selectedSlide?.landscape?.startsWith('/output/pptx/')
        ) {
          s3Bucket = calyrexConfig.calyrex_bucket_name;
        } else {
          s3Bucket = process.env.VUE_APP_MVP_ASSETS_BUCKET;
        }
      }

      // if (this.getIsDirty === true) {
      //   s3Bucket = calyrexConfig.calyrex_bucket_name;
      // }
      const body = {
        s3_bucket: s3Bucket,
        s3_path: s3Path,
        outputFormat: 'pptx',
        newImage,
        oldImage: { meta, shapeType },
        /*
          passing feature in replace_image payload
          which triggered the replace image in product
        */
        feature: this.isBestPractices ? 'BPL Deck' : 'Presentation',
      };

      // const [resPdf, response] = await Promise.all([
      //   convertToPDF({
      //     ...body,
      //     outputFormat: 'pdf',
      //     target: this.getTargetS3Location(s3Path, 'pdf'),
      //   }),
      //   replaceImage({
      //     ...body,
      //     target: this.getTargetS3Location(s3Path, 'pptx'),
      //   }),
      // ]);
      const response = await replaceImage({
        ...body,
        target: this.getTargetS3Location(s3Path, 'pptx'),
      });

      if (response?.s3_path) {
        console.log('Deck successfuly converted', response);
        try {
          if (response.input) {
            this.payloadData = {
              ...body,
              newImage: { ...response.input, imageIndex },
            };
          }
          this.deckDetails = response;
          this.newInput = response;
          if (this.deckDetails) {
            this.selectedImage = null;

            this.imagesList = this.imagesList.map((image) => {
              if (image !== undefined && image.imageIndex === imageIndex) {
                image.imgUrl = this.imageToReplace.url;
                this.selectedImage = image;
              }

              return image;
            });
          }
          // this.downloadUrl = await getFileURLDownload(
          //   this.currentUser.user.cognitoID,
          //   response?.s3_path,
          //   this.currentUser.userIp,
          // );

          this.savedImagePath = await convertPPTToPng(
            response.s3_bucket,
            response.s3_path,
          );

          // let outputFilename = '';
          // if (resPdf?.s3_path.indexOf('/newImageReplaceOutput/') !== -1) {
          //   outputFilename = await getFileURLDownload(
          //     this.currentUser.user.cognitoID,
          //     resPdf?.s3_path,
          //     this.currentUser.userIp,
          //   );
          // } else {
          //   outputFilename = await getFileURL(
          //     this.currentUser.user.cognitoID,
          //     resPdf?.s3_path,
          //     this.currentUser.userIp,
          //     resPdf?.s3_path.indexOf('private/sanitize/') !== -1
          //       ? 'IOCUpload'
          //       : undefined,
          //   );
          // }
          // this.savedImagePath = resPdf?.s3_path;
          // this.convertedImage = await convertPdfToImage(outputFilename);
          this.convertedImage = this.savedImagePath;
          this.isConvertingInProgress = false;

          let slideIndex = 0;
          this.prezentationData?.sections?.list?.forEach((section, i) => {
            section.slides.forEach((slide, j) => {
              if (
                i < this.selectedSectionIndex ||
                (this.selectedSectionIndex === i && j < this.selectedSlideIndex)
              ) {
                slideIndex++;
              }
            });
          });

          this.updateActionCompletedInfo({
            ...this.actionCompletedInfo,
            isReplaceVisualsCompleted: true,
            replacedImage: this.actionCompletedInfo?.replacedImage
              ? [...this.actionCompletedInfo.replacedImage, slideIndex]
              : [slideIndex],
          });
          this.updateReplaceImage(
            {
              type: 'replace_image',
            },
            {
              pptxPath: response?.s3_path,
              pdfPath: this.savedImagePath,
              replacedInfo: this.payloadData,
              convertedImage: this.convertedImage,
            },
            () => {
              this.setLoadingIllustrations({
                isLoading: false,
                illustrationList: [],
              });
            },
          );
        } catch (error) {
          console.error(error);
          this.isConvertingInProgress = false;
          this.setLoadingIllustrations({
            isLoading: false,
            illustrationList: [],
          });
        }
      } else {
        this.isConvertingInProgress = false;
        console.error(response);
        this.setLoadingIllustrations({
          isLoading: false,
          illustrationList: [],
        });
      }
    },
    onImageSearch() {},
  },
};
</script>

<style lang="scss" scoped>
.replace-image-container {
  margin: 10px 0;
}
.spinner {
  margin-top: 20px;
}
.replace-visual-verbose {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  padding-bottom: 20px;
}
.no-image {
  display: flex;
  gap: 24px;
  align-items: center;
  height: calc(100% - 42px);
  flex-direction: column;
  margin-top: 36px;

  .desc {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #000000;
  }

  .primary-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 16px;
    gap: 8px;
    height: 32px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-transform: capitalize;
    letter-spacing: normal;
    line-height: 19px;

    /* Color Styles (Old)/White */

    color: #ffffff;

    background: #21a7e0;
    border-radius: 25px;
  }
}
.item-disabled {
  opacity: 0.5;
  pointer-events: none;
}
.select-image {
  align-items: center;
  display: flex;
  padding-top: 2px;
  max-width: 100%;
  padding-bottom: 20px;

  .select-image__item {
    border: solid $light-gray 1px;
    border-radius: 8px;
    box-shadow: $item-box-shadow;
    min-width: 100px;

    &:hover {
      box-shadow: $item-box-shadow-hover;
      cursor: pointer;
    }
  }

  .selected {
    border: 2px solid $zen-blue-default;
  }
}
::v-deep .image-upload__container {
  .container {
    max-height: calc(100vh - 390px);
    overflow-y: auto;
    overflow-x: hidden;
  }
}
</style>
