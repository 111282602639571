var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"change-layout-container"},[_c('div',{staticClass:"heading",style:({
      marginBottom:
        _vm.origin === 'Synthesis' || _vm.origin === 'Redesign' ? '8px' : '24px',
    })},[_vm._v(" "+_vm._s(_vm.$t('generate.smartLayout'))+" "),(_vm.showPoweredByText)?_c('div',{staticClass:"sub"},[_vm._v(" "+_vm._s(_vm.$t('build.step3.poweredByZenSence'))+" ")]):_vm._e()]),_c('div',{staticClass:"sub-heading"},[_vm._v(_vm._s(_vm.$t('generate.slideWithDifferentLayout')))]),_c('div',[_c('v-radio-group',{staticClass:"similar-slides-radiobtns",attrs:{"disabled":_vm.similarSlidesLoading},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}},[_c('v-row',{attrs:{"data-pendo-id":"slide-similar-options"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio',{attrs:{"label":"Prezent","value":"default","disabled":_vm.isTemplateRemoved === true || _vm.isAudienceAvailable === false}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-radio',{attrs:{"label":_vm.companyName,"value":_vm.currentUser.company.name,"disabled":_vm.isTemplateRemoved === true || _vm.isAudienceAvailable === false}})],1)],1)],1)],1),_vm._t("default",function(){return [(_vm.relatedSlides.length)?_c('div',{staticClass:"icon-container"},[_c('div',{staticClass:"node-count-dropdown"},[_c('v-icon',{staticClass:"node-count-slides-icon",attrs:{"color":'#000'}},[_vm._v(" "+_vm._s('mdi-file-tree-outline')+" ")]),_c('div',{staticClass:"node-count-slides-dropdown"},[_c('span',{staticClass:"node-label"},[_vm._v(_vm._s(_vm.$t('generate.nodeCount'))+": ")]),_c('v-select',{attrs:{"items":_vm.similarSlidesNodesList,"item-text":function (item) { return _vm.$t(item.display_text); },"item-value":"value","hide-details":""},on:{"change":_vm.handleSimilarSlideNodeChange},model:{value:(_vm.selectedSimilarSlideNodeCount),callback:function ($$v) {_vm.selectedSimilarSlideNodeCount=$$v},expression:"selectedSimilarSlideNodeCount"}})],1)],1),_c('div',{on:{"click":function($event){return _vm.handleView(false)}}},[_c('v-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: _vm.$t('prezentationGallery.gridView'),
            placement: 'top-center',
          }),expression:"{\n            content: $t('prezentationGallery.gridView'),\n            placement: 'top-center',\n          }"}],attrs:{"color":_vm.isColumnView ? '#000' : '#21A7E0'}},[_vm._v(" "+_vm._s(_vm.isColumnView ? 'mdi-view-grid-outline' : 'mdi-view-grid')+" ")])],1),_c('div',{on:{"click":function($event){return _vm.handleView(true)}}},[_c('v-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: _vm.$t('prezentationGallery.columnView'),
            placement: 'top-center',
          }),expression:"{\n            content: $t('prezentationGallery.columnView'),\n            placement: 'top-center',\n          }"}],attrs:{"color":_vm.isColumnView ? '#21A7E0' : '#000'}},[_vm._v(" "+_vm._s(_vm.isColumnView ? 'mdi-view-agenda' : 'mdi-view-agenda-outline')+" ")])],1)]):_vm._e(),_c('div',{staticClass:"layout-content"},[_vm._l((_vm.filteredSlideByNode),function(slide,index){return _c('div',{key:index,class:[{ 'column-view': _vm.isColumnView }, 'slide'],attrs:{"data-pendo-id":index === 0 ? 'smart-layout-slide' : null}},[_c('v-img',{staticClass:"layout-image",class:{ current: slide.unique_id === _vm.selectedSlideID },attrs:{"contain":"","lazy-src":"/assets/img/slides/placeholder-slide.svg","aspect-ratio":16 / 9,"src":_vm.getImgPath(slide),"loading":"lazy"},on:{"click":function($event){return _vm.handleChangeLayout(slide)}}})],1)}),_c('div',[(_vm.similarSlidesLoading)?_c('clip-loader',{staticClass:"cliploader",staticStyle:{"margin-top":"200px"},attrs:{"loading":_vm.similarSlidesLoading,"color":"#21a7e0","width":'60',"height":'60',"size":'50px'}}):_vm._e()],1),(!_vm.similarSlidesLoading && _vm.filteredSlideByNode.length <= 0)?_c('div',{staticClass:"no-results-wrapper"},[(!_vm.filteredSlideByNodeError)?_c('EmptyState',{attrs:{"img-url":'/assets/img/no-files-available.svg',"is-btn-visible":false},scopedSlots:_vm._u([{key:"description",fn:function(){return [_c('p',{staticClass:"no-results-description"},[_vm._v(" "+_vm._s(_vm.$t('slideDetails.noResultFound'))+" ")])]},proxy:true}],null,false,989397150)}):_c('EmptyState',{attrs:{"img-url":'/assets/img/sso-user_not_found.svg',"is-btn-visible":false},scopedSlots:_vm._u([{key:"description",fn:function(){return [_c('p',{staticClass:"no-results-description"},[_vm._v(" "+_vm._s(_vm.$t('slideDetails.noResultsForSelectedNodeCount'))+" ")])]},proxy:true}],null,false,2017421418)})],1):_vm._e()],2)]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }