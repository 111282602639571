<template>
  <div>
    <!-- <SubscriptionExpiredBanner
      v-if="currentUser.user.role.toLowerCase() !== 'trialuser'"
    />
    !-->
    <div
      class="main-section-wrapper"
      :style="{ 'background-image': backgroundImage }"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import EventBus from '../../common/event-bus';
import { trackProfileEvents } from '../../common/Analytics/ProfileEvents';
// import SubscriptionExpiredBanner from '../../common/SubscriptionExpiredBanner.vue';
import SurveyModal from '../../common/SurveyModal.vue';
import FingerprintReminderModal from '../../common/FingerprintReminder.vue';
import TemplateCompletePopup from '../../common/TemplateCompletePopup.vue';

export default {
  name: 'MainSection',
  data() {
    return {
      teamSubscription: [],
      isUserSubExpired: false,
      backgroundImage: '',
      showTemplateCompletionPopupOnce: true,
    };
  },
  components: {
    // SubscriptionExpiredBanner,
  },
  methods: {
    ...mapActions('users', [
      'setCurrentUser',
      'setCurrentSubscription',
      'showConfetti',
    ]),
    getRandomBackground(mi, ma) {
      const min = Math.ceil(mi);
      const max = Math.floor(ma);
      this.backgroundImage = `url('/assets/img/quote${Math.floor(
        Math.random() * (max - min + 1) + min,
      )}.svg'`;
    },
    async displayTemplateCompletePopup() {
      this.showTemplateCompletionPopupOnce = false; // in case the td state is changed and the watcher is called
      this.$modal.show(
        TemplateCompletePopup,
        {},
        {
          name: 'TemplateCompletePopup',
          width: '400px',
          height: '426px',
          styles: {
            borderRadius: '12px',
          },
        },
      );
    },
  },
  computed: {
    ...mapState('users', ['currentUser', 'currentTheme']),
    ...mapState('tourStore', ['notifications']),
    ...mapGetters('users', ['getOPAccessLevel']),
    ...mapGetters('templateDesigner', ['getFirstTDData']),
  },
  watch: {
    getFirstTDData: {
      handler(tdData) {
        /*  Template Designer Popup is no longer dependent on notifications. 
       Show templateCompletionPopup only when status is completed, percentage is 100 and
       templateCompletionPopup is 0 or not defined and should be shown only once */
        if (
          Object.keys(tdData).length &&
          (tdData?.banners?.templateCompletionPopup || 0) === 0 &&
          tdData.status === 'completed' &&
          this.showTemplateCompletionPopupOnce
        ) {
          this.showConfetti();
          this.displayTemplateCompletePopup();
        }
      },
      immediate: false,
    },
  },
  // beforeMount() {
  //   const userData = await this.refreshCurrentUserInfo();
  //   this.setCurrentUser(userData);

  //   if (userData?.subscriptions?.[0]) {
  //     const [activeSubscription] = userData.subscriptions;
  //     this.setCurrentSubscription(activeSubscription);
  //     this.isUserSubExpired = activeSubscription.isExpired;
  //   }
  // },
  beforeMount() {
    this.getRandomBackground(1, 24);
  },

  async mounted() {
    // const userData = await this.refreshCurrentUserInfo();
    // this.setCurrentUser(userData);
    const userData = this.currentUser;
    if (userData?.subscriptions?.[0]) {
      const [activeSubscription] = userData.subscriptions;
      this.setCurrentSubscription(activeSubscription);
      this.isUserSubExpired = activeSubscription.isExpired;
    }
    if (
      (this.currentUser.user &&
        this.isUserSubExpired === false &&
        this.currentUser.user.showRating === true &&
        !this.currentUser.user.showFpReminder) ||
      (this.currentUser.user &&
        this.$route.query &&
        this.isUserSubExpired === false &&
        this.$route.query.survey &&
        this.$route.query.survey === 'true' &&
        !this.currentUser.user.showFpReminder)
    ) {
      trackProfileEvents.profileMetricsTakeSurvey(this.currentUser);
      this.$modal.show(
        SurveyModal,
        { currentUser: this.currentUser },
        {
          name: 'SurveyModal',
          width: '600px',
          height: 'auto',
          styles: {
            'max-height': '95%',
            'max-width': '800px',
            overflow: 'auto',
          },
          clickToClose: false,
        },
      );
    }

    if (
      this.currentUser.user &&
      this.currentUser.user.showFpReminder &&
      this.isUserSubExpired === false
    ) {
      this.$modal.show(
        FingerprintReminderModal,
        { currentUser: this.currentUser },
        {
          name: 'FingerprintReminderModal',
          width: '600px',
          height: 'auto',
          styles: {
            'max-height': '95%',
            'max-width': '800px',
            overflow: 'auto',
          },
          clickToClose: false,
        },
      );
    }
    // Calling displayTemplateCompletePopup if job is completed and watcher is not called on mounted
    if (
      Object.keys(this.getFirstTDData).length &&
      (this.getFirstTDData?.banners?.templateCompletionPopup || 0) === 0 &&
      this.getFirstTDData.status === 'completed' &&
      this.showTemplateCompletionPopupOnce
    ) {
      this.showConfetti();
      this.displayTemplateCompletePopup();
    }

    // Timeout to open new feature popup after one second
    // setTimeout(() => {
    //   this.activateNewFeaturePopups();
    // }, 1000);

    // clearing global search value when this component is clicked after making a global search
    EventBus.$emit('CLEAR_SEARCH');
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.main-section-wrapper {
  align-items: flex-start;
  background-position: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: calc(100vh - 64px);
  justify-content: center;
  position: relative;
  padding-left: 10%;
  padding-right: 10%;
  img {
    height: 100%;
    width: 100%;
  }
}
</style>
