<template>
  <div>
    <v-row class="tab-content basic-content row">
      <v-col
        class="left-panel"
        data-pendo-id="profile-left-panel"
        cols="12"
        sm="12"
        md="3"
        lg="3"
      >
        <div class="access-label-info">
          <div class="access-label-title">
            {{ $t('profile.basicsvue.accessLevel') }}
          </div>
          <div class="access-label-desc">
            {{ getLevel() }}
          </div>
        </div>
        <div :key="componentKey" class="profile-image-wrapper">
          <v-dialog v-model="profilePictureDialog" width="600" persistent>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" class="profileImageOrAvatar">
                <div
                  v-if="profileImageMainPage !== '/assets/img/user-no-pic.svg'"
                  class="profile-image-upload"
                >
                  <v-img contain :src="profileImageMainPage" alt />
                  <div class="overlay">
                    <v-icon large>mdi-camera</v-icon>
                  </div>
                </div>
                <div v-else class="profile-user-avatar-wrapper">
                  <v-avatar
                    class="profile-user-avatar"
                    color="rgb(88, 168, 222)"
                    size="140"
                    rounded
                  >
                    <span
                      class="white--text text-h2"
                      style="color: white; font-weight: 250"
                      >{{ userInitials }}</span
                    >
                  </v-avatar>
                  <div class="overlay">
                    <v-icon large>mdi-camera</v-icon>
                  </div>
                </div>
              </div>
            </template>

            <v-card class="profileImageUploaderCard">
              <v-card-title>
                <div class="dialogHeading">
                  {{
                    $t(
                      discardConfirmationDialog === true
                        ? 'profile.basicsvue.discardChanges'
                        : 'profile.basicsvue.editPhoto',
                    )
                  }}
                </div>
                <v-icon
                  v-if="discardConfirmationDialog === false"
                  medium
                  :disabled="
                    updatingProfilePicture === true || showChangesSaved === true
                  "
                  @click="showDiscardContent"
                >
                  mdi-close
                </v-icon>
              </v-card-title>

              <v-card-text>
                <div v-show="discardConfirmationDialog" class="discardMessage">
                  {{ $t('profile.basicsvue.editPhotoChanges') }}
                </div>
                <div
                  v-show="!discardConfirmationDialog"
                  class="uploaderElements"
                >
                  <div class="errorContainer">
                    <img
                      src="/assets/icons/warning-outline.svg"
                      class="errorIcon"
                      v-if="error !== ''"
                    />
                    <span class="errorMessage" v-if="error !== ''">{{
                      $t(error)
                    }}</span>
                  </div>
                  <div
                    v-if="profileImage !== '/assets/img/user-no-pic.svg'"
                    class="dialogAvatarWhenProfilePicIsPresent"
                  >
                    <div
                      v-if="removingProfilePicture === true"
                      class="removalContainer"
                    >
                      <div class="avatarContainer">
                        <v-avatar color="rgb(88, 168, 222)" size="140" rounded>
                          <span
                            class="white--text text-h2"
                            style="color: white; font-weight: 250"
                          >
                            {{ userInitials }}
                          </span>
                        </v-avatar>
                      </div>
                      <div class="uploadLinkContainer">
                        <div
                          class="linkWithIcon"
                          @click="handleFileExplorerOpen"
                        >
                          <v-icon>mdi-upload-outline</v-icon>
                          <span>{{
                            $t('profile.basicsvue.addProfilePhoto')
                          }}</span>
                        </div>
                        <input
                          ref="uploader"
                          class="d-none"
                          type="file"
                          accept="*/*"
                          @change="handleSetCropperFile"
                          :disabled="
                            updatingProfilePicture === true ||
                            showChangesSaved === true
                          "
                        />
                      </div>
                    </div>
                    <div v-else class="cropZoomAndActions">
                      <vue-cropper
                        ref="cropper"
                        :src="croppedImage"
                        alt="Source Image"
                        :aspect-ratio="4 / 4"
                        drag-mode="move"
                        :view-mode="0"
                        :movable="true"
                        :crop-box-movable="true"
                        :crop-box-resizable="true"
                        :img-style="{
                          width: '270px',
                          height: '270px',
                        }"
                        :container-style="{
                          width: '270px',
                          height: '270px',
                          margin: '20px auto',
                        }"
                        :zoomOnTouch="false"
                        :zoomOnWheel="false"
                        @ready="cropperIsReady"
                      />
                      <div class="sliderContainer">
                        <v-btn
                          icon
                          text
                          @click="zoomOut"
                          :disabled="
                            sliderValue <= 0 ||
                            updatingProfilePicture === true ||
                            showChangesSaved === true
                          "
                        >
                          <v-icon> mdi-magnify-minus-outline </v-icon>
                        </v-btn>
                        <v-slider
                          v-model="sliderValue"
                          :step="10"
                          track-color="secondary"
                          :thumb-color="`${
                            sliderValue === 0 ||
                            updatingProfilePicture === true ||
                            showChangesSaved === true
                              ? 'secondary'
                              : 'rgb(88, 168, 222)'
                          }`"
                          :disabled="
                            updatingProfilePicture === true ||
                            showChangesSaved === true
                          "
                        />
                        <v-btn
                          icon
                          text
                          @click="zoomIn"
                          :disabled="
                            sliderValue >= 100 ||
                            updatingProfilePicture === true ||
                            showChangesSaved === true
                          "
                        >
                          <v-icon> mdi-magnify-plus-outline </v-icon>
                        </v-btn>
                      </div>
                      <div class="cropperActionsContainer">
                        <div class="swapIcon">
                          <div @click="handleFileExplorerOpen">
                            <v-icon>mdi-swap-horizontal</v-icon>
                            <span>{{ $t('profile.basicsvue.replace') }}</span>
                          </div>
                          <input
                            ref="uploader"
                            class="d-none"
                            type="file"
                            accept="*/*"
                            @change="handleSetCropperFile"
                            :disabled="
                              updatingProfilePicture === true ||
                              showChangesSaved === true
                            "
                          />
                        </div>
                        <div class="removeIcon">
                          <div
                            @click="
                              updatingProfilePicture === false &&
                              showChangesSaved === false
                                ? handleRemoveProfilePicture()
                                : null
                            "
                          >
                            <v-icon>mdi-delete-outline</v-icon>
                            <span>{{ $t('profile.basicsvue.remove') }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else class="dialogAvatarWhenProfilePicNotPresent">
                    <v-avatar color="rgb(88, 168, 222)" size="140" rounded>
                      <span
                        class="white--text text-h2"
                        style="color: white; font-weight: 250"
                      >
                        {{ userInitials }}
                      </span>
                    </v-avatar>
                  </div>
                  <div class="suggestionContainer">
                    {{ $t('profile.basicsvue.selectPhoto') }}
                  </div>
                  <v-divider></v-divider>
                </div>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <div
                  v-if="discardConfirmationDialog === true"
                  class="discardActionsContainer"
                >
                  <v-btn
                    class="yesButton"
                    @click="
                      handleDiscardConfirmationYes(removingProfilePicture)
                    "
                  >
                    {{ $t('common.yes') }}
                  </v-btn>
                  <v-btn class="noButton" @click="handleDiscardConfirmationNo">
                    {{ $t('common.no') }}
                  </v-btn>
                </div>
                <div v-else class="updatePicActionsContainer">
                  <div
                    v-if="profileImage !== '/assets/img/user-no-pic.svg'"
                    class="saveChangesButtonWrapper"
                  >
                    <v-btn
                      @click="
                        removingProfilePicture === true
                          ? removeProfilePic()
                          : handleUploadCroppedProfileImage()
                      "
                      :loading="updatingProfilePicture"
                    >
                      {{
                        `${
                          showChangesSaved === true
                            ? $t('profile.basicsvue.changesSaved')
                            : $t('profile.basicsvue.saveChanges')
                        }`
                      }}
                    </v-btn>
                  </div>
                  <div v-else class="addPhotoButtonWrapper">
                    <v-btn @click="handleFileExplorerOpen">
                      {{ $t('profile.basicsvue.addProfilePhoto') }}
                    </v-btn>
                    <input
                      ref="uploader"
                      class="d-none"
                      type="file"
                      accept="*/*"
                      @change="handleSetCropperFile"
                    />
                  </div>
                </div>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>

        <div @click="handleEditPhotoClick" class="profile-image-input">
          <NewFeatureBadge
            module-name="editprofilephoto"
            :feature-id="'editprofilephoto_id'"
            :offsetX="'-7'"
            :offsetY="'22'"
          >
            {{ $t('profile.basicsvue.editPhoto') }}
          </NewFeatureBadge>
        </div>
        <v-btn
          v-if="!ssoConfig.isSSOApp"
          text
          class="edit-profile-btn"
          @click="handleChangePassword"
        >
          {{ $t('profile.basicsvue.changePassword') }}
        </v-btn>
        <v-btn
          style="margin-top: 7px"
          rounded
          text
          outlined
          color="primary"
          class="edit-profile-btn log-out-button"
          @click="handleSignOut"
        >
          {{ $t('profile.basicsvue.signOut') }}
        </v-btn>
      </v-col>
      <v-col class="right-panel" cols="8" sm="12" md="9" lg="9">
        <!-- Basic view switch !-->
        <div class="compSwitch">
          <v-btn
            :class="`profileInfoBtn ${
              basicsView === 'profileInformation' ? 'btnActive' : ''
            }`"
            :ripple="false"
            @click="selectBasicsView('profileInformation')"
          >
            {{ $t('profile.basicsvue.profileInfo') }}
          </v-btn>
          <v-btn
            v-if="isGoogleDownloadEnabled"
            :class="`dwnldSettBtn  ${
              basicsView === 'downloadSettings' ? 'btnActive' : ''
            } ${!isGoogleDownloadEnabled ? 'btnDisabled' : ''}`"
            :ripple="false"
            @click="selectBasicsView('downloadSettings')"
            data-pendo-id="profile-download-settings"
            :disabled="!isGoogleDownloadEnabled"
          >
            {{ $t('profile.basicsvue.downloadSett') }}
          </v-btn>
        </div>

        <!-- Basic profile Info !-->
        <div
          :key="profilekey"
          class="info-wrapper"
          v-if="basicsView === 'profileInformation'"
        >
          <div class="info-item">
            <div class="info-title email-id">
              {{ $t('profile.basicsvue.workEmail') }}
            </div>
            <v-text-field
              disabled
              single-line
              type="email"
              :placeholder="$t('profile.basicsvue.workEmail')"
              v-model="user.email"
              id="work-email"
              @blur="handleEmailChange"
            />
          </div>
          <div class="info-item">
            <div class="info-title first-name">
              {{ $t('profile.firstname') }}
            </div>
            <v-text-field
              :disabled="isReadOnly"
              single-line
              :placeholder="
                isReadOnly
                  ? user.firstName
                    ? user.firstName
                    : ''
                  : $t('profile.firstname')
              "
              v-model="user.firstName"
              :rules="emojiRules"
            />
          </div>
          <div class="info-item">
            <div class="info-title last-name">{{ $t('profile.lastname') }}</div>
            <v-text-field
              :disabled="isReadOnly"
              single-line
              :placeholder="
                isReadOnly
                  ? user.lastName
                    ? user.lastName
                    : ''
                  : $t('profile.lastname')
              "
              v-model="user.lastName"
              :rules="emojiRules"
            />
          </div>
          <div class="info-item">
            <div class="info-title job-title">
              {{ $t('fingerprint.jobTitle') }}
            </div>
            <v-combobox
              :disabled="isReadOnly || isDataLoading"
              single-line
              :return-object="false"
              v-model="user.jobTitle"
              :items="jobTitles"
              color="black"
              item-text="name"
              item-value="name"
              @keyup="jobTitleValue"
              :placeholder="
                isReadOnly
                  ? user.jobTitle
                    ? user.jobTitle
                    : ''
                  : $t('fingerprint.jobTitle')
              "
              clearable
            >
            </v-combobox>
          </div>
          <div>
            <div class="info-title user-industry">
              {{ $t('profile.basicsvue.industry') }}
            </div>
            <v-autocomplete
              :disabled="isReadOnly || isDataLoading"
              v-model="user.industry"
              :items="industries"
              color="black"
              item-text="name"
              item-value="name"
              :placeholder="
                isReadOnly
                  ? user.industry
                    ? user.industry
                    : ''
                  : $t('profile.basicsvue.industry')
              "
            >
            </v-autocomplete>
          </div>
          <div class="info-item">
            <div class="info-title job-function">
              {{ $t('profile.basicsvue.function') }}
            </div>
            <v-combobox
              :disabled="isReadOnly || isDataLoading"
              :return-object="false"
              v-model="user.jobFunction"
              :items="functions"
              color="black"
              item-text="name"
              item-value="name"
              @keyup="jobFunctionValue"
              :placeholder="
                isReadOnly
                  ? user.jobFunction
                    ? user.jobFunction
                    : ''
                  : $t('profile.basicsvue.function')
              "
              clearable
            >
            </v-combobox>
          </div>
          <div class="info-item">
            <div class="info-title company-name">
              {{ $t('fingerprint.exploreFingerprint.company') }}
            </div>
            <v-text-field
              disabled
              single-line
              :placeholder="$t('fingerprint.exploreFingerprint.company')"
              v-model="user.companyName"
            />
          </div>
          <div class="info-item" v-if="isLocalizationEnabled">
            <div class="info-title job-function">
              {{ $t('profile.basicsvue.preferredLanguage') }}
              <sup>{{ $t('rightSideBar.beta') }}</sup>
            </div>
            <v-combobox
              :disabled="isReadOnly || isDataLoading"
              return-object
              v-model="selectedLanguage"
              :items="localizationOptions"
              color="black"
              item-text="title"
              item-value="code"
              @change="handleLanguageChange"
              :placeholder="
                isReadOnly
                  ? user.language
                    ? user.language
                    : ''
                  : $t('profile.basicsvue.preferredLanguage')
              "
              clearable
            >
            </v-combobox>
          </div>

          <!-- Moved edit profile CTA button to profile information view !-->
          <div class="editProfileBtnContainer">
            <v-btn text class="edit-profile-btn" @click="handleSaveProfile">
              {{
                isSaved
                  ? $t('profile.basicsvue.editProfile')
                  : $t('profile.basicsvue.saveProfile')
              }}
            </v-btn>
          </div>
        </div>

        <!-- Download settings component !-->
        <div
          class="download-setting-wrapper"
          v-if="basicsView === 'downloadSettings'"
        >
          <UserDownloadPreferenceSelector :compType="'comp'" />
        </div>
      </v-col>
    </v-row>
    <v-row
      :class="`${
        basicsView === 'profileInformation'
          ? 'tab-footer'
          : 'tab-footer-dwnldSett'
      } row`"
    >
      <p>
        <a href="https://prezent.ai/terms-of-service/" target="_blank">{{
          $t('profile.editPaymentDetail.termsOfService')
        }}</a>
      </p>
      <p>
        <a href="https://prezent.ai/privacy-policy/" target="_blank">{{
          $t('fingerprint.privacyPolicy')
        }}</a>
      </p>
      <p>&#169; {{ $t('fingerprint.prezent') }}</p>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import 'vue-advanced-cropper/dist/style.css';
import 'vue-advanced-cropper/dist/theme.classic.css';
import { Auth } from 'aws-amplify';
import VueCropper from 'vue-cropperjs';
import { clearCachedUser } from '../../../utils/common';
import EventBus from '../../common/event-bus';
import {
  getFingerPrintAdditionalData,
  uploadOPFile,
  uploadChunks,
} from '../../../utils/api-helper';
import { getFileURL } from '@/utils/calyrex';
import { AnalyticsHandler } from '../../common/Analytics/AnalyticsHandler';
import { isEmojiExist } from '../../utils';
import 'cropperjs/dist/cropper.css';
import { SSOConfig } from '../../../runtimeConfig';
import ChangePasswordDetail from './ChangePasswordDetail.vue';
import UserDownloadPreferenceSelector from '../../common/UserDownloadPreferenceSelector.vue';
import usersApi from '../../../API/users-api';
import NewFeatureBadge from '../../common/NewFeatureBadge.vue';
import { trackProfileEvents } from '../../common/Analytics/ProfileEvents';
import { trackDownloadSettingsEvents } from '../../common/Analytics/DownloadSettingsEvents.js';
import {
  TD_PLACEMENT,
  TD_ISDOWNLOAD,
  TD_VIEWTYPE,
  TD_EXPANDED_VIEW,
} from '../../common/Analytics/MatomoTrackingDataHelper.js';

export default {
  name: 'Basics',
  components: {
    VueCropper,
    NewFeatureBadge,
    UserDownloadPreferenceSelector,
  },
  data() {
    return {
      isDataLoading: true,
      componentKey: 100,
      profileImage: '/assets/img/user-no-pic.svg',
      profileImageMainPage: '/assets/img/user-no-pic.svg',
      emojiRules: [(v) => !isEmojiExist(v) || 'Emojis are not allowed'],
      isSaved: true,
      ssoConfig: SSOConfig,
      profilekey: 10,
      user: {},
      isReadOnly: true,
      loadingCompanyInfo: true,
      profilePictureDialog: false,
      sliderValue: 0,
      updatingProfilePicture: false,
      cropFileName: '',
      cropFileType: '',
      croppedImage: '/assets/img/user-no-pic.svg',
      lastUpdatedFileName: '',
      lastUpdatedFileType: '',
      discardConfirmationDialog: false,
      removingProfilePicture: false,
      showChangesSaved: false,
      error: '',
      cropBoxData: null,
      canvasData: null,
      jobTitles: [],
      functions: [],
      industries: [],
      groups: [],
      selectedLanguage: { code: 'en', title: 'English (US)', flag: 'us' },
      localizationOptions: [
        { code: 'en', title: 'English (US)', flag: 'us' },
        { code: 'ja', title: '日本語', flag: 'jp' },
      ],
      basicsView: 'profileInformation',
    };
  },
  computed: {
    ...mapState('users', ['currentUser', 'fingerPrintAdditionalData']),

    isGoogleDownloadEnabled() {
      if (this.currentUser) {
        const feature = this.currentUser.features.find(
          (f) => f.feature_name === 'google_slides_download',
        );
        return feature && feature.enabled;
      }
      return false;
    },

    userInitials() {
      if (!this.currentUser) {
        return '';
      }
      let firstLetter = '';
      let secondLetter = '';
      if (
        this.currentUser.user.firstName &&
        this.currentUser.user.firstName.trim().length > 0
      ) {
        firstLetter = this.currentUser.user.firstName.charAt(0).toUpperCase();
      }
      if (
        this.currentUser.user.lastName &&
        this.currentUser.user.lastName.trim().length > 0
      ) {
        secondLetter = this.currentUser.user.lastName.charAt(0).toUpperCase();
      }
      return firstLetter + secondLetter;
    },
    isLocalizationEnabled: (state) => {
      if (state.currentUser) {
        const feature = state.currentUser.features.find(
          (f) => f.feature_name === 'localization',
        );
        return feature && feature.enabled;
      }
      return false;
    },
  },
  mounted() {
    if (this.currentUser?.user?.language) {
      const locale = this.localizationOptions.find(
        (lang) => lang.code === this.currentUser?.user?.language,
      );
      this.handleLanguageChange(locale);
    }
  },
  watch: {
    sliderValue(newVal, oldVal) {
      if (newVal > oldVal) {
        const diff = (newVal - oldVal) / 10;
        if (this.$refs.cropper) {
          for (let i = 0; i < diff; i++) {
            this.$refs.cropper.relativeZoom(0.2);
          }
        }
      } else {
        const diff = (oldVal - newVal) / 10;
        if (this.$refs.cropper) {
          for (let i = 0; i < diff; i++) {
            this.$refs.cropper.relativeZoom(-0.2);
          }
        }
      }
    },
    profilePictureDialog(newVal) {
      if (newVal === false && this.$refs.cropper !== undefined) {
        this.sliderValue = 0;
        this.$refs.cropper.reset();
      }
    },
    // eslint-disable-next-line func-names
    'currentUser.user.language': function (val) {
      const locale = this.localizationOptions.find((lang) => lang.code === val);
      this.selectedLanguage = locale;
    },
  },
  methods: {
    ...mapActions('users', [
      'setCurrentUser',
      'setFilteredThemes',
      'loadAvailablePlans',
      'loadAvailableAddOns',
      'setCurrentUserPersonalPreferences',
      'resetState',
      'setFingerPrintAdditionalDetails',
    ]),

    selectBasicsView(view) {
      this.basicsView = view;
      this.handleDownloadSettingsEvents();
    },
    handleDownloadSettingsEvents() {
      const otherData = {
        [TD_ISDOWNLOAD]: 0,
        [TD_PLACEMENT]: 'Profile',
        [TD_VIEWTYPE]: TD_EXPANDED_VIEW,
      };
      trackDownloadSettingsEvents.downloadSettingsClick(
        this.currentUser,
        otherData,
      );
    },

    handleLanguageChange(selectedLanguage) {
      this.selectedLanguage = selectedLanguage;
      this.user.language = selectedLanguage.code;
      this.$i18n.locale = selectedLanguage.code;
    },
    cropperIsReady() {
      this.canvasData = this.$refs.cropper.getCanvasData();
      this.cropBoxData = this.$refs.cropper.getCropBoxData();
    },
    isCropperMoved() {
      const initCropBoxLeftPos = this.cropBoxData.left;
      const initCropBoxTopPos = this.cropBoxData.top;
      const initCropBoxWidth = this.cropBoxData.width;
      const initCropBoxHeight = this.cropBoxData.height;

      const currCropBox = this.$refs.cropper.getCropBoxData();

      if (
        currCropBox.left !== initCropBoxLeftPos ||
        currCropBox.top !== initCropBoxTopPos ||
        currCropBox.width !== initCropBoxWidth ||
        currCropBox.height !== initCropBoxHeight
      ) {
        return true;
      }
      return false;
    },
    isCanvasMoved() {
      const initCanvasHeight = this.canvasData.height;
      const initCanvasLeft = this.canvasData.left;
      const initCanvasNaturalHeight = this.canvasData.naturalHeight;
      const initCanvasNaturalWidth = this.canvasData.naturalWidth;
      const initCanvasTop = this.canvasData.top;
      const initCanvasWidth = this.canvasData.width;

      const currCanvas = this.$refs.cropper.getCanvasData();

      if (
        initCanvasHeight !== currCanvas.height ||
        initCanvasLeft !== currCanvas.left ||
        initCanvasNaturalHeight !== currCanvas.naturalHeight ||
        initCanvasNaturalWidth !== currCanvas.naturalWidth ||
        initCanvasTop !== currCanvas.top ||
        initCanvasWidth !== currCanvas.width
      ) {
        return true;
      }
      return false;
    },
    showDiscardContent() {
      if (
        this.sliderValue > 0 ||
        this.profileImage !== this.profileImageMainPage ||
        this.removingProfilePicture === true ||
        (this.$refs.cropper && this.isCropperMoved() === true) ||
        (this.$refs.cropper && this.isCanvasMoved() === true)
      ) {
        this.discardConfirmationDialog = true;
      } else {
        this.profilePictureDialog = false;
        this.removingProfilePicture = false;
        this.error = '';
        if (this.$refs.cropper) {
          this.$refs.cropper.reset();
        }
        this.componentKey++;
      }
    },
    removeProfilePic() {
      this.cropFileName = null;
      this.cropFileType = null;
      this.croppedImage = '/assets/img/user-no-pic.svg';
      this.updatingProfilePicture = true;
      const userdata = {
        profileImage: this.cropFileName,
      };
      this.setCurrentUser({
        ...this.currentUser,
        user: {
          ...this.currentUser.user,
          language: this.selectedLanguage.code,
        },
      });
      usersApi.methods
        .updateUserProfile(userdata)
        .then(async (updatedUserDetail) => {
          this.updatingProfilePicture = false;
          this.showChangesSaved = true;
          // to show changes saved text after update is success
          setTimeout(() => {
            const userInfo = this.currentUser;
            userInfo.user = updatedUserDetail;
            this.setCurrentUser(userInfo);
            this.getProfileImage();
            EventBus.$emit('PROFILE_IMAGE', userdata);
          }, 2000);
        })
        .catch((err) => console.log(err));
      AnalyticsHandler.addedProfilePicture(this.currentUser, '');
    },
    handleRemoveProfilePicture() {
      this.error = '';
      this.removingProfilePicture = true;
    },
    handleDiscardConfirmationYes(removingProfilePicture) {
      this.sliderValue = 0;
      this.discardConfirmationDialog = false;
      this.profilePictureDialog = false;
      if (removingProfilePicture === true) {
        this.removingProfilePicture = false;
      }
      this.profileImage = this.profileImageMainPage;
      this.error = '';
      if (this.$refs.cropper) {
        this.$refs.cropper.reset();
      }
      this.componentKey++;
      this.getProfileImage();
    },
    handleDiscardConfirmationNo() {
      this.error = '';
      this.discardConfirmationDialog = false;
    },
    handleEditPhotoClick() {
      this.profilePictureDialog = true;
      trackProfileEvents.profileBasicEditPhoto(this.currentUser);
    },
    zoomOut() {
      if (this.sliderValue > 0) {
        this.sliderValue = this.sliderValue - 10 || 0;
      }
    },
    zoomIn() {
      if (this.sliderValue < 100) {
        this.sliderValue = this.sliderValue + 10 || 100;
      }
    },
    handleFileExplorerOpen() {
      this.$refs.uploader.click();
    },
    returnMMIEtype(header) {
      switch (header) {
        case '89504e47':
          return 'png';
        case 'ffd8ffe0':
        case 'ffd8ffe1':
        case 'ffd8ffe2':
        case 'ffd8ffe3':
        case 'ffd8ffe8':
          return 'jpeg';
        // case '00018':
        //   return 'heic';
        default:
          return 'unknown';
      }
    },
    handleSetCropperFile(event) {
      const input = event.target;
      if (input.files && input.files[0]) {
        const bufferFileReader = new FileReader();
        bufferFileReader.onload = (e) => {
          const arr = new Uint8Array(e.target.result).subarray(0, 4);
          let header = '';
          for (let i = 0; i < arr.length; i++) {
            header += arr[i].toString(16);
          }
          if (
            this.checkFileTypeAndSize(
              input.files[0],
              this.returnMMIEtype(header),
            ) === true
          ) {
            const reader = new FileReader();
            reader.onload = (evn) => {
              this.croppedImage = evn.target.result;
              if (this.$refs.cropper) {
                this.$refs.cropper.replace(evn.target.result);
              }
              this.profileImage = evn.target.result;
              this.cropFileName = input.files[0].name;
              this.cropFileType = input.files[0].type.split('/').pop();
              if (this.removingProfilePicture === true) {
                this.removingProfilePicture = false;
              }
            };
            reader.readAsDataURL(input.files[0]);
            this.sliderValue = 0;
            if (this.$refs.cropper) {
              this.$refs.cropper.reset();
            }
            this.error = '';
          }
        };
        bufferFileReader.readAsArrayBuffer(input.files[0]);
      }
    },
    checkFileTypeAndSize(file, mimeType) {
      if (['jpg', 'jpeg', 'png'].indexOf(file.type.split('/')[1]) === -1) {
        this.error = 'userUploadPrezentation.invalidFileFormat';
        return false;
      }
      if (mimeType === 'unknown') {
        this.error = 'warnings.imageCannotProcessed';
        return false;
      }
      if (Number((file.size / 1024 ** 2).toFixed(2)) > 4.0) {
        this.error = 'userUploadPrezentation.imageSizeExceedsLimit';
        return false;
      }
      return true;
    },
    async handleUploadCroppedProfileImage() {
      const canvas = this.$refs.cropper.getCroppedCanvas();
      if (canvas) {
        canvas.toBlob(async (blob) => {
          this.updatingProfilePicture = true;
          const identifier = uuidv4();
          const fileName = `${this.currentUser.user.cognitoID}_${identifier}.${this.cropFileType}`;
          const file = {
            file: blob,
            name: fileName,
            requestIdentifier: identifier,
          };
          console.log(`${identifier}.${this.cropFileType}`);
          await uploadChunks(file, 'profile-pic');
          await uploadOPFile(identifier, fileName, 'profile-pic')
            .then((result) => {
              const userdata = {
                profileImage: result?.data?.s3Prefix.replace('public/', ''),
              };
              this.updatingProfilePicture = false;
              this.showChangesSaved = true;
              usersApi.methods
                .updateUserProfile(userdata)
                .then(async (updatedUserDetail) => {
                  const userInfo = this.currentUser;
                  userInfo.user = updatedUserDetail;
                  await this.setCurrentUser(userInfo);
                  this.getProfileImage();
                  EventBus.$emit('PROFILE_IMAGE', userdata);
                });
            })
            .catch((err) => console.log(err));
          AnalyticsHandler.addedProfilePicture(this.currentUser, '');
          trackProfileEvents.profileBasicEditPhotoSave(this.currentUser);
        }, this.cropFileType);
      }
    },
    handleEmailChange() {
      this.user.email = this.user.email.toLowerCase();
    },
    getLevel() {
      switch (this.currentUser.user.role) {
        case 'SuperAdmin':
          return 'Prezent Administrator';
        case 'Admin':
          return 'Administrator';
        case 'User':
          return 'User';
        default:
          return 'Trial User';
      }
    },
    getCompanyName() {
      if (this.currentUser.company.displayName)
        return (
          this.currentUser.company.displayName.charAt(0).toUpperCase() +
          this.currentUser.company.displayName.slice(1)
        );
      if (this.currentUser.company.name) {
        return (
          this.currentUser.company.name.charAt(0).toUpperCase() +
          this.currentUser.company.name.slice(1)
        );
      }
      return '';
    },

    handleSignOut() {
      clearCachedUser();
      this.resetState();
      Auth.signOut();
    },

    handleSaveProfile() {
      if (!this.isSaved) {
        if (
          !isEmojiExist(this.user.firstName) &&
          !isEmojiExist(this.user.lastName) &&
          !isEmojiExist(this.user.jobTitle) &&
          !isEmojiExist(this.user.jobFunction) &&
          !isEmojiExist(this.user.industry) &&
          !isEmojiExist(this.user.subDivision)
        ) {
          const { id, ...userData } = this.user;
          console.log('user-->', this.user);
          usersApi.methods
            .updateUserProfile(userData)
            .then((value) => {
              if (this.currentUser.user?.jobTitle !== value?.jobTitle) {
                trackProfileEvents.profileBasicJobtitleChanged(
                  this.currentUser,
                );
              }
              if (this.currentUser.user?.industry !== value?.industry) {
                trackProfileEvents.profileBasicIndustryChanged(
                  this.currentUser,
                );
              }
              if (this.currentUser.user?.jobFunction !== value?.jobFunction) {
                trackProfileEvents.profileBasicJobfuntionChanged(
                  this.currentUser,
                );
              }
              const userInfo = this.currentUser;
              userInfo.user = value;
              this.setCurrentUser(userInfo);
              this.initialize();
              this.profilekey++;
              this.isSaved = !this.isSaved;
              this.isReadOnly = !this.isReadOnly;
              trackProfileEvents.profileBasicSaveProfile(this.currentUser);
            })
            .catch((err) => console.log(err));
        }
      } else {
        trackProfileEvents.profileBasicEditProfile(this.currentUser);
        this.isSaved = !this.isSaved;
        this.isReadOnly = !this.isReadOnly;
      }
    },

    filterByKeyValue(array, filters) {
      const filterKeys = Object.keys(filters);
      return array.filter((item) =>
        filterKeys.every((key) => filters[key] === item[key]),
      );
    },

    findAndPush(array, itemToCheck) {
      if (array.length) {
        const itemExists = array.find((x) => x?.name === itemToCheck);
        if (!itemExists && itemToCheck) {
          array.push({
            name: itemToCheck,
            imagePath: null,
          });
        }
      }
    },

    jobTitleValue(event) {
      this.user.jobTitle = event.target.value;
    },

    jobFunctionValue(event) {
      this.user.jobFunction = event.target.value;
    },

    async initialize() {
      if (this.currentUser) {
        this.loadingCompanyInfo = false;
        this.user.id = this.currentUser.user.id;
        this.user.firstName = this.currentUser.user.firstName;
        this.user.lastName = this.currentUser.user.lastName;
        this.user.companyID = this.currentUser.companyID;
        this.user.companyName = this.getCompanyName();
        this.user.jobTitle = this.currentUser.user.jobTitle;
        this.user.jobFunction = this.currentUser.user.jobFunction;
        this.user.industry = this.currentUser.user.industry;
        this.user.subDivision = this.currentUser.user.subDivision;
        this.user.email = this.currentUser.user.id;
        this.user.language = this.currentUser?.user?.language || 'en';
        this.user.defaultTheme = this.currentUser.theme.code
          ? this.currentUser.theme.code
          : '';

        this.selectedLanguage = this.localizationOptions.find(
          (item) => item.code === this.user.language,
        );
      }
      try {
        const fingerPrintAdditionalData = await getFingerPrintAdditionalData();
        console.log('fingerPrintAdditionalData', fingerPrintAdditionalData);
        this.setFingerPrintAdditionalDetails(fingerPrintAdditionalData);
      } catch (err) {
        console.log(err);
      }
      this.functions = this.fingerPrintAdditionalData.functions;
      this.industries = this.fingerPrintAdditionalData.industries;
      this.jobTitles = this.fingerPrintAdditionalData.jobTitle;
      this.isDataLoading = false;
      this.findAndPush(this.functions, this.user.jobFunction);
      this.findAndPush(this.industries, this.user.industry);
      this.findAndPush(this.jobTitles, this.user.jobTitle);
      this.getProfileImage();
    },

    handleChangePassword() {
      this.$modal.show(
        ChangePasswordDetail,
        {
          username: this.currentUser.user.id,
          currentUser: this.currentUser,
        },
        {
          name: 'ChangePasswordDetail',
          width: '528px',
          height: '444px',
        },
      );
      trackProfileEvents.profileBasicChangePassword(this.currentUser);
    },
    getDataUrl(url, callback) {
      const xhr = new XMLHttpRequest();
      xhr.onload = () => {
        const reader = new FileReader();
        reader.onloadend = () => {
          callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
    },
    getProfileImage() {
      if (this.currentUser && this.currentUser.user.profileImage) {
        getFileURL(
          null,
          `public/${this.currentUser.user.profileImage}`,
          null,
          'storage',
        )
          .then((s3obj) => {
            const url = new URL(s3obj);
            const fileName = url.pathname;
            this.cropFileName = fileName;
            this.cropFileType = fileName.split('.').pop();
            this.lastUpdatedFileName = fileName;
            this.lastUpdatedFileType = fileName.split('.').pop();
            this.profileImage = s3obj;
            this.profileImageMainPage = s3obj;
            this.getDataUrl(s3obj, (uri) => {
              this.croppedImage = uri;
              if (this.$refs.cropper) {
                this.$refs.cropper.replace(uri);
              }
            });
            this.componentKey++;
            this.showChangesSaved = false;
            this.profilePictureDialog = false;
            this.discardConfirmationDialog = false;
            this.sliderValue = 0;
            this.removingProfilePicture = false;
            this.error = '';
            this.cropBoxData = null;
            this.canvasData = null;
          })
          .catch((err) => {
            console.log(err);
            this.profileImage = '/assets/img/user-no-pic.svg';
            this.profileImageMainPage = '/assets/img/user-no-pic.svg';
            this.componentKey++;
            this.showChangesSaved = false;
            this.profilePictureDialog = false;
            this.discardConfirmationDialog = false;
            this.sliderValue = 0;
            this.removingProfilePicture = false;
            this.error = '';
            this.cropBoxData = null;
            this.canvasData = null;
          });
      } else {
        this.lastUpdatedFileName = '';
        this.lastUpdatedFileType = '';
        this.cropFileName = '';
        this.cropFileType = '';
        this.error = '';
        this.profileImage = '/assets/img/user-no-pic.svg';
        this.profileImageMainPage = '/assets/img/user-no-pic.svg';
        this.componentKey++;
        this.showChangesSaved = false;
        this.profilePictureDialog = false;
        this.discardConfirmationDialog = false;
        this.sliderValue = 0;
        this.removingProfilePicture = false;
        this.cropBoxData = null;
        this.canvasData = null;
      }
    },
  },
  beforeMount() {
    this.initialize();
  },
};
</script>
<style lang="scss" scoped>
::v-deep .tab-content {
  overflow-y: auto;
  &.basic-content {
    display: flex;
    align-items: flex-start;
    justify-content: left;
    margin-top: 30px;
    max-width: 80%;
    overflow-x: hidden;
    .left-panel {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      margin-right: 10px;

      .access-label-title {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
      }
      .access-label-desc {
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        margin-bottom: 21px;
      }

      .profile-image-input {
        width: 100%;
        left: 5px !important;
        font-size: 16px;
        text-transform: capitalize;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: $zen-blue-default;
        height: 32px;
        padding-top: 5px;
      }

      .profile-image-wrapper {
        border-radius: 50%;
        border: 7px solid white;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        overflow: hidden;
        .profileImageOrAvatar {
          .profile-image-upload {
            position: relative;
            height: 140px;
            width: 140px;
            .v-image {
              display: block;
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
            .overlay {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              height: 100%;
              width: 100%;
              opacity: 0;
              transition: 0.3s ease;
              background-color: grey;
              border-radius: 50%;
              .v-icon {
                color: white;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                text-align: center;
                opacity: 1;
              }
            }
            &:hover .overlay {
              opacity: 0.6;
              cursor: pointer;
            }
          }
          .profile-user-avatar-wrapper {
            position: relative;
            height: 140px;
            min-width: 140px;
            width: 140px;
            .profile-user-avatar {
              display: block;
              width: 100%;
              height: 100%;
              span {
                top: 30%;
                left: 24%;
                position: absolute;
              }
            }
            .overlay {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              height: 100%;
              width: 100%;
              opacity: 0;
              transition: 0.3s ease;
              background-color: grey;
              border-radius: 50%;
              .v-icon {
                color: white;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                text-align: center;
                opacity: 1;
              }
            }
            &:hover .overlay {
              opacity: 0.6;
              cursor: pointer;
            }
          }
        }
      }

      .edit-profile-btn {
        color: $zen-blue-default;
        text-transform: capitalize;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        &:hover {
          text-decoration: underline;
        }
      }

      .log-out-button {
        width: 104px;
        height: 32px;
        border-radius: 16px;
        border: solid 1px $zen-blue-default;
        background: transparent;
        color: $zen-blue-default;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        text-transform: capitalize;
      }

      .v-input {
        width: 80%;
      }
    }

    .right-panel {
      margin-right: -100px;
      .info-title {
        text-align: left;
      }
      .info-wrapper {
        font-size: 20px;
        font-weight: bold;
        .editProfileBtnContainer {
          display: flex;
          justify-content: left;
          flex-direction: row;
          padding: 20px 0px 0px 0px;
          .edit-profile-btn {
            background-color: $zen-blue-default;
            color: #ffffff;
            text-transform: none;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            box-shadow: none;
            border-radius: 20px;
          }
        }
      }
      .compSwitch {
        display: flex;
        flex-direction: row;
        justify-content: left;
        gap: 24px;
        padding: 0px 0px 24px 0px;
        .profileInfoBtn,
        .dwnldSettBtn {
          min-width: 95px;
          text-transform: none;
          letter-spacing: normal;
          box-shadow: none;
          border-radius: 8px;
          background-color: rgb(255 255 255);
          color: rgb(52 58 62);
          border: 1px solid rgb(52 58 62);
          &:before {
            background-color: rgb(255 255 255);
          }
        }
        .btnActive {
          border: none;
          background-color: rgb(224 246 251);
        }
        .btnDisabled {
          border: none;
        }
      }
      .download-setting-wrapper {
      }
    }
  }
}

.tab-footer,
.tab-footer-dwnldSett {
  color: grey;
  a {
    color: inherit;
  }
  p {
    margin: 15px;
  }
}
.tab-footer {
  justify-content: right;
  margin: 30px;
  overflow-x: hidden;
}
.tab-footer-dwnldSett {
  position: fixed;
  bottom: 30px;
  right: 100px;
}
.profileImageUploaderCard {
  padding: 20px;
  .v-card__title {
    padding: 0;
    display: flex;
    justify-content: space-between;
    .dialogHeading {
      font-size: 20px;
      font-weight: bold;
    }
    // .v-icon {
    //   margin-left: 78%;
    // }
  }
  .v-card__text {
    padding: 10px 0px 10px 0px;
    .discardMessage {
      font-size: 16px;
      font-style: 'Lato';
      color: black;
    }
    .uploaderElements {
      .errorContainer {
        width: 90%;
        margin: 8px auto;
        text-align: center;
        height: 25px;
        .errorIcon {
          margin: 0px 8px;
          width: 32px;
          height: 28px;
        }
        .errorMessage {
          font-size: 14px;
          font-style: 'Lato';
        }
      }
      .dialogAvatarWhenProfilePicIsPresent {
        .removalContainer {
          .avatarContainer {
            margin: 0 39% 0 39%;
            width: 140px;
            height: 140px;
            .v-avatar {
              width: 100%;
              height: 100;
              border-radius: 50% !important;
            }
          }
          .uploadLinkContainer {
            .linkWithIcon {
              width: 32%;
              margin: 16px auto;
              text-align: center;
              color: rgb(88, 168, 222);
              font-size: 16px;
              font-style: 'Lato';
              cursor: pointer;
              .v-icon {
                color: rgb(88, 168, 222);
                margin: 0px 8px;
              }
            }
          }
        }
        .cropZoomAndActions {
          ::v-deep .cropper-crop-box {
            border-radius: 50% !important;
          }

          ::v-deep .cropper-view-box {
            border-radius: 50% !important;
          }

          ::v-deep .cropper-view-box {
            box-shadow: 0 0 0 1px #fff;
            outline: 0;
          }

          ::v-deep .cropper-face {
            background-color: inherit !important;
          }

          .cropper-dashed,
          .cropper-point.point-se,
          .cropper-point.point-sw,
          .cropper-point.point-nw,
          .cropper-point.point-ne,
          .cropper-line {
            display: none !important;
          }

          :v-deep .cropper-view-box {
            outline: inherit !important;
          }
          .sliderContainer {
            display: flex;
            padding: 8px 0px 8px 0px;
            .v-btn {
              padding: 0px 8px;
            }
            ::v-deep .v-input__slot {
              margin: 0;
            }
            ::v-deep .v-messages {
              display: none;
            }
          }
          .cropperActionsContainer {
            display: flex;
            height: 50px;
            .swapIcon,
            .removeIcon {
              color: rgb(88, 168, 222);
              width: 50%;
              text-align: center;
              padding: 10px 0px;
              div {
                width: 40%;
                margin: 0 auto;
                cursor: pointer;
                .v-icon {
                  color: rgb(88, 168, 222);
                  margin: 0px 4px 4px 0px;
                }
                span {
                  font-size: 1.1rem;
                  letter-spacing: normal;
                  display: inline-block;
                }
              }
            }
          }
        }
      }
      .dialogAvatarWhenProfilePicNotPresent {
        margin: 0 auto;
        width: 140px;
        height: 140px;
        .v-avatar {
          width: 100%;
          height: 100;
          border-radius: 50% !important;
        }
      }
      .suggestionContainer {
        width: 75%;
        margin: 10px auto 10px auto;
      }
    }
  }
  .v-card__actions {
    padding: 16px 0px 8px 8px;
    .discardActionsContainer {
      width: 27%;
      display: flex;
      justify-content: space-between;
      .yesButton {
        color: rgb(88, 168, 222);
        background-color: #fff;
        border-radius: 20px;
        text-transform: none;
        font-size: 1.1rem;
        letter-spacing: normal;
        padding: 10px 20px;
        font-weight: bold;
        border: 1.25px solid rgb(88, 168, 222);
      }
      .noButton {
        background-color: rgb(88, 168, 222);
        color: #fff;
        border-radius: 20px;
        text-transform: none;
        font-size: 1.1rem;
        letter-spacing: normal;
        padding: 10px 20px;
        font-weight: bold;
      }
    }
    .updatePicActionsContainer {
      .saveChangesButtonWrapper,
      .addPhotoButtonWrapper {
        .v-btn {
          background-color: rgb(88, 168, 222);
          color: #fff;
          border-radius: 20px;
          text-transform: none;
          font-size: 1.1rem;
          letter-spacing: normal;
          padding: 10px 20px;
          // font-weight: bold;
        }
      }
    }
  }
}
</style>
