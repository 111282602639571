<template>
  <div class="title-row">
    <div class="title-wrapper">
      <template v-if="renameField">
        <div class="edit-field-container">
          <div class="nameError" v-if="nameLimit">
            {{ 'Name cannot be more than 85 characters' }}
          </div>
          <v-text-field
            hide-details
            @focus="handleRenameFocus()"
            @blur="handleRenameBlur()"
            @keydown.esc="handleRenameBlur()"
            autofocus
            :label="prezentationName"
            solo
            v-model="prezentationName"
          >
            <template #append>
              <v-btn
                class="rename-button"
                rounded
                @click="renameBtn"
                :disabled="nameLimit || prezentationName.length === 0"
              >
                <v-icon> mdi-check </v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </div>
      </template>
      <v-tooltip v-else top content-class="tooltip-content">
        <template v-slot:activator="{ on, attrs }">
          <h2 class="op-name" v-bind="attrs" v-on="on">
            {{ prezentationName }}
          </h2>
        </template>
        <span> {{ prezentationName }}</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OPName',
  watch: {
    editName(val) {
      this.renameField = val;
    },
  },
  computed: {
    nameLimit() {
      return this.prezentationName.length > 85;
    },
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    editName: {
      type: Boolean,
      required: true,
    },
    // rename: {
    //   type: Function,
    //   default: () => {},
    // },
  },
  data() {
    return {
      oldNameValue: '',
      prezentationName: '',
      renameField: false,
    };
  },
  mounted() {
    this.renameField = this.editName;
    this.prezentationName = this.name;
  },
  methods: {
    handleRenameBlur() {
      this.prezentationName = this.oldNameValue;
      this.renameField = false;
      this.$emit('onRename', null);
    },
    handleRenameFocus() {
      this.oldNameValue = this.prezentationName;
    },
    renameBtn() {
      const prezentationName = this.prezentationName.trim();
      this.oldNameValue = prezentationName;
      if (!prezentationName) {
        this.renameField = false;
        this.prezentationName = this.oldNameValue;
        this.$emit('onRename', null);
      } else {
        this.$emit('onRename', this.prezentationName);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.title-row {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  width: 58%;

  .title-wrapper {
    display: flex;
    align-items: center;
    color: #000000;
    width: 100%;
    margin-bottom: 10px;
    .edit-field-container {
      width: 100%;
      .nameError {
        padding: 0 12px;
        color: red;
      }
      ::v-deep
        .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
        > .v-input__control
        > .v-input__slot {
        box-shadow: none;
        border-bottom: 1px solid black;
        padding-bottom: 1px;
      }
      ::v-deep .v-input {
        // width: 50%;
        .v-input__control {
          min-height: 20px !important;
          .v-input__slot {
            .v-text-field__slot {
              input {
                font-size: 20px;
                padding: 0px !important;
                font-family: 'Lato', sans-serif;
                font-weight: 500;
                letter-spacing: 0.0125em;
                word-break: break-all;
              }
            }
          }
        }
      }
    }
    .op-name {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}
</style>
