<template>
  <div class="practice5-wrapper" id="practice5">
    <v-btn
      class="practice5-wrapper__back-btn"
      @click="handleBack()"
      icon
      color="#21a7e0"
    >
      <v-icon>mdi-arrow-left</v-icon>
      {{ $t('common.back') }}
    </v-btn>
    <div class="practice5-title">{{ $t('learn.course.practice') }}</div>
    <div class="practice5-description">
      {{ $t('learn.novice.practice5Description') }}
    </div>
    <div class="practice5-content">
      <div v-if="isInitial" class="initial-wrapper">
        <div class="initial-title">
          {{ $t('learn.novice.imageTitle') }}
        </div>
        <div class="initial-image">
          <img src="/assets/img/learn/novice-practice-5.jpg" alt />
        </div>
        <v-btn
          class="go-to-questions"
          rounded
          width="249"
          color="#21a7e0"
          height="50"
          @click="handleGoTo"
        >
          {{ $t('learn.novice.goToQuestions') }}
        </v-btn>
      </div>
      <div v-else class="practice5-questions">
        <div class="image-preview-wrapper">
          <div class="image-preview-title">
            {{ $t('learn.novice.imageTitle') }}
          </div>
          <div class="image-preview-content" @click="handlePreviewDialog">
            <img
              src="/assets/img/learn/novice-practice-5.jpg"
              alt
              width="391px"
              height="226px"
            />
            <img class="preview-icon" src="/assets/img/learn/preview.svg" alt />
          </div>
        </div>
        <div class="questions-wrapper">
          <div class="practice5-content-title">
            {{ $t('learn.novice.question') }} {{ currentIndex + 1 }}/2
          </div>
          <div v-if="currentIndex === 0" class="practice5-content-description">
            {{ $t('learn.novice.practice5Description2') }}
          </div>
          <div v-else class="practice5-content-description">
            {{ $t('learn.novice.practice5Description3') }}
          </div>
          <div class="practice5-content-explain">
            <img src="/assets/img/learn/icons-drag-drop-24-px.svg" alt />
            <div class="explain-content">
              {{ $t('learn.novice.dragAndDrop') }}
            </div>
          </div>
          <template v-if="currentExerciseData">
            <drag-and-drop
              :exercise-data="currentExerciseData"
              :update-exercise-index="handleIndex"
              :current-index="currentIndex"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import go from 'gojs';
import DragAndDrop from './components/DragAndDrop.vue';
import PreviewImageDialog from './components/PreviewImageDialog.vue';
import NoviceBadgeDialog from '../NoviceBadgeDialog';
import learnApi from '../../../../../API/learn-api';

export default {
  name: 'Practice5',
  components: {
    DragAndDrop,
  },
  data() {
    return {
      learnData: null,
      levelInfo: null,
      currentIndex: 0,
      isInitial: true,
      item: null,
    };
  },
  computed: {
    ...mapState('users', ['currentUser']),
    ...mapState('learndata', ['userModuleItemStatuss']),
    currentExerciseData() {
      const exerciseData = [
        {
          nodeDataArray: [
            {
              key: 1,
              name: '',
              message1: '',
              message2: '',
              target: 'Market Value Trends',
              color: '#D3D3D3',
              loc: new go.Point(160, 50),
              strokecolor: 'white',
            },
            {
              key: 2,
              name: '',
              message1: '',
              message2: '',
              target: 'Tesla exceeds market value of others',
              color: '#D3D3D3',
              loc: new go.Point(450, 50),
              strokecolor: 'white',
            },
            {
              key: 3,
              name: '',
              message1: '',
              message2: '',
              target: 'Are we in a Tesla hyper-bubble?',
              color: '#D3D3D3',
              loc: new go.Point(730, 50),
              strokecolor: 'white',
            },
          ],
          myPaletteArray: [
            {
              key: 1,
              name: 'Market Value Trends',
              message1:
                'This is a neutral title. Neutral titles work best in the early stages of the problem-solving process.',
              message2:
                'Such titles do a good job of summarizing the content, without influencing our audience in any specific way.',
              color: 'white',
              loc: new go.Point(30, 30),
            },
            {
              key: 2,
              name: 'Tesla exceeds market value of others',
              message1:
                'This is a factual title. Factual titles work best in the middle stages of the problem-solving process.',
              message2:
                'Such titles deliver the main message of the slide, thus aiding the group to make their own conclusions or ask more pertinent questions.',
              color: 'white',
              loc: new go.Point(320, 30),
            },
            {
              key: 3,
              name: 'Are we in a Tesla hyper-bubble?',
              message1:
                'This is an implication title. Implication titles work best in the final stages of the problem-solving process.',
              message2:
                'Such titles go beyond the obvious facts on the slide, helping the audience critically analyze the impact or implication of the content they see.',
              color: 'white',
              loc: new go.Point(600, 30),
            },
          ],
        },
        {
          nodeDataArray: [
            {
              key: 1,
              name: '',
              target: 'We believe Tesla is a fast-growing company',
              color: 'white',
              loc: new go.Point(70, 50),
            },
            {
              key: 2,
              name: '',
              target: 'Market Value Trends',
              color: 'white',
              loc: new go.Point(600, 50),
            },
          ],
          myPaletteArray: [
            {
              key: 1,
              name: 'We believe Tesla is a fast-growing company',
              color: 'white',
              loc: new go.Point(70, 30),
            },
            {
              key: 2,
              name: 'Market Value Trends',
              color: 'white',
              loc: new go.Point(600, 30),
            },
          ],
        },
      ];
      return exerciseData[this.currentIndex];
    },
  },
  methods: {
    handleBack() {
      this.$router.push({
        name: 'course.detail',
        params: { id: this.$route.params.courseId },
      });
    },
    handlePreviewDialog() {
      this.$modal.show(
        PreviewImageDialog,
        {},
        {
          name: 'PreviewImageDialog',
          width: '809px',
          height: '465px',
        },
      );
    },
    handleGoTo() {
      this.isInitial = false;
    },
    // ...mapActions('learndata', ['setUserLearnIndex']),
    async handleIndex() {
      if (this.currentIndex === 1) {
        const updateStatus = {
          asset_id: this.$route.params.assetId,
          status: 100,
        };
        await learnApi.methods.updateAssetUserStatus(updateStatus).then(
          (resp) => {
            if (resp) {
              this.$router.push({
                name: 'course.detail',
                params: { id: this.$route.params.courseId },
              });
            }
          },
          (error) => {
            console.log('error while updating the asset status --', error);
            this.$router.push({
              name: 'course.detail',
              params: { id: this.$route.params.courseId },
            });
          },
        );
      } else {
        this.currentIndex += 1;
      }
    },
    goToInitial() {
      this.$router.push('/home/learn/continue', () => {});
    },
    showBadgeDialog() {
      this.$modal.show(
        NoviceBadgeDialog,
        {
          goToInitial: this.goToInitial,
        },
        {
          name: 'NoviceBadgeDialog',
          width: '353px',
          height: '394px',
        },
      );
    },
  },
  mounted() {
    if (this.$route.query.index) {
      this.item = this.moduleItems.find(
        (item) => item.id === this.$route.query.index,
      );
    }
    this.currentIndex = 0;
  },
};
</script>

<style lang="scss" scoped>
.practice5-wrapper {
  margin-top: 30px;
  position: relative;
  height: 100%;
  margin-left: 30px;
  &__back-btn {
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    text-transform: unset;
  }

  .practice5-title {
    font-size: 24px;
    font-weight: bold;
    text-align: left;
    padding-left: 20px;
  }

  .practice5-description {
    font-size: 18px;
    text-align: left;
    padding-bottom: 20px;
    border-bottom: 1px solid #d8d8d8;
    padding-left: 20px;
  }

  .initial-wrapper {
    width: fit-content;
    margin: auto;

    .initial-title {
      text-align: left;
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000;
    }

    .initial-image {
      margin-bottom: 50px;
    }

    button {
      color: white;
      font-size: 24px;
      font-weight: bold;
      text-transform: none;
    }
  }

  .practice5-content {
    height: 100%;
    margin-top: 20px;
  }

  .practice5-questions {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;

    .questions-wrapper {
      padding: 27px;

      .practice5-content-title {
        font-size: 24px;
        font-weight: bold;
        text-align: left;
        margin-bottom: 15px;
      }

      .practice5-content-description {
        font-size: 20px;
        margin-bottom: 15px;
        text-align: left;
      }

      .practice5-content-explain {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 30px;

        .explain-content {
          margin-left: 10px;
          font-size: 20px;
          font-weight: bold;
          font-stretch: normal;
          font-style: italic;
        }
      }
    }

    .image-preview-wrapper {
      width: fit-content;
      height: 100%;
      border-right: 1px solid #d8d8d8;

      .image-preview-title {
        margin-bottom: 10px;
        padding: 27px;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #000;
      }

      .image-preview-content {
        position: relative;
        &:hover {
          img {
            filter: brightness(0.8);
            border-radius: 20px;
            overflow: hidden;
          }

          .preview-icon {
            opacity: 1;
          }
        }
        .preview-icon {
          opacity: 0;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
    .initial-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
}
</style>
