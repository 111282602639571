<template>
  <div class="tss-modal">
    <v-btn class="close-btn" icon @click="close()">
      <v-icon dark>mdi-close</v-icon>
    </v-btn>
    <div class="tss-modal__img">
      <UploadSuccessIcon />
    </div>
    <h4 class="tss-modal__title">{{ $t('upload.slidesUploaded') }}</h4>
    <p class="tss-modal__text">
      {{ $t('upload.accessToSlides') }}
    </p>
    <p class="tss-modal__text">
      <v-btn
        class="tss-modal__btn ma-2 btn"
        rounded
        color="primary"
        @click="close()"
      >
        {{ $t('upload.goToSlides') }}
      </v-btn>
    </p>
  </div>
</template>

<script>
import UploadSuccessIcon from './UploadSuccessIcon.vue';

export default {
  name: 'TransferSlidesSuccessModal',
  components: { UploadSuccessIcon },
  data() {
    return {};
  },
  methods: {
    onUploadClick() {
      this.close();
      this.$router.push('/home/upload');
    },
    onTransferClick() {
      this.close();
      this.$router.push(`/home/upload/${this.prezentationId}/transfer`);
    },

    close() {
      this.$modal.hide('TransferSlidesSuccessModal');
    },
  },
};
</script>

<style lang="scss" scoped>
.tss-modal {
  text-align: center;
  padding: 32px 22px;
  font-size: 16px;

  &__img {
    margin-bottom: 16px;
  }
  &__title {
    margin-bottom: 7px;
  }
  &__text {
    margin-bottom: 12px;
  }

  &__btn {
    &.v-btn--has-bg {
      background-color: #21a7e0 !important;
      color: #fff;
    }
    color: #21a7e0;
    border-color: #21a7e0 !important;
    font-family: Lato;
    font-size: 16px;
    font-weight: bold;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    text-transform: none !important;
  }
}
.close-btn {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 1;
}
</style>
