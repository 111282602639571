<template>
  <div
    :class="`idea-chip idea-${index}-dragarea--auto`"
    @mousedown="click(data)"
  >
    <div class="chip-title">
      <div :class="`drag-icon ${isGalleryIdea ? 'show' : ''}`">
        <v-icon v-if="isDragable" dense> mdi-dots-vertical </v-icon>
        <v-icon v-if="isDragable" dense> mdi-dots-vertical </v-icon>
      </div>
      <v-tooltip bottom max-width="250px">
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on" class="title-text">
            {{ data.outline }}
          </div>
        </template>
        <span>
          <span class="tooltip-outline-title"> {{ data.outline }} </span><br />
          <span>
            {{ getDescription(data.outline) }}
          </span>
        </span>
      </v-tooltip>
    </div>
    <div class="actions" v-if="!isGalleryIdea">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" small @click="onDuplicate">
            mdi-content-copy
          </v-icon>
        </template>
        <span> {{ $t('common.duplicate') }} </span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" small @click="onDelete">
            mdi-delete-outline
          </v-icon>
        </template>
        <span> {{ $t('common.delete') }} </span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import { getTooltipForKeyword } from '../../../../common/KeywordTooltipMapping.js';

export default {
  props: {
    data: {
      type: Object,
      default: null,
    },
    click: {
      type: Function,
      default: () => false,
    },
    onDuplicate: {
      type: Function,
      default: () => false,
    },
    onDelete: {
      type: Function,
      default: () => false,
    },
    isGalleryIdea: {
      type: Boolean,
      default: false,
    },
    isDragable: {
      type: Boolean,
      default: true,
    },
    index: {
      type: Number,
      default: null,
    },
  },

  methods: {
    getDescription(keyword) {
      return getTooltipForKeyword(keyword);
    },
  },
};
</script>

<style lang="scss" scoped>
.idea-chip {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px 10px 10px;
  background: #ffffff;
  border: 1px solid #f2f2f2;
  box-shadow: 1px 3px 6px 1px rgb(0 0 0 / 20%);
  border-radius: 8px;
  margin-bottom: 15px;
  width: 100% !important;
  cursor: pointer;

  .drag-icon {
    display: flex !important;
    cursor: move;

    .v-icon {
      width: 5px;
      display: flex;
    }
  }

  .actions .v-icon {
    display: flex !important;
    // width: 14px;

    .v-icon {
      width: 5px;
    }
  }
  .drag-icon:not(.show),
  .actions .v-icon {
    display: none !important;
  }

  .chip-title {
    display: flex;
    align-items: center;
    width: 80% !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .v-icon {
      color: #a2a9b0;
    }

    .title-text {
      font-size: 16px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: black;
      padding: 0 5px;
    }
  }

  &:hover {
    background: #f5fcff;
    .section-header-text {
      color: #21a7e0;
    }
    .drag-icon,
    .actions .v-icon {
      display: flex !important;
    }
  }

  .actions {
    // min-width: 35px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 20% !important;
    .v-icon {
      color: #21a7e0;
      cursor: pointer;
    }

    .info-icon {
      color: gray;
    }

    .mdi-delete-outline {
      font-size: 18px !important;
    }
  }
}
.tooltip-outline-title {
  font-size: 16px !important;
  font-weight: bold;
}
</style>
