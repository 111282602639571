<template>
  <div class="profile-wrapper">
    <!-- <SubscriptionExpiredBanner
      v-if="currentUser.user.role.toLowerCase() !== 'trialuser'"
    /> -->
    <div class="tab-wrapper">
      <v-tabs
        color="#21a7e0"
        slider-size="7"
        centered
        fixed-tabs
        v-model="currentTab"
      >
        <v-tab
          class="vtab"
          href="#basics"
          @click="setTab('basics')"
          id="basics-tab"
          data-pendo-id="basics-tab"
        >
          <b>{{ $t('profile.basics') }}</b>
        </v-tab>
        <v-tab
          class="vtab"
          href="#fingerprint"
          @click="setTab('fingerprint')"
          :disabled="isTrialExpired"
          id="fingerprint-tab"
          data-pendo-id="fingerprint-tab"
        >
          <NewFeatureBadge
            module-name="sharetrialform"
            :feature-id="'sharetrialformprofile_id'"
            :offsetX="'-5'"
            :offsetY="'22'"
          >
            <b>{{ $t('profile.fingerprint') }}</b>
          </NewFeatureBadge>
        </v-tab>
        <v-tab
          class="vtab"
          href="#storylines"
          @click="setTab('storylines')"
          :disabled="isTrialExpired"
          id="storyline-tab"
          data-pendo-id="storyline-tab"
        >
          <NewFeatureBadge
            module-name="custom_storylines"
            :feature-id="'custom_storylines_id'"
            :offsetX="'-5'"
            :offsetY="'22'"
          >
            <b>{{ $t('profile.storylines') }}</b>
          </NewFeatureBadge>
        </v-tab>
        <v-tab
          class="vtab"
          href="#templates"
          @click="setTab('templates')"
          :disabled="isTrialExpired"
          id="templates-tab"
          data-pendo-id="templates-tab"
        >
          <b>{{ $t('profile.templates') }}</b>
        </v-tab>
        <v-tab
          class="vtab"
          href="#metrics"
          @click="setTab('metrics')"
          :disabled="isTrialExpired"
          id="metrics-tab"
          data-pendo-id="metrics-tab"
        >
          <b>{{ $t('profile.metrics') }}</b>
        </v-tab>
        <!-- <v-tab
          class="vtab"
          href="#wish"
          @click="setTab('wish')"
          :disabled="isTrialExpired"
          id="feature-requests-tab"
        >
          <b>Feature Requests</b>
        </v-tab> -->
        <v-tab
          v-if="isAdmin || isTrial"
          href="#admin"
          @click="setTab('admin')"
          class="vtab"
          id="admin-settings-tab"
          data-pendo-id="admin-settings-tab"
        >
          <NewFeatureBadge
            module-name="profile"
            :feature-id="'admin_settings_tab_id'"
            :offsetX="'0'"
            :offsetY="'0'"
            :size="'large'"
          >
            <b>{{ $t('profile.adminSettings') }}</b>
          </NewFeatureBadge>
        </v-tab>

        <v-tab-item value="basics">
          <Basics></Basics>
        </v-tab-item>
        <v-tab-item value="fingerprint" class="fingerprint-wrapper">
          <FingerprintTabContent
            :personalPrefs="personalPrefs"
            :selections="selections"
            :handleQuestion="handleQuestion"
            @handleCreateOwn="handleCreateOwn"
            @handleSavePreference="handleSavePreference"
          />
        </v-tab-item>
        <v-tab-item value="storylines">
          <StorylinesTabContent
            class="tab-content"
            :selected-tab="currentTab"
          ></StorylinesTabContent>
        </v-tab-item>
        <v-tab-item value="templates">
          <TemplateSelector
            class="tab-content"
            :selected-tab="currentTab"
          ></TemplateSelector>
        </v-tab-item>
        <v-tab-item value="metrics">
          <div class="tab-content metrics-content">
            <ImpactMetrics
              :adminStatsToggleRadio="adminStatsToggleRadio"
              :isNormalUser="isNormalUser"
            >
              <template slot="headerRight">
                <span class="adminStatsToggle" v-if="isNormalUser === false">
                  <v-radio-group
                    v-model="adminStatsToggleRadio"
                    @change="handleAdminUserToggle"
                    :hide-details="true"
                  >
                    <v-radio
                      :label="$t('fingerprint.exploreFingerprint.team')"
                      value="Team"
                    />
                    <v-radio
                      :label="$t('fingerprint.compareFingAud.me')"
                      value="Me"
                    />
                  </v-radio-group>
                </span>
              </template>
            </ImpactMetrics>
            <div
              class="bottom-level"
              data-pendo-id="profile-bottom-level"
              v-if="!isNormalUser && adminStatsToggleRadio === 'Team'"
            >
              <UsageStatistics />
              <ActiveUsersChart v-if="isActiveUserLineChartEnabled" />
              <!-- <OvernightServicesStats
                  v-if="getOPAccessLevel !== 'no_access'"
                  :teamFunds="Number(teamFundsAvailable)"
                /> -->
            </div>
            <div
              class="bottom-level normal-user-panel"
              data-pendo-id="profile-bottom-level"
              v-else
            >
              <div class="detailed">
                {{ $t('profile.myStatistics') }}
                <v-tooltip
                  right
                  max-width="250"
                  content-class="tooltip-content"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon class="icon-title" v-bind="attrs" v-on="on">
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span>
                    {{ $t('profile.detailedView') }}
                  </span>
                </v-tooltip>
              </div>
              <div class="firstRow">
                <div class="engagementContainer">
                  <div class="imgContainer">
                    <div class="imgContent">
                      <img src="/assets/img/engagement.svg" alt />
                    </div>
                    <div class="imgText">
                      <h2>{{ $t('profile.engagement') }}</h2>
                    </div>
                  </div>
                  <div class="valueContainer">
                    <div class="engaged">
                      <h1>{{ getUserEngagement() }}</h1>
                    </div>
                  </div>
                </div>
              </div>
              <div class="statsRow">
                <div class="statsContainer">
                  <div class="imgContainer">
                    <div class="imgContent">
                      <img src="/assets/img/create-illustration.svg" alt />
                    </div>
                    <div class="imgText">
                      <h2>{{ $t('profile.create') }}</h2>
                    </div>
                  </div>
                  <div class="valueContainer">
                    <div class="cardValueContainer">
                      <div class="cardValueFigure">
                        <h1>{{ getSearchCount }}</h1>
                      </div>
                      <div class="cardValueMetric">
                        {{ $t('profile.slidesSearched') }}
                      </div>
                    </div>
                    <div class="cardValueContainer">
                      <div class="cardValueFigure">
                        <h1>{{ slideDownloads }}</h1>
                      </div>
                      <div class="cardValueMetric">
                        {{ $t('profile.slidesDownloaded') }}
                      </div>
                    </div>
                    <div class="cardValueContainer" v-if="isGenerateEnabled">
                      <div class="cardValueFigure">
                        <h1>
                          {{
                            formatAmountWithCommas(
                              slidesGeneratedByUser.slidesGenerated,
                            )
                          }}
                          <NewFeatureBadge
                            module-name="generate"
                            :feature-id="'generate_id'"
                            size="medium"
                            :offsetX="'-40'"
                            :offsetY="'-80'"
                          >
                          </NewFeatureBadge>
                        </h1>
                      </div>
                      <div class="cardValueMetric">
                        {{ $t('profile.slidesGenerated') }}
                      </div>
                    </div>
                    <div class="cardValueContainer">
                      <div class="cardValueFigure">
                        <h1>
                          {{
                            formatAmountWithCommas(
                              prezentationsBuiltAndUploaded.prezentationsBuilt,
                            )
                          }}
                        </h1>
                      </div>
                      <div class="cardValueMetric">
                        {{ $t('profile.prezentationsBuilt') }}
                      </div>
                    </div>
                    <div
                      class="cardValueContainer"
                      v-if="
                        (getUploadFeature &&
                          getUploadFeature.enabled &&
                          getUploadFeature.access_level &&
                          getUploadFeature.access_level === 'full_access') ||
                        (getUploadFeature &&
                          getUploadFeature.access_level &&
                          (getUploadFeature.access_level === 'limited_access' ||
                            getUploadFeature.access_level ===
                              'restricted_access'))
                      "
                    >
                      <div class="cardValueFigure">
                        <h1>
                          {{
                            formatAmountWithCommas(
                              slidesUploadedByUser.slidesUploaded,
                            )
                          }}
                          <NewFeatureBadge
                            module-name="upload"
                            :feature-id="'upload_landing_id'"
                            size="medium"
                            :offsetX="'-40'"
                            :offsetY="'-80'"
                          >
                          </NewFeatureBadge>
                        </h1>
                      </div>
                      <div class="cardValueMetric">
                        {{ $t('profile.slidesUploaded') }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="statsRow"
                v-if="
                  isSynthesisEnabled ||
                  isRedesignEnabled ||
                  isComplyEnabledWithAccess
                "
              >
                <div class="statsContainer">
                  <div class="imgContainer">
                    <div class="imgContent">
                      <img
                        src="/assets/img/profile-transform-category-image.svg"
                        alt
                      />
                    </div>
                    <div class="imgText">
                      <h2>{{ $t('profile.transform') }}</h2>
                    </div>
                  </div>
                  <div class="valueContainer">
                    <div class="cardValueContainer" v-if="isRedesignEnabled">
                      <div class="cardValueFigure">
                        <h1>
                          {{
                            formatAmountWithCommas(
                              slidesRedesignedByUser.slidesRedesigned,
                            )
                          }}
                        </h1>
                      </div>
                      <div class="cardValueMetric">
                        <NewFeatureBadge
                          module-name="redesign"
                          :feature-id="'redesign_id'"
                          size="medium"
                          :offsetX="'10'"
                          :offsetY="'-90'"
                        >
                          {{ $t('profile.slidesRedesigned') }}
                        </NewFeatureBadge>
                      </div>
                    </div>
                    <div class="cardValueContainer" v-if="isSynthesisEnabled">
                      <div class="cardValueFigure">
                        <h1>
                          {{
                            formatAmountWithCommas(
                              prezsSynthesizedByUser.prezsSynthesized,
                            )
                          }}
                          <NewFeatureBadge
                            :moduleName="'synthesis'"
                            :featureId="'synthesis_id'"
                            :size="'medium'"
                            :offsetX="'-40'"
                            :offsetY="'-80'"
                          >
                          </NewFeatureBadge>
                        </h1>
                      </div>
                      <div class="cardValueMetric">
                        {{ $t('profile.prezSyntesized') }}
                      </div>
                    </div>
                    <div
                      class="cardValueContainer"
                      v-if="isComplyEnabledWithAccess"
                    >
                      <div class="cardValueFigure">
                        <h1>
                          {{
                            formatAmountWithCommas(
                              slidesCheckedForComplianceByUser.complianceCheckedSlides,
                            )
                          }}
                          <NewFeatureBadge
                            :moduleName="'comply'"
                            :featureId="'comply_id'"
                            size="medium"
                            :offsetX="'-40'"
                            :offsetY="'-80'"
                          >
                          </NewFeatureBadge>
                        </h1>
                      </div>
                      <div class="cardValueMetric">
                        {{ $t('profile.slidesComplianceCheck') }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="statsRow">
                <div class="statsContainer">
                  <div class="imgContainer">
                    <div class="imgContent">
                      <img src="/assets/img/enable-illustration.svg" alt />
                    </div>
                    <div class="imgText">
                      <h2>{{ $t('profile.enable') }}</h2>
                    </div>
                  </div>
                  <div class="valueContainer">
                    <div class="cardValueContainer">
                      <div class="cardValueFigure">
                        <h1>
                          {{
                            formatAmountWithCommas(
                              currentUser.user.themeRequests || 0,
                            )
                          }}
                        </h1>
                      </div>
                      <div class="cardValueMetric">
                        {{ $t('profile.templatesRequestSent') }}
                      </div>
                    </div>
                    <div class="cardValueContainer" v-if="isKnowEnabled">
                      <div class="cardValueFigure">
                        <h1>{{ getKnowSlidesDownloads }}</h1>
                      </div>
                      <div class="cardValueMetric">
                        {{ $t('profile.bestPracticesSlidesDownloaded') }}
                      </div>
                    </div>
                    <div class="cardValueContainer" v-if="isKnowEnabled">
                      <div class="cardValueFigure">
                        <h1>{{ getKnowDecksDownloads }}</h1>
                      </div>
                      <div class="cardValueMetric">
                        {{ $t('profile.bestPracticesDecksDownloaded') }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="statsRow">
                <div class="statsContainer">
                  <div class="imgContainer">
                    <div class="imgContent">
                      <img src="/assets/img/learn-illustration.svg" alt />
                    </div>
                    <div class="imgText">
                      <h2>{{ $t('profile.learn') }}</h2>
                    </div>
                  </div>
                  <div class="valueContainer">
                    <div class="cardValueContainer">
                      <div class="cardValueFigure">
                        <div>
                          <h1>
                            {{
                              formatAmountWithCommas(fingerprintCreatedViewed)
                            }}
                          </h1>
                        </div>
                        <div class="cardValueMetric">
                          {{ $t('profile.fingerprintsViewedOrCreated') }}
                        </div>
                      </div>
                    </div>
                    <div class="cardValueContainer">
                      <div class="cardValueFigure">
                        <h1>{{ getLearnStepsCompleted() }}</h1>
                      </div>
                      <div class="cardValueMetric">
                        {{ $t('profile.learnStepsCompleted') }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="false">
                <div class="statsRow" v-if="getOPAccessLevel !== 'no_access'">
                  <div class="statsContainer">
                    <div class="imgContainer">
                      <div class="imgContent">
                        <img
                          src="/assets/img/overnight_prezentations.svg"
                          alt
                        />
                      </div>
                      <div class="imgText">
                        <h2>
                          {{ $t('profile.expertServices') }}
                        </h2>
                      </div>
                    </div>
                    <div class="valueContainer">
                      <div class="cardValueContainer">
                        <div class="cardValueFigure">
                          <h1>
                            {{ currentUser.user.OPRequestCount || 0 }}
                          </h1>
                        </div>
                        <div class="cardValueMetric">
                          {{ $t('profile.requestsSubmitted') }}
                        </div>
                      </div>
                      <div class="cardValueContainer">
                        <div class="cardValueFigure">
                          <h1>
                            {{
                              `$${formatAmountWithCommas(
                                currentUser.user.fundUtilized || 0,
                              )}`
                            }}
                          </h1>
                        </div>
                        <div class="cardValueMetric">
                          {{ $t('profile.fundUtilized') }}
                        </div>
                      </div>
                      <div class="teamFunds">
                        <div class="teamFundsFigure">
                          <h1>
                            {{
                              `$${formatAmountWithCommas(
                                currentUser.user.teamFundAvailable || 0,
                              )}`
                            }}
                          </h1>
                        </div>
                        <div class="teamFundsMetric">
                          {{ $t('profile.fundsAvailable') }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-tab-item>
        <v-tab-item value="wish">
          <div class="tab-content wish-content">
            <div class="wish-title">{{ $t('profile.featurerequest') }}</div>
            <div class="description">
              {{ $t('profile.featurerequestText') }}
            </div>
            <div class="wish__textarea-wrapper">
              <div class="wish-content-text">
                <textarea
                  v-model="wish"
                  :placeholder="$t('profile.enterYourText')"
                  rounded
                  solo
                />
              </div>
            </div>
            <v-btn
              class="primary-button mt-2 py-2"
              rounded
              color="#21a7e0"
              height="48"
              min-width="134"
              :disabled="!wish"
              @click="handleSubmitWish"
            >
              {{ $t('common.submit') }}
            </v-btn>
          </div>
        </v-tab-item>
        <v-tab-item value="admin">
          <div
            v-if="!upgradeProgess"
            class="tab-content admin-settings"
            data-pendo-id="admin-settings"
          >
            <div class="admin-settings-header">
              {{ $t('profile.userAccess') }}
            </div>
            <v-snackbar v-model="snackbar" :timeout="2000">
              {{ snackBarText }}
            </v-snackbar>
            <v-data-table
              show-select
              v-if="isAdmin || isTrial"
              :headers="profileHeaders"
              :items="userAccessLevels"
              :search="adminSearch"
              :item-class="row_classes"
              :item-key="'email'"
              class="user-panel"
              :no-results-text="'No results found'"
              :checkbox-color="'#21a7e0'"
              :footer-props="{
                'items-per-page-options': [10, 25, 50, 100],
                'disable-pagination': loadingUser,
                'disable-items-per-page': loadingUser,
              }"
              :loading="loadingUser"
              :loading-text="'Loading users...'"
              :options="paginationOptions"
              :server-items-length="adminUsersTotal"
              @update:options="handleAdminUsersTableOptionsChange"
            >
              <template v-slot:[`header.op_enabled`]="{ header }">
                <div class="header-title__wrapper">
                  <span class="header-span">
                    <div class="badge-container">
                      <NewFeatureBadge
                        module-name="overnight_prezentations"
                        :feature-id="'overnight_prezentations_user_access_id'"
                        :offsetX="'0'"
                        :offsetY="'0'"
                        :size="'medium'"
                        :userFeatureAccessDate="userFeatureAccessDate"
                      >
                      </NewFeatureBadge>
                    </div>
                    {{ header.text }}</span
                  >
                </div>
              </template>
              <template v-slot:top>
                <v-toolbar flat>
                  <div class="admin-table-toolbar">
                    <v-text-field
                      ref="adminSearchRef"
                      v-model="adminSearch"
                      append-icon="mdi-magnify"
                      single-line
                      hide-details
                      flat
                      solo
                      :placeholder="$t('profile.searchByEmailOrFirstname')"
                      class="admin-table-search-input"
                      @input.stop.native="handleUserSearch($event)"
                      clearable
                      :clear-icon="'mdi-close'"
                      @click:clear="handleSearchWhenClearIconClicked"
                      :disabled="loadingUser"
                    />
                  </div>
                  <v-menu
                    v-model="bulkActionOpen"
                    offset-y
                    v-if="selectedUsers.length"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div v-on="on" v-bind="attrs" class="bulk-actions">
                        {{ $t('profile.bulkActions') }}
                        <v-icon color="black" size="16">{{
                          bulkActionOpen ? 'mdi-menu-up' : 'mdi-menu-down'
                        }}</v-icon>
                      </div>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(item, index) in bulkActions"
                        :key="index"
                        class="cursor"
                        @click="handleBulk(item.value)"
                      >
                        <v-list-item-title>{{
                          $t(item.display_title)
                        }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-spacer />
                  <v-dialog v-model="dialog" width="900px">
                    <template v-slot:activator="{ attrs }">
                      <v-btn
                        class="primary-button mt-2 py-2"
                        rounded
                        color="#21a7e0"
                        height="48"
                        min-width="134"
                        v-bind="attrs"
                        @click="addNewUsers"
                        data-pendo-id="add-new-users"
                        :disabled="
                          !currentActiveSubscription ||
                          currentActiveSubscription.isExpired
                        "
                      >
                        <NewFeatureBadge
                          module-name="multiuseradd"
                          :feature-id="'multiuseradd_id'"
                          :offsetX="'30'"
                          :offsetY="'-15'"
                        >
                          {{ $t('profile.addNewUsers') }}
                        </NewFeatureBadge>
                      </v-btn>
                    </template>

                    <addUsersNew
                      v-if="dialog"
                      :close="closeAddUserModal"
                      :plan-license-available="planLicenseAvailable"
                      :plan-license-used="planLicenseUsed"
                      :plan-license-limit="planLicenseLimit"
                      :user-access-levels="userAccessLevels"
                      :isTrial="isTrial"
                      :filteredColleaguesList="filteredColleaguesList"
                      @addLicence="showAddlicenseModal"
                      @addUsers="refreshUserAccessTable"
                      ref="addUsersNewComp"
                    />
                  </v-dialog>
                  <v-dialog v-model="dialogEnable" width="467px">
                    <v-card class="remove-confirm-dialog">
                      <v-card-title class="headline">
                        {{ $t('profile.youWantToEnableThisUser') }}
                      </v-card-title>
                      <v-card-actions>
                        <v-btn
                          rounded
                          outlined
                          text
                          @click="closeEnable"
                          width="100"
                          height="32"
                          class="remove-cancel-btn"
                        >
                          {{ $t('common.cancel') }}
                        </v-btn>
                        <v-btn
                          rounded
                          width="100"
                          height="32"
                          color="#21a7e0"
                          class="remove-confirm-btn"
                          @click="enableUserConfirm"
                        >
                          {{ $t('common.ok') }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="dialogDelete" width="467px">
                    <v-card class="remove-confirm-dialog">
                      <v-card-title class="headline">
                        {{ $t('profile.youWantToDisableThisUser') }}
                      </v-card-title>
                      <v-card-actions>
                        <v-btn
                          rounded
                          outlined
                          text
                          @click="closeDelete"
                          width="100"
                          height="32"
                          class="remove-cancel-btn"
                        >
                          {{ $t('common.cancel') }}
                        </v-btn>
                        <v-btn
                          rounded
                          width="100"
                          height="32"
                          color="#21a7e0"
                          class="remove-confirm-btn"
                          @click="deleteItemConfirm"
                        >
                          {{ $t('common.ok') }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog
                    v-model="accessLevelEdit"
                    width="467px"
                    v-if="userAccessLevels[adminUsersEditingIndex]"
                  >
                    <v-card class="edit-access-level-dialog">
                      <v-card-title class="headline">
                        {{ $t('profile.editAccessLevel') }}
                      </v-card-title>
                      <v-card-text>
                        <div class="sub-desc">
                          {{ $t('profile.setAccessLevelFor') }}:
                          {{ userAccessLevels[adminUsersEditingIndex].name }}
                        </div>
                        <v-radio-group
                          v-model="
                            userAccessLevels[adminUsersEditingIndex].accessLevel
                          "
                        >
                          <v-radio :label="$t('profile.user')" value="User" />
                          <v-radio :label="$t('profile.admin')" value="Admin" />
                        </v-radio-group>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          rounded
                          color="#21a7e0"
                          outlined
                          @click="closeEditAccessLevel"
                          width="100"
                          height="32"
                          class="remove-cancel-btn"
                        >
                          {{ $t('common.cancel') }}
                        </v-btn>
                        <v-btn
                          rounded
                          width="100"
                          height="32"
                          color="#21a7e0"
                          class="remove-confirm-btn"
                          @click="handleConfirmEditAccessLevel"
                        >
                          {{ $t('common.save') }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>
              <template v-slot:header.data-table-select="{}">
                <v-checkbox
                  class="user-access-checkbox"
                  :input-value="
                    adminUsersTotal - 1 === totalUsersSelected &&
                    adminUsersTotal > 1
                  "
                  :indeterminate="isUserIndeterminateSelections"
                  @change="(e) => handleAllUsersSelect(e)"
                  color="#21a7e0"
                />
              </template>
              <template v-slot:item.data-table-select="{ item }">
                <v-checkbox
                  class="user-access-checkbox"
                  v-show="currentUser.user.id !== item.email"
                  :input-value="
                    selectedUsers.filter((itm) => itm.email === item.email)
                      .length > 0
                  "
                  @change="selectItem(item)"
                  color="#21a7e0"
                ></v-checkbox>
              </template>
              <template v-slot:[`item.name`]="{ item }">
                <div @click="item.fpVisibleToTeam ? onUserClick(item) : ''">
                  <AudienceItem
                    :itemClass="'no-padding'"
                    :status="item.status"
                    :firstName="item.firstName"
                    :lastName="item.lastName"
                    :profileImgS3Path="item.profileImageUrl"
                    :fpVisibleToTeam="item.fpVisibleToTeam"
                    :origin="'profile'"
                  />
                </div>
              </template>
              <template v-slot:[`item.op_enabled`]="{ item }">
                <div>
                  <v-switch
                    v-if="item.status !== 'Disabled'"
                    :disabled="item.email === currentUser.user.id"
                    v-model="item.op_enabled"
                    :label="
                      item.op_enabled
                        ? $t('profile.enabled')
                        : $t('profile.disabled')
                    "
                    @change="handleOPAccess(item)"
                  ></v-switch>
                  <span v-else>{{ $t('overnightPresentations.na') }}</span>
                </div>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <div
                  :class="`${
                    item.email === currentUser.user.id ? 'hide-visibility' : ''
                  }`"
                  v-if="
                    item.status !== 'Disabled' ||
                    item.email === currentUser.user.id
                  "
                >
                  <v-tooltip
                    top
                    max-width="200"
                    content-class="tooltip-content"
                    fixed
                    v-if="item.status !== 'Disabled'"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <img
                        src="/assets/icons/Disable.png"
                        @click="deleteItem(item)"
                        alt
                        class="admin-setting-table-action-activate"
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <span>{{ $t('profile.disable') }}</span>
                  </v-tooltip>
                  <!-- <v-icon
                    v-if="
                      item.status !== 'Disabled' ||
                      item.email === currentUser.user.id
                    "
                    :disabled="item.status === 'Disabled'"
                    class="admin-setting-table-action-trash"
                    color="primary"
                    @click="deleteItem(item)"
                  >
                    mdi-delete-outline
                  </v-icon> -->
                </div>
                <v-tooltip
                  top
                  max-width="200"
                  content-class="tooltip-content"
                  fixed
                  v-else-if="item.status === 'Disabled'"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <img
                      src="/assets/icons/Enable.png"
                      @click="handleEnableUser(item)"
                      alt
                      class="admin-setting-table-action-activate"
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <span>{{ $t('profile.reenable') }}</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.accessLevel`]="{ item }">
                <div class="admin-table-access-level">
                  {{ !isTrial ? item.accessLevel : 'Admin' }}
                  <img
                    v-if="!isTrial"
                    src="/assets/img/profile/edit.svg"
                    width="18px"
                    height="18px"
                    @click="handleEditUserAccessLevel(item)"
                    disabled="item.status==='Disabled'"
                    alt
                  />
                </div>
              </template>
              <template v-slot:[`item.limit`]="{ item }">
                <div class="admin-table-limit">
                  <div class="admin-table-limit-normal" v-if="!editLimit">
                    {{ item.limit }}
                    <img
                      v-if="item.status !== 'Disabled'"
                      src="/assets/img/profile/edit.svg"
                      width="18px"
                      height="18px"
                      @click="handleEditLimit(item)"
                      alt
                    />
                  </div>
                  <div
                    v-if="
                      editLimit && userAccessLevels[editLimitIndex] === item
                    "
                    class="admin-table-limit-edit"
                  >
                    <v-text-field
                      v-model="userAccessLevels[editLimitIndex].limit"
                      :rules="numberRule"
                    />
                    <v-btn
                      text
                      @click="closeLimitEdit"
                      width="100"
                      height="32"
                      class="limit-edit-btn"
                    >
                      {{ $t('common.save') }}
                    </v-btn>
                  </div>
                </div>
              </template>
              <template v-slot:no-data>No results found</template>
              <template v-slot:item.lastActivity="{ item }">
                {{ formatDate(item.lastActivity) }}
              </template>
            </v-data-table>
            <!-- <div v-if="isTrial" class="trial-encourage">
              <div class="trial-encourage-image">
                <img
                  src="/assets/img/transition-analyzing-similar-profiles.svg"
                  alt="trial encourage image"
                />
              </div>
              <div class="trial-encourage-action">
                <p v-html="$t('profile.trialEncourageText')"></p>
                <v-btn
                  class="primary-button"
                  rounded
                  color="#21a7e0"
                  height="48"
                  min-width="134"
                  @click="scrollIntoSubscription"
                >
                  {{ $t('profile.upgrade') }}
                </v-btn>
              </div>
            </div> -->
            <!-- <div
              class="overnightServicesContainer"
              v-if="isAdmin && getOPAccessLevel !== 'no_access'"
            >
              <div class="sectionTitle">
                <NewFeatureBadge
                  module-name="overnight_prezentations"
                  :feature-id="'overnight_prezentations_services_id'"
                  :offsetX="'0'"
                  :offsetY="'2'"
                  :userFeatureAccessDate="userFeatureAccessDate"
                >
                  {{ 'Overnight Prezentations Utilization' }}
                </NewFeatureBadge>
              </div> -->
            <!--<div>Add utlization by slide type component here</div>!-->
            <!-- <UtlizationOvertime
                :utilizationData="overnightUtilizationOvertime"
              />
            </div> -->
            <!-- <div
              class="teamFundsContainer"
              v-if="isAdmin && getOPAccessLevel !== 'no_access'"
            >
              <div class="badge-container-team-funds">
                <NewFeatureBadge
                  module-name="overnight_prezentations"
                  :feature-id="'overnight_prezentations_teams_fund_id'"
                  :offsetX="'0'"
                  :offsetY="'2'"
                  :userFeatureAccessDate="userFeatureAccessDate"
                ></NewFeatureBadge>
              </div>
              <AdminInfoTiles
                :title="'Team Funds'"
                :illustration="'/assets/img/team_funds.svg'"
                :ctaContent="'Add Funds'"
                :handleCTA="handleAddFundsCTA"
                :tilesType="'teamFunds'"
                :metrics="teamFundsAdminMetrics"
              />
            </div> -->
            <div v-if="isAdmin || isTrial" class="licenses-panel">
              <!-- <div v-if="isAdmin" class="licenses-panel"> -->
              <div class="licenses-panel-header">
                {{ $t('profile.licenses') }}
              </div>
              <div class="licenses-info">
                <div class="license-info-item">
                  <div class="license-info-item-title">
                    {{ $t('profile.totalLicenses') }}
                  </div>
                  <div class="license-info-item-value">
                    {{ formatAmountWithCommas(planLicenseLimit) }}
                  </div>
                </div>
                <div class="license-info-item">
                  <div class="license-info-item-title">
                    {{ $t('profile.assigned') }}
                  </div>
                  <div class="license-info-item-value">
                    {{ formatAmountWithCommas(planLicenseUsed) }}
                  </div>
                </div>
                <div class="license-info-item">
                  <div class="license-info-item-title">
                    {{ $t('profile.available') }}
                  </div>
                  <div class="license-info-item-value">
                    {{ formatAmountWithCommas(planLicenseAvailable) }}
                  </div>
                </div>
              </div>
              <div class="licenses-action">
                <v-btn
                  class="primary-button"
                  rounded
                  color="#21a7e0"
                  height="48"
                  min-width="134"
                  @click="handleBuyLicense"
                >
                  {{ $t('profile.buyAdditionalLicenses') }}
                </v-btn>
              </div>
            </div>
            <div>
              <PaymentMethods />
            </div>
            <div class="subscriptions-panel" id="subscribe">
              <div class="loaderSubs" v-if="loadingAvailablePlans">
                <v-progress-circular indeterminate color="primary" size="35" />
              </div>
              <div v-else class="subsContainer">
                <div class="subscriptions-panel-header">
                  {{ $t('profile.subscriptions') }}
                </div>
                <div class="subscriptions-wrapper">
                  <div
                    class="subscription-wrapper"
                    v-for="(item, index) in this.availablePlans"
                    :key="index"
                    :style="{
                      width: `${cardWidth}%`,
                      paddingBottom: `${
                        currentUser.company.companyType.toLowerCase() ===
                        'mid market'
                          ? 50
                          : 35
                      }px`,
                      border: `${
                        currentSubscription &&
                        currentSubscription.planID === item.id
                          ? '2px solid #87CEEB'
                          : null
                      }`,
                      outline: `${
                        currentSubscription &&
                        currentSubscription.planID === item.id
                          ? '2px solid #87CEEB'
                          : null
                      }`,
                    }"
                  >
                    <div class="subscription-wrapper-title">
                      <div
                        v-if="
                          currentSubscription &&
                          currentSubscription.plan &&
                          currentSubscription.plan.name &&
                          !currentSubscription.isExpired &&
                          item.name.toLowerCase() ===
                            currentSubscription.plan.name.toLowerCase()
                        "
                      >
                        <v-icon class="current-subscription-check">
                          mdi-check-circle
                        </v-icon>
                      </div>
                      <div>{{ item.name }}</div>
                    </div>
                    <div class="subscription-wrapper-img">
                      <img :src="getImage(item)" alt />
                    </div>
                    <div
                      class="d-flex flex-column justify-space-between align-center"
                      style="gap: 5px"
                    >
                      <div class="subscription-wrapper-price">
                        {{ getPrice(item) }}
                      </div>
                      <div class="subscription-wrapper-desc">
                        {{ getDescription(item, currentSubscription) }}
                      </div>
                      <div
                        v-if="
                          currentSubscription &&
                          item.id === currentSubscription.planID
                        "
                        class="subscription-cta subscription-current"
                        :style="{
                          height: ` ${
                            !isTrial &&
                            (!currentSubscription.stripeSubscriptionID ||
                              currentSubscription.isExpired)
                              ? '100'
                              : '98'
                          }px`,
                        }"
                      >
                        <div
                          class="subscription-current-header"
                          :class="{
                            'hide-visibility': currentSubscription.isExpired,
                          }"
                          :style="{
                            margin: `0px 0px ${
                              !isTrial &&
                              (!currentSubscription.stripeSubscriptionID ||
                                currentSubscription.isExpired)
                                ? '8'
                                : '16'
                            }px 0px`,
                            fontSize: `18px`,
                          }"
                        >
                          {{ $t('profile.currentSubscription') }}
                        </div>
                        <v-btn
                          class="primary-button"
                          rounded
                          color="#21a7e0"
                          height="48"
                          min-width="134"
                          :disabled="renewDisable"
                          :style="{ margin: '0px 0px 8px 0px' }"
                          v-if="
                            !isTrial &&
                            (!currentSubscription.stripeSubscriptionID ||
                              currentSubscription.isExpired)
                          "
                          @click="handleSubscription(item, 'renew')"
                        >
                          {{ $t('profile.renew') }}
                        </v-btn>
                      </div>
                      <div
                        class="subscription-cta subscription-unavailable"
                        v-else-if="
                          currentSubscription &&
                          currentSubscription.planID !== '12345' &&
                          item.id === '12345'
                        "
                        :style="{
                          margin: `${'0px 0px 15px 0px'}`,
                          height: '85px',
                        }"
                      >
                        {{ $t('profile.unavailable') }}
                      </div>
                      <div
                        class="subscription-cta"
                        v-else-if="
                          (currentSubscription &&
                            item.id < currentSubscription.planID &&
                            item.id !== '12347') ||
                          (currentSubscription &&
                            currentSubscription.planID === '12347' &&
                            item.id !== '12347')
                        "
                      >
                        <v-btn
                          class="primary-button"
                          rounded
                          color="#21a7e0"
                          height="48"
                          min-width="134"
                          @click="handleSubscription(item, 'buy')"
                          :style="{
                            margin: `${
                              currentSubscription &&
                              currentSubscription.planID === '12346' &&
                              !autoRenewStatus
                                ? '42px 0px 8px 0px'
                                : '0px 0px 8px 0px'
                            }`,
                          }"
                        >
                          Buy
                        </v-btn>
                      </div>
                      <div
                        class="subscription-cta"
                        v-else-if="
                          (currentSubscription &&
                            item.id > currentSubscription.planID) ||
                          (currentSubscription &&
                            currentSubscription.planID !== '12347' &&
                            item.id === '12347')
                        "
                      >
                        <v-btn
                          class="primary-button"
                          rounded
                          color="#21a7e0"
                          height="48"
                          min-width="134"
                          @click="handleSubscription(item, 'upgrade', true)"
                          :style="{
                            margin: `${
                              currentSubscription &&
                              currentSubscription.planID === '12346' &&
                              !autoRenewStatus
                                ? '42px 0px 8px 0px'
                                : '0px 0px 8px 0px'
                            }`,
                          }"
                        >
                          {{ $t('profile.upgrade') }}
                        </v-btn>
                      </div>
                    </div>
                    <hr
                      :style="{
                        margin: ` ${
                          !isTrial &&
                          currentSubscription &&
                          (!currentSubscription.stripeSubscriptionID ||
                            currentSubscription.isExpired)
                            ? '16'
                            : '20'
                        }px 0px 15px 0px`,
                        color: 'black',
                      }"
                    />
                    <div class="subscription-wrapper-header">
                      {{ getHeader(item, currentSubscription) }}
                    </div>
                    <div class="subscription-wrapper-detail">
                      <div
                        class="subscription-wrapper-detail"
                        v-for="(item, index) in getPlanDescription(item.Detail)"
                        :key="index"
                      >
                        <img
                          src="/assets/img/profile/check-mark.svg"
                          alt="Tick Mark"
                          class="tick-mark"
                        />
                        {{ item }}
                      </div>
                    </div>
                    <hr
                      v-if="
                        currentSubscription &&
                        currentSubscription.planID === item.id
                      "
                      style="color: black; margin: 15px 0px 10px 0px"
                    />
                    <div
                      class="subscription-current-desc"
                      :style="{
                        fontSize: `${
                          currentSubscription &&
                          currentSubscription.planID === '12346'
                            ? '18'
                            : '13'
                        }px`,
                      }"
                    >
                      {{
                        getSubscriptionAutorenewDate(item, currentSubscription)
                      }}
                    </div>
                    <div
                      class="autoRenewToggle"
                      v-if="
                        currentSubscription &&
                        !isTrial &&
                        !currentSubscription.isExpired &&
                        currentSubscription.planID === item.id &&
                        currentSubscription.planID !== '12347'
                      "
                      :style="{
                        marginBottom: `20px`,
                        display: `flex`,
                        justifyContent: `center`,
                      }"
                    >
                      <v-switch
                        v-model="autoRenewStatus"
                        :label="$t('profile.autoRenew')"
                        color="#21a7e0"
                        hide-details
                        @change="(e) => handleToggleAutoRenew(e)"
                      />
                    </div>
                    <div
                      v-if="
                        currentSubscription &&
                        item.id !== '12345' &&
                        currentSubscription.planID === item.id &&
                        showAdditionInfo
                      "
                      class="platform-fee-disclaimer"
                    >
                      <p :class="isValidLessThan60Days ? 'red-text' : ''">
                        {{ getPlatformFeeDisclaimerMessage }}
                      </p>
                    </div>
                    <div
                      v-else-if="
                        (item.id == '12348' || item.id == '12349') &&
                        currentSubscription &&
                        currentSubscription.planID !== item.id &&
                        showAdditionInfo
                      "
                      class="platform-fee-disclaimer"
                    >
                      <p>*Additional platform fees may apply</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div v-if="isAdmin">
              <UtilizationReport />
            </div> -->
          </div>
        </v-tab-item>
      </v-tabs>
    </div>
  </div>
</template>

<script>
import { findIndex, filter, isEqual } from 'lodash';
import debounce from 'lodash/debounce';
import { Auth } from 'aws-amplify';
import { mapState, mapActions, mapGetters } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import NewFeatureBadge from '../../common/NewFeatureBadge.vue';
import TemplateSelector from './TemplateSelector.vue';
import StorylinesTabContent from './StorylinesTabContent.vue';
import WelcomeModalDetail from '../../common/WelcomeModalDetail.vue';
import {
  clearCachedUser,
  getFullName,
  capitalizeFirstLetter,
} from '../../../utils/common';
import LearnApi from '../../../API/learn-api';
// import PaymentApi from '../../../API/payment-api';
// import { updateWishes } from '../../../graphql/mutations';
// import { listWishess } from '../../../graphql/queries';
import EventBus from '../../common/event-bus';
import { AnalyticsHandler } from '../../common/Analytics/AnalyticsHandler';
// import ExceedingLimitDetail from './ExceedingLimitDetail.vue';
// import AddUserFailure from './AddUserFailure.vue';
import GreatDecisionModal from '../../common/GreatDecisionModal.vue';
import AddTeamFunds from './AddTeamFunds.vue';
import AddLicensesModal from '../../common/AddLicensesModal.vue';
import EditPaymentDetail from './EditPaymentDetail.vue';
import AudienceItem from '../../common/AudienceItem.vue';
import { MatomoAnalyticsHandler } from '../../common/Analytics/MatomoAnalyticsHandler';
// import SubscriptionExpiredBanner from '../../common/SubscriptionExpiredBanner.vue';
import { isEmojiExist, getLocalisedNumberInString } from '../../utils';
import usersApi from '../../../API/users-api';
import Basics from './Basics.vue';
import FingerprintTabContent from './FingerprintTabContent.vue';
import {
  sendEmail,
  createWishes,
  updateOPAccess,
  getFundsUtilizationMetrics,
  getAdminSettingsTeamUsers,
} from '../../../utils/api-helper';
import addUsersNew from '../../navbar/addUsersNew.vue';
import PaymentMethods from './payment-methods/PaymentMethods.vue';
import { fpTypes, fpSelections } from '@/mock/fingerprint';
import { trackProfileEvents } from '../../common/Analytics/ProfileEvents';
import {
  TD_COMMON_COLUMN_NAME,
  TD_COMMON_COLUMN_VALUE,
  TD_DISABLED,
  TD_ENABLED,
  TD_QUERY,
  TD_TEAM,
} from '../../common/Analytics/MatomoTrackingDataHelper';
import ToggleAutoRenewModal from '../../common/ToggleAutoRenewModal.vue';
import BulkActionModal from './BulkActionModal.vue';
// import OvernightServicesStats from './OvernightServicesStats.vue';
// import UtilizationReport from './utilization-report/UtilizationReport.vue';
// import AdminInfoTiles from './AdminInfoTiles.vue';
// import UtlizationOvertime from './UtlizationOvertime.vue';
import UsageStatistics from './Metrics/UsageStatistics.vue';
import ImpactMetrics from './Metrics/ImpactMetrics.vue';
import ActiveUsersChart from './Metrics/ActiveUsersChart.vue';

const dateFormat = require('dateformat');

export default {
  name: 'Profile',
  components: {
    TemplateSelector,
    StorylinesTabContent,
    // SubscriptionExpiredBanner,
    Basics,
    AudienceItem,
    NewFeatureBadge,
    addUsersNew,
    FingerprintTabContent,
    PaymentMethods,
    // OvernightServicesStats,
    // UtilizationReport,
    // AdminInfoTiles,
    // UtlizationOvertime,
    UsageStatistics,
    ImpactMetrics,
    ActiveUsersChart,
  },
  data() {
    return {
      learnTeamStats: [],
      userData: {},
      numberRule: [
        (v) => !!v || this.$t('warnings.fieldIsRequired'),
        (v) => /^\d+$/.test(v) || this.$t('warnings.mustBeANumber'),
      ],
      emojiRules: [
        (v) => !isEmojiExist(v) || this.$t('warnings.emojisAreNotAllowed'),
        (v) => v.length <= 50 || this.$t('warnings.maximumOfFiftyCharacters'),
      ],
      renewDisable: false,
      prezentationsCount: 0,
      loadingUser: false,
      loadingWishes: true,
      upgradeProgess: false,
      adminKey: 0,
      imgKey: 0,
      fileName: null,
      fileType: null,
      valid: true,
      show1: false,
      loading: false,
      fullName: '',
      nameRules: [
        (v) => !!v || this.$t('warnings.nameIsRequired'),
        (v) => v && v.length <= 10,
      ],
      passwordRules: {
        required: (value) => !!value || this.$t('warnings.required'),
        min: (v) => v.length >= 8 || this.$t('warnings.minCharacters'),
        emailMatch: () => this.$t('warnings.emailAndPasswordDontMatch'),
      },
      username: '',
      password: '',
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '+144444444444',
      theme: '',
      role: '',
      monthlyDownloads: 0,
      totalDownloads: 0,
      componentKey: 100,
      topWishes: [],
      showWishes: false,
      wishesKey: 200,
      profileImage: '/assets/img/user-no-pic.svg',
      user: {},
      audienceType: 'performer',
      result: {},
      wish: '',
      themeKey: 0,
      currentTheme: null,
      earnedBadgeCount: false,
      fingerPrints: [],
      selections: [],
      selectedUsers: [],
      snackbar: false,
      snackBarText: '',
      bulkActionOpen: false,
      bulkActions: [
        {
          title: 'Set Access Level',
          display_title: 'profile.bulkActionModal.setAccessLevel',
          value: 'access',
        },
        {
          title: 'Enable/ Disable members',
          display_title: 'profile.bulkActionModal.enableOrDisableMembers',
          value: 'active',
        },
      ],
      ownPreference: '',
      personal: [],
      shareList: [],
      thumbs: [
        {
          thumb: null,
        },
        {
          thumb: null,
        },
        {
          thumb: null,
        },
      ],
      adminSearch: '',
      dialog: false,
      dialogDelete: false,
      dialogEnable: false,
      accessLevelEdit: false,
      profileHeaders: [
        {
          text: 'Name',
          title: 'profile.name',
          align: 'start',
          value: 'name',
        },
        {
          text: 'Email',
          title: 'navbarActions.addColleagueDetail.email',
          value: 'email',
        },
        {
          text: 'Fingerprint',
          title: 'profile.fingerprint',
          value: 'fingerPrint',
        },
        {
          text: 'Access Level',
          title: 'profile.basicsvue.accessLevel',
          value: 'accessLevel',
        },
        {
          text: 'Last Active Date',
          title: 'profile.lastActiveDate',
          value: 'lastActivity',
          width: '200px',
        },
        { text: 'Status', title: 'profile.status', value: 'status' },
        /*  { text: "Download Limit", value: "limit" },
        { text: "User Downloads", value: "used" }, */
        {
          text: 'Actions',
          title: 'profile.actions',
          value: 'actions',
          sortable: false,
        },
      ],
      userAccessLevels: [],
      messageToNew: '',
      currentSubscription: null,
      availableSubscriptions: [],
      effectivenessRating: 0.0,
      accessLevel: '',
      emailSent: false,
      temp: false,
      editLimit: false,
      editLimitIndex: -1,
      companyData: {},
      selectedTab: '',
      adminStatsToggleRadio: 'Team',
      loadingTimeAndCostSaveMetric: false,
      currentTab: 'basics',
      autoRenewStatus: false,
      teamFundsAvailable: 0,
      teamFundsAdminMetrics: [],
      overnightUtilizationOvertime: {},
      paginationOptions: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [],
      },
      adminUsersTotal: 0,
      loadingAvailablePlans: false,
      totalUsersSelected: 0,
      adminUsersEditingItem: {},
      adminUsersEditingIndex: -1,
      colleaguesList: [
        {
          email: '',
          firstname: '',
          lastname: '',
          role: '',
          loading: false,
          existError: '',
          isExistingTrialUser: false,
        },
        {
          email: '',
          firstname: '',
          lastname: '',
          role: '',
          loading: false,
          existError: '',
          isExistingTrialUser: false,
        },
      ],
      newUsersAdded: false,
    };
  },
  watch: {
    currentActiveSubscription() {
      if (this.currentActiveSubscription) {
        this.currentSubscription = this.currentActiveSubscription;
      }
    },
    '$route.query': {
      async handler(profileTabs) {
        const { tab } = profileTabs;
        if (tab !== this.currentTab) {
          this.currentTab = tab;
        }
      },
      deep: true,
    },
    currentSubscription(val) {
      if (val) {
        this.autoRenewStatus = !(
          val.stripeSubscriptionID === null ||
          val.stripeSubscriptionID === undefined ||
          val.stripeSubscriptionID === ''
        );
      }
    },
    '$route.hash': {
      async handler() {
        if (this.$route.hash === '#subscribe') {
          setTimeout(() => {
            this.scrollIntoSubscription();
          }, 200);
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapState('users', [
      'currentUser',
      'currentActiveSubscription',
      'themes',
      'availablePlans',
      'platformPriceDetails',
      'availableAddOns',
      'isTrialExpired',
      'companyDetails',
      'teamUsersLoadedFlag',
    ]),
    // ...mapState('metrics', ['currentUserLimit', 'organizationLimit']),
    ...mapGetters('users', [
      'getCompanyDomain',
      'isKnowEnabled',
      'isPrezentationUploadEnabled',
      'getOPAccessLevel',
      'getOPLimitedBanner',
      'isActiveUserLineChartEnabled',
      'userStats',
      'fingerprintCreatedViewed',
      'getIsNonBrandedCompany',
    ]),
    cardWidth() {
      if (this.availablePlans.length === 3) return 31;
      return 24;
    },
    isUserIndeterminateSelections() {
      return (
        this.totalUsersSelected < this.adminUsersTotal - 1 &&
        this.selectedUsers.length > 0 &&
        this.selectedUsers.length < this.adminUsersTotal - 2
      );
    },
    isValidLessThan60Days() {
      if (this.platformPriceDetails?.noOfDaysLeft != null)
        return this.platformPriceDetails?.noOfDaysLeft < 60;
      return false;
    },
    showAdditionInfo() {
      return this.platformPriceDetails?.showAdditionInfo;
    },
    getPlatformFeeDisclaimerMessage() {
      const datFormat = moment(this.platformPriceDetails?.validTillDate).format(
        'MMMM Do, YYYY',
      );
      const { status, noOfDaysLeft } = this.platformPriceDetails || {};

      if (status === 'Not Paid') return '*Additional platform fees may apply';
      if (noOfDaysLeft === 1)
        return '*Your platform fee validity expires today';
      if (noOfDaysLeft <= 0) return '*Your platform fee validity has expired';
      if (noOfDaysLeft > 60)
        return `*Your platform fee is valid through ${datFormat}`;
      if (noOfDaysLeft > 1)
        return `*Your platform fee validity expires on ${datFormat}`;
      return `*Your platform fee is valid through ${datFormat}`;
    },
    getUploadFeature() {
      return this.currentUser.features.find(
        (itm) => itm.feature_name === 'user-prezentation-upload',
      );
    },
    userFeatureAccessDate() {
      let userFeatureAccessDate = null;
      const overnightPresentationFeature = this.currentUser?.features?.find(
        (feature) => feature.feature_name === 'overnight-prezentations',
      );
      if (overnightPresentationFeature) {
        userFeatureAccessDate = overnightPresentationFeature.updated_at;
      }
      return userFeatureAccessDate;
    },
    isCharactorLimit() {
      if (this.firstName?.length > 50) return true;
      return false;
    },
    isNormalUser() {
      if (
        !this.currentUser ||
        (this.currentUser.user.role !== 'Admin' &&
          this.currentUser.user.role !== 'TrialUser')
      ) {
        return true;
      }
      return false;
    },
    getTrialValidFor() {
      if (!this.currentSubscription) return 0;
      const { endDate } = this.currentSubscription;
      const daysleft = Math.floor(
        (Date.parse(endDate) - new Date()) / (1000 * 60 * 60 * 24),
      );

      return daysleft;
    },
    planLicenseLimit() {
      if (this.currentSubscription) {
        return this.currentSubscription.numSeats;
      }
      return 0;
    },
    planLicenseUsed() {
      return Number(this.currentUser.team.licensesAssigned || 0);
    },
    planLicenseAvailable() {
      const available = this.planLicenseLimit - this.planLicenseUsed;
      return available > 0 ? available : 0;
    },
    isAdmin() {
      return this.currentUser.user.role.toLowerCase() === 'admin';
    },
    isTrial() {
      return this.currentUser.user.role.toLowerCase() === 'trialuser';
    },
    isComplyEnabledWithAccess() {
      const complyFeature = this.currentUser?.features?.find(
        (itm) => itm.feature_name === 'comply',
      );
      return (
        (complyFeature &&
          complyFeature.enabled &&
          complyFeature.access_level &&
          complyFeature.access_level === 'full_access') ||
        (complyFeature &&
          complyFeature.access_level &&
          (complyFeature.access_level === 'limited_access' ||
            complyFeature.access_level === 'restricted_access'))
      );
    },
    isRedesignEnabled() {
      const redesignFeature = this.currentUser?.features?.find(
        (itm) => itm.feature_name === 'redesign',
      );
      return (
        (redesignFeature &&
          redesignFeature.enabled &&
          redesignFeature.access_level &&
          redesignFeature.access_level === 'full_access') ||
        (redesignFeature &&
          redesignFeature.access_level &&
          (redesignFeature.access_level === 'limited_access' ||
            redesignFeature.access_level === 'restricted_access'))
      );
    },
    isGenerateEnabled() {
      const genFeature = this.currentUser?.features?.find(
        (itm) => itm.feature_name === 'slide-generate',
      );
      return (
        (genFeature &&
          genFeature.enabled &&
          genFeature.access_level &&
          genFeature.access_level === 'full_access') ||
        (genFeature &&
          genFeature.access_level &&
          (genFeature.access_level === 'limited_access' ||
            genFeature.access_level === 'restricted_access'))
      );
    },
    slidesCheckedForComplianceByUser() {
      return {
        complianceCheckedSlides: this.currentUser.user?.complySlides || 0,
      };
    },
    prezsSynthesizedByUser() {
      return {
        prezsSynthesized: this.currentUser.userStats?.prezsSynthesized || 0,
      };
    },
    slidesRedesignedByUser() {
      return {
        slidesRedesigned: this.currentUser.userStats?.slidesRedesigned || 0,
      };
    },
    slidesUploadedByUser() {
      return {
        slidesUploaded: this.currentUser.userStats?.userUploadedSlides || 0,
      };
    },
    slidesGeneratedByUser() {
      return {
        slidesGenerated: this.currentUser.userStats?.slidesGenerated || 0,
      };
    },
    prezentationsGeneratedByUser() {
      return {
        prezentationsGenerated:
          this.currentUser.userStats?.prezentationsGenerated || 0,
      };
    },
    prezentationsBuiltAndUploaded() {
      return {
        prezentationsBuilt:
          this.currentUser.userStats?.prezentationCreated || 0,
        prezentationsUploaded:
          this.currentUser.userStats?.userUploadedPrezentation || 0,
      };
    },
    hoursSavedTotal() {
      return (0.15 * this.totalDownloads).toFixed(2);
    },
    personalPrefs() {
      return this.personal.map((item) => item.title);
    },
    getSearchCount() {
      return this.currentUser?.user.countSearches ||
        this.currentUser?.user.knowSearchCount
        ? getLocalisedNumberInString(
            Number(this.currentUser.user.countSearches) +
              Number(this.currentUser.user.knowSearchCount),
          )
        : 0;
    },
    getKnowSlidesDownloads() {
      return this.currentUser?.user?.knowDownloads
        ? getLocalisedNumberInString(this.currentUser.user.knowDownloads)
        : 0;
    },

    getKnowDecksDownloads() {
      return this.currentUser?.userStats?.bpeDownloadedCount
        ? getLocalisedNumberInString(
            this.currentUser.userStats.bpeDownloadedCount,
          )
        : 0;
    },

    isProfessionalOrEnterprise() {
      const planName = this.currentSubscription.plan.name.toLowerCase();
      return planName === 'professional' || planName === 'enterprise';
    },
    slideDownloads() {
      const allDownloads = this.currentUser.userStats.downloads || 0;
      return getLocalisedNumberInString(allDownloads);
    },
    isSynthesisEnabled() {
      const synFeature = this.currentUser?.features?.find(
        (itm) => itm.feature_name === 'synthesis',
      );
      return (
        (synFeature &&
          synFeature.enabled &&
          synFeature.access_level &&
          synFeature.access_level === 'full_access') ||
        (synFeature &&
          synFeature.access_level &&
          (synFeature.access_level === 'limited_access' ||
            synFeature.access_level === 'restricted_access'))
      );
    },
    filteredColleaguesList() {
      if (this.planLicenseAvailable === 1) {
        return this.colleaguesList.slice(0, 1);
      }
      return this.colleaguesList;
    },
    allowPayByCard() {
      if (
        this.getIsNonBrandedCompany &&
        !this.themes.find((theme) => theme.isUserUploaded === true)
      ) {
        return false;
      }
      return true;
    },
  },
  methods: {
    ...mapActions('users', [
      'setCurrentUser',
      'setFilteredThemes',
      'loadAvailablePlans',
      'loadAvailableAddOns',
      'setCurrentUserPersonalPreferences',
    ]),
    ...mapActions('users', ['setAssetFolder']),
    ...mapActions('users', ['setCurrentUser', 'setCurrentTheme']),
    ...mapActions('prezentations', ['fetchAllPrezentations']),

    focusOnAdminSearch() {
      this.$nextTick(() => {
        const adminSearchBoxRef = this.$refs.adminSearchRef;
        adminSearchBoxRef.focus();
      });
    },

    // fontSizeSetter() {
    //   // console.log(this.loadAvailablePlans.length);
    //   if (this.currentSubscription.planID === '12346') {
    //     this.headerFontsize = 16;
    //     this.detailFontsize = 15;
    //     // this.expiryDateFontsize = 16;
    //   } else {
    //     this.headerFontsize = 13;
    //     this.detailFontsize = 13;
    //     // this.expiryDateFontsize = 12;
    //   }
    // },

    setAutoRenewToggleStatus(status) {
      this.autoRenewStatus = status;
    },
    scrollIntoSubscription(time = 200) {
      setTimeout(() => {
        this.$nextTick(() => {
          const el = document.getElementsByClassName(
            'subscriptions-panel-header',
          );
          if (el.length) {
            el[0].scrollIntoView({
              behavior: 'smooth',
            });
          }
        });
      }, time);
    },
    handleToggleAutoRenew(e) {
      if (this.autoRenewStatus) {
        trackProfileEvents.profileAdminSettingsPaymentsToggleOn(
          this.currentUser,
          {},
        );
      } else {
        trackProfileEvents.profileAdminSettingsPaymentsToggleOff(
          this.currentUser,
          {},
        );
      }
      this.$modal.show(
        ToggleAutoRenewModal,
        {
          modalType: !e ? 'disable' : 'enable',
          setAutoRenewToggleStatus: this.setAutoRenewToggleStatus,
        },
        {
          name: 'ToggleAutoRenewModal',
          width: '600px',
          height: 'auto',
          // top: '300px !important',
          styles: {
            // top: '300px !important',
            'max-height': '80%',
            'max-width': '800px',
            overflow: 'auto',
            'border-radius': '12px',
          },
          clickToClose: false,
        },
      );
    },

    handleAdminUserToggle() {
      if (this.adminStatsToggleRadio === 'Me') {
        trackProfileEvents.profileMetricsMeButtonClick(this.currentUser);
      } else {
        trackProfileEvents.profileMetricsTeamButtonClick(this.currentUser);
      }
    },
    onUserClick(item) {
      if (item.status !== 'Disabled') {
        this.$router.push({
          name: 'fingerprints',
          query: {
            username: item.email,
            type: 'user',
          },
        });
      }
    },
    selectItem(item) {
      const ind = this.selectedUsers.findIndex(
        (itm) => itm.email === item.email,
      );
      if (ind >= 0) {
        this.selectedUsers.splice(ind, 1);
        this.totalUsersSelected--;
      } else if (!this.selectedUsers.find((itm) => itm.email === item.email)) {
        this.selectedUsers.push(item);

        // TOD0: This logic will change
        if (this.selectedUsers.length - 1 === this.adminUsersTotal) {
          this.totalUsersSelected = this.adminUsersTotal - 1;
        } else {
          this.totalUsersSelected++;
        }
      }
    },
    handleBulk(val) {
      this.$modal.show(
        BulkActionModal,
        {
          users: this.selectedUsers,
          type: val,
          updateTeam: this.updateTeam,
          isUpdateAll: this.adminUsersTotal - 1 === this.totalUsersSelected,
          totalCount: this.adminUsersTotal - 1,
          isLocalSearchActive: this.adminSearch.length > 0,
          searchText: this.adminSearch,
        },
        {
          name: 'BulkActionModal',
          width: '540px',
          height: '226px',
          styles: {
            borderRadius: '12px',
          },
        },
      );
    },
    async updateTeam(type, status) {
      const users = this.userAccessLevels.filter(
        (i) => !!this.selectedUsers.find((itm) => itm.email === i.email),
      );
      switch (type) {
        case 'overnight':
          users.forEach((i) => {
            i.op_enabled = status;
          });
          this.snackBarText = `Successfully ${
            status ? 'enabled' : 'disabled'
          } access to Overnight Prezentations for ${
            this.selectedUsers.length
          } member${this.selectedUsers.length > 1 ? 's' : ''}`;
          this.snackbar = true;
          break;
        case 'active':
          users.forEach((i) => {
            i.status = status ? 'Active' : 'Disabled';
          });
          this.snackBarText = `Successfully ${
            status ? 'enabled' : 'disabled'
          } access to Prezent platform for ${this.selectedUsers.length} member${
            this.selectedUsers.length > 1 ? 's' : ''
          }`;
          this.snackbar = true;
          break;
        default:
          users.forEach((i) => {
            i.accessLevel = status;
          });
          this.snackBarText = `Successfully changed access level for ${
            this.selectedUsers.length
          } member${this.selectedUsers.length > 1 ? 's' : ''}`;
          this.snackbar = true;
          break;
      }
      this.selectedUsers = [];
      this.totalUsersSelected = 0;
      const { itemsPerPage, page, sortBy, sortDesc } = this.paginationOptions;
      await this.getAdminTeamUsers(itemsPerPage, page, sortDesc, sortBy, '');
      const commonVal = this.bulkActions.find(
        (ele) => ele.value === type,
      ).title;
      trackProfileEvents.profileAdminSettingsUserAccessBulkActionsSelected(
        this.currentUser,
        {
          [TD_COMMON_COLUMN_NAME]: 'Access updated',
          [TD_COMMON_COLUMN_VALUE]: commonVal,
        },
      );
    },
    handleOPAccess(item) {
      updateOPAccess({
        entity: 'user',
        entity_id: [item.email],
        feature: 'overnight-prezentations',
        enable: item.op_enabled,
        // This is very important to send in order to check the OP usage limits.
        access_level: item.op_enabled ? 'full_access' : 'no_access',
        welcomeEmail: true,
      }).then((resp) => {
        this.snackBarText = `Successfully ${
          item.op_enabled ? 'enabled' : 'disabled'
        } access to Overnight Prezentations for ${item.firstName} ${
          item.lastName
        }`;
        this.snackbar = true;
        console.log(resp);
      });
      trackProfileEvents.profileOPUserAccess(this.currentUser, {
        [TD_COMMON_COLUMN_NAME]: 'access changed to',
        [TD_COMMON_COLUMN_VALUE]: item?.op_enabled ? TD_ENABLED : TD_DISABLED,
      });
    },
    getPlanDescription(text) {
      const [knowFeature] = this.currentUser.features.filter(
        (e) => e.feature_name === 'best-practice-library',
      );
      if (knowFeature && !knowFeature.enabled) {
        const detailsText = text
          .split('\n')
          .filter(
            (e) => e.toLowerCase().indexOf('to Best practice guides') === -1,
          );
        const detailsArray = detailsText.map(
          (line, index) =>
            `${line}${index === detailsText.length - 1 ? '' : '\n'}`,
        );
        return detailsArray;
        // return text
        //   .replace('\nLimited access to Know', '')
        //   .replace('\nFull access to Know', '');
      }
      const detailsText = text.split('\n');
      const detailsArray = detailsText.map(
        (line, index) =>
          `${line}${index === detailsText.length - 1 ? '' : '\n'}`,
      );
      // console.log(detailsArray);
      return detailsArray;
    },
    getHeader(availablePlan, currentSubscription) {
      if (
        currentSubscription &&
        currentSubscription.planID === '12346' &&
        availablePlan.id === '12347'
      ) {
        availablePlan.header = 'Everything in Professional plus:';
      }
      return availablePlan.header;
    },
    async refreshCurrentUserInfo() {
      const userinfo = await usersApi.methods.getUserInfo();
      return userinfo;
    },
    closeAddUserModal() {
      this.dialog = false;
    },
    addNewUsers() {
      trackProfileEvents.profileAdminSettingsAddnewUsersClick(this.currentUser);
      if (this.planLicenseAvailable > 0) this.dialog = true;
      else {
        this.showAddlicenseModal();
      }
      if (this.isTrial) {
        this.colleaguesList.forEach((member) => {
          member.role = 'TrialUser';
        });
      } else {
        this.colleaguesList.forEach((member) => {
          member.role = '';
        });
      }
    },
    showAddlicenseModal() {
      this.dialog = false;
      this.$modal.show(
        AddLicensesModal,
        {
          upgrade: this.handleBuyAdditionalLicense,
          plan: this.currentSubscription.plan,
          paymentType: 'license',
          currentNumSeats: this.currentSubscription.numSeats,
          endDate: this.currentSubscription.endDate,
          startDate: this.currentSubscription.startDate,
          subscriptionID: this.currentSubscription.id,
          isPayByCardEnabled:
            this.isPayByCardEnabled() || this.isProfessionalOrEnterprise,
        },
        {
          name: 'AddLicensesModal',
          width: '449px',
          height: '503px',
        },
      );
    },

    async refreshUserAccessTable() {
      this.newUsersAdded = true;
      this.paginationOptions = {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [],
      };
      this.handleAdminUsersTableOptionsChange(this.paginationOptions);
      const userData = await this.refreshCurrentUserInfo();
      this.setCurrentUser(userData);

      if (this.planLicenseAvailable === 1) {
        this.colleaguesList = [
          {
            email: '',
            firstname: '',
            lastname: '',
            role: '',
            existError: '',
            loading: false,
            isExistingTrialUser: false,
          },
        ];
      } else {
        this.colleaguesList = [
          {
            email: '',
            firstname: '',
            lastname: '',
            role: '',
            existError: '',
            loading: false,
            isExistingTrialUser: false,
          },
          {
            email: '',
            firstname: '',
            lastname: '',
            role: '',
            existError: '',
            loading: false,
            isExistingTrialUser: false,
          },
        ];
      }
    },

    getUserEngagement() {
      return this.currentUser.userStats?.engagement || '';
    },

    getLearnStepsCompleted() {
      const learnStepsCompleted =
        this.currentUser.userStats?.learningSteps || 0;
      return getLocalisedNumberInString(learnStepsCompleted ?? '0');
    },

    getSubscriptionAutorenewDate(plan, sub) {
      if (sub) {
        const endDate = Date.parse(sub.endDate);
        let text = this.currentSubscription?.stripeSubscriptionID
          ? 'Auto renews on '
          : 'Expires on ';
        const daysleft = Math.floor(
          (endDate - new Date()) / (1000 * 60 * 60 * 24),
        );
        if (daysleft < 0) {
          text = 'Expired on ';
        }
        if (plan.id !== sub.planID) {
          return '';
        }
        return text + dateFormat(endDate, 'dddd, mmmm dS, yyyy');
      }
      return '';
    },
    getImage(plan) {
      return `/assets/img/profile/${plan.image}`;
    },
    getPrice(plan) {
      if (plan.name.toLowerCase() === 'enterprise') {
        return 'Custom';
      }
      if (
        plan.name.toLowerCase() === 'professional' ||
        plan.price.toLowerCase() === 'free'
      ) {
        return plan.price;
      }
      return this.showAdditionInfo
        ? `$${plan.price}* (plus taxes)`
        : `$${plan.price} (plus taxes)`;
    },
    getDescription(plan, currentSubscription) {
      if (plan.name.toLowerCase() === 'enterprise') {
        return '';
        // return 'per user per year';
      }
      if (plan.name.toLowerCase() !== 'enterprise') {
        return plan.description;
      }
      // return 'per user per year';
      if (currentSubscription.customPrice) {
        return 'per user per year';
      }
      return '';
    },
    // eslint-disable-next-line func-names
    async handleSearchWhenClearIconClicked() {
      const { itemsPerPage, page, sortBy, sortDesc } = this.paginationOptions;
      await this.getAdminTeamUsers(itemsPerPage, page, sortDesc, sortBy, '');
      console.log('getAdminTeamUsers called 2263');
      this.totalUsersSelected = 0;
      this.selectedUsers = [];
      this.focusOnAdminSearch();
    },
    // eslint-disable-next-line func-names
    handleUserSearch: debounce(async function () {
      trackProfileEvents.profileAdminSettingsSearch(this.currentUser, {
        [TD_QUERY]: this.adminSearch,
      });
      const { itemsPerPage, page, sortBy, sortDesc } = this.paginationOptions;
      await this.getAdminTeamUsers(
        itemsPerPage,
        page,
        sortDesc,
        sortBy,
        this.adminSearch,
      );
      this.selectedUsers = [];
      this.totalUsersSelected = 0;
      this.focusOnAdminSearch();
    }, 800),

    noOfDays(ratedDate) {
      const fromDate = new Date(ratedDate);
      const threeMonthAfterDate = fromDate;
      threeMonthAfterDate.setMonth(threeMonthAfterDate.getMonth() + 3);
      const days = Math.floor(
        (threeMonthAfterDate - Date.parse(ratedDate)) / (1000 * 60 * 60 * 24),
      );
      return days;
    },
    daysAgo(ratedDate) {
      const days = Math.floor(
        (new Date() - Date.parse(ratedDate)) / (1000 * 60 * 60 * 24),
      );
      return days;
    },

    handleEditLimit(item) {
      this.editLimit = true;
      if (item.status !== 'Disabled') {
        this.editLimitIndex = this.userAccessLevels.indexOf(item);
      } else {
        this.editLimit = false;
      }
    },
    closeLimitEdit() {
      this.editLimit = false;
      if (this.userAccessLevels[this.editLimitIndex].limit)
        this.userAccessLevels[this.editLimitIndex].limit = Math.min(
          this.userAccessLevels[this.editLimitIndex].limit,
        );
    },

    row_classes(item) {
      if (item.status === 'Disabled') {
        return 'disabled-row';
      }
      return '';
    },

    handleBuyLicense() {
      trackProfileEvents.profileAdminSettingsBuyAdditionalLicense(
        this.currentUser,
      );
      this.$modal.show(
        GreatDecisionModal,
        {
          upgrade: this.handleBuyAdditionalLicense,
          plan: this.currentSubscription.plan,
          paymentType: 'license',
          currentNumSeats: this.currentSubscription.numSeats,
          endDate: this.currentSubscription.endDate,
          startDate: this.currentSubscription.startDate,
          subscriptionID: this.currentSubscription.id,
          isPayByCardEnabled:
            this.isPayByCardEnabled() ||
            this.isProfessionalOrEnterprise ||
            this.currentSubscription.planID === '12348' ||
            this.currentSubscription.planID === '12349',
          isTrial: this.isTrial,
        },
        {
          name: 'GreatDecisionModal',
          width: '449px',
          height: '503px',
        },
      );
    },
    isPayByCardEnabled(subscriptionCardItem, type) {
      const subscriptionCardItemPlanName =
        subscriptionCardItem?.name?.toLowerCase();
      if (
        (subscriptionCardItemPlanName === 'prezent premium' ||
          subscriptionCardItemPlanName === 'prezent standard') &&
        (type === 'renew' ||
          (this.isTrial &&
            this.allowPayByCard &&
            this.currentSubscription.planID === '12345'))
      ) {
        return true;
      }

      if (subscriptionCardItemPlanName === 'enterprise') {
        return false;
      }

      if (subscriptionCardItemPlanName === 'professional') {
        return true;
      }

      return false;
    },
    handleEditPayment() {
      this.$modal.show(
        EditPaymentDetail,
        {
          dialogType: '',
        },
        {
          name: 'EditPaymentDetail',
          width: '995px',
          height: '800px',
        },
      );
    },
    handleSubscription(item, action, isUpgrade = false) {
      if (this.isTrial || isUpgrade || action === 'buy') {
        trackProfileEvents.profileAdminSettingsUpgrade(this.currentUser);
      } else if (action === 'renew') {
        trackProfileEvents.profileAdminSettingsRenew(this.currentUser);
      } else {
        trackProfileEvents.profileAdminSettingsBuy(this.currentUser, {
          [TD_TEAM]: this.currentUser?.team?.name,
        });
      }

      this.$modal.show(
        GreatDecisionModal,
        {
          plan: item,
          paymentType:
            this.isTrial || isUpgrade || action === 'buy' ? 'upgrade' : 'renew',
          subscriptionID: this.currentSubscription.id,
          isPayByCardEnabled: this.isPayByCardEnabled(
            item,
            this.isTrial || isUpgrade || action === 'buy' ? 'upgrade' : 'renew',
          ),
          numSeats: this.currentSubscription.numSeats,
        },
        {
          name: 'GreatDecisionModal',
          width: '449px',
          height: '503px',
        },
      );
    },
    handleCreateOwn() {
      this.$modal.show(
        WelcomeModalDetail,
        {
          addOwn: this.onEnter,
        },
        {
          name: 'WelcomeModalDetailComponent',
          width: '543px',
          height: '283px',
        },
      );
    },
    handleSavePreference() {
      this.setCurrentUserPersonalPreferences(this.personal);
    },
    onEnter(preference) {
      this.ownPreference = preference;
      this.selections.push({
        value: this.ownPreference.toLowerCase(),
        title: this.ownPreference,
      });
      this.ownPreference = '';
    },
    handleQuestion(item) {
      const initialValue = this.personal;
      if (findIndex(initialValue, { title: item.title }) > -1) {
        const updatedValue = filter(
          initialValue,
          (initial) => initial.title !== item.title,
        );
        this.personal = updatedValue;
      } else {
        initialValue.push(item);
        this.personal = initialValue;
      }
    },
    async handleSubmitWish() {
      AnalyticsHandler.addedWish(this.currentUser);
      trackProfileEvents.profileFeatureRequestSubmit(this.currentUser);
      const requester = `${this.currentUser.user.firstName} ${this.currentUser.user.lastName}`;
      const params = {
        type: 'feature-request',
        meta: {
          message: `Requested feature: ${this.wish}`,
          requester,
        },
      };
      sendEmail(params)
        .then(() =>
          MatomoAnalyticsHandler.featureRequested(this.currentUser, this.wish),
        )
        .catch((err) => console.log(err));

      const wishData = {
        id: uuidv4(),
        wish: this.wish,
        upVoteCount: 0,
        downVoteCount: 0,
      };
      await createWishes(wishData)
        .then((value) => {
          console.log(value);
        })
        .catch((err) => console.log(err));

      this.wish = '';
    },
    async loadWishesData() {
      this.loadingWishes = true;
      // const wishes = [];
      // await API.graphql(graphqlOperation(listWishess)).then((wishesData) => {
      //   wishesData.data.listWishess.items.forEach((item) => {
      //     wishes.push(item);
      //   });
      //   const sorted = wishes.sort((a, b) =>
      //     a.upVoteCount - a.downVoteCount > b.upVoteCount - b.downVoteCount
      //       ? -1
      //       : 1,
      //   );
      //   this.topWishes = sorted.slice(0, 3);
      //   this.showWishes = true;
      //   this.wishesKey++;
      // });
      this.loadingWishes = false;
    },
    async initialize() {
      // reset fingerprints and selections
      this.fpQuestions = [...fpTypes];
      this.selections = [...fpSelections];

      this.teamstats = [];
      // const learnstat = this.learnTeamStats.find(
      //   (element) => element.email === this.currentUser.email,
      // );
      if (this.currentUser) {
        this.user.id = this.currentUser.user.id;
        this.user.firstName = this.currentUser.user.firstName;
        this.user.lastName = this.currentUser.user.lastName;
        this.user.companyID = this.currentUser.user.companyID;
        this.user.companyName = this.currentUser.company.name;
        this.user.jobTitle = this.currentUser.user.jobTitle;
        this.user.jobFunction = this.currentUser.user.jobFunction;
        this.user.email = this.currentUser.user.id;
        this.user.defaultTheme = this.currentUser.theme.code
          ? this.currentUser.theme.code
          : '';
      }
      if (this.currentUser && this.currentUser.user.personalPreference) {
        this.personal = [];
        this.currentUser.user.personalPreference.forEach((pref) => {
          const p = {};
          p.key = pref;
          p.title = pref;
          this.personal.push(p);
          const found = this.selections.find(
            (element) => element.title === pref,
          );
          if (!found) this.selections.push(p);
        });
      }
    },

    addMetaToUserObject(user) {
      let status;

      if (
        this.currentActiveSubscription &&
        this.currentActiveSubscription.isExpired
      ) {
        status = 'Expired';
      } else if (user.active) {
        status = user.fingerPrint ? 'Active' : 'Not signed in';
      } else {
        status = 'Disabled';
      }
      const userlevel = {
        username: user.cognitoID,
        name: user.fullName,
        firstName: user.firstName,
        lastName: user.lastName,
        fingerPrint: user.fingerPrint
          ? capitalizeFirstLetter(user.fingerPrint)
          : 'NA',
        email: user.id,
        accessLevel: getFullName(user.role),
        status,
        lastActivity: user.lastActivity,
        defaultTheme: user.defaultTheme ? user.defaultTheme : '',
        profileImageUrl: user.profileImage || '',
        limit: user.downloadLimit,
        used: user.downloadUsed,
        efficiencyRating: user.efficiencyRating ? user.efficiencyRating : -1,
        effectiveRating: user.effectiveRating ? user.effectiveRating : -1,
        op_enabled: !!user.op_enabled,
        fpVisibleToCompany: user?.fpVisibleToCompany,
        fpVisibleToTeam: user?.fpVisibleToTeam,
      };
      return userlevel;
    },
    handleAllUsersSelect(e) {
      if (!e) {
        this.selectedUsers = [];
        this.totalUsersSelected = 0;
      } else {
        this.userAccessLevels.forEach((itm) => {
          if (!this.selectedUsers.find((it) => it.email === itm.email)) {
            this.selectedUsers.push(itm);
          }
        });
        this.totalUsersSelected = this.adminUsersTotal - 1;
      }
    },
    // async resetTableForAdmin() {
    //   const { itemsPerPage, page, sortBy, sortDesc } = this.paginationOptions;
    //   await this.getAdminTeamUsers(
    //     itemsPerPage,
    //     page,
    //     sortDesc,
    //     sortBy,
    //     this.adminSearch,
    //   );
    // },
    async handleAdminUsersTableOptionsChange(e) {
      const { itemsPerPage, page, sortBy, sortDesc } = e;
      this.adminUsersPageSize = itemsPerPage;
      this.adminUsersPageNumber = page;
      if (!isEqual(e, this.paginationOptions) || this.newUsersAdded) {
        await this.getAdminTeamUsers(
          itemsPerPage,
          page,
          sortDesc,
          sortBy,
          this.adminSearch,
        );
      }
      this.paginationOptions = { ...e };
      if (this.adminUsersTotal - 1 === this.totalUsersSelected) {
        this.userAccessLevels.forEach((itm) => {
          if (!this.selectedUsers.find((it) => it.email === itm.email)) {
            this.selectedUsers.push(itm);
          }
        });
      }
    },
    async getAdminTeamUsers(
      pageSize,
      pageNumber,
      sortingOrder,
      sortingCol,
      searchQuery,
    ) {
      this.loadingUser = true;
      await getAdminSettingsTeamUsers({
        size: pageSize,
        page: pageNumber,
        sortOrder: sortingOrder,
        sortColumn: sortingCol,
        searchText: searchQuery,
      })
        .then((resp) => {
          // this.selectedUsers = [];
          if (resp && resp.data && resp.data.length > 0) {
            this.adminUsersTotal = parseInt(resp.totalCount, 10);
            const newUsers = [];
            resp.data.forEach((user) => {
              const userlevel = this.addMetaToUserObject(user);
              if (
                user &&
                ((this.currentUser.user.role !== 'TrialUser' &&
                  user.role !== 'TrialUser') ||
                  this.currentUser.user.role === 'TrialUser')
              ) {
                newUsers.push(userlevel);
              }
            });
            this.userAccessLevels = [...newUsers];
          } else {
            this.userAccessLevels = [];
            this.paginationOptions = {
              page: 1,
              itemsPerPage: 10,
              sortBy: [],
              sortDesc: [],
            };
            this.adminUsersTotal = 0;
            this.totalUsersSelected = 0;
            this.adminUsersEditingItem = {};
            this.adminUsersEditingIndex = -1;
          }
        })
        .catch((err) => {
          console.log(err);
        });
      this.loadingUser = false;
    },

    handleEditUserAccessLevel(item) {
      if (
        item.status !== 'Disabled' &&
        item.email !== this.currentUser.user.id
      ) {
        this.adminUsersEditingItem = item;
        this.adminUsersEditingIndex = this.userAccessLevels.findIndex(
          (itm) => itm.email === item.email,
        );
        this.accessLevelEdit = !this.accessLevelEdit;
      } else {
        this.accessLevelEdit = false;
      }
    },
    closeEditAccessLevel() {
      this.adminUsersEditingItem = {};
      this.adminUsersEditingIndex = -1;
      this.accessLevelEdit = false;
    },

    handleBuyAdditionalLicense(numSeats) {
      this.currentSubscription.numSeats += numSeats;
    },

    async handleConfirmEditAccessLevel() {
      const role =
        this.userAccessLevels[this.adminUsersEditingIndex].accessLevel;
      try {
        const userInfo = {
          id: this.adminUsersEditingItem.email,
          role,
          firstName: this.adminUsersEditingItem.firstName,
          lastName: this.adminUsersEditingItem.lastName,
        };
        await usersApi.methods.updateUserByAdmin(userInfo);
      } catch (err) {
        console.log(err);
      }
      this.accessLevelEdit = false;
    },
    handleEnableUser(item) {
      this.adminUsersEditingIndex = this.userAccessLevels.findIndex(
        (itm) => itm.email === item.email,
      );
      this.adminUsersEditingItem = item;
      this.dialogEnable = true;
    },
    enableUserConfirm() {
      this.userAccessLevels[this.adminUsersEditingIndex].status = 'Active';
      try {
        usersApi.methods
          .updateUserByAdmin({
            id: this.adminUsersEditingItem.email,
            active: true,
          })
          .then(async () => {
            console.log('updated user successfully');
            trackProfileEvents.profileAdminSettingsEnableOKclick(
              this.currentUser,
            );

            const userData = await this.refreshCurrentUserInfo();
            this.setCurrentUser(userData);
          })
          .catch((err) => {
            console.log('error while enabling the user', err);
          });
      } catch (err) {
        console.log(err);
      }
      this.closeEnable();
    },

    deleteItem(item) {
      this.adminUsersEditingItem = item;
      this.adminUsersEditingIndex = this.userAccessLevels.findIndex(
        (itm) => itm.email === item.email,
      );
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.userAccessLevels[this.adminUsersEditingIndex].status = 'Disabled';
      try {
        const inputPayload = {
          id: this.adminUsersEditingItem.email,
          active: false,
        };

        usersApi.methods
          .updateUserByAdmin(inputPayload)
          .then(async (data) => {
            console.log(data);
            AnalyticsHandler.userDisabled(this.currentUser, inputPayload);
            AnalyticsHandler.logUserStats(inputPayload);
            trackProfileEvents.profileAdminSettingsDisableOKclick(
              this.currentUser,
            );
            const userData = await this.refreshCurrentUserInfo();
            this.setCurrentUser(userData);
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (err) {
        console.log(err);
      }
      this.closeDelete();
    },
    close() {
      this.dialog = false;
      this.emailSent = false;
    },
    setEmailId(emailId) {
      this.email = emailId;
    },
    closeDelete() {
      this.adminUsersEditingIndex = -1;
      this.adminUsersEditingItem = {};
      this.dialogDelete = false;
    },
    closeEnable() {
      this.adminUsersEditingIndex = -1;
      this.adminUsersEditingItem = {};
      this.dialogEnable = false;
    },
    getRandomString(bytes) {
      const randomValues = new Uint8Array(bytes);
      window.crypto.getRandomValues(randomValues);
      return Array.from(randomValues).map(this.intToHex).join('');
    },
    intToHex(nr) {
      return nr.toString(16).padStart(2, '0');
    },
    resetFormValues() {
      ['firstName', 'lastName', 'email', 'role', 'messageToNew'].forEach(
        // eslint-disable-next-line no-return-assign
        (variable) => (this[`${variable}`] = null),
      );
    },
    handleSignOut() {
      clearCachedUser();
      Auth.signOut();
    },
    async setTab(tabName) {
      if (this.$route.query.query) {
        EventBus.$emit('CLEAR_SEARCH');
      }
      this.$router
        .push({
          path: `/profile?tab=${tabName}`,
          replace: true,
        })
        .catch(() => {});
      this.currentTab = tabName;
      switch (tabName) {
        case 'basics':
          trackProfileEvents.profileBasicClick(this.currentUser);
          break;
        case 'fingerprint':
          trackProfileEvents.profileFingerprintClick(this.currentUser);
          break;

        case 'templates':
          trackProfileEvents.profileTemplatesClick(this.currentUser);
          break;
        case 'storylines':
          trackProfileEvents.profileStorylinesClick(this.currentUser);
          break;
        case 'metrics':
          trackProfileEvents.profileMetricsClick(this.currentUser);
          break;
        case 'wish':
          trackProfileEvents.profileFeatureRequestClick(this.currentUser);
          await this.loadWishesData();
          break;
        case 'admin':
          trackProfileEvents.profileAdminSettingsClick(this.currentUser);
          await this.loadAdminSettingsData();
          break;
        default:
          break;
      }
    },

    async loadAdminSettingsData() {
      this.loadingAvailablePlans = true;
      await this.loadAvailablePlans(this.currentUser);
      this.loadingAvailablePlans = false;
    },

    formatDate(value) {
      return value ? moment(value).format('MMM DD, YYYY') : 'NA';
    },

    formatAmountWithCommas(num) {
      if (num % 1 === 0)
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return num
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },

    async getFundsAdminMetrics() {
      await getFundsUtilizationMetrics()
        .then((resp) => {
          this.overnightUtilizationOvertime = {
            fundsUtilized: resp?.data?.fundsUtilization || [],
            year: resp?.data?.lastInvoiceYear || 0,
          };
          this.teamFundsAvailable = resp?.data?.balance || 0;
          this.teamFundsAdminMetrics = [
            {
              text: 'Funds available',
              value: `$${this.formatAmountWithCommas(
                resp?.data?.balance || 0,
              )}`,
            },
            {
              text: `Last recharge on ${
                resp?.data?.lastRecharge?.date
                  ? moment(resp?.data?.lastRecharge?.date).format('MM/DD/YYYY')
                  : 'NA'
              }`,
              value: `$${this.formatAmountWithCommas(
                resp?.data?.lastRecharge?.amount || 0,
              )}`,
            },
            {
              text: 'Funds utilized since last recharge',
              value: `$${this.formatAmountWithCommas(
                resp?.data?.fundsUtilizedSinceLastRecharge || 0,
              )}`,
            },
          ];
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleAddFundsCTA() {
      this.$modal.show(
        GreatDecisionModal,
        {
          paymentType: 'fund',
          isPayByCardEnabled: true,
        },
        {
          name: 'GreatDecisionModal',
          width: '449px',
          height: '503px',
          styles: {
            borderRadius: '12px',
          },
        },
      );
    },

    // getCardHeight() {
    //   function to change the card height according to screen resolution.
    //   if (
    //     this.currentUser?.company?.companyType.toLowerCase() === 'mid market'
    //   ) {
    //     if (window.innerWidth <= 1020) {
    //       this.cardHeight = this.leftNavExpanded ? 1020 : 925;
    //     } else if (window.innerWidth > 1020 && window.innerWidth <= 1110) {
    //       this.cardHeight = this.leftNavExpanded ? 955 : 890;
    //     } else if (window.innerWidth > 1110 && window.innerWidth <= 1200) {
    //       this.cardHeight = this.leftNavExpanded ? 930 : 820;
    //     } else if (window.innerWidth > 1200 && window.innerWidth <= 1300) {
    //       this.cardHeight = this.leftNavExpanded ? 930 : 800;
    //     } else if (window.innerWidth > 1300 && window.innerWidth <= 1400) {
    //       this.cardHeight = this.leftNavExpanded ? 825 : 800;
    //     } else if (window.innerWidth > 1400 && window.innerWidth <= 1500) {
    //       this.cardHeight = this.leftNavExpanded ? 810 : 790;
    //     } else if (window.innerWidth > 1500 && window.innerWidth <= 1600) {
    //       this.cardHeight = this.leftNavExpanded ? 800 : 780;
    //     } else if (window.innerWidth > 1600 && window.innerWidth <= 1700) {
    //       this.cardHeight = 775;
    //     } else {
    //       this.cardHeight = this.leftNavExpanded ? 775 : 755;
    //     }
    //   } else if (
    //     this.currentUser?.company?.companyType.toLowerCase() !== 'mid market'
    //   ) {
    //     if (window.innerWidth <= 1020) {
    //       this.cardHeight = this.leftNavExpanded ? 1150 : 925;
    //     } else if (window.innerWidth > 1020 && window.innerWidth <= 1110) {
    //       this.cardHeight = this.leftNavExpanded ? 1100 : 890;
    //     } else if (window.innerWidth > 1110 && window.innerWidth <= 1200) {
    //       this.cardHeight = this.leftNavExpanded ? 940 : 860;
    //     } else if (window.innerWidth > 1200 && window.innerWidth <= 1300) {
    //       this.cardHeight = this.leftNavExpanded ? 940 : 860;
    //     } else if (window.innerWidth > 1300 && window.innerWidth <= 1400) {
    //       this.cardHeight = this.leftNavExpanded ? 880 : 800;
    //     } else if (window.innerWidth > 1400 && window.innerWidth <= 1500) {
    //       this.cardHeight = this.leftNavExpanded ? 860 : 790;
    //     } else if (window.innerWidth > 1500 && window.innerWidth <= 1600) {
    //       this.cardHeight = this.leftNavExpanded ? 860 : 760;
    //     } else if (window.innerWidth > 1600 && window.innerWidth <= 1700) {
    //       this.cardHeight = 760;
    //     } else {
    //       this.cardHeight = this.leftNavExpanded ? 760 : 750;
    //     }
    //   }
    // },
  },
  async beforeMount() {
    const tab = this.$route?.query?.tab;
    if (tab) {
      this.currentTab = tab;
    } else {
      this.$router.push({
        path: `/profile?tab=basics`,
        replace: true,
      });
    }
    const userData = await this.refreshCurrentUserInfo();
    this.setCurrentUser(userData);
    // await this.loadAvailablePlans(this.currentUser);
  },
  async mounted() {
    // console.log(this.currentSubscription.planID);
    setTimeout(() => {
      EventBus.$emit('MINIMIZE_LEFTNAV');
    }, 50);

    if (this.$route.query.popup) {
      this.$modal.show(
        AddTeamFunds,
        {},
        {
          name: 'AddTeamFunds',
          width: '340px',
          height: '436px',
        },
      );
    }
    if (
      !this.getOPLimitedBanner &&
      ['full_access', 'trial_access'].includes(this.getOPAccessLevel)
    ) {
      this.bulkActions.splice(1, 0, {
        title: 'Set access to Overnight Prezentations',
        display_title: 'profile.bulkActionModal.accessToOp',
        value: 'overnight',
      });
      this.profileHeaders.splice(5, 0, {
        text: 'Overnight Prezentations',
        title: 'overnightPresentations.overnightPresentation',
        value: 'op_enabled',
        sortable: false,
      });
    }
    if (this.isTrial) {
      this.bulkActions = this.bulkActions.filter(
        (action) => action.value !== 'access',
      );
    }
    // this.$root.$on('upgrade-clicked', () => {
    //   setTimeout(() => {
    //     this.scrollIntoSubscription();
    //   }, 200);
    // });
    this.learnTeamStats = await LearnApi.methods.getLearnTeamStats();
    await this.initialize();
    if (this.$route.query.tab === 'admin') {
      await this.loadAdminSettingsData();
    }
    if (this.isAdmin && this.getOPAccessLevel !== 'no_access') {
      await this.getFundsAdminMetrics();
    }
    AnalyticsHandler.loadedProfile(this.currentUser);
    this.currentSubscription = this.currentActiveSubscription;
    if (this.$route.hash === '#subscribe')
      setTimeout(() => {
        this.scrollIntoSubscription();
      }, 200);
    // const { hash } = location;
    // if (hash?.includes('#subscribe')) {
    //   const element = document.getElementById('subscribe');
    //   element?.scrollIntoView();
    // }
    // this.fontSizeSetter();
    // if (this.isTrial) {
    //   const accessLevelIndex = this.profileHeaders.findIndex(
    //     (header) => header.value === 'accessLevel',
    //   );
    //   if (accessLevelIndex !== -1) {
    //     this.profileHeaders.splice(accessLevelIndex, 1); // Remove the header
    //   }
    // }
  },
  beforeDestroy() {
    EventBus.$off('LEFTNAV_EXPANDED');
    EventBus.$off('LEFTNAV_MINIMIZED');
    this.$root.$off('upgrade-clicked');
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/scss/variables.scss', '@/scss/app.scss';
.header-title__wrapper {
  display: flex;
  position: relative;

  .header-span {
    position: relative;
  }
}
.badge-container {
  position: absolute;
  top: -13px;
  right: 20px;
  z-index: 4;
}
.badge-container-team-funds {
  position: absolute;
  bottom: 270px;
  left: 132px;
}
.tab-wrapper {
  ::v-deep .v-tabs-slider-wrapper {
    height: 2px !important;
  }

  .fingerprint-wrapper {
    ::v-deep .v-tabs-slider-wrapper {
      height: 48px !important;
    }
  }
}
.available-themes {
  align-items: center;
  display: flex;
  .spacer {
    width: 30%;
  }
  .template-search-input {
    background-color: #fff;
    border-radius: 24px;
    box-shadow: 0 2px 5px 1px rgba(64, 60, 67, 0.16);
    margin-bottom: 14px;
    margin-right: 40px;
    margin-top: 0px;
    max-width: 394px;
    padding: 8px 15px 8px 23px;
    width: 394px;
    z-index: 3;
    ::v-deep .v-input__slot::before {
      border: unset;
    }
  }
}
.available-themes-container {
  padding-top: 20px;
}
.add-more-themes {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  .v-image {
    margin: 20px;
  }
  a {
    text-decoration: none;
  }
}
.fingerprint-wrapper {
  overflow: hidden;
}
.feature-request-content {
  border-radius: 18px;
  font-size: 16px;
  padding: 5px;
  text-align: left;

  &:hover {
    background-color: #93969717;
  }
}

::v-deep .disabled-row {
  color: gray;
  .v-list-item__content {
    color: gray;
    .v-list-item__subtitle {
      color: gray;
    }
  }
}
.hide-visibility {
  visibility: hidden;
}
.thumb-value {
  padding-left: 0px;
  padding-right: 5px;
}
.v-btn.v-size--x-small {
  font-size: 0.4rem !important;
  color: $zen-blue-default;
}
.flex-container-profileimage-wrapper {
  display: flex;
  width: 100%;
}
.flex-child-profileimage-textbox {
  flex: 1;
  margin-right: 10px;
}
.flex-child-profileimage-button {
  flex: 1;
  max-width: 20px;
  .v-btn {
    min-width: 0;
    padding: 0px !important;
    color: $zen-blue-default;
  }
}
.vtab {
  font-weight: 300;
  font-size: 1.1rem;
  letter-spacing: normal;
  &:hover {
    text-decoration: none;
  }
}
.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  font-weight: bold !important;
  font-size: 16px !important;
}
::v-deep.v-data-table > .v-data-footer {
  padding-bottom: 20px !important;
}
.profile-wrapper {
  width: 100%;
  height: 100%;
  text-align: center;
  overflow-x: hidden;
  .trial-banner {
    background-color: whitesmoke;
    margin-top: 5px;
    .banner-content {
      font-size: 20px;
      font-weight: 500;
      padding: 5px;
      a {
        text-decoration: none;
      }
    }
  }
  .tab-wrapper {
    margin: auto;
    margin-top: 30px;
    width: 93%;
    .v-tab {
      text-transform: none !important;
      color: black;
    }
    .tab-footer {
      justify-content: right;
      margin: 30px;
      overflow-x: hidden;
      color: grey;
      a {
        color: inherit;
      }
      p {
        margin: 15px;
      }
    }

    ::v-deep .tab-content {
      overflow-y: auto;

      &::-webkit-scrollbar {
        height: 8px;
      }
      &.basic-content {
        display: flex;
        align-items: flex-start;
        justify-content: left;
        margin-top: 30px;
        max-width: 80%;
        overflow-x: hidden;
        .left-panel {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          margin-right: 10px;

          .access-label-title {
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
          }
          .access-label-desc {
            font-size: 20px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            margin-bottom: 21px;
          }

          .profile-image-input {
            cursor: pointer;
            margin-top: 16px;
            .v-input__slot {
              left: -12px;
              margin-bottom: 0px;

              &::before {
                display: none;
              }
            }
            .v-input__prepend-outer {
              visibility: hidden;
            }

            label {
              width: 100%;
              left: 5px !important;
              font-size: 16px;
              text-transform: capitalize;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              text-align: center;
              color: $zen-blue-default;
            }
          }

          .profile-image-wrapper {
            border-radius: 50%;
            border: 7px solid white;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
            }
          }
          .profile-image-upload {
            height: 140px;
            width: 140px;
          }
          .profile-user-avatar {
            height: 132px;
            min-width: 127px;
            width: 127px;
          }

          .edit-profile-btn {
            color: $zen-blue-default;
            text-transform: capitalize;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            &:hover {
              text-decoration: underline;
            }
          }

          .log-out-button {
            width: 104px;
            height: 32px;
            border-radius: 16px;
            border: solid 1px $zen-blue-default;
            background: transparent;
            color: $zen-blue-default;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            text-transform: capitalize;
          }

          .v-input {
            width: 80%;
          }
        }

        .right-panel {
          margin-right: -100px;
          .info-title {
            text-align: left;
          }
        }
      }

      &.fingerprint-content {
        height: 100%;
        overflow: hidden;
        margin-top: 30px;
        width: 100%;

        .btn-retake {
          color: #20a7e0;
          cursor: pointer;
          font-size: 14px;
          margin-bottom: 20px;

          .v-icon {
            color: #20a7e0;
            font-size: 14px;
          }
        }

        .fingerprint-tab {
          justify-content: start;
        }

        .finger-prints-wrapper {
          padding: 50px 20px 16px 2px;
        }
      }

      &.metrics-content {
        .impact {
          display: flex;
          justify-content: space-between;
          align-items: center;
          left: 0px;
          top: -30px;
          font-size: 24px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          height: 80px;
          margin: 18px 0px;

          .adminStatsToggle {
            .v-input {
              margin-top: 0;
              .v-input__control {
                flex-direction: row !important;
                .v-input__slot {
                  .v-input--radio-group__input {
                    flex-direction: row !important;
                    .v-radio {
                      margin: 0px 14px 0px 0px;
                      .v-input--selection-controls__input {
                        margin-right: 4px;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .bottom-level {
          position: relative;
          margin-top: 36px;
          width: 100%;
          height: 100%;
          table th {
            color: #21a7e0 !important;
            font-size: 16px !important;
            font-weight: normal;
            // min-width: 150px;
            padding-top: 10px;
            padding-bottom: 10px;
          }
          table td {
            border-bottom: none;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
          }

          .audience-item {
            padding: 0px;
          }

          .detailed {
            text-align: left;
            font-size: 24px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            margin: 0px 0px 12px 0px;
          }
          .admin-table-toolbar-title {
            font-size: 16px;
            text-align: left;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            margin-bottom: 14px;
          }
          .admin-table-search-input {
            padding: 4px 8px 4px 11px;
            z-index: 3;
            border-radius: 24px;
            position: absolute;
            left: 5px;
            bottom: 20px;
            min-width: 500px;
            border: 1px solid transparent;
            background-color: #fff;
            box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);

            .v-input__slot::before {
              display: none;
            }
          }

          .fullTable {
            box-shadow: none !important;
            margin-top: 0px !important;
            .v-data-table {
              box-shadow: none !important;
              .v-data-table__wrapper {
                .v-data-table-header {
                  tr {
                    // height: 60px;
                    th {
                      white-space: nowrap;
                      // vertical-align: bottom;
                      // padding: 10px 4px 10px 4px !important;
                    }
                  }
                }
                .text-start {
                  position: sticky;
                  background-color: white;
                  left: 0;
                  z-index: 1;
                }
                // tbody {
                //   tr {
                //     .text-start {
                //       max-width: 250px !important;
                //     }
                //   }
                // }
              }
            }
          }

          .smallTable {
            box-shadow: none !important;
            margin-top: 0px !important;
            .v-data-table {
              box-shadow: none !important;
              .v-data-table__wrapper {
                .v-data-table-header {
                  tr {
                    height: 60px;
                    th {
                      vertical-align: bottom;
                      padding: 10px 13px 10px 13px !important;
                    }
                  }
                }
                tbody {
                  tr {
                    .text-start {
                      max-width: 250px !important;
                    }
                  }
                }
              }
            }
          }

          .bold {
            font-size: 20px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center !important;
          }

          .gray {
            color: #b6b6b6;
          }

          &.normal-user-panel {
            .firstRow,
            .statsRow {
              // display: flex;
              margin: 48px 0px;
            }
            .firstRow {
              .engagementContainer {
                display: flex;
                box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
                border-radius: 8px;
                // margin-right: 48px;
                width: fit-content;
                .imgContainer {
                  background-color: #daf4fc;
                  border-radius: 8px 0px 0px 8px;
                  width: 151px;
                  .imgText {
                    color: rgba(0, 0, 0, 0.54);
                  }
                }
              }
              .engagementContainer {
                margin: 0px 0px 48px 0px;
                .valueContainer {
                  width: 220px;
                  color: rgba(0, 0, 0, 0.54);
                  padding: 82px 0px;
                }
              }
            }
            .statsRow {
              .statsContainer {
                display: flex;
                box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
                border-radius: 8px;
                // margin-right: 48px;
                width: fit-content;
                margin: 0px 0px 48px 0px;
                .imgContainer {
                  background-color: #daf4fc;
                  border-radius: 8px 0px 0px 8px;
                  width: 151px;
                  .imgContent {
                    width: 132px;
                    height: 155px;
                    margin: 0 auto;
                    img {
                      width: 100%;
                      height: 100%;
                    }
                  }
                  .imgText {
                    color: rgba(0, 0, 0, 0.54);
                    padding: 10px;
                  }
                }
                // .imgContainer {
                //   background-color: #daf4fc;
                //   border-radius: 8px 0px 0px 8px;
                //   width: 151px;
                //   .imgText {
                //     color: rgba(0, 0, 0, 0.54);
                //     line-height: 22px;
                //   }
                // }
                .valueContainer {
                  display: flex;
                  .cardValueContainer,
                  .teamFunds {
                    width: 220px;
                    color: rgba(0, 0, 0, 0.54);
                    padding: 70px 0px;
                  }
                  .cardValueContainer {
                    border-right: 1px solid rgb(193 187 187 / 54%);
                    .cardValueMetric {
                      color: grey;
                      font-size: 17px;
                      font-weight: 600;
                    }
                  }
                  .teamFunds {
                    .teamFundsMetric {
                      color: grey;
                      font-size: 17px;
                      font-weight: 600;
                    }
                  }
                }
              }
            }
          }
        }
      }

      &.admin-settings {
        width: 100%;
        height: 100%;
        overflow: hidden;
        padding-top: 30px;

        .trial-encourage {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 50px 0;
          .trial-encourage-action {
            p {
              font-size: 18px;
            }
          }
        }
        .admin-settings-header {
          font-size: 24px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          margin-bottom: 12px;
          padding: 0px;
        }
        .user-panel {
          border-radius: 0px;
          box-shadow: 0px 1px 0px rgb(0 0 0 / 20%);

          .user-access-checkbox.v-input--indeterminate,
          .user-access-checkbox.v-input--is-dirty {
            .v-icon {
              color: rgb(33, 167, 224);
              caret-color: rgb(33, 167, 224);
            }
          }
        }
        .v-toolbar__content {
          padding-left: 0px !important;
        }
        .admin-table-toolbar-title {
          font-size: 20px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          margin-bottom: 14px;
        }

        .admin-table-toolbar {
          min-width: 380px;
          padding-left: 3px;
        }

        .admin-table-search-input {
          font-size: 14px;
          z-index: 3;
          height: 40px !important;
          border-radius: 24px;
          border: 1px solid transparent;
          padding: 0px 0px 5px 2px;
          background-color: #fff;
          box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
          .v-input__control {
            input {
              width: 322.477px;
              padding: 7.5px 0px 7.5px 0px;
            }
            min-height: 40px !important;
          }
          .v-input__slot::before {
            display: none;
          }
        }

        .bulk-actions {
          font-family: 'Lato';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          color: #000000;
          margin-left: 30px;
          cursor: pointer;
        }

        .cursor {
          cursor: pointer;
        }
        .v-data-table__wrapper {
          margin-top: 37px;
          &::-webkit-scrollbar {
            height: 7px !important;
          }
          &::-webkit-scrollbar-thumb {
            background-color: #b6b6b6 !important;
          }
        }

        th {
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #21a7e0 !important;
          font-size: 16px !important;
          font-weight: normal;
          min-width: 150px;
        }
        tbody {
          font-size: 16px !important;

          .audience-item {
            padding: 0px;
          }
        }
        .admin-table-access-level {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          img {
            margin-left: 18px;
            cursor: pointer;
            visibility: hidden;
          }

          &:hover {
            img {
              visibility: visible;
            }
          }
        }

        .admin-table-limit-normal {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          img {
            margin-left: 18px;
            cursor: pointer;
            visibility: hidden;
          }

          &:hover {
            img {
              visibility: visible;
            }
          }
        }

        .admin-table-limit-edit {
          position: relative;

          .v-input__slot {
            margin: 0;

            &::before,
            &::after {
              display: none;
            }

            input {
              background: white;
              padding: 8px 8px 8px 10px;
              border-radius: 8px;
              box-shadow: inset 0 1px 3px 0 #050708;
            }
          }

          button {
            position: absolute;
            right: -2px;
            top: 11px;
            font-size: 20px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #7b7b7b;
            text-transform: capitalize;
            background: transparent;
            width: fit-content !important;

            &:hover {
              color: #21a7e0;
              background: transparent;
            }
          }
        }

        tbody td {
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
        }

        .admin-setting-table-action-trash {
          color: blue;
          cursor: pointer;
        }
        .admin-setting-table-action-activate {
          cursor: pointer;
          // margin-left: -5px !important;
        }
        .table-bottom-info {
          display: flex;
          margin-top: 10px;
          padding-right: 10px;
          margin-bottom: 36px;
          padding-bottom: 32px;
          border-bottom: 1px solid #7b7b7b;
          justify-content: flex-end;
          .table-bottom-info-top {
            display: flex;
            text-align: right;
            flex-direction: row;
            justify-content: flex-end;
            width: 600px;
            font-size: 14px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            .table-bottom-info-top-child {
              width: 300px;
              text-align: center;
            }
          }
          .table-bottom-info-bottom {
            text-align: right;
            font-size: 14px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
          }
        }

        .subscriptions-panel {
          // box-shadow: 0px 1px 0px rgb(0 0 0 / 20%);
          .subscriptions-panel-header {
            font-size: 24px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            margin-bottom: 18px;
          }
          .know-more {
            font-size: 24px;
            font-weight: bold;
            padding-bottom: 32px;
            border-bottom: solid 1px #7b7b7b;
            margin-bottom: 35px;
          }
          .subscriptions-wrapper {
            width: 100%;
            padding-left: 5px;
            padding-right: 5px;
            display: flex;
            align-items: stretch;
            text-align: left;
            padding-bottom: 35px;
            margin-bottom: 35px;
            justify-content: space-between;
            .subscription-wrapper {
              min-height: 100%;
              border-radius: 16px;
              box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
              padding: 28px 17px 0px 17px;
              position: relative;
              text-align: center;

              .subscription-cta {
                align-items: center;
                display: flex;
                flex-direction: column;
                height: 100px;
                justify-content: flex-end;

                .autoRenewToggle {
                  .v-input {
                    margin: 0px !important;
                    padding: 0px !important;
                  }
                }

                .subscription-btn {
                  color: white;
                  font-size: 24px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: normal;
                  letter-spacing: normal;
                  text-align: center;
                  text-transform: none;
                }
              }

              .subscription-wrapper-title {
                display: flex;
                font-size: 23px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                justify-content: center;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                height: 35px;
                .current-subscription-check {
                  color: $zen-blue-default;
                  font-size: 30px;
                  margin-right: 5px;
                }
              }

              .subscription-wrapper-img {
                align-items: center;
                display: flex;
                height: 135px;
                justify-content: center;
                margin-bottom: 10px;
              }

              .subscription-wrapper-price {
                font-size: 22px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #040202;
              }

              .subscription-wrapper-desc {
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                height: 19px;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #7b7b7b;
              }

              .subscription-wrapper-header {
                align-items: flex-start;
                display: flex;
                font-size: 15px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                justify-content: flex-start;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
                height: 8px;
                margin-bottom: 35px;
              }
              .subscription-wrapper-detail {
                // align-items: center;
                // display: flex;
                // font-size: 13px;
                // font-weight: normal;
                // font-stretch: normal;
                // font-style: normal;
                // justify-content: center;
                // line-height: normal;
                // letter-spacing: normal;
                // text-align: center;
                // height: 100px;
                // white-space: pre-wrap;
                // margin-top: 10px;

                .subscription-wrapper-detail {
                  align-items: flex-start;
                  display: flex;
                  font-size: 15px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  justify-content: flex-start;
                  line-height: 26px;
                  letter-spacing: normal;
                  text-align: left;
                  // height: 100px;
                  // white-space: pre-wrap;
                  // margin-top: 10px;
                }

                .tick-mark {
                  display: inline-block;
                  width: 14px;
                  height: 30px;
                  margin-right: 4px;
                }
              }

              .subscription-current-desc {
                align-items: center;
                display: flex;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                justify-content: center;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
              }
              .subscription-unavailable {
                font-size: 18px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #7b7b7b;
                width: 100%;
              }
              .platform-fee-disclaimer {
                position: absolute;
                top: calc(100% - 50px);
                width: 90%;
                text-align: center;
                .red-text {
                  color: red;
                  text-align: center;
                }
                p {
                  margin-bottom: 0 !important;
                  align-items: center;
                  display: flex;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  justify-content: center;
                  line-height: normal;
                  letter-spacing: normal;
                  text-align: center;
                }
              }

              .subscription-current {
                margin-left: 0px;
                text-align: center;

                .subscription-current-header {
                  font-weight: bold;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: normal;
                  letter-spacing: normal;
                  text-align: center;
                  color: #21a7e0;
                }
                .subscription-current-desc {
                  font-size: 16px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: normal;
                  letter-spacing: normal;
                  text-align: center;
                }
              }
            }
          }
        }

        .licenses-panel {
          box-shadow: 0px 1px 0px rgb(0 0 0 / 20%);
          margin-bottom: 18px;
          padding-bottom: 32px;

          .licenses-panel-header {
            font-size: 24px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            margin-bottom: 18px;
            margin-top: 28px;
          }
          .licenses-info {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 30px;

            .license-info-item {
              width: 284px;
              &:nth-child(2) {
                margin: 0 50px;
              }

              .license-info-item-title {
                font-size: 20px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
              }

              .license-info-item-value {
                font-size: 20px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                text-align: left;
                letter-spacing: normal;
              }
            }
          }

          .licenses-action {
            display: flex;
            align-items: center;
            justify-content: flex-start;
          }
        }
        .teamFundsContainer {
          position: relative;
          padding: 32px 0px;
          box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.2);
        }
        .overnightServicesContainer {
          padding: 32px 0px;
          box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.2);
          .sectionTitle {
            font-size: 24px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            margin: 0px 0px 36px 0px;
          }
        }

        .add-ons-panel {
          padding-bottom: 149px;
          justify-content: center;
          text-align: center;

          .add-ons-panel-title {
            font-size: 24px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            margin-bottom: 18px;
          }
          .add-ons-items-container {
            width: 100%;
            padding-left: 5px;
            padding-right: 5px;
            display: flex;
            align-items: center;
            text-align: left;
            padding-bottom: 32px;
            margin-bottom: 35px;
            .add-ons-items {
              align-items: center;
              justify-content: flex-start;
              margin-bottom: 31px;
              padding: 0 5px;

              .add-ons-item {
                width: 312px;
                height: 372px;
                border-radius: 16px;
                box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
                padding: 28px 17px 39px 17px;
                text-align: center;

                &:first-child {
                  margin-right: 182px;
                }

                .add-ons-item-title {
                  font-size: 24px;
                  font-weight: bold;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: normal;
                  letter-spacing: normal;
                  text-align: center;
                  height: 58px;
                }

                .add-ons-item-img {
                  margin-bottom: 17px;
                }

                .add-ons-item-price {
                  font-size: 24px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: normal;
                  letter-spacing: normal;
                  text-align: center;
                  color: #7b7b7b;
                  margin-bottom: 29px;
                }

                .add-ons-item-desc {
                  font-size: 20px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: normal;
                  letter-spacing: normal;
                  text-align: center;
                }
              }
            }
          }

          .add-ons-panel-action {
            display: flex;
            align-items: center;
            justify-content: flex-start;
          }

          button {
            font-size: 24px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: white;
            text-transform: none;
          }
        }
      }

      &.wish-content {
        width: 60%;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        .wish-title {
          font-size: 24px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          margin: 54px auto 25px 0;
        }

        .v-list {
          overflow-x: hidden;
        }

        .v-list-item {
          margin-left: -20px;
          padding: 0 0 0 16px;

          .feature-action-icon {
            cursor: pointer;
          }
        }

        .description {
          font-size: 16px;
          font-stretch: normal;
          font-style: normal;
          font-weight: normal;
          line-height: normal;
          letter-spacing: normal;
          margin-bottom: 8px;
        }

        .wish__textarea-wrapper {
          padding: 1rem 0.2rem;
          width: 100%;
        }

        .wish-content-text {
          margin-bottom: 5px;
          min-width: 100%;

          textarea {
            background: white;
            border-color: Transparent;
            border-radius: 20px;
            border-style: none;
            box-shadow: $item-box-shadow;
            min-height: 300px;
            min-width: 100%;
            outline: none;
            padding: 12px 17px 36px 22px;
          }
        }

        .submit-wish {
          background-color: #20a7e0;
          color: white;
          font-size: 16px;
          font-stretch: normal;
          font-style: normal;
          font-weight: normal;
          height: 32px;
          letter-spacing: normal;
          line-height: normal;
          margin-bottom: 50px;
          text-transform: none;
          width: 176px;
        }

        .top-wish {
          display: flex;
          font-size: 24px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          margin-bottom: 20px;
          color: black !important;
          padding-left: 0;
        }

        .top-wish-item {
          align-items: center;
          display: flex;
          font-size: 16px;
          justify-content: space-between;
          width: 100%;

          .top-wish-item-info {
            font-size: 16px;
          }

          .top-wish-item-actions {
            button {
              margin-right: 15px;
            }
          }
        }
      }
    }
  }
  .info-wrapper {
    font-size: 20px;
    font-weight: bold;
  }
}

.remove-confirm-dialog {
  .headline {
    font-size: 16px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
    padding: 32px 34px 17px 30px !important;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .v-card__actions {
    padding: 17px 30px 19px 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .remove-cancel-btn {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #21a7e0;
    text-transform: none;
    margin-right: 16px;
  }
  .remove-confirm-btn {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: white;
    text-transform: none;
  }
}

.edit-access-level-dialog {
  .headline {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    padding: 32px 34px 26px 30px;
  }

  .sub-desc {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
  }
  .v-card__text {
    padding: 0 36px 0 30px;
  }

  .v-card__actions {
    padding: 5px 44px 38px 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .remove-cancel-btn {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #21a7e0;
    text-transform: none;
    margin-right: 16px;
  }
  .remove-confirm-btn {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: white;
    text-transform: none;
  }
}

::v-deep .v-dialog.v-dialog--active {
  max-height: 99%;
}

.add-new-user-dialog {
  .close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;
  }

  .v-card__title {
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    padding: 53px 73px 38px 67px !important;
  }

  .v-card__text {
    padding: 0 !important;
  }

  .add-new-user-sub-title {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    color: black;
    letter-spacing: normal;
    text-align: left;
    padding-left: 67px;
    margin-bottom: 18px;
  }

  .add-new-user-title {
    padding-left: 67px;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: black;
  }
  .message-tex {
    resize: vertical;
    max-height: 50px;
  }
  .email-sent-finished {
    padding-left: 67px;
    padding-bottom: 69px;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #21a7e0;
    text-align: left;
  }

  .v-textarea {
    ::v-deep .v-input__slot {
      &::before,
      &::after {
        display: none;
      }
    }

    ::v-deep textarea {
      border-radius: 20px;
      box-shadow: inset 0 1px 3px 0 #050708;
      padding: 12px 22px;
    }
  }

  .v-text-field {
    margin: 0 87px 20px 67px;
  }

  .v-input--radio-group {
    margin: 16px 87px 20px 67px;
  }

  .v-card__actions {
    padding: 0 73px 35px 67px !important;
  }
}
.v-data-table td {
  font-size: 16px !important;
}
.spinner-wrapper {
  align-items: center;
  display: flex;
  height: 80vh;
  justify-content: center;
  position: relative;
  text-align: center;
  width: 100%;
}

.footer-total td {
  font-size: 14px !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  border-top: thin solid #e1e4ea;
}
</style>
