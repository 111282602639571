<template>
  <v-dialog v-model="show" max-width="700" @click:outside="onClose">
    <v-card class="storyline-modal-container">
      <v-card-title v-if="title">
        <v-btn class="close-btn" icon @click="onClose">
          <v-icon dark v-text="`mdi-close`" />
        </v-btn>
        <h3 class="modal-title">{{ $t(title) }}</h3>
      </v-card-title>
      <v-card-text class="card-text">
        <div class="text">
          {{ $t(text) }}
        </div>
        <div class="storyline-label">
          <div class="presentation-name-label">
            {{ $t('profile.storyLineModal.storylineType') }}
          </div>
          <v-text-field
            :placeholder="$t('profile.storyLineModal.storylineExample')"
            v-model="prezentationType"
            :rules="maxLimit"
            @change="(e) => handleChange('type', e)"
          />
        </div>
        <div class="storyline-label">
          <div class="presentation-name-label">
            {{ $t('profile.storyLineModal.briefDescription') }}
          </div>
          <v-text-field
            :placeholder="$t('profile.storyLineModal.briefDescriptionExample')"
            v-model="descriptionText"
            @change="(e) => handleChange('description', e)"
          />
        </div>
        <div class="d-flex justify-end">
          <v-btn
            v-if="primaryBtnText"
            class="primary-btn"
            outlined
            rounded
            color="primary"
            :disabled="
              isSecondaryClicked ||
              !prezentationType ||
              prezentationType.length > 100
            "
            @click="handlePrimaryClick"
            :loading="isPrimaryloading && isPrimaryClicked"
          >
            {{ $t(primaryBtnText) }}
          </v-btn>
          <v-btn
            v-if="secondaryBtnText"
            class="secondary-btn"
            rounded
            color="primary"
            :disabled="
              isPrimaryClicked ||
              !prezentationType ||
              prezentationType.length > 100 ||
              !descriptionText ||
              isSecondaryloading ||
              secondaryBtnText === 'Edits saved'
            "
            @click="handleSecondaryClick"
            :loading="
              (secondaryBtnText !== 'Edits saved' && isSecondaryloading) ||
              (secondaryBtnText !== 'Edits saved' && isSecondaryClicked)
            "
          >
            <v-icon v-if="secondaryBtnText === 'Edits saved'">mdi-check</v-icon>
            {{ $t(secondaryBtnText) }}
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'StorylineModal',
  props: {
    show: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    title: {
      type: String,
      required: false,
      default: () => '',
    },
    text: {
      type: String,
      required: false,
      default: () => '',
    },
    type: {
      type: String,
      required: false,
      default: () => '',
    },
    description: {
      type: String,
      required: false,
      default: () => '',
    },
    primaryBtnText: {
      type: String,
      required: false,
      default: () => '',
    },
    secondaryBtnText: {
      type: String,
      required: false,
      default: () => '',
    },
    onPrimaryClick: {
      type: Function,
      required: false,
      default: () => {
        console.log('primary clicked');
      },
    },
    onSecondaryClick: {
      type: Function,
      required: false,
      default: () => {
        console.log('secondary clicked');
      },
    },
    onChange: {
      type: Function,
      required: false,
      default: () => {
        console.log('on change');
      },
    },
    onClose: {
      type: Function,
      required: false,
      default: () => {
        console.log('closinf popup');
      },
    },
    isPrimaryloading: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    isSecondaryloading: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data() {
    console.log('data', this.description, this.type);
    return {
      descriptionText: this.description || '',
      prezentationType: this.type || '',
      isPrimaryClicked: false,
      isSecondaryClicked: false,
    };
  },
  computed: {
    maxLimit() {
      return [
        (v) => (v || '').length <= 100 || this.$t('generate.cannotExceed100'),
      ];
    },
  },
  mounted() {
    console.log('mounted', this.description, this.type);
    this.descriptionText = this.description;
    this.prezentationType = this.type;
  },
  watch: {
    description(val) {
      this.descriptionText = val;
    },
    type(val) {
      this.prezentationType = val;
    },
    isPrimaryloading(val) {
      if (!val && this.isPrimaryClicked) {
        this.isPrimaryClicked = false;
      }
    },
    isSecondaryloading(val) {
      if (!val && this.isSecondaryClicked) {
        this.isSecondaryClicked = false;
      }
    },
  },
  methods: {
    handleChange(key, value) {
      this.onChange(key, value);
    },
    handlePrimaryClick() {
      this.isPrimaryClicked = true;
      this.onPrimaryClick();
    },
    handleSecondaryClick() {
      this.isSecondaryClicked = true;
      this.onSecondaryClick();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/app.scss', '@/scss/variables.scss';
::v-deep .v-dialog {
  border-radius: 20px !important;
}
.storyline-modal-container {
  position: relative;
  padding: 8px 0 4px 0;
  .primary-btn {
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    height: 40px !important;
    line-height: normal;
    letter-spacing: normal;
    min-width: 64px;
    text-transform: none !important;
  }

  .card-text {
    padding-top: 0px !important;
  }

  .secondary-btn {
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    height: 40px !important;
    line-height: normal;
    letter-spacing: normal;
    min-width: 64px;
    margin-left: 16px !important;
    text-transform: none !important;
  }

  .v-card__title {
    padding-bottom: 8px !important;
  }
  .modal-title {
    font-family: 'Lato' !important;
  }
  .presentation-name-label {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 16px !important;
    line-height: 19px;
    color: #000000;
  }
  .storyline-title {
    padding: 0 15px;
  }
  .text {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    padding-bottom: 24px;
  }
  .close-btn {
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 1;
  }
}
</style>
