<template>
  <div class="op-details-container">
    <div v-if="loading"></div>
    <template v-else>
      <div v-if="isOPPrezError" class="op-request-error">
        <img src="/assets/img/prezentations/prez_does_not_exist.svg" alt />
        <div class="error-message">
          {{ $t('overnightPresentations.requestDoesNotExist') }}
        </div>
        <v-btn
          class="back-btn"
          outlined
          rounded
          color="#20a7e0"
          @click="goToMyRequestPage"
        >
          Back to My requests
        </v-btn>
      </div>
      <div v-else>
        <div class="op-detail-wrapper">
          <!-- <OvernightPrezentationDetail
            :prezData="prezentation"
            :showConversationHistory="showConversationHistory"
            @onRatingUpdate="onRatingUpdate"
            :changeShowConversation="changeShowConversation"
          ></OvernightPrezentationDetail> -->
          <OPDetail
            :prezData="prezentation"
            :showConversationHistory="showConversationHistory"
            @onRatingUpdate="onRatingUpdate"
            :changeShowConversation="changeShowConversation"
          />
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { getOPTicket } from '../../../utils/api-helper';
// import OvernightPrezentationDetail from './OvernightPrezentationDetail.vue';
import OPDetail from './main/OPDetail.vue';

export default {
  name: 'OvernightPrezentationDetailContainer',
  components: {
    // OvernightPrezentationDetail,
    OPDetail,
  },
  data() {
    return {
      loading: true,
      prezentation: null,
      showConversationHistory: false,
      isOPPrezError: false,
    };
  },
  methods: {
    goToMyRequestPage() {
      this.$router.push({
        path: `/home/overnight-prezentations?tab=myRequest`,
      });
    },
    changeShowConversation(val) {
      this.showConversationHistory = val;
      if (val) {
        this.$router.push({
          path: `/home/my-overnight-prezentation/${this.$route.params.prezentationID}?origin=overnight`,
        });
      } else {
        this.$router.push({
          path: `/home/my-overnight-prezentation/${this.$route.params.prezentationID}`,
        });
      }
    },
    onRatingUpdate(payload) {
      this.prezentation = { ...this.prezentation, ...payload };
    },
  },
  beforeMount() {
    const tab = this.$route?.query?.origin;
    if (tab) {
      this.showConversationHistory = true;
    }
  },
  mounted() {
    getOPTicket(this.$route.params.prezentationID)
      .then((resp) => {
        console.log(resp);
        this.prezentation = resp.data;
        this.loading = false;
      })
      .catch((err) => {
        console.log(err);
        this.isOPPrezError = true;
        this.loading = false;
      });
  },
};
</script>
<style scoped lang="scss">
.op-details-container {
  .op-request-error {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 80vh;
    .error-message {
      color: #000000;
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      margin-top: 24px;
    }
  }
  .back-btn {
    border-radius: 25px;
    color: white !important;
    background-color: $zen-blue-default;
    font-size: 18px;
    font-style: normal;
    font-stretch: normal;
    font-weight: 600;
    height: 45px;
    line-height: normal;
    letter-spacing: normal;
    text-transform: none;
    min-width: 200px;
    padding: 0 !important;
    margin: 20px;
    cursor: pointer;
  }
}
</style>
