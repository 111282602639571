<template>
  <div class="wrapper">
    <div>
      <v-btn class="close-btn" icon @click="handleClose">
        <v-icon dark v-text="`mdi-close`" id="close-dailogue--auto" />
      </v-btn>
      <div class="content-wrapper">
        <div class="header">{{ $t('build.step2.acceptSaveStorylineBtn') }}</div>
        <div class="description">
          {{ $t('build.step2.saveThisStoryline') }}
        </div>
        <div class="content-panel">
          <div class="content-label">
            {{ $t('profile.storyLineModal.storylineType') }}
          </div>
          <v-text-field
            :placeholder="$t('profile.storyLineModal.storylineExample')"
            v-model="prezType"
            :rules="maxLimit"
            id="presentation-type--auto"
            :disabled="!this.allowEditType"
          />
        </div>
        <div class="content-panel">
          <div class="content-label">
            {{ $t('profile.storyLineModal.briefDescription') }}
          </div>
          <v-text-field
            :placeholder="$t('profile.storyLineModal.briefDescriptionExample')"
            v-model="prezDescription"
            id="presentation-description--auto"
            :disabled="!this.allowEditType"
          />
        </div>
        <div class="checkbox-wrapper">
          <v-checkbox
            v-model="updateStoryline"
            :label="$t('profile.storyLineModal.updateExistingStoryline')"
            color="primary"
            v-if="this.allowUpdate"
            :disabled="!this.allowUpdate"
            @click="handleUpdateExisting"
          ></v-checkbox>
        </div>
        <div class="action-wrapper">
          <v-btn
            rounded
            class="primary-button mb-3"
            color="#21a7e0"
            height="48"
            min-width="134"
            :disabled="!prezType || !prezDescription || prezType.length > 100"
            @click="handleNext"
            id="accept-and-save-dialogue-btn--auto"
          >
            {{ $t(buttonLabel) }}
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'AcceptAndSaveDialog',
  components: {},
  props: {
    onSave: {
      type: Function,
      default: () => ({}),
    },
    type: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    isOwner: {
      type: Boolean,
      default: false,
    },
    permissionLevel: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      buttonLabel: 'build.step2.acceptAndSaveBtn',
      allowedTeam: false,
      updateStoryline: false,
      result: {},
      allowEditType: true,
      allowUpdate: false,
      originalType: '',
      originalDesc: '',
      prezType: '',
      prezDescription: '',
    };
  },
  computed: {
    ...mapState('users', ['currentUser']),
    maxLimit() {
      return [
        (v) => (v || '').length <= 100 || this.$t('generate.cannotExceed100'),
      ];
    },
  },
  watch: {
    type(val) {
      this.prezType = val;
    },
    description(val) {
      this.prezDescription = val;
    },
  },
  methods: {
    handleClose() {
      this.$modal.hide('AcceptAndSaveDialog');
    },
    handleNext() {
      // todo make api calls
      // No need to make a call. handleStepper is taking care.
      this.buttonLabel = 'common.saved';

      setTimeout(() => {
        this.onSave(
          this.prezType,
          this.prezDescription,
          this.allowedTeam,
          this.updateStoryline,
        );
        this.handleClose();
      }, 500);
    },
    handleUpdateExisting() {
      if (this.updateStoryline) {
        if (this.permissionLevel === 'Viewer') {
          this.allowEditType = false;
          this.prezType = this.originalType;
          this.prezDescription = this.originalDesc;
        }
      } else {
        this.allowEditType = true;
      }
    },
  },
  mounted() {
    console.log('isOwner -', this.isOwner);
    console.log('type -', this.type);
    console.log('description -', this.description);
    this.originalType = this.type;
    this.prezType = this.type;
    this.originalDesc = this.description;
    this.prezDescription = this.description;
    if (this.isOwner || this.permissionLevel === 'Editor') {
      this.allowUpdate = true;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/scss/variables.scss', '@/scss/app.scss';

.wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  overflow-y: auto;
  position: relative;
  width: 100%;
}

.content-wrapper {
  font-family: 'Lato' !important;
  height: 100%;
  overflow-y: auto;
  padding: 44px 68px;
  position: relative;
  width: 100%;

  .header {
    font-size: 24px;
    font-stretch: normal;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    letter-spacing: normal;
    margin-bottom: 34px;
  }

  .description {
    font-size: 20px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    margin-bottom: 26px;
  }

  .content-panel {
    margin-bottom: 20px;
    .content-label {
      font-size: 20px;
      font-stretch: normal;
      font-style: normal;
      font-weight: normal;
      letter-spacing: normal;
      line-height: normal;
    }
    ::v-deep .error--text {
      color: #f70000 !important;
      caret-color: #f70000 !important;
    }
  }

  .action-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 36px;
  }
}

.close-btn {
  position: absolute;
  right: 24px;
  top: 24px;
  z-index: 1;
}
</style>
