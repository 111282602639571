<template>
  <div class="get-in-touch-wrapper">
    <v-btn class="close-btn" icon @click="handleClose">
      <v-icon dark v-text="`mdi-close`" />
    </v-btn>
    <div class="get-in-touch-img">
      <img src="/assets/img/profile/get-in-touch.svg" alt />
    </div>
    <div class="get-in-touch-title">
      {{ $t(title) }}
      <br />
      <br />{{ $t('profile.getInTouchDetail.weWillGetInTouchText1') }} <br />{{
        $t('profile.getInTouchDetail.weWillGetInTouchText2')
      }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'GetInTouchDetail',
  props: {
    title: {
      props: String,
      default: '',
    },
  },
  data() {
    return {};
  },
  methods: {
    handleClose() {
      this.$modal.hide('GetInTouchDetail');
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.get-in-touch-wrapper {
  width: 100%;
  height: 100%;
  text-align: center;
  .close-btn {
    color: #212121;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
  }

  .get-in-touch-title {
    margin: auto;
    width: 80%;
    font-size: 22px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
  }

  .get-in-touch-img {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 66px;
  }

  img {
    margin: auto;
    margin-bottom: 36px;
  }
}
</style>
