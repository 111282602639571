<template>
  <VueDraggableResizable
    v-if="isShowToolTip && bestPractice"
    w="auto"
    h="auto"
    class="coordinate-point"
    :x="bestPractice.xAxis"
    :y="bestPractice.yAxis"
    :draggable="isActionAllowed"
    @dragstop="onDragStop"
    :onDragStart="onDragStart"
    :parent="true"
  >
    <div class="coordinate-inner">
      {{ index + 1 }}
    </div>
    <div>
      <div v-if="isEditing" class="edit-wrapper">
        <v-text-field
          v-model="updateText"
          placeholder="Add a descriptive brief title"
          hide-details
          autofocus
        >
          <template #append-outer>
            <v-icon
              @click="handleUpdateBestPractise"
              :disabled="updateText === ''"
            >
              {{ 'mdi-play' }}
            </v-icon>
            <v-icon @click="handleEditBestPractiseClose">
              {{ 'mdi-close' }}
            </v-icon>
          </template>
        </v-text-field>
      </div>
      <div v-else class="text-wrapper">
        <div class="text">{{ bestPractice.name }}</div>
        <div class="actions" v-if="isActionAllowed">
          <v-icon size="medium" @click="handleEditBestPractise">
            mdi-pencil
          </v-icon>
          <v-icon size="medium" @click="handleOpenBestPractiseRemoveDialog">
            mdi-delete
          </v-icon>
        </div>
      </div>
    </div>
  </VueDraggableResizable>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import VueDraggableResizable from 'vue-draggable-resizable';

export default {
  components: {
    VueDraggableResizable,
  },
  props: {
    bestPracticeData: {
      type: Object,
      require: true,
      default: () => ({}),
    },
    index: {
      type: Number,
      require: true,
      default: null,
    },
    onChange: {
      type: Function,
      require: true,
      default: () => ({}),
    },
    onDelete: {
      type: Function,
      require: true,
      default: () => ({}),
    },
    isActionAllowed: {
      type: Boolean,
      require: false,
      default: true,
    },
    isShowToolTip: {
      type: Boolean,
      require: false,
      default: true,
    },
  },
  data() {
    return {
      isEditing: false,
      updateText: '',
      bestPractice: null,
      width: 0,
      height: 0,
    };
  },
  computed: {
    ...mapState('prezentationDetails', [
      'bestPracticesEditedCounter',
      'bestPracticesDeletedCounter',
    ]),
  },
  beforeMount() {
    setTimeout(() => {
      this.prepareBestpracticeCoordinates();
    }, 300);
  },
  methods: {
    ...mapActions('prezentationDetails', [
      'setBpEditedCounter',
      'setBpDeletedCounter',
    ]),
    prepareBestpracticeCoordinates() {
      const box = document.querySelector('.main-image');
      console.log(box);
      this.width = box?.offsetWidth || 0;
      this.height = box?.offsetHeight || 0;
      this.updateText = this.bestPracticeData.name;
      this.isEditing = !this.bestPracticeData.name;
      this.bestPractice = this.bestPracticeData;
      const finalData = {
        ...this.bestPracticeData,
        xAxis: parseFloat(
          ((this.bestPracticeData.x * this.width) / 100).toFixed(2),
        ),
        yAxis: parseFloat(
          ((this.bestPracticeData.y * this.height) / 100).toFixed(2),
        ),
      };
      this.bestPractice = finalData;
    },
    handleEditBestPractise() {
      this.isEditing = true;
      const count = this.bestPracticesEditedCounter + 1;
      this.setBpEditedCounter(count);
    },
    handleEditBestPractiseClose() {
      this.updateText = this.bestPracticeData.name || '';
      this.isEditing = false;
      if (this.updateText) {
        this.handleUpdateBestPractise();
      } else {
        this.handleOpenBestPractiseRemoveDialog();
      }
    },
    onDragStart() {
      this.bestPractice.name = this.updateText;
      // this.isEditing = false;
    },
    handleOpenBestPractiseRemoveDialog() {
      // remove coordinates
      this.onDelete({ ...this.bestPractice }, this.index);
      const count = this.bestPracticesDeletedCounter + 1;
      this.setBpDeletedCounter(count);
    },
    handleUpdateBestPractise() {
      this.bestPractice.name = this.updateText;
      this.onChange({ ...this.bestPractice }, this.index);
      this.isEditing = false;
    },

    onDragStop(left, top) {
      this.updateText = this.bestPractice.name;
      const finalData = {
        ...this.bestPractice,
        x: parseFloat(((left / this.width) * 100).toFixed(2)),
        y: parseFloat(((top / this.height) * 100).toFixed(2)),
        xAxis: left,
        yAxis: top,
      };
      this.bestPractice = finalData;
      this.onChange({ ...finalData }, this.index);
    },
  },
};
</script>

<style lang="scss" scoped>
.coordinate-point {
  display: flex;
  position: absolute !important;

  .coordinate-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px !important;
    height: 28px !important;
    color: rgb(255, 255, 255);
    background-color: rgb(236, 159, 11);
    border: 2px solid rgb(245, 207, 149);
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
  }

  .v-text-field {
    padding: 0;
    margin: 0;
  }

  .edit-wrapper,
  .text-wrapper {
    display: flex;
    padding: 5px;
    margin-left: 10px;
    background-color: white;
    box-shadow: 0px 0px 6px 0px #00000029;
    font-size: 16px;
    max-width: 200px;
    z-index: 10;

    .actions {
      display: flex;
      margin-left: 5px;
    }
  }

  .text-wrapper {
    display: none;
  }

  &:hover {
    z-index: 20 !important;
    .text-wrapper {
      display: flex;
    }
  }
}
</style>
