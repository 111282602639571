<template>
  <v-tooltip bottom v-if="!downloading">
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        class="icon"
        v-text="`mdi-download-outline`"
        color="#21a7e0"
        :disabled="!(opRequest.status === 'Completed')"
        size="24"
        @click.stop="downloadFile()"
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <span>{{ $t('common.download') }}</span>
  </v-tooltip>
  <v-progress-circular indeterminate color="primary" :size="22" v-else />
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {
  downloadFinalOPPPT,
  getFeatureUsage,
  logOPDownload,
  postFeatureUsage,
} from '../../../utils/api-helper';
import { downloadFileWithCustomName } from '../../../utils/common';
import {
  TD_AUD,
  TD_LIST,
  TD_PLACEMENT,
  TD_PREZENTATION_CATEGORY,
  TD_PREZNAME,
  TD_PREZTYPE,
  TD_THUMBNAIL_VIEW,
  TD_VIEWTYPE,
} from '../../common/Analytics/MatomoTrackingDataHelper';
import { trackOvernightPrezentationsEvents } from '../../common/Analytics/OvernightPrezentationsEvents';

export default {
  name: 'DownloadOpFileFromListView',
  data() {
    return {
      downloading: false,
    };
  },
  computed: {
    ...mapState('users', ['currentUser', 'limitsInfoObj']),
  },
  props: {
    opRequest: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    ...mapActions('users', ['setLimitData', 'setUpgradePopup']),
    async downloadFile() {
      await getFeatureUsage()
        .then((response) => {
          if (response?.data) {
            this.setLimitData(response.data);
          }
        })
        .catch((error) => console.log(error));
      if (
        this.currentUser.user.role.toLowerCase() !== 'trialuser' ||
        (this.currentUser.user.role.toLowerCase() === 'trialuser' &&
          !this.limitsInfoObj?.slide?.isLimitCrossed)
      ) {
        postFeatureUsage({ feature: 'op', ticketId: this.opRequest?.ticketId })
          .then((response) => {
            console.log(response);
            if (response?.data) {
              this.setLimitData(response.data);
            }
          })
          .catch((error) => console.log(error));

        this.downloading = true;
        await downloadFinalOPPPT(this.opRequest.ticketId)
          .then((resp) => {
            console.log(resp);
            downloadFileWithCustomName(
              resp.attachmentUrl,
              resp.fileName,
              (val) => {
                if (!val) {
                  this.logDownload(this.opRequest.ticketId);
                  this.downloading = val;
                }
              },
              false,
            );
          })
          .catch((err) => {
            this.downloading = false;
            console.log(err);
          });
        trackOvernightPrezentationsEvents.OPMyRequestsDownload(
          this.currentUser,
          {
            [TD_AUD]: this.opRequest?.audienceEmail,
            [TD_VIEWTYPE]: TD_LIST,
            [TD_PREZNAME]: this.opRequest.name,
            [TD_PREZTYPE]: this.opRequest.storyline,
            [TD_PLACEMENT]: TD_THUMBNAIL_VIEW,
            [TD_PREZENTATION_CATEGORY]: this.opRequest.status,
          },
        );
      } else {
        this.setUpgradePopup({ upgradePopup: true, popupType: 'op' });
      }
    },
    logDownload(id) {
      logOPDownload(id)
        .then((resp) => {
          console.log(resp);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang=""></style>
