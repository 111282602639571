<template>
  <div class="drag-drop-wrapper" id="drag-drop-wrapper">
    <div
      id="myPaletteDiv"
      style="height: 130px; width: 90%; outline: none; margin-left: -50px"
    />
    <div
      id="myDiagramDiv"
      style="height: 400px; width: 90%; outline: none; margin-left: -20px"
    />
    <div v-if="done" class="drag-result-wrapper">
      <div class="result-title">
        <img
          src="/assets/img/learn/practice-well-done.png"
          width="36px"
          height="36px"
          alt=""
        />
        <div class="result-title-content">{{ $t('learn.novice.goodJob') }}</div>
      </div>
      <div class="result-description">
        {{ $t('learn.novice.resultDescription') }}
      </div>
    </div>
    <div v-if="isSubmit && !done" class="drag-result-wrapper">
      <div class="result-title">
        <img src="/assets/img/learn/practice-highlight.svg" alt="" />
        <div class="result-title-content">{{ $t('common.tryAgain') }}</div>
      </div>
      <div class="result-description">
        {{ $t('learn.novice.resultDescription2') }}
      </div>
    </div>
    <div class="action-wrapper">
      <v-btn
        class="action-button submit"
        v-if="!redo && !done"
        id="SaveButton"
        width="157"
        height="50"
        rounded
        color="#20a7e0"
        :disabled="disableSubmit"
        @click="save()"
      >
        {{ $t('common.submit') }}
      </v-btn>
      <v-btn
        class="action-button try-again"
        v-else-if="!done"
        id="TryAgainButton"
        width="157"
        height="50"
        rounded
        color="#20a7e0"
        @click="tryAgain()"
      >
        {{ $t('common.tryAgain') }}
      </v-btn>
      <v-btn
        class="action-button try-again align-end"
        v-else
        id="NextButton"
        width="232"
        height="50"
        rounded
        color="#20a7e0"
        @click="handleUpdateIndex()"
      >
        {{
          currentIndex === 2
            ? $t('common.done')
            : $t('learn.novice.nextQuestion')
        }}
      </v-btn>
    </div>
  </div>
</template>
<script>
import go from 'gojs';
export default {
  name: 'DragAndDrop',
  data() {
    return {
      redo: false,
      isSubmit: false,
      done: false,
      CellSize: new go.Size(300, 45),
      diagCellSize: new go.Size(300, 45),
      myDiagram: null,
      myPalette: null,
      paletteData: [],
      loadValue: {
        class: 'go.TreeModel',
      },
    };
  },
  props: {
    exerciseData: {
      type: Object,
      required: true,
    },
    currentIndex: {
      type: Number,
      required: true,
    },
    updateExerciseIndex: {
      type: Function,
      required: true,
    },
  },
  watch: {
    currentIndex() {
      this.redo = false;
      this.done = false;
      this.init();
      document.getElementById('myPaletteDiv').style.display = 'block';
    },
  },
  computed: {
    disableSubmit() {
      let isDisableSubmit = true;
      if (this.myDiagram) {
        let { model } = this.myDiagram;
        for (let k = 2; k <= 10; k++) {
          let data = model.findNodeDataForKey(k);
          if (data) {
            if (data.name !== '') {
              isDisableSubmit = false;
            }
          }
        }
      }
      return isDisableSubmit;
    },
  },
  methods: {
    // The user cannot delete selected nodes in the Palette with the Delete key or Control-X,
    // but they can if they do so programmatically.
    handleUpdateIndex() {
      this.isSubmit = false;
      this.done = false;
      this.redo = false;
      this.updateExerciseIndex();
    },
    removeFromPalette() {
      this.myPalette.commandHandler.deleteSelection();
    },
    init() {
      document.getElementById('myDiagramDiv').innerHTML = '';
      document.getElementById('myPaletteDiv').innerHTML = '';
      this.loadValue.nodeDataArray = this.exerciseData.nodeDataArray;

      go.Diagram.licenseKey =
        '73f947eab26331b700ca0d2b113f69ed1bb37a639dd41ef25d5841f3ef01694370c9ed7b58d099d0d5f11ef41b7e938a8fc76a2e96440c3be339da8847e281ffb26275e01609108ba05071969afa38b1fa2d21f4c2e727a3c8688ff3e8a8dc9e43eb';
      let $ = go.GraphObject.make; // for conciseness in defining templates
      if (this.myDiagram && this.myDiagram.div) {
        this.myDiagram.div = null;
      }
      if (this.myPalette && this.myPalette.div) {
        this.myPalette.div = null;
      }

      this.myDiagram = $(
        go.Diagram,
        'myDiagramDiv', // must be the ID or reference to div
        {
          maxSelectionCount: 1, // users can select only one part at a time
          validCycle: go.Diagram.CycleDestinationTree, // make sure users can only create trees
          allowDrop: true,
          initialContentAlignment: go.Spot.TopLeft,
          ExternalObjectsDropped(e) {
            e.diagram.commandHandler.deleteSelection();
          },

          allowHorizontalScroll: false,
          allowVerticalScroll: false,
          layout: $(go.TreeLayout, {
            treeStyle: go.TreeLayout.StyleLastParents,
            arrangement: go.TreeLayout.AlignmentCenterChildren,
            // properties for most of the tree:
            angle: 0,
            layerSpacing: 40,
            nodeSpacing: 40,
            // properties for the "last parents":
            alternateAngle: 0,
            alternateLayerSpacing: 40,

            alternateNodeSpacing: 40,
          }),
          'undoManager.isEnabled': true, // enable undo & redo
        },
      );

      // define the Link template
      this.myDiagram.linkTemplate = $(
        go.Link,
        {
          routing: go.Link.Orthogonal,
          selectable: false,
        },
        $(go.Shape, { strokeWidth: 2, stroke: '#333' }),
      );

      this.myDiagram.findLayer('Tool').opacity = 0.7;
      // define the Node template
      this.myDiagram.nodeTemplate = $(
        go.Node,
        'Auto',

        {
          portId: '',
          fromSpot: go.Spot.Right, // coming out from middle-right
          toSpot: go.Spot.LeftSide,
          // handle dragging a Node onto a Node to (maybe) change the reporting relationship
          selectionAdorned: false,

          mouseDragEnter(e, node) {
            node.findObject('SHAPE').stroke = 'green';
          },
          mouseDragLeave(e, node) {
            node.findObject('SHAPE').stroke = 'black';
            node.findObject('SHAPE').fill = 'white';
          },
          mouseDrop: (e, node) => {
            // var diagram = node.diagram;
            // var selnode = diagram.selection.first();  // assume just one Node in selection
            const droppedPart = node.diagram.selection.first().part;
            let val = droppedPart.data.name;
            e.diagram.model.setDataProperty(node.data, 'name', val);
            this.removeFromPalette();
          },
        },
        // for sorting, have the Node.text be the data.name
        new go.Binding('text', 'name'),
        // bind the Part.layerName to control the Node's layer depending on whether it isSelected
        // new go.Binding("layerName", "isSelected", function(sel) { return sel ? "Foreground" : ""; }).ofObject(),
        // define the node's outer shape
        $(
          go.Shape,
          'RoundedRectangle',
          {
            name: 'SHAPE',
            minSize: this.diagCellSize,
            desiredSize: this.diagCellSize, // initially 1x1 cell
            strokeWidth: 1.5,
          },
          new go.Binding('fill', 'color'),
          new go.Binding('stroke', 'strokecolor'),
          new go.Binding('desiredSize', 'size', go.Size.parse).makeTwoWay(
            go.Size.stringify,
          ),
        ),
        // with the textual key in the middle
        $(
          go.TextBlock,
          { alignment: go.Spot.Center, font: '12px sans-serif' },
          new go.Binding('text', 'name'),
        ),
      ); // end Node

      // define the Link template
      this.myDiagram.linkTemplate = $(
        go.Link,
        go.Link.Orthogonal,
        { corner: 5, relinkableFrom: true, relinkableTo: true },
        $(go.Shape, { strokeWidth: 1.5, stroke: '#979797' }),
      ); // the link shape

      // create the Palette
      this.myPalette = $(go.Palette, 'myPaletteDiv');
      this.myPalette.findLayer('Tool').opacity = 0.5;

      // the Palette's node template is different from the main Diagram's
      this.myPalette.nodeTemplate = $(
        go.Node,
        'Auto',
        {
          mouseEnter(e, node) {
            node.findObject('SHAPE').fill = '#E1F5FE';
          },
          mouseLeave(e, node) {
            node.findObject('SHAPE').fill = 'white';
          },
        },
        $(
          go.Shape,
          'RoundedRectangle',
          {
            name: 'SHAPE',
            fill: 'white',
            minSize: this.CellSize,
            desiredSize: this.CellSize, // initially 1x1 cell
            strokeWidth: 1.5,
          },
          new go.AnimationTrigger('stroke'),
          new go.Binding('fill', 'color'),
          new go.Binding('desiredSize', 'size', go.Size.parse).makeTwoWay(
            go.Size.stringify,
          ),
        ),
        // with the textual key in the middle
        $(
          go.TextBlock,
          { alignment: go.Spot.Center, font: '12px sans-serif' },
          new go.Binding('text', 'name'),
        ),
      );

      // the list of data to show in the Palette
      this.myPalette.model.nodeDataArray = this.exerciseData.myPaletteArray;

      // read in the JSON-format data from the "mySavedModel" element
      this.loadGo();
    },

    // Show the diagram's model in JSON format
    save() {
      let modelJson = this.loadValue;
      let { model } = this.myDiagram;
      this.paletteData = [];
      for (let k = 2; k <= 10; k++) {
        var data = model.findNodeDataForKey(k);
        if (data) {
          const pk = model.getParentKeyForNodeData(data);

          const pData = model.findNodeDataForKey(pk);
          if (pData) {
            const node = this.exerciseData.nodesAndParents.find(
              (n) => n.node === data.name,
            );

            if (pData.name !== node.targetParent) {
              // var pdata = {
              //   key: k,
              //   name: data.name,
              // };
              let tempData = {
                key: k,
                name: data.name,
                color: 'white',
              };
              this.redo = true;
              modelJson.nodeDataArray[k - 1].name = '';
              this.paletteData.push(tempData);
              model.startTransaction('modified property');
              model.set(data, 'strokecolor', 'red');
              // ... maybe modify other properties and/or other data objects
              model.commitTransaction('modified property');
            } else {
              model.startTransaction('modified property');
              model.set(data, 'color', 'white');
              model.set(data, 'strokecolor', '#90ee90');
              model.commitTransaction('modified property');
            }
          }
        }
      }
      if (!this.redo) {
        document.getElementById('myPaletteDiv').style.display = 'none';
        this.done = true;
      }
      this.isSubmit = true;
      this.myDiagram.isModified = false;
    },

    tryAgain() {
      this.redo = false;
      this.myPalette.model.nodeDataArray = this.paletteData;
      this.loadGo();
    },

    loadGo() {
      this.myDiagram.model = go.Model.fromJson(this.loadValue);
      // make sure new data keys are unique positive integers
      let lastkey = 1;
      this.myDiagram.model.makeUniqueKeyFunction = (model, data) => {
        let k = data.key || lastkey;
        while (model.findNodeDataForKey(k)) k++;
        data.key = lastkey = k;
        return k;
      };
    },
  },
  mounted() {
    this.init();
    this.redo = false;
    this.done = false;
    document.getElementById('myPaletteDiv').style.display = 'block';
  },
};
</script>

<style lang="scss" scoped>
.drag-drop-wrapper {
  width: 100%;
  position: relative;
  padding-left: 20px;
  .action-wrapper {
    position: relative;
    right: 60px;
    top: 10px;
    bottom: 10px;
    z-index: 111;
    height: 100px;

    .action-button {
      font-size: 24px;
      font-weight: bold;
      color: white;
      text-transform: none;
      float: right;
    }
  }
  .drag-result-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 70%;

    .result-title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 10px;

      img {
        margin-right: 15px;
      }
    }

    .result-description {
      text-align: left;
      font-size: 16px;
    }
  }
}
</style>
<style>
canvas {
  outline: none;
}
</style>
