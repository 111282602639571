<template>
  <div class="request-access-container">
    <v-btn class="close-btn" icon @click="onClose">
      <v-icon :size="16" dark v-text="`mdi-close`" />
    </v-btn>
    <img
      class="req-img"
      :src="'/assets/img/overnight-prezentations/op-disabled.svg'"
    />
    <span>{{ text }}</span>
    <span>{{ subText }}</span>
    <v-btn
      :loading="loading"
      class="send-assessment"
      rounded
      color="#20a7e0"
      width="140px"
      height="32px"
      @click="btnClick"
    >
      {{ btnText }}
    </v-btn>
  </div>
</template>
<script>
export default {
  name: 'RequestAccess',
  components: {},
  props: {
    text: {
      type: String,
      default: 'Your access to Overnight Prezentations is disabled.',
    },
    subText: {
      type: String,
      default: 'Please contact your team admin to request access.',
    },
    btnText: {
      type: String,
      default: 'Request access',
    },
    btnClicked: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    onClose() {
      this.$modal.hide('RequestAccess');
    },
    btnClick() {
      this.loading = true;
      this.btnClicked();
    },
  },
};
</script>
<style lang="scss" scoped>
.request-access-container {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  padding: 24px;
  gap: 8px;

  .close-btn {
    position: absolute;
    right: 5px;
    top: 5px;
  }
  .req-img {
    width: 185px;
    margin-bottom: 20px;
  }

  .get-in-touch-img {
    width: 274px;
  }

  span {
    text-align: center;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }

  .send-assessment {
    background: #21a7e0;
    border-radius: 25px;
    color: white !important;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    text-transform: none;
    margin-top: 20px;
  }
}
</style>
