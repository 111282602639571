<template>
  <div class="main-actions-row">
    <div v-for="(action, index) in actions" :key="`item-${index}`">
      <v-btn
        rounded
        color="primary"
        text
        :key="`button-${index}`"
        @click="() => handleIconClick(action)"
        :class="action.id === selectedAction ? 'active' : ''"
        :data-pendo-id="action.dataPendoId ? action.dataPendoId : null"
        :disabled="action.disabled"
      >
        <div class="main-action-content">
          <component
            :is="getIcon(action)"
            :iconWidth="18"
            :iconHeight="18"
            :iconColor="getIconColor(action)"
            :iconStrokeWidth="0.5"
            v-if="action.isCustomIcon"
          />
          <v-icon dark size="20" class="main-action-icon">
            {{ action.icon }}
          </v-icon>
          <span
            v-if="action.id === 'manageRequest' && getMessageCount"
            class="badge"
            >{{ getMessageCount }}</span
          >
          <span>{{ action.labelText || $t(action.label) }}</span>
        </div>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { mainActions } from '../config/actionConfig';
import ChangeRequestIcon from '../../../../common/Icons/ChangeRequestIcon.vue';

export default {
  name: 'MainActions',
  props: {
    prezData: {
      type: Object,
      required: true,
    },
    selectedMainAction: {
      required: true,
      default: null,
    },
    getMessageCount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      actions: [],
      iconMap: { 'change-request': ChangeRequestIcon },
      prezentation: this.prezData,
      selectedAction: null,
    };
  },
  mounted() {
    this.mapFilteredActions();
    this.selectedAction = this.selectedMainAction;
  },
  computed: {
    ...mapState('users', ['currentUser', 'limitsInfoObj']),
    isOpRestrictedBannerActive() {
      return (
        (this.limitsInfoObj?.op?.access_level === 'full_access' &&
          this.currentUser.user.role.toLowerCase() === 'trialuser') ||
        (this.limitsInfoObj?.op?.access_level === 'restricted_access' &&
          this.currentUser.user.role.toLowerCase() !== 'trialuser') ||
        ((this.limitsInfoObj?.slide?.feature ||
          this.limitsInfoObj?.op?.feature) &&
          this.currentUser.user.role.toLowerCase() === 'trialuser')
      );
    },
  },
  watch: {
    prezData: {
      handler() {
        this.mapFilteredActions();
      },
      deep: true,
    },
    selectedMainAction(val) {
      this.selectedAction = val;
    },
  },
  methods: {
    getIconColor(action) {
      return action.disabled ? 'rgba(0, 0, 0, 0.26)' : '#21a7e0';
    },
    mapFilteredActions() {
      this.actions = [];
      const mainActionsTemp = JSON.parse(JSON.stringify(mainActions));
      mainActionsTemp.forEach((action) => {
        switch (action.id) {
          case 'changeRequest':
            if (this.prezentation.status === 'Completed') {
              if (this.isOpRestrictedBannerActive) {
                action.disabled = true;
              }
              this.actions.push(action);
            }
            break;
          default:
            this.actions.push(action);
            break;
        }
      });
    },
    handleIconClick(data) {
      console.log(data);
      this.selectedAction = data.id;
      this.$emit('onMainActionClick', data.id);
    },
    getIcon(action) {
      return this.iconMap[action?.icon];
    },
  },
};
</script>

<style lang="scss" scoped>
.main-actions-row {
  margin-top: 10px;
  display: flex;

  .main-action-icon {
    // margin-bottom: 4px;
    margin-right: 2px;
  }

  .v-btn {
    text-transform: none;
    letter-spacing: normal;

    &.active::before {
      background-color: #21a7e0;
      opacity: 0.08;
    }
  }
  .divider {
    border: 1px solid #21a7e0;
    width: 80%;
    transform: translateX(13px);
  }

  .tooltip-activator {
    display: flex;
    align-items: center;
  }
}
.main-action-content {
  display: flex;
  align-items: center;
  .badge {
    position: absolute;
    bottom: 13px;
    left: 10px;
    padding: 0px 7px 1px 5px;
    border-radius: 50%;
    background: #21a7e0;
    color: white;
    width: 17px;
    height: 17px;
    font-size: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  svg {
    // width: 20px !important;
  }
}
</style>
