var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoading)?_c('div',{staticClass:"text-center spinner-container"},[_c('Loading')],1):(_vm.items && _vm.items.length && _vm.items.length > 0)?_c('div',{staticClass:"storyline"},[_c('div',{staticClass:"storyline-title"},[_vm._v(" "+_vm._s(_vm.$t('profile.storylinesTabContent.myCustomStorylines'))+" ")]),_c('div',[(!_vm.isLoading)?_c('v-data-table',{staticClass:"storyline-table",attrs:{"headers":_vm.tableHeaders,"items":_vm.filteredStorylines,"footer-props":{
        itemsPerPageOptions: [10, 25, 50, 100],
      }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('div',{staticClass:"storyline-searchbar"},[_c('Search',{attrs:{"searchKey":_vm.search,"placeholder":_vm.$t('profile.storylinesTabContent.searchByAuthor'),"onSearch":_vm.onSearch}}),_c('v-btn',{staticClass:"primary-button mb-3",attrs:{"rounded":"","color":"#21a7e0","height":"48","data-pendo-id":"build-new-prezentation"},on:{"click":_vm.handleNewBuild}},[_vm._v(" "+_vm._s(_vm.$t('profile.storylinesTabContent.buildNewPrezentation'))+" ")])],1)])]},proxy:true},{key:"item.searchTypeDescription",fn:function(ref){
      var item = ref.item;
return [_c('div',[_c('v-tooltip',{attrs:{"top":"","max-width":"300","content-class":"tooltip-content","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"outline-title",on:{"click":function () { return _vm.handleViewStoryline(item); }}},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.type)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.type))])]),_c('v-tooltip',{attrs:{"top":"","max-width":"300","content-class":"tooltip-content","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"outline-description"},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.description)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.description))])])],1)]}},{key:"item.displayDate",fn:function(ref){
      var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.formatDate(item.displayDate))+" ")])]}},{key:"item.displayAuthor",fn:function(ref){
      var item = ref.item;
return [(item.isPrezentGenerated)?_c('v-img',{attrs:{"src":"/assets/img/prezent_avatar.svg","width":"22"}}):_c('div',[_vm._v(" "+_vm._s(item.displayAuthor)+" ")])]}},{key:"item.access",fn:function(ref){
      var item = ref.item;
return [(item.isOwner)?_c('div',{staticClass:"access-col"},[(!item.shared)?_c('span',{staticClass:"access-icon outline-title",on:{"click":function () { return _vm.handleShare(item); }}},[_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v(" mdi-account-check-outline ")]),_c('span',{staticClass:"access-text"},[_vm._v(_vm._s(_vm.$t('build.step1.private')))])],1):_vm._e(),(item.shared)?_c('span',{staticClass:"access-icon outline-title",on:{"click":function () { return _vm.handleShare(item); }}},[_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v(" mdi-account-multiple-check-outline ")]),_c('span',{staticClass:"access-text"},[_vm._v(_vm._s(_vm.$t('build.step1.shared')))])],1):_vm._e()]):_c('div',{staticClass:"access-col"},[(item.permissionLevel === 'Editor')?_c('span',{staticClass:"access-icon"},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-lock-open-variant-outline ")]),_c('span',{staticClass:"access-text"},[_vm._v(_vm._s(item.permissionLevel))])],1):_vm._e(),(item.permissionLevel === 'Viewer')?_c('span',{staticClass:"access-icon"},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-lock-outline ")]),_c('span',{staticClass:"access-text"},[_vm._v(_vm._s(item.permissionLevel))])],1):_vm._e()])]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","max-width":"200","content-class":"tooltip-content","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"action-button build-storyline--auto",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.handleBuildStoryline(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-plus-circle-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('profileStorylinesList.story_builder_tooltip')))])]),_c('v-tooltip',{attrs:{"top":"","max-width":"200","content-class":"tooltip-content","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"action-button copy-storyline--auto",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.handleCopyStoryline(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-content-copy ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.copiedFPId === item.id ? _vm.$t('common.copied') : _vm.$t('userUploadPrezentation.copyLink')))])]),_c('v-tooltip',{attrs:{"top":"","max-width":"200","content-class":"tooltip-content","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"action-button duplicate-storyline--auto",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.handleDuplicateStoryline(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-plus-box-multiple-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('common.duplicate')))])]),(item.permissionLevel !== 'Viewer')?_c('v-tooltip',{attrs:{"top":"","max-width":"200","content-class":"tooltip-content","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"action-button edit-storyline--auto",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.handleEditStoryline(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('profile.storylinesTabContent.edit')))])]):_vm._e(),(item.isOwner && item.permissionLevel !== 'Viewer')?_c('v-tooltip',{attrs:{"top":"","max-width":"200","content-class":"tooltip-content","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"action-button delete-storyline--auto",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.handleDeleteStoryline(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('common.delete')))])]):_vm._e()]}}],null,true)}):_c('Loading')],1),(_vm.showEdit)?_c('StorylineModal',{attrs:{"show":_vm.showEdit,"title":_vm.$t('profile.storylinesTabContent.editStorylineDetails'),"text":_vm.$t('profile.storylinesTabContent.editStorylineDetailsText'),"primaryBtnText":_vm.$t('profile.storylinesTabContent.saveStorylineBtn'),"secondaryBtnText":_vm.editButtonText,"type":_vm.selectedItem.type,"description":_vm.selectedItem.description,"onPrimaryClick":function () { return _vm.handleEditOutline(true); },"onSecondaryClick":function () { return _vm.handleEditOutline(); },"isSecondaryloading":_vm.isUpdatingSecondary,"isPrimaryloading":_vm.isUpdatingPrimary,"onChange":_vm.handleInputChange,"onClose":function () {
        _vm.editError = false;
        _vm.showEdit = false;
      }}}):_vm._e(),(_vm.showDuplicate)?_c('StorylineModal',{attrs:{"show":_vm.showDuplicate,"title":_vm.$t('profile.storylinesTabContent.duplicateStoryline'),"text":_vm.$t('profile.storylinesTabContent.duplicateStorylineText'),"secondaryBtnText":_vm.$t('common.duplicate'),"primaryBtnText":_vm.$t('common.cancel'),"type":_vm.selectedItem.type,"description":_vm.selectedItem.description,"onPrimaryClick":function () { return (_vm.showDuplicate = false); },"onSecondaryClick":_vm.handleDuplicateOutline,"onChange":_vm.handleInputChange,"isSecondaryloading":_vm.isCreating,"onClose":function () { return (_vm.showDuplicate = false); }}}):_vm._e(),(_vm.showDelete)?_c('ConfirmationModal',{attrs:{"show":_vm.showDelete,"title":_vm.$t('profile.storylinesTabContent.deleteStoryline'),"text":_vm.$t('profile.storylinesTabContent.deleteStorylineText'),"onPrimaryClick":function () { return (_vm.showDelete = false); },"onSecondaryClick":_vm.handleDeleteOutline,"isConfirming":_vm.isDeleting,"onClose":function () { return (_vm.showDelete = false); }}}):_vm._e(),(_vm.editError)?_c('ConfirmationModal',{attrs:{"show":_vm.editError,"title":_vm.$t('profile.storylinesTabContent.editStorylineDetails'),"text":_vm.$t('profile.storylinesTabContent.unableToUpdateStoryline'),"primaryBtnText":'',"secondaryBtnText":'',"onClose":function () { return (_vm.editError = false); }}}):_vm._e(),(_vm.showNoAccess)?_c('NoAccessModal',{attrs:{"noAccess":_vm.noAccess,"show":_vm.showNoAccess,"uuid":_vm.uuid,"isDuplicate":_vm.isDuplicate,"onClose":function () {
        _vm.showNoAccess = false;
        _vm.noAccess = false;
        _vm.isDuplicate = false;
        _vm.uuid = null;
        _vm.getOutlines();
      }}}):_vm._e()],1):(!_vm.isLoading)?_c('div',[_c('div',{staticClass:"no-outline-access"},[_vm._m(0),_c('p',{staticClass:"no-access-text"},[_vm._v(" "+_vm._s(_vm.$t('profile.storylinesTabContent.noStorylineCreated'))+" ")]),_c('div',{staticClass:"actions align-end"},[_c('v-btn',{staticClass:"primary-button mb-3",attrs:{"rounded":"","color":"#21a7e0","height":"48","data-pendo-id":"build-new-prezentation"},on:{"click":_vm.handleNewBuild}},[_vm._v(" "+_vm._s(_vm.$t('profile.storylinesTabContent.buildNewPrezentation'))+" ")])],1)])]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"align-center img"},[_c('img',{attrs:{"src":"/assets/img/no-results-found-generic.svg","alt":""}})])}]

export { render, staticRenderFns }