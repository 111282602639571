var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:("section-name " + (_vm.isAddNewSection ? 'add-new' : '') + " " + (_vm.editMode ? 'show' : ''))},[_c('div',{staticClass:"chip-title"},[(_vm.isAddNewSection)?_c('v-icon',{staticClass:"show"},[_vm._v(" mdi-plus-circle-outline ")]):_c('div',{class:("drag-icon section-drag-icon " + (_vm.editMode ? 'show' : ''))},[_c('v-icon',{attrs:{"dense":""}},[_vm._v(" mdi-dots-vertical ")]),_c('v-icon',{attrs:{"dense":""}},[_vm._v(" mdi-dots-vertical ")])],1),(_vm.editMode)?_c('v-text-field',{ref:"input",staticClass:"section-input",attrs:{"hide-details":""},on:{"keyup":function($event){$event.preventDefault();},"blur":_vm.onNameChangeCancel},model:{value:(_vm.newSectionName),callback:function ($$v) {_vm.newSectionName=$$v},expression:"newSectionName"}}):_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"title-text"},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.isAddNewSection ? _vm.$t(_vm.defaultText) : _vm.data.sectionName)+" ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.isAddNewSection ? _vm.$t(_vm.defaultText) : _vm.data.sectionName)+" ")])]),(!_vm.isAddNewSection && !_vm.editMode)?_c('v-badge',{staticClass:"badge",attrs:{"color":"#FFE1B7","content":_vm.count,"inline":""}}):_vm._e()],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.editMode),expression:"editMode"}],staticClass:"actions"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","disabled":_vm.newSectionName.length <= 0},on:{"mousedown":_vm.onNameChangeOk}},'v-icon',attrs,false),on),[_vm._v(" mdi-check ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('common.accept'))+" ")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"mousedown":_vm.onNameChangeCancel}},'v-icon',attrs,false),on),[_vm._v(" mdi-close ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('common.cancel'))+" ")])])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.editMode),expression:"!editMode"}],staticClass:"actions"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.onEditClick}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('overnightPresentations.rename'))+" ")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [(!_vm.isAddNewSection)?_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.handleDeleteSection}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete-outline ")]):_vm._e()]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('common.delete'))+" ")])])],1),_c('ConfirmationDialog',{attrs:{"show":_vm.deleteSectionConfirmPopup,"on-primary-click":_vm.handleDeleteSectionCancel,"on-secondary-click":_vm.handleDeleteSectionConfirm,"on-close":_vm.handleDeleteSectionCancel,"primary-btn-text":_vm.$t('common.cancel'),"secondary-btn-text":_vm.$t('common.delete'),"title":_vm.$t('build.step3.deleteSection'),"text":_vm.confirmPopupText}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }