<template>
  <div class="main-wrapper">
    <v-btn class="close-btn" icon @click="handleClose">
      <v-icon dark>mdi-close</v-icon>
    </v-btn>
    <div class="spinner-wrapper" v-if="isLoading">
      <LoadSpinner />
    </div>
    <div class="sharing-modal-main-wrapper" v-if="!isLoading">
      <div class="sharing-modal-container" data-pendo-id="share-container">
        <div class="sharing-modal__title font-weight-bold">
          {{ $t(title) }}
        </div>
        <v-radio-group v-model="shareOptionType" style="height: 67px">
          <v-radio
            color="#20a7e0"
            name="shareType"
            :label="$t('build.step1.private')"
            value="me"
            @change="handleShareTypeChangeFn()"
          ></v-radio>
          <v-radio
            color="#20a7e0"
            name="shareType"
            :label="$t('profile.shareTemplateAccess.shareWith')"
            value="share"
            @change="handleShareTypeChangeFn()"
            style="padding-top: 3px"
          ></v-radio>
        </v-radio-group>
        <div class="share-container">
          <div class="radio-button_item">
            <v-checkbox
              v-model="permissionGroup"
              :label="$t('profile.shareTemplateAccess.myCompany')"
              color="#20a7e0"
              hide-details
              value="company"
              :disabled="shareOptionType !== 'share'"
            ></v-checkbox>
            <v-select
              :menu-props="{ bottom: true, offsetY: true }"
              dense
              color="rgba(0, 0, 0, 0.6)"
              v-show="permissionGroup.indexOf('company') > -1"
              item-text="text"
              item-value="value"
              :items="filterShareOptions"
              v-model="companyPermission"
              class="share-option-select_dropdown"
            >
              <template v-slot:selection="{ item }">
                <div>
                  <span>{{ item.text }}</span>
                </div>
              </template>
              <template v-slot:item="{ item }">
                <div lass="d-flex">
                  <div class="share-select-item__selected">
                    <v-icon
                      v-if="companyPermission === item.value"
                      color="primary"
                    >
                      mdi-check
                    </v-icon>
                  </div>
                </div>
                <div
                  class="d-flex flex-column share-select-item"
                  v-if="item.text === 'Restricted'"
                >
                  <NewFeatureBadge
                    :module-name="'restricted'"
                    :feature-id="'restricted_id'"
                    :offsetX="'140'"
                    :offsetY="'15'"
                    :size="'medium'"
                  >
                    <div>
                      {{ item.text }}
                    </div>
                  </NewFeatureBadge>
                  <div
                    class="text-caption share-select-item__description"
                    v-if="item.description"
                  >
                    {{ item.description }}
                  </div>
                </div>
                <div class="d-flex flex-column share-select-item" v-else>
                  <div>
                    {{ item.text }}
                  </div>
                  <div
                    class="text-caption share-select-item__description"
                    v-if="item.description"
                  >
                    {{ item.description }}
                  </div>
                </div>
              </template></v-select
            >
          </div>
          <div class="radio-button_item">
            <v-checkbox
              v-model="permissionGroup"
              :label="$t('profile.shareTemplateAccess.myTeam')"
              color="#20a7e0"
              hide-details
              value="team"
              :disabled="shareOptionType !== 'share'"
            ></v-checkbox>
            <v-select
              :menu-props="{ bottom: true, offsetY: true }"
              dense
              color="rgba(0, 0, 0, 0.6)"
              v-show="permissionGroup.indexOf('team') > -1"
              item-text="text"
              item-value="value"
              :items="filterShareOptions"
              v-model="teamPermission"
              class="share-option-select_dropdown"
            >
              <template v-slot:selection="{ item }">
                <div>
                  <span>{{ item.text }}</span>
                </div>
              </template>
              <template v-slot:item="{ item }">
                <div class="d-flex">
                  <div class="share-select-item__selected">
                    <v-icon
                      v-if="teamPermission === item.value"
                      color="primary"
                    >
                      mdi-check
                    </v-icon>
                  </div>
                </div>
                <div
                  class="d-flex flex-column share-select-item"
                  v-if="item.text === 'Restricted'"
                >
                  <NewFeatureBadge
                    :module-name="'restricted'"
                    :feature-id="'restricted_id'"
                    :offsetX="'140'"
                    :offsetY="'15'"
                    :size="'medium'"
                  >
                    <div>
                      {{ item.text }}
                    </div>
                  </NewFeatureBadge>
                  <div
                    class="text-caption share-select-item__description"
                    v-if="item.description"
                  >
                    {{ item.description }}
                  </div>
                </div>
                <div class="d-flex flex-column share-select-item" v-else>
                  <div>
                    {{ item.text }}
                  </div>
                  <div
                    class="text-caption share-select-item__description"
                    v-if="item.description"
                  >
                    {{ item.description }}
                  </div>
                </div>
              </template>
            </v-select>
          </div>
          <v-checkbox
            v-model="permissionGroup"
            :label="$t('profile.shareTemplateAccess.selectColleague')"
            color="#20a7e0"
            hide-details
            value="user"
            :disabled="shareOptionType !== 'share'"
          ></v-checkbox>
          <div
            v-if="permissionGroup.indexOf('user') > -1"
            :label="$t('profile.shareTemplateAccess.selectColleague')"
          >
            <UserSelector
              v-model="selectedUsers"
              filter="insideCompanyUsers"
              @change="onSelectedUsersChange($event)"
            >
              <template v-slot:user-actions="{ item }">
                <v-list-item-action>
                  <v-select
                    :menu-props="{ bottom: true, offsetY: true }"
                    color="rgba(0, 0, 0, 0.6)"
                    dense
                    item-text="text"
                    item-value="value"
                    v-model="item.permission_level"
                    :items="filterUserShareOptions"
                    name="selectItem"
                    class="share-option-select_dropdown text-caption"
                  >
                    <template v-slot:selection="selectSlotItem">
                      {{ selectSlotItem.item.text }}
                    </template>
                    <template v-slot:item="selectSlotItem">
                      <div lass="d-flex">
                        <div class="share-select-item__selected">
                          <v-icon
                            v-if="
                              item.permission_level ===
                              selectSlotItem.item.value
                            "
                            color="primary"
                          >
                            mdi-check
                          </v-icon>
                        </div>
                      </div>
                      <div
                        class="d-flex flex-column share-select-item"
                        v-if="selectSlotItem.item.text === 'Restricted'"
                      >
                        <NewFeatureBadge
                          :module-name="'restricted'"
                          :feature-id="'restricted_id'"
                          :offsetX="'140'"
                          :offsetY="'15'"
                          :size="'medium'"
                        >
                          <div>
                            {{ selectSlotItem.item.text }}
                          </div>
                        </NewFeatureBadge>
                        <div
                          class="text-caption share-select-item__description"
                          v-if="selectSlotItem.item.description"
                        >
                          {{ selectSlotItem.item.description }}
                        </div>
                      </div>
                      <div class="d-flex flex-column share-select-item" v-else>
                        <div>
                          {{ selectSlotItem.item.text }}
                        </div>
                        <div
                          class="text-caption share-select-item__description"
                          v-if="selectSlotItem.item.description"
                        >
                          {{ selectSlotItem.item.description }}
                        </div>
                      </div>
                    </template>
                  </v-select>
                </v-list-item-action>
              </template>
            </UserSelector>
          </div>
        </div>
      </div>
      <div class="actions">
        <v-btn
          class="primary-button"
          rounded
          color="#21a7e0"
          height="48"
          min-width="134"
          @click="updateShareDetails"
          data-pendo-id="presentation-upload"
          :disabled="
            source === 'upload-feature' && !addToSlides && !addToPrezentations
          "
        >
          {{ $t(updateBtnText) }}
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import LoadSpinner from '../../common/LoadSpinner';
import UserSelector from '../../common/UserSelector/UserSelector.vue';
import {
  getPrezentationShareDetails,
  updatePresentationShareDetails,
} from '../../../utils/api-helper';
import { MatomoAnalyticsHandler } from '../../common/Analytics/MatomoAnalyticsHandler';
import { trackCommonEvents } from '../../common/Analytics/CommonEvents';
import { trackUploadEvents } from '../../common/Analytics/UploadEvents';
import {
  NA,
  TD_COMMON_COLUMN_NAME,
  TD_COMMON_COLUMN_VALUE,
  TD_ISEDITOR,
  TD_ISPRIVATE,
  TD_ISVIEWER,
  TD_TIME_SPENT_IN_SECS,
} from '../../common/Analytics/MatomoTrackingDataHelper';
import NewFeatureBadge from '../../common/NewFeatureBadge.vue';

export default {
  name: 'SharePrezentation',
  props: {
    prezentation: {
      type: Object,
      required: true,
    },
    prezentationUpdatedFn: {
      type: Function,
      required: false,
    },
    origin: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      default: 'profile.shareTemplateAccess.shareWithColleague',
    },
    source: {
      type: String,
      required: false,
    },
    parentComponent: {
      type: String,
      required: false,
    },
    addToPrezentations: {
      type: Boolean,
      required: false,
      default: false,
    },
    addToSlides: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: { LoadSpinner, UserSelector, NewFeatureBadge },
  data() {
    return {
      componentEnterTime: 0,
      componentExitTime: 0,
      timeSpent: 0,
      searchColleagueInput: null,
      isLoading: true,
      updateBtnText: 'common.update',
      companyUsers: [],
      selectedUsers: [],
      autoUpdate: true,
      companyPermission: '1',
      teamPermission: '1',
      shareOptionType: 'me',
      permissionGroup: [],
      userLevelShareDetails: null,
      companyLevelShareDetails: null,
      teamLevelShareDetails: null,
      shareOptions: [
        {
          text: 'Restricted',
          description:
            'Your colleagues can view slides, read comments, and download a pdf copy of the prezentation.',
          value: '0',
        },
        {
          text: 'Viewer',
          description:
            'Your colleagues can view slides, read comments, and download the prezentation.',
          value: '1',
        },
        {
          text: 'Reviewer',
          description:
            'Your colleagues can view slides and add comments to the prezentation.',
          value: '2',
        },
        {
          text: 'Editor',
          description:
            'Your colleagues can edit the storyline, add comments, and swap slides in the prezentation.',
          value: '3',
        },
      ],
      userShareOptions: [
        {
          text: 'Restricted',
          description:
            'Your colleagues can view slides, read comments, and download a pdf copy of the prezentation.',
          value: '0',
        },
        {
          text: 'Viewer',
          description:
            'Your colleagues can view slides, read comments, and download the prezentation.',
          value: '1',
        },
        {
          text: 'Reviewer',
          description:
            'Your colleagues can view slides and add comments to the prezentation.',
          value: '2',
        },
        {
          text: 'Editor',
          description:
            'Your colleagues can edit the storyline, add comments, and swap slides in the prezentation.',
          value: '3',
        },
      ],
    };
  },
  async beforeMount() {
    this.prezentation.prezentationId = this.prezentation.prezentationId
      ? this.prezentation.prezentationId
      : this.prezentation.id;
    this.componentEnterTime = Date.now();
    if (this.origin === 'upload-prezentation') {
      this.updateBtnText = 'common.update';
    }
    if (
      this.origin === 'upload-prezentation' &&
      this.source === 'upload-feature'
    ) {
      this.updateBtnText = 'common.upload';
    }
    this.isLoading = true;
    if (this.origin !== 'upload-prezentation') {
      trackCommonEvents.prezentationShareClick(
        this.currentUser,
        this.origin,
        this.prezentation,
      );
    }
    if (this.prezentation.prezentationId) {
      const prezentationShareDetails = await getPrezentationShareDetails(
        this.prezentation.prezentationId,
      );

      this.shareOptionType =
        prezentationShareDetails.message.prezentationACL.length > 0
          ? 'share'
          : 'me';
      this.userLevelShareDetails =
        prezentationShareDetails.message.prezentationACL.filter(
          (acl) => acl.shared_entity_type === 'user',
        ) || [];
      this.companyLevelShareDetails =
        prezentationShareDetails.message.prezentationACL.filter(
          (acl) => acl.shared_entity_type === 'company',
        ) || [];
      this.teamLevelShareDetails =
        prezentationShareDetails.message.prezentationACL.filter(
          (acl) => acl.shared_entity_type === 'team',
        ) || [];

      if (this.userLevelShareDetails.length > 0) {
        this.permissionGroup.push('user');
        const users = this.userLevelShareDetails.map((user) => ({
          ...user,
          id: user.shared_entity_id,
          isActive: true, // making users enabled by default
          permission_level: String(user.permission_level),
        }));
        this.selectedUsers = users;
      }
      if (this.teamLevelShareDetails.length > 0) {
        this.permissionGroup.push('team');
        this.teamPermission = String(
          this.teamLevelShareDetails[0].permission_level,
        );
      }
      if (this.companyLevelShareDetails.length > 0) {
        const entry = this.companyLevelShareDetails[0];
        this.permissionGroup.push('company');
        this.companyPermission = String(entry.permission_level);
      }
    }
    this.isLoading = false;
  },
  computed: {
    ...mapState('users', ['currentUser']),
    filterShareOptions() {
      const { shareOptions } = this;
      const viewerIdx = shareOptions.findIndex(
        (item) => item.text === 'Viewer',
      );
      const editorIdx = shareOptions.findIndex(
        (item) => item.text === 'Editor',
      );
      if (
        this.parentComponent &&
        this.parentComponent === 'ShareUploadPrezentation'
      ) {
        shareOptions[viewerIdx].description =
          'Your colleagues can view slides and best practice tooltips, read comments, and download the prezentation.';
        shareOptions[editorIdx].description =
          'Your colleagues can contribute via edit slide details and best practices, replace images, add comments, and download the prezentation.';
      } else if (this.origin === 'upload-prezentation') {
        shareOptions[viewerIdx].description =
          'Your colleagues can view slides and best practice tooltips, read comments, and download the prezentation.';
        shareOptions[editorIdx].description =
          'Your colleagues can contribute via edit slide details and best practices, replace images, add comments, and download the prezentation.';
      } else {
        shareOptions[viewerIdx].description =
          'Your colleagues can view slides, read comments, and download the prezentation.';
        shareOptions[editorIdx].description =
          'Your colleagues can edit the storyline, add comments, and swap slides in the prezentation.';
      }
      if (this.prezentation.prezentationType === 'OP') {
        return shareOptions.filter((item) => item.text !== 'Editor');
      }
      if (this.origin === 'upload-prezentation') {
        return shareOptions.filter((item) => item.text !== 'Reviewer');
      }

      return shareOptions;
    },
    filterUserShareOptions() {
      const { userShareOptions } = this;
      const viewerIdx = userShareOptions.findIndex(
        (item) => item.text === 'Viewer',
      );
      const editorIdx = userShareOptions.findIndex(
        (item) => item.text === 'Editor',
      );
      if (
        this.parentComponent &&
        this.parentComponent === 'ShareUploadPrezentation'
      ) {
        userShareOptions[viewerIdx].description =
          'Your colleagues can view slides and best practice tooltips, read comments, and download the prezentation.';
        userShareOptions[editorIdx].description =
          'Your colleagues can contribute via edit slide details and best practices, replace images, add comments, and download the prezentation.';
      } else if (this.origin === 'upload-prezentation') {
        userShareOptions[viewerIdx].description =
          'Your colleagues can view slides and best practice tooltips, read comments, and download the prezentation.';
        userShareOptions[editorIdx].description =
          'Your colleagues can contribute via edit slide details and best practices, replace images, add comments, and download the prezentation.';
      } else {
        userShareOptions[viewerIdx].description =
          'Your colleagues can view slides, read comments, and download the prezentation.';
        userShareOptions[editorIdx].description =
          'Your colleagues can edit the storyline, add comments, and swap slides in the prezentation.';
      }
      if (this.prezentation.prezentationType === 'OP') {
        return userShareOptions.filter((item) => item.text !== 'Editor');
      }
      if (this.origin === 'upload-prezentation') {
        return userShareOptions.filter((item) => item.text !== 'Reviewer');
      }
      return userShareOptions;
    },
  },
  methods: {
    onSelectedUsersChange(users) {
      this.selectedUsers = users.map((user) => {
        if (!user.permission_level) {
          user.permission_level = this.userShareOptions[1].value;
        }
        return user;
      });
    },
    updateShareDetails() {
      if (
        this.source === 'upload-feature' &&
        !this.addToSlides &&
        !this.addToPrezentations
      ) {
        return;
      }
      this.componentExitTime = Date.now();
      this.timeSpent =
        (this.componentExitTime - this.componentEnterTime) / 1000;
      let shareEntities = [];
      const usersToPush = [];

      this.permissionGroup.forEach((entity) => {
        switch (entity) {
          case 'company':
            shareEntities.push({
              shared_entity_type: 'company',
              shared_entity_id: this.currentUser.user.companyID,
              permission_level: this.companyPermission,
            });
            break;
          case 'team':
            shareEntities.push({
              shared_entity_type: 'team',
              shared_entity_id: this.currentUser.user.teamID,
              permission_level: this.teamPermission,
            });
            break;
          case 'user':
            this.selectedUsers.forEach((user) => {
              const userInfo = {
                shared_entity_type: 'user',
                shared_entity_id: user.id,
                permission_level: user.permission_level,
              };
              usersToPush.push(userInfo);
            });
            shareEntities.push(...usersToPush);
            break;
          default:
            shareEntities = [];
            break;
        }
      });

      if (
        this.prezentationUpdatedFn &&
        this.origin === 'upload-prezentation' &&
        this.source !== 'prezentationcard'
      ) {
        this.prezentationUpdatedFn(shareEntities);
        const selectedLibraries =
          (this.addToSlides ? 'Slides&' : 'NA&') +
          (this.addToPrezentations ? 'Prezentaion' : 'NA');
        const sharedEntitiesData = this.getSharedEntitiesData(shareEntities);
        trackUploadEvents.uploadImportNextSelectNextSanitizeNextTagNextShareUpdate(
          this.currentUser,
          {
            [TD_ISPRIVATE]: this.shareOptionType === 'me' ? '1' : '0',
            [TD_COMMON_COLUMN_NAME]: 'Add to Slides/ Prezentations',
            [TD_COMMON_COLUMN_VALUE]: selectedLibraries || NA,
            [TD_TIME_SPENT_IN_SECS]: this.timeSpent,
            ...sharedEntitiesData,
          },
        );
        return;
      }
      this.updateBtnText = 'common.updating';
      updatePresentationShareDetails(
        this.prezentation.prezentationId,
        shareEntities,
      )
        .then(() => {
          this.updateBtnText = 'navbarActions.shareFingerprintvue.updated';
          setTimeout(() => {
            if (this.prezentationUpdatedFn) {
              this.prezentationUpdatedFn(
                shareEntities.length === 0,
                this.prezentation,
              );
            }

            this.handleClose();
          }, 800);
          trackCommonEvents.prezentationShared(
            this.currentUser,
            this.origin,
            this.prezentation,
            shareEntities,
          );
          MatomoAnalyticsHandler.sharePrezentation(
            this.origin,
            shareEntities,
            this.prezentation,
            this.currentUser,
          );
          if (
            this.prezentationUpdatedFn &&
            this.origin === 'upload-prezentation' &&
            this.source === 'prezentationcard'
          ) {
            const isPrivate = this.shareOptionType === 'me';
            this.prezentationUpdatedFn(isPrivate);
          }
        })
        .finally(() => {});
    },
    handleClose() {
      this.$modal.hide('SharePrezentation');
    },
    handleShareTypeChangeFn() {
      if (this.shareOptionType) {
        this.permissionGroup = [];
      }
    },
    getSharedEntitiesData(sharedEntities) {
      const sharedEntitiesTrackingData = {};
      if (!sharedEntities.length) {
        return sharedEntitiesTrackingData;
      }

      const viewers = sharedEntities.filter(
        (sharedEntity) => sharedEntity.permission_level === '1',
      );
      const isViewerList = this.getEntityList(viewers);
      if (isViewerList) {
        sharedEntitiesTrackingData[TD_ISVIEWER] = isViewerList;
      }
      const editors = sharedEntities.filter(
        (sharedEntity) => sharedEntity.permission_level === '3',
      );
      const isEditorsList = this.getEntityList(editors);
      if (isEditorsList) {
        sharedEntitiesTrackingData[TD_ISEDITOR] = isEditorsList;
      }

      return sharedEntitiesTrackingData;
    },
    getEntityList(entities) {
      let eList = '';
      entities.forEach((sEntity) => {
        const entityType = sEntity.shared_entity_type;
        if (entityType === 'company') {
          eList += 'My Company$';
        }
        if (entityType === 'team') {
          eList += 'My Team$';
        }
        if (entityType === 'user') {
          eList += `${sEntity.sharedEntityID}$`;
        }
      });
      return eList;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/variables.scss', '@/scss/app.scss';

.main-wrapper {
  height: 100%;
  max-height: 85vh;
  min-height: 300px;
  overflow: auto;
}
.close-btn {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 1;
}
.radio-button_item {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.share-option-select_dropdown {
  max-width: 120px;
  width: 120px;
}
.sharing-modal-main-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  font-family: 'Lato', sans-serif;
  height: 100%;
  justify-content: space-between;
  padding: 2rem;
  width: 100%;

  .sharing-modal-container {
    width: 100%;
    // min-height: 200px;
  }
  ::v-deep .v-input--selection-controls__input {
    margin-right: 4px;
  }

  ::v-deep .v-input--selection-controls .v-radio > .v-label {
    color: black;
  }
  ::v-deep.v-select__selection,
  .v-select__selection--comma,
  .v-select.v-text-field input {
    color: rgba(0, 0, 0, 0.6) !important;
  }

  ::v-deep .v-select__selections {
    font-size: 16px;
  }

  .sharing-modal__title {
    font-size: 1.5em;
    font-weight: 500;
  }
  .sharing-modal__options {
    margin: 36px 0 0;
    width: 100%;
  }
  .sharing-modal__subtitle {
    font-size: 1.25em;
    font-weight: 500;
  }
  .sharing-modal__permissions {
    margin: 36px 0;
  }
  .edit-hint {
    color: $gray-dark-login-text;
    font-size: 0.875em;
    margin: 0px auto 0px 24px;
  }
  .actions {
    margin: 0 auto;
  }
}
.spinner-wrapper {
  left: 48%;
  margin: auto;
  position: absolute;
  top: 40%;
}
.share-select-item {
  padding: 5px;
  max-width: 220px;

  .share-select-item__description {
    color: gray;
    padding-top: 5px;
  }
}
.share-select-item__selected {
  width: 30px;
}

.share-container {
  margin-left: 25px;

  ::v-deep .v-label {
    color: black !important;
  }

  .v-input {
    margin-bottom: 10px;
    margin-top: 0;
    width: max-content;
  }
}
.pt-20 {
  padding-top: 20px;
}
.select-colleagues-autocomplete {
  margin-bottom: 0 !important;
  padding: 14px 14px 5px 14px;
  width: 100% !important;
}
.v-application .white--text {
  color: rgba(0, 0, 0, 0.87) !important;
  caret-color: rgba(0, 0, 0, 0.87) !important;
}
</style>
