<template>
  <div class="practice2-wrapper" id="practice2">
    <v-btn
      class="practice2-wrapper__back-btn"
      @click="handleBack()"
      icon
      color="#21a7e0"
    >
      <v-icon>mdi-arrow-left</v-icon>
      {{ $t('common.back') }}
    </v-btn>
    <div class="practice2-title">{{ $t('learn.course.practice') }}</div>
    <div class="practice2-description">
      {{ $t('learn.novice.practiceDescription1') }}
      <br />{{ $t('learn.novice.practiceDescription2') }}
    </div>
    <div class="practice2-content">
      <div class="practice2-content-title">
        {{ $t('learn.novice.exercise') }} {{ currentIndex + 1 }}/3
      </div>
      <div class="practice2-content-description">
        {{ $t('learn.novice.practiceDescription3') }}
      </div>
      <div class="practice2-content-explain">
        <img src="/assets/img/learn/icons-drag-drop-24-px.svg" alt />
        <div class="explain-content">
          {{ $t('learn.novice.explainContent') }}
        </div>
      </div>
      <template v-if="currentExerciseData">
        <drag-and-drop
          :exercise-data="currentExerciseData"
          :update-exercise-index="handleIndex"
          :current-index="currentIndex"
        />
      </template>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import DragAndDrop from './components/DragAndDrop';
import NoviceBadgeDialog from '../NoviceBadgeDialog';
import learnApi from '../../../../../API/learn-api';

export default {
  name: 'Practice2',
  components: {
    DragAndDrop,
  },
  data() {
    return {
      item: null,
      learnData: null,
      levelInfo: null,
      currentIndex: 0,
    };
  },
  computed: {
    ...mapState('learnStore', {
      initialLearnContent: 'learnContent',
    }),
    currentExerciseData() {
      const exerciseData = [
        {
          nodesAndParents: [
            {
              node: 'How can New Co. grow revenue?',
              targetParent: 'How can New Co. grow revenue?',
            },
            {
              node: '# of customers?',
              targetParent: 'How can New Co. grow revenue?',
            },
            {
              node: 'Average customer spend?',
              targetParent: 'How can New Co. grow revenue?',
            },
            {
              node: 'Total market volume?',
              targetParent: '# of customers?',
            },
            {
              node: 'New Co. market share?',
              targetParent: '# of customers?',
            },
            {
              node: '# of products bought per customer?',
              targetParent: 'Average customer spend?',
            },
            {
              node: '$ spend per product?',
              targetParent: 'Average customer spend?',
            },
          ],
          nodeDataArray: [
            {
              key: 1,
              name: 'How can New Co. grow revenue?',
              color: 'white',
              strokecolor: 'black',
            },
            {
              key: 2,
              name: '',
              parent: 1,
              color: '#D3D3D3',
              strokecolor: 'white',
            },
            {
              key: 3,
              name: '',
              parent: 1,
              color: '#D3D3D3',
              strokecolor: 'white',
            },
            {
              key: 4,
              name: '',
              parent: 2,
              color: '#D3D3D3',
              strokecolor: 'white',
            },
            {
              key: 5,
              name: '',
              parent: 2,
              color: '#D3D3D3',
              strokecolor: 'white',
            },
            {
              key: 6,
              name: '',
              parent: 3,
              color: '#D3D3D3',
              strokecolor: 'white',
            },
            {
              key: 7,
              name: '',
              parent: 3,
              color: '#D3D3D3',
              strokecolor: 'white',
            },
          ],
          myPaletteArray: [
            {
              key: '1',
              name: '# of customers?',
              color: 'white',
              strokecolor: 'white',
            },
            { key: '2', name: 'Total market volume?', color: 'white' },
            {
              key: '3',
              name: '# of products bought per customer?',
              color: 'white',
            },
            { key: '4', name: 'New Co. market share?', color: 'white' },
            { key: '5', name: 'Average customer spend?', color: 'white' },
            { key: '6', name: '$ spend per product?', color: 'white' },
          ],
        },
        {
          nodesAndParents: [
            {
              node: 'How can New Co. reduce operating costs?',
              targetParent: 'How can New Co. reduce operating costs?',
            },
            {
              node: 'Marketing and sales costs?',
              targetParent: 'How can New Co. reduce operating costs?',
            },
            {
              node: 'R&D costs?',
              targetParent: 'How can New Co. reduce operating costs?',
            },
            {
              node: 'Admin costs?',
              targetParent: 'How can New Co. reduce operating costs?',
            },
            {
              node: 'Sales compensation?',
              targetParent: 'Marketing and sales costs?',
            },
            {
              node: 'Advertising costs?',
              targetParent: 'Marketing and sales costs?',
            },
            {
              node: 'Researcher salaries?',
              targetParent: 'R&D costs?',
            },
            {
              node: 'Lab costs?',
              targetParent: 'R&D costs?',
            },
            {
              node: 'Rent?',
              targetParent: 'Admin costs?',
            },
            {
              node: 'Utilities?',
              targetParent: 'Admin costs?',
            },
          ],

          nodeDataArray: [
            {
              key: 1,
              name: 'How can New Co. reduce operating costs?',
              target: 'How can New Co. reduce operating costs?',
              color: 'white',
            },
            {
              key: 2,
              name: '',
              parent: 1,
              color: 'white',
            },
            {
              key: 3,
              name: '',
              parent: 1,
              color: 'white',
            },
            {
              key: 4,
              name: '',
              parent: 1,
              color: 'white',
            },
            {
              key: 5,
              name: '',
              parent: 2,
              color: 'white',
            },
            {
              key: 6,
              name: '',
              parent: 2,
              color: 'white',
            },
            {
              key: 7,
              name: '',
              parent: 3,
              color: 'white',
            },
            {
              key: 8,
              name: '',
              parent: 3,
              color: 'white',
            },
            {
              key: 9,
              name: '',
              parent: 4,
              color: 'white',
            },
            {
              key: 10,
              name: '',
              parent: 4,
              color: 'white',
            },
          ],
          myPaletteArray: [
            { key: '1', name: 'Marketing and sales costs?', color: 'white' },
            { key: '2', name: 'Admin costs?', color: 'white' },
            { key: '3', name: 'Advertising costs?', color: 'white' },
            { key: '4', name: 'Lab costs?', color: 'white' },
            { key: '5', name: 'Utilities?', color: 'white' },
            { key: '6', name: 'R&D costs?', color: 'white' },
            { key: '7', name: 'Sales compensation?', color: 'white' },
            { key: '8', name: 'Researcher salaries?', color: 'white' },
            { key: '9', name: 'Rent?', color: 'white' },
          ],
        },
        {
          nodesAndParents: [
            {
              node: 'How can New Co. improve customer experience?',
              targetParent: 'How can New Co. improve customer experience?',
            },
            {
              node: 'In-store experience?',
              targetParent: 'How can New Co. improve customer experience?',
            },
            {
              node: 'Driving experience?',
              targetParent: 'How can New Co. improve customer experience?',
            },
            {
              node: 'Service experience?',
              targetParent: 'How can New Co. improve customer experience?',
            },
            {
              node: 'Sales experience?',
              targetParent: 'In-store experience?',
            },
            {
              node: 'Purchase process?',
              targetParent: 'In-store experience?',
            },
            {
              node: 'Interior features?',
              targetParent: 'Driving experience?',
            },
            {
              node: 'Exterior features?',
              targetParent: 'Driving experience?',
            },
            {
              node: 'Phone support efficacy?',
              targetParent: 'Service experience?',
            },
            {
              node: 'Dealer service speed?',
              targetParent: 'Service experience?',
            },
          ],
          nodeDataArray: [
            {
              key: 1,
              name: 'How can New Co. improve customer experience?',
              color: 'white',
              strokecolor: 'black',
            },
            {
              key: 2,
              name: '',
              parent: 1,
              color: 'white',
              strokecolor: 'black',
            },
            {
              key: 3,
              name: '',
              parent: 1,
              color: 'white',
              strokecolor: 'black',
            },
            {
              key: 4,
              name: '',
              parent: 1,
              color: 'white',
              strokecolor: 'black',
            },
            {
              key: 5,
              name: '',
              parent: 2,
              color: 'white',
              strokecolor: 'black',
            },
            {
              key: 6,
              name: '',
              parent: 2,
              color: 'white',
              strokecolor: 'black',
            },
            {
              key: 7,
              name: '',
              parent: 3,
              color: 'white',
              strokecolor: 'black',
            },
            {
              key: 8,
              name: '',
              parent: 3,
              color: 'white',
              strokecolor: 'black',
            },
            {
              key: 9,
              name: '',
              parent: 4,
              color: 'white',
              strokecolor: 'black',
            },
            {
              key: 10,
              name: '',
              parent: 4,
              color: 'white',
              strokecolor: 'black',
            },
          ],
          myPaletteArray: [
            { key: '1', name: 'In-store experience?', color: 'white' },
            { key: '2', name: 'Service experience?', color: 'white' },
            { key: '3', name: 'Purchase process?', color: 'white' },
            { key: '4', name: 'Exterior features?', color: 'white' },
            { key: '5', name: 'Dealer service speed?', color: 'white' },
            { key: '6', name: 'Driving experience?', color: 'white' },
            { key: '7', name: 'Sales experience?', color: 'white' },
            { key: '8', name: 'Interior features?', color: 'white' },
            { key: '9', name: 'Phone support efficacy?', color: 'white' },
          ],
        },
      ];

      return exerciseData[this.currentIndex];
    },
    ...mapState('users', ['currentUser']),
    ...mapState('learndata', ['userModuleItemStatuss']),
  },
  methods: {
    // ...mapActions('learndata', ['setUserLearnIndex']),

    handleBack() {
      this.$router.push({
        name: 'course.detail',
        params: { id: this.$route.params.courseId },
      });
    },

    async handleIndex() {
      if (this.currentIndex === 2) {
        const updateStatus = {
          asset_id: this.$route.params.assetId,
          status: 100,
        };
        await learnApi.methods.updateAssetUserStatus(updateStatus).then(
          (resp) => {
            if (resp) {
              this.$router.push({
                name: 'course.detail',
                params: { id: this.$route.params.courseId, showBadge: true },
              });
            }
          },
          (error) => {
            console.log('error while updating the asset status --', error);
            this.$router.push({
              name: 'course.detail',
              params: { id: this.$route.params.courseId, showBadge: true },
            });
          },
        );
      } else {
        this.currentIndex += 1;
      }
    },
    goToInitial() {
      this.$router.push('/home/learn/continue', () => {});
    },
    showBadgeDialog() {
      this.$modal.show(
        NoviceBadgeDialog,
        {
          goToInitial: this.goToInitial,
        },
        {
          name: 'NoviceBadgeDialog',
          width: '353px',
          height: '394px',
        },
      );
    },
  },
  mounted() {
    if (this.$route.query.index) {
      this.item = this.moduleItems.find(
        (item) => item.id === this.$route.query.index,
      );
    }
    this.currentIndex = 0;
  },
};
</script>

<style lang="scss" scoped>
.practice2-wrapper {
  margin-top: 30px;
  position: relative;
  margin-left: 30px;
  &__back-btn {
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    text-transform: unset;
  }
  .practice2-title {
    color: #000;
    font-size: 24px;
    font-stretch: normal;
    font-style: normal;
    font-weight: bold;
    letter-spacing: normal;
    line-height: normal;
    text-align: left;
    margin-top: 10px;
  }

  .practice2-description {
    font-size: 18px;
    text-align: left;
    padding-bottom: 20px;
    border-bottom: 1px solid #d8d8d8;
    margin-bottom: 20px;
  }

  .practice2-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .practice2-content-title {
      font-size: 20px;
      font-weight: bold;
      text-align: left;
      margin-bottom: 15px;
    }

    .practice2-content-description {
      font-size: 18px;
      margin-bottom: 15px;
    }

    .practice2-content-explain {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 30px;

      .explain-content {
        margin-left: 10px;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: italic;
      }
    }
  }
}
</style>
