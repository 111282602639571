<template>
  <div class="detail-banner">
    <div class="context-wrapper">
      <template v-if="!isPrezentSourceBestPractice">
        <span>
          {{
            prezentationData.prezentationSource === 'IOCUpload' ||
            prezentationData.prezentationSource === 'User Uploaded'
              ? $t('build.step3.updatedOn')
              : prezentationData.prezentationSource === 'Generated'
              ? $t('build.step3.generatedOn')
              : prezentationData.prezentationSource === 'Redesign'
              ? $t('build.step3.redesignedOn')
              : prezentationData.prezentationSource === 'Comply'
              ? $t('build.step3.addedOn')
              : $t('build.step3.createdOn')
          }}
          {{ prezentationData.createdAt | formatDate('MM/DD/YYYY') }}
        </span>
        <span class="define-context-sub-header__divider"> | </span>
      </template>
      <v-tooltip bottom content-class="tooltip-content">
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on" class="storyline">
            {{ $t('prezentationsList.storylineText') }}:
            {{ getStoryline }}
          </span>
        </template>
        <span>
          {{ getStoryline }}
        </span>
      </v-tooltip>
      <span class="define-context-sub-header__divider"> | </span>
      <span>{{ $t('prezentationsList.source') }}: {{ sourceName }} </span>
      <span class="define-context-sub-header__divider"> | </span>
      <PrezentationShareStatus
        :currentUser="currentUser"
        :prezentation="prezentationData"
        :isEditable="true"
        :origin="`${
          origin || matomo_my_prezentation
        }-${matomo_expanded_view}-icon`"
      />
      <span
        class="define-context-sub-header"
        v-if="
          (prezentationData.prezentationSource === 'Product' ||
            prezentationData.prezentationSource === 'User Uploaded') &&
          !isPrezentSourceBestPractice
        "
      >
        <span
          class="define-context-sub-header__divider"
          style="padding-right: 10px"
        >
          |
        </span>
        <span>
          {{ $t('build.step3.author') }}:
          {{
            currentUser.user.id === prezentationData.ownerID
              ? $t('build.step1.me')
              : `${prezentationData.author}`
          }}
        </span>
      </span>
      <template
        v-if="
          [
            'Product',
            'User Uploaded',
            'IOCUpload',
            'Generated',
            'Comply',
          ].includes(prezentationData.prezentationSource)
        "
      >
        <span class="define-context-sub-header__divider"> | </span>
        <v-tooltip bottom content-class="tooltip-content">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on" class="description prez-description">
              {{ $t('build.step3.description') }}:
              {{ getPrezDescription }}
            </span>
          </template>
          <span>{{ getPrezDescription }}</span>
        </v-tooltip>
      </template>
      <template v-if="getPrezAlias.length > 0">
        <span class="define-context-sub-header__divider"> | </span>
        <v-tooltip bottom content-class="tooltip-content">
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
              class="description prez-description also-known-as"
            >
              {{ $t('bestPracticesGuide.knowContainer.alsoKnownAs') }}
              {{ getPrezAlias }}
            </span>
          </template>
          <span>{{ getPrezAlias }}</span>
        </v-tooltip>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import PrezentationShareStatus from '../../Prezentations/PrezentationShareStatus.vue';
import { capitalizeFirstLetter } from '../../../../utils/common';
import {
  EXPANDED_VIEW,
  MY_PREZENTATION,
} from '../../../common/Analytics/MatomoTrackingDataHelper';

export default {
  name: 'PrezentationDetailBanner',
  components: {
    PrezentationShareStatus,
  },
  data() {
    return {
      matomo_expanded_view: EXPANDED_VIEW,
      matomo_my_prezentation: MY_PREZENTATION,
    };
  },
  props: {
    origin: {
      type: String,
      required: false,
      default: MY_PREZENTATION,
    },
  },
  computed: {
    ...mapState('users', ['currentUser', 'themes']),
    ...mapState('prezentationDetails', ['prezentationData']),
    isPrezentSourceBestPractice() {
      const { prezentationType, source, prezentationSource } =
        this.prezentationData;
      return (
        prezentationType === 'Best Practices' &&
        source?.toLowerCase() === 'prezent' &&
        prezentationSource === 'IOCUpload'
      );
    },
    getStoryline() {
      return this.prezentationData?.type?.toLowerCase() === 'other'
        ? this.$t('build.step3.customStoryLine')
        : this.prezentationData.type;
    },
    sourceName() {
      return this.prezentationData.source
        ? capitalizeFirstLetter(this.prezentationData.source)
        : this.currentUser?.company?.displayName;
    },
    getPrezDescription() {
      if (this.prezentationData.type?.toLowerCase() === 'other') {
        return 'Custom storyline';
      }
      return this.prezentationData?.description ===
        'Build your own storyline based on specific needs'
        ? 'Custom outline tailored to the audience'
        : this.prezentationData?.description;
    },
    getPrezAlias() {
      return this.prezentationData?.alias || '';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss', '@/scss/app.scss';

.detail-banner {
  display: flex;
  font-family: 'Lato' !important;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  gap: 10px;
  letter-spacing: normal;
  line-height: normal;
  margin-bottom: 10px;
  text-align: left;
  justify-content: space-between;

  .define-context-sub-header__divider {
    color: $darker-gray;
  }

  .context-wrapper {
    width: 100%;
    display: flex;
    gap: 10px;
    white-space: nowrap;

    .description {
      max-width: 600px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .prez-description {
      max-width: 36%;
    }
    .prez-description-best-practice {
      max-width: 80%;
    }
    .storyline {
      max-width: 20%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
</style>
