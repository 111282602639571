<template>
  <div>
    <div :class="`slides-comment ${isCommentMode ? 'col-3' : 'col-0 normal'}`">
      <div class="comments-title">
        {{ $t('build.step3.commentsTooltip') }}
      </div>
      <div ref="commentsContainer" class="comments">
        <div class="comment" v-for="(comment, index) in comments" :key="index">
          <div class="header">
            <AudienceItem
              :itemClass="'no-padding'"
              :firstName="comment.userAttributes.firstName"
              :lastName="comment.userAttributes.lastName"
              :profileImgS3Path="comment.userAttributes.profileImage"
              :email="getTimeStamp(comment.createdAt)"
              :maxWidth="260"
            >
              <div
                class="header-tool"
                v-if="comment.userID == currentUser.user.id"
              >
                <div
                  :class="`edit edit-comment-${index}`"
                  @click="handleCommentEdit(index)"
                  v-if="currentPrezentation.permission_level !== 0"
                >
                  <v-tooltip
                    top
                    content-class="tooltip-content"
                    max-width="250"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <img
                        src="/assets/img/comment-edit.svg"
                        alt
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <span>{{ $t('build.step3.edditCommentText') }}</span>
                  </v-tooltip>
                </div>
                <div
                  :class="`delete delete-comment-${index}`"
                  @click="handleCommentDelete(index)"
                  v-if="currentPrezentation.permission_level !== 0"
                >
                  <v-tooltip
                    top
                    content-class="tooltip-content"
                    max-width="250"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <img
                        src="/assets/img/comment-delete.svg"
                        alt
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <span>{{ $t('build.step3.deleteCommentText') }}</span>
                  </v-tooltip>
                </div>
              </div>
            </AudienceItem>
          </div>
          <div class="comment-content">
            <template v-if="!comment.isCommentEdit">
              <UserMentionView :value="comment.commentJson" />
            </template>
            <template v-if="comment.isCommentEdit">
              <UserMentionControl
                v-model="comment.commentEditable"
                :type="'edit'"
              />
            </template>
          </div>
          <div
            :class="`comment-control ${
              comment.isCommentEdit ? 'show' : 'hide'
            }`"
          >
            <div class="comment-cancel" @click="handleCommentCancel(index)">
              {{ $t('common.cancel') }}
            </div>
            <div class="comment-save" @click="handleCommentSave(index)">
              {{ $t('common.save') }}
            </div>
          </div>
        </div>
      </div>

      <div
        class="comment-send-wrapper"
        v-if="
          currentUser.user.cognitoID === currentPrezentation.owner ||
          (currentPrezentation.permission_level &&
            currentPrezentation.permission_level !== 1 &&
            currentPrezentation.permission_level !== 0)
        "
      >
        <UserMentionControl
          v-model="commentJson"
          ref="commentRef"
          :type="'add'"
        />
        <div class="comment-send-control">
          <div class="icons">
            <!-- <div class="comment-who">
                  <img src="/assets/img/comment-who.svg" alt />
                </div>
                <div class="comment-smile">
                  <img src="/assets/img/comment-smile.svg" alt />
                  </div>-->
          </div>
          <div
            class="comment-send-button"
            @click="handleAddComment(null, true)"
          >
            <img
              v-if="
                this.commentJson &&
                this.commentJson.length &&
                this.commentJson[0].content
              "
              src="/assets/img/comment-send.svg"
              alt
            />
            <img
              v-else
              disabled
              src="/assets/img/comment-send-disabled.svg"
              alt
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import { sendEmail, sendNotification } from '../../../../../utils/api-helper';
import {
  addPrezentationComment,
  updatePrezentationComment,
  deletePrezentationComment,
  updatePrezentationReviewers,
  getPrezentationCommentsByID,
} from '@/utils/api-helper';
import { MatomoAnalyticsHandler } from '../../../../common/Analytics/MatomoAnalyticsHandler';
import {
  getPrezData,
  TD_COMMENT,
  BUILD,
} from '../../../../common/Analytics/MatomoTrackingDataHelper';
import { AnalyticsHandler } from '../../../../common/Analytics/AnalyticsHandler';
import { trackBuildEvents } from '../../../../common/Analytics/BuildEvents';
import { trackPrezentationEvents } from '../../../../common/Analytics/PrezentationEvents';
import UserMentionControl from '../../../../common/UserMention/UserMentionControl.vue';
import AudienceItem from '../../../../common/AudienceItem.vue';
import UserMentionView from '../../../../common/UserMention/UserMentionView.vue';
import usersApi from '../../../../../API/users-api';

export default {
  name: 'PrezentationComments',
  data() {
    return {
      currentPrezentation: {},
      comments: [],
      newComments: 0,
      commentJson: null,
      isCommentMode: false,
    };
  },
  components: {
    UserMentionControl,
    AudienceItem,
    UserMentionView,
  },
  props: {
    origin: {
      type: String,
      default: '',
    },
    isNew: {
      type: Boolean,
      default: false,
    },
    updatePrezentation: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState('users', ['currentUser']),
    ...mapState('prezentationDetails', ['prezentationData', 'selectedSlide']),
    isViewAccess() {
      return (
        this.currNewPrezentation?.permission_level === 1 ||
        this.currNewPrezentation?.permission_level === 2 ||
        this.currNewPrezentation?.permission_level === 0
      );
    },
  },
  mounted() {
    this.currentPrezentation = this.prezentationData;
    // this.newComments = this.currentPrezentation?.countNewComments;
    this.fetchPrezentationComments(this.currentPrezentation.id).then(
      (comments) => {
        this.comments = comments
          .map((comment) => ({
            ...comment,
            commentJson: JSON.parse(comment.commentJson),
          }))
          .sort((a, b) => Date.parse(a.createdAt) - Date.parse(b.createdAt));

        /*
          Assigning the prezentation comments length as the initial comment count,
          instead of prezentation object 'countNewComments' property
        */
        this.newComments = this.comments.length || 0;
      },
    );
  },
  methods: {
    ...mapActions('users', ['setPrezentationInProcess']),
    getTimeStamp(time) {
      return moment(time).fromNow();
    },
    fetchPrezentationComments(prezentationID) {
      return new Promise((resolve, reject) => {
        const comments = [];
        getPrezentationCommentsByID(prezentationID)
          .then((response) => {
            response.data.forEach((item) => {
              comments.push(item);
            });
            resolve(comments);
          })
          .catch((err) => {
            reject(err.message);
          });
      });
    },
    handleCommentEdit(index) {
      if (!this.currentPrezentation.permission_level !== 0) {
        const contents = this.comments[index].commentJson;

        this.$set(this.comments[index], 'commentEditable', contents);
        this.$set(this.comments[index], 'isCommentEdit', true);
      }
    },
    handleCommentDelete(index) {
      if (!this.currentPrezentation.permission_level !== 0) {
        const comment = this.comments[index];
        const prezentationID = comment.id;
        deletePrezentationComment(prezentationID)
          .then(() => {
            // Filtering out the deleted comment
            this.comments = this.comments.filter((_, ind) => ind !== index);

            // assigning comments length as the comment count
            this.newComments = this.comments.length || 0;

            this.updatePrezentation({
              ...this.currentPrezentation,
              countNewComments: this.newComments,
            });
          })
          .catch((err) => console.log(err));
      }
    },
    handleCommentCancel(index) {
      this.$set(this.comments[index], 'isCommentEdit', false);
      this.$set(this.comments[index], 'commentEditable', null);
    },
    handleCommentSave(index) {
      const comment = this.comments[index];
      const prezentationData = {
        id: comment.id,
        prezentationID: comment.prezentationID,
        userID: comment.userID,
        commentJson: JSON.stringify(comment.commentEditable),
      };

      updatePrezentationComment(prezentationData)
        .then((value) => {
          this.$set(
            this.comments[index],
            'commentJson',
            comment.commentEditable,
          );
          this.$set(this.comments[index], 'updatedAt', value.data.updatedAt);
          this.$set(this.comments[index], 'isCommentEdit', false);
        })
        .catch((err) => console.log(err));
    },
    async getUsersMap() {
      const users = await usersApi.methods.search('*', {
        filterBy: `email:=[${this.currentPrezentation?.reviewersIDs.join()}]`,
        limit: null,
      });
      const usersMap = new Map();
      users.forEach((user) => {
        usersMap.set(user.document.email, user.document);
      });
      return usersMap;
    },
    async handleAddComment() {
      if (
        !this.commentJson ||
        !this.commentJson.length ||
        !this.commentJson[0].content
      ) {
        return;
      }

      const payload = {
        id: uuidv4(),
        prezentationID: this.currentPrezentation.id,
        userID: this.currentUser.user.id,
        commentJson: JSON.stringify(this.commentJson),
      };

      if (this.currentPrezentation) {
        const reviewers = this.$refs.commentRef?.getMentions();
        if (reviewers?.length) {
          const newReviewers = [
            ...new Set([
              ...(this.currentPrezentation.reviewersIDs || []),
              ...reviewers,
            ]),
          ];
          this.currentPrezentation.reviewersIDs = newReviewers;
        }
      }

      addPrezentationComment(payload)
        .then(async (value) => {
          AnalyticsHandler.addedCommentsToPrezentation(
            this.currentUser,
            this.currentPrezentation,
          );
          const prezData = getPrezData(this.currentPrezentation);
          const otherData = {
            ...prezData,
            [TD_COMMENT]: this.$refs.commentRef?.getText(),
          };
          if (this.isNew) {
            MatomoAnalyticsHandler.newPrezentationAddComments(
              this.currentPrezentation,
              this.currentUser,
            );
            if (this.origin.toLowerCase() === BUILD) {
              trackBuildEvents.buildComments(this.currentUser, otherData);
            }
          } else if (this.origin.toLowerCase() !== BUILD) {
            MatomoAnalyticsHandler.myPrezentationAddComments(
              this.currentPrezentation,
              this.currentUser,
            );
            trackPrezentationEvents.prezentationsComments(
              this.currentUser,
              otherData,
            );
          }

          this.comments.push({
            ...value.data,
            userAttributes: {
              firstName: this.currentUser.user.firstName,
              lastName: this.currentUser.user.lastName,
              profileImage: this.currentUser.user.profileImage,
            },
            commentJson: JSON.parse(value.data.commentJson),
          });

          // assigning comments length as the comment count;
          this.newComments = this.comments.length || 0;

          this.commentJson = null;

          const prezentationData = {
            id: this.currentPrezentation.id,
            countNewComments: this.newComments,
            reviewersIDs: this.currentPrezentation.reviewersIDs || [],
          };
          // using a callback to update again because the response of update comment does not have author or permisson levels
          this.updatePrezentation(
            prezentationData,
            () => {
              this.updatePrezentation({
                ...this.currentPrezentation,
                countNewComments: this.newComments,
              });
            },
            true,
          );
        })
        .catch((err) => {
          console.log(err);
          this.comments.push({
            username: this.currentUser.user.cognitoID,
            createdAt: new Date(),
            commentJson: this.commentJson,
            isOnline: true,
          });
        });

      // Sending emails/notification to reviewers
      if (this.currentPrezentation?.reviewersIDs?.length) {
        let reviewersEmails = [];
        const usersMap = await this.getUsersMap();

        this.currentPrezentation.reviewersIDs.forEach((reviewerId) => {
          const message = `${
            this.currentUser.user.firstName
              ? `${this.currentUser.user.firstName} ${
                  this.currentUser.user.lastName || ''
                }`
              : this.currentUser.user.cognitoID
          } has left a comment for you in the following prezentation:`;

          const notificationMessage = `${
            this.currentUser.user.firstName
              ? `${this.currentUser.user.firstName} ${
                  this.currentUser.user.lastName || ''
                }`
              : this.currentUser.user.cognitoID
          } has left a comment for you in the following prezentation: ${
            this.currentPrezentation.name
          }`;
          const notificationData = {
            from_user: this.currentUser.user.id,
            to: reviewerId,
            scope: 'user',
            notification: notificationMessage,
            use_case: 'prezentation_comment',
          };

          sendNotification(notificationData)
            .then((response) => console.log(response))
            .catch((err) => console.log(err));

          const reviewer = usersMap.get(reviewerId);
          if (reviewer) {
            reviewersEmails.push(reviewerId);
            const sub = `${this.currentUser.user.firstName} ${this.currentUser.user.lastName} left a comment on Prezent`;
            const params = {
              type: 'user-tagged-in-comment',
              to: [reviewerId],
              subject: sub,
              meta: {
                message,
                link: `${window.location.origin}/home/myPrezentation/${this.currentPrezentation.id}`,
                linkText: this.currentPrezentation.name,
                audience: `${reviewer.firstName} ${reviewer.lastName}`,
                requester: this.currentUser.user.firstName
                  ? this.currentUser.user.firstName
                  : this.currentUser.user.cognitoID,
              },
            };
            sendEmail(params)
              .then((response) => console.log(response))
              .catch((err) => console.log(err));
          }
        });
        reviewersEmails = reviewersEmails.filter(
          (e) => e !== this.currentPrezentation.ownerID,
        );
        // add reviewers
        await updatePrezentationReviewers(
          this.currentPrezentation.id,
          reviewersEmails,
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.slides-comment {
  align-items: center;
  /* box-shadow: -2px 2px 5px 1px rgba(64, 60, 67, 0.16); */
  display: flex;
  flex-direction: column;
  height: 80vh;
  justify-content: space-between;
  min-width: 100px;
  /* transition: all 0.3s ease-out; */
  /* position: relative; */

  //   &.normal {
  //     display: none;
  //     width: 0px;
  //   }

  &.active {
    width: 340px;
  }
  .comment-send-wrapper {
    width: 99%;

    textarea {
      box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
      height: 75px;
      width: 100%;
    }
    .comment-send-control {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 5px 0 5px;
      .icons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .comment-who {
          margin-right: 5px;
        }
        .comment-who,
        .comment-smile {
          cursor: pointer;
        }
      }
      .comment-send-button {
        cursor: pointer;
      }
      .comment-send-button > img {
        filter: invert(1);
      }
    }
    &::v-deep .um-editor-add:focus-within {
      box-shadow: 0 0 0 2px #21a7e0;
    }
  }
  .comments {
    height: calc(100% - 100px);
    width: 100%;
    overflow: auto;
    .comment {
      margin-bottom: 30px;
      position: relative;
      .header {
        align-items: flex-start;
        display: flex;
        justify-content: flex-start;
        position: relative;
        width: 100%;
        .header-tool {
          align-items: flex-start;
          display: flex;
          justify-content: flex-start;
          //position: absolute;
          right: 10px;
          top: 20px;
          .edit {
            margin-right: 10px;
          }
          .edit,
          .delete {
            cursor: pointer;
          }
        }
      }
      .comment-content {
        text-align: left;
        position: relative;
        width: 100%;
        input {
          width: 100%;
          border: 1px solid gray;
          padding: 3px;
          border-radius: 5px;
        }
        ::v-deep .um-view {
          max-width: 350px;
          overflow-wrap: break-word;
        }
      }
      .comment-control {
        position: absolute;
        bottom: -30px;
        right: 0px;
        display: none;
        align-items: center;
        justify-content: flex-start;
        &.show {
          display: flex;
        }
        .comment-cancel {
          margin-right: 10px;
        }
        .comment-cancel,
        .comment-save {
          cursor: pointer;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
        }
      }
    }
  }
}
.comments-title {
  width: 100%;
  padding-bottom: 20px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}
::v-deep .ml-list {
  max-height: 250px;
  overflow-y: auto;
}
</style>
