<template>
  <div class="submit-payment-wrapper">
    <v-btn class="close-btn" icon @click="handleClose">
      <v-icon dark v-text="`mdi-close`" />
    </v-btn>
    <div class="submit-payment-wrapper-title">
      {{ $t('profile.editPaymentDetail.managePayamentMethod') }}
    </div>
    <div class="submit-payment-content-wrapper">
      <div v-if="isFirstScreen" class="payment-info-container">
        <div class="payment-info-wrapper-title">
          {{ $t('profile.editPaymentDetail.yourPaymentMethod') }}:
        </div>
        <div class="payment-info-wrapper">
          <div class="visa-image">
            <img
              src="/assets/img/profile/visa.png"
              width="75px"
              height="51px"
              alt
            />
          </div>
          <div class="payment-info-content">
            <div class="payment-info-content-title">
              {{ $t('profile.editPaymentDetail.exampleCardInfo') }}
            </div>
            <div class="payment-info-content-desc">
              {{ $t('profile.editPaymentDetail.exampleDetails') }}
            </div>
          </div>
        </div>
        <div class="payment-info-container-actions">
          <v-btn
            text
            class="add-new-btn"
            height="50"
            @click="handlePayment('add')"
          >
            {{ $t('common.addNew') }}
          </v-btn>
          <div class="payment-info-container-action-bar" />
          <v-btn
            text
            class="edit-btn"
            height="50"
            @click="handlePayment('edit')"
          >
            {{ $t('common.edit') }}
          </v-btn>
        </div>
      </div>
      <div v-else class="submit-payment-info">
        <v-btn text class="go-back-btn" height="50" @click="handleBack">
          {{ $t('common.back') }}
        </v-btn>
        <div class="submit-payment-info-title">
          {{ $t('profile.editPaymentDetail.paymentInfo') }}:
        </div>
        <div class="credit-card-wrapper">
          <div class="credit-card-title">
            {{ $t('profile.editPaymentDetail.creditCardNumber') }}
          </div>
          <stripe-element-card
            pk="
              pk_live_51IzsYWApReYnWNiuddi7hxiriMjPqtgSzrTg4K7PC6rbBfFZuEjLgN49K1dFCklIhoQupCpcrSEqda9M4ChTbJus00z9uOzUzu
            "
          />
        </div>
        <div class="date-picker-wrapper">
          <div class="expiration-month-wrapper">
            <div class="expiration-month-wrapper-title">
              {{ $t('profile.editPaymentDetail.expirationMonth') }}
            </div>
            <img src="/assets/img/profile/down-arrow.svg" alt />
            <select name="month" id="month">
              <option value>
                {{ $t('profile.editPaymentDetail.selectMonth') }}
              </option>
              <option value="1">January</option>
              <option value="2">February</option>
              <option value="3">March</option>
              <option value="4">April</option>
              <option value="5">May</option>
              <option value="6">June</option>
              <option value="7">July</option>
              <option value="8">August</option>
              <option value="9">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>
            </select>
          </div>
          <div class="expiration-year-wrapper">
            <div class="expiration-year-wrapper-title">
              {{ $t('profile.editPaymentDetail.expirationYear') }}
            </div>
            <img src="/assets/img/profile/down-arrow.svg" alt />
            <select name="year" id="year">
              <option value>
                {{ $t('profile.editPaymentDetail.selectYear') }}
              </option>
              <option value="2041">2041</option>
              <option value="2040">2040</option>
              <option value="2039">2039</option>
              <option value="2038">2038</option>
              <option value="2037">2037</option>
              <option value="2036">2036</option>
              <option value="2035">2035</option>
              <option value="2034">2034</option>
              <option value="2033">2033</option>
              <option value="2032">2032</option>
              <option value="2031">2031</option>
              <option value="2030">2030</option>
              <option value="2029">2029</option>
              <option value="2028">2028</option>
              <option value="2027">2027</option>
              <option value="2026">2026</option>
              <option value="2025">2025</option>
              <option value="2024">2024</option>
              <option value="2023">2023</option>
              <option value="2022">2022</option>
              <option value="2021">2021</option>
              <option value="2020">2020</option>
              <option value="2019">2019</option>
              <option value="2018">2018</option>
              <option value="2017">2017</option>
              <option value="2016">2016</option>
              <option value="2015">2015</option>
              <option value="2014">2014</option>
              <option value="2013">2013</option>
              <option value="2012">2012</option>
              <option value="2011">2011</option>
              <option value="2010">2010</option>
              <option value="2009">2009</option>
              <option value="2008">2008</option>
              <option value="2007">2007</option>
              <option value="2006">2006</option>
              <option value="2005">2005</option>
              <option value="2004">2004</option>
              <option value="2003">2003</option>
              <option value="2002">2002</option>
              <option value="2001">2001</option>
              <option value="2000">2000</option>
              <option value="1999">1999</option>
              <option value="1998">1998</option>
              <option value="1997">1997</option>
              <option value="1996">1996</option>
              <option value="1995">1995</option>
              <option value="1994">1994</option>
              <option value="1993">1993</option>
              <option value="1992">1992</option>
              <option value="1991">1991</option>
              <option value="1990">1990</option>
              <option value="1989">1989</option>
              <option value="1988">1988</option>
              <option value="1987">1987</option>
              <option value="1986">1986</option>
              <option value="1985">1985</option>
              <option value="1984">1984</option>
              <option value="1983">1983</option>
              <option value="1982">1982</option>
              <option value="1981">1981</option>
              <option value="1980">1980</option>
              <option value="1979">1979</option>
              <option value="1978">1978</option>
              <option value="1977">1977</option>
              <option value="1976">1976</option>
              <option value="1975">1975</option>
              <option value="1974">1974</option>
              <option value="1973">1973</option>
              <option value="1972">1972</option>
              <option value="1971">1971</option>
              <option value="1970">1970</option>
            </select>
          </div>
        </div>
        <div class="names-wrapper">
          <div class="name-wrapper">
            <div class="name-wrapper-title">
              {{ $t('navbarActions.addColleagueDetail.firstName') }}
            </div>
            <input
              v-model="firstName"
              :placeholder="$t('profile.editPaymentDetail.enterFirstName')"
            />
          </div>
          <div class="name-wrapper">
            <div class="name-wrapper-title">
              {{ $t('navbarActions.addColleagueDetail.lastName') }}
            </div>
            <input
              v-model="lastName"
              :placeholder="$t('profile.editPaymentDetail.enterLastName')"
            />
          </div>
        </div>
        <div class="billing-address-wrapper">
          <div class="billing-address-title">
            {{ $t('toggleAutoRenew.billingAddress') }}
          </div>
          <input
            v-model="billingAddress"
            :placeholder="$t('profile.editPaymentDetail.enterBillingAddress')"
          />
        </div>
        <div class="zip-country-wrapper">
          <div class="zip-code-wrapper">
            <div class="zip-code-wrapper-title">
              {{ $t('toggleAutoRenew.zipcode') }}
            </div>
            <input v-model="zipCode" placeholder="12345" />
          </div>
          <div class="country-wrapper">
            <div class="country-wrapper-title">
              {{ $t('toggleAutoRenew.country') }}
            </div>
            <select
              id="country"
              name="country"
              class="form-control"
              v-model="country"
            >
              <option value="Afghanistan">Afghanistan</option>
              <option value="Åland Islands">Åland Islands</option>
              <option value="Albania">Albania</option>
              <option value="Algeria">Algeria</option>
              <option value="American Samoa">American Samoa</option>
              <option value="Andorra">Andorra</option>
              <option value="Angola">Angola</option>
              <option value="Anguilla">Anguilla</option>
              <option value="Antarctica">Antarctica</option>
              <option value="Antigua and Barbuda">Antigua and Barbuda</option>
              <option value="Argentina">Argentina</option>
              <option value="Armenia">Armenia</option>
              <option value="Aruba">Aruba</option>
              <option value="Australia">Australia</option>
              <option value="Austria">Austria</option>
              <option value="Azerbaijan">Azerbaijan</option>
              <option value="Bahamas">Bahamas</option>
              <option value="Bahrain">Bahrain</option>
              <option value="Bangladesh">Bangladesh</option>
              <option value="Barbados">Barbados</option>
              <option value="Belarus">Belarus</option>
              <option value="Belgium">Belgium</option>
              <option value="Belize">Belize</option>
              <option value="Benin">Benin</option>
              <option value="Bermuda">Bermuda</option>
              <option value="Bhutan">Bhutan</option>
              <option value="Bolivia">Bolivia</option>
              <option value="Bosnia and Herzegovina">
                Bosnia and Herzegovina
              </option>
              <option value="Botswana">Botswana</option>
              <option value="Bouvet Island">Bouvet Island</option>
              <option value="Brazil">Brazil</option>
              <option value="British Indian Ocean Territory">
                British Indian Ocean Territory
              </option>
              <option value="Brunei Darussalam">Brunei Darussalam</option>
              <option value="Bulgaria">Bulgaria</option>
              <option value="Burkina Faso">Burkina Faso</option>
              <option value="Burundi">Burundi</option>
              <option value="Cambodia">Cambodia</option>
              <option value="Cameroon">Cameroon</option>
              <option value="Canada">Canada</option>
              <option value="Cape Verde">Cape Verde</option>
              <option value="Cayman Islands">Cayman Islands</option>
              <option value="Central African Republic">
                Central African Republic
              </option>
              <option value="Chad">Chad</option>
              <option value="Chile">Chile</option>
              <option value="China">China</option>
              <option value="Christmas Island">Christmas Island</option>
              <option value="Cocos (Keeling) Islands">
                Cocos (Keeling) Islands
              </option>
              <option value="Colombia">Colombia</option>
              <option value="Comoros">Comoros</option>
              <option value="Congo">Congo</option>
              <option value="Congo, The Democratic Republic of The">
                Congo, The Democratic Republic of The
              </option>
              <option value="Cook Islands">Cook Islands</option>
              <option value="Costa Rica">Costa Rica</option>
              <option value="Cote D'ivoire">Cote D'ivoire</option>
              <option value="Croatia">Croatia</option>
              <option value="Cuba">Cuba</option>
              <option value="Cyprus">Cyprus</option>
              <option value="Czech Republic">Czech Republic</option>
              <option value="Denmark">Denmark</option>
              <option value="Djibouti">Djibouti</option>
              <option value="Dominica">Dominica</option>
              <option value="Dominican Republic">Dominican Republic</option>
              <option value="Ecuador">Ecuador</option>
              <option value="Egypt">Egypt</option>
              <option value="El Salvador">El Salvador</option>
              <option value="Equatorial Guinea">Equatorial Guinea</option>
              <option value="Eritrea">Eritrea</option>
              <option value="Estonia">Estonia</option>
              <option value="Ethiopia">Ethiopia</option>
              <option value="Falkland Islands (Malvinas)">
                Falkland Islands (Malvinas)
              </option>
              <option value="Faroe Islands">Faroe Islands</option>
              <option value="Fiji">Fiji</option>
              <option value="Finland">Finland</option>
              <option value="France">France</option>
              <option value="French Guiana">French Guiana</option>
              <option value="French Polynesia">French Polynesia</option>
              <option value="French Southern Territories">
                French Southern Territories
              </option>
              <option value="Gabon">Gabon</option>
              <option value="Gambia">Gambia</option>
              <option value="Georgia">Georgia</option>
              <option value="Germany">Germany</option>
              <option value="Ghana">Ghana</option>
              <option value="Gibraltar">Gibraltar</option>
              <option value="Greece">Greece</option>
              <option value="Greenland">Greenland</option>
              <option value="Grenada">Grenada</option>
              <option value="Guadeloupe">Guadeloupe</option>
              <option value="Guam">Guam</option>
              <option value="Guatemala">Guatemala</option>
              <option value="Guernsey">Guernsey</option>
              <option value="Guinea">Guinea</option>
              <option value="Guinea-bissau">Guinea-bissau</option>
              <option value="Guyana">Guyana</option>
              <option value="Haiti">Haiti</option>
              <option value="Heard Island and Mcdonald Islands">
                Heard Island and Mcdonald Islands
              </option>
              <option value="Holy See (Vatican City State)">
                Holy See (Vatican City State)
              </option>
              <option value="Honduras">Honduras</option>
              <option value="Hong Kong">Hong Kong</option>
              <option value="Hungary">Hungary</option>
              <option value="Iceland">Iceland</option>
              <option value="India">India</option>
              <option value="Indonesia">Indonesia</option>
              <option value="Iran, Islamic Republic of">
                Iran, Islamic Republic of
              </option>
              <option value="Iraq">Iraq</option>
              <option value="Ireland">Ireland</option>
              <option value="Isle of Man">Isle of Man</option>
              <option value="Israel">Israel</option>
              <option value="Italy">Italy</option>
              <option value="Jamaica">Jamaica</option>
              <option value="Japan">Japan</option>
              <option value="Jersey">Jersey</option>
              <option value="Jordan">Jordan</option>
              <option value="Kazakhstan">Kazakhstan</option>
              <option value="Kenya">Kenya</option>
              <option value="Kiribati">Kiribati</option>
              <option value="Korea, Democratic People's Republic of">
                Korea, Democratic People's Republic of
              </option>
              <option value="Korea, Republic of">Korea, Republic of</option>
              <option value="Kuwait">Kuwait</option>
              <option value="Kyrgyzstan">Kyrgyzstan</option>
              <option value="Lao People's Democratic Republic">
                Lao People's Democratic Republic
              </option>
              <option value="Latvia">Latvia</option>
              <option value="Lebanon">Lebanon</option>
              <option value="Lesotho">Lesotho</option>
              <option value="Liberia">Liberia</option>
              <option value="Libyan Arab Jamahiriya">
                Libyan Arab Jamahiriya
              </option>
              <option value="Liechtenstein">Liechtenstein</option>
              <option value="Lithuania">Lithuania</option>
              <option value="Luxembourg">Luxembourg</option>
              <option value="Macao">Macao</option>
              <option value="Macedonia, The Former Yugoslav Republic of">
                Macedonia, The Former Yugoslav Republic of
              </option>
              <option value="Madagascar">Madagascar</option>
              <option value="Malawi">Malawi</option>
              <option value="Malaysia">Malaysia</option>
              <option value="Maldives">Maldives</option>
              <option value="Mali">Mali</option>
              <option value="Malta">Malta</option>
              <option value="Marshall Islands">Marshall Islands</option>
              <option value="Martinique">Martinique</option>
              <option value="Mauritania">Mauritania</option>
              <option value="Mauritius">Mauritius</option>
              <option value="Mayotte">Mayotte</option>
              <option value="Mexico">Mexico</option>
              <option value="Micronesia, Federated States of">
                Micronesia, Federated States of
              </option>
              <option value="Moldova, Republic of">Moldova, Republic of</option>
              <option value="Monaco">Monaco</option>
              <option value="Mongolia">Mongolia</option>
              <option value="Montenegro">Montenegro</option>
              <option value="Montserrat">Montserrat</option>
              <option value="Morocco">Morocco</option>
              <option value="Mozambique">Mozambique</option>
              <option value="Myanmar">Myanmar</option>
              <option value="Namibia">Namibia</option>
              <option value="Nauru">Nauru</option>
              <option value="Nepal">Nepal</option>
              <option value="Netherlands">Netherlands</option>
              <option value="Netherlands Antilles">Netherlands Antilles</option>
              <option value="New Caledonia">New Caledonia</option>
              <option value="New Zealand">New Zealand</option>
              <option value="Nicaragua">Nicaragua</option>
              <option value="Niger">Niger</option>
              <option value="Nigeria">Nigeria</option>
              <option value="Niue">Niue</option>
              <option value="Norfolk Island">Norfolk Island</option>
              <option value="Northern Mariana Islands">
                Northern Mariana Islands
              </option>
              <option value="Norway">Norway</option>
              <option value="Oman">Oman</option>
              <option value="Pakistan">Pakistan</option>
              <option value="Palau">Palau</option>
              <option value="Palestinian Territory, Occupied">
                Palestinian Territory, Occupied
              </option>
              <option value="Panama">Panama</option>
              <option value="Papua New Guinea">Papua New Guinea</option>
              <option value="Paraguay">Paraguay</option>
              <option value="Peru">Peru</option>
              <option value="Philippines">Philippines</option>
              <option value="Pitcairn">Pitcairn</option>
              <option value="Poland">Poland</option>
              <option value="Portugal">Portugal</option>
              <option value="Puerto Rico">Puerto Rico</option>
              <option value="Qatar">Qatar</option>
              <option value="Reunion">Reunion</option>
              <option value="Romania">Romania</option>
              <option value="Russian Federation">Russian Federation</option>
              <option value="Rwanda">Rwanda</option>
              <option value="Saint Helena">Saint Helena</option>
              <option value="Saint Kitts and Nevis">
                Saint Kitts and Nevis
              </option>
              <option value="Saint Lucia">Saint Lucia</option>
              <option value="Saint Pierre and Miquelon">
                Saint Pierre and Miquelon
              </option>
              <option value="Saint Vincent and The Grenadines">
                Saint Vincent and The Grenadines
              </option>
              <option value="Samoa">Samoa</option>
              <option value="San Marino">San Marino</option>
              <option value="Sao Tome and Principe">
                Sao Tome and Principe
              </option>
              <option value="Saudi Arabia">Saudi Arabia</option>
              <option value="Senegal">Senegal</option>
              <option value="Serbia">Serbia</option>
              <option value="Seychelles">Seychelles</option>
              <option value="Sierra Leone">Sierra Leone</option>
              <option value="Singapore">Singapore</option>
              <option value="Slovakia">Slovakia</option>
              <option value="Slovenia">Slovenia</option>
              <option value="Solomon Islands">Solomon Islands</option>
              <option value="Somalia">Somalia</option>
              <option value="South Africa">South Africa</option>
              <option value="South Georgia and The South Sandwich Islands">
                South Georgia and The South Sandwich Islands
              </option>
              <option value="Spain">Spain</option>
              <option value="Sri Lanka">Sri Lanka</option>
              <option value="Sudan">Sudan</option>
              <option value="Suriname">Suriname</option>
              <option value="Svalbard and Jan Mayen">
                Svalbard and Jan Mayen
              </option>
              <option value="Swaziland">Swaziland</option>
              <option value="Sweden">Sweden</option>
              <option value="Switzerland">Switzerland</option>
              <option value="Syrian Arab Republic">Syrian Arab Republic</option>
              <option value="Taiwan, Province of China">
                Taiwan, Province of China
              </option>
              <option value="Tajikistan">Tajikistan</option>
              <option value="Tanzania, United Republic of">
                Tanzania, United Republic of
              </option>
              <option value="Thailand">Thailand</option>
              <option value="Timor-leste">Timor-leste</option>
              <option value="Togo">Togo</option>
              <option value="Tokelau">Tokelau</option>
              <option value="Tonga">Tonga</option>
              <option value="Trinidad and Tobago">Trinidad and Tobago</option>
              <option value="Tunisia">Tunisia</option>
              <option value="Turkey">Turkey</option>
              <option value="Turkmenistan">Turkmenistan</option>
              <option value="Turks and Caicos Islands">
                Turks and Caicos Islands
              </option>
              <option value="Tuvalu">Tuvalu</option>
              <option value="Uganda">Uganda</option>
              <option value="Ukraine">Ukraine</option>
              <option value="United Arab Emirates">United Arab Emirates</option>
              <option value="United Kingdom">United Kingdom</option>
              <option value="United States">United States</option>
              <option value="United States Minor Outlying Islands">
                United States Minor Outlying Islands
              </option>
              <option value="Uruguay">Uruguay</option>
              <option value="Uzbekistan">Uzbekistan</option>
              <option value="Vanuatu">Vanuatu</option>
              <option value="Venezuela">Venezuela</option>
              <option value="Viet Nam">Viet Nam</option>
              <option value="Virgin Islands, British">
                Virgin Islands, British
              </option>
              <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
              <option value="Wallis and Futuna">Wallis and Futuna</option>
              <option value="Western Sahara">Western Sahara</option>
              <option value="Yemen">Yemen</option>
              <option value="Zambia">Zambia</option>
              <option value="Zimbabwe">Zimbabwe</option>
            </select>
          </div>
        </div>
      </div>
      <div class="your-order-info">
        <div class="your-order-header">
          {{ $t('profile.editPaymentDetail.youOrder') }}:
        </div>
        <div class="your-order-info-content">
          <div class="your-order-info-title">
            {{ dialogType === 'download' ? 'Download Booster' : '' }}
            {{ dialogType === '' ? 'Professional' : '' }}
            {{ dialogType === 'license' ? 'Licenses' : '' }}
          </div>
          <div class="your-order-info-content-image">
            <img
              :src="`/assets/img/profile/${
                dialogType === 'download'
                  ? 'download-boost'
                  : dialogType === 'license'
                  ? 'buy-license'
                  : 'subscription-professional'
              }.svg`"
              alt
            />
          </div>
          <div v-if="dialogType === ''">
            <div class="your-order-info-content-title">US $xxx/year</div>
            <div class="your-order-info-content-sub-title">
              {{ $t('profile.editPaymentDetail.perUser') }}
            </div>
            <div class="your-order-info-content-sub-desc">
              {{ $t('profile.editPaymentDetail.noOfDownloads') }}
            </div>
            <div class="your-oder-info-content-subscript-title">
              {{ $t('profile.editPaymentDetail.subscriptionTerm') }}:
            </div>
            <div class="your-oder-info-content-subscript-value">
              April 14, 2021 - April 13, 2021
            </div>
            <div class="your-oder-info-content-subscript-input-title">
              {{ $t('profile.editPaymentDetail.licensesIncluded') }}:
            </div>
            <div class="your-oder-info-content-subscript-input-value">
              <input v-model="licenseNum" />
            </div>
          </div>
          <div v-else-if="dialogType === 'download'" class="download-info">
            <div class="download-info-content-title">
              {{ $t('profile.editPaymentDetail.addSlideDownloads') }}
              <br />{{ $t('profile.editPaymentDetail.teamDownloadLimit') }}.
            </div>
            <div class="download-info-content-quantity">
              {{ $t('profile.editPaymentDetail.quantity') }}
            </div>
            <div class="download-info-content-actions">
              <div class="download-info-content-actions-button">-</div>
              <div class="download-info-content-actions-value">
                <div class="download-info-content-actions-value-content">
                  250
                </div>
                <div class="download-info-content-actions-value-other">
                  ($ XXX.XX)
                </div>
              </div>
              <div class="download-info-content-actions-button">+</div>
            </div>
          </div>
          <div v-else class="license-info">
            <div class="license-info-content-title">
              {{ $t('profile.editPaymentDetail.addLicensesToYour') }}
              <br />{{ $t('subscriptionExpired.subscription') }}.
            </div>
            <div class="license-info-content-quantity">
              {{ $t('profile.editPaymentDetail.quantity') }}
            </div>
            <div class="download-info-content-input">
              <input v-model="licenseNum" />
            </div>
          </div>
        </div>
        <div class="your-order-info-auto-renew">
          {{ $t('profile.editPaymentDetail.autoRenewsOn') }}
        </div>
      </div>
    </div>
    <div class="total-prices-info">
      <div class="total-prices-info-sub">
        {{ $t('profile.editPaymentDetail.subTotal') }}
      </div>
      <div class="total-prices-info-tax">
        {{ $t('profile.editPaymentDetail.tax') }}
      </div>
      <div class="total-prices-info-total">
        {{ $t('profile.editPaymentDetail.total') }}
      </div>
    </div>
    <span class="submit-payment-terms-privacy">
      {{ $t('profile.editPaymentDetail.paymentTermPrivacy') }}
      <a href="#">{{
        $t('profile.editPaymentDetail.prezentCustomerService')
      }}</a
      >. {{ $t('profile.editPaymentDetail.youAlsoAgree') }}
      <a href="https://prezent.ai/terms-of-service/" target="_blank"
        >{{ $t('profile.editPaymentDetail.termsOfService') }}&nbsp;</a
      >
      &nbsp;{{ $t('profile.editPaymentDetail.and') }}&nbsp;
      <a href="https://prezent.ai/privacy-policy/" target="_blank">{{
        $t('fingerprint.privacyPolicy')
      }}</a
      >.
    </span>
    <div class="submit-payment-actions">
      <v-btn
        color="#21a7e0"
        rounded
        class="agree-subscribe"
        width="298"
        height="50"
        @click="handleSubscribe"
      >
        {{ dialogType !== '' ? 'Agree and pay' : 'Save' }}
      </v-btn>
      <div class="submit-payment-actions-right">
        <div class="submit-payment-actions-right-title">
          {{ $t('profile.editPaymentDetail.haveAQuestion') }}
        </div>
        <div class="submit-payment-actions-right-link">
          <a href="#">{{ $t('fingerprint.getInTouch') }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { StripeElementCard } from '@vue-stripe/vue-stripe';
import ProcessingPaymentDetail from '../../common/ProcessingPaymentDetail.vue';
import SuccessPaymentDetail from './SuccessPaymentDetail.vue';

export default {
  name: 'SubmitPaymentDetail',
  components: {
    StripeElementCard,
  },
  props: {
    dialogType: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      licenseNum: '',
      firstName: '',
      lastName: '',
      billingAddress: '',
      zipCode: '',
      country: 'United States',
      isFirstScreen: true,
    };
  },
  watch: {
    processing(val) {
      if (val) {
        this.$modal.show(
          ProcessingPaymentDetail,
          {
            processing: this.processing,
          },
          {
            name: 'ProcessingPaymentDetail',
            width: '683px',
            height: '280px',
          },
        );
      } else {
        this.$modal.hide('ProcessingPaymentDetail');
        this.$modal.show(
          SuccessPaymentDetail,
          {
            processing: this.processing,
          },
          {
            name: 'SuccessPaymentDetail',
            width: '683px',
            height: '555px',
          },
        );
      }
    },
  },
  methods: {
    handleClose() {
      this.$modal.hide('EditPaymentDetail');
    },
    handleSubscribe() {
      this.processing = true;
      setTimeout(() => {
        this.processing = false;
      }, 3000);
    },
    handlePayment(method) {
      console.log(method);
      this.isFirstScreen = false;
    },
    handleBack() {
      this.isFirstScreen = true;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.submit-payment-wrapper {
  width: 100%;
  height: 100%;
  padding: 43px 61px;
  overflow: scroll;

  .close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;
  }

  .submit-payment-wrapper-title {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    margin-bottom: 54px;
  }

  .payment-info-wrapper-title {
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-bottom: 24px;
  }

  .payment-info-container {
    .payment-info-container-actions {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 16px;

      button {
        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-transform: none;
        color: #21a7e0;
      }

      .payment-info-container-action-bar {
        width: 1px;
        height: 22px;
        background: #b6b6b6;
        margin-left: 5px;
        margin-right: 5px;
      }
    }
  }

  .payment-info-wrapper {
    border-radius: 20px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
    padding: 40px 0 44px 27px;
    width: 467px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .visa-image {
      margin-right: 15px;
    }

    .payment-info-content {
      .payment-info-content-title {
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        margin-bottom: 8px;
      }

      .payment-info-content-desc {
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
      }
    }
  }

  .submit-payment-content-wrapper {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .submit-payment-info {
      .go-back-btn {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #21a7e0;
      }

      .submit-payment-info-title {
        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        margin-bottom: 20px;
      }

      .credit-card-wrapper {
        margin-bottom: 12px;

        .credit-card-title {
          font-size: 20px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          margin-bottom: 10px;
        }

        ::v-deep #stripe-element-form {
          width: 466px;

          .StripeElement {
            border-radius: 8px;
            box-shadow: inset 0 1px 3px 0 #050708;
          }
        }
      }

      .date-picker-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 32px;

        select {
          border-radius: 8px;
          box-shadow: inset 0 1px 3px 0 #050708;
          width: 220px;
          height: 40px;
          padding: 8px 0 8px 11px;
          position: relative;
          font-size: 20px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
        }

        .expiration-month-wrapper {
          position: relative;
          img {
            position: absolute;
            top: 45px;
            right: 12px;
          }
          .expiration-month-wrapper-title {
            font-size: 20px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            margin-bottom: 10px;
          }
        }

        .expiration-year-wrapper {
          position: relative;
          img {
            position: absolute;
            top: 45px;
            right: 12px;
          }
          .expiration-year-wrapper-title {
            font-size: 20px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            margin-bottom: 10px;
          }
        }
      }

      .names-wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 32px;

        .name-wrapper {
          .name-wrapper-title {
            font-size: 20px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: black;
            margin-bottom: 10px;
          }

          input {
            width: 220px;
            height: 40px;
            padding: 8px 0 8px 11px;
            border-radius: 8px;
            box-shadow: inset 0 1px 3px 0 #050708;
          }
        }
      }

      .billing-address-wrapper {
        margin-bottom: 32px;

        .billing-address-title {
          font-size: 20px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: black;
          margin-bottom: 10px;
        }

        input {
          width: 466px;
          height: 40px;
          padding: 8px 0 8px 11px;
          border-radius: 8px;
          box-shadow: inset 0 1px 3px 0 #050708;
        }
      }

      .zip-country-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .zip-code-wrapper {
          .zip-code-wrapper-title {
            font-size: 20px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            margin-bottom: 10px;
          }

          input {
            width: 220px;
            height: 40px;
            padding: 8px 0 8px 11px;
            border-radius: 8px;
            box-shadow: inset 0 1px 3px 0 #050708;
          }
        }

        .country-wrapper {
          .country-wrapper-title {
            font-size: 20px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            margin-bottom: 10px;
          }

          select {
            border-radius: 8px;
            box-shadow: inset 0 1px 3px 0 #050708;
            width: 220px;
            height: 40px;
            padding: 8px 0 8px 11px;
            position: relative;
            font-size: 20px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
          }
        }
      }
    }

    .license-info {
      .license-info-content-title {
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        margin-bottom: 24px;
      }

      .license-info-content-quantity {
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        margin-bottom: 15px;
      }

      .download-info-content-input {
        display: flex;
        align-items: center;
        justify-content: center;

        input {
          width: 81px;
          height: 40px;
          border-radius: 8px;
          box-shadow: inset 0 1px 3px 0 #050708;
          text-align: center;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
        }
      }
    }

    .download-info {
      .download-info-content-title {
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        margin-bottom: 24px;
      }

      .download-info-content-quantity {
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        margin-bottom: 15px;
      }

      .download-info-content-actions {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .download-info-content-actions-button {
          box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.2),
            -1px -1px 4px 0 rgba(0, 0, 0, 0.1);
          width: 24px;
          height: 24px;
          border-radius: 50%;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .download-info-content-actions-value {
          margin-left: 16px;
          margin-right: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          .download-info-content-actions-value-content {
            color: black;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
          }
          .download-info-content-actions-value-other {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #7b7b7b;
          }
        }
      }
    }
    .your-order-info {
      .your-order-header {
        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        margin-bottom: 19px;
      }

      .your-order-info-title {
        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        margin-bottom: 19px;
      }

      .your-order-info-auto-renew {
        margin-top: 19px;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
      }

      .your-order-info-content {
        padding: 28px 17px 26px 17px;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
        width: 312px;
        border-radius: 16px;

        .your-order-info-title {
          font-size: 24px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          margin-bottom: 15px;
          color: black;
        }

        .your-order-info-content-image {
          margin-bottom: 13px;
          display: flex;

          img {
            margin: auto;
          }
        }

        .your-order-info-content-title {
          font-size: 24px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #7b7b7b;
        }

        .your-order-info-content-sub-title {
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #7b7b7b;
          margin-bottom: 13px;
        }

        .your-order-info-content-sub-desc {
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          margin-bottom: 20px;
        }

        .your-oder-info-content-subscript-title {
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          margin-bottom: 5px;
        }

        .your-oder-info-content-subscript-value {
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          margin-bottom: 20px;
        }

        .your-oder-info-content-subscript-input-title {
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          margin-bottom: 9px;
        }

        .your-oder-info-content-subscript-input-value {
          display: flex;
          align-items: center;
          justify-content: center;

          input {
            width: 81px;
            height: 40px;
            border-radius: 8px;
            box-shadow: inset 0 1px 3px 0 #050708;
            text-align: center;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
          }
        }
      }
    }
  }

  .total-prices-info {
    width: 100%;
    margin-top: 24px;
    margin-bottom: 79px;

    .total-prices-info-sub,
    .total-prices-info-tax,
    .total-prices-info-total {
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: right;
      color: #7b7b7b;
      margin-bottom: 5px;
    }

    .total-prices-info-total {
      color: black;
    }
  }

  .submit-payment-terms-privacy {
    display: inline-block;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    margin-bottom: 24px;

    a {
      color: #21a7e0;
    }
  }

  .submit-payment-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-transform: none;
      color: white;
    }

    .submit-payment-actions-right-title {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: right;
      color: black;
    }

    .submit-payment-actions-right-link a {
      text-align: right;
      color: #21a7e0;
    }
  }
}
</style>
