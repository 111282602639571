<template>
  <div class="slides-add-container">
    <div class="header-section">
      <div class="heading-title">
        {{ $t('common.galleryText') }}
      </div>
    </div>
    <div class="verbage">
      {{ $t('build.step3.verbage') }}
    </div>
    <div
      :style="{
        height: '85%',
        overflowY: 'scroll',
        overflowX: 'hidden',
      }"
      class="moz-tab-container"
    >
      <v-tabs
        :class="`library-tabs`"
        centered
        grow
        mobile-breakpoint
        v-model="currentTab"
      >
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-tab
              class="tab-item slides-tab--auto"
              :href="'#slides'"
              v-bind="attrs"
              v-on="on"
            >
              {{ $t('prezentationDetail.gallery_slide_library') }}
            </v-tab>
          </template>
          <span>{{ $t('prezentationDetail.gallery_slide_library') }}</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-tab
              class="tab-item prezentations-tab--auto"
              :href="'#prezentations'"
              v-bind="attrs"
              v-on="on"
            >
              {{ $t('prezentationDetail.gallery_prezentation_exchange') }}
            </v-tab>
          </template>
          <span>{{
            $t('prezentationDetail.gallery_prezentation_exchange')
          }}</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-tab
              class="tab-item best-practice-tab--auto"
              :href="'#best-practice'"
              v-bind="attrs"
              v-on="on"
              data-pendo-id="slides-gallery-bpe"
            >
              {{ $t('prezentationGallery.bestPracticeGuides') }}
            </v-tab>
          </template>
          <span>{{ $t('prezentationGallery.bestPracticeGuides') }}</span>
        </v-tooltip>
        <v-tabs-items v-model="currentTab">
          <v-tab-item :value="'slides'">
            <SlidesGallery
              :slidesList="getAllSlidesList"
              :onAddSlidesToSlideNumber="onAddSlidesToSlideNumber"
              :isIndexToAddSlidesSelected="!!indexToAddSlides"
              :prezentationDetails="prezentationMetaData"
              :currentTab="currentTab"
              :origin="origin"
            />
          </v-tab-item>
          <v-tab-item :value="'prezentations'">
            <PrezentationsGallery
              :slidesList="getAllSlidesList"
              :onAddSlidesToSlideNumber="onAddSlidesToSlideNumber"
              :isIndexToAddSlidesSelected="!!indexToAddSlides"
              :prezentationDetails="prezentationMetaData"
              :currentTab="currentTab"
              :onSectionDragStart="onSectionDragStart"
              :origin="origin"
            />
          </v-tab-item>
          <v-tab-item :value="'best-practice'">
            <BestPracticesGallery
              :slidesList="getAllSlidesList"
              :onAddSlidesToSlideNumber="onAddSlidesToSlideNumber"
              :isIndexToAddSlidesSelected="!!indexToAddSlides"
              :prezentationDetails="prezentationMetaData"
              :currentTab="currentTab"
              :onSectionDragStart="onSectionDragStart"
              :origin="origin"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import EventBus from '../../../common/event-bus';
import SlidesGallery from '../../../common/SharedComponents/SlidesGallery.vue';
import { getCopyDetails } from '../../../../utils/api-helper';
import BestPracticesGallery from '../../../common/SharedComponents/BestPracticesGallery.vue';
import PrezentationsGallery from '../../../common/SharedComponents/PrezentationsGallery.vue';
import { trackPrezentationEvents } from '../../../common/Analytics/PrezentationEvents';
import { trackBuildEvents } from '../../../common/Analytics/BuildEvents';
import {
  TD_COMMON_COLUMN_VALUE,
  TD_COMMON_COLUMN_NAME,
  TD_PLACEMENT,
  TD_PREZENTATION_CATEGORY,
  TD_IDEA,
  TD_SLIDE_SOURCE,
  TD_CONSTRUCT,
  TD_SLIDE_NAME,
  TD_SLIDEID,
  NA,
  TD_HASCARTOON,
  TD_HASICONS,
  TD_HASIMAGES,
  TD_IS3D,
  TD_DECK_ID,
  // TD_COMPANY_OF_AUD_TAILORED_FOR,
  TD_NODECOUNT,
  getBuildPrezentationData,
  BUILD,
  TD_TOP_PANE,
} from '../../../common/Analytics/MatomoTrackingDataHelper';

export default {
  components: {
    SlidesGallery,
    BestPracticesGallery,
    PrezentationsGallery,
  },
  data() {
    return {
      currentTab: 'slides',
      prezentationMetaData: {},
      selectedSlideData: {},
      sectionsList: [],
      isUpdated: false,
      indexToAddSlides: null,
      addSlideOrigin: '',
      hitTheApi: false,
      sectionInd: null,
      slideStartIndex: null,
      slideEndIndex: null,
    };
  },
  props: {
    origin: {
      type: String,
      default: 'Prezentation',
      required: false,
    },
    updatePrezentation: {
      type: Function,
      default: () => {},
    },
  },
  watch: {
    isUpdated(val) {
      if (val) {
        this.updatePrezentation(this.prezentationMetaData);
      }
      this.updateTheFlag();
    },
    prezentationData(val) {
      this.prezentationMetaData = val;
      this.sectionsList = [...this.prezentationMetaData.sections.list];
    },
  },
  computed: {
    ...mapState('users', ['currentUser']),
    ...mapState('prezentationDetails', [
      'prezentationData',
      'selectedSlide',
      'selectedMainAction',
    ]),
    getCurrentTab() {
      let currTab = '';
      if (this.currentTab === 'best-practice') {
        currTab = 'Best Practice examples';
      } else {
        currTab =
          this.currentTab.charAt(0).toUpperCase() +
          this.currentTab.slice(1, this.currentTab.length);
      }
      this.setGalleryTab(currTab);
      return currTab;
    },
    getAllSlidesList() {
      const list = [];
      let count = 0;
      this.sectionsList.forEach((section, sectionIndex) => {
        list.push({
          sectionIndex,
          slideIndex: 0,
          sectionName: section.sectionName,
        });
        section.slides
          .filter((i) => i.assetId)
          .forEach((slide, slideIndex) => {
            list.push({ sectionIndex, slideIndex, count: ++count });
          });
      });
      return list;
    },
  },
  methods: {
    ...mapActions('prezentationDetails', ['setGalleryTab']),
    onSectionDragStart() {
      this.$root.$emit('sectionDragStart');
    },
    indexOfSlide(sectionIndex, slideIndex) {
      this.sectionsList[sectionIndex].slides.forEach((index, slide) => {
        let Index = null;
        if (slide.assetId === slideIndex.assetId) {
          Index = index;
        }
        return Index;
      });
    },
    addSlidesOnClick(sectionIndex, slideIndex, origin) {
      this.addSlideOrigin = origin;
      if (sectionIndex !== undefined && slideIndex !== undefined) {
        this.indexToAddSlides = { sectionIndex, slideIndex };
      } else {
        this.indexToAddSlides = null;
      }
      EventBus.$emit('MINIMIZE_LEFTNAV');
    },
    haveTheFlag(Obj) {
      const keysList = [
        'isSynthesis',
        'isComply',
        'isGenerated',
        'isAnonymized',
        'isUserUploaded',
        'isRedesign',
      ];
      for (const key in Obj) {
        if (keysList.includes(key) && Obj[key]) {
          return true;
        }
      }
      return false;
    },
    handlePayload(sectionIndex, slideStartIndex, slideEndIndex) {
      const temp = {
        s3dirpath: this.prezentationData?.s3dirpath || null,
        prezentationID: this.prezentationData.id,
        slides: [],
      };

      const sections = this.prezentationData.sections.list[sectionIndex].slides;
      sections.slice(slideStartIndex, slideEndIndex).forEach((item) => {
        if (this.haveTheFlag(item)) {
          const {
            assetId,
            filename,
            uniqueID,
            isSynthesis,
            isComply,
            isGenerated,
            isOPUploaded,
            isUserUploaded,
            isRedesign,
          } = item;
          const object = {
            assetId,
            filename,
            uniqueID,
            isSynthesis,
            isComply,
            isGenerated,
            isOPUploaded,
            isUserUploaded,
            isRedesign,
          };
          temp.slides.push(object);
        }
      });
      if (temp.slides.length > 0) {
        this.hitTheApi = true;
      }

      return temp;
    },
    onAddSlidesToSlideNumber(indexObject, slidesList) {
      if (!indexObject) {
        indexObject = this.indexToAddSlides;
      }
      if (this.prezentationMetaData.sections.list.length) {
        this.prezentationMetaData.sections.list[
          indexObject.sectionIndex
        ].slides.splice(indexObject.slideIndex, 0, ...slidesList);
      } else {
        this.prezentationMetaData.sections.list = [
          {
            slides: slidesList,
            sectionName: 'All Slides',
          },
        ];
      }
      for (let i = 0; i < slidesList.length; i++) {
        const otherData = {
          [TD_IDEA]: slidesList[i]?.category,
          [TD_CONSTRUCT]:
            slidesList[i]?.constructs && slidesList[i]?.constructs[0]
              ? slidesList[i]?.constructs[0]
              : '',
          [TD_SLIDE_SOURCE]: this.getSlideSource(slidesList[i]),
          [TD_SLIDE_NAME]: slidesList[i]?.name,
          [TD_SLIDEID]: this.getSlideId(slidesList[i]),
          [TD_DECK_ID]: this.getDeckId(slidesList[i]),
          [TD_PLACEMENT]: this.addSlideOrigin
            ? this.addSlideOrigin
            : TD_TOP_PANE,
          [TD_HASCARTOON]: slidesList[i]?.prefs?.has_cartoon === 'yes' ? 1 : 0,
          [TD_HASICONS]: slidesList[i]?.prefs?.has_icons === 'yes' ? 1 : 0,
          [TD_HASIMAGES]: slidesList[i]?.prefs?.has_image === 'yes' ? 1 : 0,
          [TD_IS3D]: slidesList[i]?.prefs?.has_three_d === 'yes' ? 1 : 0,
          [TD_COMMON_COLUMN_NAME]: 'Tab',
          [TD_COMMON_COLUMN_VALUE]: this.getCurrentTab,
          [TD_PREZENTATION_CATEGORY]: this.prezentationData?.prezentationType,
          [TD_NODECOUNT]: slidesList[i]?.prefs?.node,
        };
        if (this.origin.toLowerCase() === BUILD) {
          trackBuildEvents.buildSlideAddedStep3(this.currentUser, {
            ...getBuildPrezentationData(this.prezentationData),
            ...otherData,
          });
        } else {
          trackPrezentationEvents.prezentationsSlideAddedStep3(
            this.currentUser,
            this.prezentationData,
            otherData,
          );
        }
      }
      this.sectionInd = indexObject.sectionIndex;
      this.slideStartIndex = indexObject.sectionName
        ? 0
        : indexObject.slideIndex;
      this.slideEndIndex = indexObject.sectionName
        ? slidesList.length
        : indexObject.slideIndex + slidesList.length;
      const payload = this.handlePayload(
        this.sectionInd,
        this.slideStartIndex,
        this.slideEndIndex,
      );
      if (this.hitTheApi) {
        this.isUpdated = false;
        getCopyDetails(payload)
          .then((resp) => {
            const slideslist = {};
            resp.data?.slides.forEach((slide) => {
              slideslist[slide.uniqueID] = slide;
            });
            const presentation = JSON.parse(
              JSON.stringify(this.prezentationData),
            );

            if (this.prezentationData.sections?.list?.length) {
              presentation.sections.list =
                this.prezentationData?.sections.list.map((section) => {
                  section.slides = section.slides.map((slide) => {
                    // console.log(slide);

                    if (slideslist[slide.uniqueID]) {
                      return {
                        ...slide,
                        ...slideslist[slide.uniqueID],
                      };
                    }
                    return slide;
                  });
                  return section;
                });
              this.updatePrezentation(presentation);
            }
          })
          .catch((err) => console.log(err));
        this.hitTheApi = false;
      }
      this.indexToAddSlides = null;
      this.isUpdated = true;
    },
    updateTheFlag() {
      this.isUpdated = false;
    },
    getDeckId(slideData) {
      let uniqueID;
      if (!slideData) {
        return NA;
      }

      if (slideData?.prefs?.is_know === 'yes') {
        ({ unique_id: uniqueID } = slideData);
      } else {
        ({ uniqueID } = slideData);
      }
      if (!uniqueID?.includes('deck')) {
        return 'Deck ID not available';
      }
      return uniqueID?.substring(0, uniqueID.indexOf('deck') + 5);
    },
    getSlideSource(slideData) {
      if (!slideData) {
        return NA;
      }
      if (slideData.source !== undefined) {
        return slideData.source;
      }
      if (slideData?.prefs?.source) {
        return slideData?.prefs?.source;
      }
      return 'Source not available';
    },
    getSlideId(slideData) {
      let uniqueID;
      if (!slideData) {
        return NA;
      }
      if (slideData?.prefs?.is_know === 'yes') {
        ({ unique_id: uniqueID } = slideData);
      } else {
        ({ uniqueID } = slideData);
      }
      if (['redesign', 'generated', 'uploaded'].includes(slideData?.source))
        return uniqueID;
      return uniqueID?.split('deck1_')[1]?.split('_')[0];
    },
  },
  beforeMount() {
    this.prezentationMetaData = this.prezentationData;
    this.sectionsList = this.prezentationMetaData.sections.list;
  },
  mounted() {
    this.selectedSlideData = this.selectedSlide;
    this.$root.$on('addSlidesInBetween', this.addSlidesOnClick);
  },
  beforeDestroy() {
    this.$root.$off('addSlidesInBetween', this.addSlidesOnClick);
  },
};
</script>

<style lang="scss" scoped>
.slides-add-container {
  padding-right: 0;
  height: 100%;
  // box-shadow: -2px 0px 2px 0px #b6b6b64d;

  .verbage {
    padding: 5px 0 5px 0;
    font-size: 11px;
    margin-bottom: 8px;
  }
  .header-section {
    display: flex;
    justify-content: space-between;

    .heading-title {
      font-family: Lato;
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
    }
  }

  ::v-deep .v-slide-group__prev,
  ::v-deep .v-slide-group__next {
    display: none !important;
  }

  .tab-item {
    font-family: Lato;
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    text-transform: none !important;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none;
    width: 33.333%;
    padding: 0;
  }

  .v-window-item {
    padding: 10px;
  }

  .v-window {
    overflow: unset !important;
  }
}
@-moz-document url-prefix() {
  .moz-tab-container {
    padding-right: 20px;
  }
}
</style>
