var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"slides-add-container"},[_c('div',{staticClass:"header-section"},[_c('div',{staticClass:"heading-title"},[_vm._v(" "+_vm._s(_vm.$t('common.galleryText'))+" ")])]),_c('div',{staticClass:"verbage"},[_vm._v(" "+_vm._s(_vm.$t('build.step3.verbage'))+" ")]),_c('div',{staticClass:"moz-tab-container",style:({
      height: '85%',
      overflowY: 'scroll',
      overflowX: 'hidden',
    })},[_c('v-tabs',{class:"library-tabs",attrs:{"centered":"","grow":"","mobile-breakpoint":""},model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-tab',_vm._g(_vm._b({staticClass:"tab-item slides-tab--auto",attrs:{"href":'#slides'}},'v-tab',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('prezentationDetail.gallery_slide_library'))+" ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('prezentationDetail.gallery_slide_library')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-tab',_vm._g(_vm._b({staticClass:"tab-item prezentations-tab--auto",attrs:{"href":'#prezentations'}},'v-tab',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('prezentationDetail.gallery_prezentation_exchange'))+" ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('prezentationDetail.gallery_prezentation_exchange')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-tab',_vm._g(_vm._b({staticClass:"tab-item best-practice-tab--auto",attrs:{"href":'#best-practice',"data-pendo-id":"slides-gallery-bpe"}},'v-tab',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('prezentationGallery.bestPracticeGuides'))+" ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('prezentationGallery.bestPracticeGuides')))])]),_c('v-tabs-items',{model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_c('v-tab-item',{attrs:{"value":'slides'}},[_c('SlidesGallery',{attrs:{"slidesList":_vm.getAllSlidesList,"onAddSlidesToSlideNumber":_vm.onAddSlidesToSlideNumber,"isIndexToAddSlidesSelected":!!_vm.indexToAddSlides,"prezentationDetails":_vm.prezentationMetaData,"currentTab":_vm.currentTab,"origin":_vm.origin}})],1),_c('v-tab-item',{attrs:{"value":'prezentations'}},[_c('PrezentationsGallery',{attrs:{"slidesList":_vm.getAllSlidesList,"onAddSlidesToSlideNumber":_vm.onAddSlidesToSlideNumber,"isIndexToAddSlidesSelected":!!_vm.indexToAddSlides,"prezentationDetails":_vm.prezentationMetaData,"currentTab":_vm.currentTab,"onSectionDragStart":_vm.onSectionDragStart,"origin":_vm.origin}})],1),_c('v-tab-item',{attrs:{"value":'best-practice'}},[_c('BestPracticesGallery',{attrs:{"slidesList":_vm.getAllSlidesList,"onAddSlidesToSlideNumber":_vm.onAddSlidesToSlideNumber,"isIndexToAddSlidesSelected":!!_vm.indexToAddSlides,"prezentationDetails":_vm.prezentationMetaData,"currentTab":_vm.currentTab,"onSectionDragStart":_vm.onSectionDragStart,"origin":_vm.origin}})],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }