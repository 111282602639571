<template>
  <div
    :class="`section-name ${isAddNewSection ? 'add-new' : ''} ${
      editMode ? 'show' : ''
    }`"
  >
    <div class="chip-title">
      <v-icon class="show" v-if="isAddNewSection">
        mdi-plus-circle-outline
      </v-icon>
      <div
        :class="`drag-icon section-drag-icon ${editMode ? 'show' : ''}`"
        v-else
      >
        <v-icon dense> mdi-dots-vertical </v-icon>
        <v-icon dense> mdi-dots-vertical </v-icon>
      </div>
      <v-text-field
        v-if="editMode"
        ref="input"
        class="section-input"
        v-model="newSectionName"
        hide-details
        @keyup.prevent
        @blur="onNameChangeCancel"
      ></v-text-field>
      <v-tooltip top v-else>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on" class="title-text">
            {{ isAddNewSection ? $t(defaultText) : data.sectionName }}
          </div>
        </template>
        <span>
          {{ isAddNewSection ? $t(defaultText) : data.sectionName }}
        </span>
      </v-tooltip>

      <v-badge
        color="#FFE1B7"
        class="badge"
        :content="count"
        inline
        v-if="!isAddNewSection && !editMode"
      ></v-badge>
    </div>
    <div class="actions" v-show="editMode">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-bind="attrs"
            v-on="on"
            small
            @mousedown="onNameChangeOk"
            :disabled="newSectionName.length <= 0"
          >
            mdi-check
          </v-icon>
        </template>
        <span> {{ $t('common.accept') }} </span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-bind="attrs"
            v-on="on"
            small
            @mousedown="onNameChangeCancel"
          >
            mdi-close
          </v-icon>
        </template>
        <span> {{ $t('common.cancel') }} </span>
      </v-tooltip>
    </div>
    <div class="actions" v-show="!editMode">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" small @click="onEditClick">
            mdi-pencil
          </v-icon>
        </template>
        <span> {{ $t('overnightPresentations.rename') }} </span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-bind="attrs"
            v-on="on"
            v-if="!isAddNewSection"
            small
            @click="handleDeleteSection"
          >
            mdi-delete-outline
          </v-icon>
        </template>
        <span> {{ $t('common.delete') }} </span>
      </v-tooltip>
    </div>
    <ConfirmationDialog
      :show="deleteSectionConfirmPopup"
      :on-primary-click="handleDeleteSectionCancel"
      :on-secondary-click="handleDeleteSectionConfirm"
      :on-close="handleDeleteSectionCancel"
      :primary-btn-text="$t('common.cancel')"
      :secondary-btn-text="$t('common.delete')"
      :title="$t('build.step3.deleteSection')"
      :text="confirmPopupText"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ConfirmationDialog from '@/components/common/ConfirmationDialog';
import { trackPrezentationEvents } from '../../../../common/Analytics/PrezentationEvents';
import {
  TD_PLACEMENT,
  TD_SECTION_NAME,
  TD_STEP3,
  TD_STEP2,
  BUILD,
} from '../../../../common/Analytics/MatomoTrackingDataHelper';

export default {
  components: {
    ConfirmationDialog,
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
    onNameChange: {
      type: Function,
      default: () => false,
    },
    onDeleteClick: {
      type: Function,
      default: () => false,
    },
    origin: {
      type: String,
      default: '',
    },
    isAddNewSection: {
      type: Boolean,
      default: false,
    },
    count: {
      type: String,
      default: '0',
    },
    onEdit: {
      type: Function,
      default: () => false,
    },
    defaultText: {
      type: String,
      default: 'build.step3.newSection',
    },
    currentPrezentation: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      deleteSectionConfirmPopup: false,
      editMode: false,
      newSectionName: '',
      confirmPopupText: this.$t('build.step2.deleteSectiobPopUp', {
        sectionName: this.data?.sectionName,
      }),
    };
  },
  computed: {
    ...mapState('users', ['currentUser']),
  },
  watch: {
    editMode() {
      this.onEdit(this.editMode);
    },
  },
  methods: {
    onNameChangeOk() {
      let placement = TD_STEP3;
      if (this.$route.name === 'build') placement = TD_STEP2;
      if (this.origin.toLowerCase() !== BUILD) {
        trackPrezentationEvents.prezentationsCustomSectionAddition(
          this.currentUser,
          this.currentPrezentation,
          { [TD_SECTION_NAME]: this.newSectionName, [TD_PLACEMENT]: placement },
        );
      }
      if (this.newSectionName) {
        this.onNameChange(this.newSectionName);
        this.editMode = false;
        this.newSectionName = '';
      }
    },
    onNameChangeCancel() {
      this.editMode = false;
      this.newSectionName = '';
    },
    onEditClick() {
      this.editMode = true;
      this.newSectionName = this.data?.sectionName || '';
      setTimeout(() => {
        this.$refs.input.focus();
      }, 100);
    },
    handleDeleteSection() {
      this.deleteSectionConfirmPopup = true;
    },
    handleDeleteSectionCancel() {
      this.deleteSectionConfirmPopup = false;
    },
    handleDeleteSectionConfirm() {
      this.deleteSectionConfirmPopup = false;
      this.onDeleteClick();
    },
  },
};
</script>

<style lang="scss" scoped>
.section-name {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #21a7e0;
  text-align: left;
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100% !important;
  height: 40px;

  .drag-icon {
    display: flex !important;
    cursor: move;
    // width: 14px;

    .v-icon {
      width: 5px;
      display: flex;
    }
  }

  .drag-icon:not(.show) {
    visibility: hidden !important;
  }

  .drag-icon.show {
    visibility: visible !important;
  }

  &.show {
    .v-icon {
      visibility: visible !important;
    }
  }

  .chip-title {
    display: flex;
    align-items: center;
    width: 100% !important;
    overflow: hidden;
    text-overflow: ellipsis;

    .v-icon {
      color: #a2a9b0;
    }

    .title-text {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      color: black;
      height: relative; /* Adjust the height as needed */
      overflow: hidden;
      text-overflow: ellipsis;
      //  display: flow;
    }
    // .title-text::after {
    //   content: '...';
    //   position: absolute;
    //   bottom: 0;
    //   right: 0;
    //   padding: 0 5px;
    //   background: white;
    // }
  }
  ::v-deep .badge .v-badge__badge {
    color: #4d5358 !important;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.5px;
  }

  .section-header-text {
    display: flex;
    align-items: center;
    // width: 100%;
  }
  .v-tooltip {
    display: flex !important;
  }

  &:hover {
    .badge {
      display: none;
    }
    .title-text {
      color: #21a7e0;
      margin: 0 5px;
    }
    .v-icon {
      visibility: visible !important;
    }
    .drag-icon {
      visibility: visible !important;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    // width: 20% !important;
    .v-icon {
      color: #21a7e0;
      cursor: pointer;
      visibility: hidden;
    }

    .mdi-delete-outline {
      font-size: 18px !important;
    }
  }
}

.add-new {
  background: #f2f4f8;
  border-radius: 8px;
  padding: 8px 5px;
  margin: 12px 0;

  &:hover {
    background: transparent;
  }
  // .chip-title {
  //   width: 90% !important;
  // }

  // .actions {
  //   width: 10% !important;
  // }
}
.section-input {
  padding: 0px;
  margin: 0 5px 0 5px;
}
.section-name.show {
  .v-icon {
    display: flex !important;
  }
}
</style>
