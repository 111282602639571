var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"700"},on:{"click:outside":_vm.onClose},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',{staticClass:"storyline-modal-container"},[(_vm.title)?_c('v-card-title',[_c('v-btn',{staticClass:"close-btn",attrs:{"icon":""},on:{"click":_vm.onClose}},[_c('v-icon',{attrs:{"dark":""},domProps:{"textContent":_vm._s("mdi-close")}})],1),_c('h3',{staticClass:"modal-title"},[_vm._v(_vm._s(_vm.$t(_vm.title)))])],1):_vm._e(),_c('v-card-text',{staticClass:"card-text"},[_c('div',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.$t(_vm.text))+" ")]),_c('div',{staticClass:"storyline-label"},[_c('div',{staticClass:"presentation-name-label"},[_vm._v(" "+_vm._s(_vm.$t('profile.storyLineModal.storylineType'))+" ")]),_c('v-text-field',{attrs:{"placeholder":_vm.$t('profile.storyLineModal.storylineExample'),"rules":_vm.maxLimit},on:{"change":function (e) { return _vm.handleChange('type', e); }},model:{value:(_vm.prezentationType),callback:function ($$v) {_vm.prezentationType=$$v},expression:"prezentationType"}})],1),_c('div',{staticClass:"storyline-label"},[_c('div',{staticClass:"presentation-name-label"},[_vm._v(" "+_vm._s(_vm.$t('profile.storyLineModal.briefDescription'))+" ")]),_c('v-text-field',{attrs:{"placeholder":_vm.$t('profile.storyLineModal.briefDescriptionExample')},on:{"change":function (e) { return _vm.handleChange('description', e); }},model:{value:(_vm.descriptionText),callback:function ($$v) {_vm.descriptionText=$$v},expression:"descriptionText"}})],1),_c('div',{staticClass:"d-flex justify-end"},[(_vm.primaryBtnText)?_c('v-btn',{staticClass:"primary-btn",attrs:{"outlined":"","rounded":"","color":"primary","disabled":_vm.isSecondaryClicked ||
            !_vm.prezentationType ||
            _vm.prezentationType.length > 100,"loading":_vm.isPrimaryloading && _vm.isPrimaryClicked},on:{"click":_vm.handlePrimaryClick}},[_vm._v(" "+_vm._s(_vm.$t(_vm.primaryBtnText))+" ")]):_vm._e(),(_vm.secondaryBtnText)?_c('v-btn',{staticClass:"secondary-btn",attrs:{"rounded":"","color":"primary","disabled":_vm.isPrimaryClicked ||
            !_vm.prezentationType ||
            _vm.prezentationType.length > 100 ||
            !_vm.descriptionText ||
            _vm.isSecondaryloading ||
            _vm.secondaryBtnText === 'Edits saved',"loading":(_vm.secondaryBtnText !== 'Edits saved' && _vm.isSecondaryloading) ||
            (_vm.secondaryBtnText !== 'Edits saved' && _vm.isSecondaryClicked)},on:{"click":_vm.handleSecondaryClick}},[(_vm.secondaryBtnText === 'Edits saved')?_c('v-icon',[_vm._v("mdi-check")]):_vm._e(),_vm._v(" "+_vm._s(_vm.$t(_vm.secondaryBtnText))+" ")],1):_vm._e()],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }