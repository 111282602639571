<template>
  <div class="op-card-container">
    <div class="deck-thumbnail">
      <v-img
        v-if="prezentation.thumbnailImageURL"
        :src="prezentation.thumbnailImageURL"
        alt="image"
        min-width="200"
        :aspect-ratio="16 / 9"
        class="blue--text align-end"
        lazy-src="/assets/img/op-thumbnail.png"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular indeterminate color="grey lighten-5" />
          </v-row>
        </template>
      </v-img>
      <v-img
        :src="'/assets/img/op-thumbnail.png'"
        alt="image"
        min-width="200"
        :aspect-ratio="16 / 9"
        class="blue--text align-end"
        v-else
      />
    </div>
    <div
      class="deck-info"
      :data-pendo-id="isFirstRequest ? 'op-summary' : null"
    >
      <div class="deck-title-row">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <span
              v-show="!showRename"
              v-bind="attrs"
              v-on="on"
              class="prezentation-name"
            >
              {{ prezentation.name }}
            </span>
          </template>
          <span>{{ prezentation.name }}</span>
        </v-tooltip>
        <div class="prez-edit-name" v-show="showRename">
          <div class="nameError" v-if="nameLimit">
            {{ 'Name cannot be more than 85 characters' }}
          </div>
          <v-text-field
            v-model="prezentation.name"
            hide-details
            :label="prezentation.name"
            solo
            @click.stop="() => {}"
            @focus.stop="handleRenameFocus()"
            @blur.stop="handleRenameBlur()"
            @keydown.esc="handleRenameBlur()"
            autofocus
          >
            <template #append>
              <v-btn
                class="rename-button"
                :disabled="prezentation.name === '' || nameLimit"
                rounded
                @click.stop="renameBtn"
              >
                <v-icon> mdi-check </v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </div>
        <div
          class="actions"
          :data-pendo-id="isFirstRequest ? 'action-list' : null"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <NewFeatureBadge
                module-name="release_2100"
                :feature-id="'release_2100'"
                :offsetX="'-10'"
                :offsetY="'-20'"
                :size="'small'"
                v-if="isFirstRequest"
              >
              </NewFeatureBadge>
              <a
                href="#"
                class="notification"
                @click.stop="goToConversationHistory"
              >
                <v-icon
                  color="21a7e0"
                  size="22"
                  v-text="`mdi-message-text-clock-outline`"
                  v-bind="attrs"
                  v-on="on"
                >
                </v-icon>
                <span v-if="getMessageCount" class="badge">{{
                  getMessageCount
                }}</span>
              </a>
            </template>
            <span>{{ $t('overnightPresentations.manageRequest') }}</span>
          </v-tooltip>
          <v-tooltip bottom v-if="!isLocalDownloading">
            <template v-slot:activator="{ on, attrs }">
              <!-- <v-icon
                class="icon"
                v-text="`mdi-download-outline`"
                color="#21a7e0"
                :disabled="!(prezentation.status === 'Completed')"
                size="24"
                @click.stop="download()"
                v-bind="attrs"
                v-on="on"
              /> -->
              <div
                @click="
                  (event) => {
                    event.stopPropagation();
                    event.preventDefault();
                  }
                "
              >
                <v-progress-circular
                  indeterminate
                  color="primary"
                  v-if="isLocalDownloading"
                  :size="22"
                />
                <Download
                  v-else
                  v-bind="attrs"
                  v-on="on"
                  :mode="'icon'"
                  :file-name="getFilename()"
                  :download-file-name="getDownloadFilename()"
                  :merged-url="() => getMergedUrlForSaveToGdrive()"
                  :item="getDownloadItem()"
                  :log-download="() => logDownload(prezentation.ticketId)"
                  :on-download="() => download(prezentation.ticketId)"
                  :isDisabled="!(prezentation.status === 'Completed')"
                  origin="prezentation"
                  limitsKey="prezentation"
                />
              </div>
            </template>
            <span>{{ $t('common.download') }}</span>
          </v-tooltip>
          <v-progress-circular
            indeterminate
            color="primary"
            :size="22"
            v-else
          />
          <v-menu
            top
            absolute
            offset-y
            :close-on-click="true"
            :position-x="menuPos.x"
            :position-y="menuPos.y"
          >
            <template #activator="menu">
              <v-tooltip bottom>
                <template #activator="tooltip">
                  <v-icon v-on="{ ...menu.on, ...tooltip.on }" color="primary">
                    mdi-dots-vertical
                  </v-icon>
                </template>
                <span>{{ $t('slides.moreActions') }}</span>
              </v-tooltip>
            </template>
            <v-list>
              <v-list-item
                v-for="(action, index) in [...getDotActionsOnStatus()]"
                :key="index"
                :disabled="action.disabled"
                @click="
                  handleDotOptionClick(action.name, prezentation.ticketId)
                "
              >
                <v-list-item-title>
                  <div
                    class="otherActions"
                    :class="{ disabled: action.disabled }"
                  >
                    <div class="icon">
                      <v-icon size="24" color="black">{{ action.icon }}</v-icon>
                    </div>
                    <div class="actionName">{{ action.name }}</div>
                  </div>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
      <div class="deck-type">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div class="storyline" v-bind="attrs" v-on="on">
              {{ $t('build.steps[1]') }}:
              {{ prezentation.storyline || $t('overnightPresentations.na') }}
            </div>
          </template>
          <span>
            {{ prezentation.storyline || $t('overnightPresentations.na') }}
          </span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div class="audAndAuthor" v-bind="attrs" v-on="on">
              {{ $t('overnightPresentations.audience1') }}:
              {{
                `${
                  (!prezentation.audienceFirstName &&
                    !prezentation.audienceLastName) ||
                  (prezentation.audienceFirstName === '' &&
                    prezentation.audienceLastName === '')
                    ? '-'
                    : `${prezentation.audienceFirstName || ''} ${
                        prezentation.audienceLastName || ''
                      }`
                }`
              }}
            </div>
          </template>
          <span>
            {{
              `${
                (!prezentation.audienceFirstName &&
                  !prezentation.audienceLastName) ||
                (prezentation.audienceFirstName === '' &&
                  prezentation.audienceLastName === '')
                  ? '-'
                  : `${prezentation.audienceFirstName || ''} ${
                      prezentation.audienceLastName || ''
                    }`
              }`
            }}
          </span>
        </v-tooltip>
      </div>
      <div class="deck-status">
        <div class="deck-status-text">
          <img :src="currentLevel.icon" class="status-icon" />
          <span>
            {{ currentLevel.display }}{{ statusText(prezentation) }}
          </span>
        </div>
        <!-- <OpRatingMenu :opRequest="prezentation" @update="onRatingUpdate" /> -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import ManageFiles from './ManageFiles.vue';
// import RequestChangeModal from './RequestChangeModal.vue';
import NewFeatureBadge from '../../common/NewFeatureBadge.vue';
// import OpRatingMenu from './OpRatingMenu.vue';
import {
  updateOPTicket,
  downloadFinalOPPPT,
  logOPDownload,
  getFeatureUsage,
  postFeatureUsage,
} from '../../../utils/api-helper';
// import { downloadFileWithCustomName } from '../../../utils/common';
import { trackOvernightPrezentationsEvents } from '../../common/Analytics/OvernightPrezentationsEvents';
import {
  TD_INSTRUCTIONS,
  TD_PREZENTATION_CATEGORY,
  TD_PREZNAME,
  NA,
  TD_VIEWTYPE,
  TD_PREZTYPE,
  TD_PLACEMENT,
  TD_MOSAIC,
  TD_THUMBNAIL_VIEW,
  TD_AUD,
  TD_LIST,
} from '../../common/Analytics/MatomoTrackingDataHelper';
import Download from '../../common/Download.vue';
import { callMergeSlidesApi } from '../../../utils/merge-slide-helper';
import { miscConfig } from '../../../runtimeConfig';

export default {
  name: 'OvernightPrezentationCard',
  data() {
    return {
      dotActions: [
        {
          name: 'Rename',
          icon: 'mdi-pencil-outline',
        },
        // {
        //   name: 'Manage files and links',
        //   icon: 'mdi-paperclip',
        // },
        // {
        //   name: 'Conversation history',
        //   icon: 'mdi-message-text-clock-outline',
        // },
        {
          name: 'Change request',
          icon: 'mdi-sync-circle',
        },
      ],
      menuPosition: {
        x: 0,
        y: 0,
      },
      payload: {},
      statuses: [
        {
          value: 'submitted',
          display: 'Submitted',
          image: '/assets/img/overnight-prezentations/op-submitted.png',
          icon: '/assets/img/overnight-prezentations/op-submitted-icon.svg',
          level: 0,
          text: 'Our team of designers is reviewing your request.',
          subtext:
            'We will notify you over email with an ETA and let you know if we have any questions.',
        },
        {
          value: 'accepted',
          display: 'Accepted',
          image: '/assets/img/overnight-prezentations/op-accepted.png',
          icon: '/assets/img/overnight-prezentations/op-accepted-icon.svg',
          level: 1,
          text: 'Great news! Our team is getting ready to start work on your request.',
        },
        {
          value: 'in progress',
          display: 'In Progress',
          image: '/assets/img/overnight-prezentations/op-in-progress.png',
          icon: '/assets/img/overnight-prezentations/op-in-progress-icon.svg',
          level: 2,
          text: 'Our team is working hard to meet your expectations. Hope you are getting some well-deserved rest!',
        },
        {
          value: 'completed',
          display: 'Completed',
          image: '/assets/img/overnight-prezentations/op-completed.png',
          icon: '/assets/img/overnight-prezentations/op-completed-icon.svg',
          level: 3,
          text: 'Yay! Your Overnight Prezentation is ready!',
        },
      ],
      showRename: false,
      oldNameValue: '',
      menuPos: {
        x: 0,
        y: 0,
      },
      // downloading: false,
    };
  },
  props: {
    prezentation: {
      type: Object,
      required: true,
      default: null,
    },
    isFirstRequest: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    // OpRatingMenu,
    NewFeatureBadge,
    Download,
  },
  methods: {
    ...mapActions('users', ['setLimitData', 'setUpgradePopup']),
    ...mapActions('commonDownloads', [
      'setDownloadItems',
      'setShowDownloadSnackbar',
      'setDownloadLoaders',
    ]),
    getDotActionsOnStatus() {
      let actions = [];
      switch (this.prezentation?.status) {
        // Add cases if new statuses are introduced
        case 'Completed':
          actions = this.dotActions.map((i) => {
            if (
              i.name === 'Change request' &&
              (!!this.getOPLimitedBanner ||
                (this.getOPAccessLevel !== 'full_access' &&
                  this.getOPAccessLevel !== 'restricted_access') ||
                this.isLimitExceeded)
            ) {
              return { ...i, disabled: true };
            }
            return { ...i, disabled: false };
          });
          break;

        case 'Submitted':
        case 'Accepted':
        case 'In Progress':
          actions = this.dotActions.filter(
            (itm) => itm.name !== 'Change request',
          );
          break;

        default:
          break;
      }
      return actions;
    },
    handleRenameFocus() {
      this.oldNameValue = this.prezentation.name;
    },
    handleRenameBlur() {
      this.prezentation.name = this.oldNameValue;
      this.showRename = false;
    },
    dateFormatter(data) {
      const date = new Date(data);
      console.log(date);
      // return `${date.getMonth()}/${date.getDate()}/${date.getFullYear()}`;
      return date.toLocaleDateString('en-US', {
        day: '2-digit',
        year: 'numeric',
        month: '2-digit',
      });
    },
    statusText(prezentation) {
      let textData = '';
      switch (prezentation?.status) {
        case 'Completed':
          textData = ` on ${this.dateFormatter(prezentation?.completionDate)}`;
          break;
        case 'Submitted':
          textData = ` on ${this.dateFormatter(prezentation?.createdAt)}`;
          break;
        default:
          break;
      }
      return textData;
    },
    renameBtn() {
      const prezentationName = this.prezentation.name.trim();
      this.oldNameValue = prezentationName;
      if (!prezentationName) {
        this.showRename = false;
        return false;
      }
      const payload = {
        ticketId: this.prezentation.ticketId,
        name: this.prezentation.name,
      };
      updateOPTicket(payload)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
      this.showRename = false;
      trackOvernightPrezentationsEvents.OPMyRequestsRename(this.currentUser, {
        [TD_VIEWTYPE]: TD_MOSAIC,
        [TD_PLACEMENT]: TD_THUMBNAIL_VIEW,
        ...this.getMatomoDetails(),
      });
      return true;
    },
    handleDotOptionClick(e, id) {
      if (e === 'Manage files and links') {
        this.$modal.show(
          ManageFiles,
          {
            id,
            request: this.prezentation,
            viewType: TD_MOSAIC,
          },
          {
            name: 'ManageFiles',
            classes: 'v-application',
            width: '1020px',
            height: 'auto',
            styles: {
              borderRadius: '15px',
              'max-height': '80%',
              'min-height': '300px',
              'overflow-y': 'auto',
            },
          },
        );
        trackOvernightPrezentationsEvents.OPMyRequestsManageFilesAndLink(
          this.currentUser,
          { ...this.getMatomoDetails() },
        );
      }
      if (e === 'Rename') {
        this.showRename = true;
      }
      if (e === 'Conversation history') {
        this.$router.push({
          path: `/home/my-overnight-prezentation/${id}?origin=overnight`,
        });
        trackOvernightPrezentationsEvents.OPMyRequestsConvertionHistory(
          this.currentUser,
          {
            ...this.getMatomoDetails(),
          },
        );
      }

      // change request
      if (e === 'Change request') {
        this.$router.push({
          path: `/home/my-overnight-prezentation/${
            this.prezentation?.ticketId
          }?origin=overnight&expanded=${true}&request=change`,
        });
        // this.$modal.show(
        //   RequestChangeModal,
        //   {
        //     opRequest: this.prezentation,
        //     reqOrigin: 'myRequests',
        //     viewType: TD_MOSAIC,
        //   },
        //   {
        //     name: 'RequestChangeModal',
        //     width: '650px',
        //     height: 'auto',
        //     styles: {
        //       'max-height': '75%',
        //       'max-width': '800px',
        //       overflow: 'auto',
        //     },
        //     clickToClose: true,
        //   },
        // );
        trackOvernightPrezentationsEvents.OPMyRequestsChangeRequest(
          this.currentUser,
          {
            [TD_INSTRUCTIONS]: this.prezentation?.feedback
              ? this.prezentation?.feedback
              : NA,
            ...this.getMatomoDetails(),
          },
        );
      }
    },
    getMatomoDetails() {
      return {
        [TD_AUD]: this.prezentation?.audienceEmail,
        [TD_PREZNAME]: this.prezentation?.name,
        [TD_PREZTYPE]: this.prezentation?.storyline,
        [TD_VIEWTYPE]: TD_LIST,
        [TD_PLACEMENT]: TD_THUMBNAIL_VIEW,
        [TD_PREZENTATION_CATEGORY]: this.prezentation?.status
          ? this.prezentation?.status
          : NA,
      };
    },
    goToConversationHistory() {
      trackOvernightPrezentationsEvents.OPMyRequestsManageRequestClick(
        this.currentUser,
        this.getMatomoDetails(),
      );
      this.$router.push({
        path: `/home/my-overnight-prezentation/${
          this.prezentation?.ticketId
        }?origin=overnight&expanded=${true}&request=manage`,
      });
    },
    async download() {
      console.log('download', this.currentUser.user.role.toLowerCase());
      await getFeatureUsage()
        .then((response) => {
          if (response?.data) {
            this.setLimitData(response.data);
          }
        })
        .catch((error) => console.log(error));
      if (
        this.currentUser.user.role.toLowerCase() !== 'trialuser' ||
        (this.currentUser.user.role.toLowerCase() === 'trialuser' &&
          !this.limitsInfoObj?.slide?.isLimitCrossed)
      ) {
        console.log('download-1');
        postFeatureUsage({
          feature: 'op-download',
          ticketId: this.prezentation.ticketId,
        })
          .then((response) => {
            console.log(response);
            if (response?.data) {
              this.setLimitData(response.data);
            }
          })
          .catch((error) => console.log(error));

        // this.downloading = true;

        this.setShowDownloadSnackbar(true);
        this.setDownloadLoaders({
          loaderId: this.prezentation.ticketId,
          downloading: true,
        });
        this.setDownloadItems({
          status: 'assembling',
          itemIndex: uuidv4(),
          originType: 'opDecks',
          logDownloadCallback: () => {
            this.logDownload(this.prezentation.ticketId);
            trackOvernightPrezentationsEvents.OPMyRequestsDownload(
              this.currentUser,
              {
                [TD_AUD]: this.prezentation?.audienceEmail,
                [TD_VIEWTYPE]: TD_MOSAIC,
                [TD_PREZNAME]: this.prezentation.name,
                [TD_PREZTYPE]: this.prezentation.storyline,
                [TD_PLACEMENT]: TD_THUMBNAIL_VIEW,
                [TD_PREZENTATION_CATEGORY]: this.prezentation.status,
              },
            );
          },
          downloadStatusCallback: () => {
            // this.downloading = false;
            this.setDownloadLoaders({
              loaderId: this.prezentation.ticketId,
              downloading: false,
            });
          },
          errorCallback: (err) => {
            // this.downloading = false;
            this.setDownloadLoaders({
              loaderId: this.prezentation.ticketId,
              downloading: false,
            });
            if (this.$route.name === 'overnightPrezentations') {
              this.$modal.show(
                ManageFiles,
                {
                  id: this.prezentation.ticketId,
                  request: this.prezentation,
                  viewType: TD_MOSAIC,
                },
                {
                  name: 'ManageFiles',
                  classes: 'v-application',
                  width: '1020px',
                  height: 'auto',
                  styles: {
                    borderRadius: '15px',
                    'max-height': '80%',
                    'min-height': '300px',
                    'overflow-y': 'auto',
                  },
                },
              );
            }
            console.log(err);
          },
          metaData: {
            ...this.prezentation,
            fileName: this.prezentation.name,
            limitsKey: 'prezentation',
            origin: 'prezentation',
          },
        });

        // const ticketIds = this.prezentation.ticketId;
        // await downloadFinalOPPPT(this.prezentation.ticketId)
        //   .then((resp) => {
        //     downloadFileWithCustomName(
        //       resp.attachmentUrl,
        //       resp.fileName,
        //       (val) => {
        //         if (!val) {
        //           this.logDownload(this.prezentation.ticketId);
        //           this.downloading = val;
        //         }
        //       },
        //       false,
        //     );
        //   })
        //   .catch((err) => {
        //     this.downloading = false;
        //     this.$modal.show(
        //       ManageFiles,
        //       {
        //         id: ticketIds,
        //         request: this.prezentation,
        //         viewType: TD_MOSAIC,
        //       },
        //       {
        //         name: 'ManageFiles',
        //         classes: 'v-application',
        //         width: '1020px',
        //         height: 'auto',
        //         styles: {
        //           borderRadius: '15px',
        //           'max-height': '80%',
        //           'min-height': '300px',
        //           'overflow-y': 'auto',
        //         },
        //       },
        //     );
        //     console.log(err);
        //   });
      } else {
        console.log('download-2');
        this.setUpgradePopup({ upgradePopup: true, popupType: 'op-slide' });
      }
    },
    logDownload(id) {
      logOPDownload(id)
        .then((resp) => {
          console.log(resp);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onRatingUpdate(payload) {
      this.$emit('onRatingUpdate', payload);
    },
    async getMergedUrlForSaveToGdrive() {
      try {
        let mergedUrl = '';
        const prezDetailsResponse = await downloadFinalOPPPT(
          this.prezentation.ticketId,
        );
        if (prezDetailsResponse) {
          const s = {
            slides: [],
          };
          prezDetailsResponse.slideData.slides.forEach((slide) => {
            s.slides.push({
              slideId: slide.filename,
              source: prezDetailsResponse.s3Bucket,
              isUploaded: false,
              isGenerated: false,
              isSynthesis: false,
              isRedesign: false,
              isComply: false,
              isOPUploaded: true,
            });
          });
          await callMergeSlidesApi(s)
            .then((res) => {
              const mergedslide = res.data;
              if (mergedslide.includes('.pptx')) {
                mergedUrl = mergedslide;
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
        return `${miscConfig.aws_prezentation_distribution}/${mergedUrl}`;
      } catch (err) {
        console.log('error', err);
        return '';
      }
    },
    getFilename() {
      return this.prezentation.name;
    },
    getDownloadFilename() {
      return `${this.prezentation.name}_op.pptx`;
    },
    getDownloadItem() {
      return {
        name: this.prezentation.name,
        categories: ['title'],
        prefs: {
          source: 'uploaded',
        },
        filename: this.prezentation.name,
      };
    },
  },
  watch: {},
  computed: {
    getMessageCount() {
      const getTicket = this.opUnreadMessageData?.find(
        (item) => item?.ticketId === this.prezentation?.ticketId,
      );
      if (getTicket) return getTicket?.unreadCount;
      return 0;
    },
    nameLimit() {
      return this.prezentation.name.length > 85;
    },
    currentLevel() {
      return this.statuses.find(
        (i) => i.value === this.prezentation.status.toLowerCase(),
      );
    },
    ...mapGetters('users', ['getOPAccessLevel', 'getOPLimitedBanner']),
    ...mapState('users', [
      'currentUser',
      'opUnreadMessageData',
      'limitsInfoObj',
    ]),
    ...mapGetters('commonDownloads', ['getDownloadLoaders']),
    isLocalDownloading() {
      const ldr = this.getDownloadLoaders.find(
        (ld) => ld.loaderId === this.prezentation.ticketId,
      );
      return ldr && ldr.downloading;
    },
    isLimitExceeded() {
      if (this.limitsInfoObj?.op?.access_level === 'full_access') {
        return false;
      }
      return this.limitsInfoObj?.op?.isLimitCrossed;
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.op-card-container {
  max-width: 500px;
  max-height: 436px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  background: white;
  cursor: pointer;
  margin: 8px;
  &:hover {
    box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.5);
  }

  .deck-thumbnail {
    padding: 10px;
  }

  .deck-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px 16px;
    gap: 4px;
    border-top: 0.5px solid #d8d8d8;

    .deck-title-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
      width: 100%;
      .nameError {
        padding-bottom: 5px;
        color: red;
      }
      ::v-deep
        .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
        > .v-input__control
        > .v-input__slot {
        box-shadow: none;
        border-bottom: 1px solid black;
      }
      ::v-deep .v-input {
        .v-input__control {
          min-height: 20px !important;
          .v-input__slot {
            .v-text-field__slot {
              input {
                font-size: 16px;
                padding: 0px !important;
                font-family: 'Lato', sans-serif;
                font-weight: 500;
                letter-spacing: 0.0125em;
                word-break: break-all;
              }
            }
          }
        }
      }
      .rename-input {
        padding: 10px;
      }
      .rename-button {
        align-items: center;
        padding: 6px 12px;
        gap: 8px;

        width: 44px;
        height: 32px;

        /* Color Styles (Old)/White */

        background: #ffffff;
        border-radius: 25px;
      }
      .prezentation-name {
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 50%;
        min-width: 50%;
      }
    }

    .deck-type {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.01em;
      color: #7b7b7b;
      text-align: left;
      width: 100%;

      > div:first-child {
        margin-bottom: 4px;
      }

      .storyline {
        max-width: 75%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .audAndAuthor {
        max-width: 75%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .deck-status {
      display: flex;
      gap: 4px;
      align-items: center;
      font-family: 'Lato';
      width: 100%;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      justify-content: space-between;
      line-height: 17px;
      letter-spacing: 0.01em;
      color: #000000;
      height: 36px;

      .deck-status-text {
        display: flex;
        align-items: center;
        gap: 4px;
      }

      .status-icon {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.otherActions {
  display: flex;
  align-items: center;
  gap: 16px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: #000000;

  &.disabled {
    color: rgba(0, 0, 0, 0.38);
    pointer-events: none;

    ::v-deep .v-icon {
      color: rgba(0, 0, 0, 0.38) !important;
      caret-color: rgba(0, 0, 0, 0.38) !important;
    }
  }
}
.notification {
  text-decoration: none;
  padding: 10px;
  position: relative;
  display: inline-block;
  ::v-deep .v-icon {
    color: #21a7e0 !important;
  }
}

.notification .badge {
  position: absolute;
  top: 5px;
  right: 3px;
  padding: 0px 5px;
  border-radius: 50%;
  background: #21a7e0;
  color: white;
  width: 17px;
  height: 17px;
  font-size: 11px;
  display: flex;
  align-items: center;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
