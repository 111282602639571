export const mainActions = [
  {
    id: 'rename',
    label: 'overnightPresentations.rename',
    tooltip: 'overnightPresentations.rename',
    icon: 'mdi-pencil-outline',
    isCustomIcon: false,
    dataPendoId: 'op-action-rename',
  },
  {
    id: 'manageRequest',
    labelText: 'Manage request',
    tooltipText: 'Manage request',
    icon: 'mdi-message-text-clock-outline',
    isCustomIcon: false,
    dataPendoId: 'op-action-manage-request',
  },
  {
    id: 'changeRequest',
    labelText: 'Change request',
    tooltipText: 'Change request',
    icon: 'change-request',
    isCustomIcon: true,
    dataPendoId: 'op-action-change-request',
  },
];
