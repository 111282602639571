<template>
  <div class="wrapper">
    <v-btn class="close-btn" icon @click="handleClose">
      <v-icon dark v-text="`mdi-close`" />
    </v-btn>
    <div class="main-wrapper" />
  </div>
</template>

<script>
export default {
  name: 'PreviewImageDialog',
  components: {},
  props: {},
  data() {
    return {};
  },
  methods: {
    handleClose() {
      this.$modal.hide('PreviewImageDialog');
    },
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: auto;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
}

.close-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 1;
}

.main-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  background-image: url('/assets/img/learn/novice-practice-5.jpg');
  background-size: cover;
}
</style>
