<template>
  <div class="how_to_share_container">
    <v-btn class="close-btn" icon @click="close">
      <v-icon dark v-text="`mdi-close`" />
    </v-btn>
    <h1 class="how-to-share-heading">
      {{ $t('overnightPresentations.howToShareAccess') }}
    </h1>
    <div class="how-to-share-sub-container">
      <div class="how-to-share-left">
        <h2 class="how-to-share-sub-heading">
          {{ $t('overnightPresentations.accessOnGoogleSlides') }}
        </h2>
        <v-img
          class="overview-img"
          src="/assets/img/overnight-prezentations/how-to-share2.svg"
        />
      </div>
      <div class="how-to-share-left">
        <h2 class="how-to-share-sub-heading">
          {{ $t('overnightPresentations.accessOnMicrosoft') }}
        </h2>
        <v-img
          class="overview-img"
          src="/assets/img/overnight-prezentations/how-to-share.svg"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'HowToShareAccess',
  methods: {
    close() {
      this.$modal.hide('HowToShareAccess');
    },
  },
};
</script>

<style lang="scss" scoped>
// @import '@/scss/variables.scss', '@/scss/app.scss';

.how_to_share_container {
  padding: 10px;
  background-color: white;

  .close-btn {
    position: absolute;
    top: 0px;
    right: 0px;
    margin: 10px 5px;
  }
  .how-to-share-heading {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height */

    color: #000000;
    margin-bottom: 10px;
  }
  .how-to-share-sub-container {
    display: flex;
    gap: 30px;
    .how-to-share-sub-heading {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 10px;

      color: #000000;
    }
  }
}
</style>
