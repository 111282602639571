<template>
  <div class="wrapper">
    <v-btn class="close-btn" icon @click="handleClose">
      <v-icon dark v-text="`mdi-close`" />
    </v-btn>
    <div class="content-wrapper">
      <div class="header">
        {{ $t('fingerprint.shareFingerprintWithColleagues') }}
      </div>
      <div class="content-panel">
        <ShareWithColleagues
          :entityID="entityID"
          :updateBtnText="updateBtnText"
          :shareData="shareData"
          @handleSave="handleSave"
          @changeUpdateBtnText="changeUpdateBtnText"
          :showfpTest="showfpTest"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ShareWithColleagues from '../../common/ShareWithColleagues.vue';

export default {
  name: 'ShareFingerPrintDetail',
  components: { ShareWithColleagues },
  props: {
    entityID: {
      type: String,
      required: true,
    },
    shareData: {
      type: Object,
    },
    share: {
      type: Function,
      default: () => {},
    },
    showfpTest: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      updateBtnText: 'common.update',
    };
  },
  methods: {
    handleClose() {
      this.$modal.hide('ShareFingerPrintDetail');
    },
    changeUpdateBtnText(text) {
      this.updateBtnText = text;
    },
    handleSave(shareEntities) {
      if (this.share) this.share(shareEntities, this.entityID);
      this.changeUpdateBtnText('navbarActions.shareFingerprintvue.updated');
      setTimeout(() => {
        this.$modal.hide('ShareFingerPrintDetail');
      }, 800);
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  align-items: flex-start;
  background: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  overflow-y: auto;
  position: relative;
  width: 100%;
}
.content-wrapper {
  height: 100%;
  max-height: 100vh;
  overflow-y: auto;

  position: relative;
  width: 100%;
  padding: 20px;

  .header {
    align-items: center;
    display: flex;
    font-size: 1.5em;
    font-style: normal;
    justify-content: flex-start;
    letter-spacing: -0.01em;
    line-height: 29px;
    margin-bottom: 10px;

    img {
      height: 40px;
      margin-right: 15px;
      margin-top: -6px;
    }
  }
  .content-panel {
    margin-bottom: 15px;
    .share-item {
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      margin-bottom: 8px;
    }
  }
}
.close-btn {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 1;
}
</style>
