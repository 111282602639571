var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"upload-container"},[_c('v-btn',{staticClass:"close-btn",attrs:{"disabled":_vm.uploadInprogress,"icon":""},on:{"click":_vm.close}},[_c('v-icon',{attrs:{"dark":""},domProps:{"textContent":_vm._s("mdi-close")}})],1),_c('h1',{staticClass:"manage-heading"},[_vm._v(" "+_vm._s(_vm.$t('overnightPresentations.uploadLocalFiles'))+" ")]),_c('div',{staticClass:"rating-cont"},[_c('v-data-table',{staticClass:"user-panel",attrs:{"headers":_vm.listHeaders,"items":_vm.files,"hide-default-footer":"","no-results-text":_vm.$t('build.step1.storylineFieldNoResult')},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.fileName ? item.fileName : item.name)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.fileName ? item.fileName : item.name))])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.progress === 100 && item.processingCompleted)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('overnightPresentations.fileUploaded'))+" ")]):(
            (item.progress !== 100 &&
              item.progress !== 0 &&
              item.progress !== 'failed') ||
            !item.processingCompleted
          )?_c('div',[_vm._v(" "+_vm._s(_vm.$t('overnightPresentations.processing'))+" ")]):(item.progress === 0 || item.sizeError || item.error)?_c('div',[_vm._v(" "+_vm._s(item.status)+" ")]):_vm._e()]}},{key:"item.progress",fn:function(ref){
          var item = ref.item;
return [(item.progress === 100 && item.processingCompleted)?_c('div',{staticClass:"success"},[_c('v-icon',{attrs:{"color":'#21a7e0'}},[_vm._v(_vm._s('mdi-check-circle'))])],1):(item.progress === 0 && !item.sizeError && !item.error)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('overnightPresentations.pending'))+" ")]):(item.progress === 'failed' || item.sizeError === true)?_c('div',{staticClass:"error"},[_c('v-icon',{attrs:{"color":'rgb(211 48 47)'}},[_vm._v(_vm._s('mdi-close-circle'))])],1):_c('v-progress-circular',{attrs:{"value":item.progress,"color":'#21a7e0',"background-color":"#f2f2f2","width":3,"size":20}})]}}],null,true)})],1),(
      _vm.files &&
      _vm.files.length > 0 &&
      _vm.files.filter(
        function (fl) { return fl.error === true || fl.sizeError === true || fl.error; }
      ).length > 0 &&
      !_vm.uploadInprogress
    )?_c('div',{staticClass:"errorTextContainer"},[_vm._v(" "+_vm._s(_vm.getErrorMessage(_vm.files))+" ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }