<template>
  <div class="rdt">
    <div class="header">
      <h4>{{ $t('synthesize.viewer.slides') }}</h4>
      <span class="count">{{ (!error && slides.length) || 1 }}</span>
    </div>
    <div class="rdt-thumbnail">
      <template v-if="error">
        <div class="rdt-thumbnail__item rdt-thumbnail--selected">
          <span class="rdt-thumbnail__index">1</span>
          <div class="rdt-thumbnail__image">
            <img :src="'/assets/img/generate/warning-line.png'" />
          </div>
        </div>
      </template>
      <template v-else-if="slides.length">
        <div
          v-for="(slide, index) in slides"
          :key="index"
          :class="[
            'rdt-thumbnail__item',
            index === slideIndex && 'rdt-thumbnail--selected',
          ]"
          @click="handleSelectionChange(index)"
        >
          <span class="rdt-thumbnail__index">{{ index + 1 }}</span>
          <img
            class="rdt-thumbnail__image"
            :src="slide.thumbnail_png.thumbnail"
            v-if="
              slide.index === redesignSlideIndex ? !loading : slide.completed
            "
          />
          <div v-else class="rdt-thumbnail__image">
            <clip-loader
              class="loading-spinner"
              :color="'#21a7e0'"
              :width="40"
              :height="40"
            />
          </div>
        </div>
      </template>
      <template v-else>
        <div class="rdt-thumbnail__item rdt-thumbnail--selected">
          <span class="rdt-thumbnail__index">1</span>
          <img class="rdt-thumbnail__image" :src="exampleLandscapeUrl" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';

export default {
  name: 'RedesignThumbnail',
  data() {
    return {};
  },
  props: {
    slides: {
      type: Array,
      default: () => [],
    },
    error: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
    },
    slideIndex: {
      type: Number,
      default: 0,
    },
    redesignSlideIndex: {
      type: Number,
      default: 0,
    },
  },
  components: { ClipLoader },

  methods: {
    handleSelectionChange(index) {
      this.$emit('slideIndexChange', index);
    },
  },

  watch: {},

  computed: {
    ...mapState('users', ['currentUser']),
    exampleLandscapeUrl() {
      return this.currentUser.user.visualPreference &&
        this.currentUser.user.visualPreference[0].toLowerCase() === 'vibrant'
        ? `/assets/img/redesign/vibrant-original-slide-placeholder.svg`
        : `/assets/img/redesign/minimal-original-slide-placeholder.svg`;
    },
  },
};
</script>

<style lang="scss" scoped>
.rdt {
  overflow: hidden;
  display: grid;
  grid-template-rows: 40px 1fr;
  gap: 8px;

  .header {
    display: grid;
    grid-auto-flow: column;
    justify-content: start;
    gap: 12px;
    align-items: center;
  }

  .count {
    width: 20px;
    height: 20px;
    display: block;
    background: #ffe1b7;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 600;
  }
}

.rdt-thumbnail {
  $self: &;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: auto;
  padding: 0 8px 4px 0;

  &__item {
    display: grid;
    grid-template-columns: 20px 1fr;
    cursor: pointer;
  }

  &__index {
    color: #343a3f;
    font-size: 12px;
  }

  &__image {
    box-shadow: 0px 2px 5px 1px rgba(64, 60, 67, 0.16);
    border-radius: 8px;
    border: 2px solid transparent;
    aspect-ratio: 16/9;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .loading-spinner {
      background-color: white;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &--selected {
    #{$self}__image {
      border: 2px solid #21a7e0;
    }
  }
}
</style>
