<template>
  <div class="detail-banner">
    <div class="context-wrapper">
      <span>
        Storyline:
        {{ prezentation.storyline || 'NA' }}
      </span>
      <span class="define-context-sub-header__divider"> | </span>
      <span>
        Audience:
        {{ prezentation.audienceFirstName }} {{ prezentation.audienceLastName }}
      </span>

      <span class="define-context-sub-header__divider"> | </span>
      <span>
        {{
          prezentation.completionDate
            ? `Request completed on: ${getDateFunction(
                prezentation.completionDate,
              )}`
            : `Request submitted on: ${getDateFunction(prezentation.createdAt)}`
        }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OPDetailBanner',
  props: {
    prezData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      prezentation: this.prezData,
    };
  },
  methods: {
    getDateFunction(timestamp) {
      const date = new Date(timestamp);
      const pstFormatter = new Intl.DateTimeFormat('en-US', {
        timeZone: 'America/Los_Angeles',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
      });
      const pstDateString = pstFormatter.format(date);
      return `${pstDateString} PST`;
    },
    formatAMPM(date) {
      let hours = date.getHours();
      let minutes = date.getMinutes();
      const ampm = hours >= 12 ? 'PM' : 'AM';
      hours %= 12;
      hours = hours >= 10 ? hours : `0${hours}`;
      hours = hours % 12 ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? `0${minutes}` : minutes;
      const strTime = `${hours}:${minutes} ${ampm} PST`;
      return strTime;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss', '@/scss/app.scss';

.detail-banner {
  display: flex;
  font-family: 'Lato' !important;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  gap: 10px;
  letter-spacing: normal;
  line-height: normal;
  margin-bottom: 10px;
  text-align: left;
  justify-content: space-between;

  .context-wrapper {
    width: 100%;
    display: flex;
    gap: 10px;
    white-space: nowrap;
    .define-context-sub-header__divider {
      color: $darker-gray;
    }

    .description {
      max-width: 600px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .prez-description {
      max-width: 36%;
    }
    .prez-description-best-practice {
      max-width: 80%;
    }
    .storyline {
      max-width: 20%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
</style>
