import createPersistedState from 'vuex-persistedstate';
import { getLoggedInUserToken } from '../../../../utils/api';
import { convertPdfToImage } from '../../../../utils/pdf-to-png';
import { getFileURLDownload } from '@/utils/calyrex-test';
import { getFileURL } from '../../../../utils/calyrex';

async function getThumbnail(slide, currentUser) {
  const filePath = slide.assetId;
  let thumbnail = '';
  if (
    filePath &&
    (filePath?.startsWith('/output/pptx/') ||
      filePath?.startsWith('/newImageReplaceOutput/output/'))
  ) {
    thumbnail = await getFileURLDownload(
      currentUser.user.id,
      filePath,
      currentUser.userIp,
    );

    if (filePath?.indexOf('.pdf') !== -1) {
      thumbnail = await convertPdfToImage(thumbnail);
    }
  } else if (filePath) {
    let type = 'Product';
    if (slide.isOPUploaded) {
      type = 'OP';
    } else if (
      slide.isRedesign ||
      slide.isGenerated ||
      slide.source === 'generated' ||
      slide.isUserUploaded ||
      slide.isSynthesis ||
      slide.isComply ||
      slide.isIOCUploaded ||
      (slide.isPremiumSlide && filePath.indexOf('private/') !== -1)
    ) {
      type = 'IOCUpload';
    }
    if (type === 'OP') {
      thumbnail = slide.thumbnail;
    } else {
      thumbnail = await getFileURL(
        currentUser.user.id,
        filePath,
        currentUser.userIp,
        type,
      );
    }
  }

  return thumbnail;
}

export async function processPrezentationForThumbnail(
  prezentation,
  currentUser,
) {
  let totalSlides = 0;
  await prezentation.sections.list.forEach(async (section, sectionIndex) => {
    await section.slides.forEach(async (slide, slideIndex) => {
      let { thumbnail } = slide;
      thumbnail = '';
      if (slide?.assetId) {
        totalSlides++;
        if (
          (prezentation.prezentationSource === 'User Uploaded' &&
            slide.assetId.indexOf('.pdf') !== -1 &&
            !slide.assetId?.startsWith('newImageReplaceOutput/') &&
            !slide.assetId?.startsWith('/newImageReplaceOutput/')) ||
          slide.assetId?.indexOf('/comply/') !== -1
        ) {
          const awsAssetsDistribution =
            process.env.VUE_APP_AWS_ASSETS_DISTRIBUTION;
          const token = await getLoggedInUserToken();
          thumbnail = await convertPdfToImage(
            `${awsAssetsDistribution}${
              slide.assetId?.startsWith('/') ? '' : '/'
            }${slide.assetId}?accesskey=${token}`,
          );
        } else {
          slide.assetId = slide.assetId?.startsWith('/')
            ? slide.assetId
            : `/${slide.assetId}`;
          thumbnail = await getThumbnail(slide, currentUser);
          // console.log('::::::', thumbnail);
          if (slide.assetId?.indexOf('.pdf') !== -1) {
            if (slide.assetId?.indexOf('/sanitize/') !== -1) {
              const awsAssetsDistribution =
                process.env.VUE_APP_AWS_ASSETS_DISTRIBUTION;
              const token = await getLoggedInUserToken();
              thumbnail = await convertPdfToImage(
                `${awsAssetsDistribution}${
                  slide.assetId?.startsWith('/') ? '' : '/'
                }${slide.assetId}?accesskey=${token}`,
              );
            } else {
              thumbnail = await convertPdfToImage(thumbnail);

              // console.log(thumbnail);
            }
          }
        }
      }
      prezentation.totalSlides = totalSlides;
      prezentation.sections.list[sectionIndex].slides[slideIndex].thumbnail =
        thumbnail;
    });
  });

  return prezentation;
}
const prezentationInitialState = () => ({
  prezentationOriginalData: null,
  prezentationData: null,
  selectedMainAction: {},
  selectedSlideAction: {},
  selectedQuickAction: null,
  selectedSlide: null,
  selectedSectionIndex: 0,
  selectedSlideIndex: 0,
  currentSlideBPList: [],
  isReplaceImgSelected: true,
  isFullScreenMode: false,
  inListViewMode: true,
  showBPTooltips: false,
  fromMosaicView: false,
  isActionsLoading: false,
  isRightPanelOpen: false,
  loadingIllustrations: false,
  loadingAnimations: false,
  showBestPracticesForBpDecks: true,
  bestPracticesEditedCounter: 0,
  bestPracticesDeletedCounter: 0,
  bestPracticesAddedCounter: 0,
  selectedAudienceData: null,
  slideActions: [],
  illustrationList: [],
  animationsData: {},
  complyDetails: null,
  selectedSuggestion: null,
  selectedTemplate: null,
  galleryCurrentTab: '',
  execSummary: {
    loading: false,
    meta: {},
    landscape: '',
    slide_path: '',
    siblings: [],
    relatedSlides: [],
    slideDetails: {},
    emotion: 'optimistic',
    format: null,
    tone: 'neutral',
    audience: null,
    placement: null,
    dataStyle: 'objective',
  },
  complyUploadId: null,
  slideImages: [],
  actionCompletedInfo: {},
  resetReplaceVisualTab: true,
});

export default {
  namespaced: true,
  state: {
    ...prezentationInitialState(),
  },
  getters: {
    getShowBestPracticesForBpDecks: (state) =>
      state.showBestPracticesForBpDecks,
    getSelectedSectionIndex: (state) => state.selectedSectionIndex,
    getSelectedSlideIndex: (state) => state.selectedSlideIndex,
    isEditor: (state) => state.prezentationData?.permission_level === 3,
    isViewAccess: (state) => state.prezentationData?.permission_level <= 2,
    isRestrictedAccess: (state) =>
      state.prezentationData?.permission_level === 0,
    isPartialAccess: (state) => (currentUser) =>
      (state.prezentationData?.permission_level === 3 ||
        state.prezentationData.ownerID === currentUser.user.id) &&
      (state.prezentationData?.prezentationSource === 'Redesign' ||
        state.prezentationData?.prezentationSource === 'Comply' ||
        state.prezentationData?.prezentationSource === 'User Uploaded' ||
        state.prezentationData?.prezentationSource === 'IOCUpload'),
    isOpPrezentation: (state) =>
      state.prezentationData?.prezentationType === 'OP',
    isBestPractices: (state) =>
      state.prezentationData?.prezentationType === 'Best Practices' &&
      state.prezentationData?.prezentationSource === 'IOCUpload',
    showBestPracticeToggle: (state) =>
      state.prezentationData?.prezentationSource === 'IOCUpload' ||
      state.prezentationData?.prezentationSource === 'User Uploaded',
    isPrezentSourceBestPractice: (state) =>
      state.prezentationData?.prezentationType === 'Best Practices' &&
      state.prezentationData?.source?.toLowerCase() === 'prezent' &&
      state.prezentationData?.prezentationSource === 'IOCUpload',
    isUserUploadedPrezentation: (state) =>
      state.prezentationData?.prezentationSource === 'User Uploaded',
    showShareOption: (state) =>
      state.prezentationData?.prezentationType === 'Best Practices' &&
      state.prezentationData?.source?.toLowerCase() === 'prezent',
    isIOCUpload: (state) =>
      state.prezentationData?.prezentationSource === 'IOCUpload',
    isGenerated: (state) =>
      state.prezentationData?.prezentationSource === 'Generated',
    isRedesigned: (state) =>
      state.prezentationData?.prezentationSource === 'Redesign',
    isComply: (state) =>
      state.prezentationData?.prezentationSource === 'Comply',
    currentItemIsEditable: (state) =>
      !!(
        state.prezentationData.permission_level !== null ||
        state.prezentationData.permission_level !== undefined ||
        state.prezentationData.permission_level === 3 ||
        (state.prezentationData.prezentationSource === 'User Uploaded' &&
          state.prezentationData.permission_level === 3)
      ),
    isPremiumFeature: (state) =>
      state.prezentationData?.prezentationSource === 'Comply' ||
      state.prezentationData?.prezentationSource === 'Redesign' ||
      state.prezentationData?.prezentationSource === 'Generated',

    isTemplateRemoved: (state) => (themes) => {
      if (
        !themes?.length ||
        !state.prezentationData ||
        !state.prezentationData.theme
      ) {
        return false;
      }
      return (
        themes.filter((theme) => theme.code === state.prezentationData?.theme)
          .length <= 0
      );
    },
    getCurrentSlideBPList: (state) => state.currentSlideBPList,
    isPrezBPListFilled: (state) => {
      const slideToolTips =
        state?.prezentationData?.bestPractices?.slideToolTips;
      if (slideToolTips?.length) {
        for (const item of slideToolTips) {
          if (item?.tips?.length) {
            return true;
          }
        }
      }
      return false;
    },
    getComplyUploadId: (state) => state.complyUploadId || null,
    getSlideImages: (state) => state.slideImages || [],
  },

  mutations: {
    UPDATE_ACTION_COMPLETED_INFO(state, data) {
      state.actionCompletedInfo = {
        ...data,
      };
    },
    SET_SHOW_BEST_PRACTICES_FOR_BP_DECKS(state, flag) {
      state.showBestPracticesForBpDecks = flag;
    },
    RESET_REPLACE_VISUAL_TAB(state, resetReplaceVisualTab) {
      state.resetReplaceVisualTab = resetReplaceVisualTab;
    },
    async SET_PREZENTATIONS_DATA(state, data) {
      let prezentation = data?.prezentation;

      if (data?.prezentation?.sections) {
        state.selectedSlide = {};
        prezentation = await processPrezentationForThumbnail(
          JSON.parse(JSON.stringify(data.prezentation)),
          data.currentUser,
        );
        let count = 0;
        const interval = setInterval(() => {
          const slideData =
            prezentation.sections.list[state.selectedSectionIndex || 0].slides[
              state.selectedSlideIndex || 0
            ];
          if (slideData?.thumbnail) {
            clearInterval(interval);
            state.selectedSlide = { ...slideData };
          }
          if (count === 30 && !state.selectedSlide) {
            clearInterval(interval);
          }
          count++;
        }, 500);
      }
      if (!data.noOriginalUpdate) {
        state.prezentationOriginalData = prezentation
          ? JSON.parse(JSON.stringify(prezentation))
          : prezentation;
      }
      state.prezentationData = prezentation;
    },
    UPDATE_PREZENTATIONS_DATA(state, prezentation) {
      state.prezentationData = JSON.parse(JSON.stringify(prezentation));

      const slideData =
        prezentation.sections.list[state.selectedSectionIndex || 0].slides[
          state.selectedSlideIndex || 0
        ];
      state.selectedSlide = { ...slideData };
    },
    UPDATE_PARTIAL_PREZENTATIONS_DATA(state, prezentation) {
      state.prezentationData = { ...state.prezentationData, ...prezentation };
    },
    SET_SELECTED_MAIN_ACTION(state, data) {
      state.selectedMainAction = JSON.parse(JSON.stringify(data || {}));
    },
    SET_SELECTED_SLIDE_ACTION(state, data) {
      state.selectedSlideAction = JSON.parse(JSON.stringify(data || {}));
    },
    SET_SELECTED_QUICK_ACTION(state, data) {
      state.selectedQuickAction = JSON.parse(JSON.stringify(data || {}));
    },
    async SET_SELECTED_SLIDE_DATA(state, dataObj) {
      const { data, sectionIndex, slideIndex } = dataObj;
      state.selectedSlide = JSON.parse(JSON.stringify({ ...data }));
      state.selectedSectionIndex = sectionIndex;
      state.selectedSlideIndex = slideIndex;
    },
    SET_BEST_PRACTICES_LIST(state, data) {
      state.currentSlideBPList = [...data];
    },
    SET_IS_REPLACE_IMG_SELECTED(state, data) {
      state.isReplaceImgSelected = data;
    },
    SET_FULL_SCREEN_MODE(state, data) {
      state.isFullScreenMode = data;
    },
    SET_IN_LIST_VIEW_MODE(state, data) {
      state.inListViewMode = data;
    },
    SET_FROM_MOSAIC_VIEW(state, data) {
      state.fromMosaicView = data;
    },
    SET_BP_TOGGLE_STATE(state, data) {
      state.showBPTooltips = data;
    },
    RESET_STATE(state) {
      Object.assign(state, prezentationInitialState());
    },
    SET_IS_ACTIONS_LOADING(state, data) {
      state.isActionsLoading = data;
    },
    SET_IS_RIGHT_PANEL_OPEN(state, data) {
      state.isRightPanelOpen = data;
    },
    SET_LOADING_ILLUSTRATIONS(state, data) {
      state.loadingIllustrations = data.isLoading;
      state.illustrationList = data.illustrationList;
    },
    SET_LOADING_ANIMATIONS(state, data) {
      state.loadingAnimations = data.isLoading;
      state.animationsData = data.animationsData;
    },
    SET_SELECTED_AUDIENCE_DATA(state, data) {
      state.selectedAudienceData = data;
    },
    SET_SLIDE_ACTIONS(state, data) {
      state.slideActions = [...data];
    },
    SET_BP_EDITED_COUNTER(state, data) {
      state.bestPracticesEditedCounter = data;
    },
    SET_BP_DELETED_COUNTER(state, data) {
      state.bestPracticesDeletedCounter = data;
    },
    SET_BP_ADDED_COUNTER(state, data) {
      state.bestPracticesAddedCounter = data;
    },
    SET_COMPLY_DETAILS(state, data) {
      state.complyDetails = JSON.parse(JSON.stringify(data || {}));
    },
    SET_SELECTED_SUGGESTION(state, data) {
      state.selectedSuggestion = JSON.parse(JSON.stringify(data || {}));
    },
    SET_SELECTED_TEMPLATE(state, data) {
      state.selectedTemplate = data;
    },
    RESET_COMPLY_DATA(state) {
      state.animationsData = {};
      state.complyDetails = null;
      state.selectedSuggestion = null;
      state.selectedTemplate = null;
    },
    SET_GALLERY_TAB(state, data) {
      state.galleryCurrentTab = data;
    },
    SET_EXECUTIVE_SUMMERY(state, data) {
      state.execSummary = data;
    },
    RESET_EXECUTIVE_SUMMERY(state) {
      state.execSummary = prezentationInitialState().execSummary;
    },
    SET_COMPLY_UPLOAD_ID(state, newId) {
      state.complyUploadId = newId;
    },
    SET_COMPLY_SLIDE_IMAGES(state, newSlideImages) {
      state.slideImages = newSlideImages;
    },
  },
  actions: {
    updateActionCompletedInfo({ commit }, data) {
      commit('UPDATE_ACTION_COMPLETED_INFO', data);
    },
    setShowBestPracticesForBpDecks({ commit }, flag) {
      commit('SET_SHOW_BEST_PRACTICES_FOR_BP_DECKS', flag);
    },
    setPrezentationData({ commit }, data) {
      commit('SET_PREZENTATIONS_DATA', data);
    },
    updatePrezentationData({ commit }, data) {
      commit('UPDATE_PREZENTATIONS_DATA', data);
    },
    updatePartialPrezentationData({ commit }, data) {
      commit('UPDATE_PARTIAL_PREZENTATIONS_DATA', data);
    },
    setSelectedMainAction({ commit }, data) {
      commit('SET_SELECTED_SLIDE_ACTION', {});
      commit('SET_SELECTED_MAIN_ACTION', data);
    },
    setSelectedSlideAction({ commit }, data) {
      commit('SET_SELECTED_SLIDE_ACTION', data);
    },
    setSelectedQuickAction({ commit }, data) {
      commit('SET_SELECTED_QUICK_ACTION', data);
    },
    setSelectedSlideData({ commit }, data) {
      commit('SET_SELECTED_SLIDE_DATA', data);
    },
    setCurrentSlideBPList({ commit }, data) {
      commit('SET_BEST_PRACTICES_LIST', data);
    },
    setIsReplaceImgSelected({ commit }, data) {
      commit('SET_IS_REPLACE_IMG_SELECTED', data);
    },
    setFullScreenMode({ commit }, data) {
      commit('SET_FULL_SCREEN_MODE', data);
    },
    setInListViewMode({ commit }, data) {
      commit('SET_IN_LIST_VIEW_MODE', data);
    },
    setBPToggleState({ commit }, data) {
      commit('SET_BP_TOGGLE_STATE', data);
    },
    setFromMosaicView({ commit }, data) {
      commit('SET_FROM_MOSAIC_VIEW', data);
    },
    resetReplaceVisualTabState({ commit }, data) {
      commit('RESET_REPLACE_VISUAL_TAB', data);
    },
    resetPrezState({ commit }) {
      commit('RESET_STATE', null);
    },
    setIsActionsLoading({ commit }, data) {
      commit('SET_IS_ACTIONS_LOADING', data);
    },
    setIsRightPanelOpen({ commit }, data) {
      commit('SET_IS_RIGHT_PANEL_OPEN', data);
    },
    setLoadingIllustrations({ commit }, data) {
      commit('SET_LOADING_ILLUSTRATIONS', data);
    },
    setLoadingAnimations({ commit }, data) {
      commit('SET_LOADING_ANIMATIONS', data);
    },
    setSelectedAudienceData({ commit }, data) {
      commit('SET_SELECTED_AUDIENCE_DATA', data);
    },
    setAddSlideActions({ commit }, data) {
      commit('SET_SLIDE_ACTIONS', data);
    },
    setBpEditedCounter({ commit }, data) {
      commit('SET_BP_EDITED_COUNTER', data);
    },
    setBpDeletedCounter({ commit }, data) {
      commit('SET_BP_DELETED_COUNTER', data);
    },
    setBpAddedCounter({ commit }, data) {
      commit('SET_BP_ADDED_COUNTER', data);
    },
    setComplyDetails({ commit }, data) {
      commit('SET_COMPLY_DETAILS', data);
    },
    setSelectedSuggestion({ commit }, data) {
      commit('SET_SELECTED_SUGGESTION', data);
    },
    setSelectTemplate({ commit }, data) {
      commit('SET_SELECTED_TEMPLATE', data);
    },
    resetComplyData({ commit }, data) {
      commit('RESET_COMPLY_DATA', data);
    },
    setGalleryTab({ commit }, data) {
      commit('SET_GALLERY_TAB', data);
    },
    setExecSummary({ commit }, data) {
      commit('SET_EXECUTIVE_SUMMERY', data);
    },
    resetExecSummary({ commit }, data) {
      commit('RESET_EXECUTIVE_SUMMERY', data);
    },
    updateComplyUploadId({ commit }, newId) {
      commit('SET_COMPLY_UPLOAD_ID', newId);
    },
    updateComplySlideImages({ commit }, newSlideImages) {
      commit('SET_COMPLY_SLIDE_IMAGES', newSlideImages);
    },
  },
  persist: createPersistedState({
    key: 'vuex_prezentationDetails',
    paths: [],
  }),
};
