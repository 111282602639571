<template>
  <div class="quick-access-actions">
    <v-menu
      v-model="feedbackPopup"
      :close-on-content-click="false"
      :nudge-width="300"
      offset-y
      left
      content-class="op-rating-popover"
    >
      <!-- <template v-slot:activator="{ attrs }">
        <div class="op-rating-btn-container" v-bind="attrs">
          <div>
            <v-hover v-slot="{ hover }">
              <v-btn
                icon
                @click.stop="
                  form.isLiked === null ? handleRatingClick('like') : () => {}
                "
                :class="{
                  bounce: animateLike,
                  'rating-disabled': form.isLiked !== null,
                }"
              >
                <v-tooltip bottom v-model="likeTooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      :color="
                        (hover && form.isLiked === null) ||
                        form.isLiked === true ||
                        animateLike
                          ? '#21a7e0'
                          : '#000'
                      "
                      size="24"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{
                        form.isLiked === true || animateLike
                          ? 'mdi-thumb-up'
                          : 'mdi-thumb-up-outline'
                      }}
                    </v-icon>
                  </template>
                  <span>{{ $t('generate.likeThisPresentation') }}</span>
                </v-tooltip>
              </v-btn>
            </v-hover>
          </div>
          <div>
            <v-hover v-slot="{ hover }">
              <v-btn
                icon
                :class="{ 'rating-disabled': form.isLiked !== null }"
                @click.stop="
                  form.isLiked === null
                    ? handleRatingClick('dislike')
                    : () => {}
                "
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      :color="
                        (hover && form.isLiked === null) ||
                        form.isLiked === false
                          ? '#21a7e0'
                          : '#000'
                      "
                      size="24"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{
                        form.isLiked === false
                          ? 'mdi-thumb-down'
                          : 'mdi-thumb-down-outline'
                      }}
                    </v-icon>
                  </template>
                  <span>{{ $t('generate.dontLikeThisPresentation') }}</span>
                </v-tooltip>
              </v-btn>
            </v-hover>
          </div>
        </div>
      </template> -->
      <v-card>
        <v-btn
          elevation="0"
          icon
          class="op-rating-popover__close"
          @click="closeFeedback"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <div v-if="feedbackSuccess" class="feedback-success">
          <img src="/assets/img/generate/feedback_thanks.png" />
          <span>{{ $t('generate.thankForFeedback') }}</span>
        </div>
        <template v-else>
          <div class="op-rating-popover__content">
            <v-form ref="form" lazy-validation>
              <div>
                <label class="form__label">
                  {{ $t('generate.feedbackIsInvaluable') }}
                </label>
                <v-textarea
                  v-model="form.feedback"
                  :label="$t('generate.typeYourFeedback')"
                  solo
                  :rows="4"
                  class="form__textarea"
                  required
                />
              </div>
            </v-form>
          </div>

          <v-card-actions class="form__action">
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              style="text-transform: none !important"
              rounded
              class="submit-data-btn"
              width="100"
              height="48"
              @click="submitRatings"
            >
              {{ $t('common.submit') }}
            </v-btn>
          </v-card-actions>
        </template>
      </v-card>
    </v-menu>
    <v-tooltip
      bottom
      max-width="250"
      content-class="tooltip-content"
      fixed
      open-delay="500"
      close-delay="300"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          :class="`view-mode list ${viewMode === 'list' ? 'active' : ''}`"
          v-bind="attrs"
          v-on="on"
          @click="handleViewMode('list')"
        >
          <img class="normal" src="/assets/img/list_view_normal.svg" alt />
          <img class="active" src="/assets/img/list_view_solid.svg" alt />
        </div>
      </template>
      <span>{{ $t('build.step3.listViewText') }}</span>
    </v-tooltip>
    <v-tooltip
      bottom
      max-width="250"
      content-class="tooltip-content"
      fixed
      open-delay="500"
      close-delay="300"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          :class="`view-mode mosaic ${viewMode === 'mosaic' ? 'active' : ''}`"
          v-bind="attrs"
          v-on="on"
          @click="handleViewMode('mosaic')"
        >
          <img class="normal" src="/assets/img/mosaic_view_normal.svg" alt />
          <img class="active" src="/assets/img/mosaic_view_solid.svg" alt />
        </div>
      </template>
      <span>{{ $t('build.step3.mosaicViewText') }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import { updateRating } from '../../../../../utils/api-helper';

export default {
  name: 'QuickAccessActions',
  props: {
    viewMode: {
      type: String,
      default: 'list',
    },
    opRequest: {
      type: Object,
      default: () => {},
    },
    updatePrezentation: {
      type: Function,
      default: () => {},
    },
  },
  mounted() {
    this.form.isLiked = this.opRequest.isLiked;
  },
  data() {
    return {
      feedbackPopup: false,
      feedbackSuccess: false,
      form: {
        isLiked: null,
        feedback: '',
      },
      animateLike: false,
      likeTooltip: false,
    };
  },
  methods: {
    handleViewMode(val) {
      console.log(val);
      this.$emit('onViewChange', val);
    },
    closeFeedback() {
      this.feedbackPopup = false;
    },
    async submitRatings() {
      this.feedbackSuccess = true;
      const payload = {
        ticketId: this.opRequest.ticketId,
        ...this.form,
      };
      await updateRating(payload);
      // this.loading = false;
      this.close();
      this.$refs.form.reset();
    },
    close() {
      this.updatePrezentation();
    },
    handleRatingClick(type) {
      this.form.feedback = '';
      switch (type) {
        case 'like':
          if (this.form.isLiked) {
            this.form.isLiked = null;
          } else {
            this.form.isLiked = true;
          }
          break;
        case 'dislike':
          if (this.form.isLiked || this.form.isLiked === null) {
            this.form.isLiked = false;
          } else {
            this.form.isLiked = null;
          }
          break;
        default:
          break;
      }
      if (this.form.isLiked !== null) {
        this.$nextTick(() => {
          this.feedbackSuccess = false;
          setTimeout(() => {
            this.feedbackPopup = true;
          }, 300);
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.quick-access-actions {
  display: flex;
  align-items: center;
  gap: 15px;
  position: absolute;
  right: 15px;
  background-color: white;
}
.view-mode {
  display: flex;
  align-items: center;
  justify-content: space-between;

  cursor: pointer;
  .active {
    display: none;
  }
  .normal {
    display: block;
  }
  &.active {
    .active {
      display: block;
    }
    .normal {
      display: none;
    }
  }
}
.op-rating-btn-container {
  display: flex;
  align-items: center;
  gap: 15px;
  // margin-right: 12px;

  .rating-disabled {
    opacity: 0.8;
    cursor: default;
  }
}
.op-rating-popover {
  border-radius: 16px;

  .feedback-success {
    height: 210px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    span {
      color: #000;
      font-family: Lato;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
  &__content {
    padding: 22px;
    padding-bottom: 0;
    padding-top: 42px;
  }
  &__close {
    position: absolute;
    right: 4px;
    top: 4px;
  }
}
.form {
  &__label {
    font-weight: 600;
    margin-bottom: 6px;
    font-size: 16px;
    display: block;
  }
  &__action {
    padding: 22px;
    padding-top: 0;
    .submit-data-btn {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: normal;
      text-transform: none !important;
      margin-right: 20px;
    }
  }
  &__textarea {
    border-radius: 8px;
  }
}
</style>
