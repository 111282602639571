var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"module-wrapper"},[_c('v-expansion-panels',{attrs:{"multiple":"","value":_vm.activeModule,"flat":""}},_vm._l((_vm.modules),function(courseModule,index){return _c('v-expansion-panel',{key:index,staticClass:"module-section"},[_c('v-expansion-panel-header',[_c('div',{staticClass:"module-section__header"},[_c('v-progress-circular',{attrs:{"size":45,"value":courseModule.status,"color":"#21a7e0"}},[_c('span',{staticClass:"module-section__header--status"},[_vm._v(" "+_vm._s(courseModule.status)+"% ")])]),_c('p',{staticClass:"module-section__header--title"},[_vm._v(" "+_vm._s(courseModule.display_name)+" ")])],1)]),_vm._l((courseModule.assets),function(assetContent,assetIndex){return _c('v-expansion-panel-content',{key:assetIndex,staticClass:"module-content-section",class:[
          { first: assetIndex === 0 },
          { last: assetIndex === courseModule.assets.length - 1 } ]},[_c('v-timeline',{attrs:{"dense":""}},[_c('v-timeline-item',{class:assetContent.status < 100 ? 'v-timeline-item-custom' : '',attrs:{"icon":_vm.getAssetIcon(assetContent),"icon-color":"#fff"}},[_c('div',{staticClass:"assests-section"},[_c('div',{staticClass:"assests-title-section"},[_c('NewFeatureBadge',{attrs:{"module-name":"learncoursedetail","feature-id":assetContent.id,"offsetX":'-5',"offsetY":'20',"inline":false}},[_c('div',{staticClass:"assests-section__title"},[_vm._v(" "+_vm._s(assetContent.display_name)+" ")])]),_c('div',{staticClass:"assests-section__description"},[_vm._v(" "+_vm._s(assetContent.description)+" ")])],1),_c('div',{staticClass:"asset-video-duration-section"},[(assetContent.asset_type.toLowerCase() === 'video')?_c('a',{staticClass:"asset-video-duration-section__video",on:{"click":function($event){return _vm.getAssetInfo(Object.assign({}, assetContent,
                      {learnModuleName: courseModule.display_name,
                      courseCompletionPercentage: courseModule.status}))}}},[_c('v-icon',{attrs:{"color":"#21a7e0"}},[_vm._v("mdi-television-play")]),_vm._v(" "+_vm._s(_vm.$t('learn.course.video'))+" ")],1):_vm._e(),(assetContent.asset_type.toLowerCase() === 'exercise')?_c('a',{staticClass:"asset-video-duration-section__video",on:{"click":function($event){return _vm.goToPractice(
                      assetContent.id,
                      courseModule.id,
                      assetContent.display_name
                    )}}},[_c('v-icon',{attrs:{"color":"#21a7e0"}},[_vm._v("mdi-book-open-variant")]),_vm._v(" "+_vm._s(_vm.$t('learn.course.practice'))+" ")],1):_vm._e(),(assetContent.asset_type.toLowerCase() === 'quiz')?_c('a',{class:("asset-video-duration-section__video " + (_vm.quizEnabled(courseModule.assets) ? '' : 'disabled')),attrs:{"disabled":_vm.quizEnabled(courseModule.assets)},on:{"click":function($event){return _vm.goToQuiz(
                      assetContent.id,
                      courseModule.assets,
                      courseModule.display_name
                    )}}},[_c('v-icon',{attrs:{"color":_vm.quizEnabled(courseModule.assets, courseModule.assets)
                        ? '#21a7e0'
                        : '#b6b6b6',"disabled":!_vm.quizEnabled(courseModule.assets)}},[_vm._v("mdi-playlist-check")]),_vm._v(" "+_vm._s(_vm.$t('learn.course.quiz'))+" ")],1):_vm._e(),_c('span',{staticClass:"asset-video-duration-section__duration"},[_c('v-icon',[_vm._v(" mdi-clock-time-eight-outline ")]),_vm._v(" "+_vm._s(_vm._f("secondsToMinutes")(assetContent.duration_seconds))+"m ")],1)])])])],1)],1)})],2)}),1),(_vm.openDialogue)?_c('VideoPlayer',{attrs:{"is-open":_vm.openDialogue,"video-detail":_vm.videoInfo},on:{"likeupdated":_vm.handleLikeFn,"dislikeupdated":_vm.handleDislikeFn,"viewupdated":_vm.handleViewUpdateFn,"modalclosed":_vm.handleVideoClosedFn}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }