<template>
  <div class="anonymize">
    <div class="heading">
      {{
        $t(
          origin === 'slides'
            ? 'anonymize.anonymizeSingleSlide'
            : 'anonymize.anonymizeSlide',
        )
      }}
      <div class="sub">{{ $t('build.step3.poweredByZenSence') }}</div>
    </div>
    <template v-if="origin === 'slides' && isSlidesLimitCrosses">
      <div
        class="suggest-block__summary-text"
        :style="{
          marginTop: '80px',
        }"
      >
        <img src="/assets/img/compliance-check-failed.svg" alt />
        <br /><br />
        <div class="error-container">
          <div class="error-text">
            Anonymization is optimized for decks with up to 100 slides. Please
            consider removing some slides from your presentation and try again.
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="sub-heading">{{ $t('anonymize.anonymizeSubText') }}</div>
      <div>
        <v-radio-group class="ml-2" v-model="anonymizeType">
          <v-radio
            label="Partial Anonymization"
            value="partial_sanitize"
            :name--auto="`partial-anonymization${
              anonymizeType === 'partial_sanitize' ? '--active' : ''
            }`"
          ></v-radio>
          <div class="anonymize-option-context">
            <div class="desc">
              {{ $t('anonymize.partialAnonymizationDescription') }}
            </div>
            <v-img src="/assets/img/anonymize/partial_anonymize_slide.png" />
          </div>
          <v-radio
            label="Full Anonymization"
            value="full_sanitize"
            :name--auto="`full-anonymization${
              anonymizeType === 'full_sanitize' ? '--active' : ''
            }`"
          ></v-radio>
          <div class="anonymize-option-context">
            <div class="desc">
              {{ $t('anonymize.fullAnonymizationDescription') }}
            </div>
            <v-img src="/assets/img/anonymize/full_anonymize_slide.png" />
          </div>
        </v-radio-group>
      </div>
      <div class="action-wrapper" v-if="origin !== 'slides'">
        <v-btn
          rounded
          class="save-prez-btn"
          @click="handleAnonymizeDeck"
          :loading="isDeckUpdate"
          :disabled="isDeckUpdate || isSlideUpdate"
        >
          {{ $t('anonymize.anonymizeDeck') }}
        </v-btn>
        <v-btn
          rounded
          outlined
          color="primary"
          class="anonymizeCurrentSlide"
          @click="handleAnonymizeCurrentSlide"
          :loading="isSlideUpdate"
          :disabled="isSlideUpdate || isDeckUpdate"
        >
          {{ $t('anonymize.anonymizeCurrentSlide') }}
        </v-btn>
      </div>
      <div class="action-wrapper single-action" v-else>
        <v-btn
          rounded
          color="primary"
          class="anonymizeCurrentSlide"
          @click="handleAnonymizeSlide"
          :loading="isSlideUpdate"
          :disabled="isSlideUpdate"
        >
          {{ $t('anonymize.anonymizeSingleSlide') }}
        </v-btn>
      </div>
    </template>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { mapActions, mapState, mapGetters } from 'vuex';
import {
  anonymizeSlides,
  getAnonymizeSlidesStatus,
  postUpdateActions,
} from '../../../../utils/api-helper';
import { getSlideSource } from '../../../../utils/merge-slide-helper';
import { trackPrezentationEvents } from '../../../common/Analytics/PrezentationEvents';
import { trackBuildEvents } from '../../../common/Analytics/BuildEvents';
import {
  TD_AUD,
  TD_PREZNAME,
  TD_PREZTYPE,
  TD_TEMPLATE,
  TD_NUMBEROFSLIDES,
} from '../../../common/Analytics/MatomoTrackingDataHelper';
import { getSlideType } from '../../ComplianceChecker/utils/comply-helper.utils';
import { slideLimit } from '../config/actionConfig';

export default {
  props: {
    updatePrezentation: {
      type: Function,
      default: () => {},
    },

    updateSlide: {
      type: Function,
      default: () => {},
    },
    origin: {
      type: String,
      default: '',
    },
    getLandscape: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      anonymizeType: 'partial_sanitize',
      isUpdating: false,
      isDeckUpdate: false,
      isSlideUpdate: false,
      callbackId: '',
      illustrationList: [
        {
          text: 'Scanning for sensitive information',
          image: '/assets/img/Scanning-for-sensitive-information.svg',
          timeout: 0,
        },
        {
          text: 'Detecting confidential data',
          image: '/assets/img/Detecting-confidential-data.svg',
          timeout: 1500,
        },
        {
          text: 'Preparing to replace content',
          image: '/assets/img/Preparing-to-replace-content.svg',
          timeout: 1500,
        },
        {
          text: 'Anonymizing sensitive content',
          image: '/assets/img/Anonymizing-sensitive-content.svg',
          timeout: 1500,
        },
      ],
    };
  },
  computed: {
    ...mapState('prezentationDetails', [
      'prezentationData',
      'selectedSlide',
      'selectedSlideIndex',
      'selectedSectionIndex',
    ]),
    ...mapState('users', ['currentUser']),
    ...mapGetters('slidesStore', [
      'getActiveSlide',
      'getReplaceStackData',
      'getAnonymizedData',
    ]),

    isSlidesLimitCrosses() {
      return (
        this.origin !== 'slides' &&
        this.prezentationData.totalSlides > slideLimit.anonymize
      );
    },
  },
  methods: {
    ...mapActions('prezentationDetails', ['setLoadingIllustrations']),
    ...mapActions('slidesStore', [
      'setActiveSlide',
      'setAnonymizedData',
      'setIsSlideDetailsDirty',
      'setFlowActive',
    ]),
    handleAnonymizeCurrentSlide() {
      const otherData = {
        [TD_AUD]: this.prezentationData.audienceID,
        [TD_PREZNAME]: this.prezentationData.name,
        [TD_PREZTYPE]: this.prezentationData.type,
        [TD_TEMPLATE]: this.prezentationData.theme,
      };
      if (this.origin === 'build') {
        trackBuildEvents.buildAnonymizeSlideClick(this.currentUser, otherData);
      } else {
        trackPrezentationEvents.prezentationsAnonymizeSlide(
          this.currentUser,
          otherData,
        );
      }

      this.isUpdating = true;
      this.isSlideUpdate = true;

      this.setLoadingIllustrations({
        isLoading: true,
        illustrationList: this.illustrationList,
      });
      const callbackId = uuidv4();
      const { assetId, filename } = this.selectedSlide;

      const s3Bucket = getSlideSource(this.selectedSlide);

      this.prezentationData.s3dirpath =
        this.prezentationData?.s3dirpath ||
        `prezentation/${this.prezentationData?.id}/`;
      const s3dirpath = this.prezentationData.s3dirpath.endsWith('/')
        ? this.prezentationData.s3dirpath
        : `${this.prezentationData.s3dirpath}/`;
      this.prezentationData.action = {
        operation: 'Anonymize',
        level: 'Slide',
        additionalData: {
          sanitizeLevel:
            this.anonymizeType === 'partial_sanitize' ? 'Partial' : 'Full',
        },
      };
      const payload = {
        callback_id: callbackId,
        report_id: uuidv4(),
        workflow_context: {
          s3dirpath,
          slides_to_process: [
            {
              assetId,
              filename,
              s3_bucket: s3Bucket,
              index: 1,
            },
          ],
        },
        workflow_name: 'sanitize',
      };
      anonymizeSlides(payload)
        .then((res) => {
          console.log(res);

          this.getAnonymise(callbackId);
        })
        .catch((error) => {
          console.log(error);
          this.isUpdating = false;
          this.setLoadingIllustrations({
            isLoading: false,
            illustrationList: [],
          });
        });
    },
    handleAnonymizeDeck() {
      let countOfSlides = 0;
      this.prezentationData.sections.list.forEach((section) => {
        countOfSlides += section.slides.length;
      });
      const otherData = {
        [TD_AUD]: this.prezentationData.audienceID,
        [TD_PREZNAME]: this.prezentationData.name,
        [TD_PREZTYPE]: this.prezentationData.type,
        [TD_TEMPLATE]: this.prezentationData.theme,
        [TD_NUMBEROFSLIDES]:
          countOfSlides ||
          this.prezentationData.noofslides ||
          this.prezentationData.number_of_slides,
      };
      if (this.origin === 'build') {
        trackBuildEvents.buildAnonymizeDeckClick(this.currentUser, otherData);
      } else {
        trackPrezentationEvents.prezentationsAnonymizeDeck(
          this.currentUser,
          otherData,
        );
      }

      this.isUpdating = true;
      this.isDeckUpdate = true;
      this.setLoadingIllustrations({
        isLoading: true,
        illustrationList: this.illustrationList,
      });
      const callbackId = uuidv4();
      const { sections } = this.prezentationData;
      this.prezentationData.s3dirpath =
        this.prezentationData?.s3dirpath ||
        `prezentation/${this.prezentationData?.id}/`;
      const s3dirpath = this.prezentationData.s3dirpath.endsWith('/')
        ? this.prezentationData.s3dirpath
        : `${this.prezentationData.s3dirpath}/`;
      this.prezentationData.action = {
        operation: 'Anonymize',
        level: 'Deck',
        additionalData: {
          sanitizeLevel:
            this.anonymizeType === 'partial_sanitize' ? 'Partial' : 'Full',
        },
      };
      const slidesList = [];

      let index = 0;
      sections.list.forEach((section) => {
        section.slides.forEach((slide) => {
          const { assetId, filename } = slide;
          const s3Bucket = getSlideSource(slide);

          slidesList.push({
            assetId,
            filename,
            s3_bucket: s3Bucket,
            index,
          });
          index++;
        });
      });
      const payload = {
        callback_id: callbackId,
        report_id: uuidv4(),
        workflow_context: {
          s3dirpath,
          slides_to_process: [...slidesList],
        },
        workflow_name: 'sanitize',
      };
      anonymizeSlides(payload)
        .then((res) => {
          console.log(res);

          this.getAnonymise(callbackId);
        })
        .catch((error) => {
          console.log(error);

          this.setLoadingIllustrations({
            isLoading: false,
            illustrationList: [],
          });
          this.isUpdating = false;
        });
    },
    async handleAnonymizeSlide() {
      this.isUpdating = true;
      this.isSlideUpdate = true;

      this.setLoadingIllustrations({
        isLoading: true,
        illustrationList: this.illustrationList,
      });

      const callbackId = uuidv4();
      const { assetId, landscape, thumbnail, poster, filename } =
        this.getActiveSlide;
      const currentSlideData = { ...this.getActiveSlide };

      currentSlideData.source = this.getActiveSlide.prefs.source;

      const s3Bucket = getSlideSource(currentSlideData);
      const s3dirpath = `protected/premium-feature/anonymize/${this.getActiveSlide?.unique_id}/`;
      let assetIdObj = '';

      if (assetId) {
        assetIdObj = assetId;
      } else if (landscape) {
        assetIdObj = landscape;
      } else if (thumbnail) {
        assetIdObj = thumbnail;
      } else if (poster) {
        assetIdObj = poster;
      }
      const payload = {
        callback_id: callbackId,
        report_id: uuidv4(),
        workflow_context: {
          s3dirpath,
          is_acl_required: false,
          slides_to_process: [
            {
              assetId: assetIdObj,
              filename,
              s3_bucket: s3Bucket,
              index: 1,
            },
          ],
        },
        workflow_name: 'sanitize',
      };

      await anonymizeSlides(payload)
        .then((res) => {
          console.log(res);
          this.getAnonymise(callbackId);
        })
        .catch((error) => {
          console.log(error);
          this.isUpdating = false;
          this.setLoadingIllustrations({
            isLoading: false,
            illustrationList: [],
          });
        });
    },

    async getAnonymise(callbackId) {
      const checkStatus = await setInterval(async () => {
        await getAnonymizeSlidesStatus(callbackId)
          .then(async (res) => {
            if (res.workflow_status === 'success') {
              const anonymizedData = res?.outputs?.[this.anonymizeType]?.data;
              clearInterval(checkStatus);

              const callBack = () => {
                this.isSlideUpdate = false;
                this.isDeckUpdate = false;
                this.setLoadingIllustrations({
                  isLoading: false,
                  illustrationList: [],
                });
              };
              if (this.isSlideUpdate) {
                let otherKeys = {};
                if (this.anonymizeType === 'full_sanitize') {
                  otherKeys = {
                    description: '',
                    tags: [],
                  };
                }

                const currentSlideData =
                  this.origin !== 'slides'
                    ? this.selectedSlide
                    : this.getActiveSlide;

                const slideData = {
                  ...currentSlideData,
                  isPremiumSlide: true,
                  ...getSlideType('isAnonymized'),
                  filename: anonymizedData[0].pptx.s3_path,
                  ...otherKeys,
                };

                if (slideData.isReplaced) {
                  delete slideData.isReplaced;
                }
                if (slideData.isOPUploaded) {
                  delete slideData.isOPUploaded;
                }

                postUpdateActions({
                  slideID:
                    this.origin !== 'slides'
                      ? currentSlideData.uniqueID
                      : currentSlideData.unique_id,
                  operation: 'Anonymize',
                  noofslides: 1,
                  isBPLSlide: false,
                  additionalData: {
                    sanitizedLevel:
                      this.anonymizeType === 'partial_sanitize'
                        ? 'Partial'
                        : 'Full',
                  },
                });

                slideData.sanitizedLevel =
                  this.anonymizeType === 'partial_sanitize'
                    ? 'Partial'
                    : 'Full';

                if (this.origin !== 'slides') {
                  slideData.assetId = anonymizedData[0].pdf.s3_path;
                  const presentation = JSON.parse(
                    JSON.stringify(this.prezentationData),
                  );
                  presentation.sections.list[this.selectedSectionIndex].slides[
                    this.selectedSlideIndex
                  ] = slideData;

                  this.updatePrezentation(presentation, callBack, true);
                } else {
                  const { assetId } = this.getActiveSlide;

                  if (assetId) {
                    slideData.assetId = anonymizedData[0].pdf.s3_path;
                  } else {
                    slideData.landscape = anonymizedData[0].pdf.s3_path;
                    slideData.thumbnail = anonymizedData[0].pdf.s3_path;
                    slideData.poster = anonymizedData[0].pdf.s3_path;
                  }
                  slideData.savedSlidePath = anonymizedData[0].pptx.s3_path;

                  this.setIsSlideDetailsDirty(true);
                  this.setFlowActive('anonymizeSlide');
                  this.setAnonymizedData(slideData);
                  await this.getLandscape(slideData);
                  callBack();
                }
              } else if (this.isDeckUpdate) {
                const presentation = JSON.parse(
                  JSON.stringify(this.prezentationData),
                );
                let index = 0;
                this.prezentationData.sections.list.forEach(
                  (section, sectionIndex) => {
                    section.slides.forEach((slide, slideIndex) => {
                      let otherKeys = {};
                      if (this.anonymizeType === 'full_sanitize') {
                        otherKeys = {
                          description: '',
                          tags: [],
                        };
                      }
                      const slideData = {
                        ...slide,
                        isPremiumSlide: true,
                        ...getSlideType('isAnonymized'),
                        assetId: anonymizedData[index].pdf.s3_path,
                        filename: anonymizedData[index].pptx.s3_path,
                        ...otherKeys,
                      };
                      if (slideData.isReplaced) {
                        delete slideData.isReplaced;
                      }
                      if (slideData.isOPUploaded) {
                        delete slideData.isOPUploaded;
                      }
                      presentation.sections.list[sectionIndex].slides[
                        slideIndex
                      ] = slideData;

                      index++;
                    });
                  },
                );

                this.updatePrezentation(presentation, callBack, true);
              }
              this.isUpdating = false;
            }
          })
          .catch((error) => {
            console.log(error);
            clearInterval(checkStatus);

            this.isSlideUpdate = false;
            this.isDeckUpdate = false;
            this.setLoadingIllustrations({
              isLoading: false,
              illustrationList: [],
            });
            this.isUpdating = false;
          });
      }, 2000);
    },
  },
};
</script>

<style lang="scss" scoped>
.anonymize {
  height: calc(100vh - 140px);
  overflow: scroll;

  .heading {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 15px;
    .sub {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.02em;
      color: #697077;
      margin-top: 4px;
    }
  }

  .sub-heading {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #000000;
    margin-bottom: 16px;
  }

  .anonymize-option-context {
    padding: 10px 30px;

    .desc {
      padding-bottom: 10px;
    }
  }

  ::v-deep .v-item--active {
    label {
      color: #21a7e0;
    }
  }

  .action-wrapper {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: left;
    bottom: 10px;
    left: 10px;
    background-color: white;
    position: absolute;
    z-index: 2;
    transition: width 0.3s, visibility 0.3s, opacity 0.3s ease-out;

    .save-prez-btn {
      margin-right: 10px;
      color: $white-default;
      background-color: $zen-blue-default;
      height: 40px;
      font-size: 18px;
      font-weight: bold;
      text-transform: none;
      letter-spacing: normal;
    }

    .anonymizeCurrentSlide {
      height: 40px;
      font-size: 18px;
      font-weight: bold;
      text-transform: none;
      letter-spacing: normal;
      background-color: white;
    }
  }

  .single-action {
    // right: 408px !important;
    left: unset !important;
  }

  .error-container {
    text-align: center;

    .error-text {
      margin: 20px auto;
      font-size: 14px;
    }
  }
}
</style>
