import { render, staticRenderFns } from "./AddSlide.vue?vue&type=template&id=7cd16c21&scoped=true&"
import script from "./AddSlide.vue?vue&type=script&lang=js&"
export * from "./AddSlide.vue?vue&type=script&lang=js&"
import style0 from "./AddSlide.vue?vue&type=style&index=0&id=7cd16c21&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7cd16c21",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VTab,VTabItem,VTabs,VTabsItems,VTooltip})
