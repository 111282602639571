<template>
  <div class="fingerprint-modal">
    <div class="spinnerContainer" v-if="!loading">
      <v-progress-circular indeterminate :size="50" color="#21a7e0" />
    </div>
    <div v-else>
      <div
        class="title-wrapper"
        :style="{
          marginBottom:
            origin === 'synthesis' || origin === 'redesign' ? '8px' : '25px',
        }"
      >
        <h2>{{ $t('overnightPresentations.audience1') }}</h2>
        <!-- <div class="sub">{{ $t('build.step3.poweredByZenSence') }}</div> -->
      </div>
      <div class="audience-select-title">
        {{ $t('build.step3.tailorSlidesFor') }}
      </div>
      <AudienceSelectorTypesense
        class="audience-selector"
        v-model="filterTailor"
        :placeholder="$t('build.step3.tailorSlidesForPlaceholder')"
        :maxWidth="300"
        @change:user="handleAudienceChange($event)"
        @add="handleAddAudience($event)"
        origin=""
        :extraFields="['question2', 'question3', 'question6']"
      />
      <div class="fingerprint-data-title">
        {{ $t('generate.yourAudienceAtAGlance') }}
      </div>
      <div
        class="fingerprint-data"
        v-if="!isPublicLimited || isCurrUserCreatedPrivAudience"
      >
        <img
          :src="fingerprintData.logo"
          alt="Fingerprint-Logo"
          class="logo-fingerprint"
        />
        <h3 class="fingerprint-heading">
          {{
            $t('generate.fingerprintLabel', {
              audienceLabel: isMeAsAudience
                ? ''
                : $t('overnightPresentations.audience'),
              fingerprint: getFingerprint,
            })
          }}
        </h3>
        <div
          class="pref-item1"
          v-for="(item, index) in fingerprintData.preferences"
          :key="index"
        >
          <div class="pref-item">
            <img :src="item.logo" width="93px" />
            <div class="pref-cont">
              <div class="pref-heading">{{ $t(item.title) }}</div>
              <div class="pref-sub-heading">
                {{ item.preference }}
              </div>
              <div class="pref-data">{{ item.oneLine }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="fingerprint-data" v-else>
        <img
          src="/assets/img/lock-icon@100px.svg"
          alt="Fingerprint-Logo"
          class="logo-fingerprint"
        />
        <h3 class="locked-fingerprint-heading">
          {{
            $t('generate.fingerprintLabel2', {
              audienceLabel: isMeAsAudience
                ? ''
                : $t('overnightPresentations.audience'),
            })
          }}
          <img alt class="locked-inline" src="/assets/img/lock-icon@24px.svg" />
        </h3>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import { graphqlOperation } from 'aws-amplify';
import { fpTypes } from '@/mock/fingerprint';
import AudienceSelectorTypesense from '../../../../common/AudienceSelectorTypesense.vue';
import { AddNewAudienceModalProps } from '../../../AddNewAudience/AddNewAudienceModalProps';
import AddCollegueDetail from '../../../NewPrezentation/AddCollegueDetail.vue';
import ReminderDialog from '../../../MyPrezentation/ReminderDialog.vue';
import { capitalizeFirstLetter } from '../../../../../utils/common';
import { getChoice } from '@/utils/common';
import { GraphQLService } from '../../../../../services/GraphQLService';
import { performSearch } from '@/graphql/queries';
import { AnalyticsHandler } from '../../../../common/Analytics/AnalyticsHandler';
import { getFileURL } from '../../../../../utils/calyrex';
import {
  getPrezData,
  BUILD,
  TD_SLIDE_SOURCE,
  TD_PREZENTATION_CATEGORY,
  NA,
  getBuildPrezentationData,
} from '../../../../common/Analytics/MatomoTrackingDataHelper';
import { trackPrezentationEvents } from '../../../../common/Analytics/PrezentationEvents';
import { trackBuildEvents } from '../../../../common/Analytics/BuildEvents';

export default {
  name: 'AudiencePanel',
  props: {
    audience: {
      type: Object,
      default: () => null,
    },
    origin: {
      type: String,
      default: 'my_prezentation',
    },
    updatePrezentation: {
      type: Function,
      default: () => {},
    },
    fingerprintResponse: Boolean,
  },
  data() {
    return {
      loading: true,
      searchTerm: '',
      filterTailor: null,
      currentAudienceID: '',
      illustrationList: [
        {
          timeout: 0,
          text: this.$t('build.step1.waitingText2'),
          image: '/assets/img/apply-audience-preference-2.svg',
        },
        {
          timeout: 1500,
          text: this.$t('build.step1.waitingText3'),
          image: '/assets/img/fine-tuning-favorites-2.svg',
        },
      ],
    };
  },
  components: { AudienceSelectorTypesense },
  methods: {
    ...mapActions('prezentationDetails', [
      'setSelectedAudienceData',
      'setLoadingIllustrations',
    ]),

    handleClose() {
      this.$emit('handleClose');
    },
    getSlideSource(slideData) {
      if (!slideData) {
        return NA;
      }
      if (slideData.source !== undefined) {
        return slideData.source;
      }
      if (slideData?.prefs?.source) {
        return slideData?.prefs?.source;
      }
      return 'Source not available';
    },

    openRemindModal(currentAudience) {
      let data = {};
      let metaDataForTracking = {};
      if (this.origin.toLowerCase() === 'build') {
        data = getBuildPrezentationData(this.prezentationData);
        metaDataForTracking = {
          prezentationTrackingInfo: {
            ...data,
            [TD_SLIDE_SOURCE]: this.getSlideSource(this.selectedSlide),
            [TD_PREZENTATION_CATEGORY]: this.prezentationData?.prezentationType,
          },
          stepCount: 'STEP_3',
        };
      } else {
        data = getPrezData(this.prezentationData);
        metaDataForTracking = { prezentationTrackingInfo: data };
      }
      this.$forceUpdate();
      this.$modal.show(
        ReminderDialog,
        {
          currentAudience,
          isUser: currentAudience.type === 'user',
          origin:
            this.origin.toLowerCase() === 'build' ? 'build' : 'my_prezentation', // my_prezentation
          metaDataForTracking,
        },
        {
          name: 'ReminderDialog',
          width: '649px',
          height: '80%',
        },
      );
    },
    handleAddAudience(value) {
      if (value && !value.fingerPrint) {
        this.openRemindModal(value);

        if (this.origin.toLowerCase() === BUILD) {
          const buildData = getBuildPrezentationData(this.prezentationData);
          trackBuildEvents.buildTailorSlidesGreyAudienceClickOnStepThree(
            this.currentUser,
            buildData,
          );
        } else {
          const otherData = getPrezData(this.prezentationData);
          trackPrezentationEvents.prezentationTailorSlidesForGreyAuidence(
            this.currentUser,
            otherData,
          );
        }
      } else {
        this.$modal.show(
          AddCollegueDetail,
          {
            origin: this.origin,
          },
          AddNewAudienceModalProps,
        );
      }
    },
    async handlePopulate(presentation, fingerPrint) {
      try {
        const { sections } = presentation;
        for (let i = 0; i < sections.list.length; i++) {
          const section = sections.list[i];
          const apis = [];
          const slides = [];
          for (let j = 0; j < section.slides.length; j++) {
            const item = section.slides[j];
            const payload = {};
            payload.category = item.category || '';
            payload.username = this.currentUser.user.cognitoID; // "pz_rath_admin"),
            payload.limit = 6;
            payload.skip = 0;
            payload.choice = await getChoice(fingerPrint);
            payload.audience_id = this.currentAudienceID.email;
            payload.audience_type = this.currentAudienceID.type;
            slides.push({ ...item });
            apis.push(this.getRecommendedSlides(payload));
          }
          await Promise.all(apis).then((res) => {
            for (let index = 0; index < res.length; index++) {
              const s = slides[index];
              let slideData = {};
              if (
                s.isUserUploaded ||
                s.isUploaded ||
                s.isRedesign ||
                s.isGenerated ||
                s.source === 'generated' ||
                s.source === 'uploaded' ||
                s?.prefs?.is_know === 'yes'
              ) {
                slideData = {
                  ...s,
                };
              } else {
                const slidesList = res[index];
                let obj = {};
                if (slidesList && slidesList.length > 0) {
                  obj =
                    slidesList[
                      this.getRandom(0, Math.min(slidesList.length, 5))
                    ];
                }
                slideData = {
                  ...s,
                  uniqueID: obj.asset?.unique_id,
                  assetId: obj.asset?.landscape,
                  filename: obj.asset?.filename,
                };
              }
              sections.list[i].slides[index] = slideData;
            }
          });
        }

        const prezData = {
          ...presentation,
          sections,
          theme: this.currentTheme?.code ? this.currentTheme.code : 'default',
        };

        this.updatePrezentation(prezData, () => {
          this.setLoadingIllustrations({
            isLoading: false,
            illustrationList: [],
          });
        });

        if (this.origin.toLowerCase() === BUILD) {
          const buildData = getBuildPrezentationData(this.prezentationData);
          trackBuildEvents.buildTailorSlides(this.currentUser, buildData);
        } else {
          const otherData = getPrezData(prezData);
          trackPrezentationEvents.prezentationsTailorSlides(
            this.currentUser,
            otherData,
          );
        }
      } catch (e) {
        console.warn(e);
      }
    },
    getRandom(min, max) {
      // min and max included
      return Math.floor(Math.random() * (max - min + 1) + min);
    },
    skipEmphasis(category = '') {
      const emphasisAllowed = [
        'divider',
        'title',
        'congratulations',
        'thank_you',
        'call_to_action',
      ];
      return emphasisAllowed.indexOf(category?.toLowerCase()) <= -1;
    },
    getRecommendedSlides(payload) {
      return new Promise((resolve, reject) => {
        const notprefs = {};
        const prefs = {};
        notprefs.construct = [];
        prefs.construct = [];
        if (this.skipEmphasis(payload.category)) {
          notprefs.construct.push('emphasis');
        } else {
          prefs.construct.push('emphasis');
          notprefs.construct.push('category');
        }

        const andprefs = {};
        andprefs.theme = this.currentTheme.code
          ? this.currentTheme.code
          : 'default';
        const params = {
          username: payload.username,
          query: payload.query ? payload.query : '',
          category: payload.category,
          orderby: 'CHOICE_DESC',
          choice: payload.choice,
          morprefs: payload.morprefs ? payload.morprefs : [],
          andprefs,
          prefs,
          notprefs,
          company: this.currentUser.company.name.toLowerCase(),
          limit: payload.limit,
          skip: payload.skip >= 0 ? payload.skip : 0,
          audience_id: payload.audience_id,
          audience_type: payload.audience_type,
        };
        const serialized = JSON.stringify(params);
        AnalyticsHandler.performOutlineSlideSearch(
          this.currentUser,
          payload.query || payload.category,
        );
        AnalyticsHandler.performSlideSearch(
          this.currentUser,
          payload.query || payload.category,
        );

        GraphQLService.requestWithToken(
          graphqlOperation(performSearch, {
            parameters: serialized,
          }),
        )
          .then(async (res) => {
            const result = JSON.parse(res.data.performSearch);
            const assets = [];
            if (result.statusCode === 200) {
              const slides = result.body.payloads;
              if (!slides || slides.length < 1) {
                resolve([]);
              } else {
                // eslint-disable-next-line no-restricted-syntax
                for (const slide of slides) {
                  // eslint-disable-next-line no-await-in-loop
                  await (async () => {
                    const obj = {};
                    obj.asset = slide;
                    obj.asset.name = obj.asset.title;
                    obj.asset.file = obj.asset.filename;
                    obj.asset.pptFile = obj.asset.file;
                    obj.isTrending = false;
                    obj.isFavorite = !!this.currentUserFavorites.find(
                      (element) => element.assetID === slide.unique_id,
                    );
                    const filePath = obj.asset.landscape;
                    obj.thumbnail = await getFileURL(
                      this.currentUser.user.id,
                      filePath,
                      this.currentUser.userIp,
                    );
                    assets.push(obj);
                  })();
                }
                resolve(assets);
              }
            } else {
              reject(result);
            }
          })
          .catch((err) => reject(err));
      });
    },
    handleAudienceChange(val) {
      this.setLoadingIllustrations({
        isLoading: true,
        illustrationList: this.illustrationList,
      });
      let event = {
        id: val.id,
        num_id: val.num_id,
        type: val.audienceType,
        ownerID: val.ownerID,
        email: val.email,
      };
      if (this.fingerprintResponse) {
        event = {
          ...event,
          has_image: 1,
          color_type: val.question2 === 'leftImage' ? 'high' : 'low',
          has_cartoon: val.question3 === 'leftImage' ? 0 : 1,
          has_tables: val.question6 === 'leftImage' ? 1 : 0,
          has_graphs: val.question6 === 'rightImage' ? 1 : 0,
        };
      }
      const prezentationData = {
        ...this.prezentationData,
        id: this.prezentationData.id,
        audienceID: val.email,
        audienceOwnerID: val.ownerID ? val.ownerID : null,
        fingerprintUsed: val.fingerPrint,
      };
      this.setSelectedAudienceData(val);
      this.currentAudienceID = val;
      this.handlePopulate(prezentationData, this.currentAudienceID.fingerPrint);
      this.$emit('change', {
        type: 'audience_change',
        val: event,
      });
    },
  },
  mounted() {
    this.filterTailor = this.selectedAudienceData;
  },
  computed: {
    ...mapState('users', [
      'currentUser',
      'currentTheme',
      'currentUserFavorites',
    ]),
    ...mapState('prezentationDetails', [
      'prezentationData',
      'selectedAudienceData',
      'selectedSlide',
    ]),
    isPublicLimited() {
      return this.filterTailor?.isPublicLimited;
    },
    isCurrUserCreatedPrivAudience() {
      return (
        this.filterTailor?.isPrivate &&
        this.filterTailor?.ownerID === this.currentUser?.user?.id
      );
    },
    fingerprintData() {
      const fpData = fpTypes.find(
        (fprint) =>
          fprint.title === this.filterTailor?.fingerPrint?.toLowerCase(),
      );
      if (!fpData) {
        return fpTypes?.[0];
      }
      return fpData;
    },
    isMeAsAudience() {
      return this.currentUser?.user?.id === this.filterTailor?.id;
    },
    getFingerprint() {
      const fingerprint = this.isMeAsAudience
        ? this.currentUser.user?.fingerPrint
        : this.filterTailor?.fingerPrint;
      return fingerprint ? capitalizeFirstLetter(fingerprint) : '';
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/variables.scss', '@/scss/app.scss';
.fingerprint-modal {
  background: $white-default;
  padding-right: 5px;
  min-width: 382px;
  min-height: 600px;
  overflow: scroll;
  .spinnerContainer {
    display: flex;
    justify-content: center;
    margin: 200px auto;
  }
  .audience-select-title {
    color: #000000;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
  }
  .title-wrapper {
    font-weight: 700;
    line-height: 24px;
    // margin-bottom: 25px;
    // position: relative;

    .sub {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.02em;
      color: #697077;
      margin-top: 4px;
    }
  }
  .fingerprint-data-title {
    display: flex;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    justify-content: space-between;
    align-items: center;
    margin: 30px 0 20px 0;
  }
  .fingerprint-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    .fingerprint-heading {
      width: 354px;
      height: 19px;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      text-align: center;

      color: #000000;
    }
    .locked-fingerprint-heading {
      height: 19px;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      text-align: center;

      color: #000000;
      display: flex;
      gap: 5px;
    }
    .logo-fingerprint {
      height: 72px;
      width: 72px;
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1),
        -1px -1px 4px rgba(0, 0, 0, 0.1);
      border-radius: 50%;
      margin: 20px;
    }
    .pref-item1 {
      padding: 0px;
      height: 80px;
      margin: 20px 0;
      width: 90%;
      .pref-item {
        align-items: center;
        display: flex;
        justify-content: space-between;
        .pref-heading {
          font-family: 'Lato';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          /* identical to box height */

          letter-spacing: 0.01em;

          /* Color Styles (Old)/Grey 01 */

          color: #7b7b7b;
        }
        .pref-sub-heading {
          font-family: 'Lato';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;

          /* Color Styles (Old)/Dark */

          color: #212121;
        }
        .pref-data {
          font-family: 'Lato';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          /* identical to box height */

          letter-spacing: 0.01em;

          /* Color Styles (Old)/Dark */

          color: #212121;
        }
        .pref-cont {
          width: 60%;
        }
      }
    }
  }
}
</style>
