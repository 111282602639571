<template>
  <div class="tab-content fingerprint-content">
    <v-tabs vertical color="primary" v-model="currentTab">
      <v-tab
        color="primary"
        class="fingerprint-tab my_fingerprint-tab__auto"
        active-class="fingerprint-tab-active"
        :value="myFingerprint"
        :href="'#' + myFingerprint"
        @click="setTab(myFingerprint)"
        >{{ $t('profile.fingerprintTabContent.myFingerprint') }}</v-tab
      >

      <v-tab
        color="primary"
        class="fingerprint-tab other-fingerprints-tab__auto"
        active-class="fingerprint-tab-active"
        :href="'#' + createdFingerprints"
        @click="setTab(createdFingerprints)"
        data-pendo-id="created-fingerprints"
      >
        <NewFeatureBadge
          module-name="fingerprint"
          :feature-id="'other_fingerprints_button'"
          :offsetX="'20'"
          :offsetY="'0'"
          >{{
            $t('profile.fingerprintTabContent.createdFingerprints')
          }}</NewFeatureBadge
        ></v-tab
      >
      <v-tab
        color="primary"
        class="fingerprint-tab access-requetss-fingerprints-tab__auto"
        active-class="fingerprint-tab-active"
        :href="'#' + accessRequests"
        @click="setTab(accessRequests)"
      >
        <NewFeatureBadge
          module-name="fingerprint"
          :feature-id="'access_requests_fingerprints_button'"
          :offsetX="'20'"
          :offsetY="'0'"
          >{{
            $t('profile.fingerprintTabContent.accessRequests')
          }}</NewFeatureBadge
        >
      </v-tab>
      <v-tab
        color="primary"
        class="fingerprint-tab external-fingerprints-tab__auto"
        active-class="fingerprint-tab-active"
        :href="'#' + externalAudiences"
        @click="setTab(externalAudiences)"
        v-if="isEmailOutsideCompanyEnabled"
      >
        <NewFeatureBadge
          module-name="fingerprint"
          :feature-id="'external_fingerprints_button'"
          :offsetX="'20'"
          :offsetY="'0'"
          >{{
            $t('profile.fingerprintTabContent.externalAudiences')
          }}</NewFeatureBadge
        >
      </v-tab>
      <v-tabs-items v-model="currentTab">
        <v-tab-item :value="myFingerprint">
          <FingerPrint
            :key="currentUser.user.profileImageUrl"
            :fingerprint="currentUserFingerPrint"
            :audience="currentUser.user"
            :personalPrefs="personalPrefs"
            :allPrefs="selections"
            :shareable="true"
            prefEditable
            :currentCompany="company"
            :isCurrentUser="true"
            :origin="fp_origin"
            @handleQuestion="onHandleQuestion"
            @handleCreateOwn="handleCreateOwn"
            @handleSavePreference="handleSavePreference"
          />
          <div
            class="btn-retake"
            @click="gotoTest"
            data-pendo-id="fingerprint-retake"
          >
            <v-icon>mdi-autorenew</v-icon>
            {{ $t('fingerprint.retakeFingerprintTest') }}
          </div>
        </v-tab-item>
        <v-tab-item :value="createdFingerprints">
          <OtherFingerPrints
        /></v-tab-item>
        <v-tab-item :value="accessRequests">
          <AccessRequests :requestId="accessRequestId"
        /></v-tab-item>
        <v-tab-item
          :value="externalAudiences"
          v-if="isEmailOutsideCompanyEnabled"
        >
          <ExternalFingerPrints
        /></v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { trackProfileEvents } from '../../common/Analytics/ProfileEvents';
import FingerPrint from '../FingerPrints/FingerPrint.vue';
import OtherFingerPrints from '../FingerPrints/OtherFingerPrints.vue';
import AccessRequests from '../FingerPrints/AccessRequests.vue';
import ExternalFingerPrints from '../FingerPrints/ExternalFingerPrints.vue';
import NewFeatureBadge from '../../common/NewFeatureBadge.vue';
import {
  TD_AUDIENCE_ORIGIN_ZENTREDS,
  TD_FP_MY,
  TD_FP_PROFILE,
} from '../../common/Analytics/MatomoTrackingDataHelper';

const MY_FINGERPRINT = 'my_fingerprint';
const CREATED_FINGERPRINTS = 'created_fingerprints';
const ACCESS_REQUESTS = 'access_requests';
const EXTERNAL_AUDIENCES = 'external_audiences';
export default {
  name: 'FingerprintTabContent',
  props: {
    handleQuestion: {
      type: Function,
    },

    handleCreateOwn: {
      type: Function,
      default: () => {},
    },
    selections: {
      type: Array,
      default: () => [],
    },

    personalPrefs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      currentTab: MY_FINGERPRINT,
      myFingerprint: MY_FINGERPRINT,
      createdFingerprints: CREATED_FINGERPRINTS,
      accessRequests: ACCESS_REQUESTS,
      externalAudiences: EXTERNAL_AUDIENCES,
      validSubTabs: [
        MY_FINGERPRINT,
        CREATED_FINGERPRINTS,
        ACCESS_REQUESTS,
        EXTERNAL_AUDIENCES,
      ],
      accessRequestId: null,
      fp_origin: TD_FP_MY,
    };
  },
  components: {
    FingerPrint,
    OtherFingerPrints,
    AccessRequests,
    ExternalFingerPrints,
    NewFeatureBadge,
  },
  computed: {
    ...mapState('users', ['currentUser']),
    ...mapGetters('users', ['isEmailOutsideCompanyEnabled']),
    company() {
      if (this.currentUser.company?.displayName) {
        return this.currentUser.company.displayName;
      }
      return (
        this.currentUser.company.name.charAt(0).toUpperCase() +
        this.currentUser.company.name.slice(1)
      );
    },
    currentUserFingerPrint() {
      if (this.currentUser.user.fingerPrint)
        return this.currentUser.user.fingerPrint;
      return 'director';
    },
  },
  beforeMount() {
    const subTab = this.$route?.query?.subtab;
    const requestId = this.$route?.query?.requestId;
    const isFromZenTrends =
      this.$route?.query?.from === TD_AUDIENCE_ORIGIN_ZENTREDS;
    if (subTab && this.validSubTabs.includes(subTab.trim())) {
      this.setTab(subTab);
    }
    if (subTab && subTab === ACCESS_REQUESTS) {
      this.accessRequestId = requestId;
    }
    if (isFromZenTrends) {
      this.fp_origin = TD_FP_PROFILE;
    }
  },
  methods: {
    gotoTest() {
      trackProfileEvents.profileFingerprintRetaketest(this.currentUser);
      this.$router.push('/welcome?initialIndex=1');
    },
    onHandleQuestion(data) {
      this.handleQuestion(data);
    },
    handleSavePreference() {
      this.$emit('handleSavePreference');
    },
    setTab(tab) {
      this.currentTab = tab;
      if (this.$route?.fullPath?.indexOf(tab) === -1) {
        this.$router.push({
          path: `/profile?tab=fingerprint&subtab=${tab}`,
          replace: true,
        });
      }
      trackProfileEvents.profileFingerprintTabClick(this.currentUser, tab);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss', '@/scss/app.scss';
.fingerprint-content {
  height: 100%;
  overflow: hidden;
  margin-top: 30px;
  width: 100%;

  .btn-retake {
    color: #20a7e0;
    cursor: pointer;
    font-size: 14px;
    margin-bottom: 20px;

    .v-icon {
      color: #20a7e0;
      font-size: 14px;
    }
  }
  .v-tab {
    color: $zen-blue-default !important;
    letter-spacing: 0px;
    text-transform: none !important;
  }
  .fingerprint-tab-active {
    &::before {
      opacity: 0.12;
      background-color: rgba(0, 0, 0, 0.54);
    }
  }

  .fingerprint-tab {
    justify-content: start;
    &:hover {
      text-decoration: none !important;
    }
  }

  .finger-prints-wrapper {
    padding: 50px 20px 16px 2px;
  }
}
</style>
