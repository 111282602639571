<template>
  <div class="learn-section-wrapper">
    <router-view />
  </div>
</template>

<script>
import EventBus from '../../common/event-bus';

export default {
  name: 'Learn',
  data() {
    return {
      leftNavExpanded: false,
    };
  },
  method: {},
  mounted() {
    setTimeout(() => {
      EventBus.$emit('MINIMIZE_LEFTNAV');
    }, 50);
    EventBus.$on('LEFTNAV_EXPANDED', () => {
      this.leftNavExpanded = true;
      // this.setSmartTools(false);
    });
    EventBus.$on('LEFTNAV_MINIMIZED', () => {
      this.leftNavExpanded = false;
    });
    // this.loadLearningData();
  },
  beforeDestroy() {
    EventBus.$off('LEFTNAV_EXPANDED');
    EventBus.$off('LEFTNAV_MINIMIZED');
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.learn-section-wrapper {
  position: relative;
}
</style>
