var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[(_vm.type === 'status')?_c('div',[(!_vm.isEditable || !_vm.isPrezentationOwner)?_c('div',[_c('div',{staticClass:"permission-status"},[(_vm.prezentation.permission_level === 1)?_c('span',[_c('v-icon',{staticClass:"permission-status-icon"},[_vm._v("mdi-lock-outline")]),_vm._v(" "+_vm._s(_vm.$t('prezentationShareStatus.viewer'))+" ")],1):_vm._e(),(_vm.prezentation.permission_level === 0)?_c('span',[_c('v-icon',{staticClass:"permission-status-icon"},[_vm._v("mdi-lock-outline")]),_vm._v(" "+_vm._s(_vm.$t('prezentationShareStatus.restricted'))+" ")],1):_vm._e(),(_vm.prezentation.permission_level === 2)?_c('span',[_c('v-icon',{staticClass:"permission-status-icon"},[_vm._v("mdi-comment-outline")]),_vm._v(" "+_vm._s(_vm.$t('prezentationShareStatus.reviewer'))+" ")],1):_vm._e(),(_vm.isPrezentationOwner)?_c('span',[_c('v-icon',{staticClass:"permission-status-icon"},[_vm._v(" mdi-account-check-outline ")]),_vm._v(" "+_vm._s(_vm.$t('build.step1.private'))+" ")],1):(_vm.prezentation.permission_level === 3)?_c('span',[_c('v-icon',{staticClass:"permission-status-icon"},[_vm._v(" mdi-lock-open-variant-outline ")]),_vm._v(" "+_vm._s(_vm.$t('prezentationShareStatus.editor'))+" ")],1):_vm._e()])]):_vm._e(),(_vm.isEditable && _vm.isPrezentationOwner)?_c('div',[_c('v-tooltip',{attrs:{"top":"","max-width":"200","content-class":"tooltip-content","fixed":"","disabled":_vm.origin.includes('expanded'),"open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({class:!_vm.origin.includes('expanded') && 'prez-status clickable',on:{"click":function($event){$event.preventDefault();_vm.origin.includes('expanded')
                ? function () {}
                : _vm.openSharingOptions(_vm.prezentation)}}},'div',attrs,false),on),[(_vm.isShared)?_c('span',{staticClass:"status-label"},[_c('v-icon',{class:_vm.origin.includes('expanded')
                    ? 'status-icon-expand'
                    : 'status-icon'},[_vm._v(" mdi-account-multiple-check-outline ")]),_vm._v(" "+_vm._s(_vm.$t('build.step1.shared'))+" ")],1):_c('span',{staticClass:"status-label"},[_c('v-icon',{class:_vm.origin.includes('expanded')
                    ? 'status-icon-expand'
                    : 'status-icon'},[_vm._v(" mdi-account-check-outline ")]),_vm._v(" "+_vm._s(_vm.$t('build.step1.private'))+" ")],1)])]}}],null,false,400248174)},[_c('span',[_vm._v(_vm._s(_vm.$t('common.share')))])])],1):_vm._e()]):_vm._e(),(_vm.type === 'icon')?_c('div',{staticClass:"iconOrigin",on:{"click":function($event){_vm.origin.includes('expanded')
        ? function () {}
        : _vm.openSharingOptions(_vm.prezentation)}}},[_c('div',{staticClass:"icon"},[_c('v-icon',[_vm._v("mdi-account-check-outline")])],1),_c('div',{staticClass:"actionName"},[_vm._v(_vm._s(_vm.$t('common.share')))])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }