<template>
  <div>
    <div class="item-template">
      <div class="name">
        {{ item.name || item.display_name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.item-template {
  align-items: center;
  display: flex;
  height: 40px;
  justify-content: flex-start;
  padding: 0 1rem 0 1rem;

  // .image {
  //   margin-right: 0.5rem;
  // }

  .name {
    width: 321px;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
// .dot {
//   margin-left: 10px;
//   margin-right: 5px;
//   opacity: 0.5;
//   text-align: right;
// }
// .type {
//   font-size: 14px;
//   opacity: 0.5;
// }
</style>
