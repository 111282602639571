<template>
  <div
    class="quick-access-actions"
    :style="{ 'margin-top': `${isFullScreenMode ? '8px' : '5px'}` }"
  >
    <div
      class="best-practices-input"
      data-pendo-id="best-practices-toggle"
      v-if="isUserUploadedPrezentation || isGenerated"
    >
      <v-tooltip bottom v-if="isPrezentationOwner">
        <template v-slot:activator="{ on, attrs }">
          <div class="tooltip-activator" v-bind="attrs" v-on="on">
            <span>
              <v-switch
                v-if="shouldToggleDisable"
                :label="$t('build.step3.bestPracticeSwitchLabel')"
                hide-details
                :disabled="true"
              ></v-switch>
              <v-switch
                v-else
                v-model="showBestPractices"
                :label="$t('build.step3.bestPracticeSwitchLabel')"
                hide-details
                @change="handleBestPracticeToggle"
                :disabled="isEditBestPracticesOpen || !isOwnerOfPrezentation"
              ></v-switch>
            </span>
          </div>
        </template>
        <span v-if="isPrezentationOwner">
          {{ $t('build.step3.bestPracticeSwitchTooltip') }}
        </span>
      </v-tooltip>
    </div>
    <div
      class="best-practices-input"
      data-pendo-id="best-practices-toggle"
      v-if="isBestPractices"
    >
      <span>
        <v-switch
          v-if="shouldToggleDisable"
          :label="$t('build.step3.bestPracticeSwitchLabel')"
          hide-details
          :disabled="true"
        ></v-switch>
        <v-switch
          v-else
          :input-value="getShowBestPracticesForBpDecks"
          :label="$t('build.step3.bestPracticeSwitchLabel')"
          hide-details
          @change="(e) => handleBestPracticeToggleForBpDecks(e)"
          :disabled="
            !currentPrezentation ||
            !currentPrezentation.bestPractices ||
            !currentPrezentation.bestPractices.slideToolTips ||
            !currentPrezentation.bestPractices.slideToolTips.length > 0
          "
        />
      </span>
    </div>
    <v-tooltip
      bottom
      max-width="250"
      content-class="tooltip-content"
      fixed
      open-delay="500"
      close-delay="300"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          :class="`view-mode list ${inListViewMode ? 'active' : ''}`"
          v-bind="attrs"
          v-on="on"
          @click="handleViewMode('list')"
        >
          <img class="normal" src="/assets/img/list_view_normal.svg" alt />
          <img class="active" src="/assets/img/list_view_solid.svg" alt />
        </div>
      </template>
      <span>{{ $t('build.step3.listViewText') }}</span>
    </v-tooltip>
    <v-tooltip
      bottom
      max-width="250"
      content-class="tooltip-content"
      fixed
      open-delay="500"
      close-delay="300"
      v-if="!isOpPrezentation"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          :class="`view-mode mosaic ${!inListViewMode ? 'active' : ''}`"
          v-bind="attrs"
          v-on="on"
          @click="handleViewMode('mosaic')"
        >
          <img class="normal" src="/assets/img/mosaic_view_normal.svg" alt />
          <img class="active" src="/assets/img/mosaic_view_solid.svg" alt />
        </div>
      </template>
      <span>{{ $t('build.step3.mosaicViewText') }}</span>
    </v-tooltip>
    <div class="tutorial-container" v-if="!isOpPrezentation && !isViewAccess">
      <v-tooltip
        bottom
        max-width="250"
        content-class="tooltip-content"
        fixed
        open-delay="500"
        close-delay="300"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-hover v-slot="{ hover }">
            <v-btn
              depressed
              small
              icon
              :ripple="false"
              :color="showTutorialMenu || hover ? '#21A7E0' : '#000'"
              v-bind="attrs"
              v-on="on"
              @click="showTutorial"
            >
              <v-icon
                name="tutorials-icon"
                data-pendo-id="prezentation-tutorials"
              >
                mdi-television-play
              </v-icon>
            </v-btn>
          </v-hover>
        </template>
        <span>{{ $t('generate.tutorials') }}</span>
      </v-tooltip>
      <v-menu
        v-model="showTutorialMenu"
        :attach="true"
        absolute
        offset-y
        :position-x="-400"
        :position-y="30"
        :nudge-width="460"
        :close-on-click="true"
        :close-on-content-click="false"
        content-class="tutorial-menu"
      >
        <TutorialMenu :category="handleCategory"></TutorialMenu>
      </v-menu>
    </div>
    <div class="expand" data-pendo-id="story-builder-expand-btn">
      <v-btn depressed small icon :ripple="false" @click="toggleView">
        <v-tooltip bottom max-width="200" fixed>
          <template v-slot:activator="{ on, attrs }">
            <div class="tooltip-activator" v-bind="attrs" v-on="on">
              <v-icon color="black">
                {{
                  isFullScreenMode ? 'mdi-arrow-collapse' : 'mdi-arrow-expand'
                }}
              </v-icon>
            </div>
          </template>

          <span>
            {{
              isFullScreenMode
                ? $t('prezentation.collapse')
                : $t('prezentation.expand')
            }}
          </span>
        </v-tooltip>
      </v-btn>
    </div>
    <template v-if="isFullScreenMode">
      <v-menu
        bottom
        offset-y
        offset-x
        :close-on-click="true"
        :close-on-content-click="closeEllipsisMenu"
        :position-x="menuPos.x"
        :position-y="menuPos.y"
        :key="menuKey"
      >
        <template #activator="menu">
          <v-tooltip bottom>
            <template #activator="tooltip">
              <v-icon v-on="{ ...menu.on, ...tooltip.on }" color="black">
                mdi-dots-vertical
              </v-icon>
            </template>
            <span>{{ $t('slides.moreActions') }}</span>
          </v-tooltip>
        </template>

        <v-list>
          <v-list-item>
            <v-list-item-title>
              <div
                class="dropdown-progress fav-fixed"
                v-if="isChangingFavorite === true"
              >
                <v-progress-circular indeterminate color="primary" :size="22" />
              </div>

              <template v-else>
                <div
                  v-if="isFavorite"
                  class="list-icon-item fav-fixed"
                  @click="handleRemovePrezAsFav(true)"
                >
                  <v-icon color="primary"> mdi-heart </v-icon>

                  <span>
                    {{ $t('bestPracticesGuide.knowSlideCard.removeFavorites') }}
                  </span>
                </div>
                <div
                  v-else
                  class="list-icon-item fav-fixed"
                  @click="handleAddPrezAsFav(true)"
                >
                  <v-icon color="black"> mdi-heart-outline </v-icon>

                  <span>
                    {{ $t('bestPracticesGuide.knowSlideCard.addFavorites') }}
                  </span>
                </div>
              </template>
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="!isPrezentSourceBestPractice"
            :class="{
              'no-click':
                currentUser.user.id !== prezentationData.ownerID ||
                isTemplateRemoved(themes),
            }"
          >
            <v-list-item-title>
              <div
                class="list-icon-item"
                data-pendo-id="story-builder-share-btn"
                @click="share(true)"
              >
                <v-btn
                  :disabled="
                    currentUser.user.id !== prezentationData.ownerID ||
                    isTemplateRemoved(themes)
                  "
                  class="rounded-circle"
                  depressed
                  small
                  icon
                  :ripple="false"
                >
                  <div class="tooltip-activator">
                    <v-icon color="black">mdi-account-multiple-plus</v-icon>
                  </div>
                </v-btn>
                {{ $t('common.share') }}
              </div>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>
              <div @click="copyPrezentationLink(true)" class="list-copy-item">
                <div class="icon">
                  <v-icon color="black">mdi-link-variant</v-icon>
                </div>
                <div class="copy-title">
                  {{ $t('userUploadPrezentation.copyLink') }}
                  <span class="copiedStatus" v-if="showCopied === true">
                    <v-icon small color="#21a7e0">mdi-check</v-icon>
                    <span>{{ $t('common.copied') }}</span>
                  </span>
                </div>
              </div>
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="
              !isPrezentSourceBestPractice && isPrezentationCommentFullAccess
            "
          >
            <v-list-item-title>
              <div
                class="extras-item last-item list-icon-item comment-height"
                data-pendo-id="prezentations-comment-action"
                @click="handleCommentMode"
              >
                <v-btn
                  depressed
                  small
                  icon
                  class="rounded-circle"
                  id="comment-panel-icon--auto"
                >
                  <v-badge
                    :content="currentPrezentation.countNewComments"
                    :value="currentPrezentation.countNewComments"
                    color="primary"
                    overlap
                    size="20"
                  >
                    <v-icon :color="isCommentMode ? 'primary' : 'black'">
                      mdi-comment-outline
                    </v-icon>
                  </v-badge>
                </v-btn>
                {{ $t('build.step3.commentsTooltip') }}
              </div>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <template v-else>
      <div class="fav">
        <v-progress-circular
          indeterminate
          color="primary"
          v-if="isChangingFavorite"
          :size="22"
        />
        <template v-else>
          <v-tooltip
            bottom
            max-width="200"
            content-class="tooltip-content"
            fixed
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-if="!isFavorite"
                color="black"
                v-bind="attrs"
                v-on="on"
                @click="handleAddPrezAsFav"
              >
                mdi-heart-outline
              </v-icon>
              <v-icon
                v-else
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="handleRemovePrezAsFav"
              >
                mdi-heart
              </v-icon>
            </template>
            <span>
              {{
                `${
                  isFavorite
                    ? $t('bestPracticesGuide.knowSlideCard.removeFavorites')
                    : $t('bestPracticesGuide.knowSlideCard.addFavorites')
                }`
              }}
            </span>
          </v-tooltip>
        </template>
      </div>
      <div
        class="share"
        v-if="!isPrezentSourceBestPractice"
        data-pendo-id="prez-share-btn"
      >
        <v-btn
          :disabled="
            currentUser.user.id !== prezentationData.ownerID ||
            isTemplateRemoved(themes)
          "
          class="rounded-circle"
          depressed
          small
          icon
          :ripple="false"
          @click="share(false)"
        >
          <v-tooltip bottom max-width="200" fixed>
            <template v-slot:activator="{ on, attrs }">
              <div class="tooltip-activator" v-bind="attrs" v-on="on">
                <v-icon color="black">mdi-account-multiple-plus</v-icon>
              </div>
            </template>
            <span> {{ $t('common.share') }}</span>
          </v-tooltip>
        </v-btn>
      </div>

      <div class="copy-link" @click="copyPrezentationLink">
        <v-tooltip top v-if="showCopyLink">
          <template #activator="{ on }">
            <div v-on="on" class="copyLink">
              <div class="copyIconAndTitle">
                <div class="icon">
                  <v-icon color="black">mdi-link-variant</v-icon>
                </div>
              </div>
              <div
                v-if="showCopied === true"
                class="copiedStatus bg-copied-text"
              >
                <v-icon small color="#21a7e0">mdi-check</v-icon>
                <div>{{ $t('common.copied') }}</div>
              </div>
            </div>
          </template>
          <span>
            {{ $t('userUploadPrezentation.copyLink') }}
          </span>
        </v-tooltip>
      </div>

      <div
        class="comment extras-item last-item"
        v-if="!isPrezentSourceBestPractice && isPrezentationCommentFullAccess"
        data-pendo-id="prezentations-comment-action"
      >
        <v-btn
          depressed
          small
          icon
          @click="handleCommentMode"
          v-tooltip="{
            content: $t('build.step3.commentsTooltip'),
            placement: 'bottom',
          }"
          class="rounded-circle"
          id="comment-panel-icon--auto"
        >
          <v-badge
            :content="
              currentPrezentation.countNewComments > 0
                ? currentPrezentation.countNewComments
                : 0
            "
            :value="
              currentPrezentation.countNewComments > 0
                ? currentPrezentation.countNewComments
                : 0
            "
            color="primary"
            overlap
            size="20"
          >
            <v-icon :color="isCommentMode ? 'primary' : 'black'">
              mdi-comment-outline
            </v-icon>
          </v-badge>
        </v-btn>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import SharePrezentation from '../../../Prezentations/SharePrezentation.vue';
import TutorialMenu from '../../../GenerateSlides/TutorialMenu.vue';
import {
  MY_PREZENTATION,
  EXPANDED_VIEW,
  TD_ISFAVORITED,
  TD_AUD,
  // TD_COMMENT,
  TD_PREZNAME,
  TD_PREZTYPE,
  TD_PREZENTATION_ORIGIN,
  TD_PREZENTATION_COMPANY_SOURCE,
  TD_SECTION_4,
  TD_SECTION_5,
  TD_IDEABEGIN,
  TD_IDEAMIDDLE,
  TD_IDEAEND,
  TD_PREZENTATIONAUTHOR,
  TD_TEMPLATE,
  TD_MINIMIZE,
  TD_MAXIMIZE,
  TD_PREZENTATION_CATEGORY,
  TD_NUMBEROFSLIDES,
  TD_PLACEMENT,
  TD_EXPANDED_DETAIL_VIEW,
  TD_COMMON_COLUMN_NAME,
  TD_COMMON_COLUMN_VALUE,
  TD_CLICKED_TO,
  getPrezData,
  BUILD,
  TD_KNOWBESTPRACTOGGLE,
} from '../../../../common/Analytics/MatomoTrackingDataHelper';
import {
  addPrezentationAsFavorite,
  removePrezentationAsFavorite,
} from '@/utils/api-helper';
import { trackPrezentationEvents } from '../../../../common/Analytics/PrezentationEvents';
import { trackBuildEvents } from '../../../../common/Analytics/BuildEvents';
import { updatePrezentationFunction } from '../../../../../utils/api-helper';
import { trackKnowEvents } from '../../../../common/Analytics/KnowEvents';

export default {
  props: {
    origin: {
      type: String,
      default: 'my_prezentation',
    },
    rightPanelOpen: {
      type: Function,
      default: () => {},
    },
    rightPanelClose: {
      type: Function,
      default: () => {},
    },
  },
  components: {
    TutorialMenu,
  },
  computed: {
    ...mapState('users', ['currentUser', 'themes']),
    ...mapState('prezentationDetails', [
      'prezentationData',
      'selectedSlide',
      'isFullScreenMode',
      'selectedQuickAction',
      'inListViewMode',
      'selectedSlideAction',
      'selectedMainAction',
    ]),
    ...mapGetters('users', ['isPrezentationCommentFullAccess']),
    ...mapGetters('prezentationDetails', [
      'isViewAccess',
      'isRestrictedAccess',
      'isOpPrezentation',
      'isBestPractices',
      'showBestPracticeToggle',
      'isPrezentSourceBestPractice',
      'isUserUploadedPrezentation',
      'showShareOption',
      'isIOCUpload',
      'isGenerated',
      'isRedesigned',
      'isComply',
      'isTemplateRemoved',
      'isPartialAccess',
      'isPrezBPListFilled',
      'getShowBestPracticesForBpDecks',
    ]),
    isEditBestPracticesOpen() {
      return this.selectedSlideAction?.id === 'editBestPractices';
    },
    handleCategory() {
      if (this.isBestPractices) {
        return 'Best Practice Library';
      }
      if (this.origin === 'build') {
        return 'Story Builder';
      }
      return 'Prezentation Library';
    },
    isFavorite() {
      if (typeof this.prezentationData?.isFavorite === 'boolean') {
        return this.prezentationData?.isFavorite;
      }
      return this.prezentationData?.favourites_by?.includes(
        this.currentUser.user.num_id,
      );
    },
    shouldToggleDisable() {
      console.log(this.selectedMainAction);
      return (
        this.selectedMainAction?.id === 'convertTemplate' ||
        this.selectedMainAction?.id === 'changeVisual'
      );
    },
  },
  data() {
    return {
      currentPrezentation: {},
      matomo_expanded_view: EXPANDED_VIEW,
      matomo_my_prezentation: MY_PREZENTATION,
      isChangingFavorite: false,
      showCopied: false,
      showTutorialMenu: false,
      isCommentMode: false,
      firstOpen: true,
      showBestPractices: false,
      isFullScreen: false,
      menuKey: 0,
      menuPos: {
        x: 0,
        y: 0,
      },
      closeEllipsisMenu: true,
      isOwnerOfPrezentation: false,
    };
  },
  mounted() {
    this.currentPrezentation = this.prezentationData;
    this.isOwnerOfPrezentation =
      this.currentUser.user.id === this.currentPrezentation.ownerID;
    this.showBestPractices =
      this.isPrezBPListFilled &&
      (this.showBestPracticeToggle || this.isGenerated) &&
      this.currentPrezentation.isBestPracticesEnabled;
    this.setBPToggleState(this.showBestPractices);
    if (!this.isBestPractices) {
      this.setShowBestPracticesForBpDecks(false);
    }
  },
  watch: {
    selectedSlide() {
      //
    },
    prezentationData(val) {
      this.currentPrezentation = val;
      if (this.currentPrezentation) {
        this.showBestPractices =
          this.currentPrezentation.isBestPracticesEnabled;
      }
    },
    isFullScreenMode(val) {
      this.isFullScreen = val;
    },
    selectedQuickAction(val) {
      if (val.id === 'prezentationComments') {
        //
      } else {
        this.isCommentMode = false;
      }
    },
    isPrezBPListFilled(val) {
      // TODO: we may not need this, check and remove
      this.showBestPractices = val;
      this.setBPToggleState(this.showBestPractices);
    },
  },
  methods: {
    ...mapActions('prezentationDetails', [
      'setSelectedQuickAction',
      'setPrezentationData',
      'setFullScreenMode',
      'setInListViewMode',
      'setBPToggleState',
      'setSelectedSlideAction',
      'setSelectedMainAction',
      'updatePrezentationData',
      'setShowBestPracticesForBpDecks',
      'updatePartialPrezentationData',
    ]),
    ...mapActions('prezentations', ['updatePrezentationWithAction']),

    ...mapActions('commonDownloads', [
      'setNavFromAddToLibOrFavSnackbar',
      'setAddedToLibOrFavSnackbar',
    ]),

    handleViewMode(val) {
      if (val === 'list' && !this.inListViewMode) {
        this.setInListViewMode(true);
      }
      if (val === 'mosaic' && this.inListViewMode) {
        this.setInListViewMode(false);
        this.rightPanelClose();
        if (this.origin.toLowerCase() === BUILD) {
          trackBuildEvents.buildStepThreeMosaicClick(
            this.currentUser,
            this.currentPrezentation,
            {},
          );
        } else {
          trackPrezentationEvents.prezentationsStepThreeMosaicClick(
            this.currentUser,
            this.currentPrezentation,
            {},
          );
        }
      }
    },
    toggleView() {
      this.isFullScreen = this.isFullScreenMode;
      this.setFullScreenMode(!this.isFullScreen);
      if (this.origin.toLowerCase() === BUILD) {
        trackBuildEvents.buildMaximizeMinimizeClick(
          this.currentUser,
          this.currentPrezentation,
          {
            [TD_COMMON_COLUMN_NAME]: TD_CLICKED_TO,
            [TD_COMMON_COLUMN_VALUE]: !this.isFullScreen
              ? TD_MAXIMIZE
              : TD_MINIMIZE,
          },
        );
      } else {
        trackPrezentationEvents.prezentationsMaximizeMinimizeClick(
          this.currentUser,
          this.currentPrezentation,
          {
            [TD_COMMON_COLUMN_NAME]: TD_CLICKED_TO,
            [TD_COMMON_COLUMN_VALUE]: !this.isFullScreen
              ? TD_MAXIMIZE
              : TD_MINIMIZE,
          },
        );
      }
    },
    async handleCommentMode() {
      if (!this.isCommentMode && this.firstOpen) {
        this.firstOpen = false;
      }
      this.isCommentMode = !this.isCommentMode;
      this.handleCommentView();
    },
    handleCommentView() {
      if (this.isCommentMode) {
        this.setSelectedQuickAction({ id: 'prezentationComments' });
        this.setSelectedSlideAction({});
        this.setSelectedMainAction({});
        this.rightPanelOpen(true);
      } else {
        this.setSelectedQuickAction({ id: '' });
        this.rightPanelOpen(false);
      }
    },
    isPrezentationOwner() {
      return (
        this.currentPrezentation?.ownerID &&
        this.currentUser &&
        this.currentPrezentation?.ownerID.toLowerCase() ===
          this.currentUser.user.id.toLowerCase()
      );
    },
    showTutorial() {
      this.showTutorialMenu = !this.showTutorialMenu;
      const otherData = {
        [TD_AUD]: this.prezentationData.audienceID,
        [TD_PREZNAME]: this.prezentationData.name,
        [TD_PREZTYPE]: this.prezentationData.type,
        [TD_TEMPLATE]: this.prezentationData.theme,
      };
      if (this.origin === 'build') {
        trackBuildEvents.buildTutorialIconClick(this.currentUser, otherData);
      } else {
        trackPrezentationEvents.prezentationsTutorialIconClick(
          this.currentUser,
          otherData,
        );
      }
    },
    copyPrezentationLink(isDropdown) {
      if (isDropdown) {
        this.closeEllipsisMenu = false;
      }
      navigator.clipboard.writeText(
        `${window.location.origin}/home/${
          this.isBestPractices
            ? 'best-practice-library-decks'
            : 'myPrezentation'
        }/${this.currentPrezentation?.id}`,
      );
      this.showCopied = true;
      setTimeout(() => {
        this.showCopied = false;
        if (isDropdown) {
          this.closeEllipsisMenu = true;
          this.menuKey++;
        }
      }, 1000);
      if (this.origin === 'build') {
        trackBuildEvents.buildCopylink(
          this.currentUser,
          this.getMatomoOtherData(this.currentPrezentation),
        );
      } else {
        trackPrezentationEvents.prezentationsElipsisCopylink(this.currentUser, {
          ...this.getMatomoOtherData(this.currentPrezentation),
          [TD_PLACEMENT]: TD_EXPANDED_DETAIL_VIEW,
        });
      }
    },
    handleBestPracticeToggleForBpDecks(e) {
      trackKnowEvents.knowBestPractice(
        this.currentPrezentation,
        this.currentUser,
        {
          [TD_KNOWBESTPRACTOGGLE]: e ? 'ON' : 'OFF',
          [TD_PLACEMENT]: 'Deck',
        },
      );
      this.setShowBestPracticesForBpDecks(!!e);
    },
    handleBestPracticeToggle(event) {
      this.showBestPractices = event;
      this.setBPToggleState(event);
      this.setSelectedQuickAction({ id: 'toggleBp' });
      this.enableDisableBestPracticesForUsers(event);
      const prezData = getPrezData(this.currentPrezentation);
      trackPrezentationEvents.prezentationsBestPracticeDeckToggle(
        this.currentUser,
        {
          ...prezData,
        },
      );
    },
    async enableDisableBestPracticesForUsers(event) {
      try {
        if (this.currentUser.user.id === this.currentPrezentation.ownerID) {
          const prezentationData = {
            id: this.currentPrezentation.id,
            isBestPracticesEnabled: event,
          };
          this.updatePartialPrezentationData({ isBestPracticesEnabled: event });
          await updatePrezentationFunction(prezentationData);
        } else {
          // not owner, so no api will be called
        }
      } catch (error) {
        console.log('error in updating toggle');
      }
    },
    showCopyLink() {
      const level = this.currentPrezentation?.permission_level;

      if (
        this.currentPrezentation?.prezentationType === 'OP' &&
        !this.isPrezentationOwner()
      ) {
        if (level === 0 || level === 2 || level === 1) {
          return true;
        }
      }
      if (this.isPrezentationOwner()) {
        return true;
      }
      if (level === 0 || level === 1 || level === 2 || level === 3) {
        return true;
      }

      return false;
    },

    goToFavBpLib() {
      this.setNavFromAddToLibOrFavSnackbar({
        navigated: true,
        type: 'addToFavPrezBp',
        origin: 'fav_prez_bp',
      });
      this.$router.push('/home/best-practice-library').catch(() => {});
    },

    goToFav() {
      this.setNavFromAddToLibOrFavSnackbar({
        navigated: true,
        type: 'addToFavPrez',
        origin: 'fav_prez',
      });
      this.$router.push('/home/prezentations').catch(() => {});
    },

    async handleAddPrezAsFav(isDropdown) {
      if (isDropdown) {
        this.closeEllipsisMenu = false;
      }
      const prez = this.currentPrezentation;
      this.isChangingFavorite = true;
      await addPrezentationAsFavorite({
        prezentationId:
          this.origin === 'hybrid_search'
            ? prez.prezentationId || prez.id
            : prez.id,
      })
        .then(() => {
          // console.log(resp);
          this.isChangingFavorite = false;
          this.updatePartialPrezentationData({ isFavorite: true });

          // updating prezentation landing page snapshot
          this.updatePrezentationWithAction({
            prezentation: {
              ...prez,
              isFavorite: true,
            },
            action: 'updated',
            origin: this.origin,
          });

          // updating prezentation landing page lists
          this.updatePrezentationWithAction({
            prezentation: prez,
            action: 'addFav',
            origin: this.origin,
          });

          this.setAddedToLibOrFavSnackbar({
            show: true,
            ctaText: 'snackBarItems.goToFavorites',
            snackbarText: 'snackBarItems.prezAddedToFav',
            navFn: this.isBestPractices ? this.goToFavBpLib : this.goToFav,
            snackbarType: 'goToFav',
          });

          if (this.origin === 'build') {
            trackBuildEvents.buildFavourited(this.currentUser, {
              ...this.getMatomoOtherData(prez),
              [TD_ISFAVORITED]: 1,
            });
          } else {
            trackPrezentationEvents.prezentationsFavourited(this.currentUser, {
              ...this.getMatomoOtherData(prez),
              [TD_ISFAVORITED]: 1,
            });
          }
          if (isDropdown) {
            this.closeEllipsisMenu = true;
          }
        })
        .catch((err) => {
          console.log(err);
          this.isChangingFavorite = false;
          if (isDropdown) {
            this.closeEllipsisMenu = true;
          }
        });
    },
    async handleRemovePrezAsFav(isDropdown) {
      if (isDropdown) {
        this.closeEllipsisMenu = false;
      }
      const prez = this.currentPrezentation;
      this.isChangingFavorite = true;
      await removePrezentationAsFavorite(
        this.origin === 'hybrid_search'
          ? prez.prezentationId || prez.id
          : prez.id,
      )
        .then((resp) => {
          console.log(resp);
          this.isChangingFavorite = false;
          this.updatePartialPrezentationData({ isFavorite: false });

          // updating prezentation landing page snapshot
          this.updatePrezentationWithAction({
            prezentation: {
              ...prez,
              isFavorite: false,
            },
            action: 'updated',
            origin: this.origin,
          });

          // updating prezentation landing page lists
          this.updatePrezentationWithAction({
            prezentation: prez,
            action: 'removeFav',
            origin: this.origin,
          });

          if (this.origin === 'build') {
            trackBuildEvents.buildFavourited(this.currentUser, {
              ...this.getMatomoOtherData(prez),
              [TD_ISFAVORITED]: 0,
            });
          } else {
            trackPrezentationEvents.prezentationsFavourited(this.currentUser, {
              ...this.getMatomoOtherData(prez),
              [TD_ISFAVORITED]: 0,
            });
          }
          if (isDropdown) {
            this.closeEllipsisMenu = true;
          }
        })
        .catch((err) => {
          console.log(err);
          this.isChangingFavorite = false;
          if (isDropdown) {
            this.closeEllipsisMenu = true;
          }
        });
    },
    getMatomoOtherData(prezData) {
      try {
        const {
          audienceID,
          author,
          name,
          type,
          sections,
          prezentationType,
          prezentationSource,
          source,
        } = prezData;

        let slidesLength = 0;
        sections.list.forEach((section) => {
          slidesLength += section.slides.length;
        });
        return {
          [TD_AUD]: audienceID,
          [TD_PREZENTATIONAUTHOR]: author,
          [TD_PREZNAME]: name,
          [TD_PREZTYPE]: type,
          [TD_NUMBEROFSLIDES]: slidesLength,
          [TD_PREZENTATION_CATEGORY]: prezentationType,
          [TD_PREZENTATION_ORIGIN]: prezentationSource,
          [TD_PREZENTATION_COMPANY_SOURCE]: source,
          [TD_IDEABEGIN]: this.getSectionDeatils(0),
          [TD_IDEAMIDDLE]: this.getSectionDeatils(1),
          [TD_IDEAEND]: this.getSectionDeatils(2),
          [TD_SECTION_4]: this.getSectionDeatils(3),
          [TD_SECTION_5]: this.getSectionDeatils(4),
        };
      } catch (e) {
        return {};
      }
    },
    getSectionDeatils(value) {
      let details = '';
      if (this.currentPrezentation?.sections?.list[value]?.sectionName) {
        details = `${this.currentPrezentation?.sections?.list[value]?.sectionName}_`;
        this.currentPrezentation?.sections?.list[value]?.slides.forEach(
          (slide) => {
            details += slide.outline;
            details += '_';
          },
        );
      }
      return details;
    },
    openSharingOptions(prezentation, origin) {
      let orig = `${this.origin || MY_PREZENTATION}-${origin}`;
      if (
        origin === 'upload-prezentation' ||
        this.currentPrezentation?.prezentationSource === 'User Uploaded'
      ) {
        orig = 'upload-prezentation';
      }
      this.$modal.show(
        SharePrezentation,
        {
          prezentation: prezentation || this.currentPrezentation,
          origin: orig,
        },
        {
          name: 'SharePrezentation',
          width: 700,
          height: 'auto',
          minHeight: 300,
          styles: { borderRadius: '20px' },
        },
      );
    },
    share(expanded) {
      this.openSharingOptions(
        this.currentPrezentation,
        expanded ? `${this.matomo_expanded_view}-share-icon` : 'share-icon',
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.quick-access-actions {
  display: flex;
  align-items: center;
  gap: 15px;
  position: absolute;
  right: 15px;
  background-color: white;
}

.v-icon {
  width: 25px;
  height: 25px;
  cursor: pointer;

  &:hover {
    background-color: #21a7e01c;
    border-radius: 20px;
  }

  &:after {
    background-color: transparent;
  }
}

.tutorial-menu {
  border-radius: 12px;
  width: 520px;
  background: white;
}

.copyLink {
  cursor: pointer;
  position: relative;

  // .copyIconAndTitle {
  // }

  .copiedStatus {
    color: #21a7e0;
    position: absolute;
    margin: 0 auto;
    font-size: 12px;
    display: flex;
    align-items: center;
    transform: translateX(-50%);
    left: 50%;

    .v-icon {
      font-size: 12px !important;
      width: 20px;
    }
  }
  .bg-copied-text {
    background: white;
  }
}

.list-icon-item {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
.list-copy-item {
  // .copyIconAndTitle {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  .copy-title {
    display: flex;
    align-items: center;
    gap: 10px;
    .copiedStatus {
      color: #21a7e0;
      // position: absolute;
      margin: 0 auto;
      font-size: 12px;
      display: flex;
      align-items: center;
      // transform: translateX(-45%);
      // left: 40%;

      .v-icon {
        font-size: 12px !important;
        width: 20px;
      }
    }
  }
  // }
}

.dropdown-progress {
  display: flex;
  justify-content: center;
  width: 100%;
}

.best-practices-input {
  margin-left: auto;
  margin-right: 0;

  ::v-deep .v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;
  }

  ::v-deep .v-input--selection-controls__input + .v-label {
    color: #000000;
    font-size: 14px;
  }
}

.best-practices-input-disabled {
  ::v-deep .v-input--selection-controls__input + .v-label {
    color: rgba(0, 0, 0, 0.26) !important;
  }
}

.fav {
  width: 25px;
}

.fav-fixed {
  width: 200px;
}

.view-mode {
  display: flex;
  align-items: center;
  justify-content: space-between;

  cursor: pointer;
  .active {
    display: none;
  }
  .normal {
    display: block;
  }
  &.active {
    .active {
      display: block;
    }
    .normal {
      display: none;
    }
  }
}
.v-list-item {
  &:hover {
    background-color: #d8d8d8;
  }
}
.comment-height {
  height: 40px;
}
.no-click {
  pointer-events: none;
  opacity: 0.5;
}
</style>
