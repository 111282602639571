<template>
  <svg
    width="288"
    height="182"
    viewBox="0 0 288 182"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_870_157593)">
      <path
        opacity="0.165386"
        d="M144.001 181.076C223.157 181.076 287.326 173.656 287.326 164.503C287.326 155.35 223.157 147.93 144.001 147.93C64.8447 147.93 0.675781 155.35 0.675781 164.503C0.675781 173.656 64.8447 181.076 144.001 181.076Z"
        fill="#21A7E0"
      />
      <path
        d="M166.79 7.39185L160.75 8.51118L86.3238 22.3016L80.2828 23.4209C74.1775 24.5574 70.5048 29.0304 72.0728 33.4196L98.2677 106.405C99.8495 110.792 106.075 113.431 112.184 112.304L112.199 112.301L198.676 96.2777L198.691 96.2748C204.796 95.1383 208.469 90.6654 206.901 86.2761L180.706 13.2909C179.125 8.90415 172.899 6.26521 166.79 7.39185Z"
        fill="#F2F2F2"
      />
      <path
        d="M167.719 9.3524L160.536 10.68L88.067 24.0765L80.8851 25.4041C76.521 26.2146 73.8957 29.4044 75.0165 32.5345L101.31 105.623C102.441 108.751 106.891 110.633 111.257 109.83L111.273 109.827L198.075 93.7808L198.091 93.7779C202.455 92.9674 205.08 89.7776 203.959 86.6475L177.666 13.5592C176.535 10.4309 172.085 8.54899 167.719 9.3524Z"
        fill="white"
      />
      <path
        d="M166.633 24.4268L119.459 33.5674C118.362 33.78 117.243 33.284 116.96 32.4596C116.677 31.6352 117.337 30.7946 118.434 30.582L165.608 21.4414C166.705 21.2297 167.823 21.7257 168.105 22.5495C168.388 23.3734 167.729 24.2136 166.633 24.4268Z"
        fill="#F2F2F2"
      />
      <path
        d="M177.282 27.9794L121.509 38.661C120.404 38.8727 119.277 38.3789 118.992 37.5581C118.707 36.7373 119.372 35.9003 120.477 35.6887L176.25 25.007C177.355 24.7953 178.482 25.2891 178.767 26.1099C179.052 26.9307 178.387 27.7677 177.282 27.9794Z"
        fill="#F2F2F2"
      />
      <path
        d="M174.753 46.3283L127.58 55.4691C126.87 55.6066 126.116 55.4492 125.603 55.0561C125.09 54.663 124.894 54.094 125.091 53.5634C125.287 53.0328 125.845 52.6212 126.555 52.4836L173.728 43.3428C174.438 43.2053 175.191 43.3627 175.705 43.7558C176.218 44.1489 176.413 44.7179 176.217 45.2485C176.021 45.7791 175.462 46.1907 174.753 46.3283Z"
        fill="#F2F2F2"
      />
      <path
        d="M181.858 68.7394L134.685 77.8802C133.975 78.0177 133.222 77.8603 132.708 77.4672C132.195 77.0741 132 76.5051 132.196 75.9745C132.392 75.4439 132.951 75.0323 133.66 74.8948L180.833 65.7539C181.543 65.6164 182.297 65.7739 182.81 66.1669C183.323 66.56 183.519 67.1291 183.322 67.6597C183.126 68.1903 182.568 68.6019 181.858 68.7394Z"
        fill="#F2F2F2"
      />
      <path
        d="M184.895 49.8798L129.122 60.5618C128.407 60.6987 127.648 60.5419 127.131 60.1506C126.614 59.7592 126.417 59.1926 126.615 58.6643C126.812 58.136 127.375 57.7262 128.09 57.5893L183.863 46.9074C184.968 46.6957 186.095 47.1895 186.38 48.0103C186.665 48.8312 186 49.6682 184.895 49.8798Z"
        fill="#F2F2F2"
      />
      <path
        d="M192 71.7822L136.227 82.4641C135.512 82.601 134.753 82.4443 134.236 82.0529C133.719 81.6615 133.522 81.095 133.72 80.5667C133.918 80.0384 134.48 79.6286 135.195 79.4917L190.968 68.8097C192.074 68.5981 193.2 69.0919 193.486 69.9127C193.771 70.7335 193.106 71.5705 192 71.7822Z"
        fill="#F2F2F2"
      />
      <path
        d="M183.792 68.7396L149.229 77.8801C148.426 78.0921 147.607 77.5961 147.4 76.7721C147.192 75.9482 147.675 75.1079 148.479 74.8948L183.041 65.7543C183.845 65.5417 184.665 66.0376 184.872 66.862C185.08 67.6864 184.596 68.527 183.792 68.7396Z"
        fill="#F2F2F2"
      />
      <path
        d="M191.392 71.7821L150.273 82.4638C149.745 82.6012 149.185 82.4447 148.804 82.0532C148.422 81.6618 148.277 81.0949 148.423 80.5663C148.569 80.0377 148.984 79.6279 149.512 79.4914L190.631 68.8097C191.446 68.5981 192.277 69.0919 192.487 69.9127C192.697 70.7335 192.207 71.5704 191.392 71.7821Z"
        fill="#F2F2F2"
      />
      <path
        d="M111.082 42.1955L94.5031 45.3083C93.9807 45.4059 93.4484 45.1772 93.3131 44.7971L89.5075 34.0525C89.3734 33.6722 89.6875 33.2846 90.2095 33.1861L106.788 30.0733C107.311 29.9757 107.843 30.2043 107.978 30.5845L111.784 41.3291C111.918 41.7094 111.604 42.097 111.082 42.1955Z"
        fill="#E6E6E6"
      />
      <path
        d="M119.207 64.6066L102.628 67.7194C102.106 67.817 101.573 67.5884 101.438 67.2082L97.6325 56.4636C97.4984 56.0833 97.8125 55.6957 98.3345 55.5972L114.913 52.4844C115.436 52.3868 115.968 52.6155 116.103 52.9956L119.909 63.7402C120.043 64.1205 119.729 64.5081 119.207 64.6066Z"
        fill="#E6E6E6"
      />
      <path
        d="M126.82 87.0167L110.241 90.1295C109.719 90.2272 109.187 89.9985 109.051 89.6184L105.246 78.8738C105.112 78.4935 105.426 78.1059 105.948 78.0074L122.527 74.8946C123.049 74.797 123.581 75.0256 123.716 75.4057L127.522 86.1504C127.656 86.5307 127.342 86.9183 126.82 87.0167Z"
        fill="#E6E6E6"
      />
      <path
        d="M219.937 15.7891H124.526C117.79 15.7947 112.332 19.8497 112.324 24.8536V108.082C112.332 113.086 117.79 117.141 124.526 117.146H219.937C226.672 117.141 232.131 113.086 232.138 108.082V24.8536C232.131 19.8497 226.672 15.7947 219.937 15.7891Z"
        fill="#E6E6E6"
      />
      <path
        d="M220.371 18.8447H124.601C119.786 18.8487 115.884 21.7402 115.879 25.3082V108.645C115.884 112.213 119.786 115.105 124.601 115.109H220.371C225.186 115.105 229.087 112.213 229.093 108.645V25.3082C229.087 21.7402 225.186 18.8488 220.371 18.8447Z"
        fill="white"
      />
      <path
        d="M207.322 66.722H160.498C159.793 66.7226 159.141 66.4315 158.788 65.9585C158.435 65.4855 158.435 64.9025 158.788 64.4295C159.141 63.9565 159.793 63.6654 160.498 63.666H207.322C208.411 63.667 209.292 64.3508 209.292 65.194C209.292 66.0372 208.411 66.7211 207.322 66.722Z"
        fill="#E6E6E6"
      />
      <path
        d="M214.936 72.3255H159.989C159.284 72.3261 158.633 72.035 158.28 71.562C157.927 71.089 157.927 70.5061 158.28 70.033C158.633 69.56 159.284 69.2689 159.989 69.2695H214.936C216.025 69.2695 216.907 69.9536 216.907 70.7975C216.907 71.6414 216.025 72.3255 214.936 72.3255Z"
        fill="#E6E6E6"
      />
      <path
        d="M207.32 89.6429H160.497C159.792 89.6435 159.141 89.3524 158.788 88.8794C158.435 88.4064 158.435 87.8234 158.788 87.3504C159.141 86.8774 159.792 86.5863 160.497 86.5869H207.32C208.409 86.5869 209.292 87.271 209.292 88.1149C209.292 88.9588 208.409 89.6429 207.32 89.6429Z"
        fill="#E6E6E6"
      />
      <path
        d="M214.934 95.2445H159.989C159.284 95.2451 158.633 94.954 158.28 94.481C157.927 94.008 157.927 93.425 158.28 92.952C158.633 92.479 159.284 92.1879 159.989 92.1885H214.934C215.638 92.1879 216.29 92.479 216.643 92.952C216.995 93.425 216.995 94.008 216.643 94.481C216.29 94.954 215.638 95.2451 214.934 95.2445Z"
        fill="#E6E6E6"
      />
      <path
        d="M152.21 72.8349H139.451C139.049 72.8345 138.723 72.5108 138.723 72.1114V60.825C138.723 60.4257 139.049 60.102 139.451 60.1016H152.21C152.612 60.102 152.937 60.4257 152.938 60.825V72.1114C152.937 72.5108 152.612 72.8345 152.21 72.8349Z"
        fill="#E6E6E6"
      />
      <path
        d="M152.21 95.246H139.451C139.049 95.2456 138.723 94.9219 138.723 94.5225V83.2362C138.723 82.8368 139.049 82.5131 139.451 82.5127H152.21C152.612 82.5131 152.937 82.8368 152.938 83.2362V94.5225C152.937 94.9219 152.612 95.2456 152.21 95.246Z"
        fill="#E6E6E6"
      />
      <path
        d="M206.689 39.2181H161.126C159.689 39.2181 158.523 38.534 158.523 37.6901C158.523 36.8462 159.689 36.1621 161.126 36.1621H206.689C208.127 36.1621 209.292 36.8462 209.292 37.6901C209.292 38.534 208.127 39.2181 206.689 39.2181Z"
        fill="#CCCCCC"
      />
      <path
        d="M218.326 44.8216H161.166C160.222 44.8216 159.349 44.5304 158.877 44.0576C158.405 43.5849 158.405 43.0024 158.877 42.5296C159.349 42.0569 160.222 41.7656 161.166 41.7656H218.326C219.786 41.7656 220.969 42.4497 220.969 43.2936C220.969 44.1375 219.786 44.8216 218.326 44.8216Z"
        fill="#CCCCCC"
      />
      <path
        opacity="0.224646"
        d="M152.213 48.8958H127.773C127.372 48.8954 127.047 48.573 127.047 48.1751V29.2431C127.047 28.8453 127.372 28.5229 127.773 28.5225H152.213C152.614 28.5229 152.938 28.8453 152.939 29.2431V48.1751C152.938 48.573 152.614 48.8954 152.213 48.8958Z"
        fill="#21A7E0"
      />
      <path
        d="M240.838 167.572H78.0283C72.018 167.565 67.1474 162.947 67.1406 157.248V72.4639C67.1474 66.7646 72.018 62.1461 78.0283 62.1397H97.9901C100.699 62.1374 103.31 63.0947 105.313 64.8238L126.96 79.4814C128.371 80.7044 130.215 81.3818 132.126 81.3802H241.848C244.764 81.3802 247.557 82.4888 249.603 84.4577C251.65 86.4266 252.778 89.0913 252.736 91.8554L251.724 157.398C251.613 163.031 246.779 167.549 240.838 167.572Z"
        fill="white"
      />
      <path
        d="M239.176 162.69H78.7115C75.8109 162.687 73.4603 160.458 73.457 157.708V71.5141C73.4603 68.764 75.8109 66.5353 78.7115 66.5322C81.277 66.535 83.3561 68.5062 83.359 70.9386V151.762C83.3621 154.34 85.565 156.428 88.2834 156.431H238.635C240.172 156.415 241.522 157.395 241.907 158.806C242.339 160.144 241.752 161.588 240.486 162.306C240.095 162.547 239.642 162.68 239.176 162.69Z"
        fill="#F2F2F2"
      />
      <path
        d="M241.056 167.572H78.2471C72.2368 167.565 67.3662 162.947 67.3594 157.248V72.4639C67.3662 66.7646 72.2368 62.1461 78.2471 62.1397H98.2088C100.917 62.1374 103.529 63.0947 105.532 64.8238L127.179 79.4814C128.59 80.7044 130.433 81.3818 132.345 81.3802H242.067C244.982 81.3802 247.776 82.4888 249.822 84.4577C251.868 86.4266 252.996 89.0913 252.955 91.8554L251.943 157.398C251.832 163.031 246.997 167.549 241.056 167.572ZM78.0654 63.0219C72.4236 63.028 67.8516 67.3636 67.8452 72.7137V157.439C67.8516 162.789 72.4236 167.124 78.0654 167.131H240.753C246.33 167.109 250.868 162.868 250.972 157.58L251.983 92.0827C252.022 89.488 250.963 86.9864 249.042 85.1381C247.122 83.2898 244.499 82.2492 241.763 82.2492H132.123C130.055 82.2509 128.061 81.5205 126.531 80.201L104.9 65.5536C103.018 63.9231 100.561 63.0199 98.0122 63.0219H78.0654Z"
        fill="#68788C"
      />
      <path
        d="M231.75 97.3879C240.862 97.3879 248.25 89.9768 248.25 80.8346C248.25 71.6924 240.862 64.2812 231.75 64.2812C222.637 64.2812 215.25 71.6924 215.25 80.8346C215.25 89.9768 222.637 97.3879 231.75 97.3879Z"
        fill="#21A7E0"
      />
      <g clip-path="url(#clip1_870_157593)">
        <path
          d="M245.127 81.0312C245.127 88.2816 239.249 94.1591 231.999 94.1591C224.749 94.1591 218.871 88.2816 218.871 81.0312C218.871 73.7809 224.749 67.9033 231.999 67.9033C239.249 67.9033 245.127 73.7809 245.127 81.0312ZM220.834 81.0312C220.834 87.1976 225.833 92.1964 231.999 92.1964C238.165 92.1964 243.164 87.1976 243.164 81.0312C243.164 74.8649 238.165 69.8661 231.999 69.8661C225.833 69.8661 220.834 74.8649 220.834 81.0312Z"
          fill="white"
        />
        <path
          d="M239.143 76.7951L229.442 86.4955L224.996 82.0495L226.136 80.9097L229.442 84.2078L238.003 75.6553L239.143 76.7951Z"
          fill="white"
        />
      </g>
      <path
        d="M70.1199 8.32497C67.236 1.46241 59.8948 0.166346 59.8948 0.166346C59.8948 0.166346 52.8264 -1.73544 46.9233 7.32172C41.4212 15.7637 34.3258 23.6731 43.3241 27.0428L45.7011 22.0083L45.9862 27.8022C47.2947 28.077 48.6171 28.2794 49.9475 28.4084C59.9284 29.431 69.3369 31.1313 69.5112 27.4936C69.7428 22.6577 72.8948 14.9281 70.1199 8.32497Z"
        fill="#2F2E41"
      />
      <path
        d="M39.6235 63.3174L38.9971 79.6922C38.9971 79.6922 32.4199 92.8563 37.7443 92.5352C43.0686 92.2141 42.4422 79.6922 42.4422 79.6922L44.3214 64.2806L39.6235 63.3174Z"
        fill="#B88989"
      />
      <path
        d="M76.4152 60.3965L78.3485 76.7143C78.3485 76.7143 84.1484 85.3532 79.6374 88.2328C75.1263 91.1124 75.1263 77.9942 75.1263 77.9942L70.293 61.3564L76.4152 60.3965Z"
        fill="#B88989"
      />
      <path
        d="M70.2962 90.4441L64.5246 113.261C64.5246 113.261 61.9595 122.388 58.4324 126.951L57.1498 129.559L52.0195 128.907L55.5466 114.239L57.7911 87.1846L70.2962 90.4441Z"
        fill="#B88989"
      />
      <path
        opacity="0.1"
        d="M70.2962 90.4441L64.5246 113.261C64.5246 113.261 61.9595 122.388 58.4324 126.951L57.1498 129.559L52.0195 128.907L55.5466 114.239L57.7911 87.1846L70.2962 90.4441Z"
        fill="black"
      />
      <path
        d="M50.1129 125.175L58.7498 127.226L55.1902 135.832C55.1902 135.832 56.637 140.984 55.2589 143.713C53.8807 146.441 54.0796 151.392 54.0796 151.392L52.2078 151.089C52.2078 151.089 47.6631 159.864 42.2933 157.357C36.9235 154.85 42.6349 148.887 42.6349 148.887C42.6349 148.887 47.2779 139.472 47.8675 135.633C48.4571 131.793 50.1129 125.175 50.1129 125.175Z"
        fill="#2F2E41"
      />
      <path
        opacity="0.1"
        d="M50.1129 125.175L58.7498 127.226L55.1902 135.832C55.1902 135.832 56.637 140.984 55.2589 143.713C53.8807 146.441 54.0796 151.392 54.0796 151.392L52.2078 151.089C52.2078 151.089 47.6631 159.864 42.2933 157.357C36.9235 154.85 42.6349 148.887 42.6349 148.887C42.6349 148.887 47.2779 139.472 47.8675 135.633C48.4571 131.793 50.1129 125.175 50.1129 125.175Z"
        fill="black"
      />
      <path
        d="M42.8789 86.21L47.6703 110.408C47.6703 110.408 46.0732 122.345 47.9897 129.766L49.9063 136.864H56.2948V110.73C56.2948 110.73 56.6143 89.4363 55.656 87.8231C54.6977 86.21 42.8789 86.21 42.8789 86.21Z"
        fill="#B88989"
      />
      <path
        d="M57.9426 133.942L49.1328 134.588L51.3353 143.629C51.3353 143.629 49.1328 148.472 50.0767 151.377C51.0206 154.283 50.0767 159.126 50.0767 159.126H51.9645C51.9645 159.126 55.1109 168.49 60.7743 166.875C66.4377 165.261 61.7182 158.481 61.7182 158.481C61.7182 158.481 58.5719 148.472 58.5719 144.597C58.5719 140.723 57.9426 133.942 57.9426 133.942Z"
        fill="#2F2E41"
      />
      <path
        d="M59.7121 22.4062C64.4934 22.4062 68.3694 18.4811 68.3694 13.6391C68.3694 8.79722 64.4934 4.87207 59.7121 4.87207C54.9307 4.87207 51.0547 8.79722 51.0547 13.6391C51.0547 18.4811 54.9307 22.4062 59.7121 22.4062Z"
        fill="#B88989"
      />
      <path
        d="M53.9577 17.0479C53.9577 17.0479 54.5853 28.2708 52.7026 28.9309C50.8199 29.5911 62.43 28.9309 62.43 28.9309C62.43 28.9309 60.861 21.9991 62.43 20.0186C63.9989 18.0381 53.9577 17.0479 53.9577 17.0479Z"
        fill="#B88989"
      />
      <path
        d="M63.337 27.0738C63.337 27.0738 54.9494 26.431 53.3364 27.0738C51.7234 27.7166 47.207 30.9309 47.207 30.9309L49.4652 51.1805L67.8534 55.0375L70.7568 50.8591L73.6601 44.752L72.3697 37.0379L72.0472 32.538L63.337 27.0738Z"
        fill="#D7E2E7"
      />
      <path
        d="M45.4248 32.1465C45.4248 32.1465 41.379 33.1206 41.0678 36.3677C40.7566 39.6147 40.7566 47.7324 41.0678 49.0312C41.379 50.33 41.0678 50.9795 40.7566 51.3042C40.4454 51.6289 39.8229 50.9795 40.4454 51.9536C41.0678 52.9277 41.0678 52.9277 40.7566 53.9018C40.4454 54.8759 39.5117 53.5771 40.1341 55.2006C40.7566 56.8242 41.0678 57.1489 40.7566 57.4736C40.4454 57.7983 39.5117 65.2665 39.5117 65.2665L44.8023 66.2407L50.093 47.083L45.4248 32.1465Z"
        fill="#D7E2E7"
      />
      <path
        d="M70.0588 32.1465C71.2589 32.2155 72.3281 32.9497 72.8515 34.0643C73.7824 35.9821 76.2648 48.4478 75.9545 49.4067C75.6442 50.3656 75.0236 49.087 75.6442 51.0048C76.2648 52.9226 75.6442 52.603 75.9545 53.8815C76.2648 55.1601 74.7133 53.8815 75.9545 55.16C77.1957 56.4386 77.8163 56.7582 77.1957 57.3975C76.5751 58.0367 75.6442 56.7582 76.5751 58.0367C77.506 59.3153 77.506 61.2331 77.506 61.2331L70.0588 62.8312L67.8867 44.9318L70.0588 32.1465Z"
        fill="#D7E2E7"
      />
      <path
        d="M45.8543 30.829C46.176 30.6669 46.5486 30.6411 46.8893 30.7572C47.23 30.8733 47.5106 31.1217 47.6686 31.4472L54.9021 46.3492L62.9182 51.5177L68.6898 48.9335C68.6898 48.9335 72.8582 42.4729 71.5756 39.2427C70.706 37.0525 69.6873 33.6828 69.1214 31.7398C69.0535 31.5055 69.1384 31.2534 69.3338 31.1091C69.5292 30.9648 69.7934 30.9592 69.9947 31.095L71.0876 31.829C71.4374 32.064 71.6813 32.4283 71.7664 32.843L74.1407 44.4111L70.9343 51.1947L72.6978 67.7021L74.1407 119.804C74.1407 119.804 63.4793 124.92 55.1426 123.304C46.8059 121.689 39.9922 119.804 39.9922 119.804C39.9922 119.804 43.0383 58.3012 47.5273 53.4558C47.5273 53.4558 47.5273 49.9025 48.1686 49.5795C48.8099 49.2565 49.4512 46.9953 49.4512 46.9953L43.6431 33.3735C43.3612 32.7123 43.6401 31.9443 44.2791 31.6224L45.8543 30.829Z"
        fill="#7B7B7B"
      />
      <path
        d="M68.0219 6.23675L60.7569 2.43457L50.7241 3.99005L48.6484 13.1499L53.8154 12.9514L55.259 9.58604V12.896L57.6432 12.8043L59.0271 7.44662L59.8919 13.1499L68.368 12.9771L68.0219 6.23675Z"
        fill="#2F2E41"
      />
      <path
        d="M51.0557 15.0998C51.5869 15.0998 52.0176 14.3366 52.0176 13.3951C52.0176 12.4537 51.5869 11.6904 51.0557 11.6904C50.5244 11.6904 50.0938 12.4537 50.0938 13.3951C50.0938 14.3366 50.5244 15.0998 51.0557 15.0998Z"
        fill="#B88989"
      />
    </g>
    <defs>
      <clipPath id="clip0_870_157593">
        <rect
          width="286.65"
          height="181.075"
          fill="white"
          transform="translate(0.675781)"
        />
      </clipPath>
      <clipPath id="clip1_870_157593">
        <rect
          width="26.2558"
          height="26.2558"
          fill="white"
          transform="translate(218.871 67.9033)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'UploadSuccessIcon',
};
</script>
