<template>
  <div class="upload-container">
    <v-btn :disabled="uploadInprogress" class="close-btn" icon @click="close">
      <v-icon dark v-text="`mdi-close`" />
    </v-btn>
    <h1 class="manage-heading">
      {{ $t('overnightPresentations.uploadLocalFiles') }}
    </h1>
    <div class="rating-cont">
      <v-data-table
        :headers="listHeaders"
        :items="files"
        class="user-panel"
        hide-default-footer
        :no-results-text="$t('build.step1.storylineFieldNoResult')"
      >
        <template v-slot:[`item.name`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                {{ item.fileName ? item.fileName : item.name }}
              </div>
            </template>
            <span>{{ item.fileName ? item.fileName : item.name }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <div v-if="item.progress === 100 && item.processingCompleted">
            {{ $t('overnightPresentations.fileUploaded') }}
          </div>
          <div
            v-else-if="
              (item.progress !== 100 &&
                item.progress !== 0 &&
                item.progress !== 'failed') ||
              !item.processingCompleted
            "
          >
            {{ $t('overnightPresentations.processing') }}
          </div>
          <div v-else-if="item.progress === 0 || item.sizeError || item.error">
            {{ item.status }}
          </div>
        </template>
        <template v-slot:[`item.progress`]="{ item }">
          <div
            class="success"
            v-if="item.progress === 100 && item.processingCompleted"
          >
            <v-icon :color="'#21a7e0'">{{ 'mdi-check-circle' }}</v-icon>
          </div>
          <div
            v-else-if="item.progress === 0 && !item.sizeError && !item.error"
          >
            {{ $t('overnightPresentations.pending') }}
          </div>
          <div
            class="error"
            v-else-if="item.progress === 'failed' || item.sizeError === true"
          >
            <v-icon :color="'rgb(211 48 47)'">{{ 'mdi-close-circle' }}</v-icon>
          </div>
          <v-progress-circular
            :value="item.progress"
            :color="'#21a7e0'"
            background-color="#f2f2f2"
            :width="3"
            :size="20"
            v-else
          />
        </template>
      </v-data-table>
    </div>
    <div
      class="errorTextContainer"
      v-if="
        files &&
        files.length > 0 &&
        files.filter(
          (fl) => fl.error === true || fl.sizeError === true || fl.error,
        ).length > 0 &&
        !uploadInprogress
      "
    >
      {{ getErrorMessage(files) }}
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import {
  uploadOPFileBatchChunk,
  uploadOPFile,
} from '../../../utils/api-helper';
import { removeSpecialCharacters } from '../../utils';

export default {
  name: 'Rating',
  data() {
    return {
      listHeaders: [
        {
          text: 'File name',
          value: 'name',
          sortable: false,
        },
        {
          text: 'Uploading status',
          value: 'status',
          sortable: false,
        },
        {
          text: 'Status',
          value: 'progress',
          sortable: false,
        },
      ],
      files: [],
      uploadInprogress: false,
    };
  },
  props: {
    filess: {
      type: Array,
      default: () => [],
    },
    updateLocalFiles: {
      type: Function,
      default: () => {},
    },
    source: {
      default: 'op',
    },
  },
  computed: {
    ...mapState('users', ['currentUser']),
  },
  watch: {
    files: {
      handler(val) {
        if (val && val.length > 0) {
          let filesUploaded = 0;
          // let errorOccured = false;
          val.every((file) => {
            // if (file.sizeError) {
            //   errorOccured = true;
            //   return false;
            // }
            if (file.progress === 100 || file.processingCompleted === true) {
              filesUploaded++;
            }
            return true;
          });
          // if (errorOccured) {
          //   setTimeout(() => {
          //     this.updateLocalFiles([...val]);
          //     this.close();
          //   }, 1000);
          // } else
          if (filesUploaded === this.filess.length && !this.uploadInprogress) {
            setTimeout(() => {
              this.close();
            }, 3000);
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    getErrorMessage(files) {
      const genericErrors = [...files].filter((fl) => fl.error === true).length;
      const sizeErrors = [...files].filter(
        (fl) => fl.sizeError === true,
      ).length;
      let result = '';
      if (genericErrors === 0 && sizeErrors === 0) {
        result = '';
      } else if (genericErrors > 0 && sizeErrors === 0) {
        result = 'Failed to upload file.';
      } else if (genericErrors === 0 && sizeErrors > 0) {
        result = 'One or more file size exceeds the maximum limit.';
      } else if (genericErrors > 0 && sizeErrors > 0) {
        result =
          'Oops! One or more of your files are above the file size limit or failed to upload.';
      }
      return result;
    },
    getOutputPath() {
      const dt = new Date();
      const outputPath = `${this.currentUser.company.id}/op/${
        dt.getFullYear() +
        String(dt.getMonth() + 1).padStart(2, '0') +
        String(dt.getDate()).padStart(2, '0')
      }/${dt.getHours()}${dt.getMinutes()}${dt.getSeconds()}`;
      return outputPath;
    },
    close() {
      this.$modal.hide('upload');
    },
    uploadChunks(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = async () => {
          const chunkSize = 3 * 1024 ** 2;
          const chunks = [];
          let chunkIndex = 0;
          for (
            let start = 0;
            start < reader.result.length;
            start += chunkSize
          ) {
            const chunk = reader.result.slice(start, start + chunkSize);
            chunks.push({
              chunk,
              chunkIndex,
              requestIdentifier: file.requestIdentifier,
              filename: file.name,
            });
            chunkIndex++;
          }
          const chunkBatch = chunks.reduce((resultArray, item, index) => {
            const chunkIdx = Math.floor(index / 5);

            if (!resultArray[chunkIdx]) {
              resultArray[chunkIdx] = []; // start a new chunk
            }

            resultArray[chunkIdx].push(item);

            return resultArray;
          }, []);
          try {
            const response = await uploadOPFileBatchChunk(
              chunkBatch,
              chunkIndex,
              (prog) => {
                console.log(prog);
                file.progress = prog;
              },
              this.source,
            );
            resolve(response);
          } catch (err) {
            reject(err);
          }
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(file.file);
      });
    },
  },
  async mounted() {
    this.files = this.filess.map((i) => ({
      file: i,
      name: i.name,
      status: 'Processing',
      progress: 0,
      path: `${this.getOutputPath()}/${removeSpecialCharacters(i.name)}`,
      processingCompleted: false,
      requestIdentifier: uuidv4(),
    }));
    this.uploadInprogress = true;
    const resp = await Promise.all(
      this.files.map(async (file) => {
        if (file.file.size / 1024 ** 2 > 200) {
          file.status = 'Failed to upload';
          file.progress = 'failed';
          file.sizeError = true;
          file.processingCompleted = true;
        } else {
          try {
            await this.uploadChunks(file);
            await uploadOPFile(file.requestIdentifier, file.name, this.source)
              .then((res) => {
                file = {
                  ...file,
                  ...res.data,
                  processingCompleted: true,
                  size: file.file.size,
                };
              })
              .catch((err) => {
                file.processingCompleted = true;
                file.error = true;
                file.progress = 'failed';
                console.log(err);
              });
          } catch (err) {
            file.processingCompleted = true;
            file.error = true;
            file.progress = 'failed';
            console.log(err);
          }
          // await Storage.put(`${file.path}`, file.file, {
          //   bucket: process.env.VUE_APP_EXTERNAL_SERVICE_BUCKET,
          //   progressCallback: (progress) => {
          //     file.progress = (progress.loaded / progress.total) * 100;
          //     // console.log('Progress', (progress.loaded / progress.total) * 100);
          //   },
          //   errorCallback: () => {
          //     file.status = 'Failed to upload';
          //     file.error = true;
          //     file.processingCompleted = true;
          //     file.progress = 'failed';
          //     // console.log(err);
          //   },
          // })
          //   .then((rs) => {
          //     file.processingCompleted = true;
          //     console.log(rs);
          //   })
          //   .catch((err) => {
          //     file.processingCompleted = true;
          //     file.error = true;
          //     file.progress = 'failed';
          //     console.log(err);
          //   });
        }
        return file;
      }),
    );
    this.uploadInprogress = false;
    if (resp) {
      this.files = resp.map((file) => ({
        fileName: file.file.name,
        type: 's3',
        progress: file.progress,
        s3Bucket: file.s3Bucket,
        s3Prefix: file.s3Prefix,
        sizeError: file.sizeError,
        status: file.status,
        processingCompleted: file.processingCompleted,
        size: file.size,
        error: file.error,
      }));
    }
    this.updateLocalFiles(
      [...this.files].filter((fl) => !fl.sizeError && !fl.error),
    );
  },
};
</script>

<style lang="scss" scoped>
// @import '@/scss/variables.scss', '@/scss/app.scss';

.upload-container {
  padding: 30px 20px;
  // background-color: white;
  .manage-heading {
    letter-spacing: normal;
    font-family: 'Lato';
    font-size: 24px;
  }
  .close-btn {
    position: absolute;
    top: 0px;
    right: 0px;
    margin: 10px 5px;
  }
  .rating-cont {
    margin-top: 30px;
    width: 100%;
    ::v-deep .v-data-table {
      width: 100%;
      .v-data-table__wrapper {
        width: 100%;
        table {
          width: 100%;
          thead {
            width: 100%;
            display: block;
            tr {
              display: table;
              width: 100%;
              th:first-child {
                text-align: left !important;
                min-width: 280px;
                max-width: 280px;
                padding: 0px 16px 0px 0px;
                span {
                  font-size: 18px;
                  letter-spacing: normal;
                  color: black;
                }
              }
              th {
                width: 40%;
                text-align: left;
                span {
                  font-size: 18px;
                  letter-spacing: normal;
                  color: black;
                }
              }
            }
          }
          tbody {
            width: 100%;
            display: block;
            max-height: 240px;
            overflow: auto;
            tr {
              width: 100%;
              display: table;
              .text-start:first-child {
                min-width: 280px;
                max-width: 280px;
                border: none !important;
                padding: 0px 16px 0px 0px;
                div {
                  width: 100%;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
              .text-start {
                width: 40%;
                border: none !important;
              }
              .text-start:last-child {
                text-align: left;
                border: none !important;
              }
            }
          }
        }
      }
    }
    .success {
      color: green;
    }
    .error {
      color: red;
    }
  }
  .errorTextContainer {
    color: #d3302f;
    margin: 16px 0px 0px 0px;
  }
}
</style>
