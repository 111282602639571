var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"heart_modal_container"},[_c('v-btn',{staticClass:"close-btn",attrs:{"icon":""},on:{"click":_vm.close}},[_c('v-icon',{attrs:{"dark":""},domProps:{"textContent":_vm._s("mdi-close")}})],1),_c('div',{staticClass:"first-container"},[_c('v-img',{staticClass:"logo-with_heart__img",attrs:{"src":'/assets/img/heart/logo_with_heart.svg',"alt":""}}),_c('p',{staticClass:"help-message"},[_vm._v(" "+_vm._s(_vm.$t('build.step3.helpMessage'))+" ")])],1),_c('h4',{staticClass:"partner_heading"},[_vm._v(_vm._s(_vm.$t('build.step3.givingPartner')))]),_c('div',{staticClass:"second-container"},[_c('v-img',{staticClass:"no-kid-hungry__img",attrs:{"src":'/assets/img/heart/no_kid_hungry.svg',"alt":""}}),_c('div',{staticClass:"redirecting_box"},[_c('a',{staticClass:"redirecting_link",attrs:{"href":"https://www.prezent.ai/giving","target":"_blank"},on:{"click":_vm.onVisitSite}},[_vm._v(" "+_vm._s(_vm.$t('build.step3.visitSite'))+" "),_c('v-img',{staticClass:"external_link_logo",attrs:{"src":'/assets/img/heart/visit-external-site.svg',"alt":""}})],1)])],1),(_vm.contributors.length === 0 && _vm.origin !== 'FingerprintTest')?_c('div',{staticClass:"donation_user__image"},[_vm._v(" "+_vm._s(Number(50 + _vm.totalCount).toLocaleString())+" "+_vm._s(_vm.$t('build.step3.otherMembersHelped'))+" ")]):_vm._e(),(_vm.contributors.length > 0 && _vm.origin !== 'FingerprintTest')?_c('div',{staticClass:"user-donation-section"},[_c('div',{staticClass:"user-donation-section--list"},_vm._l((_vm.contributors),function(contributor,index){return _c('v-avatar',{key:index,class:index >= 1
            ? 'user-donation-section--item'
            : 'user-donation-section--item__border',attrs:{"size":"56","color":contributor.profileImage === null || contributor.profileImage === ''
            ? 'blue'
            : 'blue'}},[(
            contributor.profileImage === null ||
            contributor.profileImage === ''
          )?_c('v-tooltip',{attrs:{"bottom":"","max-width":"200","content-class":"tooltip-content","fixed":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"white--text text-h5",staticStyle:{"color":"white","font-size":"27px","font-weight":"250"}},'span',attrs,false),on),[_vm._v(" "+_vm._s(("" + (contributor.firstName .charAt(0) .toUpperCase()) + (contributor.lastName .charAt(0) .toUpperCase())))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(((contributor.firstName) + " " + (contributor.lastName))))])]):_c('v-tooltip',{attrs:{"bottom":"","max-width":"200","content-class":"tooltip-content","fixed":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({attrs:{"src":contributor.profileImageUrl,"contain":"","alt":""}},'v-img',attrs,false),on))]}}],null,true)},[_c('div',{style:({
              padding: '6px',
            })},[_c('span',{staticClass:"userWithPicNames"},[_vm._v(" "+_vm._s(((contributor.firstName) + " " + (contributor.lastName)))+" ")])])])],1)}),1),_c('p',{staticClass:"user-donation-section--user-count"},[_vm._v(" +"+_vm._s(Number(50 + _vm.totalCount - _vm.contributors.length).toLocaleString())+" "+_vm._s(_vm.$t('build.step3.otherMembersHelped'))+" ")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }