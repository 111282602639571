<template>
  <!-- <PrezentationDetailComponent
    ref="prezentationDetailNew"
    :origin="$route.params.origin"
  /> -->
  <PrezentationDetails
    ref="prezentationDetails"
    :origin="$route.params.origin"
  />
</template>

<script>
// import PrezentationDetailComponent from '@/components/common/PrezentationDetailNew';
import PrezentationDetails from '../PrezentationDetails/components/PrezentationDetails.vue';

export default {
  name: 'PrezentationDetail',
  props: {
    title: {
      type: String,
      default: ' ',
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs.prezentationDetails) {
      this.$refs.prezentationDetails?.backButton('other-page', next);
    } else {
      next();
    }
  },
  components: {
    // PrezentationDetailComponent,
    PrezentationDetails,
  },
};
</script>
