<template>
  <div class="request-access-container">
    <v-btn class="close-btn" icon @click="onClose">
      <v-icon :size="16" dark v-text="`mdi-close`" />
    </v-btn>
    <img
      class="get-in-touch-img"
      :src="'/assets/img/overnight-prezentations/get_in_touch.svg'"
    />
    <span class="desc">{{ text }}</span>
    <span>{{ $t('overnightPresentations.thankYou') }}</span>
  </div>
</template>
<script>
export default {
  name: 'ThankYouModal',
  components: {},
  props: {
    text: {
      type: String,
      default:
        'We sent your Overnight Prezentations access request to your admin.',
    },
  },
  data() {
    return {};
  },
  methods: {
    onClose() {
      this.$modal.hide('ThankYouModal');
    },
  },
};
</script>
<style lang="scss" scoped>
.request-access-container {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  padding: 24px;
  gap: 8px;

  .close-btn {
    position: absolute;
    right: 5px;
    top: 5px;
  }

  .get-in-touch-img {
    width: 274px;
    margin-bottom: 20px;
  }

  span {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    /* or 21px */
    display: flex;
    align-items: center;
    text-align: center;
  }

  .desc {
    margin-bottom: 20px;
  }
}
</style>
