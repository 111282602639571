<template>
  <div class="t-filter">
    <v-menu
      v-model="dateMenu"
      :close-on-content-click="false"
      :close-on-click="true"
      transition="scale-transition"
      offset-y
      v-if="timeline.value === 'range'"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="dateRangeText"
          readonly
          v-bind="attrs"
          v-on="on"
          @click="handleDateTextFieldClick($event)"
          class="t-filter__range"
          hide-details
        >
          <template #prepend>
            <v-icon v-bind="attrs" v-on="on" class="calendarIcon">
              mdi-calendar
            </v-icon>
          </template>
        </v-text-field>
      </template>
      <v-date-picker
        v-model="dates"
        :allowedDates="allowedValidDates"
        no-title
        scrollable
        color="#20a7e0"
        range
        hide-details
      />
      <div class="ctaButtons">
        <div class="btnsContainer">
          <v-btn @click="handleDatePickerClose">{{
            $t('common.cancel')
          }}</v-btn>
          <v-btn @click="applyDateRange" :disabled="datesApplyDisabled">
            {{ $t('profile.overnightServiceStats.apply') }}
          </v-btn>
        </div>
      </div>
    </v-menu>
    <v-combobox
      class="t-filter__timeline"
      :items="translatedTimelineOptions"
      v-model="timeline"
      hide-details
      item-text="display_text"
      item-value="value"
    ></v-combobox>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import { trackProfileEvents } from '../../../common/Analytics/ProfileEvents';
import {
  TD_PLACEMENT,
  TD_COMMON_COLUMN_NAME,
  TD_COMMON_COLUMN_VALUE,
  TD_FILTER_SELECTED,
  TD_CUSTOM,
  TD_IMPACT,
  TD_USAGE_STATISTICS,
} from '../../../common/Analytics/MatomoTrackingDataHelper';

export default {
  name: 'TimelineFilter',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Object,
      default: () => ({ from: null, to: null }),
    },
    origin: {
      type: String,
      default: '',
    },
  },
  data() {
    const timelineOptions = [
      {
        text: 'Cumulative',
        display_text: this.$t('storyline.cumulative'),
        value: 'cumulative',
      },
      {
        text: 'Last 90 days',
        display_text: this.$t('storyline.lastNinetyDays'),
        value: '90-days',
      },
      {
        text: 'Custom Date Range',
        display_text: this.$t('storyline.customDateRange'),
        value: 'range',
      },
    ];
    return {
      timelineOptions,
      dates: [],
      dateRangeText: '',
      dateMenu: false,
      timeline: timelineOptions[0],
    };
  },
  computed: {
    ...mapState('users', ['currentUser']),
    datesApplyDisabled() {
      return this.dates.length < 2;
    },
    translatedTimelineOptions() {
      return this.timelineOptions.map((option) => ({
        ...option,
        text: this.$t(option.display_text),
      }));
    },
  },
  watch: {
    timeline({ value }) {
      if (value === '90-days') {
        const to = moment();
        const from = moment().subtract(90, 'days');
        this.$emit('change', {
          from: from.format('YYYY-MM-DD 00:00:00'),
          to: to.format('YYYY-MM-DD 23:59:59'),
        });
        trackProfileEvents.profileMetricsDateFilterUsed(this.currentUser, {
          [TD_PLACEMENT]:
            this.origin === 'ImpactMetrics' ? TD_IMPACT : TD_USAGE_STATISTICS,
          [TD_COMMON_COLUMN_NAME]: TD_FILTER_SELECTED,
          [TD_COMMON_COLUMN_VALUE]: value,
        });
      }
      if (value === 'cumulative') {
        this.$emit('change', {
          from: null,
          to: null,
        });
        trackProfileEvents.profileMetricsDateFilterUsed(this.currentUser, {
          [TD_PLACEMENT]:
            this.origin === 'ImpactMetrics' ? TD_IMPACT : TD_USAGE_STATISTICS,
          [TD_COMMON_COLUMN_NAME]: TD_FILTER_SELECTED,
          [TD_COMMON_COLUMN_VALUE]: value,
        });
      }
    },
  },
  methods: {
    allowedValidDates(val) {
      return (
        moment(val).isSameOrAfter(moment('2023-01-01', 'YYYY-MM-DD')) &&
        moment(val).isSameOrBefore(moment())
      );
    },
    handleDateTextFieldClick() {
      this.dates = this.dateRangeText.split(' - ');
    },
    async applyDateRange() {
      this.dateRangeText = this.dates.sort().join(' - ');
      this.dateMenu = false;
      this.teamStatsSearch = '';
      const from = moment(this.dates[0], 'YYYY-MM-DD').format(
        'YYYY-MM-DD 00:00:00',
      );
      const to = moment(this.dates[1], 'YYYY-MM-DD').format(
        'YYYY-MM-DD 23:59:59',
      );
      this.$emit('change', {
        from,
        to,
      });
      trackProfileEvents.profileMetricsDateFilterUsed(this.currentUser, {
        [TD_PLACEMENT]:
          this.origin === 'ImpactMetrics' ? TD_IMPACT : TD_USAGE_STATISTICS,
        [TD_COMMON_COLUMN_NAME]: TD_FILTER_SELECTED,
        [TD_COMMON_COLUMN_VALUE]: TD_CUSTOM,
      });
    },
    handleDatePickerClose() {
      this.dateMenu = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.t-filter {
  font-weight: normal;
  display: flex;
  gap: 20px;

  &__range {
    width: 250px;
  }
  &__timeline {
    width: 190px;
  }
  .v-input {
    margin-top: 0;
    padding-top: 0;
  }
}
.v-menu__content {
  .ctaButtons {
    display: flex;
    justify-content: flex-end;
    padding: 0px 16px;
    padding: 0px 16px 24px 16px;
    background: #fff;
    .btnsContainer {
      width: 60%;
      display: flex;
      justify-content: space-between;
      .v-btn {
        box-shadow: none;
        text-transform: none;
        letter-spacing: normal;
        color: #20a7e0;
        background: #fff;
      }
      .v-btn--disabled {
        background-color: #fff !important;
      }
    }
  }
}
</style>
