<template>
  <div class="right-panel-context">
    <div class="heading">Change request</div>
    <div class="change-request-detail">
      <template v-if="addChangeRequest">
        <div class="add-change-container">
          <AddRequestV2
            @onDataSubmit="manageSubmitData"
            :updating="updating"
            :opRequest="opRequest"
            :viewType="viewType"
          />
        </div>
      </template>
      <template v-else>
        <div class="new-change-request">
          <div class="image-container">
            <img
              :src="'/assets/img/overnight-prezentations/op-detail/new-cr.svg'"
            />
          </div>
          <div class="main-title">We have received your request.</div>
          <div class="sub-title">
            Our team will reach out to you with updates via email. Also, you can
            review the status of your request under My requests.
          </div>
          <div class="btn-container">
            <v-btn
              outlined
              rounded
              color="primary"
              class="new-req-btn"
              @click="submitNewRequest"
            >
              {{ 'Submit new Change request' }}
            </v-btn>

            <v-btn
              elevation="2"
              rounded
              color="primary"
              class="my-req-btn"
              @click="goToRequests"
            >
              {{ 'Go to My Requests' }}
            </v-btn>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
// import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import { mapActions, mapState } from 'vuex';
import AddRequestV2 from './AddRequestV2.vue';
import {
  submitOPRequest,
  getOPManageFiles,
  postFeatureUsage,
  getFeatureUsage,
} from '../../../../../../utils/api-helper';
import {
  TD_LIST,
  TD_MOSAIC,
  TD_AUD,
  TD_PREZNAME,
  TD_PREZTYPE,
  TD_VIEWTYPE,
  TD_PLACEMENT,
  TD_EXPANDED_DETAIL_VIEW,
  TD_EXPANDED_STATUS_VIEW,
  TD_PREZENTATION_CATEGORY,
} from '../../../../../common/Analytics/MatomoTrackingDataHelper';
import { trackOvernightPrezentationsEvents } from '../../../../../common/Analytics/OvernightPrezentationsEvents';

export default {
  name: 'ChangeRequests',
  data() {
    return {
      addChangeRequest: true,
      updating: false,
      preferences: {
        imagePreferences: [
          {
            name: 'Incorporate images',
            img: '/assets/img/overnight-prezentations/preferences/imageIllustraion1.svg',
            value: 'HAS_IMAGE',
          },
          {
            name: 'Avoid images',
            img: '/assets/img/overnight-prezentations/preferences/imageIllustrations2.svg',
            value: 'NO_IMAGE',
          },
        ],
        colorPreferences: [
          {
            name: 'Use more color',
            img: '/assets/img/overnight-prezentations/preferences/colorPreference1.svg',
            value: 'HAS_COLOR',
          },
          {
            name: 'Use less color',
            img: '/assets/img/overnight-prezentations/preferences/colorPreference2.svg',
            value: 'NO_COLOR',
          },
        ],
        illustrationPreferences: [
          {
            name: 'Include illustrations',
            img: '/assets/img/overnight-prezentations/preferences/illustraionsPreference1.svg',
            value: 'HAS_ILLUSTRATION',
          },
          {
            name: 'Skip illustrations',
            img: '/assets/img/overnight-prezentations/preferences/illustrationPreference2.svg',
            value: 'NO_ILLUSTRATION',
          },
        ],
        dataPreferences: [
          {
            name: 'Prefers charts',
            img: '/assets/img/overnight-prezentations/preferences/dataPreferance1.svg',
            value: 'HAS_DATA',
          },
          {
            name: 'Prefers tables',
            img: '/assets/img/overnight-prezentations/preferences/dataPreference2.svg',
            value: 'NO_DATA',
          },
        ],
      },
    };
  },
  computed: {
    ...mapState('users', ['currentUser', 'limitsInfoObj']),
  },
  props: {
    opRequest: {
      type: Object,
      default: () => ({}),
    },
    reqOrigin: {
      type: String,
      default: '',
    },
    viewType: {
      type: String,
      default: '',
    },
  },
  methods: {
    ...mapActions('users', [
      'setFilteredThemes',
      'setOPTicketUnreadMessageData',
      'setLimitData',
      'setUpgradePopup',
    ]),
    submitNewRequest() {
      this.addChangeRequest = true;
      trackOvernightPrezentationsEvents.OPChangeRequestSubmitNewChangeRequest(
        this.currentUser,
        {
          [TD_PREZNAME]: this.opRequest.name,
          [TD_PREZTYPE]: this.opRequest.storyline,
          [TD_AUD]: this.opRequest?.audienceEmail,
          [TD_VIEWTYPE]: this.viewType === 'list' ? TD_LIST : TD_MOSAIC,
          [TD_PREZENTATION_CATEGORY]: this.opRequest.status,
          [TD_PLACEMENT]:
            this.opRequest?.status === 'Completed' &&
            this.opRequest?.opSlideData === null
              ? TD_EXPANDED_DETAIL_VIEW
              : TD_EXPANDED_STATUS_VIEW,
        },
      );
    },
    goToRequests() {
      trackOvernightPrezentationsEvents.OPChangeRequestGoToMyRequests(
        this.currentUser,
        {
          [TD_PREZNAME]: this.opRequest.name,
          [TD_PREZTYPE]: this.opRequest.storyline,
          [TD_AUD]: this.opRequest?.audienceEmail,
          [TD_VIEWTYPE]: this.viewType === 'list' ? TD_LIST : TD_MOSAIC,
          [TD_PREZENTATION_CATEGORY]: this.opRequest.status,
          [TD_PLACEMENT]:
            this.opRequest?.status === 'Completed' &&
            this.opRequest?.opSlideData === null
              ? TD_EXPANDED_DETAIL_VIEW
              : TD_EXPANDED_STATUS_VIEW,
        },
      );
      this.$router
        .push({
          path: `/home/overnight-prezentations?tab=myRequest`,
        })
        .catch(() => {});
      // if (this.reqOrigin === 'myRequests') {
      //   EventBus.$emit('REFRESH_MY_OP_REQUESTS');
      // }
    },
    async manageSubmitData(data) {
      console.log(data);
      await getFeatureUsage()
        .then((response) => {
          if (response?.data) {
            this.setLimitData(response.data);
          }
        })
        .catch((error) => console.log(error));
      if (
        !this.limitsInfoObj?.op?.isLimitCrossed ||
        this.currentUser.user.role.toLowerCase() !== 'trialuser'
      ) {
        postFeatureUsage({ feature: 'op', ticketId: this.opRequest.ticketId })
          .then((response) => {
            console.log(response);
            if (response?.data) {
              this.setLimitData(response.data);
            }
          })
          .catch((error) => console.log(error));

        this.updating = true;
        const opPreferences = {
          audience: {},
          selected: {},
        };
        if (this.opRequest.personalPreference) {
          const prefs = this.opRequest.personalPreference;
          // constructing preferences from array
          for (let i = 0; i < prefs.length; i++) {
            switch (prefs[i][0]) {
              case 'Image':
                opPreferences.audience.imagePreferences =
                  this.get_prefs_mapping(prefs[i][1]);
                // opPreferences.selected.imagePreferences = this.get_prefs_mapping(
                //   prefs[i][2],
                // );
                break;
              case 'Color':
                opPreferences.audience.colorPreferences =
                  this.get_prefs_mapping(prefs[i][1]);
                // opPreferences.selected.colorPreferences = this.get_prefs_mapping(
                //   prefs[i][2],
                // );
                break;
              case 'Illustration':
                opPreferences.audience.illustrationPreferences =
                  this.get_prefs_mapping(prefs[i][1]);
                // opPreferences.selected.illustrationPreferences =
                //   this.get_prefs_mapping(prefs[i][2]);
                break;
              case 'Data visualisation':
                opPreferences.audience.dataPreferences = this.get_prefs_mapping(
                  prefs[i][1],
                );
                // opPreferences.selected.dataPreferences = this.get_prefs_mapping(
                //   prefs[i][2],
                // );
                break;
              default:
                console.log('some mismatch');
                break;
            }
          }
        }
        this.instructions = data?.texts?.text || '';
        const body = {
          name: `${this.opRequest.name} - Change Request`,
          audienceId: this.opRequest.audienceId,
          audienceType: this.opRequest.audienceType,
          isCustomStoryline: this.opRequest.isCustomStoryline
            ? this.opRequest.isCustomStoryline
            : false,
          templateId: this.opRequest.templateId,
          personalPreference: this.opRequest.personalPreference
            ? [
                {
                  audience: opPreferences.audience,
                  selected: opPreferences.audience,
                },
              ]
            : undefined, // Omitting this payload property for email created op requests
          attachment: {
            files: [],
            links: [],
          },
          instructions:
            this.instructions && this.instructions.length > 0
              ? [this.instructions.replace(/\n/g, '<br />')]
              : [],
          htmlBody: `<div>Change request created for <a href="${this.opRequest.ticketId}">${this.opRequest.name}</a></div>`,
        };

        // if (this.sharedLinks) {
        //   body.attachment.links = this.sharedLinks
        //     .split('\n')
        //     .filter((ele) => ele !== '')
        //     .map((ele) => ({
        //       linkUrl: ele,
        //       isShared: true,
        //     }));
        // }
        if (data.links) {
          body.attachment.links = data?.links.map((ele) => ({
            linkUrl: ele.web_url,
            isShared: true,
          }));
        }
        if (this.opRequest.storyline) {
          body.storyline = this.opRequest.storyline;
        }
        if (data.files.length) {
          body.attachment.files = data.files;
        }

        // getting attachments and links of parent ticket
        await getOPManageFiles(this.opRequest.ticketId)
          .then((resp) => {
            // append links
            const opLinks = resp?.data?.link;
            if (opLinks) {
              body.attachment.links = [
                ...body.attachment.links,
                ...opLinks.map((lnk) => ({
                  linkUrl: lnk.linkUrl,
                  isShared: lnk.isLinkShared,
                })),
              ];
            }

            // append attachment files
            const attachments = resp?.data?.s3;
            if (attachments && attachments.length) {
              const opFiles = attachments
                .filter((attch) => attch.type === 's3')
                .map((fl) => ({
                  fileName: fl.fileName,
                  type: 's3',
                  progress: 100,
                  s3Bucket: fl.s3Bucket,
                  s3Prefix: fl.s3Prefix,
                }));
              const existingFiles = [...body.attachment.files];
              console.log('existingFiles', existingFiles);
              console.log('opFiles', opFiles);
              body.attachment.files = [...existingFiles, ...opFiles];
            }

            // adding template info if own template was chosen for parent ticket
            if (!body.templateId) {
              const templateAttachment = attachments.find(
                (attchmnt) => attchmnt.type === 's3-template',
              );
              body.attachment.template = {
                fileName: templateAttachment.fileName,
                type: 's3-template',
                s3Bucket: templateAttachment.s3Bucket,
                s3Prefix: templateAttachment.s3Prefix,
              };
            }
          })
          .catch((err) => {
            console.log(err);
            // body.attachment.template = {};
          });

        console.log(body);
        await submitOPRequest(body)
          .then((resp) => {
            console.log(resp);
          })
          .catch((err) => {
            console.log(err);
          });
        this.updating = false;
        this.addChangeRequest = false;
        // this.isReqSubmitted = true;

        // EventBus.$emit('RE-RENDER-MY-REQUESTS');
      } else {
        this.setUpgradePopup({ upgradePopup: true, popupType: 'op-slide' });
      }
    },
    get_prefs_mapping(pref) {
      const preference = {
        'Incorporate images': 'HAS_IMAGE',
        'Avoid images': 'NO_IMAGE',
        'Use more color': 'HAS_COLOR',
        'Use less color': 'NO_COLOR',
        'Include illustrations': 'HAS_ILLUSTRATION',
        'Skip illustrations': 'NO_ILLUSTRATION',
        'Prefers charts': 'HAS_DATA',
        'Prefers tables': 'NO_DATA',
      };
      return preference[pref];
    },
  },
  components: {
    // ClipLoader,
    AddRequestV2,
  },
};
</script>

<style lang="scss" scoped>
.right-panel-context {
  .heading {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    margin-top: 4px;
  }
  height: 100%;
  .change-request-detail {
    height: 100%;
    .add-change-container {
      height: 100%;
    }
  }
  .new-change-request {
    .image-container {
      margin-top: 10px;
    }
    .main-title {
      margin-top: 20px;
      font-family: Lato;
      font-size: 14px;
      font-weight: 400;
      line-height: 20.8px;
      text-align: left;
    }
    .sub-title {
      margin-top: 20px;
      font-family: Lato;
      font-size: 14px;
      font-weight: 400;
      line-height: 20.8px;
      text-align: left;
    }
    .btn-container {
      display: flex;
      margin-top: 20px;
      .new-req-btn {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        letter-spacing: normal;
        text-transform: none !important;
        margin-right: 10px;
        background-color: white;
        line-height: 19.2px;
        text-align: left;
      }
      .my-req-btn {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        letter-spacing: normal;
        text-transform: none !important;
        line-height: 19.2px;
        text-align: left;
      }
    }
  }
}
</style>
