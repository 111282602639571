<template>
  <div class="change-password-wrapper">
    <v-btn class="close-btn" icon @click="handleClose">
      <v-icon dark v-text="`mdi-close`" />
    </v-btn>
    <v-form
      class="change-password-form"
      ref="forgotPasswordForm"
      @submit.prevent="onSubmitUsername"
    >
      <div class="headline">
        {{ $t('profile.changePasswordDetail.passwordReset') }}
      </div>
      <div class="sub-title">
        {{ $t('profile.changePasswordDetail.verificationCode') }}
      </div>
      <div class="user-name">
        <div class="label">
          {{ $t('profile.changePasswordDetail.userName') }}
        </div>
        <v-text-field
          disabled
          class="user-name-text"
          placeholder=""
          v-model="username"
          id="username-field"
        />
      </div>
      <div class="mb-2 actions">
        <v-btn
          rounded
          type="submit"
          color="#21a7e0"
          min-width="333"
          height="50"
          :loading="status.isSendingResetLink"
          :disabled="!username"
          class="mb-4 email-btn"
        >
          {{ $t('profile.changePasswordDetail.emailVerificationCode') }}
        </v-btn>
        <!--      <div class="back-to-login">
          <router-link to="/signin">Back to Login</router-link>
        </div> -->
      </div>
      <div v-if="error !== ''" class="error">
        {{ error }}
      </div>
    </v-form>
  </div>
</template>

<script>
import { Auth } from 'aws-amplify';
import ResetPasswordDetail from './ResetPasswordDetail.vue';
import { checkSSOUser } from '../../../utils/api-helper';
import { trackProfileEvents } from '../../common/Analytics/ProfileEvents';

export default {
  name: 'ChangePasswordDetail',
  props: {
    username: {
      type: String,
      required: true,
    },
    currentUser: {
      type: Object,
    },
  },
  data() {
    return {
      valid: true,
      error: '',
      isSent: false,
      status: {
        isSendingResetLink: false,
      },
    };
  },
  methods: {
    handleClose() {
      this.$modal.hide('ChangePasswordDetail');
    },
    handleResetPassword() {
      this.$modal.show(
        ResetPasswordDetail,
        {
          userName: this.username,
        },
        {
          name: 'ResetPasswordDetail',
          width: '720px',
          height: '782px',
        },
      );
    },
    async onSubmitUsername() {
      if (!this.$refs.forgotPasswordForm.validate()) return;

      this.$set(this.status, 'isSendingResetLink', true);
      trackProfileEvents.profileBasicChangePasswordEmailcode(this.currentUser);

      this.username = `${this.username.trim().toLowerCase()}`;
      // Check if the user is sso user and display error, else continue
      const user = await checkSSOUser(this.username);
      if (user.data.ssoUser) {
        this.error = 'Please update corporate password for SSO enabled id';
        this.isSendingResetLink = false;
      } else {
        Auth.forgotPassword(this.username)
          .then((data) => {
            console.log(data);
            this.error = '';
            this.handleClose();
            this.handleResetPassword();
          })
          .catch((err) => {
            console.error(err);
            this.error = 'Please enter a valid email id';
          });
      }

      this.$set(this.status, 'isSendingResetLink', false);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.change-password-wrapper {
  width: 100%;
  height: 100%;

  .close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;
  }

  .change-password-form {
    width: 100%;
    height: 100%;
    padding: 77px 94px 64px 94px;

    .headline {
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      margin-bottom: 16px;
    }

    .sub-title {
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      margin-bottom: 38px;
    }

    .user-name {
      .label {
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
      }

      .user-name-text {
        margin-bottom: 38px;
      }

      ::v-deep input {
        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;

        &::placeholder {
          color: #d8d8d8;
        }
      }
      ::v-deep label {
        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #d8d8d8;
        height: fit-content;
      }
    }

    button {
      color: white;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      text-transform: capitalize;
      margin-bottom: 16px;
    }

    .back-to-login {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;

      a {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #21a7e0;
      }
    }

    .error {
      text-align: center;
    }

    .email-btn {
      letter-spacing: normal;
      font-size: 20px;
      font-family: 'Lato', sans-serif;
    }
  }
}
</style>
