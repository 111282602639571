<template>
  <div class="op-slides-list-view">
    <div class="list-wrap">
      <div class="list-items">
        <div
          class="op-slide-container"
          v-for="(listItem, itemIndex) in listItems"
          :key="itemIndex"
        >
          <div v-if="itemIndex > 0" class="op-slide-divider"></div>
          <div
            class="op-slide-item"
            :class="`${itemIndex === currentItem ? 'selected' : ''}`"
          >
            <span class="serial-number">{{ itemIndex + 1 }}</span>
            <v-img
              :src="listItem.thumbnail"
              alt
              lazy-src="/assets/img/slides/placeholder-slide.svg"
              :aspect-ratio="16 / 9"
              @click="handleSlideItemClick(listItem, itemIndex)"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <clip-loader :color="`#21a7e0`" :width="20" :height="20" />
                </v-row>
              </template>
            </v-img>
          </div>
        </div>
      </div>
    </div>
    <div class="current-item-panel">
      <div class="img-container">
        <v-img :src="getSlideSource" alt min-width="300" :aspect-ratio="16 / 9">
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <clip-loader :color="`#21a7e0`" :width="20" :height="20" />
            </v-row>
          </template>
        </v-img>
      </div>
    </div>
  </div>
</template>

<script>
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';

export default {
  name: 'ListView',
  props: {
    prezData: {
      type: Object,
      required: true,
    },
    currentIndex: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  components: {
    ClipLoader,
  },
  data() {
    return {
      listItems: [],
      currentItem: 0,
    };
  },
  computed: {
    getSlideSource() {
      return (
        this.listItems &&
        this.listItems[this.currentItem] &&
        this.listItems[this.currentItem].thumbnail
      );
    },
  },
  methods: {
    onClick() {},
    handleSlideItemClick(item, index) {
      this.currentItem = index;
    },
  },
  mounted() {
    if (this.prezData.opSlideData) {
      this.listItems = this.prezData.opSlideData.slides;
      this.currentItem = this.currentIndex;
    }
  },
};
</script>

<style lang="scss" scoped>
.op-slides-list-view {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 24px;
  margin-top: 20px;
  .list-wrap {
    height: 539px;
    .list-items {
      max-width: 250px;
      width: 250px;
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 0px 34px 0px 22px;
      position: relative;

      .op-slide-container {
        .op-slide-divider {
          padding: 10px 0;
        }
        .op-slide-item {
          cursor: pointer;
          border-radius: 8px;
          box-shadow: 1px 1px 4px rgb(0 0 0 / 10%),
            -1px -1px 4px rgb(0 0 0 / 10%);
          overflow: hidden;
          width: 100%;
          &.selected {
            border: 2px solid $zen-blue-default;
          }

          &:last-child {
            margin-bottom: 15px;
          }
          &:hover {
            box-shadow: 2px 2px 8px rgb(0 0 0 / 30%),
              -1px -1px 4px rgb(0 0 0 / 30%);
          }
          .serial-number {
            margin-left: -20px;
            z-index: 10;
            position: absolute;
          }
        }
      }
    }
  }
  .current-item-panel {
    flex-grow: 3.5;
    padding-bottom: 60px;
    padding-left: 20px;
    width: 100%;
    .img-container {
      border-radius: 8px;
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1),
        -1px -1px 4px rgba(0, 0, 0, 0.1);
      margin-bottom: 30px;
    }
  }
}
</style>
