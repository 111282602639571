<template>
  <div class="replace-icon-container" v-if="!isLoading">
    <div v-if="iconsList.length">
      <div class="replace-visual-verbose">
        {{ $t('prezentation.selectIconText') }}
      </div>
      <div
        :class="`select-image my-1 ${
          isConvertingInProgress ? 'item-disabled' : ''
        } `"
      >
        <v-slide-group show-arrows="always">
          <v-slide-item
            v-for="item in iconsList"
            :key="item.replace_ref"
            v-slot="{ toggle }"
            :class="`${
              item.imageIndex === selectedIcon.imageIndex ? 'selected' : ''
            }`"
          >
            <div>
              <v-img
                v-if="!item.invalid"
                class="select-image__item ma-2"
                max-height="80"
                max-width="100"
                height="80"
                contain
                :src="item.imgUrl"
                @click="onIconSelected(item, toggle)"
                lazy-src="/assets/img/slides/placeholder-slide.svg"
                :disabled="isConvertingInProgress"
              ></v-img>
            </div>
          </v-slide-item>
        </v-slide-group>
      </div>
      <IconLibrary
        @iconSelected="onReplaceIcon($event)"
        :currentSelectedSlideIconData="selectedIcon"
        :isConvertingInProgress="isConvertingInProgress"
        :key="iconLibraryComponentKey"
        @onIconSearch="onIconSearch"
        :slideData="selectedSlide"
      />
    </div>
    <div v-else class="no-image">
      <img
        :src="'/assets/img/generate/slide-with-no-visuals.svg'"
        alt="image"
        contain
      />
      <div class="desc">
        <div>{{ $t('generate.currentSlideHaveNoVisuals') }}</div>
        <div v-if="isShellTypeSlide">
          {{ $t('generate.toAddVisualUseDifferentLayout') }}
        </div>
      </div>
      <v-btn
        elevation="2"
        rounded
        color="primary"
        class="primary-btn"
        @click="changeLayout"
        v-if="isShellTypeSlide"
      >
        {{ $t('generate.changeLayout') }}
      </v-btn>
    </div>
  </div>
  <div v-else class="spinner text-center loading-spinner_library">
    <v-progress-circular
      :size="50"
      :width="2"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { mapState, mapActions, mapGetters } from 'vuex';
import { getFileURLDownload } from '@/utils/calyrex-test';
// import { getFileURL } from '@/utils/calyrex';
import {
  // convertToPDFForIcons,
  extractIcons,
  replaceIcon,
  getSvgOfIcon,
} from '../../../../../utils/replace-image-api-helper';
// import { convertPdfToImage } from '../../../../../utils/pdf-to-png';
import { convertPPTToPng } from '../../../../../utils/merge-slide-helper';
import { calyrexConfig } from '../../../../../runtimeConfig';
import IconLibrary from '../../../SlideIconReplacement/IconLibrary.vue';

export default {
  name: 'PrezentationReplaceIcon',
  components: {
    IconLibrary,
  },
  watch: {
    selectedSlide(val) {
      if (val) {
        this.fetchReplaceIcons();
      }
    },
  },
  props: {
    origin: {
      type: String,
      default: '',
    },
    selectedSlide: {},
    updateReplaceIcon: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      iconLibraryComponentKey: 1,
      selectedIcon: null,
      iconToReplace: null,
      convertedIcon: null,
      iconsList: [],
      iconDetails: [],
      isLoading: true,
      newInput: {},
      downloadUrl: '',
      isConvertingInProgress: false,
      savedSlidePath: '',
      savedImagePath: '',
      illustrationList: [
        {
          image: '/assets/img/slideImageReplacement/analyzing-content.svg',
          text: 'Analyzing slide content...',
          timeout: 0,
        },
        {
          image:
            '/assets/img/slideImageReplacement/resizing-cleaning-image.svg',
          text: 'Customizing the icon...',
          timeout: 1500,
        },
        {
          image: '/assets/img/slideImageReplacement/finalizing-slide.svg',
          text: 'Finalizing slide design...',
          timeout: 1000,
        },
      ],
    };
  },
  computed: {
    ...mapState('users', ['currentUser']),
    ...mapState('prezentationDetails', [
      'prezentationData',
      'selectedSectionIndex',
      'selectedSlideIndex',
      'actionCompletedInfo',
      'slideActions',
    ]),
    ...mapGetters('prezentationDetails', ['isBestPractices']),
    // TODO: Update flag when new premium features are added to prez
    isPremiumFeature() {
      return (
        this.selectedSlide?.isUserUploaded ||
        this.selectedSlide?.isUploaded ||
        this.selectedSlide?.isGenerated ||
        this.selectedSlide?.isRedesign ||
        this.selectedSlide?.isAnonymized ||
        this.selectedSlide?.isSynthesis ||
        this.selectedSlide?.source === 'generated' ||
        this.selectedSlide?.source === 'uploaded' ||
        this.selectedSlide?.source === 'comply'
      );
    },
    isShellTypeSlide() {
      let changeLayoutAction = false;
      if (this.slideActions.length) {
        changeLayoutAction = !!this.slideActions.find(
          (action) => action.id === 'changeLayout',
        );
      }
      if (
        this.selectedSlide.isSynthesis ||
        this.selectedSlide.source === 'synthesis'
      ) {
        return (
          this.actionCompletedInfo?.synthesisCompleted &&
          this.selectedSlideIndex === 0 &&
          changeLayoutAction
        );
      }
      return changeLayoutAction;
    },
    getS3Path() {
      const { replacedSlideData, filename, file } = this.selectedSlide;
      // if (this.selectedSlide.isIconReplaced) {
      //   console.log('s3 path 1');
      //   return this.selectedSlide.filename || this.selectedSlide.file;
      // }
      if (
        this.selectedSlide?.prefs?.source === 'uploaded' ||
        this.isPremiumFeature
      ) {
        return this.selectedSlide.filename;
      }
      return replacedSlideData?.s3_path || filename || file;
    },
  },
  async beforeMount() {
    this.fetchReplaceIcons();
  },
  methods: {
    ...mapActions('prezentationDetails', [
      'setSelectedSlideAction',
      'setLoadingIllustrations',
      'updateActionCompletedInfo',
    ]),
    changeLayout() {
      this.setSelectedSlideAction({
        id: 'changeLayout',
        labelText: 'Change layout',
        tooltipText: 'Change layout',
        icon: 'mdi-view-split-vertical',
        isCustomIcon: false,
      });
    },
    onIconSelected(slideIcon, toggle) {
      toggle();
      this.setCurrentSelectedSlideIconData(slideIcon);
      this.iconLibraryComponentKey++;
    },

    onIconSearch(data) {
      console.log(data, 'search');
    },
    getSlideCategoryS3Bucket() {
      // if (this.selectedSlide?.isIconReplaced) {
      //   console.log('s3 bucket 1');
      //   return calyrexConfig.calyrex_bucket_name;
      // }

      if (
        this.selectedSlide?.prefs?.source === 'uploaded' ||
        this.selectedSlide?.filename?.indexOf('public/') !== -1 ||
        this.selectedSlide?.filename?.indexOf('private/') !== -1 ||
        this.selectedSlide?.filename?.indexOf('protected/') !== -1
      ) {
        return process.env.VUE_APP_MVP_ASSETS_BUCKET;
      }
      if (this.isPremiumFeature) {
        let premiumFeatures3Bucket = process.env.VUE_APP_MVP_ASSETS_BUCKET;
        if (
          this.selectedSlide?.filename?.startsWith('newImageReplaceOutput') ||
          this.selectedSlide?.filename?.startsWith('/newImageReplaceOutput')
        ) {
          premiumFeatures3Bucket = calyrexConfig.calyrex_bucket_name;
        }
        return premiumFeatures3Bucket;
      }

      if (
        this.selectedSlide?.prefs?.source === 'generated' ||
        this.selectedSlide?.prefs?.source === 'synthesis' ||
        this.selectedSlide?.prefs?.source === 'redesign' ||
        this.selectedSlide?.prefs?.source === 'comply'
      ) {
        if (
          this.selectedSlide?.landscape?.startsWith(
            '/newImageReplaceOutput/output/',
          ) ||
          this.selectedSlide?.landscape?.startsWith('/output/pptx/')
        ) {
          return calyrexConfig.calyrex_bucket_name;
        }
        return process.env.VUE_APP_MVP_ASSETS_BUCKET;
      }

      const { replacedSlideData, filename } = this.selectedSlide;
      if (
        !replacedSlideData &&
        filename.startsWith('/newImageReplaceOutput/output/')
      ) {
        return calyrexConfig.calyrex_bucket_name;
      }
      return replacedSlideData?.s3_bucket || calyrexConfig.slides_bucket_name;
    },
    fetchReplaceIcons() {
      this.isLoading = true;
      const body = {
        s3_bucket: this.getSlideCategoryS3Bucket(),
        s3_path: this.getS3Path,
        force_update: false,
      };
      extractIcons(body)
        .then(async (res) => {
          if (res?.images) {
            const list = [];

            for (const key in res.images) {
              if (Object.hasOwnProperty.call(res.images, key)) {
                const image = res.images[key];
                const path = `${image.s3_path}`;

                const imgUrl = await getFileURLDownload(
                  this.currentUser.user.cognitoID,
                  path,
                  this.currentUser.userIp,
                );
                list.push({
                  ...image,
                  imgUrl,
                  imageName:
                    this.selectedSlide.outline ||
                    this.selectedSlide.category?.replaceAll('_', ' ') ||
                    '',
                });
              }
            }
            const icons = await this.getSvgOfAllIcons(list);

            this.iconsList = icons;
            // eslint-disable-next-line prefer-destructuring
            this.selectedIcon = list[0];
            this.setCurrentSelectedSlideIconData(this.selectedIcon);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    setCurrentSelectedSlideIconData(icon) {
      this.selectedIcon = {
        ...icon,
        iconName: this.selectedSlide.outline || this.selectedSlide.category,
        // resizeData: this.getResizeImageData(image),
      };
    },
    async onReplaceIcon(event) {
      this.setLoadingIllustrations({
        isLoading: true,
        illustrationList: this.illustrationList,
      });

      this.isConvertingInProgress = true;
      this.savedSlidePath = null;
      this.savedImagePath = null;
      this.outputFileName = `${uuidv4()}.pptx`;

      const { meta, shapeType, imageIndex } = this.selectedIcon;
      this.iconToReplace = event;

      let newImage = {
        imageIndex,
        s3_path: this.iconToReplace.s3_path,
        source: this.iconToReplace.source,
      };

      if (this.iconToReplace.source === 'flat-icons') {
        newImage = {
          imageIndex,
          source: this.iconToReplace.source,
          id: this.iconToReplace.id,
        };
      } else if (this.iconToReplace.source === 'upload') {
        newImage = {
          imageIndex,
          source: this.iconToReplace.source,
          image: this.iconToReplace.image,
          extension: this.iconToReplace.extension,
        };
      } else if (this.iconToReplace.source === 's3') {
        newImage = {
          imageIndex,
          source: this.iconToReplace.source,
          s3_path: `private${this.iconToReplace.s3_path}`,
          s3_bucket: process.env.VUE_APP_MVP_ASSETS_BUCKET,
        };
      }
      let s3Bucket = calyrexConfig.slides_bucket_name;
      const s3Path = this.newInput.s3_path
        ? this.newInput.s3_path
        : this.getS3Path;
      if (s3Path.indexOf('newImageReplaceOutput/') !== -1) {
        s3Bucket = calyrexConfig.calyrex_bucket_name;
      }

      if (
        this.selectedSlide?.prefs?.source === 'uploaded' ||
        this.selectedSlide?.filename?.indexOf('public/') !== -1 ||
        this.selectedSlide?.filename?.indexOf('private/') !== -1 ||
        this.selectedSlide?.filename?.indexOf('protected/') !== -1
      ) {
        if (s3Path.indexOf('newImageReplaceOutput/') !== -1) {
          s3Bucket = calyrexConfig.calyrex_bucket_name;
        }
        s3Bucket = process.env.VUE_APP_MVP_ASSETS_BUCKET;
      } else if (this.isPremiumFeature) {
        s3Bucket = process.env.VUE_APP_MVP_ASSETS_BUCKET;
        if (
          this.selectedSlide?.filename?.startsWith('newImageReplaceOutput') ||
          this.selectedSlide?.filename?.startsWith('/newImageReplaceOutput')
        ) {
          s3Bucket = calyrexConfig.calyrex_bucket_name;
        }
      } else if (
        this.selectedSlide?.prefs?.source === 'generated' ||
        this.selectedSlide?.prefs?.source === 'synthesis' ||
        this.selectedSlide?.prefs?.source === 'redesign' ||
        this.selectedSlide?.prefs?.source === 'comply'
      ) {
        if (
          this.selectedSlide?.landscape?.startsWith(
            '/newImageReplaceOutput/output/',
          ) ||
          this.selectedSlide?.landscape?.startsWith('/output/pptx/')
        ) {
          s3Bucket = calyrexConfig.calyrex_bucket_name;
        } else {
          s3Bucket = process.env.VUE_APP_MVP_ASSETS_BUCKET;
        }
      }

      // if (this.getIsDirty === true) {
      //   s3Bucket = calyrexConfig.calyrex_bucket_name;
      // }

      const body = {
        s3_bucket: s3Bucket,
        s3_path: s3Path,
        outputFormat: 'pptx',
        newImage,
        oldImage: { meta, shapeType },
        /*
          passing feature in replace_icon payload
          which triggered the replace icon in product
        */
        feature: this.isBestPractices ? 'BPL Deck' : 'Presentation',
      };

      // const [resPdf, response] = await Promise.all([
      //   convertToPDFForIcons({ ...body, outputFormat: 'pdf' }),
      //   replaceIcon(body),
      // ]);

      const response = await replaceIcon(body);

      if (response?.s3_path) {
        console.log('Deck successfuly converted', response);
        try {
          if (response.input) {
            this.payloadData = {
              ...body,
              newImage: { ...response.input, imageIndex },
            };
          }
          this.deckDetails = response;
          this.newInput = response;
          if (this.deckDetails) {
            // this.currentSelectedSlideIconData = null;

            this.iconsList = this.iconsList.map((icon) => {
              if (icon !== undefined && icon.imageIndex === imageIndex) {
                icon.imgUrl = this.iconToReplace.url;
                icon.meta = response?.meta;
                icon.shapeType = response?.shapeType;
                this.selectedIcon = {
                  iconName:
                    this.selectedSlide.outline || this.selectedSlide.category,
                  ...icon,
                };
              }

              return icon;
            });
          }

          // this.downloadUrl = await this.getSignedFileUrlFromCalyrex(
          //   response?.s3_path,
          // );
          this.savedSlidePath = response?.s3_path;

          // const convertedIcon = await this.getLandscapeImage(resPdf?.s3_path);

          this.savedImagePath = await convertPPTToPng(
            response.s3_bucket,
            response.s3_path,
          );

          const convertedIcon = this.savedImagePath;
          this.isConvertingInProgress = false;

          let slideIndex = 0;
          this.prezentationData?.sections?.list?.forEach((section, i) => {
            section.slides.forEach((slide, j) => {
              if (
                i < this.selectedSectionIndex ||
                (this.selectedSectionIndex === i && j < this.selectedSlideIndex)
              ) {
                slideIndex++;
              }
            });
          });

          this.updateActionCompletedInfo({
            ...this.actionCompletedInfo,
            isReplaceVisualsCompleted: true,
            replacedIcon: this.actionCompletedInfo?.replacedIcon
              ? [...this.actionCompletedInfo.replacedIcon, slideIndex]
              : [slideIndex],
          });

          this.updateReplaceIcon(
            {
              type: 'replace_icon',
            },
            {
              pptxPath: response?.s3_path,
              pdfPath: this.savedImagePath,
              replacedInfo: this.payloadData,
              convertedIcon,
            },
            () => {
              this.setLoadingIllustrations({
                isLoading: false,
                illustrationList: [],
              });
            },
          );
        } catch (error) {
          this.isConvertingInProgress = false;
          console.error(error);
          this.setLoadingIllustrations({
            isLoading: false,
            illustrationList: [],
          });
        }
      } else {
        console.error(response);
        this.isConvertingInProgress = false;
        this.setLoadingIllustrations({
          isLoading: false,
          illustrationList: [],
        });
      }
    },

    // async getLandscapeImage(outputFilename) {
    //   this.convertedIcon = await this.getSignedFileUrlFromCalyrex(
    //     outputFilename,
    //   );
    //   this.savedImagePath = outputFilename;
    //   this.convertedIcon = await convertPdfToImage(this.convertedIcon);
    //   return this.convertedIcon;
    // },

    async getSvgOfAllIcons(icons) {
      const svgPromises = icons.map(async (icn) => {
        const path = `${icn.s3_path}`;
        icn.imgUrl = null; // Need this to allow <v-img to work
        const imgUrl = await this.getSignedFileUrlFromCalyrex(path);
        icn.imgUrl = await getSvgOfIcon(imgUrl);
        return {
          ...icn,
        };
      });
      const results = await Promise.all(svgPromises);
      return results;
    },
    // Utility functions
    async getSignedFileUrlFromCalyrex(filePath) {
      if (filePath) {
        return await getFileURLDownload(
          this.currentUser.user.cognitoID,
          filePath.startsWith('/') ? filePath : `/${filePath}`,
          this.currentUser.userIp,
        );
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.replace-icon-container {
  margin: 10px 0;
}
.spinner {
  margin-top: 40px;
}
.replace-visual-verbose {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  padding-bottom: 20px;
}
.item-disabled {
  opacity: 0.5;
  pointer-events: none;
}
.no-image {
  display: flex;
  gap: 24px;
  align-items: center;
  height: calc(100% - 42px);
  flex-direction: column;
  margin-top: 36px;

  .desc {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #000000;
  }

  .primary-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 16px;
    gap: 8px;
    height: 32px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-transform: capitalize;
    letter-spacing: normal;
    line-height: 19px;

    /* Color Styles (Old)/White */

    color: #ffffff;

    background: #21a7e0;
    border-radius: 25px;
  }
}
.select-image {
  align-items: center;
  display: flex;
  padding-top: 2px;
  max-width: 100%;
  padding-bottom: 20px;

  .select-image__item {
    border: solid $light-gray 1px;
    border-radius: 8px;
    box-shadow: $item-box-shadow;
    min-width: 100px;

    &:hover {
      box-shadow: $item-box-shadow-hover;
      cursor: pointer;
    }
  }

  .selected {
    border: 2px solid $zen-blue-default;
  }
}
::v-deep .image-upload__container {
  .container {
    max-height: calc(100vh - 400px);
    overflow-y: auto;
    overflow-x: hidden;
  }
}
</style>
