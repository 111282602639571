<template>
  <div class="edit-best-practices">
    <div class="slide-detail">
      <div class="heading">
        {{ $t('build.step3.bestPracticeSwitchLabel') }}
      </div>
      <!-- <div class="sub">
        {{ $t('build.step3.poweredByZenSence') }}
      </div> -->
      <div class="sub-title">
        {{ $t('userUploadPrezentation.bestPracticeSubtext') }}
      </div>

      <div class="best-practice-body">
        <div
          v-for="(point, index) in getCurrentSlideBPList"
          :key="index"
          class="bestpractice-item"
        >
          <div class="label">#{{ index + 1 }}</div>
          <div class="value">{{ point.name }}</div>
        </div>
        <v-btn
          text
          @click="handleAddBestPractice"
          color="primary"
          :disabled="bestPracticeEditMode || bestPracticesItems.length >= 5"
        >
          <v-icon left> mdi-plus </v-icon>
          {{ $t('userUploadPrezentation.addBestPractice') }}
        </v-btn>
      </div>
      <!-- <v-checkbox
          :disabled="!isOwnerOfPrezentation && isAllBestPracticesAddedByAuthor"
          v-model="bestPracticesVisibleToOthers"
          :label="$t('userUploadPrezentation.bestPracticeVisibilityCheckbox')"
        ></v-checkbox> -->
      <div class="best-practice-actions">
        <v-btn
          outlined
          rounded
          color="primary"
          class="cancel-btn"
          @click="handleCloseBestPractice"
        >
          {{ $t('common.cancel') }}
        </v-btn>
        <v-btn
          :disabled="!isOwnerOfPrezentation && isAllBestPracticesAddedByAuthor"
          rounded
          color="primary"
          @click="handleAcceptBestPractice"
        >
          {{ $t('common.accept') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { updateBestPractices } from '../../../../../utils/api-helper';
import { trackPrezentationEvents } from '../../../../common/Analytics/PrezentationEvents';
import {
  TD_AUD,
  TD_PREZNAME,
  TD_PREZTYPE,
  TD_TEMPLATE,
  TD_COMMON_COLUMN_NAME,
  TD_COMMON_COLUMN_VALUE,
  TD_NO_OF_BESTPRACTICES_EDITED,
  TD_NO_OF_BESTPRACTICES_DELETED,
  TD_NO_OF_BESTPRACTICES_ADDED,
} from '../../../../common/Analytics/MatomoTrackingDataHelper';

export default {
  name: 'EditBestPractices',
  data() {
    return {
      showBestPractices: false,
      isBestPracticesVisible: false,
      bestPracticesItems: [],
      bestPracticesVisibleToOthers: true,
      bestPracticeEditMode: false,
      isAllBestPracticesAddedByAuthor: false,
      isOwnerOfPrezentation: false,
      slideData: {},
      selectedBestPractice: {},
      prezentationMetaData: {},
    };
  },
  watch: {
    selectedSlide(val) {
      if (val.assetId !== this.slideData.assetId) {
        this.slideData = val;
        this.setBestPractices();
      }
    },
    prezentationData(val) {
      this.prezentationMetaData = val;
    },
    currentSlideBPList(val) {
      this.bestPracticesItems = [...val];
    },
  },
  beforeDestroy() {
    if (this.selectedSlideAction?.id === 'editBestPractices') {
      this.setSelectedSlideAction({});
    }
  },
  props: {
    updatePrezentation: {
      type: Function,
      default: () => {},
    },
    origin: {
      type: String,
      required: false,
      default: 'Prezentation',
    },
  },
  computed: {
    ...mapState('prezentationDetails', [
      'prezentationData',
      'selectedSlide',
      'currentSlideBPList',
      'selectedSlideAction',
      'bestPracticesEditedCounter',
      'bestPracticesDeletedCounter',
      'bestPracticesAddedCounter',
    ]),
    ...mapState('users', ['currentUser']),

    ...mapGetters('prezentationDetails', ['getCurrentSlideBPList']),
  },
  methods: {
    ...mapActions('prezentationDetails', [
      'setCurrentSlideBPList',
      'setSelectedSlideAction',
      'setPrezentationData',
      'setBPToggleState',
      'setBpEditedCounter',
      'setBpDeletedCounter',
      'setBpAddedCounter',
    ]),
    handleAddBestPractice() {
      const count = this.bestPracticesAddedCounter + 1;
      this.setBpAddedCounter(count);
      const tempData = {
        x: 70,
        y: 0,
        xAxis: 500,
        yAxis: 0,
        name: '',
        id: this.bestPracticesItems.length,
        isAddedByAuthor: this.isOwnerOfPrezentation,
      };
      this.selectedBestPractice = tempData;
      this.bestPracticesItems.push(tempData);
      this.setCurrentSlideBPList(this.bestPracticesItems);
    },
    handleCloseBestPractice() {
      this.bestPracticeEditMode = false;
      this.setBestPractices();
      this.setSelectedSlideAction({});
    },
    setBestPractices() {
      this.bestPracticesItems = [];
      this.setCurrentSlideBPList(this.bestPracticesItems);
      if (this.prezentationMetaData?.bestPractices?.slideToolTips.length) {
        this.prezentationMetaData?.bestPractices?.slideToolTips.forEach(
          (slideToolTip) => {
            const slideAssetId = this.getFilePathWithoutPrependSlash(
              this.slideData.assetId,
            );
            const toolTipAssetId = this.getFilePathWithoutPrependSlash(
              slideToolTip.assetId,
            );
            if (slideAssetId === toolTipAssetId) {
              this.isBestPracticesVisible = slideToolTip.visible;
              if (slideToolTip?.tips.length && slideToolTip?.visible) {
                this.showBestPractices = true;
              }
              this.bestPracticesItems = [];
              this.setCurrentSlideBPList(this.bestPracticesItems);
              let toolTipCount = 0;
              slideToolTip.tips.forEach((tip, i) => {
                if (
                  !tip.isAddedByAuthor &&
                  this.prezentationMetaData.permission_level === 3
                ) {
                  this.showBestPractices = true;
                }
                const coordinates = tip[`pro_tip${i}_pos`].split(',');
                this.bestPracticesItems.push({
                  name: tip[`pro_tip${i}`],
                  x: parseFloat(coordinates[0]),
                  y: parseFloat(coordinates[1]),
                  isAddedByAuthor: tip.isAddedByAuthor,
                });
                this.setCurrentSlideBPList(this.bestPracticesItems);
                if (tip?.isAddedByAuthor) {
                  toolTipCount++;
                }
                if (toolTipCount === 5) {
                  this.isAllBestPracticesAddedByAuthor = true;
                }
              });
            }
          },
        );
      } else {
        this.bestPracticesItems = [];
        this.setCurrentSlideBPList(this.bestPracticesItems);
      }
    },

    getFilePathWithoutPrependSlash(filename) {
      return filename
        ?.split('/')
        .filter((t) => t)
        .join('/');
    },
    handleAcceptBestPractice() {
      const otherData = {
        [TD_AUD]: this.prezentationData.audienceID,
        [TD_PREZNAME]: this.prezentationData.name,
        [TD_PREZTYPE]: this.prezentationData.type,
        [TD_TEMPLATE]: this.prezentationData.theme,
      };
      if (this.bestPracticesEditedCounter) {
        const extraData = {
          ...otherData,
          [TD_COMMON_COLUMN_NAME]: TD_NO_OF_BESTPRACTICES_EDITED,
          [TD_COMMON_COLUMN_VALUE]: this.bestPracticesEditedCounter,
        };
        trackPrezentationEvents.prezentationsBestPracticesEdited(
          this.currentUser,
          extraData,
        );
        this.setBpEditedCounter(0);
      }
      if (this.bestPracticesDeletedCounter) {
        const extraData = {
          ...otherData,
          [TD_COMMON_COLUMN_NAME]: TD_NO_OF_BESTPRACTICES_DELETED,
          [TD_COMMON_COLUMN_VALUE]: this.bestPracticesDeletedCounter,
        };
        trackPrezentationEvents.prezentationsBestPracticesDeleted(
          this.currentUser,
          extraData,
        );
        this.setBpDeletedCounter(0);
      }
      if (this.bestPracticesAddedCounter) {
        const extraData = {
          ...otherData,
          [TD_COMMON_COLUMN_NAME]: TD_NO_OF_BESTPRACTICES_ADDED,
          [TD_COMMON_COLUMN_VALUE]: this.bestPracticesAddedCounter,
        };
        trackPrezentationEvents.prezentationsBestPracticesAdded(
          this.currentUser,
          extraData,
        );
        this.setBpAddedCounter(0);
      }

      this.bestPracticesItems = this.getCurrentSlideBPList.filter(
        (item) => item.name,
      );
      const slideToolTips = [];
      if (this.prezentationMetaData?.bestPractices?.slideToolTips.length) {
        this.prezentationMetaData?.bestPractices?.slideToolTips.forEach(
          (slideToolTip) => {
            if (this.slideData.assetId !== slideToolTip.assetId) {
              slideToolTips.push({
                ...slideToolTip,
              });
            }
          },
        );
      }
      slideToolTips.push({
        assetId: this.slideData.assetId,
        visible: this.bestPracticesVisibleToOthers,
        tips: this.bestPracticesItems.map((item, i) => ({
          [`pro_tip${i}`]: item.name,
          [`pro_tip${i}_pos`]: `${item.x},${item.y}`,
          isAddedByAuthor: item.isAddedByAuthor,
        })),
      });
      const payload = {
        id: this.prezentationMetaData.id,
        slideToolTips,
      };

      updateBestPractices(payload)
        .then((res) => {
          this.bestPracticeEditMode = false;
          this.setSelectedSlideAction({});
          if (this.isOwnerOfPrezentation) {
            this.updatePrezentation({
              ...this.prezentationMetaData,
              isBestPracticesEnabled: true,
              bestPractices: res?.data?.bestPrectices[0]?.bestPractices,
            });
            this.setBPToggleState(true);
          } else {
            this.updatePrezentation({
              ...this.prezentationMetaData,
              bestPractices: res?.data?.bestPrectices[0]?.bestPractices,
            });
          }
        })
        .catch((error) => console.log(error))
        .finally(() => {});
    },
  },
  mounted() {
    this.prezentationMetaData = this.prezentationData;
    this.isOwnerOfPrezentation =
      this.currentUser.user.id === this.prezentationMetaData.ownerID;
    this.slideData = this.selectedSlide;
    this.setBestPractices();
  },
};
</script>

<style lang="scss" scoped>
.edit-best-practices {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  .heading {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
  }
  .slide-detail {
    height: 100%;
  }

  .sub {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.02em;
    color: #697077;
    margin-top: 4px;
    margin-left: 2px;
  }

  .sub-title {
    padding-top: 19px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 2px;

    .v-input--selection-controls {
      margin-top: 0px;
      padding-top: 0px;
    }
  }

  .best-practice-body {
    height: calc(100% - 200px);
    margin: 20px 0;

    .v-btn {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: normal;
      text-transform: none !important;
      margin-right: 20px;
      margin-top: 28px;
      margin-left: 7px;
    }

    .bestpractice-item {
      margin: 10px 0;
      .label {
        color: #7b7b7b;
        margin-bottom: 10px;
      }

      .value,
      .label {
        font-family: Lato;
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
  }

  .best-practice-item {
    .bp-number {
      width: 20px;
      height: 20px;
      background-color: orange;
      color: white;
    }
  }

  .best-practice-actions {
    .v-btn {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: normal;
      text-transform: none !important;
      margin-right: 20px;
    }
    .cancel-btn {
      background-color: white;
    }
  }
}
</style>
