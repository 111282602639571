<template>
  <div class="novice-quiz-wrapper">
    <div v-if="!isLoading">
      <v-btn
        class="novice-quiz-wrapper__back-btn"
        @click="handleBack"
        icon
        color="#21a7e0"
      >
        <v-icon>mdi-arrow-left</v-icon>
        {{ $t('common.back') }}
      </v-btn>
      <div class="quiz-title">{{ $t('learn.novice.quiz') }}</div>
      <div class="quiz-items">
        <div
          class="quiz-item"
          v-for="(item, index) in currentQuizQuestions"
          :key="index"
        >
          <div class="quiz-item-title">{{ index + 1 }}. {{ item.title }}</div>
          <div class="quiz-item-content">
            <v-radio-group v-model="item.userAnswer" row>
              <div
                class="quiz-option"
                v-for="(option, optionIndex) in item.options"
                :key="optionIndex"
              >
                <v-radio
                  class="quiz-checkbox"
                  :label="`${option.text}`"
                  color="primary"
                  :value="option"
                  @change="optionSelectionFn(option)"
                ></v-radio>
                <img class="main-img" :src="option.illustration" alt="image" />
                <div class="quiz-option-check-wrapper">
                  <div
                    v-if="option == item.userAnswer && option.displayFeedback"
                  >
                    <div class="check-wrong" v-if="!option.isAnswer">
                      <div class="check-title">
                        <img
                          src="/assets/img/learn/practice-highlight.svg"
                          alt
                        />
                        <div class="check-title-content">
                          {{ option.congratulation_message }}
                        </div>
                      </div>
                      <div class="check-description">
                        {{ option.feedback }}
                      </div>
                    </div>
                    <div class="check-correct" v-if="option.isAnswer">
                      <div class="check-title">
                        <img
                          src="/assets/img/learn/practice-well-done.png"
                          width="36px"
                          height="36px"
                          alt
                        />
                        <div class="check-title-content">
                          {{ option.congratulation_message }}
                        </div>
                      </div>
                      <div class="check-description">
                        {{ option.feedback }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-radio-group>
          </div>
        </div>
      </div>
      <div class="quiz-check-answers">
        <v-btn
          class="try-again-button"
          rounded
          width="296"
          height="50"
          color="#21a7e0"
          @click="handleCheckAnswers()"
          :disabled="isCheckButtonDisable()"
          :loading="checkingAllCorrectAnswers"
          v-if="!showKeepLeaning"
        >
          {{ $t('learn.novice.checkAnswers') }}
        </v-btn>
        <v-btn
          class="try-again-button"
          rounded
          width="296"
          height="50"
          color="#21a7e0"
          @click="routeToCoursePage()"
          v-else
        >
          {{ $t('learn.novice.keepLearning') }}
        </v-btn>
      </div>
    </div>
    <div v-else class="spinner-wrapper">
      <LoadSpinner
        :id="'spinner'"
        :text="$t('learn.movieDialog.loading')"
        :size="'60'"
        :bgcolor="'lightgray'"
        :speed="'0'"
        :color="'gray'"
        class="spinner"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import learnApi from '../../../../API/learn-api';
import { getFileURL } from '@/utils/calyrex';
import LoadSpinner from '../../../common/LoadSpinner.vue';
import { MatomoAnalyticsHandler } from '../../../common/Analytics/MatomoAnalyticsHandler';
import { trackLearnEvents } from '../../../common/Analytics/LearnEvents';

export default {
  name: 'Quiz',
  components: {
    LoadSpinner,
  },
  data() {
    return {
      isLoading: true,
      badgeIssued: false,
      currentItem: null,
      currentQuiz: null,
      currentQuizQuestions: [],
      learnData: null,
      levelInfo: null,
      isAllChecked: false,
      result: null,
      checkCount: 0,
      showKeepLeaning: false,
      displayFeedback: false,
      checkingAllCorrectAnswers: false,
    };
  },
  computed: {
    ...mapState('users', ['currentUser']),
    ...mapState('learndata', ['courseInfoForMatotmo']),
  },
  methods: {
    async handleCheckAnswers() {
      MatomoAnalyticsHandler.trackQuizAnswersCheck(
        this.courseInfoForMatotmo,
        this.currentUser.user,
      );
      trackLearnEvents.learnQuizCheckAnswer(
        this.currentUser,
        this.courseInfoForMatotmo,
      );
      if (this.checkCount === 0) this.checkCount = 1;
      if (this.checkForCorrectness()) {
        const updateStatus = {
          asset_id: this.$route.params.assetId,
          status: 100,
        };
        this.checkingAllCorrectAnswers = true;
        await learnApi.methods.updateAssetUserStatus(updateStatus).then(
          (resp) => {
            if (resp) {
              this.showKeepLeaning = true;
              MatomoAnalyticsHandler.learnCourseModuleProgress(
                this.courseInfoForMatotmo,
                this.currentUser,
                '100%',
              );
            }
          },
          (error) => {
            console.log('error while updating the asset status --', error);
            this.showKeepLeaning = false;
          },
        );
        this.checkingAllCorrectAnswers = false;
      }
      if (this.checkCount === 1) {
        this.currentQuizQuestions.forEach((questionInfo) =>
          questionInfo.options.forEach((option) => {
            option.displayFeedback = true;
          }),
        );
      }
      this.$forceUpdate();
    },

    routeToCoursePage() {
      this.$router.push({
        name: 'course.detail',
        params: { id: this.$route.params.courseId, showBadge: true },
        query: {
          showBadge: true,
        },
      });
    },

    isCheckButtonDisable() {
      let isDisable = true;
      if (this.currentQuizQuestions) {
        isDisable = !this.allQuestionsAnswered();
      }
      return isDisable;
    },

    allQuestionsAnswered() {
      let attemptCount = 0;
      this.currentQuizQuestions.forEach((question) => {
        if (question.userAnswer) attemptCount += 1;
      });
      return attemptCount === this.currentQuizQuestions.length;
    },

    checkForCorrectness() {
      let correctnessCount = 0;
      this.currentQuizQuestions.forEach((question) => {
        if (question.userAnswer.isAnswer) correctnessCount += 1;
      });
      return correctnessCount === this.currentQuizQuestions.length;
    },

    goToInitial() {
      this.$router.back();
    },
    handleBack() {
      this.$router.push({
        name: 'course.detail',
        params: { id: this.$route.params.courseId },
      });
    },

    optionSelectionFn(option) {
      if (option.displayFeedback) {
        option.displayFeedback = false;
      }
    },

    sortQuizOptions(x, y) {
      if (x.option.toLowerCase() < y.option.toLowerCase()) {
        return -1;
      }
      if (x.option.toLowerCase() > y.option.toLowerCase()) {
        return 1;
      }
      return 0;
    },
  },
  async mounted() {
    MatomoAnalyticsHandler.learnCourseModuleProgress(
      this.courseInfoForMatotmo,
      this.currentUser,
      this.courseInfoForMatotmo.moduleStatus,
    );
    try {
      await learnApi.methods
        .getAssetInfo(this.$route.params.assetId)
        .then((resp) => {
          if (resp) {
            this.currentQuiz = resp;
            this.currentQuizQuestions = this.currentQuiz.asset;
            this.currentQuizQuestions.forEach((question) => {
              question.options.sort(this.sortQuizOptions).map(async (qInfo) => {
                qInfo.displayFeedback = false;
                await getFileURL(
                  this.currentUser.user.cognitoID,
                  qInfo.illustration,
                  this.currentUser.userIp,
                ).then((signedImageUrl) => {
                  qInfo.illustration = signedImageUrl;
                  qInfo.disabled = false;
                });
              });
            });
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
      this.$forceUpdate();
    } catch (err) {
      console.log(err);
    }
    window.scrollTo(0, 0);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.spinner-wrapper {
  position: relative;
  height: 100vh;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.novice-quiz-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow: hidden;
  padding: 16px 35px;
  position: relative;
  width: 100%;

  &__back-btn {
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    text-transform: unset;
  }
  > div {
    width: 100%;
  }

  .quiz-title {
    font-size: 30px;
    font-weight: bold;
    text-align: left;
    margin-bottom: 30px;
  }

  .quiz-items {
    width: 100%;

    .quiz-item {
      margin-bottom: 20px;

      .quiz-option {
        width: calc(25% - 15px);
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin-right: 15px;
        .quiz-checkbox {
          display: flex;
          align-items: flex-start;
          width: 240px;
          margin-top: 30px;
          min-height: 60px;
        }
        img.main-img {
          margin-top: 30px;
          box-shadow: -2px 2px 25px 1px rgba(0, 0, 0, 0.1);
          width: 240px;
          height: 240px;
          object-fit: contain;
          border-radius: 10px;
        }

        .quiz-option-check-wrapper {
          width: 100%;
          margin-top: 15px;

          .check-title {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 10px;

            .check-title-content {
              font-size: 20px;
              text-align: left;
            }

            img {
              margin-right: 5px;
            }
          }

          .check-description {
            font-size: 16px;
            text-align: left;
          }
        }
      }
    }

    .quiz-item-title {
      font-size: 20px;
      font-weight: bold;
      text-align: left;
    }

    .quiz-item-content {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 15px;
      padding-right: 15px;

      div {
        width: 100%;
      }
    }
  }

  .quiz-check-answers {
    margin-bottom: 50px;

    .try-again-button {
      font-size: 24px;
      font-weight: bold;
      color: white;
      text-transform: none;
    }
  }
}
</style>
