<template>
  <div class="main-wrapper">
    <div class="spinner-wrapper" v-if="loadingPresentations">
      <LoadSpinner
        :id="'spinner'"
        :text="'Loading...'"
        :size="'40'"
        :bgcolor="'lightgray'"
        :speed="'0'"
        :color="'gray'"
      />
    </div>
    <div
      v-else
      class="director-section-wrapper my-2 mx-2 ml-4 pa-2"
      :key="componentKey"
    >
      <presentation-slider
        v-if="!activeItem"
        :prezentations="myPresentations"
        :title="`My Prezentations`"
        :goto-detail="handleClick"
        :editable="true"
        id="my-presentations-block--auto"
      />
      <presentation-slider
        v-if="!activeItem"
        :prezentations="reviewPresentations"
        :title="`Prezentations to Review`"
        :goto-detail="handleClick"
        :editable="false"
        id="presentations-to-review-block--auto"
      />
      <presentation-slider
        v-if="
          !activeItem && allTeamPrezentations && allTeamPrezentations.length > 0
        "
        :prezentations="allTeamPrezentations"
        :title="`Team Prezentations`"
        :goto-detail="handleClick"
        :editable="false"
        id="team-presentations-block--auto"
      />
    </div>
  </div>
</template>

<script>
// import { get } from "lodash";

import { mapActions, mapState, mapGetters } from 'vuex';
import { findIndex } from 'lodash';
import PresentationSlider from '../../common/PresentationSlider.vue';
import LoadSpinner from '../../common/LoadSpinner.vue';
import { AnalyticsHandler } from '../../common/Analytics/AnalyticsHandler';

// import PrezentationDetail from "./PrezentationDetail.vue";

export default {
  name: 'MyPrezentation',
  components: {
    // PrezentationDetail,
    PresentationSlider,
    LoadSpinner,
  },

  data() {
    return {
      teamPrezentations: [],
      loadingPresentations: true,
      componentKey: 0,
      activeItem: null,
      currentItem: null,
      currentItemIsEditable: true,
    };
  },
  computed: {
    ...mapState('users', ['currentUser', 'isTrialExpired', 'themes']),
    // ...mapState('metrics', ['organizationLimit']),
    ...mapGetters('users', ['getAssetFolder']),
    ...mapState('dialogStore', {
      initialDialogContent: 'content',
    }),
    ...mapState('prezentations', [
      'allPrezentations',
      'allTeamPrezentations',
      'allPrezentationsForReview',
    ]),
    ...mapState('users', ['currentUser']),
    myPresentations() {
      const myPrez = [];
      if (!this.allPrezentations || this.allPrezentations.length <= 0)
        return myPrez;
      return this.allPrezentations;
    },
    reviewPresentations() {
      const reviewPrez = [];
      if (
        !this.allPrezentationsForReview ||
        this.allPrezentationsForReview.length <= 0
      )
        return reviewPrez;
      return this.allPrezentationsForReview;
    },
    // TODO: Unused can be deprecated
    viewItems() {
      return findIndex(this.myPresentations, ['id', this.activeItem.id]) > -1
        ? this.myPresentations
        : this.reviewPresentations;
    },
  },
  methods: {
    ...mapActions('prezentations', [
      'fetchAllPrezentations',
      'fetchAllTeamPrezentations',
      'fetchAllPrezentationsForReview',
    ]),
    setIntervalX(callback, delay, repetitions) {
      let x = 0;
      const intervalID = window.setInterval(() => {
        callback();
        if (++x === repetitions) {
          window.clearInterval(intervalID);
        }
      }, delay);
    },

    async handleClick(presentation, editable) {
      this.$router.push({
        name: 'myPrezentation.detail',
        params: { id: presentation.id, currentItemIsEditable: editable },
      });
      // this.activeItem = presentation;
      // this.currentItemIsEditable = editable;
    },

    async initialize() {
      const reviewData = {
        userIp: this.currentUser.userIp,
        userId: this.currentUser.user.id,
        companyID: this.currentUser.user.companyID,
      };
      await this.fetchAllPrezentationsForReview(reviewData);
      await this.fetchAllTeamPrezentations(this.currentUser);
      await this.fetchAllPrezentations(this.currentUser);
    },
  },

  async mounted() {
    if (this.$route.query.id) {
      const { id } = this.$route.query;
      const item = { id };
      this.handleClick(item, true);
    } else {
      await this.initialize();
      AnalyticsHandler.loadedMyPrezentations(this.currentUser);
      console.log(this.allPrezentations);
    }
    this.loadingPresentations = false;
    // TODO: Need to clean this up. It looks like a hacky way to force UI update
    // this.setIntervalX(
    //   () => {
    //     this.componentKey++;
    //   },
    //   200,
    //   10
    // );
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.spinner-wrapper {
  position: relative;
  height: 100vh;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.director-section-wrapper {
  position: relative;
  height: 100%;
  background: white;
  display: flex;
  flex-direction: column;

  .slider-wrapper:first-child {
    margin-bottom: 40px !important;
  }
}

.define-context-wrapper {
  width: 100%;
  height: fit-content;
  margin: auto;

  &.stepper-3 {
    height: 100%;
  }

  &.full-width {
    width: 100%;
    height: 100%;
  }

  .define-context-header {
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    margin-bottom: 5px;
  }
  .define-context-subheader {
    font-family: 'Lato' !important;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    margin-bottom: 40px;
  }

  .define-context-sub-header {
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    margin-bottom: 10px;
  }

  .define-context-description {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    margin-bottom: 40px;
  }

  .toolbar-panel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 12px;
    padding-bottom: 15px;
    border-bottom: solid 0.3px #d8d8d8;

    .view-modes,
    .view-mode {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .comment-panel {
      cursor: pointer;
    }

    .view-mode {
      cursor: pointer;

      .active {
        display: none;
      }
      .normal {
        display: block;
      }

      img {
        margin-right: 5px;
      }

      &.active {
        .active {
          display: block;
        }
        .normal {
          display: none;
        }
      }
    }

    .list {
      margin-right: 20px;
    }
  }

  .slides-content-wrapper {
    /* justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      height: 100%; */
    padding-top: 10px;
    height: calc(100vh - 250px);
    overflow: auto;
    .slides-list-view,
    .slides-mosaic-view {
      width: 100%;
      transition: width 0.3s, visibility 0.3s, opacity 0.3s ease-out;
      height: 100%;
    }

    ::v-deep .fab-btn {
      box-shadow: none;
      background: white;
      padding: 0;

      &::before {
        display: none;
      }
    }

    ::v-deep .my-slide-speed .v-speed-dial__list {
      left: 0px;
    }

    ::v-deep .download-list {
      width: 208px;
      border-radius: 2px;
      box-shadow: 0 2px 4px 0 #68788c;
      position: absolute;
      top: -166px;
      right: -10px;
      .download-list-item {
        font-size: 20px;
        padding-bottom: 5px;
        background: white;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: black;
        text-align: left;
        padding-left: 5px;
        cursor: pointer;

        &:hover {
          background: #f2f2f2;
        }
      }
    }

    .slides-comment {
      height: calc(100% - 50px);
      transition: all 0.3s ease-out;
      border-left: 0.3px solid #d8d8d8;
      border-bottom: 0.3px solid #d8d8d8;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      &.normal {
        width: 0px;
        display: none;
      }
      &.active {
        width: 340px;
      }

      .comment-send-wrapper {
        width: 100%;

        textarea {
          width: 100%;
          height: 75px;
          border: solid 0.2px #d8d8d8;
          padding-left: -12px !important;
          padding-right: -12px !important;
        }

        .comment-send-control {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 5px 0 5px;

          .icons {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .comment-who {
              margin-right: 5px;
            }

            .comment-who,
            .comment-smile {
              cursor: pointer;
            }
          }

          .comment-send-button {
            cursor: pointer;
          }
          .comment-send-button > img {
            filter: invert(1);
          }
        }
      }

      .comments {
        height: calc(100% - 100px);
        overflow: auto;

        .comment {
          margin-bottom: 30px;
          position: relative;

          .header {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            position: relative;
            width: 260px;
            .header-tool {
              display: flex;
              align-items: flex-start;
              justify-content: flex-start;
              //position: absolute;
              right: 10px;
              top: 0px;

              .edit {
                margin-right: 10px;
              }

              .edit,
              .delete {
                cursor: pointer;
              }
            }

            // &:hover {
            //   .header-tool {
            //     display: flex;
            //   }
            // }

            .online-status {
              width: 8px;
              height: 8px;
              border-radius: 50%;
              margin-top: 7px;
              margin-right: 5px;

              &.green {
                background: #61bc6f;
              }
              &.red {
                background: #bc6161;
              }
            }

            .header-info {
              text-align: left;
              .uesr-name {
                font-size: 16px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
              }
              .date-info {
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #68788c;
              }
            }
          }

          .comment-content {
            text-align: left;
            position: relative;
            width: 100%;

            input {
              width: 100%;
              border: 1px solid gray;
              padding: 3px;
              border-radius: 5px;
            }
          }
          .comment-control {
            position: absolute;
            bottom: -30px;
            right: 0px;
            display: none;
            align-items: center;
            justify-content: flex-start;

            &.show {
              display: flex;
            }

            .comment-cancel {
              margin-right: 10px;
            }

            .comment-cancel,
            .comment-save {
              cursor: pointer;
              font-size: 16px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
            }
          }
        }
      }
    }

    .slides-list-view {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;

      &.less-width {
        width: calc(100% - 340px);
      }

      .left-side-items {
        width: 300px;
        height: 100%;
        overflow-y: auto;
        padding: 5px;
        padding-right: 15px;

        .left-side-item {
          width: 100%;
          height: 170px;
          border-radius: 8px;
          box-shadow: -2px 2px 25px 1px rgba(0, 0, 0, 0.1);
          overflow: hidden;
          margin-bottom: 15px;
          cursor: pointer;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .left-side-item:hover {
          box-shadow: -4px 4px 25px 1px rgba(0, 0, 0, 0.3);
        }
      }

      .current-item-panel {
        padding-left: 30px;

        .image {
          border-radius: 16px;
          box-shadow: 0 2px 4px 0 #68788c;
          overflow: hidden;
          margin-bottom: 30px;
          cursor: pointer;

          img {
            width: 100%;
            height: 90%;
          }
        }
        .description-wrapper {
          display: grid;
          grid-template-columns: 1fr 170px;
          align-items: center;
          margin-top: -20px;
        }

        .description {
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
        }

        button.download-btn {
          font-size: 24px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: white;
          text-transform: none;
        }
      }
    }

    .slides-mosaic-view {
      button.download-btn {
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: white;
        text-transform: none;
        margin-bottom: 30px;
      }
      .slides-wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        height: calc(100vh - 350px);
        //overflow: scroll;
        margin-right: 5px;

        &::-webkit-scrollbar {
          -webkit-appearance: scrollbartrack-vertical;
        }

        .slide-wrapper {
          .slider-content-wrapper {
            box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
            border-radius: 8px;
            cursor: pointer;
          }
          .slider-content-wrapper:hover {
            box-shadow: -4px 4px 25px 1px rgba(0, 0, 0, 0.3);
          }

          .image-wrapper img {
            width: 100%;
          }
          .image-wrapper-mosaic img {
            width: 80%;
            height: 80%;
          }

          .image-wrapper {
            border-bottom: 1px solid rgba(211, 211, 211, 0.2);
          }

          .bottom-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 15px;

            .bottom-wrapper-title {
              font-size: 12px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              width: 80%;
            }

            .action-wrapper {
              display: flex;
              align-items: center;
              justify-content: center;

              .show-detail {
                cursor: pointer;
                margin-right: 15px;
                margin-left: 15px;
              }
            }
          }
        }
      }
    }
  }

  .outline-wrapper {
    .outline-header-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      &.idea {
        padding-left: 40px;
      }
    }

    .outline-header {
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #21a7e0;
      text-align: left;
      margin-right: 10px;

      &.idea {
        color: black;
      }
    }

    .list-group {
      margin-top: 25px;
    }

    .list-group-item {
      height: 40px;
      width: 195px;
      cursor: pointer;
      margin: 0 0 24px;
      padding: 11px 13px 10px 12px;
      border-radius: 10px;
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5), 0 0 3px 0 rgba(0, 0, 0, 0.5);
      background-color: trasparent;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &.idea {
        margin-left: 40px;
      }

      .element-info {
        display: none;
      }

      &:hover {
        box-shadow: 0 1px 8px -1px rgba(0, 0, 0, 0.5),
          0 2px 4px 1px rgba(0, 0, 0, 0.15), 0 0 3px 0 rgba(0, 0, 0, 0.5);
        border: solid 3px #21a7e0;

        .element-info {
          display: block;
        }
      }
    }
  }

  .accept-outline-btn {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: white;
    text-transform: none;
  }

  .presentation-name-wrapper {
    margin-bottom: 30px;

    .presentation-name-label {
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
    }
  }

  .primary-audience-wrapper {
    margin-bottom: 30px;

    .primary-audience-label {
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .audience-combobox-wrapper {
      position: relative;

      .icon {
        position: absolute;
        right: 12px;
        top: 12px;
      }

      ::v-deep .v-input__icon {
        display: none;
      }
    }
  }

  .presentation-type-wrapper {
    .presentation-type-label {
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
    }
  }
}

.dialog-actions-fab {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  // width: 95%;
  width: 100%;
  margin-top: 20px;
  margin-right: 200px;
  width: 100%;
  ::v-deep .new-slide-detail-speed .v-speed-dial__list {
    left: 0;
  }

  ::v-deep .download-list {
    width: 208px;
    border-radius: 2px;
    box-shadow: 0 2px 4px 0 #68788c;

    .download-list-item {
      font-size: 20px;
      padding-bottom: 5px;
      background: white;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: black;
      text-align: left;
      padding-left: 5px;
      cursor: pointer;

      &:hover {
        background: #f2f2f2;
      }
    }
  }

  button.cancel {
    border-radius: 25px;
    border: solid 2px #21a7e0;
    text-transform: none;
    color: #21a7e0 !important;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-right: 40px;
  }
  button.download {
    border-radius: 25px;
    text-transform: none;
    color: white !important;
    background: #21a7e0;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
}
.download-btn-fab {
  margin-left: -15px;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: black;
  text-transform: none;

  .v-btn::before {
    background-color: transparent !important;
    text-align: left;
  }
  .v-btn__content {
    justify-content: flex-start !important;
  }
  .add-icon {
    color: #21a7e0;
    position: relative;
    transform: translateY(-2px);
  }

  .v-list .v-list-item--active .v-icon {
    color: black;
  }
}
</style>
