<template>
  <div class="defaultPaymentSwitchModalContainer">
    <div class="closeIconContainer">
      <v-icon @click="handleModalClose">{{ 'mdi-close' }}</v-icon>
    </div>
    <div class="modalHeader">
      {{ $t('profile.editPaymentDetail.updatePaymentMethod') }}
    </div>
    <div class="modalText">
      {{ $t('profile.editPaymentDetail.updatePaymentMethodText') }}
    </div>
    <div class="modalActions">
      <div class="buttonsContainer">
        <v-btn class="cancel" @click="handleModalClose">
          {{ $t('common.cancel') }}
        </v-btn>
        <v-btn
          class="accept"
          @click="handleUpdatePaymentAsDefault"
          :loading="markingDefault === true"
        >
          {{ $t('common.update') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { markPaymentMethodAsDefault } from '../../../../API/payment-api';
import {
  TD_PLACEMENT,
  TD_SET_AS_DEFAULT,
} from '../../../common/Analytics/MatomoTrackingDataHelper';
import { trackProfileEvents } from '../../../common/Analytics/ProfileEvents';

export default {
  name: 'SwitchPaymentDefaultModal',
  props: {
    card: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      markingDefault: false,
    };
  },
  computed: {
    ...mapState('users', ['currentUser']),
  },
  methods: {
    ...mapActions('paymentMethods', [
      'setSnackbar',
      'setPaymentMethodAsDefault',
    ]),
    handleModalClose(e) {
      this.$modal.hide('SwitchPaymentDefaultModal');
      if (e) {
        trackProfileEvents.profileAdminSettingsPaymentsCancel(
          this.currentUser,
          {
            [TD_PLACEMENT]: TD_SET_AS_DEFAULT,
          },
        );
      }
    },

    async handleUpdatePaymentAsDefault() {
      trackProfileEvents.profileAdminSettingsPaymentsUpdate(this.currentUser, {
        [TD_PLACEMENT]: TD_SET_AS_DEFAULT,
      });
      this.markingDefault = true;
      await markPaymentMethodAsDefault({
        payment_method_id: this.card.id,
      })
        .then((resp) => {
          this.markingDefault = false;
          this.handleModalClose();
          this.setSnackbar({
            status: true,
            text: 'Default payment method updated successfully!',
            action: null,
          });
          this.setPaymentMethodAsDefault(this.card.id);
          console.log(resp);
        })
        .catch((err) => {
          this.markingDefault = false;
          this.handleModalClose();
          this.setSnackbar({
            status: true,
            text: 'Could not update default payment method. Something went wrong.',
            action: null,
          });
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.defaultPaymentSwitchModalContainer {
  width: 100%;
  padding: 24px 32px;
  .closeIconContainer {
    text-align: right;
    position: absolute;
    right: 10px;
    top: 8px;
  }
  .modalHeader {
    width: 100%;
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
  .modalText {
    width: 100%;
    font-size: 16px;
    margin: 24px 0px 32px 0px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    font-weight: 400;
  }
  .modalActions {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .buttonsContainer {
      width: 35%;
      display: flex;
      justify-content: space-between;
      .cancel,
      .accept {
        background: transparent;
        box-shadow: none;
        letter-spacing: normal;
        text-transform: none;
        font-size: 17px;
        color: #20a7e0;
        border-radius: 22px;
      }
      ::v-deep .cancel {
        color: #20a7e0;
        border: 1px solid #20a7e0;
        background-color: transparent;
        .v-btn__content {
          line-height: 25px !important;
        }
      }
      ::v-deep .accept {
        color: #fff;
        border: 0px;
        background-color: #20a7e0;
        .v-btn__content {
          line-height: 25px !important;
        }
      }
    }
  }
}
</style>
