<template>
  <div class="remind-container">
    <v-btn class="close-btn" icon @click="handleClose">
      <v-icon dark v-text="`mdi-close`" />
    </v-btn>
    <div class="inner">
      <img src="/assets/img/fingerprints-not-found.svg" alt />
      <div class="remind-paragraph">
        <p>
          {{ $t('overnightPresentations.colleagueNotYetTakenAssessment') }}
        </p>
      </div>
      <v-btn
        @click="handleRemind"
        class="remind-btn"
        rounded
        color="#20a7e0"
        height="52"
        minWidth="125"
        :loading="isLoading"
        :disabled="isDone"
      >
        {{
          isDone
            ? $t('overnightPresentations.reminderSent')
            : $t('overnightPresentations.remind')
        }}
      </v-btn>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import DialogService from '@/services/DialogService';
import { sendFPReminder } from '@/utils/api-helper';
import { MatomoAnalyticsHandler } from '../../common/Analytics/MatomoAnalyticsHandler';
import { MY_PREZENTATION } from '../../common/Analytics/MatomoTrackingDataHelper';
import { trackBuildEvents } from '../../common/Analytics/BuildEvents';
import { trackPrezentationEvents } from '../../common/Analytics/PrezentationEvents';

export default {
  name: 'ReminderDialog',
  mixins: [DialogService],
  data() {
    return { isLoading: false, isDone: false };
  },
  props: {
    currentAudience: {
      type: Object,
    },
    isUser: {
      type: Boolean,
    },
    origin: {
      type: String,
      required: false,
    },
    metaDataForTracking: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  methods: {
    async handleRemind() {
      if (this.isDone) return;
      this.isLoading = true;
      sendFPReminder(this.currentUser, this.currentAudience, this.isUser)
        .then(() => {
          this.isDone = true;
          if (this.origin) {
            MatomoAnalyticsHandler.tailorSlidesGrayAudienceReminded(
              this.origin,
              this.currentUser,
            );
            if (Object.keys(this.metaDataForTracking || {}).length > 0) {
              const { prezentationTrackingInfo, stepCount } =
                this.metaDataForTracking;
              if (this.origin.toLowerCase() === 'build') {
                switch (stepCount) {
                  case 'STEP_1':
                    trackBuildEvents.buildBuildTailorSlidesForRemindStepOne(
                      this.currentUser,
                      prezentationTrackingInfo,
                    );
                    break;
                  case 'STEP_3':
                    trackBuildEvents.buildBuildTailorSlidesForRemindStepThree(
                      this.currentUser,
                      prezentationTrackingInfo,
                    );
                    break;
                  default:
                    break;
                }
              }
              if (this.origin.toLowerCase() === MY_PREZENTATION) {
                trackPrezentationEvents.prezentationTailorSlidesForRemind(
                  this.currentUser,
                  prezentationTrackingInfo,
                );
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleClose() {
      this.$modal.hide('ReminderDialog');
    },
  },
  computed: {
    ...mapState('users', [
      'currentUser',
      'currentTheme',
      'isTrialExpired',
      'themes',
    ]),
  },
};
</script>
<style lang="scss" scoped>
.remind-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 2rem;
}
.inner {
  text-align: center;
}
.remind-paragraph {
  font-size: 24px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  margin: 2rem 0;
}
.remind-btn {
  border-radius: 25px;
  text-transform: none;
  color: white !important;
  background: $zen-blue-default;
  font-size: 1.3vw;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.close-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 1;
}
</style>
