import { inductiveTypeList } from './inductive.js';
import { deductiveTypeList } from './deductive.js';

export const DEDUCTIVE_PRINTS = [
  'performer',
  'producer',
  'scientist',
  'scholar',
];
export const INDUCTIVE_PRINTS = [
  'director',
  'surgeon',
  'navigator',
  'architect',
];

function getOutlineMatch(fingerprint) {
  if (fingerprint && DEDUCTIVE_PRINTS.includes(fingerprint.toLowerCase())) {
    console.log(fingerprint, ':DEDUCTIVE_PRINTS');
    return deductiveTypeList;
  }
  if (fingerprint && INDUCTIVE_PRINTS.includes(fingerprint.toLowerCase())) {
    console.log(fingerprint, ':INDUCTIVE_PRINTS');
    return inductiveTypeList;
  }
  // default INDUCTIVE_PRINTS
  console.log(fingerprint, ':INDUCTIVE_PRINTS');
  return inductiveTypeList;
}

// function isChipPresent(outline, chipName) {
//   let isPresent;
//   let sectionIndex;
//   let index;
//   chipName = chipName.trim().toLowerCase();
//   for (
//     let sectionIdx = 0;
//     sectionIdx < outline.sections.list.length;
//     sectionIdx++
//   ) {
//     const section = outline.sections.list[sectionIdx];
//     for (let slideIndex = 0; slideIndex < section.slides.length; slideIndex++) {
//       if (section.slides[slideIndex].outline.toLowerCase() === chipName) {
//         isPresent = true;
//         sectionIndex = sectionIdx;
//         index = slideIndex;
//         return { isPresent, sectionIndex, index, ideaGallery: false };
//       }
//     }
//   }
//   for (let idx = 0; idx < outline.ideaGallery.list.length; idx++) {
//     const data = outline.ideaGallery.list[idx];
//     if (data.outline.trim().toLowerCase() === chipName) {
//       isPresent = true;
//       sectionIndex = -1;
//       index = idx;
//       return { isPresent, sectionIndex, index, ideaGallery: true };
//     }
//   }
//   isPresent = false;
//   sectionIndex = -1;
//   index = -1;
//   return { isPresent, sectionIndex, index, ideaGallery: false };
// }

function isChipPresentInside(outline, chipName, inside) {
  let isPresent;
  let index;
  chipName = chipName.trim().toLowerCase();
  for (let idx = 0; idx < outline[inside].slides.length; idx++) {
    const data = outline[inside].slides[idx];
    if (data.outline.trim().toLowerCase() === chipName) {
      isPresent = true;
      index = idx;
      return { isPresent, index };
    }
  }
  return { isPresent: false, index: -1 };
}

function createChip(outline, category) {
  const chip = {
    outline,
    category,
  };
  return chip;
}

// function reIndexChip(outline) {
//   let id = 1;
//   for (let idx = 0; idx < outline.beginning.length; idx++) {
//     outline.beginning[idx].id = id;
//     id++;
//   }
//   for (let idx = 0; idx < outline.middle.length; idx++) {
//     outline.middle[idx].id = id;
//     id++;
//   }
//   for (let idx = 0; idx < outline.end.length; idx++) {
//     outline.end[idx].id = id;
//     id++;
//   }
//   for (let idx = 0; idx < outline.idea.length; idx++) {
//     outline.idea[idx].id = id;
//     id++;
//   }
// }

function addChipAtEnd(outline, chip, inside, ideaGallery = false) {
  let currentLength;
  if (ideaGallery) {
    currentLength = outline.ideaGallery.list.length;
    outline.ideaGallery.list.splice(currentLength, 0, chip);
  } else {
    currentLength = outline[inside].slides.length;
    outline[inside].slides.splice(currentLength, 0, chip);
  }
  // reIndexChip(outline);
}

function addChipAtIndex(outline, chip, inside, index) {
  outline[inside].splice(index, 0, chip);
  // reIndexChip(outline);
}

function removeChipAtIndex(outline, inside, index, ideaGallery = false) {
  if (ideaGallery) {
    return outline.ideaGallery.list.splice(index, 1);
  }
  return outline[inside].slides.splice(index, 1);
}

export const getPrezentationOutlineForEdit = (fingerprint, type) => {
  // deep copy of outlines
  const outlineObjectList = JSON.parse(
    JSON.stringify(getOutlineMatch(fingerprint)),
  );
  const responseList = [];
  let customOutlineObj = {};
  for (const outlineObj of outlineObjectList) {
    if (
      type &&
      outlineObj.text.toLowerCase().trim() === type.toLowerCase().trim()
    ) {
      responseList.push(outlineObj);
      break;
    }
    if (outlineObj.text.toLowerCase().trim() === 'custom') {
      customOutlineObj = outlineObj;
    }
  }
  if (responseList.length === 0) {
    responseList.push(customOutlineObj);
  }
  return responseList;
};

export const getCustomPrezentationOutline = (fingerprint = '') => {
  // deep copy of outlines
  const outlineObjectList = JSON.parse(
    JSON.stringify(getOutlineMatch(fingerprint)),
  );
  let customOutlineObj = {
    outline: {
      beginning: [],
      middle: [],
      end: [],
      idea: [],
    },
  };
  for (const outlineObj of outlineObjectList) {
    if (outlineObj.text.toLowerCase().trim() === 'custom') {
      customOutlineObj = outlineObj;
      break;
    }
  }
  return customOutlineObj;
};

export const getAllPrezentationOutline = (outlineObj, personalPreferences) => {
  // deep copy of outlines
  // const outlineObjectList = JSON.parse(
  //   JSON.stringify(getOutlineMatch(fingerprint)),
  // );
  console.log('personalPreferences', personalPreferences, outlineObj);

  // for (const outlineObj of outlines) {
  // exclude custom outline while applying rules.
  if (outlineObj.isPrezentGenerated && outlineObj.type !== 'Custom') {
    if (
      personalPreferences &&
      personalPreferences.includes('Start with an Executive Summary')
    ) {
      const { isPresent, index } = isChipPresentInside(
        outlineObj.sections.list,
        'Executive Summary',
        0,
      );
      if (isPresent) {
        removeChipAtIndex(outlineObj.sections.list, 0, index);
      }
      const chip = createChip('Executive Summary', 'executive_summary');

      const titleChip = isChipPresentInside(
        outlineObj.sections.list,
        'Title',
        0,
      );
      const agendaChip = isChipPresentInside(
        outlineObj.sections.list,
        'Agenda',
        0,
      );
      const tableOfContentsChip = isChipPresentInside(
        outlineObj.sections.list,
        'Table of Contents',
        0,
      );
      const topicsChip = isChipPresentInside(
        outlineObj.sections.list,
        'Topics',
        0,
      );

      let toPlaceAt = 0;
      if (titleChip.isPresent && titleChip.index >= toPlaceAt) {
        toPlaceAt = titleChip.index + 1;
      }
      if (agendaChip.isPresent && agendaChip.index >= toPlaceAt) {
        toPlaceAt = agendaChip.index + 1;
      }
      if (
        tableOfContentsChip.isPresent &&
        tableOfContentsChip.index >= toPlaceAt
      ) {
        toPlaceAt = tableOfContentsChip.index + 1;
      }
      if (topicsChip.isPresent && topicsChip.index >= toPlaceAt) {
        toPlaceAt = topicsChip.index + 1;
      }
      addChipAtIndex(outlineObj.sections.list[0], chip, 'slides', toPlaceAt);
    }
    if (
      personalPreferences &&
      personalPreferences.includes('State objective upfront')
    ) {
      const { isPresent, index } = isChipPresentInside(
        outlineObj.sections.list,
        'Objectives',
        0,
      );
      if (isPresent) {
        removeChipAtIndex(outlineObj.sections.list, 0, index);
      }
      const chip = createChip('Objectives', 'objectives');

      const titleChip = isChipPresentInside(
        outlineObj.sections.list,
        'Title',
        0,
      );
      const agendaChip = isChipPresentInside(
        outlineObj.sections.list,
        'Agenda',
        0,
      );
      const tableOfContentsChip = isChipPresentInside(
        outlineObj.sections.list,
        'Table of Contents',
        0,
      );
      const topicsChip = isChipPresentInside(
        outlineObj.sections.list,
        'Topics',
        0,
      );
      const executiveSummaryChip = isChipPresentInside(
        outlineObj.sections.list,
        'Executive Summary',
        0,
      );

      let toPlaceAt = 0;
      if (titleChip.isPresent && titleChip.index >= toPlaceAt) {
        toPlaceAt = titleChip.index + 1;
      }
      if (agendaChip.isPresent && agendaChip.index >= toPlaceAt) {
        toPlaceAt = agendaChip.index + 1;
      }
      if (
        tableOfContentsChip.isPresent &&
        tableOfContentsChip.index >= toPlaceAt
      ) {
        toPlaceAt = tableOfContentsChip.index + 1;
      }
      if (topicsChip.isPresent && topicsChip.index >= toPlaceAt) {
        toPlaceAt = topicsChip.index + 1;
      }
      if (
        executiveSummaryChip.isPresent &&
        executiveSummaryChip.index >= toPlaceAt
      ) {
        toPlaceAt = executiveSummaryChip.index + 1;
      }
      addChipAtIndex(outlineObj.sections.list[0], chip, 'slides', toPlaceAt);
    }
    if (
      (personalPreferences &&
        personalPreferences.includes('Pause for questions')) ||
      (personalPreferences &&
        personalPreferences.includes('Leave 50% time for questions'))
    ) {
      const { isPresent, index } = isChipPresentInside(
        outlineObj.sections.list,
        'Questions',
        outlineObj.sections.list.length - 1,
      );
      if (isPresent) {
        removeChipAtIndex(
          outlineObj.sections.list,
          outlineObj.sections.list.length - 1,
          index,
        );
      }
      const chip = createChip('Questions', 'questions');
      addChipAtEnd(
        outlineObj.sections.list,
        chip,
        outlineObj.sections.list.length - 1,
      );

      const thankYouChip = isChipPresentInside(
        outlineObj.sections.list,
        'Thank You',
        outlineObj.sections.list.length - 1,
      );

      if (thankYouChip.isPresent) {
        const chipRemovedList = removeChipAtIndex(
          outlineObj.sections.list,
          outlineObj.sections.list.length - 1,
          thankYouChip.index,
        );
        if (chipRemovedList.length > 0) {
          addChipAtEnd(outlineObj, chipRemovedList[0], 'list', true);
        }
      }
    }
    // reIndexChip(outlineObj.outline);
  }
  // }
  return outlineObj;
};
