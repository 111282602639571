<template>
  <div class="novice-wrapper">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'Novice',
  data() {
    return {
      levelInfo: null,
      learnData: null,
    };
  },
  computed: {},
  methods: {},

  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.novice-wrapper {
  position: relative;
  height: 100%;
}
</style>
