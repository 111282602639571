<template>
  <div>
    <div class="impact">
      <span>
        {{ $t('profile.metricDetail.impact') }}
        <v-tooltip right max-width="260" content-class="tooltip-content" fixed>
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="icon-title" v-bind="attrs" v-on="on">
              mdi-information-outline
            </v-icon>
          </template>
          <span>
            {{
              $t('profile.metricDetail.totalTimeAndCostsSaved', {
                teamText:
                  isNormalUser === false && adminStatsToggleRadio === 'Team'
                    ? $t('profile.metricDetail.andYourTeam')
                    : '',
              })
            }}
          </span>
        </v-tooltip>
      </span>
      <div class="im__header-right">
        <slot name="headerRight"></slot>
        <TimelineFilter
          v-show="adminStatsToggleRadio === 'Team'"
          v-model="range"
          origin="ImpactMetrics"
          @change="getTimeAndCostSaved()"
        />
      </div>
    </div>
    <div
      :class="
        !isNormalUser && adminStatsToggleRadio === 'Team'
          ? 'top-panel'
          : 'normalUserPanelTopPanel'
      "
    >
      <div class="timeSaved">
        <div class="timeSavedImgContainer">
          <div class="imgText">
            <h2>{{ $t('profile.metricDetail.timeSaved') }}</h2>
          </div>
          <div class="imgContent">
            <img src="/assets/img/time-saved.svg" alt />
          </div>
        </div>
        <div class="timeSavedValueContainer">
          <div class="timeFigure" v-if="loading === false">
            <h1>
              {{
                Number(timeSaved) >= 1
                  ? `${formatCounts(timeSaved)} hrs`
                  : `${formatCounts(timeSaved)} min`
              }}
            </h1>
          </div>
          <clip-loader
            v-else
            :loading="true"
            :color="`#21a7e0`"
            :width="'60'"
            :height="'60'"
            :size="'30px'"
            class="cliploader"
          />
        </div>
      </div>
      <div class="costSaved">
        <div class="costSavedImgContainer">
          <div class="imgText">
            <h2>{{ $t('profile.metricDetail.costsSaved') }}</h2>
          </div>
          <div class="imgContent">
            <img src="/assets/img/costs-saved.svg" alt />
          </div>
        </div>
        <div class="costSavedValueContainer">
          <div class="costFigure" v-if="loading === false">
            <h1>{{ `$${formatCounts(costSaved)}` }}</h1>
          </div>
          <clip-loader
            v-else
            :loading="true"
            :color="`#21a7e0`"
            :width="'60'"
            :height="'60'"
            :size="'30px'"
            class="cliploader"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import { getMyTeamStats } from '../../../../utils/api-helper';
import TimelineFilter from './TimelineFilter.vue';

export default {
  name: 'ImpactMetrics',
  props: {
    adminStatsToggleRadio: String,
    isNormalUser: Boolean,
  },
  components: {
    ClipLoader,
    TimelineFilter,
  },
  data() {
    return {
      timeSaved: 0,
      costSaved: 0,
      range: { from: null, to: null },
      loading: false,
    };
  },
  computed: {
    ...mapState('users', ['currentUser']),
  },
  mounted() {
    this.getTimeAndCostSaved();
  },
  watch: {
    adminStatsToggleRadio() {
      this.getTimeAndCostSaved();
    },
  },
  methods: {
    getTimeAndCostSaved() {
      if (
        this.isNormalUser === false &&
        this.adminStatsToggleRadio === 'Team'
      ) {
        this.loading = true;
        getMyTeamStats(this.range.from, this.range.to)
          .then((resp) => {
            this.timeSaved = resp.data.timeSaved || 0;
            this.costSaved = resp.data.costSaved || 0;
            this.loading = false;
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
          });
      } else {
        this.timeSaved = this.currentUser.user.timeSaved || 0;
        this.costSaved = this.currentUser.user.costSaved || 0;
      }
    },
    formatCounts(count) {
      count = Number(count);

      let value = 0;
      let unit = '';

      if (count < 1000) {
        value = count;
      } else if (count < 1.0e6) {
        value = count / 1.0e3;
        unit = 'K';
      } else if (count < 1.0e9) {
        value = count / 1.0e6;
        unit = 'M';
      } else if (count >= 1.0e9) {
        value = count / 1.0e9;
        unit = 'B';
      }

      return value.toFixed(1).toLocaleString() + unit;
    },
  },
};
</script>
<style lang="scss" scoped>
.im {
  &__header-right {
    display: grid;
    grid-auto-flow: column;
    gap: 22px;
    align-items: center;
  }
}
.top-panel {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-bottom: 50px;
  border-bottom: 0.8px solid #d8d8d8;
  position: relative;
  .timeSaved,
  .costSaved {
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 8px;
  }
  .timeSaved {
    .timeSavedImgContainer {
      background-color: #daf4fc;
      border-radius: 8px 0px 0px 8px;
      width: 151px;
      .imgText {
        color: rgba(0, 0, 0, 0.54);
        margin-top: 21px;
      }
    }
    .timeSavedValueContainer {
      width: 220px;
      color: rgba(0, 0, 0, 0.54);
      padding: 82px 0px;
    }
  }
  .costSaved {
    .costSavedImgContainer {
      background-color: #daf4fc;
      border-radius: 8px 0px 0px 8px;
      width: 151px;
      .imgText {
        color: rgba(0, 0, 0, 0.54);
        margin-top: 21px;
      }
    }
    .costSavedValueContainer {
      width: 220px;
      color: rgba(0, 0, 0, 0.54);
      padding: 82px 0px;
    }
  }
}
.normalUserPanelTopPanel {
  display: flex;
  padding-bottom: 40px;
  border-bottom: 0.8px solid #d8d8d8;
  position: relative;
  .timeSaved,
  .costSaved {
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 8px;
    margin-right: 48px;
  }
  .timeSaved {
    .timeSavedImgContainer {
      background-color: #daf4fc;
      border-radius: 8px 0px 0px 8px;
      width: 151px;
      .imgText {
        color: rgba(0, 0, 0, 0.54);
        margin-top: 21px;
      }
    }
    .timeSavedValueContainer {
      width: 220px;
      color: rgba(0, 0, 0, 0.54);
      padding: 82px 0px;
      .timeMetric {
        color: grey;
        font-size: 17px;
        font-weight: 600;
      }
    }
  }
  .costSaved {
    .costSavedImgContainer {
      background-color: #daf4fc;
      border-radius: 8px 0px 0px 8px;
      width: 151px;
      .imgText {
        color: rgba(0, 0, 0, 0.54);
        margin-top: 21px;
      }
    }
    .costSavedValueContainer {
      width: 220px;
      color: rgba(0, 0, 0, 0.54);
      padding: 82px 0px;
      .costMetric {
        color: grey;
        font-size: 17px;
        font-weight: 600;
      }
    }
  }
}
</style>
