<template>
  <div class="select-block">
    <div
      class="select-slides"
      v-if="sanitizeStep === 0"
      data-pendo-id="upload-select-slides"
    >
      <h1 class="title">{{ $t('upload.selectSlides') }}</h1>
      <p class="pt-2 virbage">
        {{ $t('upload.presentationContains') }}
        {{ prezentationData.number_of_slides || 0 }}
        {{
          $t(
            prezentationData.number_of_slides > 1
              ? 'upload.slides'
              : 'upload.slide',
          )
        }}.<br />
        {{
          $t('upload.pleaseSelect', {
            slides:
              prezentationData.number_of_slides > 1
                ? $t('upload.slides')
                : $t('upload.slide'),
          })
        }}
      </p>

      <div class="slides-selection-scetion">
        <div class="select-all-row">
          <div>
            {{ $t('upload.allSlides') }}
            <v-badge
              color="#FFE1B7"
              :content="prezentationData.number_of_slides || 0"
              inline
            ></v-badge>
          </div>
          <div data-pendo-id="presentation-select-all">
            <v-checkbox
              v-model="selectAll"
              @change="handleSelectAllSlides"
              :label="
                selectAll ? $t('upload.deselectAll') : $t('upload.selectAll')
              "
            ></v-checkbox>
          </div>
        </div>
        <div class="slides-list">
          <div
            class="slide-card"
            v-for="slide in prezentationData.slidesList"
            :key="slide.index"
          >
            <template v-if="slide.thumbnail">
              <img
                :src="slide.thumbnail"
                alt
                lazy-src="/assets/img/slides/placeholder-slide.svg"
                class="slide-img"
                loading="lazy"
              />
            </template>
            <template v-else>
              <div>
                <img
                  src=""
                  alt
                  lazy-src="/assets/img/slides/placeholder-slide.svg"
                  class="slide-img"
                  loading="lazy"
                />
              </div>
            </template>
            <!-- <img
              :src="slide.thumbnail"
              alt
              lazy-src="/assets/img/slides/placeholder-slide.svg"
              class="slide-img"
              loading="lazy"
            /> -->
            <v-checkbox
              v-model="selectedRef[slide.index]"
              class="ckeck-box"
              @change="(e) => handleSelectSlide(e, slide)"
            ></v-checkbox>
            <v-tooltip
              top
              max-width="200"
              content-class="tooltip-content"
              fixed
            >
              <template v-slot:activator="{ on }">
                <div class="slide-text" v-on="on">
                  {{ slide.name }}
                </div>
              </template>
              <span>{{ slide.name }}</span>
            </v-tooltip>
          </div>
        </div>
      </div>
      <div class="center">
        <div class="footer">
          <p>
            {{ selectedSlides.length }}
            {{
              selectedSlides.length > 1
                ? $t('upload.slides')
                : $t('upload.slide')
            }}
            {{ $t('toggleAutoRenew.selectedCard') }}
          </p>
          <div v-if="isTransferFlow">
            <v-btn
              class="ma-2 btn"
              rounded
              color="primary"
              width="120px"
              @click="transferSlidesAsIs()"
              :disabled="!selectedSlides.length"
              :loading="transferSlidesLoading"
            >
              <span class="mr-2">Add</span>
              <v-tooltip
                top
                max-width="300"
                content-class="tooltip-content"
                fixed
              >
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-information-outline</v-icon>
                </template>
                <span> {{ $t('upload.addCopyOfSlides') }} </span>
              </v-tooltip>
            </v-btn>
            <v-btn
              class="ma-2 btn"
              width="120px"
              rounded
              color="primary"
              @click="transferSlidesForReview()"
              :disabled="!selectedSlides.length"
              :loading="transferSlidesLoading"
            >
              <span class="mr-2">{{ $t('upload.review') }}</span>
              <v-tooltip
                top
                max-width="300"
                content-class="tooltip-content"
                fixed
              >
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-information-outline</v-icon>
                </template>
                <span> {{ $t('upload.reviewText') }} </span>
              </v-tooltip>
            </v-btn>
          </div>
          <div v-else>
            <v-btn
              class="ma-2 btn"
              rounded
              color="primary"
              @click="handleSelectSlidesNextStep()"
              :disabled="!selectedSlides.length"
              data-pendo-id="presentation-next"
            >
              {{ $t('common.next') }}
            </v-btn>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showWaiting && sanitizeStep === 1" class="waiting-block">
      <clip-loader
        :loading="showWaiting"
        :color="`#21a7e0`"
        :width="20"
        :height="20"
      />
      <br />
      <h2>
        {{ $t('upload.analyzingSlidesForSensitiveContent') }}
      </h2>
      <div class="image">
        <img src="/assets/img/uploader/directing-new-1.svg" alt width="350px" />
      </div>
    </div>
    <div v-if="showWaiting && sanitizeStep === 2" class="waiting-block">
      <clip-loader
        :loading="showWaiting"
        :color="`#21a7e0`"
        :width="20"
        :height="20"
      />
      <br />
      <h2>{{ $t('upload.importingSlides') }}</h2>
      <div class="image">
        <img
          src="/assets/img/uploader/import-prezentation.svg"
          alt
          width="350px"
        />
      </div>
    </div>
    <div v-if="showWaiting && sanitizeStep === 3" class="waiting-block">
      <clip-loader
        :loading="showWaiting"
        :color="`#21a7e0`"
        :width="20"
        :height="20"
      />
      <br />
      <h2>{{ $t('upload.addingSlidesToSlides') }}</h2>
      <div class="image">
        <img
          src="/assets/img/uploader/adding-individual-slides.svg"
          alt
          width="350px"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import { mapGetters, mapState } from 'vuex';
import { getFileURL } from '@/utils/calyrex';
import { getFileURLDownload } from '@/utils/calyrex-test';
import { UploadService } from '../../../services/UploadService';
import { getLoggedInUserToken } from '../../../utils/api';
import {
  getPipeineStatus,
  getPrezentationByID,
  getPrezentationShareDetails,
  getSuperhawkCategories,
  uploadPPTXStartRequest,
} from '../../../utils/api-helper';
import { convertPdfToImage } from '../../../utils/pdf-to-png';
import TransferSlidesSuccessModalVue from './TransferSlidesSuccessModal.vue';
import { calyrexConfig } from '../../../runtimeConfig';
import audienceApi from '../../../API/audience-api';
import { trackPrezentationEvents } from '../../common/Analytics/PrezentationEvents';
import {
  TD_AUD,
  TD_PREZNAME,
  TD_PREZTYPE,
  TD_TEMPLATE,
} from '../../common/Analytics/MatomoTrackingDataHelper';

export default {
  name: 'SlidesSelection',
  components: {
    ClipLoader,
  },
  data() {
    return {
      componentEnterTime: 0,
      componentExitTime: 0,
      timeSpent: 0,
      sanitizeStep: 0,
      selectAll: false,
      showWaiting: false,
      selectedSlides: [],
      selectedRef: {},
      isSelectSlideChanged: false,
      prezentation: {},
      transferSlidesLoading: false,
      callbackId: '',
    };
  },

  props: {
    nextStep: {
      type: Function,
      default: () => ({}),
    },
    prezentationData: {
      type: Object,
      default: () => ({}),
    },
    onPrezentationUpload: {
      type: Function,
      default: () => ({}),
    },
    setIsUploading: {
      type: Function,
      default: () => ({}),
    },
    isTransferFlow: Boolean,
  },
  async mounted() {
    this.componentEnterTime = Date.now();
    this.initTransferSlidesFlow();
    this.initSlides();
  },
  computed: {
    ...mapState('users', ['currentUser']),
    ...mapGetters('users', ['getTemplateByCode']),
  },
  methods: {
    async initSlides() {
      this.prezentationData?.slidesList?.forEach((item) => {
        this.selectedRef[item.index] = false;
      });
      if (this.prezentationData?.selectedSlidesList) {
        this.selectedSlides = [...this.prezentationData.selectedSlidesList];

        this.selectedSlides.forEach((item) => {
          this.selectedRef[item.index] = true;
        });
        this.selectAll =
          this.selectedSlides.length ===
          this.prezentationData?.slidesList.length;
      }
    },
    async initTransferSlidesFlow() {
      if (this.isTransferFlow) {
        this.showWaiting = true;
        this.sanitizeStep = 2;
        const prezentationId = this.$route.params.id;
        this.prezentation = await getPrezentationByID(prezentationId);

        const prezentationFinalData = await this.transformDataForTransferFlow(
          this.prezentation,
        );

        this.$emit('setPrezentationFinalData', prezentationFinalData);

        this.$nextTick(() => {
          this.handleSelectAllSlides(true);
          this.showWaiting = false;
          this.sanitizeStep = 0;
        });

        const uuid = uuidv4();
        const body = {
          callback_id: uuid,
          report_id: uuid,
          workflow_name: 'sanitize',
          workflow_context: {
            slides_to_process: prezentationFinalData.slidesList.map(
              (slide, index) => {
                let s3Bucket = process.env.VUE_APP_MVP_ASSETS_BUCKET;
                if (
                  slide.pdf?.s3_path?.startsWith('newImageReplaceOutput') ||
                  slide.pdf?.s3_path?.startsWith('/newImageReplaceOutput')
                ) {
                  s3Bucket = calyrexConfig.calyrex_bucket_name;
                }

                return {
                  s3_bucket: s3Bucket,
                  assetId: slide.pdf?.s3_path.startsWith('/')
                    ? slide.pdf.s3_path.substring(1)
                    : slide.pdf?.s3_path,
                  filename: slide.pptx?.s3_path.startsWith('/')
                    ? slide.pptx.s3_path.substring(1)
                    : slide.pptx?.s3_path,
                  index,
                };
              },
            ),
          },
        };
        uploadPPTXStartRequest(body).then(() => {
          this.callbackId = uuid;
          getPipeineStatus(uuid).then((resp) => {
            this.onPrezentationUpload({
              ...prezentationFinalData,
              pipelineCallbackId: this.callbackId,
              pipelineStatus: resp,
            });
          });
        });
      }
    },
    handleSelectSlide(isSelected, slide) {
      if (isSelected) {
        this.selectedSlides.push({ ...slide });
        this.isSelectSlideChanged = true;
      } else {
        this.selectedSlides = this.selectedSlides.filter(
          (item) => item.index !== slide.index,
        );
      }
      this.selectedRef[slide.index] = isSelected;
      this.selectAll =
        this.selectedSlides.length === this.prezentationData?.slidesList.length;
    },
    handleSelectAllSlides(isSelected) {
      if (isSelected) {
        this.selectAll = true;
        const allData =
          this.prezentationData?.slidesList.map((item) => {
            this.selectedRef[item.index] = true;
            return {
              ...item,
            };
          }) || [];
        this.selectedSlides = allData;
        this.isSelectSlideChanged = true;
      } else {
        this.selectedRef = {};
        this.selectedSlides = [];
      }
    },
    handleSelectSlidesNextStep() {
      if (this.selectedSlides && this.selectedSlides.length > 0) {
        this.showWaiting = true;
        this.sanitizeStep = 1;
        this.onPrezentationUpload({
          ...this.prezentationData,
          selectedSlidesList: [...this.selectedSlides].map((slide) => ({
            s3_path: slide?.pdf?.s3_path || slide?.s3_path,
            ...slide,
          })),
          isSelectSlideChanged: this.isSelectSlideChanged,
        });
        setTimeout(() => {
          this.showWaiting = false;
          this.sanitizeStep = 4;
          this.componentExitTime = Date.now();
          this.timeSpent =
            (this.componentExitTime - this.componentEnterTime) / 1000;
          this.nextStep(3);
        }, 1000);
      }
    },
    async transformDataForTransferFlow(prezentation) {
      const data = {
        ...prezentation,
        prezentationName: prezentation.name,
        addToSlides: true,
        slidesList: [],
        selectedSanitizedSlideList: [],
      };

      // Slides
      const token = await getLoggedInUserToken();
      const categoriesRecords = await getSuperhawkCategories();
      this.ideas = Object.keys(categoriesRecords).map((category) => ({
        display_name: categoriesRecords[category],
        category,
      }));
      let index = 0;
      for (const section of prezentation.sections.list) {
        for (const slide of section.slides) {
          const {
            name,
            nodeCount,
            keys: tags,
            template,
            description,
            mlTags,
          } = slide;
          const pptx = { s3_path: slide.filename };
          const pdf = { s3_path: slide.assetId };
          const thumbnail = await this.downloadThumbnail(slide, token);
          const ideas = slide.businessIdeas.map((category) => ({
            category,
            outline: categoriesRecords[category],
          }));

          data.slidesList.push({
            name,
            nodeCount,
            thumbnail,
            pptx,
            pdf,
            ideas,
            tags,
            index,
            ml_detected_tags: mlTags,
            theme_code: template,
            speaker_notes: description,
          });
          index++;
        }
      }
      data.selectedSanitizedSlideList = data.slidesList;
      data.number_of_slides = data.slidesList.length;

      return data;
    },
    async downloadThumbnail(slide, token) {
      let thumbnail = null;

      if (
        slide.isUserUploaded &&
        slide.assetId.indexOf('.pdf') !== -1 &&
        !slide.assetId?.startsWith('newImageReplaceOutput/') &&
        !slide.assetId?.startsWith('/newImageReplaceOutput/')
      ) {
        const awsAssetsDistribution =
          process.env.VUE_APP_AWS_ASSETS_DISTRIBUTION;
        thumbnail = await convertPdfToImage(
          `${awsAssetsDistribution}${slide.assetId.startsWith('/') ? '' : '/'}${
            slide.assetId
          }?accesskey=${token}`,
        );
      } else {
        thumbnail = await this.getThumbnail(slide);
      }
      return thumbnail;
    },

    async getThumbnail(slide) {
      const filePath = slide.assetId;
      let thumbnail = '';
      if (
        filePath &&
        (filePath.startsWith('/output/pptx/') ||
          filePath.startsWith('/newImageReplaceOutput/output/'))
      ) {
        thumbnail = await getFileURLDownload(
          this.currentUser.user.id,
          filePath,
          this.currentUser.userIp,
        );

        if (filePath?.indexOf('.pdf') !== -1) {
          thumbnail = await convertPdfToImage(thumbnail);
        }
      } else if (filePath) {
        thumbnail = await getFileURL(
          this.currentUser.user.id,
          filePath,
          this.currentUser.userIp,
          this.prezentationData.prezentationSource,
        );
      }

      return thumbnail;
    },
    async transferSlidesAsIs() {
      const otherData = {
        [TD_AUD]: this.prezentationData.audienceID,
        [TD_PREZNAME]: this.prezentationData.name,
        [TD_PREZTYPE]: this.prezentationData.type,
        [TD_TEMPLATE]: this.prezentationData.theme,
      };
      trackPrezentationEvents.prezentationsAddToSlidesAddClick(
        this.currentUser,
        otherData,
      );
      if (!this.selectedSlides?.length) {
        return;
      }
      this.setIsUploading(true);
      this.onPrezentationUpload(this.selectedSlides, this.isSelectSlideChanged);

      this.showWaiting = true;
      this.sanitizeStep = 3;

      const { id, audienceID, theme, prezentationType, audienceOwnerID } =
        this.prezentation;
      const sharedEntities = (await getPrezentationShareDetails(id)).message
        .prezentationACL;
      const selectedAudience = await this.getAudience(
        audienceID,
        audienceOwnerID,
      );
      const templateSelected = this.getTemplateByCode(theme);
      const sanitizeType = this.getSanitize(prezentationType);

      const payload = {
        ...this.prezentationData,
        selectedSanitizedSlideList: this.selectedSlides,
        selectedAudience,
        templateSelected,
        sanitizeType,
      };

      sharedEntities.forEach((e) => {
        e.permission_level = e.permission_level.toString();
      });

      try {
        await UploadService.processUploadDeck(payload, sharedEntities, {
          isTransferSlidesFlow: true,
        });
        this.$modal.show(
          TransferSlidesSuccessModalVue,
          {},
          {
            name: 'TransferSlidesSuccessModal',
            width: '449px',
            height: '380px',
            styles: { borderRadius: '20px' },
          },
        );
        this.$router.push(`/home/slides?type=uploaded`);
      } catch (error) {
        console.error(error);
      } finally {
        this.showWaiting = false;
        this.setIsUploading(false);
      }
    },

    async getAudience(audienceId, audienceOwnerId) {
      const audience =
        await audienceApi.methods.getPrezentationAudienceTypesense(
          audienceId,
          audienceOwnerId,
          {
            fields: ['id', 'num_id', 'email', 'type', 'ownerID', 'fingerPrint'],
          },
        );

      if (audience) {
        return {
          ...audience,
          audience_type: audience.type,
          userId: audience.ownerID,
        };
      }
      return null;
    },

    transferSlidesForReview() {
      this.handleSelectSlidesNextStep();
    },

    getSanitize(type) {
      switch (type) {
        case 'Shell':
          return 'full';
        case 'Best Practices':
          return 'partial';
        default:
          return 'no';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.select-block {
  width: 100%;
  .select-slides {
    width: 100%;

    .title,
    .sub-title,
    .virbage {
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
    }

    .title {
      font-size: 28px !important;
      font-family: 'Lato' !important;
      font-weight: bold;
    }

    .sub-title {
      font-family: 'Lato' !important;
      font-size: 24px;
    }

    .virbage {
      font-family: 'Lato' !important;
      font-size: 24px;
    }

    .center {
      display: flex;
      justify-content: center;

      .v-btn {
        font-family: 'Lato', sans-serif;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        height: 32px !important;
        line-height: normal;
        letter-spacing: normal;
        min-width: 64px;
        text-transform: none !important;
      }
    }

    .select-all-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    ::v-deep .v-badge__badge {
      color: #4d5358;
    }
    .slides-list {
      display: flex;
      flex-wrap: wrap;
      max-height: 390px;
      overflow: auto;

      .slide-card {
        width: 250px;
        padding: 10px;
        position: relative;

        .ckeck-box {
          position: absolute;
          top: 0;
          left: 20px;
        }
        .slide-img {
          width: 244px;
          height: 146px;
          margin-bottom: 5px;
          border-radius: 8px;
          filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.1))
            drop-shadow(-1px -1px 4px rgba(0, 0, 0, 0.1));

          &:hover {
            box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3),
              -1px -1px 4px rgba(0, 0, 0, 0.3);
          }
        }

        .slide-text {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100% !important;
        }
      }
    }

    .footer {
      text-align: center;
    }
  }

  .waiting-block {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .image {
      margin-top: 20px;
    }
  }
}
</style>
