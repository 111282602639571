import { render, staticRenderFns } from "./KnowDecks.vue?vue&type=template&id=c61793bc&scoped=true&"
import script from "./KnowDecks.vue?vue&type=script&lang=js&"
export * from "./KnowDecks.vue?vue&type=script&lang=js&"
import style0 from "./KnowDecks.vue?vue&type=style&index=0&id=c61793bc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c61793bc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VItem } from 'vuetify/lib/components/VItemGroup';
import { VItemGroup } from 'vuetify/lib/components/VItemGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCol,VContainer,VItem,VItemGroup,VRow})
