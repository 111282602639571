var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:("idea-chip idea-" + _vm.index + "-dragarea--auto"),on:{"mousedown":function($event){return _vm.click(_vm.data)}}},[_c('div',{staticClass:"chip-title"},[_c('div',{class:("drag-icon " + (_vm.isGalleryIdea ? 'show' : ''))},[(_vm.isDragable)?_c('v-icon',{attrs:{"dense":""}},[_vm._v(" mdi-dots-vertical ")]):_vm._e(),(_vm.isDragable)?_c('v-icon',{attrs:{"dense":""}},[_vm._v(" mdi-dots-vertical ")]):_vm._e()],1),_c('v-tooltip',{attrs:{"bottom":"","max-width":"250px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"title-text"},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.data.outline)+" ")])]}}])},[_c('span',[_c('span',{staticClass:"tooltip-outline-title"},[_vm._v(" "+_vm._s(_vm.data.outline)+" ")]),_c('br'),_c('span',[_vm._v(" "+_vm._s(_vm.getDescription(_vm.data.outline))+" ")])])])],1),(!_vm.isGalleryIdea)?_c('div',{staticClass:"actions"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.onDuplicate}},'v-icon',attrs,false),on),[_vm._v(" mdi-content-copy ")])]}}],null,false,3824563801)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('common.duplicate'))+" ")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.onDelete}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete-outline ")])]}}],null,false,1453302146)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('common.delete'))+" ")])])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }