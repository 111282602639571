<template>
  <div class="template-spinner-wrapper" v-if="loadingThemes">
    <Loading v-if="loadingThemes" />
  </div>
  <div v-else class="slide-settings">
    <div class="back-btn-cntr" v-if="previousRoute === 'search'">
      <v-btn class="btn-back" @click="goToSearchLandingPage">
        <v-icon class="icon" color="primary">mdi-chevron-left</v-icon>
        <span>{{ $t('slides.backToSearch') }}</span>
      </v-btn>
    </div>

    <div class="slide-settings-title">
      {{ companyName }} {{ $t('profile.templates') }}
      <div class="slide-settings-title-icon">
        <v-tooltip right max-width="250" content-class="tooltip-content" fixed>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="icon-title"
              v-text="`mdi-information-outline`"
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <span>{{ $t('profile.chooseTheTemplate') }}</span>
        </v-tooltip>
      </div>
    </div>
    <div class="available-themes-wrapper">
      <div class="available-themes">
        <div class="available-themes-title">
          {{ $t('profile.availableTemplates') }}
        </div>
        <div class="spacer" />
        <v-text-field
          solo
          v-model="templateSearch"
          single-line
          hide-details
          class="template-search-input"
          autocomplete="off"
          :placeholder="
            !!globalSearchResult
              ? $t('profile.displaying')
              : $t('profile.searchTemplates')
          "
          :disabled="globalSearchResult"
          @keyup.stop.native="sortedThemeArray"
        >
          <template v-slot:append>
            <v-icon
              color="primary"
              @click="resetSearch"
              v-text="
                !!globalSearchResult || templateSearch
                  ? `mdi-close`
                  : `mdi-magnify`
              "
            />
          </template>
        </v-text-field>
      </div>
      <v-row
        v-if="
          currentTheme &&
          themes &&
          themes.length > 0 &&
          sortedThemeArray().length
        "
        class="available-themes-container"
        no-gutters
      >
        <v-col
          class="available-themes-list col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3"
          :style="[
            $vuetify.breakpoint.xs ||
            $vuetify.breakpoint.sm ||
            $vuetify.breakpoint.md
              ? { 'min-width': '300px' }
              : {},
          ]"
          cols="12"
          sm="4"
          v-for="item in sortedThemeArray()"
          :key="item.id"
        >
          <div class="available-theme">
            <div class="available-theme-wrapper">
              <v-badge
                :content="$t('filters.new')"
                overlap
                tile
                offset-x="74"
                offset-y="32"
                :value="
                  item.isNew &&
                  item.isNew === true &&
                  currentUser.user.createdAt < item.createdAt
                "
              >
                <div
                  class="available-theme-img"
                  :class="
                    item.isUserUploaded &&
                    item.jobSubmitted &&
                    (item.status === 'inprogress' || item.status === 'failed')
                      ? 'td-thumbnail'
                      : ''
                  "
                >
                  <img
                    :class="`${item.status === 'failed' ? 'img-err' : ''}`"
                    :src="item.thumbnailURL"
                    alt
                  />
                  <div
                    class="td-progress-container"
                    v-if="item.isUserUploaded && item.jobSubmitted"
                  >
                    <v-tooltip bottom fixed max-width="350" close-delay="1500">
                      <template v-slot:activator="{ on, attrs }">
                        <div v-if="item.status === 'inprogress'">
                          <v-progress-circular
                            :value="getTDPercentages[item.designerCurrentIndex]"
                            :size="128"
                            :width="12"
                            :rotate="-90"
                            color="#121619"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <span class="percentage">
                              {{ getTDPercentages[item.designerCurrentIndex] }}%
                              Complete
                            </span>
                          </v-progress-circular>
                        </div>
                        <div v-else-if="item.status === 'failed'">
                          <img
                            class="td-error"
                            :src="'assets/img/templateDesign/warning-line.png'"
                            v-bind="attrs"
                            v-on="on"
                          />
                        </div>
                      </template>
                      <div
                        class="tooltip"
                        style="white-space: pre-wrap"
                        v-if="item.status === 'inprogress'"
                      >
                        {{ $t('templateDesign.inProgressTooltip') }}
                        <a @click="getInTouch">{{
                          $t('templateDesign.getInTouch')
                        }}</a>
                      </div>
                      <div
                        class="tooltip"
                        style="white-space: pre-wrap; text-align: center"
                        v-else-if="item.status === 'failed'"
                      >
                        {{ $t('templateDesign.tdFailedTooltip') }}
                        <a @click="getInTouch">{{
                          $t('templateDesign.getInTouch')
                        }}</a>
                        {{ $t('templateDesign.withDesignTeam') }}
                      </div>
                    </v-tooltip>
                  </div>
                </div>
              </v-badge>
              <div class="templateNameAndShare">
                <div class="templateNameAndShare__content">
                  <v-tooltip
                    bottom
                    content-class="tooltip-content"
                    fixed
                    open-delay="500"
                    close-delay="300"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-card-title
                        v-bind="attrs"
                        v-on="on"
                        v-text="item.name"
                        :class="
                          item.permissionLevel &&
                          item.permissionLevel === 'Editor'
                            ? 'card__title text-truncate available-theme-title'
                            : 'cardTitleForViewer'
                        "
                      />
                      <v-card-subtitle
                        class="subtitle"
                        v-if="item.isUserUploaded"
                      >
                        Created by {{ item.uploadedByName }}
                      </v-card-subtitle>
                    </template>
                    <span class="tooltip">{{ item.name }} </span>
                  </v-tooltip>
                </div>
                <div
                  v-if="
                    item.permissionLevel && item.permissionLevel === 'Editor'
                  "
                  class="templateShareOption"
                  @click="handleShareTemplateModalOpen(item.name, item.id)"
                >
                  <img src="/assets/img/share_template_access.svg" alt />
                  <p>{{ $t('common.share') }}</p>
                </div>
              </div>
            </div>
            <v-btn
              :text="currentTheme.code === item.code"
              :rounded="currentTheme.code !== item.code"
              :disabled="
                item.isUserUploaded &&
                item.jobSubmitted &&
                (item.status === 'inprogress' || item.status === 'failed')
              "
              :color="currentTheme.code !== item.code ? '#21a7e0' : ''"
              :class="`select-theme ${
                currentTheme.code === item.code ? 'active' : ''
              }`"
              :data-pendo-id="
                currentTheme.code === item.code
                  ? 'selected-template'
                  : undefined
              "
              height="36"
              min-width="64"
              @click="handleClickTheme(item)"
            >
              {{
                currentTheme.code === item.code
                  ? $t('tagSlides.currentSelection')
                  : $t('profile.select')
              }}
            </v-btn>
          </div>
        </v-col>
        <v-col
          v-if="!templateSearch && !globalSearchResult"
          cols="12"
          sm="4"
          class="available-themes-list"
        >
          <div class="available-theme">
            <div
              class="available-theme-wrapper add-new"
              data-pendo-id="add-new-template"
              @click="handleAddMore"
            >
              <div class="icon-wrapper">
                <v-icon color="#21a7e0"> mdi-plus </v-icon>
              </div>
              <div class="add-new-title">
                {{ $t('prezentationsList.addMoreBtn') }}
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row
        v-else-if="!themes.length || !sortedThemeArray().length"
        class="add-more-themes"
      >
        <EmptyState
          img-url="/assets/img/no-results-found-templates.svg"
          :is-btn-visible="false"
        >
          <template v-slot:description>
            <p>
              {{ $t('profile.noTemplatesFound') }}<br /><a
                @click="addMoreTemplate"
                >{{ $t('fingerprint.getInTouch') }}</a
              >
              {{ $t('profile.addMoreTemplates') }}
            </p>
          </template>
        </EmptyState>
      </v-row>
      <v-row class="template-spinner-wrapper" v-else>
        <Loading />
      </v-row>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import { MatomoAnalyticsHandler } from '../../common/Analytics/MatomoAnalyticsHandler';
import SlideThemeChangeDetail from './SlideThemeChangeDetail.vue';
import Loading from '../../common/Loading.vue';
import EmptyState from '../../common/EmptyState.vue';
import GetInTouchDetail from './GetInTouchDetail.vue';
import AddingMoreTemplateDetail from './AddingMoreTemplateDetail.vue';
import AddNewTDPopup from './AddNewTDPopup.vue';
import ShareTemplateAccess from './ShareTemplateAccess.vue';
import commonApi from '../../../API/common-api';
import usersApi from '../../../API/users-api';
import {
  sendEmail,
  logSearchQuery,
  createTemplateRequest,
} from '../../../utils/api-helper';
import EventBus from '../../common/event-bus';
import { trackProfileEvents } from '../../common/Analytics/ProfileEvents';
import {
  TD_QUERY,
  TD_TEMPLATE,
} from '../../common/Analytics/MatomoTrackingDataHelper';
import { handleDebounce } from '../SlideImageReplacement/slideImageReplacementUtils';
import ContactSoonModal from '../../common/ContactSoonModal.vue';
import { getFileURL } from '@/utils/calyrex';
import { designerTemplateLimit } from '../../../pages/components/TemplateDesigner/constants.js';

export default {
  name: 'TemplateSelector',
  components: {
    Loading,
    EmptyState,
  },
  data() {
    return {
      previousRoute: '',
      companyName: null,
      loadingThemes: true,
      templateSearch: '',
      user: {},
      globalSearchResult: false,
      globalSearchTemplates: [],
      templateSearchQuery: '',
      designerThemes: [],
    };
  },
  props: {
    selectedTab: {
      type: String,
      required: true,
      default: 'basics',
    },
  },
  computed: {
    ...mapState('users', [
      'currentUser',
      'currentTheme',
      'themes',
      'currentActiveSubscription',
    ]),
    ...mapState('templateDesigner', ['tdOverallStatus']),
    ...mapGetters('templateDesigner', [
      'getTDData',
      'getTDStatuses',
      'getTDPercentages',
      'areAllJobsSubmitted',
    ]),
    ...mapGetters('users', ['getIsNonBrandedCompany']),
  },
  async mounted() {
    this.previousRoute = this.$router.app.previousRoute.name;
    EventBus.$on('GOTO_TEMPLATES', (query) => {
      this.templateSearch = query;
    });
    if (this.$route.query.query) {
      this.searchInitialize();
    }
    this.loadingThemes = true;

    await this.updateThemes();
    this.companyName = this.getCompanyName();
    this.setCurrentTheme(this.currentTheme);
    // setTimeout(() => {
    // }, 5000);
    this.loadingThemes = false;
    setTimeout(() => {
      if (
        this.getTDData.length &&
        this.areAllJobsSubmitted &&
        this.tdOverallStatus !== 'completed'
      ) {
        this.updateDesignerCard();
      }
    }, 1000);
  },
  watch: {
    $route() {
      if (this.$route.query.query) {
        this.searchInitialize();
      }
    },
    selectedTab() {
      if (this.selectedTab === 'templates') {
        this.globalSearchTemplates = [];
        this.globalSearchResult = false;
      }
    },
    templateSearch(newVal) {
      this.onSearch(newVal);
    },
    // eslint-disable-next-line func-names
    tdOverallStatus(status) {
      if (status === 'completed') {
        this.designerTheme = [];
        this.updateThemes();
      }
    },
    getTDStatuses(statuses) {
      statuses.forEach((status) => {
        if (status === 'failed') {
          this.updateDesignerCard();
        }
      });
    },
  },
  methods: {
    ...mapActions('users', [
      'setCurrentUser',
      'setCurrentTheme',
      'loadThemes',
      'setFilteredThemes',
    ]),
    goToSearchLandingPage() {
      this.$router.back();
    },
    async updateDesignerCard() {
      let thumbnailURL = {};
      this.getTDData.forEach(async (tdItem, index) => {
        if (tdItem?.thumbnail?.filepath) {
          thumbnailURL = await getFileURL(
            this.currentUser.user.id,
            tdItem?.thumbnail?.filepath,
            this.currentUser.userIp,
            'IOCUpload',
          );
        }
        this.designerThemes.push({
          ...tdItem,
          designerCurrentIndex: index,
          thumbnailURL,
        });
      });
    },
    onSearch(searchTerm) {
      handleDebounce(() => this.trackTemplateSearchEvent(searchTerm.trim()));
    },
    async updateThemes() {
      const themes = await commonApi.methods.getThemesList();
      const filteredThemes = themes.filter(
        (item) => item.companyID === this.currentUser.user.companyID,
      );
      this.setFilteredThemes(filteredThemes);
    },
    trackTemplateSearchEvent(searchTerm) {
      trackProfileEvents.profileTemplatesSearch(this.currentUser, {
        [TD_QUERY]: searchTerm,
      });
    },
    handleShareTemplateModalOpen(tempName, tempId) {
      this.$modal.show(
        ShareTemplateAccess,
        {
          templateName: tempName,
          templateId: tempId,
          companyName: this.companyName,
        },
        {
          name: 'ShareTemplateAccess',
          width: 700,
          height: 'auto',
          minHeight: 300,
          styles: { borderRadius: '20px' },
        },
      );
    },
    getInTouch() {
      try {
        const params = {
          type: 'template-designer-request',
          meta: {
            firstName: `${this.currentUser?.user?.firstName}`,
            trialDate: `${moment(new Date()).format('MM/DD/YYYY')}`,
          },
        };
        sendEmail(params)
          .then((response) => console.log(response))
          .catch((err) => console.log(err));
      } catch (err) {
        console.log(err);
      }
      this.$modal.show(
        ContactSoonModal,
        {},
        {
          name: 'ContactSoonModal',
          width: '449px',
          height: '503px',
          styles: {
            borderRadius: '12px',
          },
        },
      );
    },
    resetSearch() {
      this.templateSearchQuery = '';
      this.templateSearch = '';
      this.globalSearchResult = false;
      this.globalSearchTemplates = [];
      if (this.$route.fullPath !== '/profile?tab=templates')
        this.$router.push('/profile?tab=templates');
      EventBus.$emit('CLEAR_SEARCH');
    },
    searchInitialize() {
      this.templateSearchQuery = this.$route.query.query;
      const logPayload = {
        searchTerm: this.templateSearchQuery,
        searchTermResult: false,
        type: 'Templates',
      };
      const filteredArray = this.themes.filter((x) =>
        x.name.toLowerCase().includes(this.templateSearchQuery.toLowerCase()),
      );
      this.globalSearchResult = true;
      this.globalSearchTemplates = filteredArray;
      if (filteredArray.length) {
        logPayload.searchTermResult = true;
      }
      logSearchQuery(logPayload)
        .then()
        .catch((err) => console.log(err));
    },
    // displayBadgeInTheme(theme) {
    //   if (theme) {
    //     const currentUserCreatedDate = this.currentUser?.user?.createdAt;
    //     const currentDate = moment(new Date());
    //     const expiryInDays = 15;
    //     const templateReleaseDate = moment(theme.createdAt, 'YYYY-MM-DD');
    //     if (moment(currentUserCreatedDate).isAfter(templateReleaseDate)) {
    //       return false;
    //     }
    //     const duration = moment.duration(currentDate.diff(templateReleaseDate));
    //     return duration.asDays() >= 0 && duration.asDays() <= expiryInDays;
    //   }
    //   return false;
    // },

    handleClickTheme(theme) {
      if (theme.code === this.currentTheme.code) {
        return;
      }
      this.$modal.show(
        SlideThemeChangeDetail,
        {
          index: theme,
          updateThemeIndex: this.updateThemeIndex,
        },
        {
          name: 'SlideThemeChangeDetail',
          width: '540px',
          height: '165px',
          styles: { borderRadius: '18px' },
        },
      );
    },

    async updateThemeIndex(theme) {
      try {
        this.loadingThemes = true;
        MatomoAnalyticsHandler.templateSwitched(this.currentUser, theme);
        trackProfileEvents.profileTemplatesSwitch(this.currentUser, {
          [TD_TEMPLATE]: theme?.name,
        });

        await usersApi.methods
          .updateUserProfile({ defaultTheme: theme.code })
          .then(async (updatedUserDetail) => {
            const userInfo = this.currentUser;
            userInfo.user = { ...userInfo.user, ...updatedUserDetail };
            userInfo.theme = theme;
            await this.setCurrentUser(userInfo);
            this.globalSearchTemplates = [];
            this.globalSearchResult = false;
            EventBus.$emit('CLEAR_SEARCH');
          });
        this.setCurrentTheme(theme);
        this.loadingThemes = false;
      } catch (err) {
        console.error(err);
      }
    },
    handleAddMore() {
      if (
        this.getIsNonBrandedCompany &&
        this.getTDData.length < designerTemplateLimit
      ) {
        this.$modal.show(
          AddNewTDPopup,
          {
            templateRequestFrom: 'Profile',
          },
          {
            name: 'AddNewTDPopup',
            width: '400px',
            height: '346.91px',
            styles: { borderRadius: '12px' },
          },
        );
      } else {
        this.$modal.show(
          AddingMoreTemplateDetail,
          {
            templateRequestFrom: 'Profile',
          },
          {
            name: 'AddingMoreTemplateDetail',
            width: '564px',
            height: '507px',
            styles: { borderRadius: '12px' },
          },
        );
      }
    },

    addMoreTemplate() {
      // template request call
      createTemplateRequest({
        requestFrom: 'Profile',
      })
        .then((resp) => {
          console.log(resp);
        })
        .catch((err) => {
          console.log(err);
        });

      // email api call
      try {
        const mBody =
          `${this.currentUser.user.firstName} ${this.currentUser.user.lastName}(${this.currentUser.user.id})` +
          ` has expressed interest in additional templates`;
        const params = {
          type: 'template-request',
          meta: {
            message: mBody,
            requester: this.currentUser.user.firstName,
          },
        };
        sendEmail(params)
          .then((response) => console.log(response))
          .catch((err) => console.log(err));
      } catch (err) {
        console.error(err);
      }

      this.$modal.show(
        GetInTouchDetail,
        {
          title: 'prezentation.thanksForYourInterestInTemplates',
        },
        {
          name: 'GetInTouchDetail',
          width: '564',
          height: '507px',
        },
      );
    },
    // TODO: investigate if this can moved to computed property
    sortedThemeArray() {
      if (this.globalSearchTemplates.length) {
        return this.globalSearchTemplates;
      }
      let finalArray = [];
      const currentTheme = this.themes.find(
        (x) => x.code === this.currentTheme?.code,
      );
      finalArray = this.themes.filter(
        (x) => x.code !== this.currentTheme?.code,
      );

      finalArray.sort((a, b) => {
        if (a.name > b.name) return 1;
        if (b.name > a.name) return -1;
        return 0;
      });
      this.designerThemes.forEach((item) => {
        // Add designer theme only if not in themes, in progress/failed and not 100%
        if (
          ((item &&
            item?.jobSubmitted &&
            item?.status === 'inprogress' &&
            item?.percentage < 100) ||
            item?.status === 'failed') &&
          !finalArray.find((template) => template.code === item.code)
        ) {
          finalArray.unshift({
            isNew: false,
            ...item,
          });
        }
      });
      if (currentTheme) {
        finalArray.unshift(currentTheme);
      }

      if (this.templateSearch) {
        const filteredArray = finalArray.filter((x) =>
          x.name.toLowerCase().includes(this.templateSearch.toLowerCase()),
        );
        return filteredArray;
      }
      return finalArray;
    },
    getCompanyName() {
      if (this.currentUser.company.displayName)
        return (
          this.currentUser.company.displayName.charAt(0).toUpperCase() +
          this.currentUser.company.displayName.slice(1)
        );
      return (
        this.currentUser.company.name.charAt(0).toUpperCase() +
        this.currentUser.company.name.slice(1)
      );
    },
  },
  beforeDestroy() {
    EventBus.$off('GOTO_TEMPLATES');
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/variables.scss';
::v-deep .v-badge__badge {
  background: $new-tag-bg;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 600;
  height: fit-content;
  padding: 6px 16px;
  width: fit-content;
  z-index: 1;
}
.back-btn-cntr {
  width: fit-content;

  .btn-back {
    border: none;
    text-transform: none;
    background-color: #ffffff !important;
    box-shadow: none;
    letter-spacing: normal;
    color: #21a7e0;
    padding: 0;
    margin: 0px 0px 0px -8px;
  }
}
.template-spinner-wrapper {
  height: 300px;
}
.slide-settings {
  padding: 12px;
}
.slide-settings-title {
  align-items: center;
  display: flex;
  font-size: 24px;
  font-stretch: normal;
  font-style: normal;
  font-weight: bold;
  justify-content: flex-start;
  line-height: normal;
  letter-spacing: normal;
  margin-bottom: 30px;
  .slide-settings-title-icon {
    margin-left: 8px;
    .icon {
      font-size: 24px;
    }
  }
}
.available-themes-title {
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  margin-bottom: 14px;
  text-align: left;
}
.available-themes-list {
  align-items: top;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .available-theme {
    align-items: center;
    flex-direction: column;
    margin-bottom: 45px;
    max-width: 320px;
    width: 100%;
    .available-theme-img {
      width: fit-content;
    }
    img {
      height: 171px;
      width: 308px;
      &.img-err {
        filter: grayscale(1);
      }
    }
    .td-error {
      height: 64px;
      width: 64px;
    }
    .available-theme-wrapper {
      display: grid;
      border-radius: 8px;
      box-shadow: 0 1px 16px 0 rgba(0, 0, 0, 0.1);
      margin: auto;
      margin-bottom: 35px;
      overflow: hidden;
      width: fit-content;
      transition: all ease 0.5s;
      &.add-new {
        align-items: center;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        height: 226px;
        justify-content: center;
        width: 308px;
        .icon-wrapper {
          transform: scale(3);
        }
        .add-new-title {
          color: $zen-blue-default;
          font-size: 20px;
          margin-top: 20px;
        }
      }
      &:hover {
        transform: scale(1.05);
      }
      .templateNameAndShare {
        display: grid;
        grid-template-columns: 216px auto;
        gap: 4px;
        text-align: left;
        cursor: pointer;
        min-height: 64px;
        padding: 10px 14px;
        &__content {
          display: grid;
          align-items: center;
        }
        .subtitle {
          margin-top: 2px;
          padding: 0;
          color: #444444;
        }
        .templateShareOption {
          display: grid;
          grid-template-columns: 20px 1fr;
          align-items: center;
          gap: 3px;
          img {
            min-width: 20px;
            height: 20px;
            margin-right: 2px;
          }
          p {
            margin: 0;
            color: rgb(33, 167, 224);
          }
        }
      }
    }
    .v-card__title,
    .cardTitleForViewer {
      display: block;
      font-size: 16px;
      font-stretch: normal;
      font-style: normal;
      font-weight: normal;
      line-height: normal;
      letter-spacing: normal;
      padding: 0;
      text-align: left;
      white-space: nowrap !important;
      overflow: hidden !important;
      text-overflow: ellipsis !important;
    }
    .select-theme {
      color: white;
      font-size: 20px;
      font-stretch: normal;
      font-style: normal;
      font-weight: bold;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      text-transform: capitalize;
    }
    .select-theme.active {
      color: $zen-blue-default;
      width: auto;
    }
  }
}
.available-themes-container {
  padding-top: 20px;
}
.add-more-themes {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  .v-image {
    margin: 20px;
  }
  a {
    text-decoration: none;
  }
}
.available-themes {
  align-items: center;
  display: flex;
  .template-search-heading {
    font-size: 24px;
    font-weight: bold;
  }
  .spacer {
    width: 30%;
  }
  .template-search-input {
    background-color: #fff;
    border-radius: 24px;
    box-shadow: 0 2px 5px 1px rgba(64, 60, 67, 0.16);
    margin-bottom: 14px;
    margin-right: 40px;
    margin-top: 0px;
    // padding: 8px 15px 8px 23px;
    z-index: 3;
    ::v-deep .v-input__slot {
      box-shadow: none !important;
      .v-text-field__slot {
        input {
          color: #464a4c;
        }
        input::placeholder {
          color: #464a4c;
        }
      }
    }
    ::v-deep .v-input__slot::before {
      border: unset;
    }
  }
  ::v-deep {
    .v-expansion-panel--active
      > .v-expansion-panel-header--active
      .v-expansion-panel-header__icon:not(.v-expansion-panel-header__icon--disable-rotate)
      .v-icon {
      color: $zen-blue-default;
    }
    .v-expansion-panels {
      display: block;
    }
    .v-input--is-disabled:not(.v-input--is-readonly) {
      pointer-events: all;
    }
  }
}
.td-thumbnail {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.td-progress-container {
  word-wrap: break-word;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.4);

  ::v-deep .v-progress-circular__info {
    background: #c1c7cd;
    height: 100%;
    width: 100%;
    border-radius: 50%;
  }

  .percentage {
    width: 70%;
  }
  ::v-deep .v-progress-circular__underlay {
    stroke: #f2f4f8;
  }
}
.v-tooltip__content {
  pointer-events: initial;
}
.center-tooltip {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  width: 300px;
  opacity: 1;
}
</style>
