import { render, staticRenderFns } from "./SuccessPaymentDetail.vue?vue&type=template&id=515145e5&scoped=true&"
import script from "./SuccessPaymentDetail.vue?vue&type=script&lang=js&"
export * from "./SuccessPaymentDetail.vue?vue&type=script&lang=js&"
import style0 from "./SuccessPaymentDetail.vue?vue&type=style&index=0&id=515145e5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "515145e5",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VIcon})
