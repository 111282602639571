<template>
  <div v-if="opRequest.status === 'Completed'">
    <v-menu
      v-model="popover"
      :close-on-content-click="false"
      :nudge-width="300"
      offset-y
      left
      content-class="op-rating-popover"
    >
      <template v-slot:activator="{ attrs }">
        <div
          :class="[
            'op-rating',
            typeof opRequest.isLiked === 'boolean' && 'op-rating--disabled',
          ]"
          v-bind="attrs"
        >
          <div class="op-rating__like">
            <v-btn
              icon
              @click.stop="
                typeof opRequest.isLiked === 'boolean'
                  ? () => {}
                  : handleRatinClick(true)
              "
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="#21a7e0" size="24" v-bind="attrs" v-on="on">
                    {{
                      isLiked === true ? 'mdi-thumb-up' : 'mdi-thumb-up-outline'
                    }}
                  </v-icon>
                </template>
                <span>{{ $t('common.like') }}</span>
              </v-tooltip>
            </v-btn>
          </div>
          <div class="op-rating__dislike">
            <v-btn
              icon
              @click.stop="
                typeof opRequest.isLiked === 'boolean'
                  ? () => {}
                  : handleRatinClick(false)
              "
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="#21a7e0" size="24" v-bind="attrs" v-on="on">
                    {{
                      isLiked === false
                        ? 'mdi-thumb-down'
                        : 'mdi-thumb-down-outline'
                    }}
                  </v-icon>
                </template>
                <span>{{ $t('common.disLike') }}</span>
              </v-tooltip>
            </v-btn>
          </div>
        </div>
      </template>
      <v-card>
        <v-btn
          elevation="0"
          icon
          class="op-rating-popover__close"
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <div class="op-rating-popover__content">
          <v-form ref="form" lazy-validation>
            <div>
              <label class="form__label">
                {{ $t('overnightPresentations.feedback') }}
              </label>
              <v-textarea
                v-model="form.feedback"
                label="Type your feedback"
                required
                solo
                :rows="4"
                class="form__textarea"
              />
            </div>
          </v-form>
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            style="text-transform: none !important"
            rounded
            class="primary-button"
            width="100"
            height="48"
            @click="submitRatings"
            :loading="loading"
          >
            {{ $t('common.submit') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { updateRating } from '../../../utils/api-helper';

export default {
  name: 'OpRatingMenu',
  props: {
    opRequest: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      popover: false,
      form: {
        isLiked: false,
        feedback: '',
      },
      loading: false,
    };
  },
  methods: {
    handleRatinClick(val) {
      this.popover = true;
      this.form.isLiked = val;
    },

    async submitRatings() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        const payload = {
          ticketId: this.opRequest.ticketId,
          ...this.form,
        };
        await updateRating(payload);
        this.$emit('update', payload);
        this.loading = false;
        this.popover = false;
        this.$refs.form.reset();
      }
    },

    close() {
      this.popover = false;
    },
  },
  computed: {
    isLiked() {
      if (typeof this.opRequest.isLiked === 'boolean') {
        return this.opRequest.isLiked;
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss', '@/scss/app.scss';
.op-rating {
  display: flex;
  align-items: center;
  &--disabled {
    opacity: 0.8;
    cursor: default;
  }
}
.op-rating-popover {
  border-radius: 16px;
  &__content {
    padding: 22px;
    padding-bottom: 0;
    padding-top: 42px;
  }
  &__close {
    position: absolute;
    right: 4px;
    top: 4px;
  }
}
.form {
  &__label {
    font-weight: 600;
    margin-bottom: 6px;
    font-size: 16px;
    display: block;
  }
  &__action {
    width: 100px;
  }
  &__textarea {
    border-radius: 8px;
  }
}
</style>
