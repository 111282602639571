<template>
  <div class="knowDecksIdeaChipsContainer">
    <div class="chipsLoader" v-if="loading">
      <v-skeleton-loader
        type="actions"
        v-for="(item, ind) in loaders"
        :key="ind"
      />
    </div>
    <v-sheet max-width="100%" v-else>
      <v-chip-group
        show-arrows
        mandatory
        cols="1"
        data-pendo-id="best=practices-storyline-chips"
      >
        <v-chip
          :class="getOutlineChipsClasss(outline)"
          outlined
          :id="outline.name === 'All' ? 'allChip' : ''"
          v-for="(outline, index) in getFrequentOutlines"
          :key="index"
          exact-active-class=""
          @click="handleOutlinesChipsClick(outline.name)"
          @mouseenter="
            handleOutlineHovered(outline.name, index, getFrequentOutlines)
          "
          @mouseleave="handleOutlineLeave()"
        >
          <!-- Name attribute has been added for autmation testing purpose !-->
          <div class="textWrapper" :name="outline.name">
            {{ outline.name }}
          </div>
        </v-chip>
      </v-chip-group>
    </v-sheet>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import {
  getBestPracticesFrequentOutlines,
  logSearchQuery,
} from '../../../utils/api-helper';
import { trackKnowEvents } from '../../common/Analytics/KnowEvents';
import {
  TD_QUERY,
  TD_PLACEMENT,
  TD_NORESULTSFOUND,
} from '../../common/Analytics/MatomoTrackingDataHelper';

export default {
  name: 'KnowDecksIdeaChips',
  data() {
    return {
      defaultOutlines: [
        { name: 'Competitor Review' },
        { name: 'Launch Readiness Review' },
        { name: 'Sales Update' },
        { name: 'Brand plan' },
        { name: 'Sales Contest Rollout' },
        { name: 'Tactical Plan' },
        { name: 'All Hands' },
        { name: 'Incentive compensation' },
        { name: 'Congress Plan' },
        { name: 'Territory Alignment' },
      ],
      outlineHovered: '',
      loading: false,
      loaders: [1, 2, 3, 4, 5],
    };
  },
  props: {
    resetParentFilters: {
      type: Function,
      required: false,
    },
    doFilterChange: {
      type: Function,
      required: false,
    },
  },
  computed: {
    ...mapState('users', ['currentUser']),
    ...mapGetters('bestPracticesExamples', [
      'getFrequentOutlines',
      'getSelectedOutlines',
      'getDecksLoaded',
    ]),
  },
  methods: {
    ...mapActions('bestPracticesExamples', [
      'setFrequentOutlines',
      'setSelectedOutlinesAndSearch',
      // 'setSortByFilterDecks',
    ]),
    removeDuplicatesOutlinesFromDefault(trends, defaults) {
      const trendOutlinesNames = trends.map((tr) => tr.type);
      const defaultTrends = defaults.filter(
        (df) => !trendOutlinesNames.includes(df.name),
      );
      return defaultTrends;
    },

    getOutlineChipsClasss(outline) {
      let cls = '';
      if (this.getSelectedOutlines.indexOf(outline.name) !== -1) {
        cls = 'outlineChipActive';
      } else {
        cls = 'outlineChipAvailable';
      }
      return outline.name === 'All' ? `${cls}All` : cls;
    },

    handleOutlinesChipsClick(outline) {
      // this.setSortByFilterDecks('Recommended');
      this.resetParentFilters();
      this.setSelectedOutlinesAndSearch(outline);
      this.doFilterChange([], ['All'], 'tag');
      if (this.isChipActive(outline)) this.logIdeaChipClick(outline);
      trackKnowEvents.trackKnowIdeachipClick(this.currentUser, {
        [TD_QUERY]: outline,
        [TD_PLACEMENT]: 'Decks',
        [TD_NORESULTSFOUND]: this.getDecksLoaded.length === 0 ? '1' : '0',
      });
    },
    isChipActive(outline) {
      if (this.getSelectedOutlines.indexOf(outline) !== -1) {
        return true;
      }
      return false;
    },
    logIdeaChipClick(item) {
      const itemClicked = item.toLowerCase();
      const payload = {
        searchTermResult: !!this.getDecksLoaded.length,
        type: 'BPE Deck Chip',
        searchTerm: itemClicked,
        searchTermTranslated: itemClicked,
      };
      logSearchQuery(payload)
        .then((resp) => {
          console.log(resp);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handleOutlineHovered(outlineName, index, outlines) {
      this.outlineHovered = outlineName;
      if (index + 1 === outlines.length) {
        document.querySelector('.v-slide-group__next').click();
      }
    },

    handleOutlineLeave() {
      this.outlineHovered = '';
    },
  },
  async mounted() {
    this.loading = true;
    await getBestPracticesFrequentOutlines()
      .then((resp) => {
        const outlinesFrequencies = resp?.data?.items ? resp.data.items : [];
        let outlines = [];
        if (outlinesFrequencies.length < 10) {
          const frequenciesSize = outlinesFrequencies.length;
          const itemsToSubstitute = 10 - frequenciesSize;
          outlines = [
            ...outlinesFrequencies.map((outl) => ({
              ...outl,
              name: outl.type,
            })),
          ].concat(
            this.removeDuplicatesOutlinesFromDefault(
              outlinesFrequencies,
              this.defaultOutlines,
            ).slice(0, itemsToSubstitute),
          );
        } else {
          outlines = [
            ...outlinesFrequencies.map((outl) => ({
              ...outl,
              name: outl.type,
            })),
          ];
        }
        this.setFrequentOutlines([{ name: 'All' }, ...outlines]);
      })
      .catch((err) => {
        console.log(err);
        this.setFrequentOutlines([{ name: 'All' }, ...this.defaultOutlines]);
      });
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
.knowDecksIdeaChipsContainer {
  display: flex;
  // width: 100%;
  .chipsLoader {
    display: flex;
    ::v-deep .v-skeleton-loader {
      .v-skeleton-loader__actions {
        padding: 0 !important;
        width: 476px !important;
        .v-skeleton-loader__button {
          margin: 0px 28px 0px 0px;
          border-radius: 20px;
          width: 210px !important;
          background: rgb(150 145 145 / 12%) !important;
        }
      }
    }
  }
  .v-sheet {
    .v-item-group {
      .v-slide-group__wrapper {
        .v-slide-group__content {
          .outlineChipActiveAll,
          .outlineChipAvailableAll {
            border-radius: 40px;
            font-size: 16px;
            font-weight: 500;
            height: 40px;
            margin-right: 18px !important;
            padding: 0 25px !important;
          }
          .outlineChipActiveAll {
            background-color: #21a7e0 !important;
            color: white !important;
          }
          .outlineChipAvailableAll {
            background-color: white !important;
            color: #21a7e0 !important;
            border-color: #21a7e0 !important;
          }
          .outlineChipActive,
          .outlineChipAvailable {
            border-radius: 40px;
            font-size: 16px;
            font-weight: 500;
            height: 40px;
            margin-right: 18px !important;
            padding: 0 25px !important;
            width: 210px !important;
            min-width: 210px !important;
            ::v-deep .v-chip__content {
              width: 100% !important;
              min-width: 100% !important;
              max-width: 100% !important;
              .textWrapper {
                width: 100%;
                text-align: center;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
            &:hover {
              width: auto !important;
              transition: width 1s, visibility 1s, opacity 1s ease-out;
            }
            &:before {
              opacity: 0 !important;
            }
          }
          .outlineChipActive {
            background-color: #21a7e0 !important;
            color: white !important;
          }
          .outlineChipAvailable {
            background-color: white !important;
            color: #21a7e0 !important;
            border-color: #21a7e0 !important;
          }
        }
      }
      ::v-deep .v-slide-group__prev {
        justify-content: left !important;
        align-items: left !important;
        min-width: 40px !important;
      }
      ::v-deep .v-slide-group__next {
        justify-content: right !important;
        align-items: right !important;
        min-width: 40px !important;
      }
    }
  }
}
</style>
