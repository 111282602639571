<template>
  <div class="heart_modal_container">
    <v-btn class="close-btn" icon @click="close">
      <v-icon dark v-text="`mdi-close`" />
    </v-btn>
    <div class="first-container">
      <v-img
        class="logo-with_heart__img"
        :src="'/assets/img/heart/logo_with_heart.svg'"
        alt
      ></v-img>
      <p class="help-message">
        {{ $t('build.step3.helpMessage') }}
      </p>
    </div>
    <h4 class="partner_heading">{{ $t('build.step3.givingPartner') }}</h4>
    <div class="second-container">
      <v-img
        class="no-kid-hungry__img"
        :src="'/assets/img/heart/no_kid_hungry.svg'"
        alt
      ></v-img>
      <div class="redirecting_box">
        <a
          class="redirecting_link"
          href="https://www.prezent.ai/giving"
          target="_blank"
          @click="onVisitSite"
        >
          {{ $t('build.step3.visitSite') }}
          <v-img
            class="external_link_logo"
            :src="'/assets/img/heart/visit-external-site.svg'"
            alt
          ></v-img>
        </a>
      </div>
    </div>
    <div
      class="donation_user__image"
      v-if="contributors.length === 0 && origin !== 'FingerprintTest'"
    >
      {{ Number(50 + totalCount).toLocaleString() }}
      {{ $t('build.step3.otherMembersHelped') }}
    </div>
    <div
      class="user-donation-section"
      v-if="contributors.length > 0 && origin !== 'FingerprintTest'"
    >
      <div class="user-donation-section--list">
        <v-avatar
          size="56"
          v-for="(contributor, index) in contributors"
          :color="
            contributor.profileImage === null || contributor.profileImage === ''
              ? 'blue'
              : 'blue'
          "
          :key="index"
          :class="
            index >= 1
              ? 'user-donation-section--item'
              : 'user-donation-section--item__border'
          "
        >
          <v-tooltip
            bottom
            max-width="200"
            content-class="tooltip-content"
            fixed
            v-if="
              contributor.profileImage === null ||
              contributor.profileImage === ''
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
                class="white--text text-h5"
                style="color: white; font-size: 27px; font-weight: 250"
              >
                {{
                  `${contributor.firstName
                    .charAt(0)
                    .toUpperCase()}${contributor.lastName
                    .charAt(0)
                    .toUpperCase()}`
                }}
              </span>
            </template>
            <span>{{
              `${contributor.firstName} ${contributor.lastName}`
            }}</span>
          </v-tooltip>
          <v-tooltip
            bottom
            max-width="200"
            content-class="tooltip-content"
            fixed
            v-else
          >
            <template eager v-slot:activator="{ on, attrs }">
              <v-img
                :src="contributor.profileImageUrl"
                contain
                alt
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <div
              :style="{
                padding: '6px',
              }"
            >
              <span class="userWithPicNames">
                {{ `${contributor.firstName} ${contributor.lastName}` }}
              </span>
            </div>
          </v-tooltip>
        </v-avatar>
      </div>
      <p class="user-donation-section--user-count">
        +{{ Number(50 + totalCount - contributors.length).toLocaleString() }}
        {{ $t('build.step3.otherMembersHelped') }}
      </p>
    </div>
  </div>
</template>
<script>
// import { Storage } from 'aws-amplify';

export default {
  name: 'LearnMorePop',
  props: {
    totalCount: {
      type: Number,
    },
    origin: {
      type: String,
      required: false,
      default: '',
    },
    contributors: {
      type: Array,
      default: () => [],
      required: true,
    },
    onVisitSite: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    close() {
      this.$modal.hide('LearnMorePop');
    },
  },
};
</script>

<style lang="scss" scoped>
// @import '@/scss/variables.scss', '@/scss/app.scss';

.heart_modal_container {
  padding: 10px;
  background-color: white;

  .close-btn {
    position: absolute;
    top: 0px;
    right: 0px;
    margin: 10px 5px;
  }

  .first-container {
    display: flex;

    .logo-with_heart__img {
      max-height: 70px;
      max-width: 70px;
    }

    .help-message {
      margin-top: 10px;
      margin-left: 10px;
      font-size: 14.5px;
      min-width: 350px;
    }
  }
  .partner_heading {
    text-align: center;
  }
  .second-container {
    // background-color: black;
    position: relative;
    .no-kid-hungry__img {
      opacity: 1;
      display: block;
      transition: 0.5s ease;
      box-sizing: border-box;
      backface-visibility: hidden;
      height: 105%;
      width: 105%;
      box-shadow: 1px black;
    }
    .redirecting_box {
      transition: 0.5s ease;
      opacity: 0;
      height: 100%;
      width: 100%;
      left: 50%;
      top: 50%;
      border-radius: 5px;
      justify-content: center;
      align-items: center;
      position: absolute;
      background-color: black;
      transform: translate(-50%, -50%);
      text-align: center;
      color: white;
      display: flex;
      .redirecting_link {
        text-decoration: none;
        display: flex;
        color: white;
        .external_link_logo {
          height: 15px;
          margin-top: 3px;
          margin-left: 3px;
          width: 15px;
          background-color: transparent;
        }
      }
    }
  }
  .second-container:hover {
    .no-kid-hungry__img {
      opacity: 0.3;
    }
    .redirecting_box {
      opacity: 0.5;
    }
  }
  .donation_user__image {
    margin: 10px;
  }
  .members_count {
    color: gray;
    margin-left: -10px;
    margin-top: 10px;
  }
}
.v-menu__content,
.theme--light,
.v-menu__content--fixed {
  background: #fff;
}
.user-donation-section {
  margin-top: 5px;
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  &--list {
    align-items: flex-start;
    display: flex;
    justify-content: flex-start;
  }
  &--item {
    border: 2px solid white !important;
    margin-left: -20px;
  }
  &--item__border {
    border: 2px solid white !important;
  }
  &--user-count {
    color: #7b7b7b;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    margin-top: 20px;
    margin-left: 10px;
  }
}
</style>
