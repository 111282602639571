<template>
  <div class="sanitize-block">
    <div class="select-slides" v-if="sanitizeStep === 0">
      <h1 class="title">{{ $t('upload.anonymizeSlides') }}</h1>
      <p class="pt-2 virbage">
        {{ $t('upload.anonymizeSlidesText') }}
      </p>

      <v-radio-group v-model="sanitizeType">
        <v-row>
          <v-col cols="4" class="">
            <div :class="`radio-card ${sanitizeType === 'no' ? 'active' : ''}`">
              <v-radio value="no"></v-radio>
              <img src="/assets/img/uploader/radio-card.svg" alt />
              <div>
                <div class="radio-card-title">
                  {{ $t('upload.noAnonymization') }}
                </div>
                <div class="radio-card-description">
                  {{ $t('upload.noAnonymizationText') }}
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="4" class="">
            <div
              :class="`radio-card ${
                sanitizeType === 'partial' ? 'active' : ''
              }`"
              data-pendo-id="partial-sanitization-tab"
            >
              <v-radio value="partial"></v-radio>
              <img src="/assets/img/uploader/radio-card.svg" alt />
              <div>
                <div class="radio-card-title">
                  {{ $t('upload.partialAnonymization') }}
                </div>
                <div class="radio-card-description">
                  {{ $t('upload.partialAnonymizationText') }}
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="4" class="">
            <div
              :class="`radio-card ${sanitizeType === 'full' ? 'active' : ''}`"
            >
              <v-radio value="full"></v-radio>
              <img src="/assets/img/uploader/radio-card.svg" alt />
              <div>
                <div class="radio-card-title">
                  {{ $t('upload.fullAnonymization') }}
                </div>
                <div class="radio-card-description">
                  {{ $t('upload.fullAnonymizationText') }}
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-radio-group>

      <div :class="`main-body`">
        <div class="total-slides-block">
          <div class="mb-title">
            {{
              $t('upload.slideLabel', {
                slideNumber: selectedSlideIndex + 1,
                totalSlides: prezentationData.selectedSlidesList.length,
              })
            }}
          </div>
          <div class="slide-items" v-if="prezentationData">
            <v-img
              v-for="(slide, index) in prezentationData.selectedSlidesList"
              :key="slide.index"
              :src="
                slide.thumbnail || '/assets/img/slides/placeholder-slide.svg'
              "
              alt
              :class="`slide-img ${
                selectedSlideOriginal.s3_path === slide.s3_path ? 'active' : ''
              }`"
              loading="lazy"
              :aspect-ratio="16 / 9"
              @click="(e) => handleSlideSelect(slide, index, e)"
            />
          </div>
        </div>
        <div class="original-block">
          <div class="mb-title">{{ $t('upload.original') }}</div>
          <v-img
            :src="
              selectedSlideOriginal.thumbnail ||
              '/assets/img/slides/placeholder-slide.svg'
            "
            alt
            class="slide-img"
            lazy-src="/assets/img/slides/placeholder-slide.svg"
            loading="lazy"
            :aspect-ratio="16 / 9"
          />
        </div>
        <div class="preview-block" data-pendo-id="preview-slide">
          <div class="mb-title">
            {{ $t('navbarActions.addColleagueDetail.preview') }}
          </div>
          <template v-if="selectedSlidePreview.thumbnail">
            <v-img
              :src="selectedSlidePreview.thumbnail"
              alt
              lazy-src="/assets/img/slides/placeholder-slide.svg"
              class="slide-img"
              :aspect-ratio="16 / 9"
            />
          </template>
          <template v-else>
            <div class="preview-noimage">
              <v-img
                alt
                src="/assets/img/slides/placeholder-slide.svg"
                class="slide-img"
                :aspect-ratio="16 / 9"
              >
              </v-img>
              <div class="spinner-noimage">
                <v-progress-circular
                  indeterminate
                  color="primary"
                  :size="30"
                ></v-progress-circular>
              </div>
            </div>
          </template>
        </div>
      </div>

      <div class="center">
        <v-btn
          class="mt-7 ma-2 btn"
          rounded
          color="primary"
          @click="handleNextStep"
          :disabled="
            fullSanitizationLoading ||
            partialSanitizationLoading ||
            noSanitizationLoading
          "
          data-pendo-id="presentation-next"
        >
          {{ $t('common.next') }}
        </v-btn>
      </div>
    </div>

    <div v-if="showWaiting && sanitizeStep === 1" class="waiting-block">
      <clip-loader
        :loading="showWaiting"
        :color="`#21a7e0`"
        :width="20"
        :height="20"
      />
      <br />
      <h2>{{ $t('upload.anonymizingSlides') }}</h2>
      <div class="image">
        <img src="/assets/img/uploader/directing-new-2.svg" alt width="350px" />
      </div>
    </div>
    <div v-if="showWaiting && sanitizeStep === 2" class="waiting-block">
      <clip-loader
        :loading="showWaiting"
        :color="`#21a7e0`"
        :width="20"
        :height="20"
      />
      <br />
      <h2>{{ $t('upload.intelligentTags') }}</h2>
      <div class="image">
        <img src="/assets/img/uploader/directing-new-3.svg" alt width="350px" />
      </div>
    </div>
  </div>
</template>

<script>
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import { mapState } from 'vuex';
import { getPipeineStatus } from '../../../utils/api-helper';
import { getLoggedInUserToken } from '../../../utils/api';
import { convertPdfToImage } from '../../../utils/pdf-to-png';
import { mySetInterval } from '../../../utils/common';
import { trackUploadEvents } from '../../common/Analytics/UploadEvents';
import {
  TD_COMMON_COLUMN_VALUE,
  TD_COMMON_COLUMN_NAME,
} from '../../common/Analytics/MatomoTrackingDataHelper';

export default {
  name: 'SanitizeSlides',
  components: {
    ClipLoader,
  },
  data() {
    return {
      componentEnterTime: 0,
      componentExitTime: 0,
      timeSpent: 0,
      sanitizeStep: 0,
      selectAll: false,
      showWaiting: false,
      selectedSlides: [],
      sanitizeType: 'no',
      selectedSlidePreview: {},
      selectedSlideOriginal: {},
      partialSanitizedData: [],
      fullSanitizedData: [],
      noSanitizedData: [],
      fullSanitizationLoading: false,
      partialSanitizationLoading: false,
      noSanitizationLoading: false,
      selectedSlideIndex: 0,
      pipelineStatus: null,
    };
  },

  props: {
    nextStep: {
      type: Function,
      default: () => ({}),
    },
    prezentationData: {
      type: Object,
      default: () => ({}),
    },
    onPrezentationUpload: {
      type: Function,
      default: () => ({}),
    },
    isTransferFlow: Boolean,
  },
  async mounted() {
    this.componentEnterTime = Date.now();
    const [slide] = this.prezentationData.selectedSlidesList;
    this.selectedSlideOriginal = slide;

    if (this.prezentationData?.sanitizeType) {
      this.sanitizeType = this.prezentationData.sanitizeType;
    }

    this.pipelineStatus = this.prezentationData.pipelineStatus;
    this.fullSanitizationLoading = true;
    this.partialSanitizationLoading = true;
    this.noSanitizationLoading = true;

    if (this.isTransferFlow) {
      // this.noSanitizedData = [...this.prezentationData.selectedSlidesList];

      const timeInterval = 3000;
      const maxTime = 2 * 60 * 1000;
      mySetInterval(
        timeInterval,
        maxTime,
        (interval) => {
          getPipeineStatus(this.prezentationData.pipelineCallbackId)
            .then((resp) => {
              this.pipelineStatus = resp;
              if (
                resp &&
                Object.keys(resp.outputs).length > 0 &&
                ((resp.outputs.full_sanitize &&
                  resp.outputs.full_sanitize.status === 'success') ||
                  (resp.outputs.partial_sanitize &&
                    resp.outputs.partial_sanitize.status === 'success'))
              ) {
                clearInterval(interval);
                this.processSuccessData();
              }
            })
            .catch((error) => {
              console.log(error);
              clearInterval(interval);

              this.fullSanitizationLoading = false;
              this.partialSanitizationLoading = false;
              this.noSanitizationLoading = false;
            });
        },
        () => {
          // Timeout code here
          this.fullSanitizationLoading = false;
          this.partialSanitizationLoading = false;
          this.noSanitizationLoading = false;
        },
      );
    } else {
      this.getPipelineStatusUntilFinished();
      this.processSuccessData();
    }
  },
  watch: {
    async sanitizeType() {
      const [slide] = this.prezentationData.selectedSlidesList;
      this.selectedSlidePreview = { thumbnail: null };
      this.selectedSlideOriginal = slide;
      this.selectedSlideIndex = 0;
      this.selectedSlidePreview = this.getPreviewSlide();
    },
  },
  computed: {
    ...mapState('users', ['currentUser']),
    disableNextButton() {
      if (this.sanitizeType === 'full') {
        return this.fullSanitizationLoading;
      }
      if (this.sanitizeType === 'partial') {
        return this.partialSanitizationLoading;
      }
      if (this.sanitizeType === 'no') {
        return this.noSanitizationLoading;
      }
      return true;
    },
  },
  methods: {
    processSuccessData() {
      const slideIndexToIdxMapping = {};
      if (this.prezentationData && this.prezentationData.fullSanitizedData) {
        for (
          let i = 0;
          i < this.prezentationData.fullSanitizedData?.length;
          i++
        ) {
          const item = this.prezentationData.fullSanitizedData[i];
          slideIndexToIdxMapping[item.index] = i;
        }
      }

      const preLoadedFullSanitizedData = [];
      const preLoadedPartialSanitizedData = [];
      const preLoadedNoSanitizedData = [];

      this.prezentationData.selectedSlidesList.forEach((slide) => {
        const idx = slideIndexToIdxMapping[slide.index];
        if (typeof idx === 'number') {
          preLoadedFullSanitizedData.push(
            this.prezentationData.fullSanitizedData[idx],
          );
          preLoadedPartialSanitizedData.push(
            this.prezentationData.partialSanitizedData[idx],
          );
          preLoadedNoSanitizedData.push(
            this.prezentationData.noSanitizedData[idx],
          );
        } else {
          preLoadedFullSanitizedData.push({});
          preLoadedPartialSanitizedData.push({});
          preLoadedNoSanitizedData.push({});
        }
      });

      this.fullSanitizedData = preLoadedFullSanitizedData;
      this.partialSanitizedData = preLoadedPartialSanitizedData;
      if (this.isTransferFlow) {
        this.noSanitizedData = [
          ...this.prezentationData.selectedSlidesList,
        ].map((item) => ({
          s3_path: item?.pdf?.s3_path || item?.s3_path,
          ...item,
        }));
      } else {
        this.noSanitizedData = preLoadedNoSanitizedData;
      }

      const arrayList = this.prezentationData.selectedSlidesList.map(
        (item) => item.index,
      );

      this.getFullSanitize(arrayList);

      this.getPartialSanitize(arrayList);

      this.getNoSanitize(arrayList);

      this.onPrezentationUpload({
        ...this.prezentationData,
        fullSanitizedData: this.fullSanitizedData,
        partialSanitizedData: this.partialSanitizedData,
        noSanitizedData: this.noSanitizedData,
        isSelectSlideChanged: false,
      });

      this.selectedSlidePreview = this.getPreviewSlide();
    },

    sleep(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    },
    async getPipelineStatusUntilFinished() {
      while (
        !this.pipelineStatus ||
        (this.pipelineStatus.outputs?.full_sanitize &&
          this.pipelineStatus.outputs?.full_sanitize.status !== 'success') ||
        (this.pipelineStatus.outputs?.partial_sanitize &&
          this.pipelineStatus.outputs?.partial_sanitize.status !== 'success')
      ) {
        await this.sleep(3000);
        const resp = await getPipeineStatus(
          this.prezentationData.pipelineCallbackId,
        );
        this.pipelineStatus = resp;
      }
    },
    async getFullSanitize(arrayList) {
      const list = [...this.fullSanitizedData];
      const obj = {};
      this.prezentationData.selectedSlidesList.forEach((slide) => {
        obj[slide.index] = { ...slide };
      });
      let allFound = false;

      while (allFound === false) {
        allFound = true;
        const fetchThumbnailPromises = [];
        const iteratorSlideDataMappings = [];

        for (let itr = 0; itr < arrayList.length; itr++) {
          const idx = arrayList[itr];
          // If data does not exist for this idx in list then fetch the data
          if (list[itr].index === undefined) {
            let selectedIndex = null;

            // Find the output for this idx in status output data
            for (
              let i = 0;
              i < this.pipelineStatus?.outputs?.full_sanitize?.data.length;
              i++
            ) {
              if (
                this.pipelineStatus?.outputs?.full_sanitize?.data[i]?.index ===
                idx
              ) {
                selectedIndex = i;
                break;
              }
            }

            if (selectedIndex !== null) {
              const slideData =
                this.pipelineStatus?.outputs?.full_sanitize?.data[
                  selectedIndex
                ];

              // save thumbnail fetch promise and slideData details so that it can be used later when all promises resolve
              fetchThumbnailPromises.push(this.getThumbnail(slideData));
              iteratorSlideDataMappings.push({
                itr,
                slideData,
              });
            } else {
              allFound = false;
            }
          }
        }
        await Promise.all(fetchThumbnailPromises).then((values) => {
          for (let i = 0; i < iteratorSlideDataMappings.length; i++) {
            const thumbnail = values[i];
            const slideObj = iteratorSlideDataMappings[i].slideData;
            const listItem = {
              ...slideObj,
              name:
                obj[slideObj.index].name ||
                `${this.prezentationData.prezentationName}_Slide_${slideObj.index}`,
              thumbnail,
            };
            list[iteratorSlideDataMappings[i].itr] = listItem;
          }
        });
        this.fullSanitizedData = [...list];
        this.selectedSlidePreview = this.getPreviewSlide();
        await this.sleep(3000);
      }

      this.fullSanitizationLoading = false;

      this.onPrezentationUpload({
        ...this.prezentationData,
        fullSanitizedData: this.fullSanitizedData,
      });
    },
    async getPartialSanitize(arrayList) {
      const list = [...this.partialSanitizedData];
      const obj = {};
      this.prezentationData.selectedSlidesList.forEach((slide) => {
        obj[slide.index] = { ...slide };
      });
      let allFound = false;
      while (allFound === false) {
        allFound = true;
        const fetchThumbnailPromises = [];
        const iteratorSlideDataMappings = [];
        for (let itr = 0; itr < arrayList.length; itr++) {
          const idx = arrayList[itr];
          // If data does not exist for this idx in list then fetch the data
          if (list[itr].index === undefined) {
            let selectedIndex = null;

            // Find the output for this idx in status output data
            for (
              let i = 0;
              i < this.pipelineStatus?.outputs?.partial_sanitize?.data.length;
              i++
            ) {
              if (
                this.pipelineStatus?.outputs?.partial_sanitize?.data[i]
                  ?.index === idx
              ) {
                selectedIndex = i;
                break;
              }
            }
            if (selectedIndex !== null) {
              const slideData =
                this.pipelineStatus?.outputs?.partial_sanitize?.data[
                  selectedIndex
                ];

              // save thumbnail fetch promise and slideData details so that it can be used later when all promises resolve
              fetchThumbnailPromises.push(this.getThumbnail(slideData));
              iteratorSlideDataMappings.push({
                itr,
                slideData,
              });
            } else {
              allFound = false;
            }
          }
        }
        await Promise.all(fetchThumbnailPromises).then((values) => {
          for (let i = 0; i < iteratorSlideDataMappings.length; i++) {
            const thumbnail = values[i];
            const slideObj = iteratorSlideDataMappings[i].slideData;
            const listItem = {
              ...slideObj,
              name:
                obj[slideObj.index].name ||
                `${this.prezentationData.prezentationName}_Slide_${slideObj.index}`,
              thumbnail,
            };
            list[iteratorSlideDataMappings[i].itr] = listItem;
          }
        });
        this.partialSanitizedData = [...list];
        this.selectedSlidePreview = this.getPreviewSlide();
        await this.sleep(3000);
      }
      this.partialSanitizationLoading = false;

      this.onPrezentationUpload({
        ...this.prezentationData,
        partialSanitizedData: this.partialSanitizedData,
      });
    },
    async getNoSanitize(arrayList) {
      const list = [...this.noSanitizedData];
      const obj = {};
      this.prezentationData.selectedSlidesList.forEach((slide) => {
        obj[slide.index] = { ...slide };
      });
      let allFound = false;
      while (allFound === false) {
        allFound = true;
        const fetchThumbnailPromises = [];
        const iteratorSlideDataMappings = [];
        for (let itr = 0; itr < arrayList.length; itr++) {
          const idx = arrayList[itr];
          // If data does not exist for this idx in list then fetch the data
          if (list[itr].index === undefined) {
            let selectedIndex = null;

            // Find the output for this idx in status output data
            for (
              let i = 0;
              i < this.pipelineStatus?.outputs['U-SplitPPTX']?.data.length;
              i++
            ) {
              if (
                this.pipelineStatus?.outputs['U-SplitPPTX']?.data[i]?.index ===
                idx
              ) {
                selectedIndex = i;
                break;
              }
            }

            if (selectedIndex !== null) {
              const slideData =
                this.pipelineStatus?.outputs['U-SplitPPTX']?.data[
                  selectedIndex
                ];

              // save thumbnail fetch promise and slideData details so that it can be used later when all promises resolve
              fetchThumbnailPromises.push(this.getThumbnail(slideData));
              iteratorSlideDataMappings.push({
                itr,
                slideData,
              });
            } else {
              allFound = false;
            }
          }
        }
        await Promise.all(fetchThumbnailPromises).then((values) => {
          for (let i = 0; i < iteratorSlideDataMappings.length; i++) {
            const thumbnail = values[i];
            const slideObj = iteratorSlideDataMappings[i].slideData;
            const listItem = {
              ...slideObj,
              name:
                obj[slideObj.index].name ||
                `${this.prezentationData.prezentationName}_Slide_${slideObj.index}`,
              thumbnail,
            };
            list[iteratorSlideDataMappings[i].itr] = listItem;
          }
        });
        this.noSanitizedData = [...list];
        this.selectedSlidePreview = this.getPreviewSlide();
        await this.sleep(3000);
      }
      this.noSanitizationLoading = false;

      this.onPrezentationUpload({
        ...this.prezentationData,
        noSanitizedData: this.noSanitizedData,
      });
    },
    getPreviewSlide() {
      const slide = { thumbnail: null };
      if (this.sanitizeType === 'full') {
        // eslint-disable-next-line prefer-destructuring
        return this.fullSanitizedData[this.selectedSlideIndex] || slide;
      }
      if (this.sanitizeType === 'partial') {
        // eslint-disable-next-line prefer-destructuring
        return this.partialSanitizedData[this.selectedSlideIndex] || slide;
      }

      if (this.sanitizeType === 'no') {
        // eslint-disable-next-line prefer-destructuring
        return this.noSanitizedData[this.selectedSlideIndex] || slide;
      }

      return slide;
    },
    sliceIntoChunks(arr, chunkSize) {
      const res = [];
      for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        res.push(chunk);
      }
      return res;
    },

    async getThumbnail(slide) {
      const awsAssetsDistribution = process.env.VUE_APP_AWS_ASSETS_DISTRIBUTION;
      const token = await getLoggedInUserToken();
      let thumbnail = `${awsAssetsDistribution}${
        slide.pdf.s3_path.startsWith('/') ? '' : '/'
      }${slide.pdf.s3_path}?accesskey=${token}`;

      if (slide?.pdf?.s3_path?.indexOf('.pdf') !== -1) {
        thumbnail = await convertPdfToImage(thumbnail);
      }

      return thumbnail;
    },
    handleSelectSlide(e, slide) {
      if (e) {
        this.selectedSlides.push(slide);
      } else {
        this.selectedSlides = this.selectedSlides.filter(
          (item) => item !== slide,
        );
      }

      this.selectAll = this.selectedSlides.length === this.slidesList.length;
    },
    handleSelectAllSlides(e) {
      if (e) {
        this.selectedSlides = [...this.slidesList];
      } else {
        this.selectedSlides = [];
      }
    },
    handleNextStep() {
      this.showWaiting = true;
      this.sanitizeStep = 1;
      setTimeout(() => {
        this.sanitizeStep = 2;
      }, 1000);
      setTimeout(() => {
        this.showWaiting = false;
        let selectedSanitizedSlideList = [...this.noSanitizedData];
        if (this.sanitizeType === 'full') {
          selectedSanitizedSlideList = [...this.fullSanitizedData];
        } else if (this.sanitizeType === 'partial') {
          selectedSanitizedSlideList = [...this.partialSanitizedData];
        }

        if (this.isTransferFlow) {
          const obj = {};
          this.prezentationData.selectedSlidesList.forEach((slide, index) => {
            obj[index] = {
              ideas: slide.ideas,
              ml_detected_tags: slide.ml_detected_tags,
              nodeCount: slide.nodeCount,
              speaker_notes: slide.speaker_notes,
              tags: slide.tags,
              theme_code: slide.theme_code,
            };
          });
          selectedSanitizedSlideList = selectedSanitizedSlideList.map(
            (slide, index) => ({
              ...slide,
              ...(obj[index] || {}),
            }),
          );
        }

        // Update the PipelineStatus back in prezentationData for later use in next step
        this.prezentationData.pipelineStatus = this.pipelineStatus;

        this.onPrezentationUpload({
          ...this.prezentationData,
          sanitizeType: this.sanitizeType,
          selectedSanitizedSlideList,
        });
        this.componentExitTime = Date.now();
        this.timeSpent =
          (this.componentExitTime - this.componentEnterTime) / 1000;
        this.nextStep(4);
      }, 2000);
    },
    async handleSlideSelect(slide, index) {
      this.selectedSlidePreview = { thumbnail: null };
      this.$forceUpdate();
      this.selectedSlideOriginal = slide;
      this.selectedSlideIndex = index;
      this.selectedSlidePreview = this.getPreviewSlide();
      trackUploadEvents.uploadSanitizeSlideThumbnailClick(this.currentUser, {
        [TD_COMMON_COLUMN_NAME]: 'Location',
        [TD_COMMON_COLUMN_VALUE]: 'Sanitize',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.sanitize-block {
  width: 100%;

  .title,
  .sub-title,
  .virbage {
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
  }

  .title {
    font-size: 28px !important;
    font-family: 'Lato' !important;
    font-weight: bold;
  }

  .sub-title {
    font-family: 'Lato' !important;
    font-size: 24px;
  }

  .virbage {
    font-family: 'Lato' !important;
    font-size: 24px;
  }
  .mb-title {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }

  .center {
    display: flex;
    justify-content: center;

    .v-btn {
      font-family: 'Lato', sans-serif;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      height: 32px !important;
      line-height: normal;
      letter-spacing: normal;
      min-width: 64px;
      text-transform: none !important;
    }
  }

  .waiting-block {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .image {
      margin-top: 20px;
    }
  }

  .radio-card {
    width: 100%;
    height: 81.27px;
    padding: 10px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1), -1px -1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    display: flex;
    align-items: center;
    margin: 15px 0;

    &.active {
      background: #f5fcff;
    }

    .radio-card-title {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
    }

    .radio-card-description {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.01em;
    }
  }

  .main-body {
    display: flex;
    // justify-content: space-between;

    .mb-title {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 10px;
    }

    > div {
      margin: 0 20px;
      min-width: 14%;
    }

    .original-block,
    .preview-block {
      min-width: 40%;
      width: 40%;
      .loading-container {
        max-height: 16rem;

        ::v-deep .v-skeleton-loader__image {
          height: 260px;
          border-radius: 0;
        }
      }
      .preview-noimage {
        position: relative;
        .spinner-noimage {
          position: absolute;
          left: 50%;
          top: 50%;
          // transform: translate(-50%, 0);
        }
      }

      .slide-img {
        filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.1))
          drop-shadow(-1px -1px 4px rgba(0, 0, 0, 0.1));
        border-radius: 11.3455px;
      }
    }

    .preview-block {
      margin-right: 10px;
    }

    .total-slides-block {
      margin-left: 0;
    }

    .slide-items {
      height: 77%;
      max-height: 300px;
      overflow: auto;
      padding-right: 10px;

      .slide-img {
        filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.1))
          drop-shadow(-1px -1px 4px rgba(0, 0, 0, 0.1));
        border-radius: 2.68687px;
        margin: 8px 0;
        border: 1px solid #fff;
        cursor: pointer;

        &.active {
          border: 1px solid #21a7e0;
        }

        &:hover {
          box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3),
            -1px -1px 4px rgba(0, 0, 0, 0.3);
        }
      }
    }
  }

  @-moz-document url-prefix() {
    .slide-items {
      padding-right: 20px !important;
    }
  }
}
</style>
