var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{class:("slides-comment " + (_vm.isCommentMode ? 'col-3' : 'col-0 normal'))},[_c('div',{staticClass:"comments-title"},[_vm._v(" "+_vm._s(_vm.$t('build.step3.commentsTooltip'))+" ")]),_c('div',{ref:"commentsContainer",staticClass:"comments"},_vm._l((_vm.comments),function(comment,index){return _c('div',{key:index,staticClass:"comment"},[_c('div',{staticClass:"header"},[_c('AudienceItem',{attrs:{"itemClass":'no-padding',"firstName":comment.userAttributes.firstName,"lastName":comment.userAttributes.lastName,"profileImgS3Path":comment.userAttributes.profileImage,"email":_vm.getTimeStamp(comment.createdAt),"maxWidth":260}},[(comment.userID == _vm.currentUser.user.id)?_c('div',{staticClass:"header-tool"},[(_vm.currentPrezentation.permission_level !== 0)?_c('div',{class:("edit edit-comment-" + index),on:{"click":function($event){return _vm.handleCommentEdit(index)}}},[_c('v-tooltip',{attrs:{"top":"","content-class":"tooltip-content","max-width":"250"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('img',_vm._g(_vm._b({attrs:{"src":"/assets/img/comment-edit.svg","alt":""}},'img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('build.step3.edditCommentText')))])])],1):_vm._e(),(_vm.currentPrezentation.permission_level !== 0)?_c('div',{class:("delete delete-comment-" + index),on:{"click":function($event){return _vm.handleCommentDelete(index)}}},[_c('v-tooltip',{attrs:{"top":"","content-class":"tooltip-content","max-width":"250"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('img',_vm._g(_vm._b({attrs:{"src":"/assets/img/comment-delete.svg","alt":""}},'img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('build.step3.deleteCommentText')))])])],1):_vm._e()]):_vm._e()])],1),_c('div',{staticClass:"comment-content"},[(!comment.isCommentEdit)?[_c('UserMentionView',{attrs:{"value":comment.commentJson}})]:_vm._e(),(comment.isCommentEdit)?[_c('UserMentionControl',{attrs:{"type":'edit'},model:{value:(comment.commentEditable),callback:function ($$v) {_vm.$set(comment, "commentEditable", $$v)},expression:"comment.commentEditable"}})]:_vm._e()],2),_c('div',{class:("comment-control " + (comment.isCommentEdit ? 'show' : 'hide'))},[_c('div',{staticClass:"comment-cancel",on:{"click":function($event){return _vm.handleCommentCancel(index)}}},[_vm._v(" "+_vm._s(_vm.$t('common.cancel'))+" ")]),_c('div',{staticClass:"comment-save",on:{"click":function($event){return _vm.handleCommentSave(index)}}},[_vm._v(" "+_vm._s(_vm.$t('common.save'))+" ")])])])}),0),(
        _vm.currentUser.user.cognitoID === _vm.currentPrezentation.owner ||
        (_vm.currentPrezentation.permission_level &&
          _vm.currentPrezentation.permission_level !== 1 &&
          _vm.currentPrezentation.permission_level !== 0)
      )?_c('div',{staticClass:"comment-send-wrapper"},[_c('UserMentionControl',{ref:"commentRef",attrs:{"type":'add'},model:{value:(_vm.commentJson),callback:function ($$v) {_vm.commentJson=$$v},expression:"commentJson"}}),_c('div',{staticClass:"comment-send-control"},[_c('div',{staticClass:"icons"}),_c('div',{staticClass:"comment-send-button",on:{"click":function($event){return _vm.handleAddComment(null, true)}}},[(
              this.commentJson &&
              this.commentJson.length &&
              this.commentJson[0].content
            )?_c('img',{attrs:{"src":"/assets/img/comment-send.svg","alt":""}}):_c('img',{attrs:{"disabled":"","src":"/assets/img/comment-send-disabled.svg","alt":""}})])])],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }