<template>
  <div :class="`slides-mosaic-view col-12 `">
    <!-- ${isCommentMode ? 'col-9' : 'col-12'}` -->
    <div class="slides-wrapper row">
      <div
        class="slide-wrapper"
        v-for="(item, index) in listItems"
        :key="index"
      >
        <div class="op-slide-divider"></div>
        <div class="slide-container">
          <div class="slide-content-wrapper">
            <span class="serial-number">{{ index + 1 }}</span>
            <div class="image-container">
              <div class="image-wrapper">
                <v-img
                  :src="item.thumbnail"
                  alt
                  lazy-src="/assets/img/slides/placeholder-slide.svg"
                  :aspect-ratio="16 / 9"
                  @click="handleSlideItemClick(item, index)"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <clip-loader
                        :color="`#21a7e0`"
                        :width="20"
                        :height="20"
                      />
                    </v-row>
                  </template>
                </v-img>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';

export default {
  name: 'MosaicView',
  props: {
    prezData: {
      type: Object,
      required: true,
    },
  },
  components: {
    ClipLoader,
  },
  data() {
    return {
      listItems: [],
    };
  },
  methods: {
    handleSlideItemClick(item, index) {
      this.$emit('onMosaicSlideClick', index);
    },
  },
  mounted() {
    if (this.prezData.opSlideData) {
      this.listItems = this.prezData.opSlideData.slides;
    }
  },
};
</script>

<style lang="scss" scoped>
.slides-mosaic-view {
  transition: width 0.3s, visibility 0.3s, opacity 0.3s ease-out;
  padding-left: 0;
  height: calc(100vh - 250px);
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 14px;
}
.slides-wrapper {
  position: relative;
  align-items: flex-start !important;
  padding: 8px 8px 8px 33px !important;

  &::-webkit-scrollbar {
    -webkit-appearance: scrollbartrack-vertical;
  }

  .op-slide-divider {
    padding: 0 10px;
  }
  .slide-wrapper {
    display: flex;
    .slide-container {
      width: 350px;
      max-width: 380px;
      min-width: 200px;
      .slide-content-wrapper {
        cursor: pointer;
        max-width: 380px;
        min-width: 200px;
        padding: 12px 10px;
        overflow: hidden;

        .serial-number {
          margin-left: -23px;
          z-index: 10;
          position: absolute;
        }
        .image-container {
          box-shadow: 0 2px 5px 1px rgba(64, 60, 67, 0.16);
          border-radius: 8px;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          overflow: hidden;
          &:hover {
            box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3),
              -1px -1px 4px rgba(0, 0, 0, 0.3);
          }
          .image-wrapper {
            border-bottom: 1px solid rgba(211, 211, 211, 0.2);
          }
        }
      }
    }
  }
}
</style>
