var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"au-chart"},[_c('div',{staticClass:"au-chart__header"},[_c('div',{staticClass:"au-chart__head"},[_c('h3',{staticClass:"au-chart__heading"},[_vm._v(" "+_vm._s(_vm.$t('profile.metricDetail.activeUsers'))+" ")]),_c('v-tooltip',{attrs:{"right":"","max-width":"200","content-class":"tooltip-content","fixed":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"icon-title"},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('profile.metricDetail.numberOfUsers'))+" ")])]),_c('v-tooltip',{attrs:{"right":"","max-width":"200","content-class":"tooltip-content","fixed":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.download()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-download-outline")])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('profile.metricDetail.downloadAsCsv'))+" ")])])],1),_c('div',{staticClass:"au-chart__actions"},[_c('v-menu',{attrs:{"close-on-content-click":false,"close-on-click":true,"transition":"scale-transition","offset-y":"","max-width":200,"min-width":200},scopedSlots:_vm._u([{key:"activator",fn:function(ref){return [_c('v-combobox',{staticClass:"au-chart__select",attrs:{"items":_vm.filters,"hide-details":""},on:{"change":_vm.trackFilterChange},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [(item.value === 'custom' && _vm.datepickerValue)?_c('span',[_vm._v(" "+_vm._s(item.text)+": "+_vm._s(_vm.datepickerValue)+" ")]):_c('span',[_vm._v(_vm._s(item.text))])]}},{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({on:{"click":function($event){return _vm.onFilterSelection(item.value)}}},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.text)+" ")])],1)],1)]}}],null,true),model:{value:(_vm.year),callback:function ($$v) {_vm.year=$$v},expression:"year"}})]}}]),model:{value:(_vm.isDatepickerVisible),callback:function ($$v) {_vm.isDatepickerVisible=$$v},expression:"isDatepickerVisible"}},[_c('v-date-picker',{staticClass:"au-chart__datepicker",attrs:{"no-title":"","scrollable":"","color":"#20a7e0","range":"","active-picker":_vm.datepickerActivePicker,"max":((new Date().getFullYear()) + "-01-01"),"min":"2022-01-01","width":200},on:{"update:activePicker":function($event){_vm.datepickerActivePicker=$event},"update:active-picker":function($event){_vm.datepickerActivePicker=$event},"click:year":function($event){return _vm.applyYearPicker($event)}}})],1)],1)]),_c('div',{staticClass:"au-chart__body"},[_c('svg',{ref:"chart",staticClass:"au-chart__svg"}),(_vm.loading)?_c('div',{staticClass:"au-chart__loader"},[_c('clip-loader',{staticClass:"cliploader",attrs:{"loading":true,"color":"#21a7e0","width":'60',"height":'60',"size":'30px'}})],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }