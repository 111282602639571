import { render, staticRenderFns } from "./RequestChangeModal.vue?vue&type=template&id=63d07e52&scoped=true&"
import script from "./RequestChangeModal.vue?vue&type=script&lang=js&"
export * from "./RequestChangeModal.vue?vue&type=script&lang=js&"
import style0 from "./RequestChangeModal.vue?vue&type=style&index=0&id=63d07e52&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63d07e52",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VCheckbox,VIcon,VTextarea,VTooltip})
