<template>
  <div class="add-team-funds-container">
    <v-btn class="close-btn" icon @click="onClose">
      <v-icon :size="16" dark v-text="`mdi-close`" />
    </v-btn>
    <div class="header">{{ $t('profile.teamFunds.addTeamfunds') }}</div>
    <img class="req-img" :src="'/assets/img/add_team_funds.svg'" />
    <span>{{ $t('profile.teamFunds.howMuchYouWantToAdd') }}</span>
    <v-text-field
      v-model="amount"
      prefix="$"
      type="number"
      :rules="amountRules"
    ></v-text-field>
    <v-btn
      class="send-assessment"
      rounded
      color="#20a7e0"
      width="140px"
      height="32px"
      :loading="loading"
      @click="getInTouch"
      :disabled="!amount || amount < 50"
    >
      {{ $t('fingerprint.getInTouch') }}
    </v-btn>
  </div>
</template>
<script>
import { requestAddFunds } from '../../../utils/api-helper';
import ContactSoonModal from '../../common/ContactSoonModal.vue';

export default {
  name: 'AddTeamFunds',
  components: {},
  props: {},
  data() {
    return {
      amount: 0.0,
      loading: false,
      amountRules: [(v) => v >= 50 || 'Minimum $50'],
    };
  },
  methods: {
    onClose() {
      this.$modal.hide('AddTeamFunds');
    },
    async getInTouch() {
      this.loading = true;
      await requestAddFunds({ amount: this.amount });
      this.loading = false;
      this.$modal.hide('AddTeamFunds');
      this.$modal.show(
        ContactSoonModal,
        {},
        {
          name: 'ContactSoonModal',
          width: '449px',
          height: '503px',
        },
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.add-team-funds-container {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 24px;
  gap: 8px;

  .header {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    /* identical to box height */

    display: flex;
    align-items: center;
    letter-spacing: -0.01em;

    /* Color Styles (Old)/Dark */

    color: #212121;
  }

  .close-btn {
    position: absolute;
    right: 5px;
    top: 5px;
  }
  .req-img {
    width: 200px;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .get-in-touch-img {
    width: 274px;
  }

  span {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
  }

  .send-assessment {
    background: #21a7e0;
    border-radius: 25px;
    color: white !important;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    text-transform: none;
    margin-top: 20px;
    align-self: end;
  }
}
</style>
