<template>
  <div class="novice-practice-wrapper">
    <v-btn class="back-button" @click="handleBack()" icon color="#21a7e0">
      <v-icon>mdi-arrow-left</v-icon>
      {{ $t('common.back') }}
    </v-btn>
    <div class="practice-title">{{ $t('learn.course.practice') }}</div>
    <div class="practice-description">
      {{ $t('learn.novice.practiceText1') }}
      <br />
      <br />{{ $t('learn.novice.practiceText2') }}
    </div>
    <div class="practice-content">
      <div class="content-title">
        {{ $t('learn.novice.zensenseRecommendations') }}
      </div>
      <div class="practice-content-text">
        <textarea
          v-model="question"
          :placeholder="$t('learn.novice.writeQuestion')"
        />
      </div>
      <div class="practice-question-actions">
        <div class="submit-wrapper" v-if="!isSubmit">
          <div class="practice-question-example">
            {{ $t('learn.novice.exampleQuestion') }}
          </div>
          <div class="example" />
          <v-btn
            class="question-submit-button"
            rounded
            width="285"
            height="50"
            color="#21a7e0"
            @click="handleSubmit()"
            :disabled="!question"
          >
            {{ $t('learn.novice.submitForReview') }}
          </v-btn>
        </div>
        <div v-else>
          <div v-if="allDone" class="well-done">
            <img
              src="/assets/img/learn/practice-well-done.png"
              width="36px"
              height="36px"
              alt
            />
            <div class="well-done-info">
              {{ $t('learn.novice.wellDone') }}
            </div>
          </div>
          <div v-if="!allDone" class="well-done">
            <img
              src="/assets/img/learn/practice-well-done.png"
              width="36px"
              height="36px"
              alt
            />
            <div class="well-done-info">
              {{ motivationMsg }}
            </div>
          </div>
          <div v-if="!allDone" class="well-done">
            <img
              v-if="!allDone"
              src="/assets/img/learn/practice-highlight.svg"
              alt
            />
            <div class="well-done-info">
              {{ suggestionMsg }}
            </div>
          </div>
          <div v-if="!allDone">
            <div
              class="well-done mt-3"
              v-for="(example, index) in exampleMsg"
              :key="index"
            >
              <div class="well-done-info" style="margin-left: 52px">
                {{ example }}
              </div>
            </div>
          </div>
          <!--  <div>
            <span>
              <br />
              <br />
              <b>debug: {{ resultText }}</b>
            </span>
          </div>-->
          <div class="after-actions">
            <v-btn
              class="try-again-button"
              rounded
              width="188"
              height="50"
              color="#21a7e0"
              @click="handleTryAgain()"
            >
              {{ $t('common.tryAgain') }}
            </v-btn>
            <v-btn
              class="done-button"
              rounded
              outlined
              width="188"
              height="50"
              color="#21a7e0"
              @click="handleDone()"
            >
              {{ $t('common.done') }}
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { API, graphqlOperation } from 'aws-amplify';
import { mapState } from 'vuex';
import { evaluateFit } from '../../../../graphql/queries';
import learnApi from '../../../../API/learn-api';
import { MatomoAnalyticsHandler } from '../../../common/Analytics/MatomoAnalyticsHandler';
import { logSearchQuery } from '../../../../utils/api-helper';
import { trackLearnEvents } from '../../../common/Analytics/LearnEvents';

export default {
  name: 'Practice',
  data() {
    return {
      question: '',
      item: null,
      isSubmit: false,
      learnData: null,
      levelInfo: null,
      result: null,
      motivationMsg: '',
      exampleMsg: '',
      suggestionMsg: '',
    };
  },
  computed: {
    ...mapState('users', ['currentUser']),
    ...mapState('learndata', ['courseInfoForMatotmo']),
    allDone() {
      return (
        this.result &&
        this.result?.focussed &&
        this.result?.intuitive &&
        this.result?.timebound
      );
    },
    resultText() {
      return JSON.stringify(this.result);
    },
    combinationArray() {
      let combination = '';
      const arr = [
        this.isFocussed(),
        this.isIntuitive(),
        this.isTimebound(),
        this.isSuccint(),
      ];
      combination = arr.toString().replaceAll(',', '');
      return combination;
    },
  },
  methods: {
    isSuccint() {
      return this.question?.length < 100 ? 1 : 0;
    },
    isFocussed() {
      return this.result?.focussed ? 1 : 0;
    },
    isIntuitive() {
      return this.result?.intuitive ? 1 : 0;
    },
    isTimebound() {
      return this.result?.timebound ? 1 : 0;
    },
    parseFIT() {
      switch (this.combinationArray) {
        case '1111':
          this.motivationMsg =
            'Bravo! Your question is succinct and has a specific goal. It also includes a clear metric with a target, to be achieved in a relevant time frame.';
          this.suggestionMsg =
            'Using this question, you can frame a powerful message that grabs the attention of your audience.';
          this.exampleMsg = [''];
          break;
        case '1101':
          this.motivationMsg =
            'Great work! Your question is succinct and has a specific goal. It also includes a clear metric with a target.';
          this.suggestionMsg =
            'You can strengthen the question further by including a time frame for the goal.';
          this.exampleMsg = [
            "Examples of mentioning a relevant time frame: '...by end of 2023?' or '...in next three months'.",
          ];
          break;
        case '1011':
          this.motivationMsg =
            'Great work! Your question is succinct and has a specific goal to be achieved in a relevant time frame.';
          this.suggestionMsg =
            'You can strengthen the question further by adding a specific metric with a target.';
          this.exampleMsg = [
            "Examples of including a metric with target: '...grow customer adoption by 10%...' or '...to increase revenue by $10 million...'.",
          ];
          break;
        case '1001':
          this.motivationMsg =
            'Well done! Your question is succinct and has a specific goal.';
          this.suggestionMsg =
            'You can strengthen the question further by including a clear metric with a target, to be achieved in a relevant time frame.';
          this.exampleMsg = [
            "Examples of including a metric with target: '...grow customer adoption by 10%...' or '...to increase revenue by $10 million...",
            "Examples of mentioning a relevant time frame: '...by end of 2023?' or '...in next three months.'",
          ];
          break;
        case '0111':
          this.motivationMsg =
            'Great work! Your question is succinct. It includes a clear metric with a target, to be achieved in a relevant time frame.';
          this.suggestionMsg =
            'You can strengthen the question further by adding a specific goal that needs to be achieved.';
          this.exampleMsg = [
            "Examples of adding a business goal: 'What marketing survey should we launch...'' or 'How do we achieve top position...'.",
          ];
          break;
        case '0101':
          this.motivationMsg =
            'Well done! Your question is succinct and includes a clear metric with a target.';
          this.suggestionMsg =
            'You can strengthen the question further by adding a specific goal and a time frame for achieving it.';
          this.exampleMsg = [
            "Examples of adding a business goal: 'What marketing survey should we launch...' or 'How do we achieve top position...'",
            "Examples of mentioning a relevant time frame: '...by end of 2023?' or '...in next three months.",
          ];
          break;
        case '0011':
          this.motivationMsg =
            'Well done! Your question is succinct and has a clear time frame.';
          this.suggestionMsg =
            'You can strengthen the question further by adding a specific goal and including a metric with a target.';
          this.exampleMsg = [
            "Examples of adding a business goal: 'What marketing survey should we launch...' or 'How do we achieve top position...'.",
            "Examples of including a metric with target: '...grow customer adoption by 10%...' or '...to increase revenue by $10 million...'.",
          ];
          break;
        case '0001':
          this.motivationMsg =
            'Pretty good! Your question is succinct. Short and sweet is the way to go.';
          this.suggestionMsg =
            'You can further enhance the question by by adding a specific goal that needs to be achieved. Also include a clear metric with a target, and a relevant time frame.';
          this.exampleMsg = [
            "Examples of adding a business goal: 'What marketing survey should we launch...' or 'How do we achieve top position...'",
            "Examples of including a metric with target: '...grow customer adoption by 10%...' or '...to increase revenue by $10 million...'",
            "Examples of mentioning a relevant time frame: '...by end of 2023?' or '...in next three months'",
          ];
          break;
        case '1110':
          this.motivationMsg =
            'Great work! Your question has a specific goal. It also includes a clear metric with a target, to be achieved in a relevant time frame.';
          this.suggestionMsg =
            'You can strengthen the question further by making it succinct (25 words or fewer).';
          this.exampleMsg = [''];
          break;
        case '1100':
          this.motivationMsg =
            'Well done! Your question has a specific goal. It also includes a clear metric with a target.';
          this.suggestionMsg =
            'You can strengthen the question further by making it more succinct (25 words or fewer) and adding a relevant time frame.';
          this.exampleMsg = [
            "Examples of mentioning a relevant time frame: '...by end of 2023?' or '...in next three months'.",
          ];
          break;
        case '1010':
          this.motivationMsg =
            'Well done! , Your question has a clear goal to be achieved in a relevant time frame.';
          this.suggestionMsg =
            'You can strengthen the question further by making it more succinct (25 words or fewer). Also add a specific metric with a target.';
          this.exampleMsg = [
            "Examples of including a metric with target: '...grow customer adoption by 10%...' or '...to increase revenue by $10 million...'.",
          ];
          break;
        case '1000':
          this.motivationMsg =
            'Pretty good! Your question has a clear business goal.';
          this.suggestionMsg =
            'You can further enhance the question by making it more succinct (25 words or fewer). Also add a specific metric with a target, and a relevant time frame.';
          this.exampleMsg = [
            "Examples of including a metric with target: '...grow customer adoption by 10%...' or '...to increase revenue by $10 million...'",
            "Examples of mentioning a relevant time frame: '...by end of 2023?' or '...in next three months.",
          ];
          break;
        case '0110':
          this.motivationMsg =
            'Well done! Your question has a clear metric with a target to be achieved in a relevant time frame.';
          this.suggestionMsg =
            'You can strengthen the question further by making it succinct (25 words or fewer). Also add a specific goal to be achieved.';
          this.exampleMsg = [
            "Examples of adding a business goal: 'What marketing survey should we launch...' or 'How do we achieve top position...'.",
          ];
          break;
        case '0100':
          this.motivationMsg =
            'Pretty good! Your question has a clear metric with a target.';
          this.suggestionMsg =
            'You can further enhance the question by adding a specific goal that needs to be achieved and a relevant time frame. Also, try being succinct (25 words or fewer).';
          this.exampleMsg = [
            "Examples of adding a business goal: 'What marketing survey should we launch...' or 'How do we achieve top position...'",
            "Examples of mentioning a relevant time frame: '...by end of 2023?' or '...in next three months.'",
          ];
          break;
        case '0010':
          this.motivationMsg =
            'Pretty good! Your question includes a specific time frame.';
          this.suggestionMsg =
            'You can further enhance the question by making it more succinct (25 words or fewer). Also, add a clear goal to be achieved and a relevant metric with a target.';
          this.exampleMsg = [
            "Examples of adding a business goal: 'What marketing survey should we launch...' or 'How do we achieve top position...'",
            "Examples of including a metric with target: '...grow customer adoption by 10%...' or '...to increase revenue by $10 million....'",
          ];
          break;
        case '0000':
          this.motivationMsg =
            'Nice try! You are on your way to defining a strong question which will help you tailor your message to the needs of your audience.';
          this.suggestionMsg =
            'You can improve the question by including a clear business goal to be achieved. Further, add a specific metric along with a relevant time frame. Also, try being succinct (25 words or fewer).';
          this.exampleMsg = [
            "Examples of adding a business goal: 'What marketing survey should we launch...' or 'How do we achieve top position...'",
            "Examples of including a metric with target: '...grow customer adoption by 10%...' or '...to increase revenue by $10 million...'.",
            "Examples of mentioning a relevant time frame: '...by end of 2023?' or '...in next three months.'",
          ];
          break;
        default:
          this.motivationMsg = '';
          this.suggestionMsg = '';
          this.exampleMsg = [''];
      }
    },
    async updateAssetStatus() {
      const updateStatus = {
        asset_id: this.$route.params.assetId,
        status: 100,
      };
      await learnApi.methods.updateAssetUserStatus(updateStatus).then(
        (resp) => {
          if (resp) {
            this.$router.push({
              name: 'course.detail',
              params: { id: this.$route.params.courseId },
            });
          }
        },
        (error) => {
          console.log('error while updating the asset status --', error);
          this.$router.push({
            name: 'course.detail',
            params: { id: this.$route.params.courseId },
          });
        },
      );
    },
    async handleSubmit() {
      const params = {
        username: 'pz_rath_admin',
        query: this.question,
      };
      const serialized = JSON.stringify(params);

      API.graphql(
        graphqlOperation(evaluateFit, {
          parameters: serialized,
        }),
      )
        .then(async (res) => {
          MatomoAnalyticsHandler.trackFitSubmit(
            this.courseInfoForMatotmo,
            this.currentUser.user,
          );
          trackLearnEvents.learnPracticeSubmitButton(
            this.currentUser,
            this.courseInfoForMatotmo,
          );
          const ret = JSON.parse(res.data.evaluateFIT);
          this.result = ret.body;
          this.parseFIT();
          console.log('FIT result', this.result);
          this.isSubmit = true;
        })
        .catch((err) => {
          console.log(err);
          this.isSubmit = true;
        });
    },
    handleTryAgain() {
      this.isSubmit = false;
      trackLearnEvents.learnPracticeTryAgainButton(
        this.currentUser,
        this.courseInfoForMatotmo,
      );
    },
    async handleDone() {
      await this.updateAssetStatus();
      trackLearnEvents.learnPracticeTryDoneButton(
        this.currentUser,
        this.courseInfoForMatotmo,
      );
    },
    handleBack() {
      this.$router.push({
        name: 'course.detail',
        params: { id: this.$route.params.courseId },
      });
    },
  },
  mounted() {
    const logPayload = {
      type: 'Learn',
      searchTerm: '',
      searchTermResult: true,
    };
    if (this.$route.query) {
      logPayload.searchTerm =
        this.$route.query.display_name?.toLowerCase() || '';
      if (this.$route.query.log_search) {
        logSearchQuery(logPayload)
          .then()
          .catch((err) => console.log(err));
      }
    }
    if (this.$route.query.index) {
      this.item = this.moduleItems.find(
        (item) => item.id === this.$route.query.index,
      );
    }
  },
  watch: {
    async $route() {
      const logPayload = {
        type: 'Learn',
        searchTerm: '',
        searchTermResult: true,
      };
      if (this.$route.query) {
        logPayload.searchTerm =
          this.$route.query.display_name?.toLowerCase() || '';
        if (this.$route.query.log_search) {
          logSearchQuery(logPayload)
            .then()
            .catch((err) => console.log(err));
        }
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.novice-practice-wrapper {
  position: relative;
  margin-left: 20px;
  margin-right: 20px;
  .practice-title {
    font-size: 36px;
    font-weight: bold;
    text-align: left;
  }
  .practice-description {
    font-size: 20px;
    text-align: left;
    padding-bottom: 20px;
    border-bottom: 1px solid #d8d8d8;
    margin-bottom: 20px;
  }
  .practice-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    .content-title {
      font-size: 24px;
      font-weight: bold;
      text-align: left;
      margin-bottom: 15px;
    }
    .practice-content-text {
      margin-bottom: 15px;
      textarea {
        width: 483px;
        height: 115px;
        padding: 12px 17px 36px 22px;
        border-radius: 20px;
        // box-shadow: inset 0 1px 3px 0 #050708;
        box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
        background: white;
        border: none;
        outline: none;
      }
    }
    input:focus {
      outline: none !important;
    }
    .practice-question-example {
      font-size: 20px;
      text-align: left;
    }
    .practice-question-actions {
      .submit-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }
      .example {
        text-align: left;
        margin-top: 10px;
        margin-bottom: 25px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        img {
          background: #e66e28;
          border-radius: 50%;
          filter: invert(1);
          margin-right: 10px;
        }
      }
      .question-submit-button {
        font-size: 24px;
        font-weight: bold;
        color: white;
        text-transform: none;
      }
      .well-done {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        white-space: initial;
        margin-bottom: 20px;
        img {
          margin-right: 15px;
        }
        .well-done-info {
          text-align: left;
          white-space: initial;
          font-size: 16px;
        }
      }
      .after-actions {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 25px;
        .try-again-button {
          margin-right: 35px;
          font-size: 24px;
          font-weight: bold;
          color: white;
          text-transform: none;
        }
        .done-button {
          font-size: 24px;
          font-weight: bold;
          text-transform: none;
        }
      }
    }
  }
  .back-button {
    color: #21a7e0;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    margin: 10px 0;
    margin-left: 10px;
    position: relative;
    text-transform: unset;
    top: 7px;
    cursor: pointer;
  }
}
</style>
