<template>
  <div class="wrapper">
    <v-btn class="close-btn" icon @click="handleClose">
      <v-icon dark v-text="`mdi-close`" />
    </v-btn>
    <div class="main-wrapper">
      <div class="novice-badge">
        <div class="novice-badge-title">
          {{ $t('learn.course.congratulations') }}
        </div>
        <div class="novice-badge-sub-title">
          {{ $t('learn.novice.earnedABadge') }}
        </div>
      </div>
      <div class="novice-badge-description" v-if="this.remainingBadges !== 0">
        <span>{{ $t('learn.intialLoad.youAre') }}</span>
        <span class="novice-badge-highlight">{{ remainingBadges }}</span>
        <span>{{ getMessage }}</span>
      </div>
      <div class="novice-badge-description" v-else>
        {{ $t('learn.novice.unlockedCourseCertificate') }}
      </div>
      <v-btn
        class="close-dialog"
        rounded
        width="181"
        color="#21a7e0"
        height="31"
        @click="handleContinue"
      >
        {{
          remainingBadges === 0
            ? $t('learn.course.viewCertificate')
            : $t('learn.learningPageLocked.continueLearning')
        }}
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NoviceBadgeDialog',
  components: {},
  props: {
    goToInitial: {
      type: Function,
      required: true,
    },
    remainingBadges: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      prevLevel: null,
      nextLevel: null,
    };
  },
  computed: {
    getMessage() {
      return this.$t('learn.course.BadgeAwayFromGetting', {
        remainingBadges:
          this.remainingBadges === 1
            ? this.$t('learn.course.badge')
            : this.$t('learn.course.badges'),
      });
    },
  },
  methods: {
    handleClose() {
      this.$modal.hide('NoviceBadgeDialog');
    },
    handleContinue() {
      this.$modal.hide('NoviceBadgeDialog');
      this.goToInitial();
    },
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: auto;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  padding: 25px;
}
.close-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 1;
}
.main-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding: 10px;
  .novice-badge {
    background-image: url('/assets/img/learn/novice-earned-badge.png');
    background-size: cover;
    width: 303px;
    height: 238px;
    .novice-badge-title {
      font-size: 24px;
      font-weight: bold;
      text-align: center;
      margin: 15px 0px;
    }
    .novice-badge-sub-title {
      font-size: 16px;
      font-weight: bold;
      text-align: center;
    }
  }
  .novice-badge-description {
    display: block;
    font-size: 16px;
    text-align: center;
    .novice-badge-highlight {
      font-size: 18px;
      font-weight: bold;
      color: #21a7e0;
      padding-left: 5px;
      padding-right: 5px;
    }
  }
  .close-dialog {
    margin-top: 24px;
    font-size: 16px;
    color: white;
    text-transform: none;
  }
}
</style>
