var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loadingThemes)?_c('div',{staticClass:"template-spinner-wrapper"},[(_vm.loadingThemes)?_c('Loading'):_vm._e()],1):_c('div',{staticClass:"slide-settings"},[(_vm.previousRoute === 'search')?_c('div',{staticClass:"back-btn-cntr"},[_c('v-btn',{staticClass:"btn-back",on:{"click":_vm.goToSearchLandingPage}},[_c('v-icon',{staticClass:"icon",attrs:{"color":"primary"}},[_vm._v("mdi-chevron-left")]),_c('span',[_vm._v(_vm._s(_vm.$t('slides.backToSearch')))])],1)],1):_vm._e(),_c('div',{staticClass:"slide-settings-title"},[_vm._v(" "+_vm._s(_vm.companyName)+" "+_vm._s(_vm.$t('profile.templates'))+" "),_c('div',{staticClass:"slide-settings-title-icon"},[_c('v-tooltip',{attrs:{"right":"","max-width":"250","content-class":"tooltip-content","fixed":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"icon-title",domProps:{"textContent":_vm._s("mdi-information-outline")}},'v-icon',attrs,false),on))]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('profile.chooseTheTemplate')))])])],1)]),_c('div',{staticClass:"available-themes-wrapper"},[_c('div',{staticClass:"available-themes"},[_c('div',{staticClass:"available-themes-title"},[_vm._v(" "+_vm._s(_vm.$t('profile.availableTemplates'))+" ")]),_c('div',{staticClass:"spacer"}),_c('v-text-field',{staticClass:"template-search-input",attrs:{"solo":"","single-line":"","hide-details":"","autocomplete":"off","placeholder":!!_vm.globalSearchResult
            ? _vm.$t('profile.displaying')
            : _vm.$t('profile.searchTemplates'),"disabled":_vm.globalSearchResult},nativeOn:{"keyup":function($event){$event.stopPropagation();return _vm.sortedThemeArray.apply(null, arguments)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{attrs:{"color":"primary"},domProps:{"textContent":_vm._s(
              !!_vm.globalSearchResult || _vm.templateSearch
                ? "mdi-close"
                : "mdi-magnify"
            )},on:{"click":_vm.resetSearch}})]},proxy:true}]),model:{value:(_vm.templateSearch),callback:function ($$v) {_vm.templateSearch=$$v},expression:"templateSearch"}})],1),(
        _vm.currentTheme &&
        _vm.themes &&
        _vm.themes.length > 0 &&
        _vm.sortedThemeArray().length
      )?_c('v-row',{staticClass:"available-themes-container",attrs:{"no-gutters":""}},[_vm._l((_vm.sortedThemeArray()),function(item){return _c('v-col',{key:item.id,staticClass:"available-themes-list col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3",style:([
          _vm.$vuetify.breakpoint.xs ||
          _vm.$vuetify.breakpoint.sm ||
          _vm.$vuetify.breakpoint.md
            ? { 'min-width': '300px' }
            : {} ]),attrs:{"cols":"12","sm":"4"}},[_c('div',{staticClass:"available-theme"},[_c('div',{staticClass:"available-theme-wrapper"},[_c('v-badge',{attrs:{"content":_vm.$t('filters.new'),"overlap":"","tile":"","offset-x":"74","offset-y":"32","value":item.isNew &&
                item.isNew === true &&
                _vm.currentUser.user.createdAt < item.createdAt}},[_c('div',{staticClass:"available-theme-img",class:item.isUserUploaded &&
                  item.jobSubmitted &&
                  (item.status === 'inprogress' || item.status === 'failed')
                    ? 'td-thumbnail'
                    : ''},[_c('img',{class:("" + (item.status === 'failed' ? 'img-err' : '')),attrs:{"src":item.thumbnailURL,"alt":""}}),(item.isUserUploaded && item.jobSubmitted)?_c('div',{staticClass:"td-progress-container"},[_c('v-tooltip',{attrs:{"bottom":"","fixed":"","max-width":"350","close-delay":"1500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [(item.status === 'inprogress')?_c('div',[_c('v-progress-circular',_vm._g(_vm._b({attrs:{"value":_vm.getTDPercentages[item.designerCurrentIndex],"size":128,"width":12,"rotate":-90,"color":"#121619"}},'v-progress-circular',attrs,false),on),[_c('span',{staticClass:"percentage"},[_vm._v(" "+_vm._s(_vm.getTDPercentages[item.designerCurrentIndex])+"% Complete ")])])],1):(item.status === 'failed')?_c('div',[_c('img',_vm._g(_vm._b({staticClass:"td-error",attrs:{"src":'assets/img/templateDesign/warning-line.png'}},'img',attrs,false),on))]):_vm._e()]}}],null,true)},[(item.status === 'inprogress')?_c('div',{staticClass:"tooltip",staticStyle:{"white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(_vm.$t('templateDesign.inProgressTooltip'))+" "),_c('a',{on:{"click":_vm.getInTouch}},[_vm._v(_vm._s(_vm.$t('templateDesign.getInTouch')))])]):(item.status === 'failed')?_c('div',{staticClass:"tooltip",staticStyle:{"white-space":"pre-wrap","text-align":"center"}},[_vm._v(" "+_vm._s(_vm.$t('templateDesign.tdFailedTooltip'))+" "),_c('a',{on:{"click":_vm.getInTouch}},[_vm._v(_vm._s(_vm.$t('templateDesign.getInTouch')))]),_vm._v(" "+_vm._s(_vm.$t('templateDesign.withDesignTeam'))+" ")]):_vm._e()])],1):_vm._e()])]),_c('div',{staticClass:"templateNameAndShare"},[_c('div',{staticClass:"templateNameAndShare__content"},[_c('v-tooltip',{attrs:{"bottom":"","content-class":"tooltip-content","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-card-title',_vm._g(_vm._b({class:item.permissionLevel &&
                        item.permissionLevel === 'Editor'
                          ? 'card__title text-truncate available-theme-title'
                          : 'cardTitleForViewer',domProps:{"textContent":_vm._s(item.name)}},'v-card-title',attrs,false),on)),(item.isUserUploaded)?_c('v-card-subtitle',{staticClass:"subtitle"},[_vm._v(" Created by "+_vm._s(item.uploadedByName)+" ")]):_vm._e()]}}],null,true)},[_c('span',{staticClass:"tooltip"},[_vm._v(_vm._s(item.name)+" ")])])],1),(
                  item.permissionLevel && item.permissionLevel === 'Editor'
                )?_c('div',{staticClass:"templateShareOption",on:{"click":function($event){return _vm.handleShareTemplateModalOpen(item.name, item.id)}}},[_c('img',{attrs:{"src":"/assets/img/share_template_access.svg","alt":""}}),_c('p',[_vm._v(_vm._s(_vm.$t('common.share')))])]):_vm._e()])],1),_c('v-btn',{class:("select-theme " + (_vm.currentTheme.code === item.code ? 'active' : '')),attrs:{"text":_vm.currentTheme.code === item.code,"rounded":_vm.currentTheme.code !== item.code,"disabled":item.isUserUploaded &&
              item.jobSubmitted &&
              (item.status === 'inprogress' || item.status === 'failed'),"color":_vm.currentTheme.code !== item.code ? '#21a7e0' : '',"data-pendo-id":_vm.currentTheme.code === item.code
                ? 'selected-template'
                : undefined,"height":"36","min-width":"64"},on:{"click":function($event){return _vm.handleClickTheme(item)}}},[_vm._v(" "+_vm._s(_vm.currentTheme.code === item.code ? _vm.$t('tagSlides.currentSelection') : _vm.$t('profile.select'))+" ")])],1)])}),(!_vm.templateSearch && !_vm.globalSearchResult)?_c('v-col',{staticClass:"available-themes-list",attrs:{"cols":"12","sm":"4"}},[_c('div',{staticClass:"available-theme"},[_c('div',{staticClass:"available-theme-wrapper add-new",attrs:{"data-pendo-id":"add-new-template"},on:{"click":_vm.handleAddMore}},[_c('div',{staticClass:"icon-wrapper"},[_c('v-icon',{attrs:{"color":"#21a7e0"}},[_vm._v(" mdi-plus ")])],1),_c('div',{staticClass:"add-new-title"},[_vm._v(" "+_vm._s(_vm.$t('prezentationsList.addMoreBtn'))+" ")])])])]):_vm._e()],2):(!_vm.themes.length || !_vm.sortedThemeArray().length)?_c('v-row',{staticClass:"add-more-themes"},[_c('EmptyState',{attrs:{"img-url":"/assets/img/no-results-found-templates.svg","is-btn-visible":false},scopedSlots:_vm._u([{key:"description",fn:function(){return [_c('p',[_vm._v(" "+_vm._s(_vm.$t('profile.noTemplatesFound'))),_c('br'),_c('a',{on:{"click":_vm.addMoreTemplate}},[_vm._v(_vm._s(_vm.$t('fingerprint.getInTouch')))]),_vm._v(" "+_vm._s(_vm.$t('profile.addMoreTemplates'))+" ")])]},proxy:true}])})],1):_c('v-row',{staticClass:"template-spinner-wrapper"},[_c('Loading')],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }