<template>
  <div class="au-chart">
    <div class="au-chart__header">
      <div class="au-chart__head">
        <h3 class="au-chart__heading">
          {{ $t('profile.metricDetail.activeUsers') }}
        </h3>
        <v-tooltip right max-width="200" content-class="tooltip-content" fixed>
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="icon-title" v-bind="attrs" v-on="on">
              mdi-information-outline
            </v-icon>
          </template>
          <span> {{ $t('profile.metricDetail.numberOfUsers') }} </span>
        </v-tooltip>
        <v-tooltip right max-width="200" content-class="tooltip-content" fixed>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="primary"
              icon
              @click="download()"
            >
              <v-icon>mdi-download-outline</v-icon>
            </v-btn>
          </template>
          <span>
            {{ $t('profile.metricDetail.downloadAsCsv') }}
          </span>
        </v-tooltip>
      </div>
      <div class="au-chart__actions">
        <v-menu
          v-model="isDatepickerVisible"
          :close-on-content-click="false"
          :close-on-click="true"
          transition="scale-transition"
          offset-y
          :max-width="200"
          :min-width="200"
        >
          <template v-slot:activator="{}">
            <v-combobox
              class="au-chart__select"
              :items="filters"
              v-model="year"
              hide-details
              @change="trackFilterChange"
            >
              <template v-slot:selection="{ item }">
                <span v-if="item.value === 'custom' && datepickerValue">
                  {{ item.text }}: {{ datepickerValue }}
                </span>
                <span v-else>{{ item.text }}</span>
              </template>
              <template v-slot:item="{ item, attrs, on }">
                <v-list-item
                  v-bind="attrs"
                  v-on="on"
                  @click="onFilterSelection(item.value)"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.text }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-combobox>
          </template>
          <v-date-picker
            no-title
            scrollable
            color="#20a7e0"
            range
            :active-picker.sync="datepickerActivePicker"
            @click:year="applyYearPicker($event)"
            :max="`${new Date().getFullYear()}-01-01`"
            min="2022-01-01"
            :width="200"
            class="au-chart__datepicker"
          />
        </v-menu>
      </div>
    </div>
    <div class="au-chart__body">
      <svg ref="chart" class="au-chart__svg"></svg>
      <div class="au-chart__loader" v-if="loading">
        <clip-loader
          :loading="true"
          :color="`#21a7e0`"
          :width="'60'"
          :height="'60'"
          :size="'30px'"
          class="cliploader"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import * as d3 from 'd3';
import moment from 'moment';
import { isNumber } from 'lodash';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import csvDownload from 'json-to-csv-export';
import { getMyTeamActiveUser } from '../../../../utils/api-helper';
import { trackProfileEvents } from '../../../common/Analytics/ProfileEvents';
import {
  TD_PLACEMENT,
  TD_ACTIVE_USERS,
  TD_COMMON_COLUMN_NAME,
  TD_COMMON_COLUMN_VALUE,
  TD_FILTER_SELECTED,
  TD_CUSTOM,
} from '../../../common/Analytics/MatomoTrackingDataHelper';

export default {
  name: 'ActiveUsersChart',
  components: {
    ClipLoader,
  },
  data() {
    const filters = [
      {
        text: 'Current Year',
        value: new Date().getFullYear(),
      },
      {
        text: 'Previous Year',
        value: new Date().getFullYear() - 1,
      },
      {
        text: 'Custom',
        value: 'custom',
      },
    ];
    return {
      year: filters[0],
      data: [],
      loading: false,
      filters,
      isDatepickerVisible: false,
      datepickerValue: '',
      datepickerActivePicker: null,
    };
  },
  mounted() {
    this.fetchActiveUsers();
  },
  watch: {
    year({ value }) {
      if (value !== 'custom') {
        this.fetchActiveUsers();
        this.datepickerValue = '';
      }
    },
    isDatepickerVisible(val) {
      if (val) {
        setTimeout(() => {
          this.datepickerActivePicker = 'YEAR';
        });
      }
    },
  },
  computed: {
    ...mapState('users', ['currentUser']),
    isDatepickerDisabled() {
      return (
        !this.datepickerValue ||
        !this.allowedValidDates(moment(this.datepickerValue, 'YYYY'))
      );
    },
  },
  methods: {
    trackFilterChange(item) {
      if (item?.text !== 'Custom')
        trackProfileEvents.profileMetricsDateFilterUsed(this.currentUser, {
          [TD_PLACEMENT]: TD_ACTIVE_USERS,
          [TD_COMMON_COLUMN_NAME]: TD_FILTER_SELECTED,
          [TD_COMMON_COLUMN_VALUE]: item?.text,
        });
    },
    fetchActiveUsers(year) {
      this.loading = true;
      getMyTeamActiveUser(year || this.year.value)
        .then((response) => {
          if (this.year.text !== 'Custom') {
            this.data = response.data?.filter((item) => item.users !== null);
            this.createChart(this.data);
          } else {
            this.data = response.data;
            this.createChart(this.data);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    createChart(dataset) {
      const filteredDataset = dataset.filter((item) => isNumber(item.users));
      const compSvg = this.$refs.chart;
      const areaRect = compSvg.getBoundingClientRect();
      const areaWidth = areaRect.width;

      const margin = { top: 20, right: 20, bottom: 20, left: 50 };
      const width = areaWidth - margin.left - margin.right;
      const height = 250 - margin.top - margin.bottom;
      // const leftSpace = 43;

      const svg = d3.select(compSvg).attr('viewBox', [0, 0, width, height]);

      const x = d3
        .scaleBand()
        .domain(dataset.map((d) => d.month))
        .rangeRound([margin.left, width - margin.right])
        .padding(0.1);

      const yMax = d3.max([d3.max(dataset, (d) => d.users), 50]);
      const y = d3
        .scaleLinear()
        .domain([0, yMax])
        .range([height - margin.bottom, margin.top]);

      const line = d3
        .line()
        .x((d) => x(d.month) + x.bandwidth() / 2)
        .y((d) => y(d.users))
        .curve(d3.curveLinear);

      // X-axis
      const xAxisSelection = svg.selectAll('.x-axis').data([0]);

      xAxisSelection.exit().remove();

      xAxisSelection
        .enter()
        .append('g')
        .attr('class', 'x-axis')
        .attr('transform', `translate(-2, ${height - margin.bottom})`)
        .call(
          d3
            .axisBottom(x)
            .tickFormat((d) =>
              moment(d, 'YYYY-M').endOf('month').format(`MMM DD 'YY`),
            )
            .tickSizeOuter(0),
        )
        .style('font-size', '12px')
        .style('stroke-width', '1px');

      xAxisSelection.call(
        d3
          .axisBottom(x)
          .tickFormat((d) =>
            moment(d, 'YYYY-M').endOf('month').format(`MMM DD 'YY`),
          )
          .tickSizeOuter(0),
      );

      // Y-axis
      const yAxisSelection = svg.selectAll('.y-axis').data([0]);

      yAxisSelection.exit().remove();

      yAxisSelection
        .enter()
        .append('g')
        .attr('class', 'y-axis')
        .attr('transform', `translate(${margin.left}, 0)`)
        .call(d3.axisLeft(y).ticks(5, 'f').tickSizeOuter(0))
        .style('font-size', '12px')
        .style('stroke-width', '1px');

      yAxisSelection.call(d3.axisLeft(y).ticks(5, 'f').tickSizeOuter(0));

      // Y-axis label
      svg.select('.y-axis-label').remove();
      svg
        .append('text')
        .attr('class', 'y-axis-label')
        .attr('text-anchor', 'end')
        .attr('transform', 'rotate(-90)')
        .attr('y', 10)
        .attr('x', -(height / 2))
        .attr('font-size', '12px')
        .attr('text-anchor', 'middle')
        .text('Number of active users');

      // Line
      const lineSelection = svg
        .selectAll('path.main-line')
        .data([dataset.filter((a) => a.users !== null)]);

      lineSelection.exit().remove();

      lineSelection
        .enter()
        .append('path')
        .attr('class', 'main-line')
        .attr('fill', 'none')
        .attr('stroke', '#21a7e0')
        .attr('stroke-width', 3)
        .attr('d', line);

      lineSelection.transition().duration(500).attr('d', line);

      // Dots
      const dotSelection = svg.selectAll('.dot').data(filteredDataset);

      dotSelection.exit().remove();

      dotSelection
        .enter()
        .append('g')
        .attr('class', 'dot')
        .append('circle')
        .attr('r', 5)
        .style('fill', '#21a7e0')
        .attr('cx', (d) => x(d.month) + x.bandwidth() / 2)
        .attr('cy', (d) => y(d.users));

      dotSelection
        .select('circle')
        .transition()
        .duration(500)
        .attr('cx', (d) => x(d.month) + x.bandwidth() / 2)
        .attr('cy', (d) => y(d.users));

      // Labels
      const dotLabelShadowSelection = svg
        .selectAll('.dot-label-shadow')
        .data(filteredDataset);

      dotLabelShadowSelection.exit().remove();

      dotLabelShadowSelection
        .enter()
        .append('g')
        .attr('class', 'dot-label-shadow')
        .append('text')
        .attr('x', (d) => x(d.month) + x.bandwidth() / 2)
        .attr('y', (d) => y(d.users) - 10)
        .text((d) => d.users?.toLocaleString())
        .attr('fill', '#FFF')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'after-edge')
        .attr('font-size', '14px')
        .attr('stroke', '#FFF')
        .attr('stroke-width', 5)
        .attr('transform', 'translate(0, -5)');

      dotLabelShadowSelection
        .select('text')
        .transition()
        .duration(500)
        .attr('x', (d) => x(d.month) + x.bandwidth() / 2)
        .attr('y', (d) => y(d.users))
        .text((d) => d.users?.toLocaleString());

      const dotLabelSelection = svg
        .selectAll('.dot-label')
        .data(filteredDataset);

      dotLabelSelection.exit().remove();

      dotLabelSelection
        .enter()
        .append('g')
        .attr('class', 'dot-label')
        .append('text')
        .attr('x', (d) => x(d.month) + x.bandwidth() / 2)
        .attr('y', (d) => y(d.users) - 10)
        .attr('y', (d) => y(d.users))
        .text((d) => d.users?.toLocaleString())
        .attr('fill', '#7B7B7B')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'after-edge')
        .attr('font-size', '14px')
        .attr('transform', 'translate(0, -5)')
        .style('display', (d) => {
          if (d.users === null) {
            return 'none';
          }
          return null;
        });

      dotLabelSelection
        .select('text')
        .transition()
        .duration(500)
        .attr('x', (d) => x(d.month) + x.bandwidth() / 2)
        .attr('y', (d) => y(d.users))
        .text((d) => d.users?.toLocaleString());
    },
    onFilterSelection(value) {
      if (value === 'custom') {
        this.isDatepickerVisible = true;
      }
    },
    allowedValidDates(val) {
      return moment(val).isSameOrAfter(moment('2023-01-01', 'YYYY-MM-DD'));
    },
    openYearPicker() {
      this.isDatepickerVisible = true;
    },
    closeYearPicker() {
      this.isDatepickerVisible = false;
    },
    applyYearPicker(year) {
      this.datepickerValue = year;
      setTimeout(() => {
        this.datepickerActivePicker = 'YEAR';
      });
      this.isDatepickerVisible = false;
      this.fetchActiveUsers(this.datepickerValue);
      trackProfileEvents.profileMetricsDateFilterUsed(this.currentUser, {
        [TD_PLACEMENT]: TD_ACTIVE_USERS,
        [TD_COMMON_COLUMN_NAME]: TD_FILTER_SELECTED,
        [TD_COMMON_COLUMN_VALUE]: TD_CUSTOM,
      });
    },
    download() {
      csvDownload({
        headers: ['Month', 'Number of Active Users'],
        data: this.data.map((item) => [
          moment(item.month).endOf('month').format(`MMM DD 'YY`),
          item.users ?? '',
        ]),
        filename: 'Active Users',
        delimiter: ',',
      });
      trackProfileEvents.profileMetricsDownload(this.currentUser, {
        [TD_PLACEMENT]: TD_ACTIVE_USERS,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.au-chart {
  text-align: left;

  &__header {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 12px;
    margin: 0px 0px 16px 0px;
    align-items: end;
  }
  &__head {
    display: flex;
    gap: 12px;
  }
  &__heading {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
  &__toolbar {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  &__select {
    width: 200px;
  }
  &__body {
    margin-bottom: 52px;
    position: relative;
  }
  &__svg {
    width: 100%;
  }
  &__loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
  &__datepicker {
    ::v-deep .v-date-picker-years {
      height: auto;
      padding-bottom: 18px;
    }
  }
}
</style>
