<template>
  <div class="slide-theme-change-wrapper">
    <div class="slide-theme-change-title">
      {{ getConfirmationHeading }}
    </div>
    <div
      class="slide-theme-change-desc"
      :class="isUpdated ? 'space-adjust' : ''"
    >
      {{ getConfirmationDesc }}
    </div>
    <div class="slide-theme-change-actions">
      <template v-if="!isUpdated">
        <v-btn
          rounded
          height="36"
          min-width="64"
          color="#ffffff"
          @click="handleClose"
        >
          {{ $t('common.cancel') }}
        </v-btn>
        <v-btn
          rounded
          height="36"
          min-width="64"
          color="#21a7e0"
          class="slide-theme-change-btn"
          @click="handleSubmit"
        >
          {{ $t('fingerprint.groupInsights.yesSure') }}
        </v-btn>
      </template>
      <v-btn
        v-else
        rounded
        height="36"
        min-width="64"
        color="#21a7e0"
        class="slide-theme-change-btn"
        @click="handleClose"
      >
        {{ $t('common.done') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SlideThemeChangeDetail',
  props: {
    index: {
      type: Object,
    },
    updateThemeIndex: {
      type: Function,
      default: () => ({}),
    },
  },
  data() {
    return {
      isUpdated: false,
    };
  },
  computed: {
    getConfirmationHeading() {
      return this.isUpdated
        ? this.$t('slides.templateUpdated')
        : this.$t('fingerprint.groupInsights.areYouSure');
    },
    getConfirmationDesc() {
      return this.isUpdated
        ? this.$t('slides.templatesApplyText')
        : this.$t('slides.basedOnAudiencePreferences');
    },
  },
  methods: {
    handleClose() {
      this.$modal.hide('SlideThemeChangeDetail');
    },
    handleSubmit() {
      this.updateThemeIndex(this.index);
      this.isUpdated = true;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.slide-theme-change-wrapper {
  background: white;
  width: 100%;
  height: 100%;
  padding: 20px;
  .slide-theme-change-title {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    margin-bottom: 9px;
  }
  .slide-theme-change-desc {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-bottom: 10px;
    &.space-adjust {
      margin-bottom: 30px;
    }
  }

  .slide-theme-change-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .slide-theme-change-btn {
    margin-left: 20px;
    color: white;
    border: 2px solid #21a7e0;
  }

  button {
    border: 1.5px solid #21a7e0;
    border-color: #21a7e0 !important;
    border-radius: 25px;
    color: #21a7e0;
    text-transform: none;
    font-family: 'Lato' !important;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    padding: 16px;
  }
}
</style>
