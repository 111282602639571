<template>
  <div class="learn-landing-item">
    <NewFeatureBadge
      class="d-flex"
      style="width: 100%; justify-content: end; height: 0"
      module-name="learncourse"
      :feature-id="courseInfo.id"
      :offsetX="'120'"
      :offsetY="'30'"
      :content="'common.newContent'"
    >
    </NewFeatureBadge>
    <div :class="getDivWithImgClass(courseInfo.is_locked)">
      <img
        loading="lazy"
        :src="courseInfo.illustration_med"
        :alt="courseInfo.title"
        @click="selectCourse({ origin: origin.thumbnail })"
      />
    </div>
    <div class="learn-landing-item-progress">
      <v-progress-linear
        height="7"
        :value="courseInfo.status"
        color="primary"
        background-color="#f2f2f2"
      />
    </div>
    <v-expansion-panels accordion multiple v-model="panel">
      <v-expansion-panel class="course-title-expansion-panel">
        <v-expansion-panel-header
          class="pl-2 pt-0 course-title-expansion-header"
        >
          <div class="course-title-section">
            <div class="certificate-icon">
              <span>
                <v-icon
                  :color="courseInfo.status === 100 ? '#21a7e0' : '#b6b6b6'"
                  @click="handleCertificateIcon"
                  :class="`${
                    courseInfo.status === 100
                      ? 'cert-completed--auto'
                      : 'cert-notcompleted--auto'
                  }`"
                  >mdi-certificate-outline</v-icon
                >
              </span>
            </div>
            <div class="learn-landing-item-title">
              {{ courseInfo.display_name }}
            </div>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pr-0">
          <v-divider style="padding-right: 0; padding-left: 0"></v-divider>
          <v-list dense>
            <v-list-item-group color="primary">
              <v-list-item
                v-for="(courseModule, moduleIndex) in courseInfo.modules"
                :key="moduleIndex"
                @click="
                  goToCourse(courseInfo.id, courseModule.id, courseModule)
                "
              >
                <v-list-item-icon class="mr-3">
                  <v-icon
                    :color="courseModule.status < 100 ? '#d8d8d8' : '#21a7e0'"
                    style="font-size: 20px"
                    >mdi-shield-star</v-icon
                  >
                </v-list-item-icon>
                <v-list-item-content>
                  <v-tooltip
                    top
                    max-width="250"
                    content-class="tooltip-content"
                    fixed
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item-title
                        v-if="!courseInfo.is_locked"
                        class="course-module-title"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ courseModule.display_name }}
                      </v-list-item-title>
                      <v-list-item-title
                        v-else
                        @click="
                          selectCourse({ origin: origin.thumbnail_module })
                        "
                        class="course-module-title"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ courseModule.display_name }}
                      </v-list-item-title>
                    </template>
                    <span> {{ courseModule.display_name }}</span>
                  </v-tooltip>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import CourseOverviewModal from './CourseOverviewModal.vue';
import LearnApi from '../../../API/learn-api.js';
import CourseCertificateModal from './CourseCertificateModal.vue';
import EmailSentModal from './EmailSentModal.vue';
import { MatomoAnalyticsHandler } from '../../common/Analytics/MatomoAnalyticsHandler';
import NewFeatureBadge from '../../common/NewFeatureBadge.vue';
import { trackLearnEvents } from '../../common/Analytics/LearnEvents';

export default {
  name: 'CourseCard',
  mixins: [LearnApi],
  components: { NewFeatureBadge },
  props: {
    courseInfo: {
      type: Object,
      required: true,
    },
    domain: {
      type: String,
      required: true,
    },
    isActiveCourse: {
      type: Boolean,
      default: false,
    },
    openPanel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      origin: {
        thumbnail: 'thumbnail',
        thumbnail_module: 'thumbnail-module',
        detail: 'detail',
      },
      courseInfoForMatomo: {
        ...this.courseInfo,
        domain: this.domain,
      },
      panel: [],
    };
  },
  computed: {
    ...mapState('users', ['currentUser']),
  },
  methods: {
    selectCourse({ origin }) {
      this.$modal.show(
        CourseOverviewModal,
        {
          skillsCovered: this.domain,
          courseInfo: this.courseInfo,
          courseInfoForMatomo: this.courseInfoForMatomo,
          modalClosed: this.handleModalClosed,
        },
        {
          name: 'CourseOverviewModal',
          width: '1172px',
          height: '690px',
          styles: { borderRadius: '18px' },
        },
      );
      try {
        switch (origin) {
          case this.origin.thumbnail:
            MatomoAnalyticsHandler.learnCourseViewedThumbnail(
              this.courseInfoForMatomo,
              this.currentUser,
            );
            trackLearnEvents.learnThumbnailClick(
              this.currentUser,
              this.courseInfoForMatomo,
            );
            break;

          case this.origin.thumbnail_module:
            MatomoAnalyticsHandler.learnCourseViewedThumbnailModuleClick(
              this.courseInfoForMatomo,
              this.currentUser,
            );
            break;

          default:
        }
      } catch (e) {
        console.log(e);
      }
    },
    handleCertificateIcon(e) {
      MatomoAnalyticsHandler.tractViewCertificateIconClick(
        this.currentUser.user,
        this.courseInfo.display_name,
      );
      trackLearnEvents.learnCourseViewCertIcon(
        this.currentUser,
        this.courseInfoForMatomo,
      );
      e.stopPropagation();
      if (this.courseInfo.status === 100) {
        this.$modal.show(
          CourseCertificateModal,
          {
            courseDetails: this.courseInfoForMatomo,
            emailSuccess: this.openEmailSentModal,
            functionalScreen: 'landingPage',
          },
          {
            name: 'CourseCertificateModal',
            width: '1172px',
            height: '75%',
            styles: { borderRadius: '20px' },
          },
        );
      }
    },
    openEmailSentModal() {
      this.$modal.show(
        EmailSentModal,
        {
          courseDetails: this.courseDetails,
        },
        {
          name: 'EmailSentModal',
          width: '500px',
          height: '500px',
          styles: { borderRadius: '20px' },
        },
      );
    },
    goToCourse(courseId, mId, courseModule) {
      MatomoAnalyticsHandler.learnCourseViewedThumbnailModuleClick(
        { ...this.courseInfoForMatomo, moduleName: courseModule.display_name },
        this.currentUser,
      );
      trackLearnEvents.learnThumbnailModuleClick(
        this.currentUser,
        this.courseInfoForMatomo,
      );
      if (mId) {
        this.$router.push({
          name: 'course.detail',
          params: { id: courseId, moduleId: mId },
        });
      } else {
        this.$router.push({
          name: 'course.detail',
          params: { id: courseId },
        });
      }
    },
    getDivWithImgClass(isLocked) {
      if (isLocked) {
        return 'learn-landing-item-image-blured';
      }
      return 'learn-landing-item-image';
    },
    handleModalClosed() {
      const query = { ...this.$route.query };
      // removing courseid from query
      if (query.courseid) {
        delete query.courseid;
        this.$router.replace({ query }).catch(() => {});
        this.$emit('removeActiveCourse');
      }
    },
  },
  mounted() {
    if (this.isActiveCourse) {
      this.selectCourse({});
    }
    if (this.openPanel) {
      this.panel.push(0);
    } else {
      this.panel = [];
    }
  },
  watch: {
    isActiveCourse(val) {
      if (val) {
        this.selectCourse({});
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/variables.scss';

.v-expansion-panel-content {
  max-height: 150px;
}

::v-deep .v-expansion-panel-header__icon {
  margin-top: 0px;
}

.certificate-icon {
  margin-right: 12px;
}

.learn-landing-items {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: flex-start;
  .inactive {
    pointer-events: none;
  }

  .learn-landing-item {
    min-height: 350px;
    cursor: pointer;
    object-fit: contain;
    //box-shadow: 4px 5px 16px -3px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1),
      -1px -1px 4px 0 rgba(0, 0, 0, 0.1);
    position: relative;

    .learn-landing-item-coming {
      position: absolute;
      top: 21px;
      right: 18px;
      padding: 2px 25.4px 6px 25.6px;
      border-radius: 16px;
      background: #21a7e0;
      font-size: 20px;
      font-weight: normal;
      color: white;
      text-align: center;
      z-index: 1;
    }
    .learn-landing-item-image-blured {
      width: 100%;
      height: 266px;
      position: relative;
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      /* background-image: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0.04),
          rgba(255, 255, 255, 0.99)
        );
       */
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.3;
        /*   -webkit-filter: blur(5px); /* Safari 6.0 - 9.0 */
        /*filter: blur(5px); */
      }
    }
    .learn-landing-item-image {
      width: 100%;
      height: 266px;
      position: relative;
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      /* background-image: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0.04),
          rgba(255, 255, 255, 0.99)
        ); */
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        /*  -webkit-filter: blur(3px); /* Safari 6.0 - 9.0 */
        /*filter: blur(3px); */
      }
    }

    .learn-landing-item-progress {
      margin-bottom: 17px;
    }

    .learn-landing-item-desc {
      font-size: 16px;
      font-weight: normal;
      text-align: left;
      margin-bottom: 3px;
      padding: 0 17px 0 14px;
    }

    .learn-landing-item-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 17px 0 14px;

      .learn-landing-item-info-modules,
      .learn-landing-item-info-hours {
        font-size: 14px;
        font-weight: normal;
        color: $gray-dark-login-text;
        text-align: left;

        &.coming {
          visibility: hidden;
        }
      }
    }
  }
}
.learn-landing-item:hover {
  box-shadow: -4px 4px 25px 1px rgba(0, 0, 0, 0.3);
}
.course-title-expansion-panel::before {
  box-shadow: unset !important;
}
.course-title-expansion-header {
  align-items: flex-start !important;
  padding-left: 10px !important;
  padding-top: 0px;
  padding-bottom: 0px;
  min-height: 40px;
}
.course-title-section {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  // width: 300px;

  .learn-landing-item-title {
    color: $grey-blue;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-bottom: 5px;
    padding: 0 0px 0 2px;
    text-align: left;
  }
}

.course-module-title {
  color: $zen-blue-default;
  font-size: 18px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  letter-spacing: normal;
}

::v-deep .v-expansion-panel {
  .v-expansion-panel-header__icon svg,
  .learn-landing-item-title {
    color: black;
  }
  &--active > .v-expansion-panel-header {
    min-height: 40px;
    .v-expansion-panel-header__icon svg,
    .learn-landing-item-title {
      color: #21a7e0;
    }
  }
}
</style>
