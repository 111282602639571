<template>
  <div class="deletePaymentMethodModalContainer">
    <div class="closeIconContainer" @click="handleModalClose">
      <v-icon>{{ 'mdi-close' }}</v-icon>
    </div>
    <div class="modalHeading">
      {{ $t('profile.editPaymentDetail.deletePaymentMethod') }}
    </div>
    <div class="modalTextContainer">
      {{ modalText }}
    </div>
    <div
      class="modalSubTextContainer"
      v-if="
        card.is_default === true &&
        (getPaymentMethods.length === 2 || getPaymentMethods.length === 3)
      "
    >
      {{ $t('profile.editPaymentDetail.likeToDelete') }}
    </div>
    <div
      class="selectCardsContainer"
      v-if="card.is_default === true && getPaymentMethods.length > 3"
    >
      <div class="selectLabel">
        {{ $t('profile.editPaymentDetail.newDefaultMethod') }}
      </div>
      <div class="cardSelect">
        <v-select
          v-model="cardSelected"
          :items="[
            ...getPaymentMethods.filter(
              (itm) => !itm.title && itm.id !== card.id,
            ),
          ]"
          return-object
          single-line
          :placeholder="$t('profile.editPaymentDetail.selectACard')"
          @change="(e) => handleCardSelectChange(e)"
        >
          <template #prepend-inner v-if="!cardSelected">
            <v-icon>
              {{ 'mdi-credit-card-outline' }}
            </v-icon>
          </template>
          <template v-slot:selection="{ item }">
            <div class="selectedCardLogoContainer">
              <v-img
                :src="`${logos[item.paymentMethodBrand]}`"
                alt="image"
                :aspect-ratio="16 / 9"
                lazy-src="/assets/img/Generic-card.svg"
              />
            </div>
            <div class="selectItemCardInfo">
              <div class="selectMask">
                {{ `**** **** **** ****` }}
              </div>
              <div class="selectLast4">
                {{ item.paymentMethodLast4 }}
              </div>
            </div>
          </template>
          <template v-slot:item="{ item }">
            <div class="cardLogoContainer">
              <v-img
                :src="`${logos[item.paymentMethodBrand]}`"
                alt="image"
                :aspect-ratio="16 / 9"
                lazy-src="/assets/img/Generic-card.svg"
              />
            </div>
            <div class="itemCardInfo">
              <div class="itemContainerMask">
                {{ `**** **** **** ****` }}
              </div>
              <div class="itemContainerLast4">
                {{ item.paymentMethodLast4 }}
              </div>
            </div>
          </template>
        </v-select>
      </div>
    </div>
    <div class="modalActions">
      <div class="buttonsContainer">
        <v-btn class="cancel" @click="handleModalClose">
          {{ $t('common.cancel') }}
        </v-btn>
        <v-btn
          class="accept"
          :disabled="
            card.is_default === true &&
            getPaymentMethods.length > 3 &&
            !cardToBeReplacedAsDefault
          "
          :loading="deleting === true"
          @click="handlePaymentMethodDelete"
        >
          {{ $t('common.delete') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import {
  deletePaymentMethod,
  markPaymentMethodAsDefault,
} from '../../../../API/payment-api';
import {
  TD_PLACEMENT,
  TD_DELETE,
} from '../../../common/Analytics/MatomoTrackingDataHelper';
import { trackProfileEvents } from '../../../common/Analytics/ProfileEvents';

export default {
  name: 'DeletePaymentMethodModal',
  props: {
    card: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      cardToDelete: null,
      cardToBeReplacedAsDefault: null,
      modalText: '',
      cardSelected: null,
      logos: {
        visa: '/assets/img/Visa.svg',
        mastercard: '/assets/img/Mastercard.svg',
        amex: '/assets/img/American-Express.svg',
        jcb: '/assets/img/JCB.svg',
        unionpay: '/assets/img/Union-Pay.svg',
        discover: '/assets/img/Discover.svg',
        diners: '/assets/img/Diners-Club.svg',
      },
      deleting: false,
    };
  },
  computed: {
    ...mapGetters('paymentMethods', ['getPaymentMethods']),
    ...mapState('users', ['currentActiveSubscription', 'currentUser']),
  },
  methods: {
    ...mapActions('paymentMethods', [
      'setSnackbar',
      'removePaymentMethod',
      'setPaymentMethodAsDefault',
    ]),
    handleModalClose(e) {
      this.$modal.hide('DeletePaymentMethodModal');
      if (e) {
        trackProfileEvents.profileAdminSettingsPaymentsCancel(
          this.currentUser,
          {
            [TD_PLACEMENT]: TD_DELETE,
          },
        );
      }
    },

    getCardToBeReplacedAsDefault() {
      if (!this.card.is_default) {
        this.cardToBeReplacedAsDefault = null;
        this.modalText = this.$t('storyline.youWantToDeleteThisPaymentMethod');
      } else if (this.getPaymentMethods.length === 2) {
        this.cardToBeReplacedAsDefault = null;
        this.modalText = this.$t(
          'storyline.yourTeamWillNoLongerHaveSavedCard',
          {
            currentActiveSubscription: this.currentActiveSubscription
              ?.stripeSubscriptionID
              ? this.$t('storyline.yourTeamWillFailToAutoRenew')
              : '',
          },
        );
      } else if (this.getPaymentMethods.length === 3) {
        const alternativeCard = [...this.getPaymentMethods].find(
          (itm) => itm.id !== this.card.id && !itm.title,
        );
        this.cardToBeReplacedAsDefault = alternativeCard;
        this.modalText = this.$t(
          'storyline.deletingYourDefaultCardForPayment',
          { Alternativecard: alternativeCard.paymentMethodLast4 },
        );
      } else {
        this.cardToBeReplacedAsDefault = null;
        this.modalText = this.$t('storyline.thisIsYourDefaultPaymentMethod');
      }
    },

    handleCardSelectChange(e) {
      this.cardToBeReplacedAsDefault = e;
    },

    async handlePaymentMethodDelete() {
      this.deleting = true;
      let idToDelete = '';
      await deletePaymentMethod(this.cardToDelete.id)
        .then(async (resp) => {
          console.log(resp);
          if (this.cardToBeReplacedAsDefault) {
            await markPaymentMethodAsDefault({
              payment_method_id: this.cardToBeReplacedAsDefault.id,
            })
              .then((res) => {
                this.setPaymentMethodAsDefault(
                  this.cardToBeReplacedAsDefault.id,
                );
                console.log(res);
                this.deleting = false;
                this.handleModalClose();
                idToDelete = this.cardToDelete.id;
                this.setSnackbar({
                  status: true,
                  text: 'Payment method deleted successfully!',
                  action: null,
                });
              })
              .catch((er) => {
                console.log(er);
                this.deleting = false;
                this.handleModalClose();
                idToDelete = this.cardToDelete.id;
              });
          } else {
            idToDelete = this.cardToDelete.id;
            this.deleting = false;
            this.handleModalClose();
            this.setSnackbar({
              status: true,
              text: 'Payment method deleted successfully!',
              action: null,
            });
          }
          trackProfileEvents.profileAdminSettingsPaymentsElipsisDeleteDelete(
            this.currentUser,
            {},
          );
        })
        .catch((err) => {
          this.setSnackbar({
            status: true,
            text: 'Could not delete payment method. Something went wrong.',
            action: null,
          });
          console.log(err);
          this.handleModalClose();
          this.deleting = false;
        });
      if (idToDelete !== '' && !!idToDelete) {
        this.removePaymentMethod(idToDelete);
      }
    },
  },
  mounted() {
    this.cardToDelete = this.card;
    this.getCardToBeReplacedAsDefault();
  },
};
</script>

<style lang="scss" scoped>
.deletePaymentMethodModalContainer {
  width: 100%;
  padding: 24px 32px;
  .closeIconContainer {
    text-align: right;
    position: absolute;
    right: 10px;
    top: 8px;
    cursor: pointer;
  }
  .modalHeading {
    width: 100%;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
  .modalTextContainer {
    width: 100%;
    font-size: 16px;
    margin: 24px 0px 30px 0px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    font-weight: 400;
  }
  .modalSubTextContainer {
    width: 100%;
    font-size: 16px;
    margin: 24px 0px 32px 0px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    font-weight: 400;
  }
  .selectCardsContainer {
    width: 100%;
    margin: 0px 0px 24px 0px;
    .selectLabel {
      width: 100%;
      padding: 16px 0px 20px 0px;
      font-size: 16px;
      font-weight: 600;
    }
    .cardSelect {
      ::v-deep .v-input {
        margin: 0 !important;
        padding: 0 !important;
        .v-input__control {
          .v-input__slot {
            margin: 0 !important;
            &:before {
              border-style: none !important;
              border-width: 0px !important;
              border-bottom: thin solid rgba(0, 0, 0, 0.42) !important;
            }
            &:after {
              border: none !important;
            }
            .v-select__slot {
              .v-select__selections {
                input {
                  padding: 6px 0px 8px 8px;
                }
                .selectedCardLogoContainer {
                  padding: 4px 0px 0px 0px;
                  .v-image {
                    width: 30px;
                    height: 24px;
                  }
                }
                .selectItemCardInfo {
                  display: flex;
                  padding: 0px 0px 0px 16px;
                  .selectMask {
                    font-size: 24px;
                    height: 10px;
                    font-weight: 600;
                    padding: 8px 0px 0px 0px;
                  }
                  .selectLast4 {
                    font-size: 14px;
                    font-weight: 600;
                    font-size: 14px;
                    padding: 2px 0px 0px 8px;
                  }
                }
              }
            }
          }
          .v-text-field__details {
            display: none;
          }
        }
      }
      ::v-deep .v-input--is-focused {
        margin: 0 !important;
        padding: 0 !important;
        .v-input__control {
          .v-input__slot {
            margin: 0 !important;
            &:before {
              border-style: none !important;
              border-width: 0px !important;
              border-bottom: thin solid #21a7e0 !important;
            }
            &:after {
              border: none !important;
            }
            .v-select__slot {
              .v-select__selections {
                input {
                  padding: 6px 0px 8px 8px;
                }
                .selectedCardLogoContainer {
                  padding: 4px 0px 0px 0px;
                  .v-image {
                    width: 30px;
                    height: 24px;
                  }
                }
                .selectItemCardInfo {
                  display: flex;
                  padding: 0px 0px 0px 16px;
                  .selectMask {
                    font-size: 24px;
                    height: 10px;
                    font-weight: 600;
                    padding: 8px 0px 0px 0px;
                  }
                  .selectLast4 {
                    font-size: 14px;
                    font-weight: 600;
                    font-size: 14px;
                    padding: 2px 0px 0px 8px;
                  }
                }
              }
            }
          }
          .v-text-field__details {
            display: none;
          }
        }
      }
    }
  }
  .modalActions {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .buttonsContainer {
      width: 35%;
      display: flex;
      justify-content: space-between;
      .cancel,
      .accept {
        background: transparent;
        box-shadow: none;
        letter-spacing: normal;
        text-transform: none;
        font-size: 17px;
        color: #20a7e0;
        border-radius: 22px;
      }
      ::v-deep .cancel {
        color: #20a7e0;
        border: 1px solid #20a7e0;
        background-color: transparent;
        .v-btn__content {
          line-height: 25px !important;
        }
      }
      ::v-deep .accept {
        color: #fff;
        border: 0px;
        background-color: #20a7e0;
        .v-btn__content {
          line-height: 25px !important;
        }
      }
    }
  }
}
.v-menu__content {
  .v-list {
    ::v-deep .v-list-item {
      color: white !important;
      .cardLogoContainer {
        padding: 4px 0px 0px 0px;
        .v-image {
          width: 30px;
          height: 24px;
        }
      }
      .itemCardInfo {
        display: flex;
        padding: 0px 0px 0px 16px;
        color: black;
        .itemContainerMask {
          font-size: 24px;
          height: 10px;
          font-weight: 600;
        }
        .itemContainerLast4 {
          font-size: 14px;
          font-weight: 600;
          font-size: 14px;
          padding: 2px 0px 0px 8px;
        }
      }
      &:hover {
        background-color: #d8d8d8 !important;
      }
    }
  }
}
</style>
