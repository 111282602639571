<template>
  <div class="multipanel-container">
    <div class="multipanel-wrap">
      <v-expansion-panels class="multiContentPanels" v-model="currPanel">
        <template v-for="panel in panels">
          <v-expansion-panel :key="panel.id">
            <v-expansion-panel-header>
              <img :src="panel.icon" alt />
              <div class="panelHeader">{{ panel.description }}</div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div v-if="panel.type === 'texts'" class="textContent">
                <div class="textHeading">
                  {{ 'Add your message here' }}
                </div>
                <v-textarea
                  v-model="currVal.texts.text"
                  @change="onChange"
                  solo
                  :placeholder="'Please type in your instructions, at least 10 characters. e.g. Make this slide vibrant.'"
                  :no-resize="true"
                >
                  <template #append>
                    <v-btn
                      text
                      class="clear-button"
                      rounded
                      v-if="currVal.texts.text && currVal.texts.text.length > 0"
                      color="#20a7e0"
                      height="30"
                      @click="currVal.texts.text = ''"
                      >{{ $t('common.clear') }}</v-btn
                    >
                  </template>
                </v-textarea>
              </div>
              <div v-if="panel.type === 'links'" class="linkContent">
                <div class="linkHeading">
                  {{
                    'Paste link(s) from Google Slides or Microsoft 365. Recommended for files larger than 200 MB.'
                  }}
                  <p class="link-sub-heading">
                    {{ 'Paste each shareable link in a new line' }}
                  </p>
                </div>
                <v-form ref="form">
                  <v-textarea
                    v-model="link"
                    @keydown.enter.prevent="onEnter"
                    @paste.prevent="onPaste"
                    class="share-links"
                    solo
                    :rules="linkRules"
                    :placeholder="'Paste links here'"
                    :no-resize="true"
                  >
                    <template #prepend-inner v-if="currVal.links.length">
                      <div class="links-container">
                        <template v-for="(link, i) in currVal.links">
                          <div class="file-chip" :key="link.web_id">
                            <div class="fileNameWrapper">
                              {{ link.web_url }}
                            </div>

                            <v-icon
                              :size="18"
                              :color="'#7B7B7B'"
                              @click="removeLink(i)"
                              >mdi-close</v-icon
                            >
                          </div>
                        </template>
                      </div>
                    </template>
                    <template #append>
                      <v-btn
                        text
                        class="clear-button"
                        rounded
                        v-if="currVal.links && currVal.links.length > 0"
                        color="#20a7e0"
                        height="30"
                        @click="clearLinks"
                        >{{ $t('common.clear') }}</v-btn
                      >
                    </template>
                  </v-textarea>
                </v-form>

                <div
                  :class="`${
                    currVal.links.length > 0 && !currVal.linkAccess
                      ? 'check-access-error'
                      : 'check-access'
                  }`"
                >
                  <v-checkbox
                    v-model="currVal.linkAccess"
                    @change="onChange"
                    :hide-details="true"
                    :label="'I have shared access with slides@prezentium.com'"
                  />
                  <span class="guide-link" @click="handleHowtoShareAccess">
                    {{ 'How to share access?' }}
                  </span>
                </div>
              </div>
              <div v-if="panel.type === 'files'" class="fileContent">
                <div class="fileHeading">
                  {{ 'Upload files. The maximum file upload size is 200 MB.' }}
                  <span
                    ><v-tooltip
                      top
                      max-width="220"
                      content-class="tooltip-content"
                      fixed
                      open-delay="500"
                      close-delay="300"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="info-icon"
                          v-bind="attrs"
                          v-on="on"
                          v-text="`mdi-information-outline`"
                          size="18"
                        />
                      </template>
                      <span>
                        {{ $t('overnightPresentations.fileType') }}
                      </span>
                    </v-tooltip></span
                  >
                </div>
                <div class="file-upload-action">
                  <label for="file-upload" class="custom-file-upload">
                    <span :class="{ 'disable-upload': uploadInprogress }">
                      <v-icon>{{ 'mdi-tray-arrow-up' }}</v-icon>
                      {{ 'Upload files' }}
                    </span>
                  </label>
                  <input
                    id="file-upload"
                    type="file"
                    ref="hiddenInput"
                    :accept="`${allowedUploadTypes.join(', ')}`"
                    @change="previewFiles"
                    multiple
                    :disabled="uploadInprogress"
                  />
                </div>
                <div class="upload-limit">
                  {{ 'Add as many files as you need' }}
                </div>
                <div class="file-chips-wrapper">
                  <div
                    class="file-placeholder"
                    v-if="
                      (!localFiles || localFiles.length === 0) &&
                      (!filesPreview || filesPreview.length === 0)
                    "
                    @click="uploadFileClick"
                  >
                    Your files will appear here
                  </div>
                  <template v-else>
                    <div class="chips-list">
                      <div
                        class="file-chip"
                        v-for="(lFile, i) in localFiles"
                        :key="i"
                        :style="{
                          height: `${getFileItemsHeight(lFile)}`,
                        }"
                      >
                        <!-- <div class="fileNameWrapper">
                          {{ lFile.fileName ? lFile.fileName : lFile.name }}
                        </div> -->
                        <v-tooltip
                          top
                          content-class="tooltip-content file-tooltip"
                          fixed
                          max-width="250"
                          open-delay="500"
                          close-delay="300"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <span
                              class="fileNameWrapper"
                              v-bind="attrs"
                              v-on="on"
                            >
                              {{ lFile.fileName ? lFile.fileName : lFile.name }}
                            </span>
                          </template>
                          <span>{{
                            lFile.fileName ? lFile.fileName : lFile.name
                          }}</span>
                        </v-tooltip>
                        <div
                          v-if="lFile.error || lFile.sizeError"
                          class="warning-line-container"
                        >
                          <img
                            :src="'/assets/img/generate/warning-line.png'"
                            alt="slide-pos-icon"
                          />
                        </div>
                        <v-icon
                          :size="18"
                          :color="'#7B7B7B'"
                          @click="removeFile(i)"
                          >mdi-close</v-icon
                        >
                      </div>
                      <template v-if="uploadInprogress">
                        <div
                          class="file-chip"
                          v-for="(lFile, i) in filesPreview"
                          :key="`${i}-preview`"
                          :style="{
                            height: `${getFileItemsHeight(lFile)}`,
                          }"
                        >
                          <div class="fileNameWrapper">
                            {{ lFile.fileName ? lFile.fileName : lFile.name }}
                          </div>
                          <v-progress-circular
                            v-if="!lFile.processingCompleted"
                            indeterminate
                            :color="'#21a7e0'"
                            background-color="#f2f2f2"
                            :width="3"
                            :size="20"
                          ></v-progress-circular>
                        </div>
                      </template>
                    </div>
                    <div class="clear-button-cont">
                      <v-btn
                        text
                        class="clear-button"
                        rounded
                        v-if="localFiles && localFiles.length > 0"
                        color="#20a7e0"
                        height="30"
                        @click="localFiles = []"
                        >{{ $t('common.clear') }}</v-btn
                      >
                    </div>
                  </template>
                </div>
                <div
                  v-if="isFileError(localFiles)"
                  class="file-error error--text"
                  ref="errorDiv"
                >
                  {{ getErrorMessage(localFiles) }}
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </template>
      </v-expansion-panels>
    </div>

    <div class="cta-action">
      <v-btn
        elevation="2"
        rounded
        color="primary"
        class="submit-data-btn"
        @click="onSubmitData"
        :loading="updating"
        :disabled="disableSubmitBtn"
      >
        {{ 'Submit' }}
      </v-btn>
      <div class="time-limit-message">
        We recommend you to submit your reply before 5:30 PM PST
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import {
  uploadOPFileBatchChunk,
  uploadOPFile,
} from '../../../../../../utils/api-helper.js';
import HowToShareAccess from '../../../HowToShareAccess.vue';
import { removeSpecialCharacters } from '../../../../../utils.js';
import {
  TD_LIST,
  TD_MOSAIC,
  TD_AUD,
  TD_PREZNAME,
  TD_PREZTYPE,
  TD_VIEWTYPE,
  TD_PLACEMENT,
  TD_EXPANDED_DETAIL_VIEW,
  TD_EXPANDED_STATUS_VIEW,
  TD_PREZENTATION_CATEGORY,
  TD_INSTRUCTIONS,
  TD_COMMON_COLUMN_NAME,
  TD_COMMON_COLUMN_VALUE,
  NA,
  TD_CHECKED,
  TD_UNCHECKED,
} from '../../../../../common/Analytics/MatomoTrackingDataHelper';
import { trackOvernightPrezentationsEvents } from '../../../../../common/Analytics/OvernightPrezentationsEvents';

export default {
  name: 'AddRequestV2',
  props: {
    origin: {
      type: String,
      default: null,
    },
    updating: {
      type: Boolean,
    },
    opRequest: {
      type: Object,
      default: () => ({}),
    },
    viewType: {
      type: String,
      default: 'list',
    },
  },
  computed: {
    ...mapState('users', ['currentUser']),
    errorFiles() {
      return [...this.localFiles].filter((fl) => fl.sizeError || fl.error);
    },

    disableSubmitBtn() {
      return (
        this.uploadInprogress ||
        (this.currVal.links?.length <= 0 &&
          this.currVal.texts?.text.length < 10 &&
          this.localFiles.length <= 0) ||
        (this.currVal.links.length && !this.currVal.linkAccess) ||
        (this.currVal.links?.length <= 0 &&
          this.currVal.texts?.text.length < 10 &&
          this.localFiles.length > 0 &&
          this.errorFiles.length === this.localFiles.length)
      );
    },
  },
  data() {
    return {
      currVal: {
        texts: { text: '' },
        files: [],
        links: [],
        linkAccess: false,
      },
      link: '',
      uploadInprogress: false,
      localFiles: [],
      filesPreview: [],
      files: [],
      filess: [],
      panels: [
        {
          type: 'texts',
          id: 1,
          description: 'Text',
          icon: '/assets/img/text-box-edit-outline.svg',
        },
        {
          type: 'links',
          id: 2,
          description: 'Web Link',
          icon: '/assets/img/link-variant.svg',
        },
        {
          type: 'files',
          id: 3,
          description: 'Local Files',
          icon: '/assets/img/laptop.svg',
        },
      ],
      linkRules: [
        (v) =>
          this.isValidHttpUrl(v) ||
          v.length <= 0 ||
          'Oops! One or more of the entered links are invalid',
      ],
      sharedAccess: false,
      allowedUploadTypes: [
        '.ppt',
        '.pptx',
        '.xls',
        '.xlsx',
        '.doc',
        '.docx',
        '.txt',
        '.pdf',
        '.jpg',
        '.png',
      ],
      currPanel: 0,
    };
  },
  methods: {
    onSubmitData() {
      this.$emit('onDataSubmit', { ...this.currVal });
      if (this.origin === 'manage_request') {
        trackOvernightPrezentationsEvents.OPAddMessageSend(this.currentUser, {
          [TD_AUD]: this.opRequest?.audienceEmail,
          [TD_PREZNAME]: this.opRequest?.name,
          [TD_PREZTYPE]: this.opRequest?.storyline || NA,
          [TD_VIEWTYPE]: this.viewType === 'list' ? TD_LIST : TD_MOSAIC,
          [TD_PLACEMENT]:
            this.opRequest?.status === 'Completed' &&
            this.opRequest?.opSlideData === null
              ? TD_EXPANDED_DETAIL_VIEW
              : TD_EXPANDED_STATUS_VIEW,
          [TD_PREZENTATION_CATEGORY]: this.opRequest?.status
            ? this.opRequest?.status
            : NA,
        });
      } else {
        trackOvernightPrezentationsEvents.OPMyRequestsChangeRequestSubmit(
          this.currentUser,
          {
            [TD_AUD]: this.opRequest?.audienceEmail,
            [TD_PREZNAME]: this.opRequest?.name,
            [TD_PREZTYPE]: this.opRequest?.storyline || NA,
            [TD_VIEWTYPE]: this.viewType === 'list' ? TD_LIST : TD_MOSAIC,
            [TD_PLACEMENT]:
              this.opRequest?.status === 'Completed' &&
              this.opRequest?.opSlideData === null
                ? TD_EXPANDED_DETAIL_VIEW
                : TD_EXPANDED_STATUS_VIEW,
            [TD_PREZENTATION_CATEGORY]: this.opRequest?.status
              ? this.opRequest?.status
              : NA,
            [TD_COMMON_COLUMN_NAME]:
              'I have shared access with slides@prezentium.com',
            [TD_COMMON_COLUMN_VALUE]: this.currVal.linkAccess
              ? TD_CHECKED
              : TD_UNCHECKED,
            [TD_INSTRUCTIONS]: this.currVal.texts
              ? this.currVal.texts.text
              : NA,
          },
        );
      }
    },
    clearLinks() {
      this.currVal.links = [];
      this.link = '';
      this.$refs.form[0].validate();
    },
    isFileError(files) {
      // return (
      //   [...files].filter((fl) => fl.error === true).length ||
      //   [...files].filter((fl) => fl.sizeError === true).length
      // );
      const hasError = files.some((fl) => fl.error || fl.sizeError);
      if (hasError) {
        this.scrollToError();
      }
      return hasError;
    },
    scrollToError() {
      this.$nextTick(() => {
        const errorElement = this.$refs.errorDiv;
        // If it's an array, select the first or the required element
        const element = Array.isArray(errorElement)
          ? errorElement[0]
          : errorElement;

        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      });
    },
    getErrorMessage(files) {
      const genericErrors = [...files].filter((fl) => fl.error === true).length;
      const sizeErrors = [...files].filter(
        (fl) => fl.sizeError === true,
      ).length;
      let result = '';
      if (genericErrors === 0 && sizeErrors === 0) {
        result = '';
      } else if (genericErrors > 0 && sizeErrors === 0) {
        result = 'Failed to upload file(s).';
      } else if (genericErrors === 0 && sizeErrors > 0) {
        result = 'One or more file size exceeds the maximum limit.';
      } else if (genericErrors > 0 && sizeErrors > 0) {
        result =
          'Oops! One or more of your files are above the file size limit or failed to upload.';
      }
      return result;
    },
    onChange() {
      console.log(this.currVal, 'currVal');
    },
    onPaste(evt) {
      if (this.isValidHttpUrl(evt.clipboardData.getData('text/plain'))) {
        this.currVal.links.push({
          web_id: uuidv4(),
          web_url: evt.clipboardData.getData('text/plain'),
        });
        this.link = '';
        this.currVal.linkAccess = false;
        this.onChange();
      } else {
        this.link = evt.clipboardData.getData('text/plain');
      }
      this.$refs.form[0].validate();
    },
    uploadChunks(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = async () => {
          const chunkSize = 3 * 1024 ** 2;
          const chunks = [];
          let chunkIndex = 0;
          for (
            let start = 0;
            start < reader.result.length;
            start += chunkSize
          ) {
            const chunk = reader.result.slice(start, start + chunkSize);
            chunks.push({
              chunk,
              chunkIndex,
              requestIdentifier: file.requestIdentifier,
              filename: file.name,
            });
            chunkIndex++;
          }
          const chunkBatch = chunks.reduce((resultArray, item, index) => {
            const chunkIdx = Math.floor(index / 5);

            if (!resultArray[chunkIdx]) {
              resultArray[chunkIdx] = []; // start a new chunk
            }

            resultArray[chunkIdx].push(item);

            return resultArray;
          }, []);
          try {
            const response = await uploadOPFileBatchChunk(
              chunkBatch,
              chunkIndex,
              (prog) => {
                console.log(prog);
                file.progress = prog;
              },
              this.source,
            );
            resolve(response);
          } catch (err) {
            reject(err);
          }
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(file.file);
      });
    },
    onEnter() {
      console.log(this.link);
      if (this.isValidHttpUrl(this.link)) {
        this.currVal.links.push({
          web_id: uuidv4(),
          web_url: this.link,
        });
        this.link = '';
        this.currVal.linkAccess = false;
        this.onChange();
        this.$refs.form[0].validate();
      }
    },
    isValidHttpUrl(string) {
      if (!string || string.trim().length === 0) {
        return false;
      }
      const linksArr = string.split('\n').filter((ln) => ln !== '');
      for (let i = 0; i < linksArr.length; i++) {
        let url = linksArr[i];
        try {
          url = new URL(url);
          if (url.protocol === 'http:' || url.protocol === 'https:') {
            console.log(url.protocol);
          } else {
            return false;
          }
        } catch (_) {
          return false;
        }
      }
      return true;
    },

    handleHowtoShareAccess() {
      this.$modal.show(
        HowToShareAccess,
        {},
        {
          name: 'HowToShareAccess',
          classes: 'v-application',
          width: '1300px',
          height: 'auto',
          margin: '0 auto',
          left: '0px',
          styles: {
            borderRadius: '20px',
            padding: '20px',
            'max-height': '800px',
            'min-height': '300px',
            'overflow-y': 'auto',
          },
        },
      );
    },
    // regenerate() {
    //   this.$emit('completed', this.currVal);
    // },

    fileAlreadyUploaded(uploadedFiles, file) {
      return uploadedFiles.find((fl) => fl.fileName === file.name);
    },

    updateLocalFiles(val) {
      const filesToUpload = [...this.localFiles, ...val];
      this.localFiles = filesToUpload;
      this.onChange();
    },

    async previewFiles(event) {
      const newFilesToUpload = [...event.target.files].filter(
        (fle) => !this.fileAlreadyUploaded(this.localFiles, fle),
      );
      if (newFilesToUpload.length > 0) {
        this.filess = [...newFilesToUpload];
        this.manageFileUpload();
      }
      event.target.value = '';
    },

    getFileItemsHeight(file) {
      if (file.fileName) {
        if (file.fileName.length <= 40) {
          return '36px';
        }
        return '36px';
      }
      return file.name.length <= 40 ? '36px' : '36px';
    },

    uploadFileClick() {
      this.$refs.hiddenInput[0].click();
    },
    getOutputPath() {
      const dt = new Date();
      const outputPath = `${this.currentUser.company.id}/op/${
        dt.getFullYear() +
        String(dt.getMonth() + 1).padStart(2, '0') +
        String(dt.getDate()).padStart(2, '0')
      }/${dt.getHours()}${dt.getMinutes()}${dt.getSeconds()}`;
      return outputPath;
    },

    removeFile(i) {
      this.localFiles.splice(i, 1);
      this.onChange();
    },
    removeLink(i) {
      this.currVal.links.splice(i, 1);
      this.onChange();
      this.$refs.form[0].validate();
    },
    async manageFileUpload() {
      this.files = this.filess.map((i) => ({
        file: i,
        name: i.name,
        status: 'Processing',
        progress: 50,
        path: `${this.getOutputPath()}/${removeSpecialCharacters(i.name)}`,
        processingCompleted: false,
        requestIdentifier: uuidv4(),
      }));
      this.filesPreview = [...this.files];
      this.uploadInprogress = true;
      const resp = await Promise.all(
        this.files.map(async (file) => {
          if (file.file.size / 1024 ** 2 > 200) {
            file.status = 'Failed to upload';
            file.progress = 100;
            file.sizeError = true;
            file.processingCompleted = true;
          } else {
            try {
              await this.uploadChunks(file);
              await uploadOPFile(file.requestIdentifier, file.name, this.source)
                .then((res) => {
                  file = {
                    ...file,
                    ...res.data,
                    processingCompleted: true,
                    size: file.file.size,
                  };
                })
                .catch((err) => {
                  file.processingCompleted = true;
                  file.error = true;
                  file.progress = 100;
                  console.log(err);
                });
            } catch (err) {
              file.processingCompleted = true;
              file.error = true;
              file.progress = 100;
              console.log(err);
            }
          }
          return file;
        }),
      );
      if (resp) {
        this.files = resp.map((file) => ({
          fileName: file.file.name,
          type: 's3',
          progress: file.progress,
          s3Bucket: file.s3Bucket,
          s3Prefix: file.s3Prefix,
          sizeError: file.sizeError,
          status: file.status,
          processingCompleted: file.processingCompleted,
          size: file.size,
          error: file.error,
        }));
        this.filesPreview = [];
        this.uploadInprogress = false;
        this.localFiles = [...this.localFiles, ...this.files];
        const filestoSubmit = [...this.localFiles].filter(
          (fl) => !fl.sizeError && !fl.error,
        );
        this.currVal.files = filestoSubmit || [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.multipanel-container {
  height: 100%;
}
.multipanel-wrap {
  width: 100%;
  height: calc(100% - 142px);
  overflow-y: auto;
  overflow-x: hidden;
  ::v-deep .v-expansion-panels {
    justify-content: flex-start;
  }
}
.textbox-label {
  font-family: 'Lato';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.links-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}

.file-chip {
  display: flex;
  flex-direction: row;
  padding: 8px 12px;
  max-width: 395px;
  align-items: center;
  justify-content: space-between;
  // height: 32px;
  gap: 8px;
  position: relative;
  background: #ffffff;
  // box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1),
  //   -1px -1px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #71787e !important;
  border-radius: 8px;
  cursor: default;
  color: #41484d !important;
  height: min-content;
  .warning-line-container {
    height: 24px;
    width: 24px;
  }

  // &:hover {
  //   box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.1),
  //     -1px -1px 8px rgba(0, 0, 0, 0.1);
  // }

  .fileNameWrapper {
    max-width: calc(100% - 24px);
    line-break: anywhere;
  }

  i {
    cursor: pointer;
  }
}

.file-tooltip {
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.multiContentPanels {
  ::v-deep .v-expansion-panel {
    margin: 12px 0px 12px 1px;
    max-width: 95%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 2px;
    border-radius: 8px !important;
    border: none;
    &::before {
      box-shadow: none;
    }
    &::after {
      border-color: #ffffff !important;
    }
    .v-expansion-panel-header {
      padding: 10px;
      img {
        width: 40px;
        height: 40px;
        flex: none;
      }
      .panelHeader {
        margin: 0px 0px 0px 6px;
        font-size: 16px;
        font-weight: 600;
        color: #075689;
      }
    }
    .v-expansion-panel-content {
      .v-expansion-panel-content__wrap {
        padding: 0px 12px 12px 12px !important;

        .clear-button {
          position: absolute;
          bottom: 5px;
          left: 0;
          margin-left: 8px;
          padding: 5px;
          font-family: 'Lato';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.24px;
          text-transform: none;
        }

        .v-input.v-textarea {
          .v-input__control {
            .v-input__slot {
              textarea::placeholder {
                color: #757575;
              }
              position: relative;
              padding-bottom: 40px;
              box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.05),
                -2px -2px 4px 0px rgba(0, 0, 0, 0.05);
            }
          }
        }
        .textContent {
          .textHeading {
            font-size: 16px;
            margin: 0px 0px 16px 0px;
            // line-height: 20px;

            font-family: Lato;
            font-size: 16px;
            font-weight: 600;
            line-height: 19.2px;
            text-align: left;
          }
          .textLabel {
            margin: 0px 0px 8px 0px;
            font-weight: 600;
            font-size: 16px;
          }
        }
        .linkContent {
          .linkHeading {
            margin: 0px 0px 16px 0px;
            font-family: Lato;
            font-size: 16px;
            font-weight: 400;
            line-height: 20.8px;
            text-align: left;

            .link-sub-heading {
              margin-top: 20px;
              font-family: Lato;
              font-size: 16px;
              font-weight: 600;
              line-height: 19.2px;
              text-align: left;
              margin-bottom: 0;
            }
          }
          .linkLabel {
            margin: 0px 0px 8px 0px;
            font-weight: 600;
            font-size: 16px;
          }
          .v-messages__message {
            line-height: 14px;
            word-break: normal;
            overflow-wrap: normal;
            word-wrap: normal;
            hyphens: none;
          }
          .share-links {
            .v-input__control {
              .v-input__slot {
                textarea::placeholder {
                  color: #757575;
                }
                display: flex;
                flex-direction: column;
                padding-bottom: 40px;
                box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.05),
                  -2px -2px 4px 0px rgba(0, 0, 0, 0.05);
              }
            }
          }
          .check-access {
            .guide-link {
              font-family: 'Lato';
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 19px;
              color: #21a7e0;
              margin-left: 30px;
              margin-top: 4px;
              display: inline-block;
              cursor: pointer;
            }

            .v-input {
              margin-top: 0;
              padding-top: 0;
              .v-input__control {
                .v-input__slot {
                  align-items: flex-start;
                  .v-input--selection-controls__input {
                    margin-right: 6px;
                    .v-icon {
                      svg {
                        width: 20px;
                        height: 20px;
                      }
                    }
                  }
                  .v-label {
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 130%;
                    color: #000000;
                  }
                }
              }
            }
          }
          .check-access-error {
            .guide-link {
              font-family: 'Lato';
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 19px;
              color: #21a7e0;
              margin-left: 30px;
              margin-top: 4px;
              display: inline-block;
              cursor: pointer;
            }

            .v-input {
              margin-top: 0;
              padding-top: 0;
              .v-input__control {
                .v-input__slot {
                  align-items: flex-start;
                  .v-input--selection-controls__input {
                    margin-right: 6px;
                    .v-icon {
                      svg {
                        width: 20px;
                        height: 20px;
                        color: red;
                      }
                    }
                  }
                  .v-label {
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 130%;
                    color: #000000;
                  }
                }
              }
            }
          }
        }
        .fileContent {
          .fileHeading {
            font-size: 16px;
            margin: 0px 0px 16px 0px;
            line-height: 20px;
          }
          .file-error {
            font-size: 12px;
            line-height: 14px;
            margin-top: 8px;
            padding: 0 12px;
          }
          .sizeDesc {
            font-size: 16px;
            margin: 0px 0px 16px 0px;
            line-height: 20px;
          }
          .file-upload-action {
            margin: 24px 0px 20px 0px;
            input[type='file'] {
              display: none;
            }

            .custom-file-upload {
              span {
                padding: 6px 16px;
                height: 31px;
                border: 1.5px solid #21a7e0;
                border-radius: 25px;
                font-size: 16px;
                color: #21a7e0;
                cursor: pointer;
                letter-spacing: normal;
                .v-icon {
                  color: #21a7e0;
                }
                &.disable-upload {
                  background-color: rgba(0, 0, 0, 0.12) !important;
                  border: 1.5px solid rgba(118, 118, 118, 0.3);
                  color: rgba(0, 0, 0, 0.26);
                  pointer-events: none;
                  .v-icon {
                    color: rgba(0, 0, 0, 0.26);
                  }
                }
              }
            }
          }
          .upload-limit {
            font-family: Lato;
            font-size: 16px;
            font-weight: 600;
            line-height: 19.2px;
            text-align: left;
            margin-bottom: 20px;
          }
          .file-chips-wrapper {
            .file-placeholder {
              font-size: 16px;
              color: #757575;
            }
            position: relative;
            padding: 12px;
            padding-bottom: 40px;
            height: 190px;
            border-radius: 6px;
            background: white;
            // filter: drop-shadow(2px 2px 8px rgba(0, 0, 0, 0.05))
            //   drop-shadow(-2px -2px 4px rgba(0, 0, 0, 0.05));
            box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.05),
              -2px -2px 4px 0px rgba(0, 0, 0, 0.05);
            display: flex;
            .chips-list {
              height: 100%;
              display: flex;
              flex-direction: column;
              gap: 8px;
              overflow: auto;
              width: 100%;
              .file-chip {
                width: 98%;
                .fileNameWrapper {
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
            }
          }
        }
      }
    }
  }
}
.cta-action {
  position: fixed;
  width: 25%;
  padding-top: 10px;
  background-color: white;
  .submit-data-btn {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: normal;
    text-transform: none !important;
    margin-right: 20px;
  }
  .time-limit-message {
    font-family: Lato;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.4px;
    // letter-spacing: 0
    text-align: left;
    color: #343a3f;
    margin-top: 10px;
  }
}
</style>
