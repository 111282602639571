<template>
  <div class="import-prezentation">
    <div v-if="waitingIndex === 0">
      <h1 class="title">{{ $t('upload.impostPrezentations') }}</h1>
      <p class="pt-2 mb-0 virbage">
        {{ $t('upload.vibrage') }}
      </p>

      <p class="virbage">{{ $t('upload.maximunFileSize') }}</p>

      <v-form ref="form">
        <div class="center" v-if="file">
          <div class="reset-file" data-pendo-id="import-selector">
            <div>
              {{
                file.name.length &lt; 49
                  ? file.name
                  : file.name.substring(0, 47) + '...'
              }}
            </div>
            <v-icon small @click="resetUploadFile">mdi-close</v-icon>
          </div>
        </div>
        <div class="file-select center" v-else>
          <v-btn
            class="ma-2 btn"
            outlined
            rounded
            width="101"
            height="32"
            color="primary"
            @click="uploadFile"
            :loading="fileLoading"
            data-pendo-id="import-selector"
          >
            {{ $t('overnightPresentations.selectFile') }}
          </v-btn>
          <input
            ref="uploader"
            class="d-none"
            type="file"
            accept=".pptx"
            @change="onFileChanged"
          />

          <div class="error-text">{{ fileError }}</div>
        </div>
        <v-row>
          <v-col cols="12" class="" data-pendo-id="presentation-name">
            <label class="field-label">{{
              $t('build.step1.prezentationNameFieldLabel')
            }}</label>
            <v-text-field
              v-model="prezentationName"
              :placeholder="$t('build.step1.prezentationNameFieldPlaceholder')"
              :rules="prezentationNameRules"
              required
            />
          </v-col>
          <v-col cols="12" class="" data-pendo-id="presentation-audience">
            <label class="field-label">
              {{ $t('build.step1.audianceFieldLabel') }}
              <v-tooltip
                top
                max-width="250"
                content-class="tooltip-content"
                fixed
                open-delay="500"
                close-delay="300"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    class="icon"
                    v-text="`mdi-information-outline`"
                  />
                </template>
                <span>
                  {{ $t('build.step1.audianceLabelTooltip') }}
                </span>
              </v-tooltip>
            </label>
            <AudienceSelectorTypesense
              v-model="selectedAudience"
              :isTextFieldStyle="true"
              class="no-padding"
              origin="Build"
              :placeholder="$t('build.step1.audianceFieldPlaceholder')"
              @change="handleAudienceSelect($event)"
              @add="handleAddAudience($event)"
            />
          </v-col>
          <v-col
            cols="12"
            class="storyline-col"
            data-pendo-id="presentation-storyline"
          >
            <!-- <label class="field-label">The type of storyline is</label>
            <v-autocomplete
              v-model="prezentationType"
              :placeholder="$t('overnightPresentations.storyLineName')"
              :items="presentTypeList"
              item-text="type"
              return-object
              :rules="dropdownRules"
              required
              :disabled="!selectedAudience"
              :search-input.sync="search"
              :menu-props="{ closeOnClick: true, closeOnContentClick: true }"
              @keyup="debounceInputChange"
            >
              <template v-slot:no-data>
                <v-list-item dense class="no-results">
                  <v-list-item-content>
                    <v-list-item-title class="presentation-type-content--auto">
                      {{ $t('build.step1.storylineFieldNoResult') }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-slot:item="{ item, attrs, on }">
                <v-list-item
                  v-on="on"
                  v-bind="attrs"
                  two-line
                  style="padding-left: 50px"
                >
                  <v-list-item-content
                    v-if="!(item.type === 'Other' && item.isPrezentGenerated)"
                    id="presentation-type-content--auto"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <div class="option-wrapper" v-bind="attrs" v-on="on">
                          <div class="prez-heading__wrapper">
                            <v-list-item-title
                              class="prez-heading"
                              v-if="!item.isPrezentGenerated && item.isOwner"
                            >
                              {{ item.type }} |
                              {{ $t('build.step1.createdBy') }}
                              {{
                                !item.isOwner
                                  ? item.fullName
                                  : $t('build.step1.me')
                              }}
                              |
                              {{
                                item.shared
                                  ? $t('build.step1.shared')
                                  : $t('build.step1.private')
                              }}
                              <NewFeatureBadge
                                module-name="build"
                                :feature-id="'build_storyline_dropdown_id'"
                                :offsetX="'-4'"
                                :offsetY="'4'"
                                :size="'medium'"
                                v-if="item.isNew"
                                :content="'New Content!'"
                              >
                              </NewFeatureBadge>
                            </v-list-item-title>
                            <v-list-item-title
                              class="prez-heading"
                              v-else-if="
                                !item.isPrezentGenerated && !item.isOwner
                              "
                            >
                              {{ item.type }} |
                              {{ $t('build.step1.createdBy') }}
                              {{
                                !item.isOwner
                                  ? item.fullName
                                  : $t('build.step1.me')
                              }}
                              <NewFeatureBadge
                                module-name="build"
                                :feature-id="'build_storyline_dropdown_id'"
                                :offsetX="'-4'"
                                :offsetY="'4'"
                                :size="'medium'"
                                :content="'New Content!'"
                                v-if="item.isNew"
                              >
                              </NewFeatureBadge>
                            </v-list-item-title>
                            <v-list-item-title class="prez-heading" v-else>
                              {{ item.type }}
                              <img
                                src="/assets/img/prezent_avatar.svg"
                                alt
                                width="22px"
                              />
                              <NewFeatureBadge
                                module-name="build"
                                :feature-id="'build_storyline_dropdown_id'"
                                :offsetX="'-4'"
                                :offsetY="'4'"
                                :size="'medium'"
                                :content="'New Content!'"
                                v-if="item.isNew"
                              >
                              </NewFeatureBadge>
                            </v-list-item-title>
                            <v-list-item-subtitle class="prez-description">
                              {{ item.description }}
                            </v-list-item-subtitle>
                          </div>
                        </div>
                      </template>
                      <div
                        :class="`option-tooltip-wrapper${
                          item.description.length > 50 ? '__small' : ''
                        }`"
                      >
                        <div>
                          <v-list-item-title
                            class="prez-heading"
                            v-if="!item.isPrezentGenerated && item.isOwner"
                          >
                            {{ item.type }} |
                            {{ $t('build.step1.createdBy') }}
                            {{
                              !item.isOwner
                                ? item.fullName
                                : $t('build.step1.me')
                            }}
                            |
                            {{
                              item.shared
                                ? $t('build.step1.shared')
                                : $t('build.step1.private')
                            }}
                            <NewFeatureBadge
                              module-name="build"
                              :feature-id="'build_storyline_dropdown_id'"
                              :offsetX="'-4'"
                              :offsetY="'4'"
                              :size="'medium'"
                              :content="'New Content!'"
                              v-if="item.isNew"
                            >
                            </NewFeatureBadge>
                          </v-list-item-title>
                          <v-list-item-title
                            class="prez-heading"
                            v-else-if="
                              !item.isPrezentGenerated && !item.isOwner
                            "
                          >
                            {{ item.type }} |
                            {{ $t('build.step1.createdBy') }}
                            {{
                              !item.isOwner
                                ? item.fullName
                                : $t('build.step1.me')
                            }}
                            <NewFeatureBadge
                              module-name="build"
                              :feature-id="'build_storyline_dropdown_id'"
                              :offsetX="'-4'"
                              :offsetY="'4'"
                              :size="'medium'"
                              :content="'New Content!'"
                              v-if="item.isNew"
                            >
                            </NewFeatureBadge>
                          </v-list-item-title>
                          <v-list-item-title class="prez-heading" v-else>
                            {{ item.type }}
                            <img
                              src="/assets/img/prezent_avatar.svg"
                              alt
                              width="22px"
                            />
                            <NewFeatureBadge
                              module-name="build"
                              :feature-id="'build_storyline_dropdown_id'"
                              :offsetX="'-4'"
                              :offsetY="'4'"
                              :size="'medium'"
                              :content="'New Content!'"
                              v-if="item.isNew"
                            >
                            </NewFeatureBadge>
                          </v-list-item-title>
                          <v-list-item-subtitle class="prez-description">
                            {{ item.description }}
                          </v-list-item-subtitle>
                        </div>
                      </div>
                    </v-tooltip>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template #append-item>
                <v-list-item
                  class="prezentaion-list-item--add add-list-item"
                  @click="handleCustomSelectClick"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <div class="option-wrapper">
                        <div>
                          <v-list-item-title class="prez-heading">
                            {{ $t('common.other') }}
                          </v-list-item-title>
                        </div>
                      </div>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-autocomplete> -->
            <StorylineDropdown
              ref="storylineSelector"
              v-model="prezentationType"
              :isTextFieldStyle="true"
              class="no-padding"
              origin="Upload"
              :audienceStoryPref="audienceStoryPref"
              @change="handleStorylineSelect($event)"
              :label="$t('build.step1.storylineFieldLabel')"
              :isCustom="false"
              :placeholder="$t('build.step1.storylineFieldPlaceholder')"
              :handleCustomSelectClick="handleCustomSelectClick"
              :handleManageStorylines="handleManageStorylines"
              :disabled="!selectedAudience"
            />
          </v-col>
          <template
            v-if="prezentationType && prezentationType.type === 'Other'"
          >
            <v-col cols="12" class="">
              <label class="field-label">{{
                $t('overnightPresentations.storyLineName')
              }}</label>
              <v-text-field
                v-model="customPrezentationType"
                :placeholder="
                  $t('overnightPresentations.storyLineNamePlaceholder')
                "
                :rules="nameRules"
                required
              />
            </v-col>
          </template>

          <v-col cols="12" class="" data-pendo-id="presentation-template">
            <label class="field-label">{{
              $t('overnightPresentations.selectCompanyTemplate')
            }}</label>

            <v-autocomplete
              :value="templateSelected"
              :items="templatesList"
              return-object
              :placeholder="$t('upload.selectTemplate')"
              item-text="name"
              class="templates-dropdown"
              :search-input.sync="templateSearch"
              :attach="true"
              :menu-props="{
                maxHeight: '400',
                padding: '0px',
                rounded: 'lg',
              }"
              :rules="dropdownRules"
              @focus="trackTemplateMenuClickEvent"
              @change="handleClickTheme"
              @blur="handleTemplateBlur"
            >
              <template #append>
                <v-icon class="icon search-icon" v-text="`mdi-magnify`" />
              </template>
              <template v-slot:item="{ item }">
                <div class="template-list-item">
                  <div class="template-header">
                    <v-tooltip
                      top
                      max-width="200"
                      content-class="tooltip-content"
                      fixed
                    >
                      <template v-slot:activator="{ on }">
                        <div class="template-title" v-on="on">
                          {{ item.name }}
                        </div>
                      </template>
                      <span>{{ item.name }}</span>
                    </v-tooltip>
                    <div
                      v-if="isCurrentSelected(item.code)"
                      class="current-selection"
                    >
                      <v-tooltip
                        top
                        max-width="200"
                        content-class="tooltip-content"
                        fixed
                      >
                        <template v-slot:activator="{ on }">
                          <div v-on="on">
                            {{ $t('tagSlides.currentSelection') }}
                          </div>
                        </template>
                        <span>{{ $t('tagSlides.currentSelection') }}</span>
                      </v-tooltip>
                    </div>
                  </div>
                  <v-row class="mb-2">
                    <v-col class="available-theme-img">
                      <img :src="item.thumbnailURL" alt />
                    </v-col>
                    <v-col class="example-slide-img">
                      <img
                        :src="
                          item.sampleSlide
                            ? item.sampleSlideURL
                            : item.thumbnailURL
                        "
                        alt
                      />
                    </v-col>
                  </v-row>
                </div>
              </template>
              <template v-slot:no-data>
                <div class="no-templates-fount">
                  <p class="no-result-text">
                    {{ $t('build.step1.storylineFieldNoResult') }}
                  </p>
                  <div class="add-more-templates-btn" @click="handleAddMore">
                    <v-icon
                      color="#20a7e0"
                      class="add-icon"
                      v-text="`mdi-plus`"
                    />
                    <p>{{ $t('tagSlides.addMoreTemplatesText') }}</p>
                  </div>
                </div>
              </template>
              <template v-slot:append-item>
                <div class="tip-bar">
                  <p>
                    {{ $t('tagSlides.templateProTipText') }}
                  </p>
                </div>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-form>

      <div class="center">
        <v-btn
          class="ma-2 btn"
          rounded
          color="primary"
          width="67"
          @click="handlePptUpload"
          :disabled="fileLoading || isAllFieldsFilledAndValid"
          data-pendo-id="presentation-next"
        >
          {{ $t('common.next') }}
        </v-btn>
      </div>
    </div>
    <div v-if="waitingIndex === 1" class="waiting-block">
      <clip-loader
        :loading="waitingIndex === 1"
        :color="`#21a7e0`"
        :width="20"
        :height="20"
      />
      <br />
      <h2>{{ $t('upload.importingPrezentation') }}</h2>
      <div class="image">
        <img src="/assets/img/uploader/saving.svg" alt width="350px" />
      </div>
    </div>
    <div v-if="waitingIndex === 2" class="waiting-block">
      <clip-loader
        :loading="waitingIndex === 2"
        :color="`#21a7e0`"
        :width="20"
        :height="20"
      />
      <br />
      <h2>{{ $t('upload.analyzingSlides') }}</h2>
      <div class="image">
        <img src="/assets/img/uploader/directing.svg" alt width="350px" />
      </div>
    </div>
    <div v-if="waitingIndex === 3" class="waiting-block">
      <!-- <clip-loader
        :loading="waitingIndex === 3"
        :color="`#21a7e0`"
        :width="20"
        :height="20"
      /> -->
      <br />
      <h2>{{ $t('upload.intelligentTags') }}</h2>
      <div class="image">
        <img src="/assets/img/uploader/directing-1.svg" alt width="350px" />
      </div>
    </div>
  </div>
</template>
<script>
import { v4 as uuidv4 } from 'uuid';
import { mapActions, mapState } from 'vuex';
import _ from 'lodash';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
// import { uploadPrezentation } from '../../../API/prezentation-upload-api';
import {
  getStorylines,
  uploadOPFile,
  uploadOPFileBatchChunk,
  uploadPPTXStartRequest,
  getPipeineStatus,
  addUploadLog,
} from '../../../utils/api-helper';
// import NewFeatureBadge from '../../common/NewFeatureBadge.vue';
import {
  DEDUCTIVE_PRINTS,
  INDUCTIVE_PRINTS,
} from '../NewPrezentation/getPrezentationOutline';
import AddCollegueDetail from '../NewPrezentation/AddCollegueDetail.vue';
import {
  BUILD,
  NA,
  SLIDES,
  TD_AUD,
  TD_AUDIENCE_ORIGIN_NP,
  TD_PREZNAME,
  TD_PREZTYPE,
  TD_QUERY,
  getAudienceCompanyName,
  TD_NUMBEROFSLIDES,
  TD_FILE_SIZE,
} from '../../common/Analytics/MatomoTrackingDataHelper';
import { AddNewAudienceModalProps } from '../AddNewAudience/AddNewAudienceModalProps';
import ReminderDialog from '../MyPrezentation/ReminderDialog.vue';
import { trackBuildEvents } from '../../common/Analytics/BuildEvents';
import { trackSlideEvents } from '../../common/Analytics/SlideEvents';
import AddingMoreTemplateDetail from '../Profile/AddingMoreTemplateDetail.vue';
import { handleDebounce } from '../SlideImageReplacement/slideImageReplacementUtils';
import AudienceSelectorTypesense from '../../common/AudienceSelectorTypesense.vue';
import StorylineDropdown from '../../common/StorylineDropdown.vue';
import { trackUploadEvents } from '../../common/Analytics/UploadEvents';

export default {
  name: 'ImportPrezentation',
  callbackId: '',
  pipelineStatus: null,
  components: {
    ClipLoader,
    AudienceSelectorTypesense,
    StorylineDropdown,
  },
  data() {
    return {
      componentEnterTime: 0,
      componentExitTime: 0,
      timeSpent: 0,
      audienceStoryPref: 'all',
      isLoading: true,
      isUploading: false,
      file: null,
      prezentationName: '',
      prezentationType: null,
      customPrezentationType: '',
      pptData: null,
      presentTypeList: [],
      templateSelected: null,
      templatesList: [],
      selectedAudience: null,
      audienceList: [],
      waitingIndex: 0,
      fileError: '',
      fileLoading: false,
      fileData: {},
      prezentationNameRules: [
        (v) => !!v || this.$t('warnings.enterRequiredField'),
        (v) =>
          v?.length <= 90 ||
          this.$t('warnings.inputMustBeLessThan90Characters'),
      ],
      nameRules: [
        (v) => !!v || this.$t('warnings.enterRequiredField'),
        (v) => v?.length <= 50 || this.$t('warnings.inputMustBeLessThan'),
      ],
      dropdownRules: [(v) => !!v || this.$t('warnings.selectRequiredField')],
      search: '',
      templateSearch: '',
      presentTypeListAll: [],
    };
  },
  props: {
    nextStep: {
      type: Function,
      default: () => ({}),
    },
    prezentationData: {
      type: Object,
      default: () => ({}),
    },
    onPrezentationUpload: {
      type: Function,
      default: () => ({}),
    },
    toggleIsFileUploaded: {
      type: Function,
      default: () => ({}),
    },
  },
  computed: {
    ...mapState('users', [
      'currentUser',
      'typesenseRealtimeAudiences',
      'themes',
      'currentTheme',
      'startLoadingSlidesFlag',
    ]),

    isAllFieldsFilledAndValid() {
      return (
        !this.file ||
        !this.prezentationName ||
        this.prezentationName.length > 90 ||
        !this.prezentationType ||
        !this.templateSelected ||
        !this.selectedAudience ||
        (this.prezentationType?.type === 'Other' &&
          !this.customPrezentationType)
      );
    },
  },
  mounted() {
    this.componentEnterTime = Date.now();
    if (this.themes.length) {
      this.setFilteredThemes(this.themes);
    }
  },
  async beforeMount() {
    if (this.prezentationData?.fileData) {
      this.fileLoading = true;
    }
    await this.getStoryLinesForBuild();
    if (this.currentTheme) {
      this.templateSelected = this.currentTheme;
      this.templatesList = this.sortedThemeArray();
    }
    if (this.prezentationData?.fileData) {
      this.pipelineStatus = this.prezentationData?.pipelineStatus;
      this.fileData = this.prezentationData?.fileData;
      this.file = this.prezentationData?.file;
      this.toggleIsFileUploaded(true);
      this.prezentationName = this.prezentationData.prezentationName;
      this.selectedAudience = this.prezentationData.selectedAudience;
      this.templateSelected = this.prezentationData.templateSelected;
      this.prezentationType = this.prezentationData.prezentationType;
      this.customPrezentationType =
        this.prezentationData.customPrezentationType;
      this.handleAudienceSelect(this.selectedAudience);
    }

    if (!this.selectedAudience) {
      this.setAudianceFunc();
    }
    this.fileLoading = false;
  },
  watch: {
    async currentTheme(newVal, oldVal) {
      if (newVal.code === oldVal?.code) {
        return;
      }
      this.templateSelected = newVal;
      this.templatesList = this.sortedThemeArray();
    },
    templateSearch(val) {
      if (val && this.templateSelected?.name !== val) this.onSearch(val);
    },
    startLoadingSlidesFlag() {
      if (this.startLoadingSlidesFlag && !this.selectedAudience) {
        this.setAudianceFunc();
      }
    },
  },
  methods: {
    ...mapActions('users', ['setFilteredThemes']),
    isCurrentSelected(id) {
      return this.templateSelected?.code === id;
    },
    sortedThemeArray() {
      let finalArray = [];
      const currentTheme = this.themes.find(
        (x) => x.code === this.currentTheme.code,
      );
      finalArray = this.themes.filter((x) => x.code !== this.currentTheme.code);

      finalArray.sort((a, b) => {
        if (a.name > b.name) return 1;
        if (b.name > a.name) return -1;
        return 0;
      });
      finalArray.unshift(currentTheme);
      return finalArray;
    },
    handleTemplateBlur() {
      if (!this.templateSelected) {
        this.templateSelected = this.currentTheme;
      }
    },
    async getStoryLinesForBuild() {
      this.presentTypeListAll = [];
      await Promise.all([
        getStorylines({ addInternal: true, storyType: 'inductive' }),
        getStorylines({ addInternal: true, storyType: 'deductive' }),
      ])
        .then((res) => {
          res.forEach((response, i) => {
            response.data?.items.forEach((storyline) => {
              if (
                storyline.type === 'Custom' &&
                !storyline.isPrezentGenerated
              ) {
                //
              } else {
                this.presentTypeListAll.push({
                  ...storyline,
                  type: storyline.type === 'Custom' ? 'Other' : storyline.type,
                  storyType: i === 1 ? 'deductive' : 'inductive',
                });
              }
            });
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleAddAudience(value) {
      if (value && !value.fingerPrint) {
        const additionalTrackingInfo = {};

        this.openRemindModal(value, additionalTrackingInfo);
      } else {
        this.openAddCollegue();
      }
    },
    handleAudienceSelect(val) {
      console.log(val.audienceType, val.num_id, val);

      if (
        DEDUCTIVE_PRINTS.includes(
          this.selectedAudience.fingerPrint.toLowerCase(),
        )
      ) {
        this.audienceStoryPref = 'deductive';
      } else if (
        INDUCTIVE_PRINTS.includes(
          this.selectedAudience.fingerPrint.toLowerCase(),
        )
      ) {
        this.audienceStoryPref = 'inductive';
      }
      // this.presentTypeList = this.presentTypeListAll.filter(
      //   (item) => item.storyType === storyType,
      // );

      // if (this.prezentationType) {
      //   this.prezentationType = this.presentTypeList.find(
      //     (i) => this.prezentationType.type === i.type,
      //   );
      // }
      return true;
    },

    openAddCollegue() {
      this.$modal.show(
        AddCollegueDetail,
        {
          origin: TD_AUDIENCE_ORIGIN_NP,
        },
        AddNewAudienceModalProps,
      );
    },
    openRemindModal(currentAudience, trackingInfo) {
      this.$forceUpdate();
      this.$modal.show(
        ReminderDialog,
        {
          currentAudience,
          isUser: currentAudience.type === 'user',
          origin: BUILD,
          metaDataForTracking: trackingInfo,
        },
        {
          name: 'ReminderDialog',
          width: '649px',
          height: '80%',
        },
      );
    },
    // eslint-disable-next-line func-names
    debounceInputChange: _.debounce(function (e) {
      const searchText = e?.target?.value;
      if (searchText.length) this.inputChange(searchText);
    }, 300),
    inputChange(text) {
      trackBuildEvents.buildStorylineSearch(this.currentUser, {
        [TD_AUD]: this.selectedAudience?.id,
        [TD_QUERY]: text,
      });
    },
    handleStorylineSelect(storyline) {
      if (storyline && storyline.type !== 'Custom') {
        this.prezentationType = storyline;
      }
    },

    handleManageStorylines() {
      this.$router.push(`/profile?tab=storylines`);
      trackBuildEvents.buildManagestorylines(
        this.currentUser,
        this.getCommonMatomoOtherData(),
      );
    },
    async handleCustomSelectClick() {
      this.search = 'Other';
      trackBuildEvents.buildCustomstoryline(
        this.currentUser,
        this.getCommonMatomoOtherData(),
      );
    },
    getCommonMatomoOtherData() {
      return {
        [TD_AUD]: this.selectedAudience?.id,
        [TD_PREZNAME]: this.presentationName || NA,
        [TD_PREZTYPE]: this.prezentationType?.text || NA,
      };
    },

    onSearch(searchTerm) {
      handleDebounce(() => this.trackTemplateSearchEvent(searchTerm.trim()));
    },
    trackTemplateSearchEvent(searchTerm) {
      trackSlideEvents.slidesTemplateInfoSearch(this.currentUser, {
        [TD_AUD]: this.getAudienceDataToTrack()?.email,
        [TD_QUERY]: searchTerm,
      });
    },
    handleAddMore() {
      this.$modal.show(
        AddingMoreTemplateDetail,
        {
          origin: SLIDES,
          tailorID: this.getAudienceDataToTrack()?.email,
          audienceCompany: getAudienceCompanyName(
            this.selectedAudience,
            this.currentUser,
          ),
          templateRequestFrom: 'Slides',
        },
        {
          name: 'AddingMoreTemplateDetail',
          width: '564px',
          height: '507px',
          styles: { borderRadius: '12px' },
        },
      );
    },
    handleClickTheme(theme) {
      // move the selected element at the first position
      this.templatesList = this.templatesList.filter(
        (item) => item.id !== theme?.id,
      );
      if (theme) this.templatesList.unshift(theme);

      this.templateSelected = theme;
    },

    trackTemplateMenuClickEvent() {
      trackSlideEvents.slidesTemplateInfoClick(this.currentUser, {
        [TD_AUD]: this.getAudienceDataToTrack()?.email,
      });
    },

    getAudienceDataToTrack() {
      try {
        return {
          email: this.currentUser?.id || this.currentUser?.user?.id,
        };
      } catch (e) {
        return {
          email: '',
        };
      }
    },
    uploadFile() {
      this.$refs.uploader.click();
    },
    resetData() {
      this.file = null;
      this.prezentationName = '';
      this.toggleIsFileUploaded(false);
      this.prezentationType = null;
      this.customPrezentationType = '';
      this.setAudianceFunc();
      this.templateSelected = this.currentTheme;
      this.onPrezentationUpload({});
      this.callbackId = '';
      this.pipelineStatus = null;
    },

    setAudianceFunc() {
      this.selectedAudience = { defaultAudience: 'SELF' };
    },
    async onFileChanged(event) {
      this.fileError = '';
      this.prezentationName = '';
      const file = event.target.files[0];
      file.requestIdentifier = uuidv4();
      const [name, extension] = file.name.split('.');
      if (extension !== 'pptx') {
        this.fileError = 'Select .pptx files only';
        return;
      }
      const maxAllowedSize = 200 * 1024 * 1024;
      console.log(file.size);
      if (file.size > maxAllowedSize) {
        const otherDatas = {
          [TD_NUMBEROFSLIDES]: file?.noOfSlides?.length,
          [TD_PREZNAME]: file?.name,
          [TD_FILE_SIZE]: `${file.size ? file.size / 1024 / 1024 : 0} MB`,
        };
        trackUploadEvents.uploadFailed(this.currentUser, otherDatas);
        this.fileError =
          'Oops! The file that you tried to upload is above the size limit.';
      } else {
        this.prezentationName = name;
        this.toggleIsFileUploaded(true);
        this.fileLoading = true;
        await this.uploadChunks(file);
        await uploadOPFile(file.requestIdentifier, file.name, 'sanitize')
          .then((res) => {
            this.fileData = {
              file,
              ...res.data,
              processingCompleted: true,
              prezentationName: name,
            };
            // start uploadPPTXPipeline
            const uuid = uuidv4();
            const body = {
              callback_id: uuid,
              report_id: uuid,
              workflow_name: 'pptx-upload',
              workflow_context: {
                s3_path: res.data.s3Prefix,
                s3_bucket: res.data.s3Bucket,
              },
            };
            uploadPPTXStartRequest(body).then(() => {
              this.callbackId = uuid;
            });
            this.file = file;
            const formData = new FormData();
            formData.append('file', file);
            this.pptData = formData;
          })
          .catch((err) => {
            let numOfSlide = 0;
            if (err?.response?.data?.data) {
              numOfSlide = err.response.data.data.numOfPages;
            }
            const otherDatas = {
              [TD_NUMBEROFSLIDES]: numOfSlide,
              [TD_PREZNAME]: file?.name,
              [TD_FILE_SIZE]: `${file.size ? file.size / 1024 / 1024 : 0} MB`,
            };
            trackUploadEvents.uploadFailed(this.currentUser, otherDatas);
            this.fileError = 'Please check the file uploaded.';
            this.fileData.processingCompleted = true;
            this.fileData.error = true;
            this.fileData.progress = 'failed';
            console.log(err.response.data.data);
          });

        this.fileLoading = false;
      }
    },
    uploadChunks(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onloadend = async () => {
          const chunkSize = 3 * 1024 ** 2;
          const chunks = [];
          let chunkIndex = 0;
          for (
            let start = 0;
            start < reader.result.length;
            start += chunkSize
          ) {
            const chunk = reader.result.slice(start, start + chunkSize);
            chunks.push({
              chunk,
              chunkIndex,
              requestIdentifier: file.requestIdentifier,
              filename: file.name,
            });
            chunkIndex++;
          }
          const chunkBatch = chunks.reduce((resultArray, item, index) => {
            const chunkIdx = Math.floor(index / 5);

            if (!resultArray[chunkIdx]) {
              resultArray[chunkIdx] = []; // start a new chunk
            }

            resultArray[chunkIdx].push(item);

            return resultArray;
          }, []);
          try {
            const response = await uploadOPFileBatchChunk(
              chunkBatch,
              chunkIndex,
              (prog) => {
                file.progress = prog;
              },
              'sanitize',
            );
            resolve(response);
          } catch (err) {
            reject(err);
          }
        };
        reader.onerror = (error) => {
          reject(error);
        };

        reader.readAsDataURL(file);
      });
    },
    resetUploadFile() {
      this.file = null;
      this.prezentationName = '';
      this.toggleIsFileUploaded(false);
      this.resetData();
    },
    sleep(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    },
    async handlePptUpload() {
      const isValid = this.$refs?.form?.validate();
      if (!this.file) {
        this.fileError = 'Select prezentation file';
        return;
      }
      if (isValid) {
        this.isUploading = true;
        this.waitingIndex = 1;
        while (
          !this.pipelineStatus ||
          this.pipelineStatus.outputs['U-Thumbnails']?.status !== 'success'
        ) {
          await this.sleep(3000);
          const resp = await getPipeineStatus(this.callbackId);
          this.pipelineStatus = resp;
        }

        this.pipelineStatus.outputs['U-Thumbnails'].number_of_slides =
          this.pipelineStatus?.outputs['U-Thumbnails']?.data.length;
        this.onPrezentationUpload({
          ...this.pipelineStatus?.outputs['U-Thumbnails'],
          prezentationName: this.prezentationName,
          file: this.file,
          fileData: {
            ...this.fileData,
          },
          pipelineCallbackId: this.callbackId,
          pipelineStatus: this.pipelineStatus,
          prezentationType: this.prezentationType,
          customPrezentationType: this.customPrezentationType,
          templateSelected: this.templateSelected,
          selectedAudience: this.selectedAudience,

          templatesList: this.templatesList,
        });
        this.isUploading = false;
        this.waitingIndex = 0;
        this.componentExitTime = Date.now();
        this.timeSpent =
          (this.componentExitTime - this.componentEnterTime) / 1000;
        const addLog = {
          fileName: this.file.name,
        };
        addUploadLog(addLog)
          .then(() => {
            // if (response?.data) {
            //   this.setLimitData(response.data);
            // }
            // this.checkingUsage = false;
          })
          .catch((error) => {
            console.log(error);
          });
        this.nextStep(2);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss', '@/scss/app.scss';
.import-prezentation {
  width: 60%;

  .title,
  .sub-title,
  .virbage {
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
  }

  .title {
    font-size: 28px !important;
    font-family: 'Lato' !important;
    font-weight: bold;
  }

  .sub-title {
    font-family: 'Lato' !important;
    font-size: 24px;
  }

  .virbage {
    font-family: 'Lato' !important;
    font-size: 24px;
  }

  .center {
    display: flex;
    justify-content: center;
  }

  .file-select {
    align-items: center;
    flex-direction: column;
  }

  .btn {
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    height: 32px !important;
    line-height: normal;
    letter-spacing: normal;
    min-width: 64px;
    text-transform: none !important;
  }

  .d-none {
    display: none;
  }

  .field-label {
    font-size: 16px;
    font-weight: 500;

    .mdi-information-outline {
      float: right;
    }
  }

  .error-text {
    color: #f70000;
  }

  .reset-file {
    padding: 2px 15px;
    border: 1px solid;
    border-radius: 30px;
    display: flex;

    font-size: 16px;
    height: 32px !important;
    line-height: normal;
    letter-spacing: normal;
    min-width: 64px;
    text-transform: none !important;
    padding: 4px 16px;
    margin: 8px;

    > div {
      margin-right: 10px;
    }
  }

  .waiting-block {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .image {
      margin-top: 20px;
    }
  }

  .row {
    margin: 10px 0;
    > .col {
      padding: 0px;
    }
  }

  .storyline-col {
    margin-top: 15px;
  }

  ::v-deep .audience-select {
    width: 100%;
  }
}
.templates-dropdown {
  flex: none;
  font-size: 14px !important;
  font-weight: 400;
  line-height: 19px;
  ::v-deep .v-text-field__details {
    display: none;
  }

  ::v-deep .v-input__slot {
    border-bottom: 0.8px solid #b6b6b6;
    box-shadow: none;
    font-family: 'Lato';
  }
}

.template-list-item {
  padding: 0 16px;
}
.template-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  font-family: 'Lato';
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  height: 40px;
  margin-top: 10px;
  .template-title {
    color: #212121;
    width: 45%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .current-selection {
    color: #7b7b7b;
    width: 46%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.available-theme-img,
.example-slide-img {
  img {
    box-shadow: 0.413462px 0.413462px 1.65385px rgba(0, 0, 0, 0.1),
      -0.413462px -0.413462px 1.65385px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
  }
}
.no-templates-fount {
  color: #000000;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  .no-result-text {
    border-bottom: 0.8px solid #b6b6b6;
    padding: 20px 0px 20px 20px;
  }
  .add-more-templates-btn {
    cursor: pointer;
    align-items: flex-end;
    display: flex;
    padding: 20px 0px 20px 20px;
    p {
      margin-left: 10px;
      margin-bottom: 2px !important;
    }
  }
}
.tip-bar {
  position: sticky;
  bottom: 0;
  background: #ffffff;
  color: #7b7b7b;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  height: 54px;
  line-height: 17px;
  letter-spacing: 0.01em;
  p {
    height: 34px;
    padding: 15px;
  }
}
.prezentaion-list-item--add {
  position: sticky;
  bottom: 0;
  background: white;
  border-top: 1px solid #ccc;
  padding: 0px 50px !important;

  // &.add-list-item {
  //   bottom: 49px;
  // }

  .v-list-item__title {
    align-items: center;
    display: flex;
    font-size: 14px;

    .add-icon {
      margin-right: 10px;
    }
  }

  .prez-heading-primary {
    justify-content: center;
    font-size: 14px;
    color: #21a7e0;
  }
}

.option-wrapper {
  display: flex;
  align-items: flex-end;
  .plus-icon {
    font-size: 30px;
    margin-right: 10px;
  }
  .prez-heading,
  .prez-description {
    max-width: 43vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .prez-heading {
    font-size: 14px;
    color: black;
  }
  .prez-description {
    font-size: 12px;
  }
}
</style>
