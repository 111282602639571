var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"prezentation-details"},[(
      !_vm.isPrezentationDataLoading &&
      !_vm.isOpPrezentation &&
      !_vm.isBestPracticePrezLanding
    )?_c('div',{staticClass:"prezLockedTopBanner"},[(_vm.isTemplateRemoved(_vm.themes))?_c('div',{staticClass:"templateRemovedAlertBanner"},[_c('div',{staticClass:"lockAlertMessage"},[_c('span',{staticClass:"alertText"},[_c('v-icon',[_vm._v("mdi-lock")]),_vm._v(" "+_vm._s(_vm.$t('build.step3.templateRemovedBanner'))+" ")],1)])]):_vm._e()]):_vm._e(),(_vm.isPrezError)?_c('div',{staticClass:"error-page-container"},[_c('img',{attrs:{"src":("" + (_vm.isForbidden
          ? '/assets/img/prezentations/prez_not_shared.svg'
          : '/assets/img/prezentations/prez_does_not_exist.svg')),"alt":""}}),_c('div',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm.isForbidden ? _vm.$t('build.step3.forbiddenErrorText') : _vm.$t('build.step3.nonForbiddenErrorText'))+" ")]),_c('div',{staticClass:"action-container"},[(_vm.isPrezDetailLandingPage)?_c('div',[_c('v-btn',{staticClass:"primary-btn ma-2 py-2",attrs:{"center":"","id":"new-prez-btn","rounded":"","filled":"","color":"primary","height":"50px !important"},on:{"click":_vm.goBackToPrezentationPage}},[_vm._v(" "+_vm._s(_vm.$t(_vm.backToPrezentationPageText))+" ")])],1):_vm._e(),(_vm.isForbidden)?_c('div',{staticClass:"request-prezentation-container"},[_c('v-btn',{staticClass:"send-assessment ma-2 py-2",attrs:{"loading":_vm.isPrezShareAccessSendingNotification,"width":"262px !important","height":"50px !important"},on:{"click":_vm.handleRequestPrezentation}},[_vm._v(" "+_vm._s(_vm.$t('build.step3.requestAccess'))+" ")])],1):_vm._e()]),(_vm.isBestPracticePrezLanding)?_c('div',{staticClass:"goToSlidesBtnCntr"},[_c('v-btn',{staticClass:"primary-btn ma-2 py-2 goToSlidesBtn",attrs:{"center":"","id":"new-bp-prez-btn","rounded":"","filled":"","color":"primary","height":"50px !important"},on:{"click":function () { return _vm.goBackToBpLibray(); }}},[_vm._v(" "+_vm._s(_vm.$t('bestPracticesGuide.goToBestPracticesLibrary'))+" ")])],1):_vm._e()]):_vm._e(),(!_vm.isPrezentationDataLoading && !_vm.isPrezError)?_c('div',{staticClass:"prezentation-details-wrapper",style:({
      'padding-right': ("" + (this.origin === 'build' && !_vm.isFullScreenMode && _vm.showRightPanel
          ? '30px'
          : '0px')),
    })},[_c('div',{staticClass:"prez-detail-middle",style:({
        width: _vm.showRightPanel ? '70%' : '89%',
        margin: ("" + (this.origin === 'build' && !_vm.isFullScreenMode && _vm.showRightPanel
            ? '0'
            : '0 auto')),
        'margin-top': ("" + (this.origin === 'build' && _vm.isFullScreenMode ? '20px' : '0px')),
      })},[(_vm.origin !== 'build' && !_vm.isFullScreenMode)?_c('div',{staticClass:"backToSlidesContainer"},[_c('v-btn',{on:{"click":function($event){_vm.isBestPracticePrezLanding && _vm.previousRoute !== 'search'
              ? _vm.goBackToBpLibray()
              : _vm.backButton('back')}}},[_c('v-icon',[_vm._v("mdi-chevron-left")]),_vm._v(" "+_vm._s(_vm.$t(_vm.backButtonLabel))+" ")],1),(_vm.isDateAndUser && !_vm.isBestPracticePrezLanding)?_c('span',{staticClass:"last-modified"},[_vm._v(" "+_vm._s(_vm.$t('prezentationsList.lastModified'))+" "+_vm._s(_vm._f("formatDate")(_vm.prezentationData.updatedAt,'MM/DD/YYYY'))+" "+_vm._s(_vm.$t('prezentationsList.by'))+" "+_vm._s(_vm.currentUser.user.id === _vm.prezentationData.updatedByID ? _vm.$t('build.step1.me') : ("" + (_vm.prezentationData.updatedBy || '')))+" ")]):_vm._e()],1):_vm._e(),(!_vm.isFullScreenMode || _vm.isBestPracticePrezLanding)?_c('div',{staticClass:"title-row"},[_c('div',{staticClass:"title-wrapper"},[_c('v-tooltip',{attrs:{"top":"","content-class":"tooltip-content"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('h2',_vm._g(_vm._b({staticClass:"prez-name",style:({
                  'max-width': _vm.showRightPanel ? '315px' : '525px',
                })},'h2',attrs,false),on),[_vm._v(" "+_vm._s(_vm.prezentationData.name)+" ")])]}}],null,false,1469766517)},[_c('span',[_vm._v(" "+_vm._s(_vm.prezentationData.name))])]),(_vm.savedStatus && !_vm.isBestPracticePrezLanding)?_c('span',{staticClass:"saved-status"},[_vm._v(" "+_vm._s(_vm.savedStatus)+" ")]):_vm._e()],1),_c('QuickAccessActions',{attrs:{"rightPanelOpen":_vm.handleRightPanelOpen,"rightPanelClose":_vm.handleRightPanelClose,"origin":_vm.origin}})],1):_vm._e(),(!_vm.isFullScreenMode)?_c('div',{class:("banner-row " + (_vm.isBestPracticePrezLanding ? 'bannerRowForBp' : ''))},[_c('PrezentationDetailBanner',{attrs:{"origin":_vm.origin}})],1):_vm._e(),_c('div',{staticClass:"main-action-container"},[_c('MainActions',{attrs:{"rightPanelOpen":_vm.handleRightPanelOpen,"rightPanelClose":_vm.handleRightPanelClose,"updatePrezentation":_vm.handleUpdatePrezentation,"origin":_vm.origin}}),(_vm.isFullScreenMode)?_c('div',{staticClass:"title-wrapper"},[(_vm.savedStatus)?_c('span',{staticClass:"saved-status"},[_vm._v(" "+_vm._s(_vm.savedStatus)+" ")]):_vm._e(),(!_vm.isBestPracticePrezLanding)?_c('QuickAccessActions',{attrs:{"rightPanelOpen":_vm.handleRightPanelOpen,"rightPanelClose":_vm.handleRightPanelClose,"origin":_vm.origin}}):_vm._e()],1):_vm._e()],1),_c('SlideActions',{attrs:{"rightPanelOpen":_vm.handleRightPanelOpen,"showRightPanel":_vm.showRightPanel,"origin":_vm.origin}}),(_vm.inListViewMode)?_c('ListView',{attrs:{"rightPanelOpen":_vm.handleRightPanelOpen,"rightPanelClose":_vm.handleRightPanelClose,"updatePrezentation":_vm.handleUpdatePrezentation,"showRightPanel":_vm.showRightPanel,"origin":_vm.origin,"selectedAudienceData":_vm.selectedAudienceData}}):_vm._e(),(!_vm.inListViewMode)?_c('MosaicView',{attrs:{"rightPanelOpen":_vm.handleRightPanelOpen,"updatePrezentation":_vm.handleUpdatePrezentation}}):_vm._e()],1),(_vm.showRightPanel)?_c('div',{staticClass:"prez-detail-right",class:{
        'prez-detail-build': this.origin === 'build' && !_vm.isFullScreenMode,
        'banner-active': _vm.isBannerActive,
      },style:({
        'padding-top': _vm.paddingTop,
        height: ("" + (this.origin === 'build' && _vm.isFullScreenMode
            ? 'calc(100vh - 44px)'
            : 'calc(100vh - 68px)')),
      })},[_c('div',{staticClass:"right-panel-component"},[_c(_vm.getRightPanelComponent,{tag:"component",staticClass:"right-panel-context",attrs:{"updatePrezentation":_vm.handleUpdatePrezentation,"updateSlide":_vm.handleUpdateSlideInPrezentation,"origin":_vm.origin,"isNew":_vm.isNew}})],1),_c('div',{staticClass:"smart-tools-icon-panel"},[_c('v-tooltip',{attrs:{"left":"","max-width":"250","content-class":"tooltip-content","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var hover = ref.hover;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","small":"","icon":"","ripple":false,"color":hover ? '#21A7E0' : '#000'},on:{"click":_vm.handleRightPanelClose}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"name":"close-icon"}},[_vm._v(" mdi-close ")])],1)]}}],null,true)})]}}],null,false,3987204704)},[(
              _vm.selectedQuickAction &&
              _vm.selectedMainAction &&
              _vm.selectedQuickAction.id !== 'prezentationComments' &&
              _vm.selectedMainAction.id === 'editSlide'
            )?_c('span',[_vm._v(" "+_vm._s(_vm.$t('generate.closeSmartTools'))+" ")]):(
              _vm.selectedMainAction && _vm.selectedMainAction.id === 'addSlide'
            )?_c('span',[_vm._v(" "+_vm._s(_vm.$t('build.step3.closeGallery'))+" ")]):(
              _vm.selectedMainAction &&
              _vm.selectedQuickAction &&
              _vm.selectedMainAction.id !== 'addSlide' &&
              _vm.selectedMainAction.id !== 'editSlide' &&
              _vm.selectedQuickAction.id !== 'prezentationComments'
            )?_c('span',[_vm._v(_vm._s(_vm.$t('build.step3.close')))]):_c('span',[_vm._v(_vm._s(_vm.$t('build.step3.closeComments')))])]),(
            _vm.selectedMainAction &&
            _vm.selectedQuickAction &&
            _vm.selectedMainAction.id === 'editSlide' &&
            _vm.selectedQuickAction.id !== 'prezentationComments'
          )?[_vm._l((_vm.smartToolsActions),function(action,index){return [(action && action.visible)?_c('v-tooltip',{key:index,attrs:{"left":"","max-width":"250","content-class":"tooltip-content","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var hover = ref.hover;
return [(
                      action.id !== 'deleteSlide' &&
                      action.id !== 'duplicateSlide'
                    )?_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","small":"","icon":"","ripple":false,"color":action.id === _vm.selectedSlideAction.id || hover
                        ? '#21A7E0'
                        : '#000',"disabled":action.disabled},on:{"click":function($event){return _vm.handleSmartToolsIconClick(action)}}},'v-btn',attrs,false),on),[(action.isCustomIcon)?_c('div',{staticClass:"custom-icon"},[(_vm.selectedSlideAction.id === action.id || hover)?_c('img',{attrs:{"src":_vm.getHighlightedIcon(action)}}):_c('img',{attrs:{"src":_vm.getIcon(action)}})]):(action.icon)?_c('v-icon',{attrs:{"name":action.id + '-icon'}},[_vm._v(" "+_vm._s(action.icon)+" ")]):_vm._e()],1):_vm._e()]}}],null,true)})]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t(action.tooltipText)))])]):_vm._e()]})]:_vm._e()],2)]):_vm._e()]):_c('div',{staticClass:"loading-container"},[_c('clip-loader',{attrs:{"loading":_vm.isPrezentationDataLoading,"color":"#21a7e0","width":40,"height":40}})],1),(!_vm.isPrezentationDataLoading && !_vm.isPrezError)?_c('div',{class:("prez-detail-actions-wrapper " + _vm.prezentationDetailActionsClass)},[(!_vm.isBestPracticePrezLanding)?_c('v-menu',{attrs:{"top":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"action-btn",attrs:{"rounded":"","outlined":"","color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('userUploadPrezentation.otherActions'))+" "),_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(("mdi-menu-" + (_vm.otherActionsOpen ? 'up' : 'down')))+" ")])],1)]}}],null,false,1869041986),model:{value:(_vm.otherActionsOpen),callback:function ($$v) {_vm.otherActionsOpen=$$v},expression:"otherActionsOpen"}},[_c('v-list',_vm._l((_vm.getOtherActionsOptions),function(action,index){return _c('v-list-item',{key:index,attrs:{"disabled":_vm.prezentationData.ownerID != _vm.currentUser.user.id &&
            action.id === 'share-prezentation'},on:{"click":function($event){return _vm.handleActionsOptionClick(action)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t(action.name))+" ")])],1)}),1)],1):_vm._e(),(_vm.showRevertToOriginalButton)?_c('v-btn',{class:!_vm.isPremiumFeatureActionCompleted ? 'disabledRevertBtn' : 'revertBtn',attrs:{"disabled":!_vm.isPremiumFeatureActionCompleted,"data-pendo-id":"revert-to-original-btn"},on:{"click":_vm.handleRevertOriginal}},[_vm._v(" "+_vm._s(_vm.$t('slideDetails.revertToOriginal'))+" ")]):_vm._e(),_c('Download',{attrs:{"downloading":_vm.isLocalDownloading,"file-name":_vm.getFilename(),"download-file-name":_vm.getDownloadFilename(),"merged-url":_vm.getMergedUrl,"item":_vm.prezentationData,"log-download":_vm.logDownload,"on-download":function () { return _vm.handleSlideDownload(); },"origin":_vm.getDownloadOrigin,"limitsKey":"prezentation","speedDialPosRight":'10',"isDisabled":_vm.isLocalDownloading}})],1):_vm._e(),_c('ConfirmationDialog',{attrs:{"show":_vm.showResetConfirmDailog,"on-primary-click":_vm.onRevertVisualsConfirm,"on-secondary-click":_vm.onRevertVisualsCancel,"on-close":_vm.onRevertVisualsCancel,"primary-btn-text":_vm.$t('common.yes'),"secondary-btn-text":_vm.$t('common.no'),"title":"Reset Prezentation","text":"All your changes on this Prezentation will be lost. Would you like to continue?"}}),_c('ConfirmationDialog',{attrs:{"show":_vm.showExitConfirmDailog,"on-primary-click":_vm.onExitVisualsConfirm,"on-secondary-click":_vm.onExitVisualsCancel,"on-close":_vm.onExitVisualsCancel,"primary-btn-text":_vm.$t('common.yes'),"secondary-btn-text":_vm.$t('common.no'),"title":_vm.getExitConfirmTitle,"text":"Any changes to the Best Practice Deck will be lost. Would you like to leave anyway?"}}),_c('v-overlay',{staticClass:"overlay",attrs:{"absolute":true,"light":true,"z-index":"20","value":_vm.loadingIllustrations || _vm.loadingAnimations,"color":"#fff","opacity":"0.4"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }