<template>
  <div>
    <div v-if="!isEditing" class="item-name-effect" @click="onSelectFn">
      <v-tooltip
        bottom
        content-class="tooltip-content"
        fixed
        open-delay="500"
        close-delay="300"
      >
        <template v-slot:activator="{ on, attrs }">
          <div
            v-bind="attrs"
            v-on="on"
            class="text-truncate"
            style="font-size: 1rem"
          >
            <!--
            <v-badge :value="countNewComments > 0" dot inline />
            !-->
            <span>{{ name }}</span>
          </div>
        </template>
        <span class="tooltip">{{ name }}</span>
      </v-tooltip>
      <v-tooltip
        top
        content-class="tooltip-content"
        fixed
        open-delay="500"
        close-delay="300"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            small
            class="edit-icon"
            v-bind="attrs"
            v-on="on"
            @click.stop="toggleEditing"
          >
            mdi-pencil
          </v-icon>
        </template>
        <span>{{ $t('overnightPresentations.rename') }}</span>
      </v-tooltip>
    </div>
    <div class="card-edit-name" v-else>
      <v-text-field
        v-model="newName"
        hide-details
        :label="name"
        solo
        autofocus
        @blur="handleRenameBlur"
        @focus="handleRenameFocus"
      >
        <template #append>
          <v-btn
            color="white"
            rounded
            min-width="25"
            height="40"
            @click.stop="handleEdit"
            class="check-btn"
          >
            <v-icon>mdi-check</v-icon>
          </v-btn>
        </template>
      </v-text-field>
    </div>
  </div>
</template>
<script>
export default {
  name: 'NameEdit',
  props: {
    name: {
      type: String,
      required: true,
    },
    countNewComments: {
      type: Number,
      required: true,
    },
    onSelectFn: {
      type: Function,
      default: () => {},
    },
    onEditFn: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      isEditing: false,
      oldNameValue: '',
      newName: '',
    };
  },
  mounted() {
    this.newName = this.name;
  },
  methods: {
    toggleEditing() {
      this.isEditing = !this.isEditing;
    },
    handleRenameFocus() {
      this.oldNameValue = this.name;
    },
    async handleRenameBlur() {
      this.isEditing = false;
      await this.onEditFn(this.oldNameValue);
    },
    async handleEdit() {
      console.log(this.newName);
      if (this.newName && this.newName !== this.name) {
        await this.onEditFn(this.newName);
      }
      this.isEditing = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.item-name-effect {
  display: flex;
  max-width: 250px;
  min-width: 250px;
  align-items: center;
  justify-content: space-between;

  .edit-icon {
    display: none;
    color: #21a7e0;
  }

  &:hover {
    border-radius: 5px;
    cursor: pointer;

    .edit-icon {
      display: block;
    }
  }
}

.card-edit-name {
  align-items: center;
  display: flex;
  padding-left: 5px;
  padding-top: 5px;
  padding-right: 5px;

  ::v-deep .v-input__slot {
    border-bottom: 0.8px solid #b6b6b6;
    box-shadow: none !important;
  }

  .check-btn {
    margin-left: 5px;
  }
}
</style>
