<template>
  <v-card class="confirm-popup">
    <v-card-title class="v-card-title">
      <v-btn class="close-btn" icon @click="onPrimaryClick">
        <v-icon dark v-text="`mdi-close`" />
      </v-btn>
      <h3 class="title">
        {{ $t('overnightPresentations.addToPrezentations') }}
      </h3>
    </v-card-title>
    <v-card-text class="pt-4 v-card-text">
      <div class="texxt">
        {{ $t('overnightPresentations.cardText') }}
      </div>
      <div class="d-flex justify-end">
        <v-btn
          class="primary-btn ma-2 py-2"
          outlined
          rounded
          color="primary"
          @click="onPrimaryClick"
        >
          {{ $t('common.cancel') }}
        </v-btn>
        <v-btn
          style="color: white !important"
          class="secondary-btn ma-2 py-2"
          rounded
          color="primary"
          @click="onSecondaryClick()"
        >
          {{ $t('fingerprint.add') }}
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'ConfirmationDialoge2',
  props: {
    onSecondaryClick: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    onPrimaryClick() {
      this.$modal.hide('ConfirmationDialoge2');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/app.scss', '@/scss/variables.scss';

::v-deep .v-dialog {
  border-radius: 20px !important;
}

.confirm-popup {
  padding: 8px 0 4px 0;
  border: none;
  box-shadow: none !important;

  .v-card-title {
    padding-bottom: 0px !important;
  }

  .v-card-text {
    padding-top: 0px !important;
  }
}
.primary-btn {
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  height: 40px !important;
  line-height: normal;
  letter-spacing: normal;
  min-width: 64px;
  text-transform: none !important;
}

.secondary-btn {
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  height: 40px !important;
  line-height: normal;
  letter-spacing: normal;
  min-width: 64px;
  text-transform: none !important;
}

.text {
  color: #000;
  font-family: 'Lato' !important;
  font-size: 16px !important;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal !important;
  line-height: normal;
  padding-bottom: 28px !important;
}
.texxt {
  color: #000;
  font-family: 'Lato' !important;
  font-size: 16px !important;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal !important;
  line-height: normal;
  margin-top: 10px;
  padding-bottom: 28px !important;
}
.close-btn {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 1;
}
</style>
