<template>
  <div class="us-modal">
    <v-btn class="close-btn" icon @click="close()">
      <v-icon dark>mdi-close</v-icon>
    </v-btn>
    <div class="us-modal__img">
      <UploadSuccessIcon />
    </div>
    <h4 class="us-modal__title">{{ $t('upload.uploadedSuccessfully') }}</h4>
    <p class="us-modal__text">
      {{ $t('upload.accessPrezentation') }}
    </p>
    <p class="us-modal__text margin-top-25">
      {{ $t('upload.doMoreWithPrezentations') }}
    </p>
    <p class="us-modal__text">
      <v-btn
        class="us-modal__btn ma-2 btn"
        rounded
        color="primary"
        @click="onTransferClick()"
      >
        {{ $t('build.step3.addSlidesText') }}
      </v-btn>
    </p>
    <p class="us-modal__text margin-top-25">
      {{ $t('upload.uploadAnotherPrezentation') }}
    </p>
    <v-btn class="us-modal__btn" rounded @click="onUploadClick()">
      {{ $t('common.upload') }}
    </v-btn>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import UploadSuccessIcon from './UploadSuccessIcon.vue';
import { trackUploadEvents } from '../../common/Analytics/UploadEvents';

export default {
  name: 'UploadSuccessModal',
  components: { UploadSuccessIcon },
  props: {
    prezentationId: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState('users', ['currentUser']),
  },
  methods: {
    onUploadClick() {
      this.close();
      trackUploadEvents.uploadSuccessUpload(this.currentUser);
      this.$router.push('/home/upload');
    },
    onTransferClick() {
      this.close();
      trackUploadEvents.uploadSuccessAddToSlides(this.currentUser);
      this.$router.push(`/home/upload/${this.prezentationId}/transfer`);
    },

    close() {
      this.$modal.hide('UploadSuccessModal');
    },
  },
};
</script>

<style lang="scss" scoped>
.us-modal {
  text-align: center;
  padding: 32px 22px;
  font-size: 16px;

  .margin-top-25 {
    margin-top: 25px;
  }

  &__img {
    margin-bottom: 16px;
  }
  &__title {
    margin-bottom: 7px;
  }
  &__text {
    margin-bottom: 12px;
  }

  &__btn {
    &.v-btn--has-bg {
      background-color: #21a7e0 !important;
      color: #fff;
    }
    color: #21a7e0;
    border-color: #21a7e0 !important;
    font-family: Lato;
    font-size: 16px;
    font-weight: bold;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    text-transform: none !important;
  }
}
.close-btn {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 1;
}
</style>
