<template>
  <div class="text-center">
    <template v-if="!waitForAllPrezToLoad">
      <v-item-group
        active-class="primary"
        class="text-center"
        v-if="!isFirstPrezentationCallDone && prezentations.length > 0"
      >
        <v-container fluid>
          <v-row>
            <v-col
              v-for="(prezentation, i) in prezentationsInPage"
              :key="`${prezentation.id}__${i}`"
              class="col-xs-12 col-sm-6 col-md-4 col-xs-12 col-lg-4 col-xl-4"
              :style="[
                $vuetify.breakpoint.xs ||
                $vuetify.breakpoint.sm ||
                $vuetify.breakpoint.md
                  ? { 'min-width': '300px' }
                  : {},
              ]"
            >
              <v-item v-slot="{ active, toggle }">
                <PrezentationCard
                  :prezentation="prezentation"
                  :index="i"
                  :active="active"
                  :toggle-fn="toggle"
                  :is-editable="isEditable"
                  :on-clone-fn="onClone"
                  :on-delete-fn="onDelete"
                  :on-update-fn="onUpdate"
                  :prezentation-status="prezentationStatus"
                  :origin="origin"
                  :thumbnailAsset="prezentation.thumbnailAsset || ''"
                  :advancedFilterActive="advancedFilterActive"
                  @select="
                    $emit('select', {
                      prezentation: $event,
                      listComponentStateSnapshot: $data,
                    })
                  "
                  :isSearchPage="isSearchPage"
                />
              </v-item>
            </v-col>
          </v-row>
        </v-container>
      </v-item-group>
      <v-btn
        v-if="!isFirstPrezentationCallDone && prezentations.length"
        id="loadmore-btn"
        class="loadmore-btn"
        style="text-transform: none"
        @click="loadNext"
        color="#21a7e0"
        rounded
        outlined
        height="32"
        :disabled="
          !isFirstPrezentationCallDone &&
          prezentationsInPage.length >= prezentations.length &&
          !canLoadMore
        "
      >
        {{
          canLoadMore
            ? $t('prezentationsList.seeMoreResult')
            : $t('prezentationsList.noMoreResult')
        }}
      </v-btn>
      <div
        v-if="!isFirstPrezentationCallDone && prezentations.length === 0"
        class="empty-state__container"
      >
        <slot name="empty-state" />
      </div>
      <Loading v-if="isFirstPrezentationCallDone" />
    </template>
    <template v-else>
      <Loading />
    </template>
    <span> </span>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import API from '../../../utils/api';
import PrezentationCard from './PrezentationCard.vue';
import Loading from '../../common/Loading.vue';
import { MatomoAnalyticsHandler } from '../../common/Analytics/MatomoAnalyticsHandler';
import { trackPrezentationEvents } from '../../common/Analytics/PrezentationEvents';
import { getPrezentationThumbnaiAssetId } from '../../../store/modules/helper';
// import { getPrezentationByID } from '../../../utils/api-helper';

export default {
  name: 'PrezentationCardList',
  components: {
    PrezentationCard,
    Loading,
  },
  props: {
    prezentationStatus: {
      type: String,
      default: '',
    },
    waitForAllPrezToLoad: {
      type: Boolean,
      default: false,
    },
    loadedFirstCall: {
      type: Boolean,
      default: true,
    },
    isAdvancedFilterChanged: {
      type: Boolean,
      default: true,
    },
    loadedPrezentationsByOwner: {
      type: Array,
      default: () => [],
    },
    loadPrezentationsFn: {
      type: Function,
      required: false,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
    isResetClicked: {
      type: Boolean,
      default: false,
    },
    prezentationType: {
      type: String,
      default: '',
    },
    origin: {
      type: String,
      required: false,
    },
    loadedPrezentations: {
      type: Array,
      required: false,
    },
    isSearchPage: {
      type: Boolean,
      default: false,
    },
    advancedFilterActive: {
      type: Boolean,
      default: false,
    },
    snapshot: {
      type: Object,
      default: () => null,
    },
  },
  watch: {
    deep: true,
    immediate: true,
    isResetClicked() {
      this.currentPage = 1;
    },
    // loadedPrezentationsByOwner: {
    //   handler(val) {
    //     if (!this.loadedPrezentations && !this.isAdvancedFilterChanged) {
    //       this.prezentations = [...val];
    //       this.loadPrezentationsForPage();
    //     }
    //   },
    //   deep: true,
    //   immediate: true,
    // },
    getAllPrezentations: {
      handler(val) {
        this.prezentations = val;
        this.loadPrezentationsForPage();
      },
      deep: true,
      immediate: true,
    },
    getFilteredAndSearchedPrezentations: {
      handler(val) {
        this.prezentations = val;
        this.loadPrezentationsForPage();
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      canLoadMore: true,
      isLoading: true,
      prezentations: [],
      prezentationsInPage: [],
      totalPrezentations: 0,
      itemsPerPage: 6,
      currentPage: 1,
      totalLoadedPrezentations: [],
      // compkey: 0,
    };
  },
  computed: {
    ...mapGetters('prezentations', [
      'getAllPrezentations',
      'getFilteredAndSearchedPrezentations',
    ]),
    ...mapState('users', ['currentUser']),
    ...mapState('prezentations', [
      'nextTokenToFetchPrezenations',
      'allPrezentations',
      'filteredAndSearchedPrezentations',
      'prezentationBackClicked',
    ]),
    isFirstPrezentationCallDone() {
      if (!this.loadedPrezentations) {
        return this.loadedFirstCall;
      }
      return false;
    },
  },
  async mounted() {
    // Setting snapshot for non-loaded prezentations
    if (this.prezentationBackClicked && this.snapshot) {
      const { allPrezentations, ...state } = this.snapshot;
      this.setAllPrezentationsForLandingPage(allPrezentations || []);
      this.prezentations = allPrezentations;
      this.prezentationsInPage = state.prezentationsInPage;
      this.$nextTick(() => {
        Object.assign(this.$data, state);
      });
      setTimeout(() => {
        this.setPrezentationStateSnapshot({});
        this.setSearchStateSnapshot({});
        this.setPrezentationBackClicked(false);
      }, 200);

      return;
    }

    this.isLoading = true;
    this.setAllPrezentationsForLandingPage([]);
    if (!this.loadedPrezentations) {
      this.canLoadMore = true;
      this.loadPrezentationsFn()
        .then(() => {
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    } else {
      this.setAllPrezentationsForLandingPage(this.loadedPrezentations);
      this.prezentations = this.loadedPrezentations;
      // this.canLoadMore = false;
      this.loadPrezentationsForPage();
      this.canLoadMore = true;
      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions('prezentations', [
      'setAllPrezentationsForLandingPage',
      'updatePrezentationWithAction',
      'setPrezentationStateSnapshot',
      'setPrezentationBackClicked',
    ]),
    ...mapActions('search', ['setSearchStateSnapshot']),
    loadNext() {
      this.currentPage++;
      this.loadPrezentationsForPage();
    },
    loadPrezentationsForPage() {
      this.totalPrezentations = this.prezentations.length;
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      this.prezentationsInPage = this.prezentations
        .map((prez) => ({
          ...prez,
          thumbnailAsset: getPrezentationThumbnaiAssetId(prez),
        }))
        .slice(0, endIndex);
      this.canLoadMore =
        this.prezentationsInPage.length < this.prezentations.length;
    },
    async onClone(prezentationToBeCloned) {
      trackPrezentationEvents.prezentationsThumbnailCopyIconClick(
        this.currentUser,
        prezentationToBeCloned,
      );
      const prezDataForMatomo = {
        accessType: prezentationToBeCloned.shared ? 'Shared' : 'Private',
        ...prezentationToBeCloned,
      };
      const clone = { ...prezentationToBeCloned };
      delete clone.owner;
      delete clone.audience;
      delete clone.comments;
      delete clone.slides;
      delete clone.thumbnail;
      clone.id = uuidv4();
      clone.name = `${prezentationToBeCloned.name}--copy`;
      if (prezentationToBeCloned.prezentationSource === 'User Uploaded') {
        clone.isDuplicateForUserUploaded = true;
      }
      await this.createPrezentation(clone).then((value) => {
        MatomoAnalyticsHandler.trackPrezCopyIconClick(
          this.currentUser.user,
          prezDataForMatomo,
        );
        this.updatePrezentationWithAction({
          prezentation: {
            ...value.prezentationData,
            prezType: 'get_my_prezentations',
            shared: false,
            isFavorite: false,
            isDownloaded: false,
            audience: prezentationToBeCloned.audience,
            prezentationCarouselsThumbnails:
              !prezentationToBeCloned.prezentationCarouselsThumbnails
                ? undefined
                : prezentationToBeCloned.prezentationCarouselsThumbnails,
          },
          action: 'duplicate',
          parentPrezId: prezentationToBeCloned.id,
        });
      });
    },
    async onDelete(prezentationToBeDeleted) {
      trackPrezentationEvents.prezentationsThumbnailDelete(
        this.currentUser,
        prezentationToBeDeleted,
      );
      this.updatePrezentationWithAction({
        prezentation: prezentationToBeDeleted,
        action: 'delete',
      });
    },
    async onUpdate(newName, prezentationToBeUpdated) {
      trackPrezentationEvents.prezentationsThumbnailRename(
        this.currentUser,
        prezentationToBeUpdated,
      );
      const prezDataForMatomo = {
        accessType: prezentationToBeUpdated.shared ? 'Shared' : 'Private',
        ...prezentationToBeUpdated,
      };
      const prezentationData = {
        id: prezentationToBeUpdated.id,
        name: newName,
      };
      await this.updatePrezentation(prezentationData).then(async () => {
        MatomoAnalyticsHandler.trackPrezRenameIconClick(
          this.currentUser.user,
          prezDataForMatomo,
        );
        this.updatePrezentationWithAction({
          prezentation: {
            ...prezentationToBeUpdated,
            name: newName,
          },
          action: 'rename',
        });
      });
    },
    async createPrezentation(prezentationData) {
      const path = '/prezentation';
      return API.post(path, prezentationData);
    },
    async updatePrezentation(prezentationData) {
      const path = '/prezentation';
      return API.put(path, prezentationData);
    },
    async deletePrezentation(toDeleteId) {
      const path = `/prezentation/${toDeleteId}`;
      return API.delete(path);
    },
  },
};
</script>
<style lang="scss" scoped>
.slides-filter__sort {
  display: flex;
  margin-left: auto;
  width: 20rem;
}
.mosaic-view,
.list-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
::v-deep .v-input__slot {
  border-bottom: 0.8px solid #b6b6b6;
  box-shadow: none !important;
}

.prez-load__wrapper {
  margin-bottom: 37px;
  padding-bottom: 24px;
}
.loadmore-btn {
  margin-top: 22px;
}
</style>
