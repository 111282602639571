var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"manage_container"},[_c('div',{staticClass:"closeIconContainer"},[_c('v-icon',{on:{"click":_vm.close}},[_vm._v(_vm._s('mdi-close'))])],1),_c('h1',{staticClass:"manage-heading"},[_vm._v(" "+_vm._s(_vm.$t('overnightPresentations.newMessage'))+" ")]),(_vm.loading)?_c('div',{staticClass:"spinnerContainer"},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":50,"color":"#21a7e0"}})],1):_c('div',{staticClass:"content"},[_c('div',{staticClass:"instructions"},[_c('div',{staticClass:"instructionsHeader"},[_vm._v(" "+_vm._s(_vm.$t('overnightPresentations.enterMessage'))+" ")]),_c('v-textarea',{staticClass:"instructionsArea",attrs:{"solo":"","label":_vm.$t('overnightPresentations.example'),"no-resize":true},model:{value:(_vm.instructions),callback:function ($$v) {_vm.instructions=$$v},expression:"instructions"}})],1),_c('div',{staticClass:"files-desc"},[_c('div',{staticClass:"files-subheader"},[_vm._v(" "+_vm._s(_vm.$t('overnightPresentations.uploadFiles'))+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.$t('overnightPresentations.provideDocumets'))+" "+_vm._s(_vm.$t('overnightPresentations.confidentiality'))+" ")])]),_c('div',{staticClass:"upload-container"},[_c('div',{staticClass:"local-files-wrapper",class:{
          highlight: _vm.localFiles.length,
        }},[_c('div',{staticClass:"header"},[_vm._v(" "+_vm._s(_vm.$t('overnightPresentations.localFiles'))+" ")]),_c('div',{staticClass:"desc"},[_c('div',[_vm._v(_vm._s(_vm.$t('overnightPresentations.filesUpload')))]),_c('div',[_c('span',[_vm._v(" "+_vm._s(_vm.$t('overnightPresentations.maximumFileSize'))+" "),_c('v-tooltip',{attrs:{"right":"","max-width":"300","content-class":"tooltip-content","fixed":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"icon-title",style:({ 'font-size': '16px' }),domProps:{"textContent":_vm._s("mdi-information-outline")}},'v-icon',attrs,false),on))]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('overnightPresentations.fileType'))+" ")])])],1)])]),_c('div',{staticClass:"user-input"},[_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t('overnightPresentations.addAsManyAsYouNeed'))+" ")]),_c('div',{staticClass:"file-chips-wrapper"},[(_vm.localFiles.length)?_vm._l((_vm.localFiles),function(lFile,i){return _c('div',{key:i,staticClass:"file-chip",class:{ 'size-error': lFile.size / 1024 / 1024 > 200 },style:({
                  height: ("" + (_vm.getFileItemsHeight(lFile))),
                })},[_c('div',{staticClass:"fileNameWrapper"},[_vm._v(" "+_vm._s(lFile.fileName ? lFile.fileName : lFile.name)+" ")]),_c('v-icon',{attrs:{"color":'#7B7B7B'},on:{"click":function($event){return _vm.removeFile(i)}}},[_vm._v(" mdi-close-circle-outline ")])],1)}):_c('div',{staticClass:"file-chip-placeholder"},[_vm._v(" "+_vm._s(_vm.$t('overnightPresentations.filesWillAppear'))+" ")])],2)]),_c('div',{staticClass:"file-upload-action"},[_c('label',{staticClass:"custom-file-upload",attrs:{"for":"file-upload"}},[_vm._v(" "+_vm._s(_vm.$t('overnightPresentations.selectFiles'))+" ")]),_c('input',{attrs:{"id":"file-upload","type":"file","accept":("" + (_vm.allowedUploadTypes.join(', '))),"multiple":""},on:{"change":_vm.previewFiles}})])]),_c('div',{staticClass:"cloud-files-wrapper",class:{ highlight: _vm.sharedLinks.trim().length }},[_c('div',{staticClass:"header"},[_vm._v(" "+_vm._s(_vm.$t('overnightPresentations.cloudlinks'))+" ")]),_c('div',{staticClass:"desc"},[_c('div',[_vm._v(_vm._s(_vm.$t('overnightPresentations.pasteLinks')))]),_c('div',[_vm._v(_vm._s(_vm.$t('overnightPresentations.filesLargerThan200MB')))])]),_c('div',{staticClass:"user-input"},[_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t('overnightPresentations.shareableLinkInNewLink'))+" ")]),_c('v-textarea',{staticClass:"share-links",attrs:{"solo":"","rules":_vm.linkRules,"label":_vm.$t('overnightPresentations.pasteLinksHere'),"no-resize":true},model:{value:(_vm.sharedLinks),callback:function ($$v) {_vm.sharedLinks=$$v},expression:"sharedLinks"}})],1),_c('div',{class:("" + (_vm.sharedLinks.length > 0 && !_vm.sharedAccess
              ? 'check-access-error'
              : 'check-access'))},[_c('v-checkbox',{attrs:{"hide-details":true,"label":_vm.$t('overnightPresentations.sharedAccessWithPrezentium')},model:{value:(_vm.sharedAccess),callback:function ($$v) {_vm.sharedAccess=$$v},expression:"sharedAccess"}}),_c('span',{staticClass:"guide-link",on:{"click":_vm.handleHowtoShareAccess}},[_vm._v(_vm._s(_vm.$t('overnightPresentations.howToShareAccess')))])],1)])]),_c('div',{staticClass:"actionButtons"},[_c('div',{staticClass:"buttonsContainer"},[_c('v-btn',{staticClass:"saveButton",attrs:{"disabled":(_vm.localFiles.length <= 0 &&
              _vm.sharedLinks.length <= 0 &&
              _vm.filesToBeDeleted.length <= 0 &&
              _vm.linksToBeDeleted.length <= 0 &&
              _vm.instructions.length <= 0) ||
            (_vm.sharedLinks.length > 0 &&
              (!_vm.isValidHttpUrl(_vm.sharedLinks) || _vm.sharedAccess === false)),"loading":_vm.updating},on:{"click":_vm.handleSaveAttachmentsAndLinks}},[_vm._v(" "+_vm._s(_vm.$t('common.submit'))+" ")])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }