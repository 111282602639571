<template>
  <div class="exceeding-limit-detail-wrapper">
    <v-btn class="close-btn" icon @click="handleClose">
      <v-icon dark v-text="`mdi-close`" />
    </v-btn>
    <div class="metric-detail-img">
      <img src="/assets/img/profile/limit.svg" alt />
    </div>
    <div class="exceding-limit-title">
      {{
        getIsNonBrandedCompany
          ? $t('overnightPresentations.interestedInAddingYourBrandTemplates')
          : $t('overnightPresentations.interestedInAddingMoreSlides')
      }}
    </div>
    <div class="eceeding-limit-action">
      <v-btn
        rounded
        type="submit"
        color="#21a7e0"
        width="150"
        height="40"
        @click="handleSubmit"
      >
        {{ $t('overnightPresentations.getInTouch1') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import GetInTouchDetail from './GetInTouchDetail.vue';
import { sendEmail, createTemplateRequest } from '../../../utils/api-helper';
import { trackBuildEvents } from '../../common/Analytics/BuildEvents';
import { trackSlideEvents } from '../../common/Analytics/SlideEvents';
import {
  BUILD,
  SLIDES,
  TD_AUD,
  TD_COMPANY_OF_AUD_TAILORED_FOR,
} from '../../common/Analytics/MatomoTrackingDataHelper';
import { trackProfileEvents } from '../../common/Analytics/ProfileEvents';

export default {
  name: 'AddingMoreTemplateDetail',
  props: {
    onHandleSubmit: {
      type: Function,
      default: () => {},
    },
    origin: {
      type: String,
      default: '',
      required: false,
    },
    tailorID: {
      type: String,
      default: '',
      required: false,
    },
    audienceCompany: {
      type: String,
      default: '',
      required: false,
    },
    templateRequestFrom: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState('users', ['currentUser']),
    ...mapGetters('users', ['getIsNonBrandedCompany']),
  },
  methods: {
    handleSubmit() {
      // create template request
      createTemplateRequest({
        requestFrom: this.templateRequestFrom,
      })
        .then((resp) => {
          console.log(resp);
        })
        .catch((err) => {
          console.log(err);
        });

      // send email api call
      try {
        const mBody =
          `${this.currentUser.user.firstName} ${this.currentUser.user.lastName}(${this.currentUser.user.id})` +
          ` has expressed interest in additional templates`;
        const params = {
          type: 'template-request',
          meta: {
            message: mBody,
            requester: this.currentUser.user.firstName,
          },
        };
        sendEmail(params)
          .then(() => this.onHandleSubmit?.())
          .catch((err) => console.log(err));
        if (this.origin.toLowerCase() === BUILD) {
          trackBuildEvents.buildTemplateInfoAddMoreGetInTouch(
            this.currentUser,
            {},
          );
        }
        if (this.origin.toLowerCase() === SLIDES) {
          trackSlideEvents.slidesTemplateInfoAddMoreGetInTouch(
            this.currentUser,
            {
              [TD_AUD]: this.tailorID,
              [TD_COMPANY_OF_AUD_TAILORED_FOR]: this.audienceCompany,
            },
          );
        } else {
          trackProfileEvents.profileTemplatesAddmoreGetinTouch(
            this.currentUser,
          );
        }
      } catch (err) {
        console.log(err);
      }

      this.$modal.hide('AddingMoreTemplateDetail');
      this.$modal.show(
        GetInTouchDetail,
        {
          title: 'prezentation.thanksForYourInterestInTemplates',
        },
        {
          name: 'GetInTouchDetail',
          width: '564',
          height: '507px',
          styles: { borderRadius: '12px' },
        },
      );
    },
    handleClose() {
      this.$modal.hide('AddingMoreTemplateDetail');
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.exceeding-limit-detail-wrapper {
  width: 100%;
  height: 100%;

  .close-btn {
    color: #212121;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
  }

  .exceding-limit-title {
    font-size: 20px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    height: 54px;
  }

  .eceeding-limit-action {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 14px;

    button {
      color: white;
      font-size: 20px;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-transform: none;
    }
  }

  .metric-detail-img {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 66px;
    margin-bottom: 48px;
  }

  img {
    margin: auto;
  }
}
</style>
