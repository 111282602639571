<template>
  <div class="main-wrapper">
    <v-btn class="close-btn" icon @click="handleClose">
      <v-icon dark>mdi-close</v-icon>
    </v-btn>
    <div class="spinner-wrapper" v-if="isLoading">
      <LoadSpinner />
    </div>
    <div class="sharing-modal-main-wrapper" v-if="!isLoading">
      <div
        class="defaultCompanyTemplateShare"
        v-if="templateId === defaultThemeID"
      >
        <div v-if="emailSent === true" class="emailSent">
          <div class="getInTouchImgWrapper">
            <img
              src="/assets/img/get-in-touch.svg"
              alt
              width="342px"
              height="276px"
            />
          </div>
          <div class="thanksText">
            {{ $t('profile.shareTemplateAccess.thanks') }}
          </div>
          <div class="ackText">
            {{ $t('profile.shareTemplateAccess.weRecievedyourRequest') }}
          </div>
        </div>
        <div v-else class="emailNotSent">
          <div class="defaultTemplateAlertHeading">
            {{
              $t('profile.shareTemplateAccess.defaultTemplate', { companyName })
            }}
          </div>
          <div class="defaultTemplateAlertContent">
            {{ $t('profile.shareTemplateAccess.defaultTemplateContent') }}
          </div>
          <div class="helpText">
            {{ $t('profile.shareTemplateAccess.needHelp') }}
          </div>
          <div class="getInTouchBtnContainer">
            <v-btn
              class="primary-button"
              rounded
              color="#21a7e0"
              height="48"
              min-width="134"
              @click="getInTouchThroughMail"
              :loading="emailSending === true"
            >
              {{ $t('fingerprint.getInTouch') }}
            </v-btn>
          </div>
        </div>
      </div>
      <div class="generalTemplateShare" v-else>
        <div
          class="step1"
          v-show="revokePermissionsOnGeneralTemplateStep === 1"
        >
          <div class="sharing-modal-container">
            <div class="sharing-modal__title font-weight-bold">
              {{ $t('profile.shareTemplateAccess.shareWithColleague') }}
            </div>
            <v-radio-group v-model="shareOptionType" style="height: 67px">
              <v-radio
                color="#20a7e0"
                name="shareType"
                :label="$t('build.step1.private')"
                value="me"
                @change="handleShareTypeChangeFn()"
              />
              <v-radio
                color="#20a7e0"
                name="shareType"
                :label="$t('profile.shareTemplateAccess.shareWith')"
                value="share"
                style="padding-top: 3px"
                @change="handleShareTypeChangeFn()"
              />
            </v-radio-group>
            <div class="share-container">
              <div class="radio-button_item">
                <v-checkbox
                  v-model="companyCheckPermission"
                  :label="$t('profile.shareTemplateAccess.myCompany')"
                  color="#20a7e0"
                  hide-details
                  value="company"
                  :disabled="shareOptionType !== 'share'"
                />
                <v-select
                  :menu-props="{ bottom: true, offsetY: true }"
                  dense
                  color="rgba(0, 0, 0, 0.6)"
                  v-show="companyCheckPermission === 'company'"
                  item-text="text"
                  item-value="value"
                  :items="shareOptions"
                  v-model="companyPermission"
                  class="share-option-select_dropdown"
                  hide-details
                >
                  <template v-slot:selection="{ item }">
                    <div>
                      <span>{{ item.text }}</span>
                    </div>
                  </template>
                  <template v-slot:item="{ item }">
                    <div class="d-flex">
                      <div class="share-select-item__selected">
                        <v-icon
                          v-if="companyPermission === item.text"
                          color="primary"
                        >
                          mdi-check
                        </v-icon>
                      </div>
                    </div>
                    <div class="d-flex flex-column share-select-item">
                      <div>
                        {{ item.text }}
                      </div>
                      <div
                        class="text-caption share-select-item__description"
                        v-if="item.description"
                      >
                        {{ item.description }}
                      </div>
                    </div>
                  </template></v-select
                >
              </div>
              <div class="radio-button_item">
                <v-checkbox
                  v-model="teamCheckPermission"
                  :label="$t('profile.shareTemplateAccess.myTeam')"
                  color="#20a7e0"
                  hide-details
                  value="team"
                  :disabled="shareOptionType !== 'share'"
                />
                <v-select
                  :menu-props="{ bottom: true, offsetY: true }"
                  dense
                  color="rgba(0, 0, 0, 0.6)"
                  v-show="teamCheckPermission === 'team'"
                  item-text="text"
                  item-value="value"
                  :items="shareOptions"
                  v-model="teamPermission"
                  class="share-option-select_dropdown"
                  hide-details
                >
                  <template v-slot:selection="{ item }">
                    <div>
                      <span>{{ item.text }}</span>
                    </div>
                  </template>
                  <template v-slot:item="{ item }">
                    <div class="d-flex">
                      <div class="share-select-item__selected">
                        <v-icon
                          v-if="teamPermission === item.text"
                          color="primary"
                        >
                          mdi-check
                        </v-icon>
                      </div>
                    </div>
                    <div class="d-flex flex-column share-select-item">
                      <div>
                        {{ item.text }}
                      </div>
                      <div
                        class="text-caption share-select-item__description"
                        v-if="item.description"
                      >
                        {{ item.description }}
                      </div>
                    </div>
                  </template>
                </v-select>
              </div>
              <div class="radio-button_item">
                <div class="select-users-container">
                  <v-checkbox
                    v-model="userCheckPermission"
                    :label="$t('profile.shareTemplateAccess.selectColleague')"
                    color="#20a7e0"
                    hide-details
                    value="user"
                    :disabled="shareOptionType !== 'share'"
                  />
                  <div
                    v-if="userCheckPermission === 'user'"
                    :label="$t('profile.shareTemplateAccess.selectColleague')"
                  >
                    <UserSelector
                      v-model="usersWithPermissions"
                      filter="insideCompanyUsers"
                      class="select-colleagues-autocomplete"
                      @change="onSelectedUsersChange($event)"
                    >
                      <template v-slot:user-actions="{ item }">
                        <v-list-item-action
                          :class="
                            currentUser.user.id === item.id
                              ? 'currentUserRemove'
                              : 'colleaguesRemove'
                          "
                        >
                          <v-select
                            :menu-props="{ bottom: true, offsetY: true }"
                            color="rgba(0, 0, 0, 0.6)"
                            dense
                            item-text="text"
                            item-value="value"
                            v-model="item.permissionLevel"
                            :items="userShareOptions"
                            name="selectItem"
                            class="share-option-select_dropdown text-caption"
                            :disabled="currentUser.user.id === item.id"
                          >
                            <template v-slot:selection="selectSlotItem">
                              {{ selectSlotItem.item.text }}
                            </template>
                            <template v-slot:item="selectSlotItem">
                              <div lass="d-flex">
                                <div class="share-select-item__selected">
                                  <v-icon
                                    v-if="
                                      item.permissionLevel ===
                                      selectSlotItem.item.text
                                    "
                                    color="primary"
                                  >
                                    mdi-check
                                  </v-icon>
                                </div>
                              </div>
                              <div class="d-flex flex-column share-select-item">
                                <div>
                                  {{ selectSlotItem.item.text }}
                                </div>
                                <div
                                  class="text-caption share-select-item__description"
                                  v-if="selectSlotItem.item.description"
                                >
                                  {{ selectSlotItem.item.description }}
                                </div>
                              </div>
                            </template>
                          </v-select>
                        </v-list-item-action>
                      </template>
                    </UserSelector>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="actions">
            <v-btn
              class="primary-button"
              rounded
              color="#21a7e0"
              height="48"
              min-width="134"
              @click="updateShareDetailsOnStepOne"
              :disabled="
                shareOptionType === 'share' &&
                !(
                  companyCheckPermission ||
                  teamCheckPermission ||
                  userCheckPermission
                )
              "
            >
              {{ this.$t(this.updateBtnText) }}
            </v-btn>
          </div>
        </div>
        <div
          class="step2"
          v-show="revokePermissionsOnGeneralTemplateStep === 2"
        >
          <div class="alertHeading">
            {{ $t('fingerprint.groupInsights.areYouSure') }}
          </div>
          <div class="alertContent">
            {{
              $t('profile.shareTemplateAccess.changeAccessPermissions', {
                templateName,
              })
            }}
          </div>
          <div class="alertActions">
            <v-btn
              class="primary-button noButton"
              rounded
              color="#21a7e0"
              height="48"
              min-width="134"
              @click="handleCancelRevoking"
            >
              {{ $t('common.no') }}
            </v-btn>
            <v-btn
              class="primary-button yesButton"
              rounded
              color="#21a7e0"
              height="48"
              min-width="134"
              @click="handleUpdateTemplateDetailsOnStepTwo"
            >
              {{ $t(updateBtnText) }}
            </v-btn>
          </div>
        </div>
        <div
          class="step3"
          v-show="revokePermissionsOnGeneralTemplateStep === 3"
        >
          <div class="stepThreeHeading">
            {{ $t('profile.editPaymentDetail.success') }}
          </div>
          <div class="stepThreeContent">
            {{ $t('profile.shareTemplateAccess.stepThreeContent') }}
          </div>
          <div class="stepThreeAction">
            <v-btn
              class="primary-button"
              rounded
              color="#21a7e0"
              height="48"
              min-width="134"
              @click="handleDoneRevoking"
            >
              {{ $t('common.done') }}
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import LoadSpinner from '../../common/LoadSpinner';
import UserSelector from '../../common/UserSelector/UserSelector.vue';
import {
  sendEmail,
  getThemesAclById,
  updateTemplateShareDetails,
} from '../../../utils/api-helper';

export default {
  name: 'ShareTemplateAccess',
  props: {
    templateName: {
      type: String,
    },
    templateId: {
      type: String,
    },
    companyName: {
      type: String,
    },
  },
  components: { LoadSpinner, UserSelector },
  data() {
    return {
      defaultThemeID: '',
      colleaguesWithPermissions: [],
      isLoading: true,
      updateBtnText: 'common.update',
      companyPermission: 'Viewer',
      teamPermission: 'Viewer',
      shareOptionType: '',
      companyCheckPermission: null,
      teamCheckPermission: null,
      userCheckPermission: null,
      shareOptions: [
        {
          text: 'Viewer',
          description:
            'Access slides and create prezentations using this template.',
        },
        {
          text: 'Editor',
          description:
            'Access slides, create prezentations using this template and control access to this template.',
        },
      ],
      userShareOptions: [
        {
          text: 'Viewer',
          description:
            'Access slides and create prezentations using this template.',
        },
        {
          text: 'Editor',
          description:
            'Access slides, create prezentations using this template and control access to this template.',
        },
      ],
      usersWithPermissions: [],
      emailSent: false,
      emailSending: false,
      revokePermissionsOnGeneralTemplateStep: 1,
      usersRevoked: [],
      privatePermission: [],
      myCompanyPermission: [],
      myTeamPermission: [],
      teamOriginalPermission: 'Viewer',
      companyOriginalPermission: 'Viewer',
    };
  },
  async beforeMount() {
    this.isLoading = true;
    await getThemesAclById(this.templateId)
      .then((resp) => {
        this.defaultThemeID = resp?.data?.defaultThemeID || '';
        const sharedEntities = resp?.data?.sharedEntities;

        if (
          sharedEntities.length === 1 &&
          sharedEntities[0].sharedEntityID === this.currentUser.user.id &&
          sharedEntities[0].sharedEntityType === 'user' &&
          sharedEntities[0].permissionLevel === 'Editor'
        ) {
          this.privatePermission.push('Private');
          this.shareOptionType = 'me';
          this.userCheckPermission = '';
          this.teamCheckPermission = '';
          this.companyCheckPermission = '';
        } else {
          this.shareOptionType = 'share';

          const teamEntities = sharedEntities.filter(
            (ents) =>
              ents.sharedEntityType === 'team' &&
              ents.sharedEntityID === this.currentUser.team.id,
          );
          const companyEntity = sharedEntities.filter(
            (ents) =>
              ents.sharedEntityType === 'company' &&
              ents.sharedEntityID === this.currentUser.company.id,
          );
          const usersEntities = sharedEntities.filter(
            (ents) => ents.sharedEntityType === 'user',
          );

          if (teamEntities.length > 0) {
            this.teamCheckPermission = 'team';
            this.teamPermission = teamEntities[0].permissionLevel;
            this.myTeamPermission.push('Team');
            this.teamOriginalPermission = teamEntities[0].permissionLevel;
          } else {
            this.teamCheckPermission = '';
            this.teamPermission = 'Viewer';
            this.myTeamPermission = [];
            this.teamOriginalPermission = 'Viewer';
          }

          if (companyEntity.length > 0) {
            this.companyCheckPermission = 'company';
            this.companyPermission = companyEntity[0].permissionLevel;
            this.myCompanyPermission.push('Company');
            this.companyOriginalPermission = companyEntity[0].permissionLevel;
          } else {
            this.companyCheckPermission = '';
            this.companyPermission = 'Viewer';
            this.myCompanyPermission = [];
            this.companyOriginalPermission = 'Viewer';
          }

          if (usersEntities.length > 0) {
            this.userCheckPermission = 'user';
            this.usersWithPermissions = usersEntities.map((userEnts) => ({
              ...userEnts,
              isActive: true, // making users enabled by default
              id: userEnts.sharedEntityID,
            }));
          }
        }
      })
      .catch((err) => console.log(err));
    this.isLoading = false;
  },
  computed: {
    ...mapState('users', ['currentUser']),
  },
  methods: {
    onSelectedUsersChange(users) {
      this.user = users.map((user) => {
        if (!user.permissionLevel) {
          user.permissionLevel = this.userShareOptions[0].text;
        }
        return user;
      });
    },
    handleDoneRevoking() {
      this.handleClose();
    },
    handleShareTypeChangeFn() {
      if (this.shareOptionType) {
        this.companyCheckPermission = '';
        this.userCheckPermission = '';
        this.teamCheckPermission = '';
      }
    },
    updateTemplateShareInfo(step) {
      this.updateBtnText = 'common.updating';
      let updatePayload = {};
      const teamEntity = [];
      const companyEntity = [];
      let usersEntities = [];
      if (this.shareOptionType === 'me') {
        updatePayload = {
          id: this.templateId,
          sharedEntities: [
            {
              sharedEntityID: this.currentUser.user.id,
              sharedEntityType: 'user',
              permissionLevel: 'Editor',
            },
          ],
          defaultThemeID:
            this.defaultThemeID !== '' ? this.defaultThemeID : undefined,
        };
      } else if (this.shareOptionType === 'share') {
        if (this.teamCheckPermission === 'team') {
          teamEntity.push({
            sharedEntityID: this.currentUser.team.id,
            sharedEntityType: 'team',
            permissionLevel: this.teamPermission,
          });
        }
        if (this.companyCheckPermission === 'company') {
          companyEntity.push({
            sharedEntityID: this.currentUser.company.id,
            sharedEntityType: 'company',
            permissionLevel: this.companyPermission,
          });
        }
        if (this.userCheckPermission === 'user') {
          usersEntities = [
            ...this.usersWithPermissions.map((usr) => ({
              sharedEntityID: usr.id,
              permissionLevel: usr.permissionLevel,
              sharedEntityType: 'user',
            })),
          ];
        }

        if (
          this.privatePermission.indexOf('Private') !== -1 ||
          (this.myTeamPermissionRevoked() === true &&
            this.teamOriginalPermission === 'Editor') ||
          (this.myCompanyPermissionRevoked() === true &&
            this.companyOriginalPermission === 'Editor')
        ) {
          usersEntities = [
            ...usersEntities.filter(
              (usr) => usr.sharedEntityID !== this.currentUser.user.id,
            ),
          ];
          usersEntities.push({
            sharedEntityID: this.currentUser.user.id,
            sharedEntityType: 'user',
            permissionLevel: 'Editor',
          });
        }
        updatePayload = {
          id: this.templateId,
          sharedEntities: [...usersEntities, ...teamEntity, ...companyEntity],
          defaultThemeID:
            this.defaultThemeID !== '' ? this.defaultThemeID : undefined,
        };
      }
      updateTemplateShareDetails(updatePayload)
        .then(() => {
          if (step === 1) {
            this.updateBtnText = 'navbarActions.shareFingerprintvue.updated';
            setTimeout(() => {
              this.handleClose();
            }, 800);
          } else {
            this.revokePermissionsOnGeneralTemplateStep = 3;
          }
        })
        .catch((err) => console.log(err))
        .finally(() => {});
    },
    handleUpdateTemplateDetailsOnStepTwo() {
      this.updateTemplateShareInfo(this.revokePermissionsOnGeneralTemplateStep);
    },
    handleCancelRevoking() {
      this.revokePermissionsOnGeneralTemplateStep = 1;
      this.updateBtnText = 'common.update';
    },
    async getInTouchThroughMail() {
      this.emailSending = true;
      const payload = {
        type: 'template_permission_change_help',
        to: ['success@prezent.ai'],
        meta: {
          firstName: this.currentUser.user.firstName,
          lastName: this.currentUser.user.lastName,
          templateName: this.templateName,
          userEmail: this.currentUser.user.id,
        },
      };
      await sendEmail(payload)
        .then((resp) => {
          console.log(resp);
          this.emailSent = true;
        })
        .catch((err) => console.log(err));
      this.emailSending = false;
    },
    privatePermissionRevoked() {
      return !!(
        this.shareOptionType !== 'me' &&
        this.privatePermission.indexOf('Private') !== -1
      );
    },
    myCompanyPermissionRevoked() {
      return !!(
        this.companyCheckPermission !== 'company' &&
        this.myCompanyPermission.indexOf('Company') !== -1
      );
    },
    myTeamPermissionRevoked() {
      return !!(
        this.teamCheckPermission !== 'team' &&
        this.myTeamPermission.indexOf('Team') !== -1
      );
    },
    areUsersRevoked() {
      return !!(
        this.usersRevoked.length > 0 ||
        (this.userCheckPermission !== 'user' &&
          this.usersWithPermissions.length > 0)
      );
    },
    updateShareDetailsOnStepOne() {
      if (
        this.privatePermissionRevoked() === true ||
        this.myCompanyPermissionRevoked() === true ||
        this.myTeamPermissionRevoked() === true ||
        this.areUsersRevoked() === true
      ) {
        this.revokePermissionsOnGeneralTemplateStep = 2;
        this.updateBtnText = 'common.yes';
      } else {
        this.updateTemplateShareInfo(
          this.revokePermissionsOnGeneralTemplateStep,
        );
      }
    },
    handleClose() {
      this.$modal.hide('ShareTemplateAccess');
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/variables.scss', '@/scss/app.scss';

.main-wrapper {
  height: 100%;
  max-height: 85vh;
  min-height: 230px;
  overflow: auto;
}
.close-btn {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 1;
}
.radio-button_item {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 6px 0;
  padding-right: 40px;
  width: 100%;
}

.share-option-select_dropdown {
  max-width: 120px;
  width: 120px;
}
.sharing-modal-main-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  font-family: 'Lato', sans-serif;
  height: 100%;
  justify-content: space-between;
  padding: 2rem;
  width: 100%;
  .defaultCompanyTemplateShare {
    width: 100%;
    .emailSent {
      width: 100%;
      .thanksText,
      .ackText {
        font-size: 17px;
        padding: 0px 0px 20px 0px;
        text-align: center;
      }
      .getInTouchImgWrapper {
        width: 36%;
        margin: 0 auto;
        padding: 20px 0px;
      }
    }
    .emailNotSent {
      width: 100%;
      .defaultTemplateAlertHeading {
        font-size: 19px;
        font-weight: 600;
        padding: 10px 0px 30px 0px;
      }
      .defaultTemplateAlertContent,
      .helpText {
        font-size: 17px;
        padding: 0px 0px 20px 0px;
      }
      .getInTouchBtnContainer {
        width: 21%;
        margin: 0 auto;
      }
    }
  }
  .generalTemplateShare {
    width: 100%;
    .step1 {
      width: 100%;
      .sharing-modal-container {
        width: 100%;
        // min-height: 200px;
      }
      ::v-deep .v-input--selection-controls__input {
        margin-right: 4px;
      }

      ::v-deep .v-input--selection-controls .v-radio > .v-label {
        color: black;
      }
      ::v-deep.v-select__selection,
      .v-select__selection--comma,
      .v-select.v-text-field input {
        color: rgba(0, 0, 0, 0.6) !important;
      }

      .currentUserRemove {
        margin-right: 24px !important;
      }

      .sharing-modal__title {
        font-size: 1.5em;
        font-weight: 500;
        // .templateNameInBold {
        //   b {
        //     color: grey;
        //   }
        // }
      }
      .actions {
        width: 21%;
        margin: 0 auto;
      }
    }
    .step2 {
      .alertHeading {
        font-size: 19px;
        font-weight: 600;
        padding: 10px 0px 30px 0px;
      }
      .alertContent {
        font-size: 17px;
        padding: 0px 0px 30px 0px;
      }
      .alertActions {
        display: flex;
        justify-content: space-between;
        width: 45%;
        margin: 0 0 0 55%;
        padding: 20px 0px 0px 0px;
        .noButton {
          background-color: #fff !important;
          color: rgb(33, 167, 224);
          border: 1px solid rgb(33, 167, 224);
        }
      }
    }
    .step3 {
      .stepThreeHeading {
        font-size: 19px;
        font-weight: 600;
        padding: 10px 0px 30px 0px;
      }
      .stepThreeContent {
        font-size: 17px;
        padding: 0px 0px 30px 0px;
      }
      .stepThreeAction {
        padding: 20px 0px 0px 0px;
        margin: 0 0 0 80%;
      }
    }
  }
}
.spinner-wrapper {
  left: 48%;
  margin: auto;
  position: absolute;
  top: 40%;
}
.share-select-item {
  padding: 5px;
  max-width: 220px;

  .share-select-item__description {
    color: gray;
    padding-top: 5px;
  }
}
.share-select-item__selected {
  width: 30px;
}

.share-container {
  margin-left: 25px;

  ::v-deep .v-label {
    color: black;
  }

  .v-input {
    margin-top: 0;
    width: max-content;
  }
}
.select-colleagues-autocomplete {
  margin-bottom: 0 !important;
  padding: 0 14px 5px 14px;
  width: 100% !important;
}

.select-users-container {
  width: 100%;
}
</style>
