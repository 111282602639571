<template>
  <div class="password-set-wrapper">
    <v-btn class="close-btn" icon @click="handleClose">
      <v-icon dark v-text="`mdi-close`" />
    </v-btn>
    <div class="password-set-img">
      <img src="/assets/img/password-reset.svg" alt />
    </div>
    <div class="password-set-desc">
      {{ $t('profile.changePasswordDetail.passwordHasBeenReset') }}
    </div>
    <div class="password-set-action">
      <v-btn
        rounded
        type="submit"
        color="#21a7e0"
        min-width="150"
        height="50"
        @click="handleClose"
      >
        {{ $t('common.done') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { Auth } from 'aws-amplify';

export default {
  name: 'PasswordSetDetail',

  data() {
    return {};
  },
  methods: {
    handleClose() {
      this.$modal.hide('PasswordSetDetail');
    },
    async onSubmit() {
      // if (!this.$refs.forgotPasswordForm.validate()) return;

      this.$set(this.status, 'isSendingResetLink', true);
      try {
        Auth.forgotPasswordSubmit(
          this.username,
          this.verificationCode,
          this.password,
        )
          .then((data) => {
            this.error = '';
            this.isSent = data;
            this.$router.push('/signin');
            this.handleClose();
          })
          .catch((err) => {
            this.error = err.msg;
          });
      } catch (err) {
        this.console.log(err);
      }

      this.$set(this.status, 'isSendingResetLink', false);
    },
    async resendVerificationCode() {
      Auth.forgotPassword(this.username)
        .then((data) => {
          console.log(data);
          this.error = '';
        })
        .catch((err) => {
          console.error(err);
          this.error = err.message;
        });
    },
  },
  mounted() {
    this.username = this.userName;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.password-set-wrapper {
  width: 100%;
  height: 100%;
  padding: 79px 60px;

  .close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;
  }

  img {
    margin-bottom: 36px;
  }

  .password-set-desc {
    margin-bottom: 47px;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
  }

  .password-set-action {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  button {
    color: white;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-transform: none;
  }
}
</style>
